<template>
  <div class="wrap" @keydown.esc="escEvent">
    <Entoolbar type="1" unit="2" route-name="unit2-3" color="orange"></Entoolbar>

<!--    <img class="sample" src="../assets/Unit2-3/sample2.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="pic-1" src="../assets/Unit2-3/20230407-物件-2-3-01-首圖1.png">
      <div class="people">
        <LottieTest :json="require('@/assets/Unit2-3/2-3-0.json')" alt="The Left and The Right" title="The Left and The Right"></LottieTest>
      </div>

      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; top: 33.5%; left: 67.5%" src="../assets/common/zoom-btn.png" alt="more information" title="more information">

      <div class="title-logo">The Left and The Right</div>
    </div>

    <div class="part2 part-color">
      <div class="content">
        <img style="margin-left: 0vw; width: 40%" src="../assets/Unit2-3/en/20230706-EN網站-2-3-物件-04-裝飾字1-改組.png" alt="Reorganization" title="Reorganization">
        <p tabindex="0" class="content-style" style="margin-top: 1.4vw">In January 1927, the Taiwan Cultural Association was reorganized. Left-wing youth leaders emerged and put forward the slogan “Actual Movement.” The association was reorganized with a central committee in Taichung under the joint leadership of Lien Wen-ching and Wang Min-chuan.</p>
        <p class="content-style" style="margin-top: 1.4vw">They published the Taiwan People Times from March to July 1928; it advocated for agricultural workers, small businessmen, and petty bourgeoisie, while criticizing the Governor-General’s dictatorship and land policies, and arguing in favor of trade unions and anarchism.</p>
        <p class="content-style" style="margin-top: 1.4vw">After the division of the Cultural Association, the leftist youth more clearly articulated their critiques against imperialism and capitalists. They criticized the Taiwanese People's Party for attempting to curry favor with both the general public and the Governor-General's Office, and they denounced the Taiwan Local Autonomy Alliance as a representative of the landlord and capitalist classes.</p>
      </div>

      <div class="people">
        <LottieTest :json="require('@/assets/Unit2-3/2-3-1.json')" alt="Lien Wen-ching and Wang Min-chuan" title="Lien Wen-ching and Wang Min-chuan" ></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
<!--      <img class="absolute top-0" src="../assets/Unit2-3/sample3.png">-->
      <img class="pic-1" src="../assets/Unit2-3/20230407-物件-2-3-05-插圖1.png" alt="Unsent Reply Mail" title="Unsent Reply Mail">

      <div class="content">
        <p class="title-style" style="color: #ea5518; margin-top: 1.4vw">Unsent Reply Mail</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927</p>
        <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan History 2018.011.0014.0005-7</p>
        <p class="content-style" style="margin-top: 0.5vw;">A letter from Taiwan Cultural Association’s Provisional Standing Committee to Provisional Central Committee Member Lin Hsien-tang regarding the transfer of equipment used for Association activities like film screenings. The letter mentions that in February 1927 the resolution to “transfer” the equipment was opposed by Tsai Pei-huo. The letter included a reply mail soliciting committee member opinions. Lin Hsien-tang did not send out the reply and instead kept it, showing his disillusionment with the affairs of the Taiwan Cultural Association.</p>
      </div>

      <div class="letter-div" :class="envelopeStyle" @mouseenter="letterUp" @mouseleave="letterDown" @click="()=>{envelopeStyle ='letter-div-open'; envelopeOpen();}" @keydown.enter="()=>{envelopeStyle ='letter-div-open'; envelopeOpen();}" tabindex="0">
        <img  class="letter" :src="letterSrc" :class="letterFade==true?'letter-fade':''">
        <img  v-if="envelopeStyle!=''"  class="letter-open"  :src="letterOpenSrc" :class="letterFade==true?'letter-fade':''">
        <img class="envelope"   src="../assets/Unit2-3/envelope.png" alt="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927" title="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927">

        <transition name="img-frame-fade">
          <div class="point" v-if="envelopeStyle==''" alt="Click the Letter" title="Click the Letter">
            <div></div>
            <div>Click the Letter</div>
          </div>
        </transition>
      </div>

      <div class="letter-open-div" v-if="letterFade">
        <div style="position:relative; height: 13.5vw" >
          <img class="h-full" src="../assets/Unit2-3/20230407-物件-2-3-07-pic3.png" alt="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927" title="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927">
          <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 5%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
        <div style="position:relative; height: 11vw; margin-left: 1vw;" >
          <img class="h-full"  src="../assets/Unit2-3/20230407-物件-2-3-08-pic4.png" alt="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927" title="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927">
          <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0"  style="width: 2.2vw; bottom: 5%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
          <div style="position:relative; height: 15vw; margin-left: 2vw;" >
            <img class="h-full"  src="../assets/Unit2-3/20230407-物件-2-3-10-pic6.png" alt="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927" title="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927">
            <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0"  style="width: 2.2vw; bottom: 5%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
          </div>
      </div>
    </div>

    <div class="part4 part-color">
<!--      <img class="absolute top-0 opacity-30 z-10 w-full" src="../assets/Unit2-3/sample7.png">-->


      <transition name="img-frame-fade">
        <div class="arrow-btn" style="left: 3%;" v-if="curPart4Page>0">
          <p>{{part4PageName[0]}}</p>
          <img class="w-full arrow-left" style="margin-top: 0.3vw" src="../assets/Unit2-3/20230703-物件-2-3-50-左鍵-白.png" alt="previous page" title="previous page" @click="nextArrowBtn(-1)" @keydown.enter="nextArrowBtn(-1)" tabindex="0">
        </div>
      </transition>

      <transition name="test" >
        <div class="content content-1" v-if="part4Content[0]">
          <div class="top-content">
            <img style="width: 14.2vw" src="@/assets/Unit2-3/en/20230706-EN網站-2-3-物件-13-插圖2.png" alt="Taiwan Farmers' Association、Taiwan Comrades Association、Changhua Aspirants、Taiwan Cultural Association" title="Taiwan Farmers' Association、Taiwan Comrades Association、Changhua Aspirants、Taiwan Cultural Association">
            <img class="left-line" :src="leftLineSrc">
            <img class="left-bottom-line" :src="leftBottomLineSrc">
            <div class="wire-div">
              <img style="width: 15.4vw"  src="../assets/Unit2-3/20230407-物件-2-3-14-虛線圓.png">
              <img class="absolute" style="width: 10vw" src="../assets/Unit2-3/20230407-物件-2-3-19-pic7.png" alt="Leaflet protesting a lecture by Tadao Yanaihara" title="Leaflet protesting a lecture by Tadao Yanaihara">
            </div>
            <img class="right-line" :src="rightLineSrc">
            <img style="width:26.5vw; margin-left: -0.5vw" src="../assets/Unit2-3/en/20230706-EN網站-2-3-物件-15-插圖3.png" alt="Taiwanese People's Party、Taiwan Local Autonomy Alliance" title="Taiwanese People's Party、Taiwan Local Autonomy Alliance">
          </div>
          <div class="down-content">
              <div class="pic-1">
                <img class="w-full"  src="../assets/Unit2-3/20230407-物件-2-3-19-pic7.png" alt="Leaflet protesting a lecture by Tadao Yanaihara" title="Leaflet protesting a lecture by Tadao Yanaihara">
                <img class="zoom-2-btn zoom-fade" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 2.2vw; bottom: 7%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              </div>
            <div class="pic-2">
              <img class="w-full" src="../assets/Unit2-3/20230407-物件-2-3-20-pic8.png" alt="Leaflet protesting a lecture by Tadao Yanaihara" title="Leaflet protesting a lecture by Tadao Yanaihara">
              <img class="zoom-2-btn zoom-fade" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.2vw; bottom: 7%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
            </div>

            <div class="content-text">
              <p class="title-style" style="color: #ea5518">Conflict Erupts!</p>
              <p class="subtitle-style" style="margin-top: 0.5vw">Leaflet protesting a lecture by Tadao Yanaihara</p>
              <p class="source-style" style="margin-top: 0.3vw">Courtesy of Academic Institute of Taiwan History, Academia Sinica Archives</p>
              <p class="content-style" style="margin-top: 0.5vw; height: 9.9vw; overflow: scroll">In August 1927, Tsai Pei-huo and others invited renowned social economist Tadao Yanaihara to give a lecture in Taiwan, which was met with opposition from left-leaning members of the Taiwan Cultural Association. Youths claiming to be part of the Taiwan Comrades Association and Changhua Aspirants distributed leaflets to protest the event. The leaflet accused Yanaihara of being a scholar of capitalism and colonial policy, criticizing him for "having no sincerity in working towards the well-being of the Taiwanese people."</p>
              <img class="content-btn" @click="explainOpen=!explainOpen" @keydown.enter="explainOpen=!explainOpen" tabindex="0"  src="../assets/Unit2-3/en/20230706-EN網站-2-3-物件-16-失內按鈕-藍.png" alt="Who was Tadao Yanaihara?(Open/Close Description)" title="Who was Tadao Yanaihara?(Open/Close Description)">
              <div class="explain" :class="explainOpen==true?'explain-open':''">
                <p class="content-style" style="overflow: scroll; height: 7vw;" >Tadao Yanaihara was a renowned social economist. He advocated for "the liberation of the oppressed, the elevation of the downtrodden, and fostering peaceful integration among independent and autonomous entities." Although he sympathized with colonized territories, his stance was significantly different from that of the left-wing youth in Taiwan at the time, which led to extensive criticism.</p>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="test" >
        <div class="content content-2" v-if="part4Content[1]">
        <div class="top-content">
          <img style="width: 14.2vw" src="@/assets/Unit2-3/en/20230706-EN網站-2-3-物件-13-插圖2.png" alt="Taiwan Farmers' Association、Taiwan Comrades Association、Changhua Aspirants、Taiwan Cultural Association" title="Taiwan Farmers' Association、Taiwan Comrades Association、Changhua Aspirants、Taiwan Cultural Association">
          <img class="left-line" :src="leftLine2Src">
          <img class="left-bottom-line" :src="leftBottomLine2Src">
          <div class="wire-div">
            <img style="width: 15.4vw" src="../assets/Unit2-3/20230407-物件-2-3-14-虛線圓.png">
            <img class="absolute" style="width: 8.5vw" src="../assets/Unit2-3/20230407-物件-2-3-21-pic9.png" alt="Changhua Street Aspirants warning leaflet" title="Changhua Street Aspirants warning leaflet">
          </div>
          <img class="right-line" :src="rightLine2Src">
          <img style="width:26.5vw; margin-left: -0.5vw" src="../assets/Unit2-3/en/20230706-EN網站-2-3-物件-15-插圖3.png" alt="Taiwanese People's Party、Taiwan Local Autonomy Alliance" title="Taiwanese People's Party、Taiwan Local Autonomy Alliance">
        </div>
        <div class="down-content">
          <div class="pic-1">
            <img class="w-full" src="../assets/Unit2-3/20230407-物件-2-3-21-pic9.png" alt="Changhua Street Aspirants warning leaflet" title="Changhua Street Aspirants warning leaflet">
            <img class="zoom-2-btn zoom-fade" @click="showImg[6]=true" @keydown.enter="showImg[6]=true" tabindex="0" style="width: 2.2vw; bottom: 7%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
          </div>

          <div class="content-text">
            <p class="title-style" style="color: #ea5518">Right-wing Counterattack! </p>
            <p class="subtitle-style" style="margin-top: 0.5vw">Changhua Street Aspirants warning leaflet</p>
            <p class="source-style" style="margin-top: 0.3vw">Courtesy of Academic Institute of Taiwan History, Academia Sinica Archives</p>
            <p class="content-style" style="margin-top: 0.5vw;">The leaflet, critical of the Taiwan Cultural Association,  reads "What is the true nature of the Cultural Association? An impure group full of rabid dog-ism, chaos-ism, and entrapment-ism."</p>
          </div>
        </div>
      </div>
      </transition>

      <transition name="test" >
        <div class="content content-3" v-if="part4Content[2]">
          <div class="top-content">
            <img style="width: 14.2vw" src="@/assets/Unit2-3/en/20230706-EN網站-2-3-物件-13-插圖2.png" alt="Taiwan Farmers' Association、Taiwan Comrades Association、Changhua Aspirants、Taiwan Cultural Association" title="Taiwan Farmers' Association、Taiwan Comrades Association、Changhua Aspirants、Taiwan Cultural Association">
            <img class="left-line" :src="leftLineSrc">
            <img class="left-top-line" :src="leftTopLine3Src">
            <div class="wire-div">
              <img style="width: 15.4vw" src="../assets/Unit2-3/20230407-物件-2-3-14-虛線圓.png">
              <img class="absolute" style="width: 10vw" src="../assets/Unit2-3/20230407-物件-2-3-22-pic10.png" alt="Joint statement of Taiwan Cultural Association and Taiwan Farmers' Association opposing the Taiwan Local Autonomy Alliance declaration" title="Joint statement of Taiwan Cultural Association and Taiwan Farmers' Association opposing the Taiwan Local Autonomy Alliance declaration">
            </div>
            <img class="right-line" :src="rightLineSrc">
            <img style="width:26.5vw; margin-left: -0.5vw" src="../assets/Unit2-3/en/20230706-EN網站-2-3-物件-15-插圖3.png" alt="Taiwanese People's Party、Taiwan Local Autonomy Alliance" title="Taiwanese People's Party、Taiwan Local Autonomy Alliance">
          </div>
          <div class="down-content">
            <div class="pic-1">
              <img class="w-full" src="../assets/Unit2-3/20230407-物件-2-3-22-pic10.png" alt="Joint statement of Taiwan Cultural Association and Taiwan Farmers' Association opposing the Taiwan Local Autonomy Alliance declaration" title="Joint statement of Taiwan Cultural Association and Taiwan Farmers' Association opposing the Taiwan Local Autonomy Alliance declaration">
              <img class="zoom-2-btn zoom-fade"  @click="showImg[7]=true" @keydown.enter="showImg[7]=true" tabindex="0" style="width: 2.2vw; bottom: 7%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
            </div>

            <div class="content-text">
              <p class="title-style" style="color: #ea5518">I Oppose Your Opposition</p>
              <p class="subtitle-style" style="margin-top: 0.5vw">Joint statement of Taiwan Cultural Association and Taiwan Farmers' Association opposing the Taiwan Local Autonomy Alliance declaration</p>
              <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan History  2020.006.0199</p>
              <p class="content-style" style="margin-top: 0.5vw;">This leaflet presents a joint statement by the Cultural Association and the Taiwan Farmers' Association, condemning the newly established Taiwan Local Autonomy Alliance as promoting "self-deceptive local autonomy." The statement acknowledges that the Taiwanese People's Party also opposes the Taiwan Local Autonomy Alliance, but argues that this opposition merely reflects internal struggles among capitalists. Both the Cultural Association and the Taiwan Farmers' Association firmly stand against both organizations.</p>
            </div>
          </div>
        </div>
      </transition>

      <transition name="test" >
        <div class="content content-4" v-if="part4Content[3]">
        <div class="top-content">
          <img style="width: 14.2vw" src="@/assets/Unit2-3/en/20230706-EN網站-2-3-物件-13-插圖2.png" alt="Taiwan Farmers' Association、Taiwan Comrades Association、Changhua Aspirants、Taiwan Cultural Association" title="Taiwan Farmers' Association、Taiwan Comrades Association、Changhua Aspirants、Taiwan Cultural Association">
          <img class="left-line" :src="leftLineSrc">
          <div class="wire-div">
            <img style="width: 15.4vw" src="../assets/Unit2-3/20230407-物件-2-3-14-虛線圓.png">
            <img class="absolute" style="width: 9.7vw" src="../assets/Unit2-3/24-pic12-翻頁效果/24-pic12-翻頁效果-00.png" alt="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy" title="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy">
          </div>
          <img class="right-line" :src="rightLineSrc">
          <img style="width:26.5vw; margin-left: -0.5vw" src="../assets/Unit2-3/en/20230706-EN網站-2-3-物件-15-插圖3.png" alt="Taiwanese People's Party、Taiwan Local Autonomy Alliance" title="Taiwanese People's Party、Taiwan Local Autonomy Alliance">
        </div>
        <div class="down-content">
<!--          <div class="pic-1">-->
<!--            <img class="w-full relative" :style="book1Style" :src="book1">-->
<!--            <img class="w-full absolute top-0" :style="book2Style"  :src="book2">-->
<!--            <img class="w-full absolute top-0" :style="book3Style"  :src="book3">-->
<!--            <img class="zoom-2-btn zoom-fade " @click="showImg[bookPage+8] = true" style="width: 2.2vw; bottom: 5%; right: 3%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
<!--          </div>-->
          <transition name="img-frame-fade"><img v-if="bookPage>1" class="left-arrow"  @click="bookLeftBtn" @keydown.enter="bookLeftBtn" tabindex="0" src="../assets/common/left-arrow.png" alt="previous page" title="previous page"></transition>


          <div class="turnClass">
            <img class="zoom-2-btn zoom-fade " @click="showImg[bookPage+7] = true"  @keydown.enter="showImg[bookPage+7] = true" tabindex="0" style="width: 2.2vw; bottom: 5%; right: 3%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
            <div id="flipbook">
              <img class="page page-1 " :src="require('@/assets/Unit2-3/24-pic12-翻頁效果/24-pic12-翻頁效果-00.png')" alt="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy" title="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy">
              <img class="page page-2 " :src="require('@/assets/Unit2-3/23-pic11-翻頁效果/23-pic11-翻頁效果-00.png')" alt="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy" title="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy">
              <img class="page page-3 " :src="require('@/assets/Unit2-3/20230407-物件-2-3-26-pic13.png')" alt="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy" title="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy">
            </div>
          </div>

          <transition name="img-frame-fade"><img v-if="bookPage<3" class="right-arrow" @click="bookRightBtn" @keydown.enter="bookRightBtn" tabindex="0"   src="../assets/common/right-arrow.png" alt="next page" title="next page"></transition>

          <div class="content-text">
            <p class="title-style" style="color: #ea5518">Opposition from Former Comrades</p>
            <p class="subtitle-style" style="margin-top: 0.5vw">Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy</p>
            <p class="source-style" style="margin-top: 0.3vw">Courtesy of Liu Ran Ju Archives</p>
            <p class="content-style" style="margin-top: 0.5vw;">This leaflet, issued by the Taiwanese Cultural Association to oppose the Taiwan People's Party, is curiously written on the party's exclusive stationery. The reason for this remains a mystery, but it could be a copied document kept by People's Party members who were still interested in the criticisms from the Taiwan Cultural Association.</p>
          </div>
        </div>
      </div>
      </transition>

      <!--      <transition name="img-frame-fade"><div v-if="part4Content[3]==false" @click="nextArrowBtn" class="next-arrow"></div></transition>-->
      <transition name="img-frame-fade">
      <div class="arrow-btn" style="right: 3%;" v-if="curPart4Page<3">
        <p>{{part4PageName[1]}}</p>
        <img class="w-full arrow-right" style="margin-top: 0.3vw" src="../assets/Unit2-3/20230703-物件-2-3-49-右鍵-白.png" alt="next page" title="next page" @click="nextArrowBtn(1)" @keydown.enter="nextArrowBtn(1)" tabindex="0">
      </div>
      </transition>
    </div>

    <div class="part5 part-color">
      <img class="pic-1" src="../assets/Unit2-3/20230407-物件-2-3-32-插圖4.png" alt="Dissolution" title="Dissolution">
      <div class="content">
        <img style="width: 35vw; margin-right: 0.7vw" src="../assets/Unit2-3/en/20230706-EN網站-2-3-物件-29-裝飾字2-解散.png" alt="Dissolution" title="Dissolution">
        <p tabindex="0" class="content-style">After police clashed with civilians at the Hsinchu Lecture (November 1927), made arrests at a protest against the demolition of a cemetery in Tainan (May 1928), and took into custody an agricultural group (February 1929), many key members of the Taiwan Cultural Association were arrested and intermittently detained. After November 1929, members sympathetic to communism took control of the association, and it became more radical. Japanese colonial authorities cracked down, and the association disbanded at the end of 1931.</p>
      </div>
    </div>

    <div class="part6 part-color">
      <div class="unit-btn" style="top: 23%; left: 12%; " @click="routeBtn('/en/unit2-0')" @keydown.enter="routeBtn('/en/unit2-0')" tabindex="0">
        <img class="w-full"  src="../assets/Unit2-0/文協向前衝按鈕.png" alt="The Taiwan Cultural Association Forges Ahead" title="The Taiwan Cultural Association Forges Ahead">
        <p>The Taiwan Cultural</p>
        <p>Association Forges Ahead</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 38.5%; ">
        <img class="w-full" src="../assets/Unit2-0/臺灣人的臺灣按鈕.png" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0">
        <p>Taiwanese People’s</p>
        <p>Taiwan</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 65%; ">
        <img class="w-full" src="../assets/Unit2-0/為農工大眾主張按鈕.png" alt="Advocating for Farmers and Workers" title="Advocating for Farmers and Workers" @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" tabindex="0">
        <p>Advocating for</p>
        <p>Farmers and Workers</p>
      </div>
      <div class="unit-btn" style="top: 57.5%; left: 25%; ">
        <img class="w-full"  src="../assets/Unit2-0/20230724-2-3-物件-36-左派與右派按鈕-淡.png" alt="The Left and The Right" title="The Left and The Right" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0">
        <p>The Left and The Right</p>
      </div>
      <div class="unit-btn" style="top: 57.5%; left: 52%; ">
        <img class="w-full" src="../assets/Unit2-0/政治結社的走向按鈕.png" alt="The Trend of Political Association" title="The Trend of Political Association" @click="routeBtn('/en/unit2-4')" @keydown.enter="routeBtn('/en/unit2-4')" tabindex="0">
        <p>The Trend of</p>
        <p>Political Association</p>
      </div>
    </div>

    <!--img0--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample8.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34.5vw; margin:3.5vw 0 0 3.2vw" src="../assets/Unit2-3/20230703-物件-2-3-03-pic1.png" alt="'Down With Taiwan Local Autonomy Alliance!' leaflet 1930" title="'Down With Taiwan Local Autonomy Alliance!' leaflet 1930">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 55vw; width: 28vw;">
            <div class="bold-title">'Down With Taiwan Local Autonomy Alliance!' leaflet 1930</div>
            <div class="noto-text" >Authorized use provided by the Liu Ran Ju Archives</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample9.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:21.5vw; margin:2.5vw 0 0 21vw" src="../assets/Unit2-3/20230703-物件-2-3-07-pic3-放大文物.png" alt="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927" title="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927">
          </div>

          <div class="content-text">
            <div class="bold-title">Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927</div>
            <div class="noto-text" >National Museum of Taiwan History  2018.011.0014.0005</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample10.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.3vw; margin:2.5vw 0 0 23.5vw" src="../assets/Unit2-3/20230703-物件-2-3-09-pic5-放大文物.png" alt="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927" title="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927">
          </div>

          <div class="content-text">
            <div class="bold-title">Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927</div>
            <div class="noto-text" >National Museum of Taiwan History  2018.011.0014.0006</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample11.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35vw; margin:2vw 0 0 14.5vw" src="../assets/Unit2-3/20230703-物件-2-3-11-pic6-放大文物.png" alt="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927" title="Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927">
          </div>

          <div class="content-text">
            <div class="bold-title">Notice from the Taiwan Cultural Association Provisional Standing Committee sent to Lin Hsien-tang dated April 8, 1927</div>
            <div class="noto-text" >National Museum of Taiwan History  2018.011.0014.0007</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample12.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.5vw; margin:2vw 0 0 13vw" src="../assets/Unit2-3/20230703-物件-2-3-19-pic7-放大文物.png" alt="Leaflet protesting a lecture by Tadao Yanaihara" title="Leaflet protesting a lecture by Tadao Yanaihara">
          </div>

          <div class="content-text">
            <div class="bold-title">Leaflet protesting a lecture by Tadao Yanaihara</div>
            <div class="noto-text" >Courtesy of Academic Institute of Taiwan History, Academia Sinica Archives</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
<!--                <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample13.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:28vw; margin:1.5vw 0 0 18vw" src="../assets/Unit2-3/20230703-物件-2-3-20-pic8-放大文物.png" alt="Leaflet protesting a lecture by Tadao Yanaihara" title="Leaflet protesting a lecture by Tadao Yanaihara">
          </div>

          <div class="content-text">
            <div class="bold-title">Leaflet protesting a lecture by Tadao Yanaihara</div>
            <div class="noto-text" >Courtesy of Academic Institute of Taiwan History, Academia Sinica Archives</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
<!--          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample14.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:28vw; margin:1.5vw 0 0 18vw" src="../assets/Unit2-3/20230703-物件-2-3-21-pic9-放大文物.png" alt="Changhua Street Aspirants warning leaflet" title="Changhua Street Aspirants warning leaflet">
          </div>

          <div class="content-text">
            <div class="bold-title">Changhua Street Aspirants warning leaflet</div>
            <div class="noto-text" >Courtesy of Academic Institute of Taiwan History, Academia Sinica Archives</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img7--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[7]">
<!--                  <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample15.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:40.5vw; margin:2.5vw 0 0 12vw" src="../assets/Unit2-3/20230703-物件-2-3-22-pic10-放大文物.png" alt="Joint statement of Taiwan Cultural Association and Taiwan Farmers' Association opposing the Taiwan Local Autonomy Alliance declaration" title="Joint statement of Taiwan Cultural Association and Taiwan Farmers' Association opposing the Taiwan Local Autonomy Alliance declaration">
          </div>

          <div class="content-text">
            <div class="bold-title">Joint statement of Taiwan Cultural Association and Taiwan Farmers' Association opposing the Taiwan Local Autonomy Alliance declaration</div>
            <div class="noto-text" >National Museum of Taiwan History  2020.006.
              0199</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[7]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img8--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[8]">
<!--                          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.8vw; margin:2vw 0 0 13.5vw" src="../assets/Unit2-3/20230703-物件-2-3-23-pic11-放大文物.png" alt="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy" title="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy">
          </div>

          <div class="content-text">
            <div class="bold-title">Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy</div>
            <div class="noto-text" >Courtesy of Liu Ran Ju Archives</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[8]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img9--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[9]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.8vw; margin:2vw 0 0 13.5vw" src="../assets/Unit2-3/20230703-物件-2-3-25-pic12-放大文物.png" alt="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy" title="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy">
          </div>

          <div class="content-text">
            <div class="bold-title">Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy</div>
            <div class="noto-text" >Courtesy of Liu Ran Ju Archives</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[9]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img10--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[10]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.8vw; margin:2vw 0 0 13.5vw" src="../assets/Unit2-3/20230703-物件-2-3-26-pic13-放大文物.png" alt="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy" title="Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy">
          </div>

          <div class="content-text">
            <div class="bold-title">Leaflet by the Taiwan Cultural Association Headquarters, expressing strong opposition to the Taiwanese People's Party local autonomy</div>
            <div class="noto-text" >Courtesy of Liu Ran Ju Archives</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[10]=false">
          </div>
        </div>
      </div>
    </transition>

    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn";
import LottieTest from "@/components/LottieTest";
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import flipAudio from "@/assets/Unit3-1/insert_paper.mp3";
import $ from "jquery";

export default {
  name: "Unit2-2",
  mixins: [myMixin],
  components:{
    Entoolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const letterSrc = ref(null);
    const letterOpenSrc = ref(null);
    letterSrc.value = require('@/assets/Unit2-3/letter-move/06-信件抽拉動態-00.png');
    letterOpenSrc.value = require('@/assets/Unit2-3/letter-open/06-信件抽拉動態-2_00.png');
    const envelopeStyle = ref('');
    const letterFade = ref(false);
    const explainOpen = ref(false);

    const showImg = reactive(Array.from({ length: 11 }, () => false));
    const part4Content = reactive(Array.from({ length: 4 }, () => false));
    part4Content[0] = true;
    const curPart4Page = ref(0);
    const part4PageName  = reactive(Array.from({ length: 2 }, () => ''));

    let letterIndex = 0;
    let letterOpen = false;

    const book1 = ref(require('@/assets/Unit2-3/24-pic12-翻頁效果/24-pic12-翻頁效果-00.png'));
    const book2 = ref(require('@/assets/Unit2-3/23-pic11-翻頁效果/23-pic11-翻頁效果-00.png'));
    const book3 = ref(require('@/assets/Unit2-3/20230407-物件-2-3-26-pic13.png'));
    const book1Style = ref('z-index:3;');
    const book2Style = ref('z-index:2; opacity:0;');
    const book3Style = ref('z-index:1; opacity:0;');

    const flipAudio = require('@/assets/Unit3-1/insert_paper.mp3');
    const flipEle = new Audio(flipAudio);

    let intervalMap = new Map();


    function routeBtn(path){
      router.push(path);
    }

    function letterUp() {
      if(letterOpen == true) return;
      if(intervalMap.has('letterUp')){
        clearInterval(intervalMap['letterUp']);
      }

      letterIndex = 0;

      intervalMap['letterUp'] = setInterval(()=>{

        letterIndex = Math.min(letterIndex + 1, 14);

        letterSrc.value = require('@/assets/Unit2-3/letter-move/06-信件抽拉動態-'+letterIndex.toString().padStart(2, '0') +'.png');
        //letterSrc.value = letterMouseUp[letterIndex].src;

        if(letterIndex >= 14){
          clearInterval(intervalMap['letterUp']);
        }
        console.log(111)
      }, 30);
    }

    function letterDown() {
      if(letterOpen == true) return;

      if(intervalMap.has('letterDown')){
        clearInterval(intervalMap['letterDown']);
      }

      letterIndex = 14;

      intervalMap['letterDown'] = setInterval(()=>{

        letterIndex = Math.min(letterIndex - 1, 14);

        console.log(letterIndex)

        letterSrc.value = require('@/assets/Unit2-3/letter-move/06-信件抽拉動態-'+letterIndex.toString().padStart(2, '0') +'.png');
        //letterSrc.value = letterMouseUp[letterIndex].src;

        if(letterIndex <= 0){
          clearInterval(intervalMap['letterDown']);
        }
      }, 30);
    }

    function envelopeOpen() {
      if(letterOpen == true) return;

      if(intervalMap.has('letter')){
        clearInterval(intervalMap['letter']);
      }

      letterIndex = 1;
      letterOpen = true;

      intervalMap['letter'] = setInterval(()=>{

        letterIndex = (letterIndex + 1);

        letterOpenSrc.value = require('@/assets/Unit2-3/letter-open/06-信件抽拉動態-2_'+letterIndex.toString().padStart(2, '0') +'.png');
        //letterSrc.value = test[letterIndex].src;


        if(letterIndex >= 55){
          letterFade.value = true;
          clearInterval(intervalMap['letter']);
        }
      }, 30);
    }


    const letterMouseUp = reactive([]);

    function onTurn(){
      $("#flipbook").turn({
        acceleration:true,
        display: "single",
        //gradients: true,
        autoCenter: true,
        height:646,
        width:896,
        direction: "rtl",
      });


      $('#flipbook').turn('size', '100%', '100%');
      $("#flipbook").bind("turning", function(event, page, view) {
        bookPage.value = page;
        flipEle.play();
      });

    }


    onUnmounted(()=>{
      for (const key in intervalMap) {
        clearIntervalId(key);
      }
    });

    function clearIntervalId(name){
      if(intervalMap.has(name)){
        clearInterval(intervalMap[name]);
      }
    }

    /****Content-1 Png動畫***/
    let leftLineIndex = 0;
    const leftLineSrc = ref('');
    content1LeftLine();
    function content1LeftLine(){
      clearIntervalId('leftLine');
        leftLineIndex = 0;

      intervalMap['leftLine'] = setInterval(()=>{

          leftLineIndex = (leftLineIndex + 1) % (55+1);

          leftLineSrc.value = require('@/assets/Unit2-3/en/19-pic7-動態線/19-pic7-動態線-發行-左邊-'+leftLineIndex.toString().padStart(2, '0') +'.png');
        }, 50);
    }

    let leftBottomLineIndex = 0;
    const leftBottomLineSrc = ref('');
    content1LeftBottomLine();
    function content1LeftBottomLine(){
      clearIntervalId('leftBottomLine');
      leftBottomLineIndex = 0;

      intervalMap['leftBottomLine'] = setInterval(()=>{

        leftBottomLineIndex = (leftBottomLineIndex + 1) % (55+1);

        leftBottomLineSrc.value = require('@/assets/Unit2-3/en/19-pic7-動態線/19-pic7-動態線-發行-左下-'+leftBottomLineIndex.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    let rightLineIndex = 0;
    const rightLineSrc = ref('');
    content1RightLine();
    function content1RightLine(){
      clearIntervalId('rightLine');
      rightLineIndex = 0;

      intervalMap['rightLine'] = setInterval(()=>{

        rightLineIndex = (rightLineIndex + 1) % (55+1);

        rightLineSrc.value = require('@/assets/Unit2-3/en/19-pic7-動態線/19-pic7-動態線-批評-右邊-'+rightLineIndex.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    /****Content-2 Png動畫***/
    let leftLine2Index = 0;
    const leftLine2Src = ref('');
    content2LeftLine();
    function content2LeftLine(){
      clearIntervalId('leftLine2');
      leftLine2Index = 0;

      intervalMap['leftLine2'] = setInterval(()=>{

        leftLine2Index = (leftLine2Index + 1) % (55+1);

        leftLine2Src.value = require('@/assets/Unit2-3/en/21-pic9-動態線/21-pic9-動態線-批評-左邊 00'+leftLine2Index.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    let leftBottomLine2Index = 0;
    const leftBottomLine2Src = ref('');
    content2LeftBottomLine();
    function content2LeftBottomLine(){
      clearIntervalId('leftBottomLine2');
      leftBottomLineIndex = 0;

      intervalMap['leftBottomLine2'] = setInterval(()=>{

        leftBottomLine2Index = (leftBottomLine2Index + 1) % (55+1);

        leftBottomLine2Src.value = require('@/assets/Unit2-3/en/21-pic9-動態線/21-pic9-動態線-批評-左下00'+leftBottomLine2Index.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    let rightLine2Index = 0;
    const rightLine2Src = ref('');
    content2RightLine();
    function content2RightLine(){
      clearIntervalId('rightLine2');
      rightLine2Index = 0;

      intervalMap['rightLine2'] = setInterval(()=>{

        rightLine2Index = (rightLine2Index + 1) % (55+1);

        rightLine2Src.value = require('@/assets/Unit2-3/en/21-pic9-動態線/21-pic9-動態線-發行-右邊00'+rightLine2Index.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    /****Content-3 Png動畫***/
    let leftTopLine3Index = 0;
    const leftTopLine3Src = ref('');
    content3LeftTopLine();
    function content3LeftTopLine(){
      clearIntervalId('leftTopLine3');
      leftTopLine3Index = 0;

      intervalMap['leftTopLine3'] = setInterval(()=>{

        leftTopLine3Index = (leftTopLine3Index + 1) % (55+1);

        leftTopLine3Src.value = require('@/assets/Unit2-3/en/22-pic10-動態線/22-pic10-動態線-發行-左上-'+leftTopLine3Index.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    let rightText = ['Right-wing Counterattack! ','I Oppose Your Opposition','Opposition from Former Comrades',''];
    let leftText = ['','Conflict Erupts!','Right-wing Counterattack! ','I Oppose Your Opposition'];
    part4PageName[1] = rightText[0];

     async function nextArrowBtn(dir){
      for (let i=0; i<4; i++)
        part4Content[i] = false;

      if(dir == 1){
        curPart4Page.value = Math.min(curPart4Page.value+1, 3);
      }
      else {
        curPart4Page.value = Math.max(curPart4Page.value-1, 0);
      }

      part4PageName[0] = leftText[curPart4Page.value];
      part4PageName[1] = rightText[curPart4Page.value];

      part4Content[curPart4Page.value] = true;

      await nextTick();

      if(curPart4Page.value==3 && !$('#flipbook').turn('is')){
          bookPage.value =1;
          onTurn();
          window.addEventListener('resize', ()=>{
            $('#flipbook').turn('size', 'auto', '100%');
          });
      }
    }


    const bookPage = ref(1);
    function bookRightBtn(){
      $("#flipbook").turn("next");
    }

    function bookLeftBtn(){
      $("#flipbook").turn("previous");
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }


    return{
      routeBtn,
      letterSrc,
      letterUp,
      letterDown,
      envelopeStyle,
      envelopeOpen,
      letterFade,
      letterMouseUp,
      letterOpenSrc,
      explainOpen,
      leftLineSrc,
      leftBottomLineSrc,
      rightLineSrc,
      leftLine2Src,
      leftBottomLine2Src,
      rightLine2Src,
      leftTopLine3Src,
      part4Content,
      book1,
      book2,
      book3,
      book1Style,
      book2Style,
      book3Style,
      bookPage,
      bookRightBtn,
      bookLeftBtn,
      showImg,
      nextArrowBtn,
      curPart4Page,
      part4PageName,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit2-3.css"></style>


