<template>
  <div class="wrap w-full">
    <toolbar type="2" unit="5" route-name="person-intro-all" color="brown"></toolbar>
<!--    <img class="absolute w-full top-0 opacity-30" src="../assets/person-intro/sample/20230712-EN網站-對位-人物介紹-入口頁面-or-07.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div style="height: 49.6vw; overflow: hidden">
      <div class="title-div">
        <p class="title">文協人物介紹</p>
        <div>
          <p class="sort-btn" @click="changePage(0)" @keydown.enter="changePage(0)" tabindex="0" alt="看全部" title="看全部">看全部</p>
          <p class="sort-btn" @click="changePage(1)" @keydown.enter="changePage(1)" tabindex="0" alt="文協人物" title="文協人物">文協人物</p>
          <p class="sort-btn" @click="changePage(2)" @keydown.enter="changePage(2)" tabindex="0" alt="鄉紳儒士" title="鄉紳儒士">鄉紳儒士</p>
          <p class="sort-btn" @click="changePage(3)" @keydown.enter="changePage(3)" tabindex="0" alt="叛逆青年" title="叛逆青年">叛逆青年</p>
          <p class="sort-btn" @click="changePage(4)" @keydown.enter="changePage(4)" tabindex="0" alt="醫者與牧者" title="醫者與牧者">醫者與牧者</p>
          <p class="sort-btn" @click="changePage(5)" @keydown.enter="changePage(5)" tabindex="0" alt="性別的追尋" title="性別的追尋">性別的追尋</p>
          <p class="sort-btn" @click="changePage(6)" @keydown.enter="changePage(6)" tabindex="0" alt="勞動者的剪影" title="勞動者的剪影">勞動者的剪影</p>
        </div>
      </div>

      <transition name="img-frame-fade"> <div v-if="page[0]==true" class="content content-1 " >
        <img @click="routeBtn('/person-intro/11')" @keydown.enter="routeBtn('/person-intro/11')" tabindex="0" class="head person-1 vibrate-1" src="../assets/person-intro/head/20230424-入口頁面-物件-18-蔡惠如-白.png" alt="蔡惠如" title="蔡惠如">
        <img @click="routeBtn('/person-intro/24')" @keydown.enter="routeBtn('/person-intro/24')" tabindex="0" class="head person-2 vibrate-2" src="../assets/person-intro/head/20230424-入口頁面-物件-31-王敏川-白.png" alt="王敏川" title="王敏川">
        <img @click="routeBtn('/person-intro/0')" @keydown.enter="routeBtn('/person-intro/0')" tabindex="0" class="head person-3 vibrate-3" src="../assets/person-intro/head/20230424-入口頁面-物件-07-連溫卿-白.png" alt="連溫卿" title="連溫卿">
        <img @click="routeBtn('/person-intro/14')" @keydown.enter="routeBtn('/person-intro/14')" tabindex="0" class="head person-4 vibrate-4" src="../assets/person-intro/head/20230424-入口頁面-物件-21-林幼春-白.png" alt="林幼春" title="林幼春">
        <img @click="routeBtn('/person-intro/9')" @keydown.enter="routeBtn('/person-intro/9')" tabindex="0" class="head person-5 vibrate-5" src="../assets/person-intro/head/20230424-入口頁面-物件-16-王詩琅-白.png" alt="王詩琅" title="王詩琅">
        <img @click="routeBtn('/person-intro/10')" @keydown.enter="routeBtn('/person-intro/10')" tabindex="0" class="head person-6 vibrate-1" src="../assets/person-intro/head/20230424-入口頁面-物件-17-蔡淵騰-白.png" alt="蔡淵騰" title="蔡淵騰">
        <img @click="routeBtn('/person-intro/8')" @keydown.enter="routeBtn('/person-intro/8')" tabindex="0" class="head person-7 vibrate-2" src="../assets/person-intro/head/20230424-入口頁面-物件-15-盧丙丁-白.png" alt="盧丙丁" title="盧丙丁">
        <img @click="routeBtn('/person-intro/2')" @keydown.enter="routeBtn('/person-intro/2')" tabindex="0" class="head person-8 vibrate-3" src="../assets/person-intro/head/20230424-入口頁面-物件-09-謝文達-白.png" alt="謝文達" title="謝文達">
        <img @click="routeBtn('/person-intro/16')" @keydown.enter="routeBtn('/person-intro/16')" tabindex="0" class="head person-9 vibrate-4" src="../assets/person-intro/head/20230424-入口頁面-物件-23-杜香國-白.png" alt="杜香國" title="杜香國">
        <img @click="routeBtn('/person-intro/17')" @keydown.enter="routeBtn('/person-intro/17')" tabindex="0" class="head person-10 vibrate-5" src="../assets/person-intro/head/20230424-入口頁面-物件-24-蔣渭水-白.png" alt="蔣渭水" title="蔣渭水">
        <img @click="routeBtn('/person-intro/6')" @keydown.enter="routeBtn('/person-intro/6')" tabindex="0" class="head person-11 vibrate-1" src="../assets/person-intro/head/20230424-入口頁面-物件-13-陳植棋-白.png" alt="陳植棋" title="陳植棋">
        <img @click="routeBtn('/person-intro/20')" @keydown.enter="routeBtn('/person-intro/20')" tabindex="0" class="head person-12 vibrate-2" src="../assets/person-intro/head/20230424-入口頁面-物件-27-賴和-白.png" alt="賴和" title="賴和">
        <img @click="routeBtn('/person-intro/21')" @keydown.enter="routeBtn('/person-intro/21')" tabindex="0" class="head person-13 vibrate-3" src="../assets/person-intro/head/20230424-入口頁面-物件-28-林淇漳-白.png" alt="林淇漳" title="林淇漳">
        <img @click="routeBtn('/person-intro/15')" @keydown.enter="routeBtn('/person-intro/15')" tabindex="0" class="head person-14 vibrate-4" src="../assets/person-intro/head/20230424-入口頁面-物件-22-蘇璧輝-白.png" alt="蘇璧輝" title="蘇璧輝">
        <img @click="routeBtn('/person-intro/23')" @keydown.enter="routeBtn('/person-intro/23')" tabindex="0" class="head person-15 vibrate-5" src="../assets/person-intro/head/20230424-入口頁面-物件-30-簡吉-白.png" alt="簡吉" title="簡吉">
        <img @click="routeBtn('/person-intro/3')" @keydown.enter="routeBtn('/person-intro/3')" tabindex="0" class="head person-16 vibrate-1" src="../assets/person-intro/head/20230424-入口頁面-物件-10-林獻堂-白.png" alt="林獻堂" title="林獻堂">
        <img @click="routeBtn('/person-intro/1')" @keydown.enter="routeBtn('/person-intro/1')" tabindex="0" class="head person-17 vibrate-2" src="../assets/person-intro/head/20230424-入口頁面-物件-08-蔡培火-白.png" alt="蔡培火" title="蔡培火">
        <img @click="routeBtn('/person-intro/13')" @keydown.enter="routeBtn('/person-intro/13')" tabindex="0" class="head person-18 vibrate-3" src="../assets/person-intro/head/20230424-入口頁面-物件-20-陳懷澄-白.png" alt="陳懷澄" title="陳懷澄">
        <img @click="routeBtn('/person-intro/18')" @keydown.enter="routeBtn('/person-intro/18')" tabindex="0" class="head person-19 vibrate-4" src="../assets/person-intro/head/20230424-入口頁面-物件-25-韓石泉-白.png" alt="韓石泉" title="韓石泉">
        <img @click="routeBtn('/person-intro/19')" @keydown.enter="routeBtn('/person-intro/19')" tabindex="0" class="head person-20 vibrate-5" src="../assets/person-intro/head/20230424-入口頁面-物件-26-李應章-白.png" alt="李應章" title="李應章">
        <img @click="routeBtn('/person-intro/22')" @keydown.enter="routeBtn('/person-intro/22')" tabindex="0" class="head person-21 vibrate-1" src="../assets/person-intro/head/20230424-入口頁面-物件-29-楊肇嘉-白.png" alt="楊肇嘉" title="楊肇嘉">
        <img @click="routeBtn('/person-intro/4')" @keydown.enter="routeBtn('/person-intro/4')" tabindex="0" class="head person-22 vibrate-2" src="../assets/person-intro/head/20230424-入口頁面-物件-11-謝雪紅-白.png" alt="謝雪紅" title="謝雪紅">
        <img @click="routeBtn('/person-intro/12')" @keydown.enter="routeBtn('/person-intro/12')" tabindex="0" class="head person-23 vibrate-3" src="../assets/person-intro/head/20230424-入口頁面-物件-19-陳澄波-白.png" alt="陳澄波" title="陳澄波">
        <img @click="routeBtn('/person-intro/5')" @keydown.enter="routeBtn('/person-intro/5')" tabindex="0" class="head person-24 vibrate-4" src="../assets/person-intro/head/20230424-入口頁面-物件-12-簡娥-白.png" alt="簡娥" title="簡娥">
        <img @click="routeBtn('/person-intro/7')" @keydown.enter="routeBtn('/person-intro/7')" tabindex="0" class="head person-25 vibrate-5" src="../assets/person-intro/head/20230424-入口頁面-物件-14-莊松林-白.png" alt="莊松林" title="莊松林">

        <p class="text-1">點選人物插畫，進入人物的介紹。</p>
      </div></transition>

      <transition name="img-frame-fade"><div v-if="page[1]==true" class="content content-2 " >
        <img @click="routeBtn('/person-intro/0')" @keydown.enter="routeBtn('/person-intro/0')" tabindex="0" class="head person-3" src="../assets/person-intro/head/20230424-入口頁面-物件-07-連溫卿-白.png" alt="連溫卿" title="連溫卿">
        <img @click="routeBtn('/person-intro/2')" @keydown.enter="routeBtn('/person-intro/2')" tabindex="0" class="head person-8" src="../assets/person-intro/head/20230424-入口頁面-物件-09-謝文達-白.png" alt="謝文達" title="謝文達">
        <img @click="routeBtn('/person-intro/3')" @keydown.enter="routeBtn('/person-intro/3')" tabindex="0" class="head person-16" src="../assets/person-intro/head/20230424-入口頁面-物件-10-林獻堂-白.png" alt="林獻堂" title="林獻堂">
        <img @click="routeBtn('/person-intro/1')" @keydown.enter="routeBtn('/person-intro/1')" tabindex="0" class="head person-17" src="../assets/person-intro/head/20230424-入口頁面-物件-08-蔡培火-白.png" alt="蔡培火" title="蔡培火">

<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">點選人物插畫，進入人物的介紹。</p>
      </div></transition>

      <transition name="img-frame-fade"><div v-if="page[2]==true" class="content content-3 " >
        <div class="content-text content-style">
          <p>日治初期社會鉅變，各地具有土地基業的家族，仍具有統合地方經濟與社會文化的力量，特別是新一代知識菁英，成為對國際變局有所認知，並抱持著淑世精神的進步份子。</p>
          <p style="margin-top: 0.4vw">地方士紳的支持與投入，可說是文化協會能發展運作的主力，他們出錢出力，作為組織發展的實質後援者，多半以落實自治民權為目標，秉持民族團結的路線。不過，由於行事溫和、瞻前顧後，面臨殖民統治者的壓力，有時會妥協，而常遭激進派的青年知識份子質疑，抨擊說是只關心自身利益的「土地資產階級」。事實上，他們為本土政治運動挺身而出，對自治運動、總體社會利權的實質推動，乃至人權思想的普及，都發揮實質的力量。</p>
        </div>

        <img @click="routeBtn('/person-intro/11')" @keydown.enter="routeBtn('/person-intro/11')" tabindex="0" class="head person-1" src="../assets/person-intro/head/20230424-入口頁面-物件-18-蔡惠如-白.png" alt="蔡惠如" title="蔡惠如">
        <img @click="routeBtn('/person-intro/14')" @keydown.enter="routeBtn('/person-intro/14')" tabindex="0" class="head person-4" src="../assets/person-intro/head/20230424-入口頁面-物件-21-林幼春-白.png" alt="林幼春" title="林幼春">
        <img @click="routeBtn('/person-intro/16')" @keydown.enter="routeBtn('/person-intro/16')" tabindex="0" class="head person-9" src="../assets/person-intro/head/20230424-入口頁面-物件-23-杜香國-白.png" alt="杜香國" title="杜香國">
        <img @click="routeBtn('/person-intro/15')" @keydown.enter="routeBtn('/person-intro/15')" tabindex="0" class="head person-14" src="../assets/person-intro/head/20230424-入口頁面-物件-22-蘇璧輝-白.png" alt="蘇璧輝" title="蘇璧輝">
        <img @click="routeBtn('/person-intro/13')" @keydown.enter="routeBtn('/person-intro/13')" tabindex="0" class="head person-18" src="../assets/person-intro/head/20230424-入口頁面-物件-20-陳懷澄-白.png" alt="陳懷澄" title="陳懷澄">
        <img @click="routeBtn('/person-intro/12')" @keydown.enter="routeBtn('/person-intro/12')" tabindex="0" class="head person-23" src="../assets/person-intro/head/20230424-入口頁面-物件-19-陳澄波-白.png" alt="陳澄波" title="陳澄波">
        <img @click="routeBtn('/person-intro/22')" @keydown.enter="routeBtn('/person-intro/22')" tabindex="0" class="head person-21" src="../assets/person-intro/head/20230424-入口頁面-物件-29-楊肇嘉-白.png" alt="楊肇嘉" title="楊肇嘉">

<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">點選人物插畫，進入人物的介紹。</p>
      </div></transition>

      <transition name="img-frame-fade"><div v-if="page[3]==true" class="content content-4 " >
        <div class="content-text content-style">
          <p>學生青年，是圍繞在文協活動的周圍，參與最熱烈、主張也最激進的一群。文協成立時的一千多名會員中，有超過4分之1是學生，其中又以臺北師範學校最多。1922年臺北師範爆發學潮，警察單位抨擊文協煽動，脅迫學生退會，而後各地以讀書會、研究會為名的學生組織開始出現，集結成小型社群，屢發議論，如同更自由奔放的游擊隊。</p>
          <p style="margin-top: 0.4vw">青年激進活動，反映了當時青年對殖民體制，以及僵化政治環境的無力感。他們常被稱為「無產青年」，有的也以此自居，自承投入開創更好的新世界。只是後續社會運動無以為繼，他們以研究的熱情、孤冷的筆、獨走的創意，投入文學、美術等領域，成為隨後臺灣新文藝運動的先鋒部隊。</p>
        </div>

        <img @click="routeBtn('/person-intro/24')" @keydown.enter="routeBtn('/person-intro/24')" tabindex="0" class="head person-2" src="../assets/person-intro/head/20230424-入口頁面-物件-31-王敏川-白.png" alt="王敏川" title="王敏川">
        <img @click="routeBtn('/person-intro/6')" @keydown.enter="routeBtn('/person-intro/6')" tabindex="0" class="head person-11" src="../assets/person-intro/head/20230424-入口頁面-物件-13-陳植棋-白.png" alt="陳植棋" title="陳植棋">
        <img @click="routeBtn('/person-intro/23')" @keydown.enter="routeBtn('/person-intro/23')" tabindex="0" class="head person-15" src="../assets/person-intro/head/20230424-入口頁面-物件-30-簡吉-白.png" alt="簡吉" title="簡吉">



<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">點選人物插畫，進入人物的介紹。</p>
      </div></transition>

      <transition name="img-frame-fade"><div v-if="page[4]==true" class="content content-5 " >
        <div class="content-text content-style">
          <p>透過就學表現，升學深造而成為醫師、教師，是日治初期臺灣社會階層向上流動的主要管道，於是醫學校與師範學校，可說是當時臺灣本土新知識菁英的培養皿。由於教師身為公務員，而受到官方的嚴格控管，醫師便成為真正投入社會運動的主力社群。</p>
          <p style="margin-top: 0.4vw">基於深入的科學教育，醫師普遍懷抱追尋理想生活方式，加上與底層社會的廣泛接觸，文協裡的醫師們，往往具有理論與行動上的彈性，尤其對社會底層抱持關懷。</p>
          <p style="margin-top: 0.4vw">此外，作為臺灣社會接觸世界文化前鋒的基督教徒，抱持犧牲奉獻的「牧者」精神，也是早期政治運動的主力。他們往往傾向宣傳式的草根組織，傾向合法的抗爭，以爭取平等的政治參與為目標，成為近代草根民主的基本精神。</p>
        </div>

        <img @click="routeBtn('/person-intro/17')" @keydown.enter="routeBtn('/person-intro/17')" tabindex="0" class="head person-10" src="../assets/person-intro/head/20230424-入口頁面-物件-24-蔣渭水-白.png" alt="蔣渭水" title="蔣渭水">
        <img @click="routeBtn('/person-intro/20')" @keydown.enter="routeBtn('/person-intro/20')" tabindex="0" class="head person-12" src="../assets/person-intro/head/20230424-入口頁面-物件-27-賴和-白.png" alt="賴和" title="賴和">
        <img @click="routeBtn('/person-intro/21')" @keydown.enter="routeBtn('/person-intro/21')" tabindex="0" class="head person-13" src="../assets/person-intro/head/20230424-入口頁面-物件-28-林淇漳-白.png" alt="林淇漳" title="林淇漳">
        <img @click="routeBtn('/person-intro/18')" @keydown.enter="routeBtn('/person-intro/18')" tabindex="0" class="head person-19" src="../assets/person-intro/head/20230424-入口頁面-物件-25-韓石泉-白.png" alt="韓石泉" title="韓石泉">
        <img @click="routeBtn('/person-intro/19')" @keydown.enter="routeBtn('/person-intro/19')" tabindex="0" class="head person-20" src="../assets/person-intro/head/20230424-入口頁面-物件-26-李應章-白.png" alt="李應章" title="李應章">

<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">點選人物插畫，進入人物的介紹。</p>
      </div></transition>

      <transition name="img-frame-fade"><div v-if="page[5]==true" class="content content-6 " >
        <div class="content-text content-style">
          <p>性別議題—特別是女權與婚姻問題，在文化協會發展開始便受到廣泛討論。在文協影響下，彰化、嘉義前後成立「彰化婦女共勵會」、「諸羅婦女協進會」，主辦講演會、夜學等活動，曾發生會員相偕私奔脫離家庭的事件，而引發議論。1927年文協改組後成立「婦女部」，嘉義的婦女協進會更改組為「臺灣婦女協進會」，成為第一個全島性的本土婦女團體。</p>
          <p style="margin-top: 0.4vw">文協與左派團體中，特別多傑出的女性社運工作者，反映在世界新知普及的過程裡，知識女青年的崛起。雖然在公眾媒體上，仍常被戲謔地稱為「自由女」、「文明女」、「摩登女郎」，她們精采而活潑的社會表現，直接改變了性別界限，鬆動既定的社會結構，也在每個人的心中不斷展開自我認同的革命。</p>
        </div>

        <img @click="routeBtn('/person-intro/4')" @keydown.enter="routeBtn('/person-intro/4')" tabindex="0" class="head person-22" src="../assets/person-intro/head/20230424-入口頁面-物件-11-謝雪紅-白.png" alt="謝雪紅" title="謝雪紅">
        <img @click="routeBtn('/person-intro/5')" @keydown.enter="routeBtn('/person-intro/5')" tabindex="0" class="head person-24" src="../assets/person-intro/head/20230424-入口頁面-物件-12-簡娥-白.png" alt="簡娥" title="簡娥">


<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">點選人物插畫，進入人物的介紹。</p>
      </div></transition>

      <transition name="img-frame-fade"> <div v-if="page[6]==true" class="content content-7" >
        <div class="content-text content-style">
          <p>文化協會的發起與運作是以知識階層為主，在發展過程中，農民與勞工權益的各種社會議題，逐漸成為文協、民眾黨等組織發展的焦點，也確實發展成龐大的組織陣線。據統計，1928年間臺灣農民組合旗下會員達2萬4千人，而臺灣民眾黨周邊的各職業類別工友會30多個，會員總數也達萬人。</p>
          <p style="margin-top: 0.4vw">由人數來看，參與過如上組織的農民、勞工，可說是文化協會發展的主角，不過在回顧近代歷史時，卻僅留下模糊不清的剪影。這些剪影卻提醒我們，在殖民統治的高壓下，曾經有那麼一群奮起合作的勞農大眾，不只敬業的工作，也試圖去面對結構性的問題，團結衝撞這個不公義的體制，改善自己的處境，讓這個世界更美好。</p>
        </div>

        <img @click="routeBtn('/person-intro/9')" class="head person-5" src="../assets/person-intro/head/20230424-入口頁面-物件-16-王詩琅-白.png" alt="王詩琅" title="王詩琅">
        <img @click="routeBtn('/person-intro/10')" class="head person-6" src="../assets/person-intro/head/20230424-入口頁面-物件-17-蔡淵騰-白.png" alt="蔡淵騰" title="蔡淵騰">
        <img @click="routeBtn('/person-intro/7')" class="head person-25" src="../assets/person-intro/head/20230424-入口頁面-物件-14-莊松林-白.png" alt="莊松林" title="莊松林">
        <img @click="routeBtn('/person-intro/8')" class="head person-7" src="../assets/person-intro/head/20230424-入口頁面-物件-15-盧丙丁-白.png" alt="盧丙丁" title="盧丙丁">

<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">點選人物插畫，進入人物的介紹。</p>
      </div></transition>

    </div>

    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import FooterFeature from "@/components/FooterFeature";
import {reactive} from "vue";

export default {
  name: "PersonIntroAllView",
  components:{
    toolbar,
    FooterFeature,
  },
  setup(){
    const page = reactive(Array.from({ length: 7 }, () => false));
    page[0] = true;

    function changePage(pageIndex){
      for (let i=0; i<page.length; i++)
        page[i] = false;
      page[pageIndex] = true;
    }


    function routeBtn(path){
      router.push(path);
    }

    return{
      routeBtn,
      page,
      changePage
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/personintro-all.css"></style>
