<template>
  <div class="wrap" @keydown.esc="escEvent">
    <Entoolbar type="2" unit="3" route-name="unit4-1" color="brown"></Entoolbar>

<!--    <img class="sample" src="../assets/Unit4-1/en/20230708-EN網站-對位-4-1-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="title-logo">Monument of Time</div>
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-1.json')" alt="Monument of Time" title="Monument of Time" ></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit4-1/20230415-4-1-物件-01-首圖１.png" alt="Monument of Time" title="Monument of Time">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-0.json')" alt="Monument of Time" title="Monument of Time" ></LottieTest>
      </div>
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit4-1/en/20230708-EN網站-4-1-03-裝飾字1-時鐘塔.png" alt="Monument of Time" title="Monument of Time">
      <div class="content content-style">
        <p>Near Longshan Temple Square in Taipei’s Wanhua District, a stone stele erected in December 1927 bears four Chinese characters which can be translated as “Being Strict with Time.” The Chinese is accompanied by Esperanto text.</p>
        <p style="margin-top: 1.6vw">This monument was donated by the family of local businessman Su Bi-hui. In the year it was carved, the Taiwan Cultural Association implemented a program called “Popularize the Culture of the Masses,” which included “Rewards for Being Punctual.” The stele, a reminder of good habits, can be seen as an attempt to reform traditional concepts in local society and establish a “beachhead” for a new era.</p>
      </div>
      <img class="img-1" src="../assets/Unit4-1/20230415-4-1-物件-04-插畫１.png" alt="Monument of Time" title="Monument of Time">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-2.json')" alt="TEMPO ESTAS MONO." title="TEMPO ESTAS MONO."></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <div class="content-1">
        <p class="title-style" style="color: #267c68; width: auto" >Meet the Monument of Time</p>
        <p class="content-style" style="margin-top: 0.5vw; height: 15vw; overflow: auto">A stone stele outside Wanhua Longshan Temple had long been concealed by vegetation. The research team accidentally saw a discussion around the inscription on the monument, from there tey found the relation of this monument and the Cultural Association. With the temple's authorization, on-site surveying and historical data analysis were conducted, we had an illustration of the monument in its original appearance circa 1927. At that time, it served as a spiritual beacon for the Taiwan Cultural Association in their pursuit of the "Battle for Time and Space."</p>
      </div>

      <div class="marquee">
        <vue3-marquee class="h-full">
          <img class="h-full" src="../assets/Unit4-1/20230415-4-1-物件-05-插畫2.png" alt="TEMPO ESTAS MONO" title="TEMPO ESTAS MONO">
        </vue3-marquee>
      </div>

    </div>

    <div class="part4 part-color">
      <img class="img-1" src="../assets/Unit4-1/20230415-4-1-物件-07-插畫3.png" alt="Monument of Time" title="Monument of Time">
      <img class="img-2" src="../assets/Unit4-1/20230415-4-1-物件-08-pic2.png" alt="Monument of Time" title="Monument of Time">
      <img class="img-3" src="../assets/Unit4-1/20230415-4-1-物件-09-pic3.png" alt="Monument of Time" title="Monument of Time">
      <div class="content-1">
        <p class="title-style" style="color: #267c68">「TEMPO ESTAS MONO」</p>
        <p class="source-style" style="margin-top: 0.3vw; margin-left: 1.2vw">Courtesy of Zhou Jun-wei</p>
        <p class="content-style" style="margin-top: 0.5vw; margin-left: 1.2vw">The stone tower is inscribed with Chinese characters meaning “Being Strict with Time”and "TEMPO ESTAS MONO," which translates to "time is money" in Esperanto. In 1970, the tower underwent restoration ,the Japanese era name was changed to the Republic of China era, and was relocated to the right side of the temple plaza's pond as part of the garden landscape. As time passed, it became hidden by vegetation and slowly faded from memory.</p>
      </div>
    </div>

    <div class="part5 part-color">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-3.json')" alt="Su Bi-hui" title="Su Bi-hui"></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit4-1/20230415-4-1-物件-10-pic4.png" alt="Su Bi-hui" title="Su Bi-hui">
      <div class="img-2">
        <img class="w-full" src="../assets/Unit4-1/20230415-4-1-物件-11-pic5.png" alt="Su family at the clock tower restoration ceremony, a keepsake from 1970." title="Su family at the clock tower restoration ceremony, a keepsake from 1970.">
        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #267c68">We Found Su Bi-hui</p>
        <p class="source-style" style="margin-top: 0.3vw; font-size: 0.85vw">Courtesy of Zheng Hui-mei</p>
        <p class="content-style" style="margin-top: 0.5vw;">Su Bi-hui, donor of the monument, was a local merchant in Wanhua, a Taiwan Cultural Association board member, and an advocate for Esperanto. While collecting data, the research team couldn’t find any detail of Su Bi-hui. However, an online memorial article about Su Bi-hui was eventually discovered, and lead the team to the Su family.</p>
        <p class="content-style" >Su Bi-hui was doing business in Xiamen in 1937. He was involved in the conflict of China and Japan, leading to his death in 1937 at the hands of the Nationalist government.</p>
        <a class="blue-btn" style="margin-top: 1.2vw" href="http://www.eastgate.org.tw/f2/index.php?load=read&id=1169" target="_blank" tabindex="0" alt="The story of Su Bi-hui(open in a new window)" title="The story of Su Bi-hui(open in a new window)">The story of Su Bi-hui</a>
      </div>
    </div>

    <div class="part6 part-color">
<!--      <img class="sample" src="../assets/Unit4-1/sample/20230703-對位-4-1-op-03.png">-->

      <img class="center-clock" src="../assets/Unit4-1/20230415-4-1-物件-14-pic6.png" alt="The Mystery of the Statue Atop the Monument" title="The Mystery of the Statue Atop the Monument">
      <div class="content-1">
        <p class="title-style" style="color: #267c68">The Mystery of the</p>
        <p class="title-style" style="color: #267c68">Statue Atop the Monument</p>
        <p class="content-style" style="margin-top: 1vw;">During the reconstruction process, there were many unresolved details, such as the statue that once sat atop the stele. In 1927, when the stele was first erected, it featured a winged statue on top. However, in the 1970s, when the stele was moved next to the temple plaza's pond, the winged statue was replaced with a dragon, and the area where the clock once stood was filled in. As a result, the current Wanhua Longshan Temple Clock Tower no longer has a clock.</p>
        <p class="content-style" style="margin-top: 1vw;">What exactly adorned the top of the tower when it was first built? Was it a bird, a phoenix, or perhaps a deity? To this day, it remains a mystery. What do you think the original decoration depicted? </p>

<!--        <div class="option-div content-style">-->
<!--          <div class="option"><div></div><div style="margin-left: 0.8vw">鳥</div><div class="bar" style="width: 3vw"><div>30%</div></div></div>-->
<!--          <div class="option"><div></div><div style="margin-left: 0.8vw">龍</div><div class="bar" style="width: 21.5vw"><div>100%</div></div></div>-->
<!--          <div class="option"><div></div><div style="margin-left: 0.8vw">鳳凰</div><div class="bar" style="width: 14.2vw;margin-left: 2vw"><div>40%</div></div></div>-->
<!--          <div class="option"><div></div><div style="margin-left: 0.8vw">某神像</div><div class="bar" style="width: 14.2vw;margin-left: 1vw"><div>55%</div></div></div>-->
<!--          <div class="blue-btn">我選好了</div>-->
<!--          <div class="blue-btn hidden">看大家的想像</div>-->
<!--        </div>-->
      </div>

      <img class="ani-img " :class="aniImg[0]" style="width: 6.4vw; top: 28.2%; left: 22.9%;" src="../assets/Unit4-1/20230415-4-1-物件-22-鳥插畫４.png" alt="Bird illustration" title="Bird illustration">
      <img class="ani-img " :class="aniImg[1]" style="width: 6.5vw; top: 28.9%; left: 23%;" src="../assets/Unit4-1/20230415-4-1-物件-23-龍插畫５.png" alt="Dragon illustration" title="Dragon illustration">
      <img class="ani-img " :class="aniImg[2]" style="width: 6.4vw; top: 28.2%; left: 22.9%;" src="../assets/Unit4-1/20230415-4-1-物件-25-某個神像插畫７.png" alt="Illustration of a deity" title="Illustration of a deity">
      <img class="ani-img " :class="aniImg[3]" style="width: 6.4vw; top: 28.2%; left: 22.9%;" src="../assets/Unit4-1/20230415-4-1-物件-24-鳳凰插畫６.png" alt="Phoenix illustration" title="Phoenix illustration">

      <div class="circle-name" :style="circleStyle[0]">{{circleName}}</div>
      <div class="tip content-style">Click on the dots to see the statue.</div>
      <div class="circle-div" :style="circleStyle[1]">
        <img class="relative w-full" src="../assets/Unit4-1/20230415-4-1-物件-15-大圓線-01.png">
          <div class="small-circle" :class="circleSelect[0]" style="top: 48%; left: -1.5%" @click="circleBtn(0)" @keydown.enter="circleBtn(0)" tabindex="0" alt="Click on the dots to see the statue." title="Click on the dots to see the statue."></div>
          <div class="small-circle" :class="circleSelect[1]" style="top: -1.5%; left: 48%" @click="circleBtn(1)" @keydown.enter="circleBtn(1)" tabindex="0" alt="Click on the dots to see the statue." title="Click on the dots to see the statue."></div>
        <div class="small-circle" :class="circleSelect[2]" style="top: 48%; right: -1.5%" @click="circleBtn(2)" @keydown.enter="circleBtn(2)" tabindex="0" alt="Click on the dots to see the statue." title="Click on the dots to see the statue."></div>
        <div class="small-circle" :class="circleSelect[3]" style="bottom: -1.5%; left: 48%" @click="circleBtn(3)" @keydown.enter="circleBtn(3)" tabindex="0" alt="Click on the dots to see the statue." title="Click on the dots to see the statue."></div>
      </div>
    </div>

    <div class="part7 part-color">
      <img class="img-1" src="../assets/Unit4-1/en/20230708-EN網站-4-1-30-裝飾字2-記憶之塔.png" alt="Monument of Memory" title="Monument of Memory">
      <div class="content-1 content-style">The Monument stood in Longshan Temple's plaza for over 30 years, adding a touch of modernity to the site. The clock tower was featured in paintings by Chen Cheng-po and, 80 years later, in the work of amateur painter Xie Zhaozhi, symbolizing the dawn of a new era for the Taiwan Cultural Association.</div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit4-1/20230415-4-1-物件-31-pic7.png" alt="Image of Chen Cheng-po’s Oil Painting Longshan Temple" title="Image of Chen Cheng-po’s Oil Painting Longshan Temple">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #ffffff">Missing Oil Painting</p>
        <p class="title-style" style="color: #ffffff">Longshan Temple</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Image of Chen Cheng-po’s Oil Painting Longshan Temple</p>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of the Chen Cheng-po Cultural Foundation</p>
        <p class="content-style" style="margin-top: 0.5vw;">In 1928, Chen Cheng-po created an on-site oil painting titled Longshan Temple, commissioned by a group of local Mengjia residents. Upon completion, the painting was selected for the Second Taiwan Fine Arts Exhibition and received a special award. Later, it was displayed alongside Huang Tu-shui's work Shakyamuni at the Longshan Temple venue. The whereabouts of the original painting are currently unknown, but an art catalog from that time shows the clock tower at the center of the painting.</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-4.json')" alt="Chen Cheng-po" title="Chen Cheng-po"></LottieTest>
      </div>
    </div>

    <div class="part8 part-color">
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-1/20230415-4-1-物件-32-pic8.png" alt="Morning at the Temple Entrance" title="Morning at the Temple Entrance">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 5%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #ffffff">Morning at the Temple Entrance</p>
        <p class="title-style" style="color: #ffffff">by Hsieh Chao-chih</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Morning at the Temple Entrance</p>
        <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan History 2017.022.0002</p>
        <p class="content-style" style="margin-top: 0.5vw;">Frequent visitors to the Taiwan History Museum are likely familiar with “Grandma” Hsieh Chao-chih. Born in 1929 near Mengjia Longshan Temple, she began painting at 65, using her brush to chronicle her childhood memories of Taiwan. Morning at the Temple Entrance depicts the area as she remembers circa 1940. At that time, her father had a stall selling fried dough sticks near the temple, and she would often wake up at four or five in the morning to help before leaving for school at seven o'clock. Hsieh recalled, "As a child, I would always argue with my father whenever it was time to leave, so I wouldn’t be late to school."
          She recalls that at six in the morning, the radio mounted on the temple gate would play music for morning exercises. People would gather in front of the temple to participate in the activities.</p>
        <p class="content-style" style="margin-top: 0.5vw; width: 40%">Breakfast vendors would also begin setting up their stalls. In her memory, the clock tower wasn't towering or imposing. In the painting, it features a simple design and serves as a welcoming focal point.</p>
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-5.json')" ></LottieTest>
      </div>

      <div class="back-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-white.png" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
        <p style="margin-left: 0.5vw">Back</p>
      </div>
    </div>

    <img class="clock" src="../assets/Unit4-1/20230415-4-1-物件-06-pic1.png" alt="Monument of Time" title="Monument of Time" :style="clockStyle">


    <!--寫這裡-->
    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--            <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-1/sample/20230703-對位-4-1-op-07.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:2.8vw 0 0 15vw" src="../assets/Unit4-1/20230703-4-1-op-11-pic5-放大文物.png" alt="Su family at the clock tower restoration ceremony, a keepsake from 1970." title="Su family at the clock tower restoration ceremony, a keepsake from 1970.">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title" >Su family at the clock tower restoration ceremony, a keepsake from 1970.</div>
              <div class="noto-text" >Courtesy of Zheng Hui-mei </div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--     <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-02.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:2.8vw 0 0 15vw" src="../assets/Unit4-1/20230703-4-1-op-31-pic7-放大文物.png" alt="Image of Chen Cheng-po’s Oil Painting Longshan Temple" title="Image of Chen Cheng-po’s Oil Painting Longshan Temple">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title" >Image of Chen Cheng-po’s Oil Painting Longshan Temple</div>
              <div class="noto-text" >Courtesy of the Chen Cheng-po Cultural Foundation</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--     <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-02.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:2.8vw 0 0 15vw" src="../assets/Unit4-1/20230703-4-1-op-32-pic8-放大文物.png" alt="Morning at the Temple Entrance" title="Morning at the Temple Entrance">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Morning at the Temple Entrance</div>
              <div class="noto-text" >National Museum of Taiwan History 2017.022.0002 </div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>


    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language="en" color="black"></FooterFeature>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn";
import FooterFeature from "@/components/FooterFeature";
import LottieTest from "@/components/LottieTest";
import {Vue3Marquee} from "vue3-marquee";
import 'vue3-marquee/dist/style.css'
import {effect, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import myMixin from "@/assets/common/js/mixin";
export default {
  name: "Unit4-1",
  mixins: [myMixin],
  components:{
    Entoolbar,
    EnIntroBtn,
    FooterFeature,
    LottieTest,
    Vue3Marquee
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));
    const clockStyle = ref('');
    const circleName = ref('');
    const circleSelect = reactive(Array.from({ length: 4 }, () => ''));
    const circleStyle = reactive(Array.from({ length: 2 }, () => ''));
    const aniImg = reactive(Array.from({ length: 4 }, () => ''));

    onMounted(()=>{
      window.addEventListener('scroll', handleScroll);


      aniImg[1] = "ani-img-in";
      circleSelect[1] = "selected";
      circleStyle[0] = 'opacity:1';
      circleName.value = "Dragon";

      setAnimInterval();
    });

    onUnmounted(()=>{
      clearAnimInterval();
    });

    function routeBtn(path){
      router.push(path);
    }

    let top = 106;
    let left = 40.1;
    let rotate = 0;
    let scale = 1;

    function  handleScroll() {
      const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; // 获取窗口高度
      const fullHeight = document.documentElement.scrollHeight || document.body.scrollHeight; // 获取页面总高度
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // 获取滚动距离
      let scrollPercentage = (scrollTop / (fullHeight - windowHeight)) * 100; // 计算滚动页面百分比

      let percent = 0;
      /*Rot*/
      if(scrollPercentage >= 30 && scrollPercentage <= 38){
        percent = (scrollPercentage - 30)/(38-30);
        rotate = (0+((30-0)*percent));
        scale = (1-((1-0.9)*percent));
      }else  if(scrollPercentage >= 38 && scrollPercentage <= 42){
        percent = (scrollPercentage - 38)/(42-38);
        rotate = (30-((30-0)*percent));
        scale = (0.9-((0.9-0.65)*percent));
      }else  if(scrollPercentage >= 50 && scrollPercentage <= 57){
        percent = (scrollPercentage - 50)/(57-50);
        rotate = (0+((0-12)*percent));
        scale = (0.65+((0.68-0.65)*percent));
      }

      /*Move*/
      if(scrollPercentage >= 30 && scrollPercentage <= 42){
        percent = (scrollPercentage - 30)/(42-30);
        top = (106+((165-106)*percent));
        left = (40.1-((40.1-9.1)*percent));
        clockStyle.value = 'top:'+ top + "vw; left:" + left +"vw; transform:rotate("+ rotate+"deg) scale("+scale + ")";
      } else if(scrollPercentage >= 50 && scrollPercentage <= 57){
        percent = (scrollPercentage - 50)/(57-50);
        top = (165+((199.7-165)*percent));
        left = (9.1+((20.4-9.1)*percent));
        clockStyle.value = 'top:'+ top + "vw; left:" + left +"vw; transform:rotate("+ rotate+"deg) scale("+scale + ")";
      }

      //console.log(scrollPercentage)
    }

    let preAniImg = 1;
    let nextDeg = 0;
    let nextName = '';

    function circleBtn(circleNum){
      clearAnimInterval();


      for (let i=0; i<4; i++){
        circleSelect[i] = "";
      }


      circleStyle[0] = 'opacity:0; ';
      setTimeout(()=>{
        circleStyle[0] = 'opacity:1';
        circleName.value = nextName;
        // if(nextDeg == -360){
        //   circleStyle[1] = "transform: rotate("+0+"deg);";
        // }
        setAnimInterval();
      },3000);

      if(preAniImg != -1){
        aniImg[preAniImg] = "ani-img-out";
        //preAniImg = -1;
      }

      switch (circleNum){
        case 0:
          nextName = "Bird";
          circleSelect[0] = "selected";
          aniImg[0] = "ani-img-in";
          preAniImg = 0;
          nextDeg = -270;
          break;
        case 1:
          nextName = "Dragon";
          circleSelect[1] = "selected";
          aniImg[1] = "ani-img-in";
          preAniImg = 1;
          nextDeg = 0;
          break;
        case 2:
          nextName = "A Deity";
          circleSelect[2] = "selected";
          aniImg[2] = "ani-img-in";
          preAniImg = 2;
          nextDeg = -90;
          break;
        case 3:
          nextName = "Phoenix";
          circleSelect[3] = "selected";
          aniImg[3] = "ani-img-in";
          preAniImg = 3;
          nextDeg = -180;
          break;

      }

      //circleStyle[1] = "transform: rotate("+nextDeg+"deg);";
      targetAngle.value = nextDeg;
      updateRotation(previousAngle.value, targetAngle.value);
    }

    const rotationAngle = ref(0);
    const previousAngle = ref(0); // 上一次的旋转角度
    const targetAngle = ref(-90); // 目标逆时针旋转的角度
    const animationDuration = 3000; // 动画持续时间，单位为毫秒

    let animInterval = null;

    function clearAnimInterval(){
      clearInterval(animInterval);
      animInterval = null;
    }

    function setAnimInterval(){

      clearAnimInterval();

      if(animInterval == null){
        animInterval  = setTimeout(()=>{
          let nextBtn = (preAniImg+1)%4;
          circleBtn(nextBtn);
        }, 5000);
      }
    }


    const updateRotation = (startAngle, endAngle) => {
      const startTime = performance.now();
      const clockwise = startAngle < endAngle; // 判断是否为顺时针旋转

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / animationDuration, 1);
        let angle = lerp(startAngle, endAngle, progress, clockwise);

        rotationAngle.value = angle;
        circleStyle[1] = "transform: rotate("+angle+"deg);";
        previousAngle.value = angle;

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    };

    const lerp = (start, end, amount, clockwise) => {
      if (clockwise) {
        // 如果为顺时针旋转，则取反角度差，以确保逆时针旋转
        end -= 360;
      }

      return (1 - amount) * start + amount * end;
    };

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      clockStyle,
      circleBtn,
      circleName,
      circleSelect,
      circleStyle,
      aniImg,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit4-1.css"></style>
