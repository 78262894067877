<template>
  <div class="wrap" @keydown.esc="escEvent">
<!--    <img class="sample" src="../assets/Unit3-3/sample/20230710-對位-3-3-op-21.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="text-content">
      <p class="title-style" style="color: #4e241f">模擬選舉</p>
      <p class="content-style" style="margin-top: 1vw">1930年11月間《臺灣新民報》發起模擬選舉，由《臺灣新民報》將投票用紙印於報紙上，訂戶需剪下填寫並寄回報社，投票期間共計兩個月，總計收到約 19 萬 1 千張選票，1931年1月17日公告計票結果，當選的模擬州、市議員共400 多位。</p>
      <p class="content-style">當時分裂成兩派的民眾黨與地方自治同盟，都積極投入參選宣傳，報上密集報導各區的領先候選人及票數，可見當時熱烈程度。不過這時的文化協會，卻是站在反對的立場，覺得這是報紙與資本家的宣傳把戲。</p>
    </div>

    <div class="img-content" >
      <img class="w-full" src="../assets/Unit3-3/20230710--3-3-物件-36-插圖５.png" @load="imgLoad">


      <div class="title-style subtitle" style=" top: 0.7%; left: 5.4%;">第一階段 發起</div>
      <div class="title-style subtitle" style=" top: 18.2%; left: 51.5%;">第二階段 剪下選票</div>
      <div class="title-style subtitle" style=" top: 37.7%; left: 5.4%;">第三階段 預測</div>
      <div class="title-style subtitle" style=" top: 58.2%; left: 51.5%;">第四階段 當選</div>



      <img class="img-1" src="../assets/Unit3-3/20230710--3-3-物件-37-插圖6.png" alt="第一階段 發起" title="第一階段 發起">
      <div class="json-1" :class="imgClass[0]">
        <LottieTest :json="require('@/assets/Unit3-3/20230710-3-3-6.json')" alt="第一階段 發起" title="第一階段 發起"></LottieTest>
      </div>
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit3-3/20230710-3-3-7.json')" alt="第二階段 剪下選票" title="第二階段 剪下選票"></LottieTest>
      </div>
      <div class="json-3" :ref="imgRef[1]">
        <LottieTest :json="require('@/assets/Unit3-3/20230720-3-3-8.json')" alt="第三階段 預測" title="第三階段 預測" ></LottieTest>
      </div>
      <div class="json-4" :ref="imgRef[2]">
        <LottieTest :json="require('@/assets/Unit3-3/20230720-3-3-9.json')" alt="第四階段 當選" title="第四階段 當選"></LottieTest>
      </div>

      <div class="stage-2-text-1">郵寄回報社</div>
      <div class="stage-2-text-2">投於備置投票箱</div>

      <div :class="img2Div"><img class="img-2" src="../assets/Unit3-3/20230710--3-3-物件-38-插圖7.png" :class="imgClass[1]" alt="第二階段 剪下選票" title="第二階段 剪下選票"></div>
      <img class="img-3" src="../assets/Unit3-3/20230710--3-3-物件-38-插圖7.png" :class="imgClass[2]" alt="第二階段 剪下選票" title="第二階段 剪下選票">
      <img class="img-5" src="../assets/Unit3-3/43-剪報紙動態/20230412-3-3-物件動態-43-剪報紙動態-op.gif" :ref="imgRef[0]" alt="第二階段 剪下選票" title="第二階段 剪下選票">

      <img class="img-4" src="../assets/Unit3-3/20230710--3-3-物件-39-pic12.png" alt="批評模擬選舉宣傳單" title="批評模擬選舉宣傳單">
      <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2vw; bottom: 5%; right: 54%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      <div class="content-1">
        <p class="subtitle-style" style="margin-top: 0.5vw">批評模擬選舉宣傳單</p>
        <p class="source-style" style="margin-top: 0.3vw">莊明正 提供</p>
        <p class="content-style" style="margin-top: 0.5vw;">臺灣興文齋印行的傳單，攻擊新民報是民眾黨、自治聯盟的機關報，批評「模擬選舉」是為推廣報紙的營利目的活動，只是假造民意的豪紳遊戲。</p>
      </div>

      <div class="back-btn" @click="routerBtn('/unit3-3')" @keydown.enter="routerBtn('/unit3-3')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="回言論機關" title="回言論機關">
        <p style="margin-left: 0.5vw">回言論機關</p>
      </div>
    </div>
    <!--img12--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 28.4vw; margin:1.5vw 0 0 10.5vw" src="../assets/Unit3-3/20230720-3-3-物件-67-pic11.png" alt="批評模擬選舉宣傳單" title="批評模擬選舉宣傳單">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 26vw; width: auto;">
            <div >
              <div class="bold-title">批評模擬選舉宣傳單</div>
              <div class="noto-text" style="margin-top: 0.4vw">莊明正 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <img class="window-close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" @click="routerBtn('/unit3-3')" @keydown.enter="routerBtn('/unit3-3')" tabindex="0" alt="關閉" title="關閉">
  </div>
</template>

<script>
import router from "@/router";
import LottieTest from "@/components/LottieTest";
import {onMounted, reactive, ref} from "vue";
import myMixin from "@/assets/common/js/mixin";

export default {
  name: "Unit3-3-more",
  mixins: [myMixin],
  components:{
    LottieTest
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 12 }, () => false));
    const pageLoaded = ref(false);
    const imgRef = reactive(Array.from({ length: 3 }, () => ref(null)));
    const imgClass = reactive(Array.from({ length: 3 }, () => ''));
    const img2Div = ref('');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if(entry.target == imgRef[0].value){
              imgClass[0] = 'json-1-move';
          }
          else if(entry.target == imgRef[1].value && imgClass[1]==''){
            imgClass[1] = 'img-2-move';
          }
          else if(entry.target == imgRef[2].value){
            img2Div.value = 'fade-out';
            imgClass[2] = 'img-3-move';
          }
        }
      });
    }, {
      root: null, // 觀察器根節點，null 表示整個瀏覽器視窗
      threshold: 0.5 // 設定觸發 callback 的交叉比例，0 表示完全不交叉，1 表示完全交叉
    });


    function routerBtn(path){
      router.push(path);
    }

    function imgLoad(){
      pageLoaded.value = true;
      observer.observe(imgRef[0].value);
      observer.observe(imgRef[1].value);
      observer.observe(imgRef[2].value);


    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routerBtn,
      imgRef,
      imgClass,
      imgLoad,
      img2Div,
      showImg,
      pageLoaded,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit3-3-more.css"></style>
