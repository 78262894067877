<template>
  <div class="wrap">

    <img class="sample" src="../assets/Unit2-1/sample2.png" @click="routeBtn('Unit2-1')">


  </div>
</template>

<script>
import router from "@/router";
import {ref} from "vue";

export default {
  name: "Unit2-1-game",
  components:{
  },
  setup(){

    const pic1 = ref(false);

    function routeBtn(path){
      router.push(path);
    }

    return{
      routeBtn,
      pic1
    }
  }
}
</script>

<style scoped>

.wrap{
  position: relative;
  width: 100%;
}

.sample{
  position: relative;
  width: 100%;
}

.test-btn{
  position: absolute;
  //background: black;
  width: 30vw;
  height: 18vw;
  bottom: 4%;
  right: 14%;
  cursor: pointer;
}


</style>
