import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import VueGtag from 'vue-gtag';

// window.visitorPath = "http://122.117.191.93:6988/national-museum-website/";
window.visitorPath = "https://tca100.nmth.gov.tw/";
//window.contactPath = "http://122.117.191.93:6988/national-museum-website/";
window.contactPath = "https://tca100.nmth.gov.tw/";
window.firstOpen = true;




createApp(App)
    .use(store)
    .use(router)
    .use(VueGtag, {
        config: { id: 'G-NWQQ8XKHM4'}
    }, router)
    .mount('#app');
