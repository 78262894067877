<template>
  <div class="wrap w-full" @keydown.esc="escEvent">
    <en-toolbar type="2" unit="5" route-name="person-intro" color="brown" style="background: #FFAB35"></en-toolbar>

<!--    <img style="position: absolute; top: 0; width: 100%; opacity: 0.4; z-index: 10" src="../assets/person-intro/sample/en/20230712-EN網站-對位-人物介紹-op-01-連溫卿.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="top-bar">
      <img v-if="headPage>0" @click="leftHeadBtn" @keydown.enter="leftHeadBtn" tabindex="0" class="arrow left-arrow" src="../assets/common/left-arrow.png" alt="previous page" title="previous page">
      <div class="content " v-if="headPage==0">
        <div class="person person-1" @click="changePerson(0)" @keydown.enter="changePerson(0)" tabindex="0"><img class="w-full" :class="personArr[0]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-07-連溫卿-白.png" alt="Lien Wen-chin" title="Lien Wen-chin"><p>Lien Wen-ching</p></div>
        <div class="person person-2" @click="changePerson(1)" @keydown.enter="changePerson(1)" tabindex="0"><img class="w-full" :class="personArr[1]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-08-蔡培火-白.png" alt="Tsai Pei-huo" title="Tsai Pei-huo"><p>Tsai Pei-huo</p></div>
        <div class="person person-3" @click="changePerson(2)" @keydown.enter="changePerson(2)" tabindex="0"><img class="w-full" :class="personArr[2]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-09-謝文達-白.png" alt="Hsieh Wen-ta" title="Hsieh Wen-ta"><p>Hsieh Wen-ta</p></div>
        <div class="person person-4" @click="changePerson(3)" @keydown.enter="changePerson(3)" tabindex="0"><img class="w-full" :class="personArr[3]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-10-林獻堂-白.png" alt="Lin Hsien-tang" title="Lin Hsien-tang"><p>Lin Hsien-tang</p></div>
        <div class="person person-5" @click="changePerson(4)" @keydown.enter="changePerson(4)" tabindex="0"><img class="w-full" :class="personArr[4]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-11-謝雪紅-白.png" alt="Hsieh Hsueh-hung" title="Hsieh Hsueh-hung"><p>Hsieh Hsueh-hung</p></div>
        <div class="person person-6" @click="changePerson(5)" @keydown.enter="changePerson(5)" tabindex="0"><img class="w-full" :class="personArr[5]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-12-簡娥-白.png" alt="Jian E" title="Jian E"><p>Jian E</p></div>
        <div class="person person-7" @click="changePerson(6)" @keydown.enter="changePerson(6)" tabindex="0"><img class="w-full" :class="personArr[6]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-13-陳植棋-白.png" alt="Chen Zhiqi" title="Chen Zhiqi"><p>Chen Zhiqi</p></div>
        <div class="person person-8" @click="changePerson(7)" @keydown.enter="changePerson(7)" tabindex="0"><img class="w-full" :class="personArr[7]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-14-莊松林-白.png" alt="Chuang Sung-lin" title="Chuang Sung-lin"><p>Chuang Sung-lin</p></div>
        <div class="person person-9" @click="changePerson(8)" @keydown.enter="changePerson(8)" tabindex="0"><img class="w-full" :class="personArr[8]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-15-盧丙丁-白.png" alt="Lu Bing-ding" title="Lu Bing-ding"><p>Lu Bing-ding</p></div>
      </div>
      <div class="content" v-if="headPage==1">
        <div class="person person-10" @click="changePerson(9)" @keydown.enter="changePerson(9)" tabindex="0"><img class="w-full" :class="personArr[9]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-16-王詩琅-白.png" alt="Wang Shi-lang" title="Wang Shi-lang"><p>Wang Shi-lang</p></div>
        <div class="person person-11" @click="changePerson(10)" @keydown.enter="changePerson(10)" tabindex="0"><img class="w-full" :class="personArr[10]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-17-蔡淵騰-白.png" alt="Tsai Yuan-tang" title="Tsai Yuan-tang"><p>Tsai Yuan-tang</p></div>
        <div class="person person-12" @click="changePerson(11)" @keydown.enter="changePerson(11)" tabindex="0"><img class="w-full" :class="personArr[11]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-18-蔡惠如-白.png" alt="Tsai Hui-ju" title="Tsai Hui-ju"><p>Tsai Hui-ju</p></div>
        <div class="person person-13" @click="changePerson(12)" @keydown.enter="changePerson(12)" tabindex="0"><img class="w-full" :class="personArr[12]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-19-陳澄波-白.png" alt="Chen Cheng-po" title="Chen Cheng-po"><p>Chen Cheng-po</p></div>
        <div class="person person-14" @click="changePerson(13)" @keydown.enter="changePerson(13)" tabindex="0"><img class="w-full" :class="personArr[13]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-20-陳懷澄-白.png" alt="Chen Huai-cheng" title="Chen Huai-cheng"><p>Chen Huai-cheng</p></div>
        <div class="person person-15" @click="changePerson(14)" @keydown.enter="changePerson(14)" tabindex="0"><img class="w-full" :class="personArr[14]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-21-林幼春-白.png" alt="Lin You-chun" title="Lin You-chun"><p>Lin You-chun</p></div>
        <div class="person person-16" @click="changePerson(15)" @keydown.enter="changePerson(15)" tabindex="0"><img class="w-full" :class="personArr[15]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-22-蘇璧輝-白.png" alt="Su Bi-hui" title="Su Bi-hui"><p>Su Bi-hui</p></div>
        <div class="person person-17" @click="changePerson(16)" @keydown.enter="changePerson(16)" tabindex="0"><img class="w-full" :class="personArr[16]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-23-杜香國-白.png" alt="Du Xiang-gu" title="Du Xiang-gu"><p>Du Xiang-guo</p></div>
        <div class="person person-18" @click="changePerson(17)" @keydown.enter="changePerson(17)" tabindex="0"><img class="w-full" :class="personArr[17]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-24-蔣渭水-白.png" alt="Chiang Wei-shui" title="Chiang Wei-shui"><p>Chiang Wei-shui</p></div>
      </div>
      <div class="content" v-if="headPage==2">
        <div class="person person-19" @click="changePerson(18)" @keydown.enter="changePerson(18)" tabindex="0"><img class="w-full" :class="personArr[18]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-25-韓石泉-白.png" alt="Han Shi-quan" title="Han Shi-quan"><p>Han Shi-quan</p></div>
        <div class="person person-20" @click="changePerson(19)" @keydown.enter="changePerson(19)" tabindex="0"><img class="w-full" :class="personArr[19]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-26-李應章-白.png" alt="Li Ying-zhang" title="Li Ying-zhang"><p>Li Ying-zhang</p></div>
        <div class="person person-21" @click="changePerson(20)" @keydown.enter="changePerson(20)" tabindex="0"><img class="w-full" :class="personArr[20]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-27-賴和-白.png" alt="Loa Ho" title="Loa Ho"><p>Loa Ho</p></div>
        <div class="person person-22" @click="changePerson(21)" @keydown.enter="changePerson(21)" tabindex="0"><img class="w-full" :class="personArr[21]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-28-林淇漳-白.png" alt="Lin Qi-zhang" title="Lin Qi-zhang"><p>Lin Qi-zhang</p></div>
        <div class="person person-23" @click="changePerson(22)" @keydown.enter="changePerson(22)" tabindex="0"><img class="w-full" :class="personArr[22]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-29-楊肇嘉-白.png" alt="Yang Zhao-jia" title="Yang Zhao-jia"><p>Yang Zhao-jia</p></div>
        <div class="person person-24" @click="changePerson(23)" @keydown.enter="changePerson(23)" tabindex="0"><img class="w-full" :class="personArr[23]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-30-簡吉-白.png" alt="Jian Ji" title="Jian Ji"><p>Jian Ji</p></div>
        <div class="person person-25" @click="changePerson(24)" @keydown.enter="changePerson(24)" tabindex="0"><img class="w-full" :class="personArr[24]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-31-王敏川-白.png" alt="Wang Min-chuan" title="Wang Min-chuan"><p>Wang Min-chuan</p></div>
      </div>
      <img v-if="headPage<2" @click="rightHeadBtn" @keydown.enter="rightHeadBtn" tabindex="0" class="arrow right-arrow" src="../assets/common/right-arrow.png" alt="next page" title="next page">
    </div>

    <!--img0--><!--ver-->
    <div class="normal-page" v-if="page=='normal'">
        <div class="person-1" v-if="personArr[0]">
          <div class="top-content">
            <div class="person">
              <div class="person-name">Lien Wen-ching</div>
              <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-53-連溫卿-pic1.png" alt="Lien Wen-chin" title="Lien Wen-chin">
              <div class="content">
                <p>Currently, the promotion of Esperanto has an unstoppable momentum and is rapidly spreading across the globe. It can be said that this is the result of the reaction against narrow nationalistic ideas and a response to the concept of "national self-determination."</p>
                <p>Lien Wen-ching, "What is the Esperanto Movement?"</p>
                <p><i>Taiwan Min Pao</i>, 1926</p>
              </div>
            </div>
          </div>

          <div class="bottom-content">
            <div class="person">
              <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-54-連溫卿-插畫1.png" alt="Lien Wen-chin" title="Lien Wen-chin">
              <div class="center-person-div">
                <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-55-連溫卿-pic2.png" alt="Lien Wen-chin" title="Lien Wen-chin">
                <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
                <p class="source">Courtesy of National Tsing Hua University</p>
              </div>
              <div class="content">
                <div class="row-1">
                  <p class="person-name">Lien Wen-ching</p>
                  <p class="year">1894-1957</p>
                  <div class="text-1"><div class="dot"></div>People of Cultural Association</div>
                </div>
                <div class="row-2">
                  <div class="dot"></div>
                  <div>Introduction</div>
                  <div class="line"></div>
                </div>
                <div class="row-3">
                  <p class="text">Upon graduating from public school, he delved into language and social science theories through self-study. During his early years, he was primarily involved in the Esperanto movement. In 1923, he established the Social Issues Research Association and the Taipei Youth Association, emerging as a pivotal figure in the leftward shift of the Taiwan Cultural Association.</p>
                  <p class="text" style="margin-top: 1vw">During his time with the Taiwan Cultural Association, he actively engaged in youth and labor union organizations, passionately supporting island-wide strikes, the establishment of a general labor union, and fostering close collaboration with farmers' associations. He organized lectures and managed interactions with the police. After 1929, he withdrew from social movement circles. In 1955, he chronicled the history of social movements during the Japanese colonial era, and this work was published in 1988 as The History of Taiwan's Political Movements.</p>
                </div>
                <div class="row-4">
                  <div>
                    <div class="dot" style="background: black"></div>
                    <div>Story with Cultural Association</div>
                  </div>
                  <div>
                    <div class="hashtag" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0" alt="The Left and The Right" title="The Left and The Right">#The Left and The Right</div>
                    <div class="hashtag" @click="routeBtn('/en/unit3-5')" @keydown.enter="routeBtn('/en/unit3-5')" tabindex="0" alt="Esperanto" title="Esperanto">#Esperanto</div>
                  </div>
                </div>
              </div>
              <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
                <div style="margin-top: 1.1vw">If they live in today……</div>
                <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
                <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              </div>
            </div>
          </div>
        </div>
        <div class="person-1" v-if="personArr[1]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Tsai Pei-huo</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-60-蔡培火-pic3.png" alt="Tsai Pei-huo" title="Tsai Pei-huo">
            <div class="content">
              <p>Taiwan is the Taiwan of the empire, and at the same time, it is the Taiwan of us Taiwanese people.</p>
              <p>Tsai Pei-huo, "Our Island and Ourselves," Taiwan Youth, 1921</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-61-蔡培火-插畫5.png" alt="Tsai Pei-huo" title="Tsai Pei-huo">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-62-蔡培火-pic4.png" alt="Tsai Pei-huo" title="Tsai Pei-huo">
              <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Collection of the National Museum of Taiwan Literature Donated by Lin Zhang-feng</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Tsai Pei-huo</p>
                <p class="year">1889-1983</p>
                <div class="text-1"><div class="dot"></div>People of Cultural Association</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Born in Beigang, he was a public school teacher. In 1920, he served as the editor and publisher of Taiwan Youth, where he published the statement "Taiwan is the Taiwan of Taiwanese people," which became important to advocacy at the time. Later, he took the lead in publishing <i>Taiwan Min Pao</i>.</p>
                <p class="text" style="margin-top: 1vw">Starting in 1923, he served as the executive director of the Taiwan Cultural Association. He pioneered a new promotional approach by organizing traveling film screenings, which was well-received throughout the island. Additionally, he put great effort into promoting Taiwanese vernacular script (Church Romanization). He wrote and performed numerous social movement songs, including "Taiwan Autonomy Song."when he was imprisoned because of Security Police Incident, and "Our Taiwan," which have been passed down through generations.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan">#Taiwanese People’s Taiwan</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-2')" @keydown.enter="routeBtn('/en/unit3-2')" tabindex="0" alt="Stage Performances and Films" title="Stage Performances and Films">#Stage Performances and Films</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-4')" @keydown.enter="routeBtn('/en/unit3-4')" tabindex="0" alt="Social Ballads" title="Social Ballads">#Social Ballads</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[2]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Hsieh Wen-ta</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-68-謝文達-pic5.png" alt="Hsieh Wen-ta" title="Hsieh Wen-ta">
            <div class="content">
              <p>The numerous challenges we encounter serve as opportunities for personal growth, provided by God as trials; we must approach them with a positive mindset and determined action.</p>
              <p>Hsieh Wen-ta, "The Peaceful Purpose of Aviation and its Cultural Influence" Taiwan Cultural Association Conference News, Issue 4, 1922</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 10vw; top: 38%; left: 10%;" src="../assets/person-intro/20230424-人物介紹-物件-69-謝文達-插畫10.png" alt="Hsieh Wen-ta" title="Hsieh Wen-ta">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-70-謝文達-pic6.png" alt="Hsieh Wen-ta" title="Hsieh Wen-ta">
              <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of the National Central Library</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Hsieh Wen-ta</p>
                <p class="year">1901-1983</p>
                <div class="text-1"><div class="dot"></div>People of Cultural Association</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">After graduating from Taichung High School in 1919, Hsieh Wen-ta went to Japan to study at the Ito Airplane Research Institute. He graduated with excellent results, becoming Taiwan's first pilot. </p>
                <p class="text" style="margin-top: 1vw">In 1923, amid the second movement advocating for the establishment of the Taiwanese Parliament, Hsieh Wen-ta piloted the aircraft "Taipei" over Tokyo. He dropped tens of thousands of leaflets featuring messages such as "Taiwanese people have long suffered under oppressive politics!" and "Give the Taiwanese people a Parliament!" The Governor-General's office harshly criticized this incident, which led to Hsieh Wen-ta leaving Taiwan and ultimately settling in China.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit1-1')" @keydown.enter="routeBtn('/en/unit1-1')" tabindex="0" alt="Skyview of the World" title="Skyview of the World">#Skyview of the World</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[3]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Lin Hsien-tang</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-72-林獻堂-pic7.png" alt="Lin Hsien-tang" title="Lin Hsien-tang">
            <div class="content">
              <p>In fostering unity through independence, we must not forget the significance of mutual assistance and mentorship for our fellow citizens.</p>
              <p>Lin Hsien-tang (at the opening tea party of the third Summer School)</p><p>"The Road Ahead for Taiwanese Youth," 1923</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-73-林獻堂-插畫11.png" alt="Lin Hsien-tang" title="Lin Hsien-tang">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-74-林獻堂-pic8.png" alt="Lin Hsien-tang" title="Lin Hsien-tang">
              <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Collection of the National Museum of Taiwan Literature Donated by Lin Zhang-feng </p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Lin Hsien-tang</p>
                <p class="year">1881-1956</p>
                <div class="text-1"><div class="dot"></div>People of Cultural Association</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">As the head of the Wufeng Lin family, Lin Hsien-tang passionately campaigned for equal treatment of Taiwanese people under Japanese rule. Starting in 1921, he spearheaded the Taiwan Parliament Petition Movement, a campaign that spanned 15 years. When the Taiwan Cultural Association was established, he was unanimously elected as the highest representative, the chairman, and served as the de facto leader in its early stages.</p>
                <p class="text" style="margin-top: 1vw">Lin Hsien-tang generously provided funds and resources, even offering his Wufeng Lin residence as the venue for the largest lecture camp, the "Summer School," organized by the Taiwan Cultural Association. The association's committee meetings were also held at the Wufeng Lin residence. In addition, he served as the long-term president of <i>Taiwan Min Pao</i>.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div style="flex-wrap: wrap">
                  <div class="hashtag" @click="routeBtn('/en/unit2-0')" @keydown.enter="routeBtn('/en/unit2-0')" tabindex="0" alt="The Taiwan Cultural Association Forges Ahead" title="The Taiwan Cultural Association Forges Ahead">#The Taiwan Cultural Association Forges Ahead</div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan">#Taiwanese People’s Taiwan</div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0" alt="The Left and The Right" title="The Left and The Right">#The Left and The Right</div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-4')" @keydown.enter="routeBtn('/en/unit2-4')" tabindex="0" style="margin-top: 1vw" alt="The Trend of Political Association" title="The Trend of Political Association">#The Trend of Political Association</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[4]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Hsieh Hsueh-hung</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-76-謝雪紅-pic9.png" alt="Hsieh Hsueh-hung" title="Hsieh Hsueh-hung">
            <div class="content">
              <p>"Hsieh, you've often said since your return to Taiwan in 1928, that life should be a continuous journey in pursuit of truth."</p>
              <p>Yang Ke-huang, Beijing, 1971</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-77-謝雪紅-插畫12.png" alt="Hsieh Hsueh-hung" title="Hsieh Hsueh-hung">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-78-謝雪紅-pic10.png" alt="Hsieh Hsueh-hung" title="Hsieh Hsueh-hung">
              <img class="zoom-2-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of NUEVA VISION CO., LTD.</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Hsieh Hsueh-hung</p>
                <p class="year">1901-1970</p>
                <div class="text-1"><div class="dot"></div>Exploring Gender Boundaries</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Born into poverty and orphaned, she journeyed with her husband to Kobe and Qingdao, where she taught herself Japanese and Chinese. She worked in the Western clothing sewing machine business. In 1925, she traveled to Shanghai and eventually to Moscow to learn about communism. In 1928, she became one of the founding members of the Taiwan Communist Party. After returning to Taiwan, she opened the International Bookstore to continue her revolutionary work. She was arrested again in 1931 and sentenced to 13 years in prison but was released in 1940.</p>
                <p class="text" style="margin-top: 1vw">After the war, she participated in what became known as the 228 Incident. Later, she left Taiwan and established the Taiwan Democratic Autonomy Alliance in Hong Kong. During the Cultural Revolution, she faced severe criticism and persecution.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0" alt="The Left and The Right" title="The Left and The Right">#The Left and The Right</div>
                  <div class="hashtag" @click="routeBtn('/en/unit4-2')" @keydown.enter="routeBtn('/en/unit4-2')" tabindex="0" alt="Cultural Bases" title="Cultural Bases">#Cultural Bases</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[5]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Jian E</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-83-簡娥-pic11.png" alt="Jian E" title="Jian E">
            <div class="content">
              <p>From this moment forward, I am wholeheartedly committed to the farmers' movement. I should no longer be regarded as a student.</p>
              <p>Jian E, in a postcard sent to her high school after leaving home</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-84-簡娥-插畫16.png" alt="Jian E" title="Jian E">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-85-簡娥-pic12.png" alt="Jian E" title="Jian E">
              <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of the family of Zuang Yong-ming</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Jian E</p>
                <p class="year">1909-2004</p>
                <div class="text-1"><div class="dot"></div>Exploring Gender Boundaries</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">While attending Kaohsiung Girls' High School, Jian E and her classmate Zhang Yu-lan were inspired by lectures from the Farmers' Association and the Taiwan Cultural Association, leading them to join study groups. Following Zhang Yu-lan's expulsion from school for delivering a speech, Jian E voluntarily withdrew and became actively involved in the farmers' movement.</p>
                <p class="text" style="margin-top: 1vw">In the 1929 212 Incident, more than 300 Taiwan Farmers' Association locations across the island were subjected to surprise police raids and searches. Jian E went undercover in the Taoyuan area to develop the organization and later joined the Taiwan Communist Party. She was ultimately arrested and imprisoned.</p>
                <p class="text" style="margin-top: 1vw">Following the war, Jian E shifted her focus to family life. Her husband was once arrested for his involvement in a political case. In the 1980s, they relocated to the United States.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" tabindex="0" alt="Advocating for Farmers and Workers" title="Advocating for Farmers and Workers">#Advocating for Farmers and Workers</div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0" alt="The Left and The Right" title="The Left and The Right">#The Left and The Right</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[6]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Chen Zhiqi</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-90-陳植棋-pic13.png" alt="Chen Zhiqi" title="Chen Zhiqi">
            <div class="content">
              <p>If life is meant to be long and drawn out, I'd rather have it short and radiant. I yearn for a life bursting with vitality.</p>
              <p>Chen Zhiqi</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 8.5vw; top: 50%; left: 11%;"  src="../assets/person-intro/20230424-人物介紹-物件-91-陳植棋-插畫20.png" alt="Chen Zhiqi" title="Chen Zhiqi">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-92-陳植棋-pic14.png" alt="Chen Zhiqi" title="Chen Zhiqi">
              <img class="zoom-2-btn" @click="showImg[6]=true" @keydown.enter="showImg[6]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source" >Academic Institute of Taiwan History, Academia Sinica Archives</p>
              <p class="source" style="top: 113%">Courtesy of Chen Zi-zhi</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Chen Zhiqi</p>
                <p class="year">1906-1931</p>
                <div class="text-1"><div class="dot"></div>Rebellious Youth</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">While in school, he was mentored by Kinichiro Ishikawa and displayed remarkable artistic talent. Before graduating from Taipei Normal School, he was expelled for leading a student movement. He later enrolled in Tokyo Fine Arts School and participated in the Cultural Movement Reform Association, a political organization advocating for the "dismantling of schools that serve as breeding grounds for slavery" and the "destruction of the economic infrastructure of oppressors."</p>
                <p class="text" style="margin-top: 1vw">From 1928, his works were selected twice for the Imperial Art Exhibition, and they also garnered acclaim in the Taiwan Exhibition. He was in charge of the Taiwanese local artist group, Chidao Society. In 1930, he fell ill while in Japan participating in the Imperial Exhibition and tragically passed away at the young age of 25.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0" alt="The Left and The Right" title="The Left and The Right">#The Left and The Right</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[7]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Chuang Sung-lin</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-97-莊松林-pic16.png" alt="Chuang Sung-lin" title="Chuang Sung-lin">
            <div class="content">
              <p> </p>
              <p> </p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-98-莊松林-插畫24.png" alt="Chuang Sung-lin" title="Chuang Sung-lin">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-99-莊松林-pic16.png" alt="Chuang Sung-lin" title="Chuang Sung-lin">
              <img class="zoom-2-btn" @click="showImg[7]=true" @keydown.enter="showImg[7]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of Zhuang Ming-zheng</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Chuang Sung-lin</p>
                <p class="year">1910-1974</p>
                <div class="text-1"><div class="dot"></div>Sketches of Laborers</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">After graduating from Xiamen Jimei High School and returning to Taiwan, in 1929, he joined the anti-pudu movement, protesting Ghost Month customs, initiated by the Chikan Labor Youth Association. He also participated in founding the left-wing journal Equator. The journal faced multiple bans, leading to his arrest on several occasions. To avoid deportation by the government for twice violating publishing rules, he began working at an iron factory.</p>
                <p class="text" style="margin-top: 1vw">Subsequently, he immersed himself in the study of new literature, Esperanto, and exploring Taiwanese folk customs. He published his research under the pen name Zhu Feng. After the war, he was appointed as a member of the Tainan City Archives Committee.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0" alt="The Left and The Right" title="The Left and The Right">#The Left and The Right</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-3')" @keydown.enter="routeBtn('/en/unit3-3')" tabindex="0" alt="Speech Forums" title="Speech Forums">#Speech Forums</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-5')" @keydown.enter="routeBtn('/en/unit3-5')" tabindex="0" alt="Esperanto" title="Esperanto">#Esperanto</div>

                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[8]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Lu Bing-ding</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-105-盧丙丁-pic17.png" alt="Lu Bing-ding" title="Lu Bing-ding">
            <div class="content">
              <p>Now, we clutch our dreams as we each go our separate ways,</p>
              <p>Transforming our once joyful days into dry and flavorless ones,</p>
              <p>In this world without a soulmate, it's like a time without light,</p>
              <p>Reluctant to part, we can only sing the song of farewell together.</p>
              <p>"Farewell Poem"</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 11vw; top: 50%; left: 8%;" src="../assets/person-intro/20230424-人物介紹-物件-106-盧丙丁-插畫29.png" alt="Lu Bing-ding" title="Lu Bing-ding">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-107-盧丙丁-pic18.png" alt="Lu Bing-ding" title="Lu Bing-ding">
              <img class="zoom-2-btn" @click="showImg[8]=true" @keydown.enter="showImg[8]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Collection of the National Museum of Taiwan Literature Donated by Lin Zhang-feng</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Lu Bing-ding</p>
                <p class="year">1901-?</p>
                <div class="text-1"><div class="dot"></div>Sketches of Laborers</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Previously a public school teacher, he resigned to dedicate himself entirely to the activities of the Taiwan Cultural Association. He was heavily involved in lectures, the Tainan New Drama Movement, and earned acclaim as an eloquent film commentator, receiving the nickname "Bing-ding the Immortal."</p>
                <p class="text" style="margin-top: 1vw">In 1927, upon the founding of the Taiwanese People's Party, Lu Bing-ding served as a standing committee member of the party's central committee and took charge of the publicity department. He was deeply committed to the labor movement, resulting in several arrests by the Japanese police. After 1932, he vanished, likely due to police persecution, and was possibly sent to Losheng Sanatorium before being deported to Xiamen. His wife, Lin Shi-Hao, was also actively engaged in social movements and later became a renowned singer.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-1')" @keydown.enter="routeBtn('/en/unit3-1')" tabindex="0" alt="Cultural Lectures" title="Cultural Lectures">#Cultural Lectures</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-2')" @keydown.enter="routeBtn('/en/unit3-2')" tabindex="0" alt="Stage Performances and Films" title="Stage Performances and Films">#Stage Performances and Films</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[9]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Wang Shi-lang</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-109-王詩琅-pic19.png" alt="Wang Shi-lang" title="Wang Shi-lang">
            <div class="content">
              <p></p>
              <p></p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 7vw; top: 50%; left: 13%;" src="../assets/person-intro/20230424-人物介紹-物件-110-王詩琅-插畫30.png" alt="Wang Shi-lang" title="Wang Shi-lang">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-111-王詩琅-pic20.png" alt="Wang Shi-lang" title="Wang Shi-lang">
              <img class="zoom-2-btn" @click="showImg[9]=true" @keydown.enter="showImg[9]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of the family of Zhuang Yong-ming</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Wang Shi-lang</p>
                <p class="year">1908-1984</p>
                <div class="text-1"><div class="dot"></div>Sketches of Laborers</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">With a solid foundation in Chinese literature, he developed an interest in Russian literature and embraced anarchist ideas. In 1926, he joined the Taiwan Black Youth League, an anarchist organization, he was arrested and imprisoned three times. His involvement with the Taiwan Labor Aid Society also led to a 10-month prison sentence.</p>
                <p class="text" style="margin-top: 1vw">Drawing from his personal experiences and the contemporary social context, he depicted social activists, striking workers, bank clerks, and veteran prostitutes in his literary works. He aimed to capture the social phenomena and the feelings of frustration experienced under oppression.</p>
                <p class="text" style="margin-top: 1vw">After the war, he served as a member of the Archives Committee, where he compiled chronicles and gathered materials related to social movements. His efforts were instrumental in preserving the records of the Taiwan Cultural Association.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" tabindex="0" alt="Advocating for Farmers and Workers" title="Advocating for Farmers and Workers">#Advocating for Farmers and Workers</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[10]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Tsai Yuan-tang</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-113-蔡淵騰-pic22.png" alt="Tsai Yuan-tang" title="Tsai Yuan-tang">
            <div class="content">
              <p></p>
              <p></p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-114-蔡淵騰-插畫31.png" alt="Tsai Yuan-tang" title="Tsai Yuan-tang">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-115-蔡淵騰-pic22.png" alt="Tsai Yuan-tang" title="Tsai Yuan-tang">
              <img class="zoom-2-btn" @click="showImg[10]=true" @keydown.enter="showImg[10]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of Tsai Shih Chieh</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Tsai Yuan-tang</p>
                <p class="year">1896-1971</p>
                <div class="text-1"><div class="dot"></div>Sketches of Laborers</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Born in 1896 in Erlin, he served as a director of the sugarcane farmers' association in 1925, advocating for farmers' rights and benefits against the corporation. Following the Erlin Incident, he was arrested, during his approximately six-month incarceration, he penned the verse, "I believed escaping this misfortune would be difficult, as I championed the people's rights while confined in a dark cell."</p>
                <p class="text" style="margin-top: 1vw">Following his imprisonment, Tsai Yuan-teng remained committed to public service, taking on roles such as Baozheng (a local administrative officer). After the war, he attempted to recover his family's ancestral property, which had been forcibly taken by Yuancheng Farm, but his efforts were unsuccessful. Additionally, he experienced the effects of land reform and served as a representative in the first session of the Erlin Town Council.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" tabindex="0" alt="Advocating for Farmers and Workers" title="Advocating for Farmers and Workers">#Advocating for Farmers and Workers</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[11]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Tsai Hui-ju</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-120-蔡惠如-pic23.png" alt="Tsai Hui-ju" title="Tsai Hui-ju">
            <div class="content">
              <p>My fellow countrymen must be aware of their position and should strive in all aspects, be it politics, economy, or other fields. They should not hesitate to reform wherever necessary.</p>
              <p>Tsai Hui-ju, "Hsinchu's Flourishing Cultural Lecture Series" <i>Taiwan Min Pao</i>, 1925</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-121-蔡惠如-插畫35.png" alt="Tsai Hui-ju" title="Tsai Hui-ju">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-122-蔡惠如-pic24.png" alt="Tsai Hui-ju" title="Tsai Hui-ju">
              <img class="zoom-2-btn" @click="showImg[11]=true" @keydown.enter="showImg[11]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Tsai Hui-ju</p>
                <p class="year">1881-1929</p>
                <div class="text-1"><div class="dot"></div>Scholar-gentry</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Tsai Hui-ju was a prominent merchant from Qingshui. In 1914, he participated in the Assimilation Society (Dōkakai) founded by Lin Hsien-tang. In 1918, he co-founded the Taiwan Literary Society with Lin Hsien-tang and  Lin You-chun from the Wufeng Lin family, and launched the Taiwan Literature and Art Journal, a pioneering Chinese-language magazine that mainly featured classical Chinese poetry.</p>
                <p class="text" style="margin-top: 1vw">Tsai Hui-ju advocated for the establishment of an Enlightenment Society and Responsive Association by Taiwanese students studying in Japan, and founded the New People's Society in 1920. He was also an important financial supporter of Taiwan Youth magazine. Following the Security Police Incident, he was sentenced to three months in prison and many supporters gathered to bid him farewell. His poetry penned in prison has been passed down through generations.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan">#Taiwanese People’s Taiwan</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-3')" @keydown.enter="routeBtn('/en/unit3-3')" tabindex="0" alt="Speech Forums" title="Speech Forums">#Speech Forums</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[12]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Chen Cheng-po</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-124-陳澄波-pic25.png" alt="Chen Cheng-po" title="Chen Cheng-po">
            <div class="content">
              <p>As an artist, it is essential to expand one's horizons in life and to demonstrate a greater tolerance and concern in all things.</p>
              <p>Chen Cheng-po, "Artistic Perspective", 1921</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 16.8vw; top: 50%; left: 2%;" src="../assets/person-intro/20230424-人物介紹-物件-125-陳澄波-插畫36.png" alt="Chen Cheng-po" title="Chen Cheng-po">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-126-陳澄波-pic26.png" alt="Chen Cheng-po" title="Chen Cheng-po">
              <img class="zoom-2-btn" @click="showImg[12]=true" @keydown.enter="showImg[12]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of  Chen Cheng-po Cultural Foundation</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Chen Cheng-po</p>
                <p class="year">1895-1947</p>
                <div class="text-1"><div class="dot"></div>Scholar-gentry</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">While in school, he was taught by Kinichiro Ishikawa and later became a public school teacher. In his late twenties, he enrolled in the Tokyo Fine Arts School. In 1927, his work Outside Chiayi Street was selected for the Imperial Art Exhibition, garnering instant fame. During his time at school, he connected with Taiwanese artistic talents, showed interest in the parliamentary petition movement, and published plein air works from various locations in Taiwan. After graduation, he taught in Shanghai before returning to Taiwan in 1933 to join the Tai-Yang Art Association.</p>
                <p class="text" style="margin-top: 1vw">After the war, he actively engaged in social affairs, was elected as a Chiayi City council member, and served as a "Peace Envoy" to assist in mediation during the 228 Incident. However, he was eventually arrested and executed by firing squad.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan">#Taiwanese People’s Taiwan</div>
                  <div class="hashtag" @click="routeBtn('/en/unit4-1')" @keydown.enter="routeBtn('/en/unit4-1')" tabindex="0" alt="Monument of Time" title="Monument of Time">#Monument of Time</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[13]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Chen Huai-cheng</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-131-陳懷澄-pic27.png" alt="Chen Huai-cheng" title="Chen Huai-cheng">
            <div class="content">
              <p>Lin Hsien-tang sent a petition for the establishment of a parliament while in the capital. </p>
              <p>Chen Huai-cheng, "Mr. Chen Huaicheng's Diary (Part 5)", 1921</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 7.3vw; top: 51.3%; left: 12.3%;" src="../assets/person-intro/20230424-人物介紹-物件-132-陳懷澄-插畫40.png" alt="Chen Huai-cheng" title="Chen Huai-cheng">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-133-陳懷澄-pic28.png" alt="Chen Huai-cheng" title="Chen Huai-cheng">
              <img class="zoom-2-btn" @click="showImg[13]=true" @keydown.enter="showImg[13]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of Li Zhao-rong</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Chen Huai-cheng</p>
                <p class="year">1877-1940</p>
                <div class="text-1"><div class="dot"></div>Scholar-gentry</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Chen Huai-cheng's family was prominent in Lukang, belonging to the local leadership class. They maintained a friendly relationship with the Japanese, and Chen became the first head of Lukang Street after the local system underwent reform. Throughout his tenure, he dedicated himself to local development and made substantial contributions.</p>
                <p class="text" style="margin-top: 1vw">Although appointed as the street chief by the government, which prevented him from directly participating in association activities, Chen Huai-cheng continued to express concern for the association's development. The Taiwan Cultural Association organized several cultural lectures at Lukang Wen Temple, and when the Changhua County Office police discovered this, they pressured Chen Huai-cheng. However, thanks to his support, the events were successfully held, making him the most crucial backer of the association's activities in Lukang.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-1')" @keydown.enter="routeBtn('/en/unit3-1')" tabindex="0" alt="Cultural Lectures" title="Cultural Lectures">#Cultural Lectures</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[14]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Lin You-chun</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-135-林幼春-pic29.png" alt="Lin You-chun" title="Lin You-chun">
            <div class="content">
              <p>Enduring ties bind us, knowing the world is not as it seems, With weary eyes, I force myself to gaze upon the slanted light.</p>
              <p>In ninety years, the azure sky turns threatening, The whole nation calls to the spirits, yet they dare not fly.</p>
              <p>Through countless trials, we still willingly fall, How many lifetimes until we can reach the blossoms?</p>
              <p>Lin You-chun, "Poem of Fallen Flowers to Console Oneself in Prison During Spring", 1925</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 12.5vw; top: 51.3%; left: 6%;" src="../assets/person-intro/20230424-人物介紹-物件-136-林幼春-插畫41.png" alt="Lin You-chun" title="Lin You-chun">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-137-林幼春-pic30.png" alt="Lin You-chun" title="Lin You-chun">
              <img class="zoom-2-btn" @click="showImg[14]=true" @keydown.enter="showImg[14]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Collection of the National Museum of Taiwan Literature Donated by Lin Zhang-feng</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Lin You-chun</p>
                <p class="year">1880-1939</p>
                <div class="text-1"><div class="dot"></div>Scholar-gentry</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Lin You-chun, a gifted poet and writer specializing in classical Chinese literature, was born into the prestigious Wufeng Lin family. In 1918, he co-founded the Taiwan Literary Society with Tsai Hui-ju and other members of the Li Poetry Society.</p>
                <p class="text" style="margin-top: 1vw">In 1921, Lin You-chun joined the Taiwan Cultural Association, serving as vice premier, and later became actively involved in the Taiwan Parliament Founding Alliance. During the Security Police Incident, he was sentenced to three months in prison, where he composed modern-style poetry reflecting contemporary democratic thought. After his release, Lin You-chun resigned from his official position. In 1927, he served as an advisor to the Taiwanese People's Party and later retired to Wufeng. He went on to sponsor new literary journals founded by Zhang Shen-qie and Yang Kui.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan">#Taiwanese People’s Taiwan</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[15]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Su Bi-hui</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-139-蘇璧輝-pic31.png" alt="Su Bi-hui" title="Su Bi-hui">
            <div class="content">
              <p>TEMPO ESTAS MONO</p>
              <p>Being Strict with Time</p>
              <p>Su Bi-hui, Su Qi-xi, Su Zhe-xiu, Su Bi-cong</p>
              <p>Stone inscription on the Clock Tower</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style=" top: 51.3%; left: 10%;" src="../assets/person-intro/20230424-人物介紹-物件-140-蘇璧輝-插畫42.png" alt="Su Bi-hui" title="Su Bi-hui">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-141-蘇璧輝-pic32.png" alt="Su Bi-hui" title="Su Bi-hui">
              <img class="zoom-2-btn" @click="showImg[15]=true" @keydown.enter="showImg[15]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of Zheng Hui-mei</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Su Bi-hui</p>
                <p class="year">1884-1937</p>
                <div class="text-1"><div class="dot"></div>Scholar-gentry</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Born in Mengjia in 1884, he was a key advocate for Esperanto in Taiwan, he co-founded the Taiwan Esperanto Association with Lien Wen-ching and others in 1919. He took on the roles of editor and publisher for the magazine La Verda Ombro and established the Esperanto Commercial Agency. Sometimes using the pseudonym "S Sheng," he contributed articles promoting the use of Esperanto.</p>
                <p class="text" style="margin-top: 1vw">In 1921, Su Bi-hui played a role in founding the Taiwan Cultural Association, serving as a director. He also participated in the movement of parliament establish in Taiwan. In 1923, he was arrested and detained during the Security Police Incident. Later, in 1927, he donated the clock monument to Longshan Temple. In 1934, Su Bi-hui relocated to Xiamen, China. When the Sino-Japanese War erupted in 1937, he and his eldest son were executed by the Chinese government under suspicion of being Japanese spies.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-5')" @keydown.enter="routeBtn('/en/unit3-5')" tabindex="0" alt="Esperanto" title="Esperanto">#Esperanto</div>
                  <div class="hashtag" @click="routeBtn('/en/unit4-1')" @keydown.enter="routeBtn('/en/unit4-1')" tabindex="0" alt="Monument of Time" title="Monument of Time">#Monument of Time</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[16]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Du Xiang-guo</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-146-杜香國-pic33.png" alt="Du Xiang-gu" title="Du Xiang-gu">
            <div class="content">
              <p></p>
              <p></p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-147-杜香國-插畫46.png" alt="Du Xiang-gu" title="Du Xiang-gu">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-148-杜香國-pic34.png" alt="Du Xiang-gu" title="Du Xiang-gu">
              <img class="zoom-2-btn" @click="showImg[16]=true" @keydown.enter="showImg[16]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source" style="bottom: -14%">National Museum of Taiwan History Collection 2020.032.0252</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Du Xiang-guo</p>
                <p class="year">1894-1946</p>
                <div class="text-1"><div class="dot"></div>Scholar-gentry</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Du Xiang-guo was born into a prestigious family and was involved in rush hat and mat production, securities, and paper industry. He and his father, Du Qing, were enthusiastic about participating in the local branches of the Taiwan Cultural Association and the Taiwanese People's Party. The correspondence between Du Xiang-guo, his father Du Qing, and the members of the Taiwan Cultural Association provide valuable insights into the association's activities during that period, serving as a crucial resource for studying the association.</p>
                <p class="text"></p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan">#Taiwanese People’s Taiwan</div>
                  <div class="hashtag" @click="routeBtn('/en/unit4-3')" keydown.enter="routeBtn('/en/unit4-3')" tabindex="0" alt="Social Forums" title="Social Forums">#Social Forums</div>
                  <div class="hashtag" @click="routeBtn('/en/unit4-4')" keydown.enter="routeBtn('/en/unit4-4')" tabindex="0" alt="Changing Customs" title="Changing Customs">#Changing Customs</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[17]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Chiang Wei-shui</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-150-蔣渭水-pic35.png" alt="Chiang Wei-shui" title="Chiang Wei-shui">
            <div class="content">
              <p>Compatriots, we must stand united, for unity brings true strength!</p>
              <p>Chiang Wei-shui, 1927</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-151-蔣渭水-插畫47.png" alt="Chiang Wei-shui" title="Chiang Wei-shui">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-152-蔣渭水-pic36.png" alt="Chiang Wei-shui" title="Chiang Wei-shui">
              <img class="zoom-2-btn" @click="showImg[17]=true" @keydown.enter="showImg[17]=true"  tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of  Chiang Wei-shui Cultural Foundation</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Chiang Wei-shui</p>
                <p class="year">1891-1931</p>
                <div class="text-1"><div class="dot"></div>Physicians and Christians</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Throughout his time in medical school, Chiang Wei-shui demonstrated remarkable leadership skills. Upon graduating, he founded Da'an Hospital in Dadaocheng. In 1921, Chiang played a pivotal role in establishing the Taiwan Cultural Association and became a central figure in the Taiwanese People's Party and the Workers' Federation. Additionally, Da'an Hospital functioned as the publishing house for the <i>Taiwan Min Pao</i> newspaper. Chiang also opened the Cultural Bookstore to disseminate progressive ideas. His thoughts were dynamic and innovative, characterized by a strong sense of Han ethnic identity and a dedication to socialism.</p>
                <p class="text" style="margin-top: 1vw">Chiang Wei-shui passed away from illness in 1931. His comrades chose to hold a public funeral, and reports indicate that over 5,000 people attended the procession.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-0')" @keydown.enter="routeBtn('/en/unit2-0')" tabindex="0" alt="The Taiwan Cultural Association Forges Ahead" title="The Taiwan Cultural Association Forges Ahead">#The Taiwan Cultural Association Forges Ahead</div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-4')" @keydown.enter="routeBtn('/en/unit2-4')" tabindex="0" alt="The Trend of Political Association" title="The Trend of Political Association">#The Trend of Political Association</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-4')" @keydown.enter="routeBtn('/en/unit3-4')" tabindex="0" alt="Social Ballads" title="Social Ballads">#Social Ballads</div>
                  <div class="hashtag" @click="routeBtn('/en/unit4-4')" @keydown.enter="routeBtn('/en/unit4-4')" tabindex="0" alt="Changing Customs" title="Changing Customs">#Changing Customs</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[18]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Han Shi-quan</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-159-韓石泉-pic37.png" alt="Han Shi-quan" title="Han Shi-quan">
            <div class="content">
              <p>A physician should possess a spirit of universal love and noble character, study the essential basic knowledge of modern times, and have a deep understanding of the specialized knowledge and skills related to medicine, as well as the ability to apply them appropriately. Moreover, their knowledge and skills must keep pace with the global standards of medical practice, requiring continuous progress and improvement.</p>
              <p>Han Shi-quan, "Doctor's Ten Admonitions", Sixteen Recollections, 2009 </p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style=" top: 51.3%; left: 10%;" src="../assets/person-intro/20230424-人物介紹-物件-160-韓石泉-插畫53.png" alt="Han Shi-quan" title="Han Shi-quan">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-161-韓石泉-pic38.png" alt="Han Shi-quan" title="Han Shi-quan">
              <img class="zoom-2-btn" @click="showImg[18]=true" @keydown.enter="showImg[18]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of Han Liang-cheng</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Han Shi-quan</p>
                <p class="year">1897-1963</p>
                <div class="text-1"><div class="dot"></div>Physicians and Christians</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Hailing from a scholarly family in Tainan, he gained admission to medical school in 1913. In his third year, he challenged the unreasonable conduct of the school store and dormitory supervisor by orchestrating a student boycott. In 1922, he and Dr. Huang Jin-huo co-founded the Republic Hospital in Tainan. Later, in 1928, he established the Han Internal Medicine Clinic.</p>
                <p class="text" style="margin-top: 1vw">As his medical career progressed, he actively engaged in social activities. He served as a director when the Taiwan Cultural Association was founded, gave lectures on health and hygiene, and humbly participated in theater activities to promote new plays and concepts. He was arrested and detained during the Security Police Incident and later joined the Taiwanese People's Party, serving as a central committee member, responsible for the affairs of the Tainan branch.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan">#Taiwanese People’s Taiwan</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-2')" @keydown.enter="routeBtn('/en/unit3-2')" tabindex="0" alt="Stage Performances and Films" title="Stage Performances and Films">#Stage Performances and Films</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[19]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Li Ying-zhang</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-166-李應章-pic39.png" alt="Li Ying-zhang" title="Li Ying-zhang">
            <div class="content">
              <p>Unable to bear their grief and depression, I went directly to the lecture hall, shared the plight of the farmers, and conveyed their anguish for our fellow compatriots in society to hear.</p>
              <p>Li Ying-zhang, "Retrospective on the Sugarcane Farmer Disputes",  <i>Taiwan Min Pao</i>, 1927</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 19.5vw; top: 52.7%; left: 2.5%;" src="../assets/person-intro/20230424-人物介紹-物件-167-李應章-插畫57_Fix.png" alt="Li Ying-zhang" title="Li Ying-zhang">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-168-李應章-pic40.png" alt="Li Ying-zhang" title="Li Ying-zhang">
              <img class="zoom-2-btn" @click="showImg[19]=true" @keydown.enter="showImg[19]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of Dr. Leon Chen, author of The Boy in the Corn Field</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Li Ying-zhang</p>
                <p class="year">1897-1954</p>
                <div class="text-1"><div class="dot"></div>Physicians and Christians</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">During his medical school days, he passionately embraced progressive ideas and collaborated with classmates to create a student organization, which led to the formation of the Taiwan Cultural Association, where he served as a member of council. After graduation, he returned to Erlin and founded the Bao'an Hospital. With his exceptional medical skills, he earned the respect of the local community while attending to numerous cases, often traveling by motorcycle.</p>
                <p class="text" style="margin-top: 1vw">While treating patients, he encountered sugarcane farmers suffering from exploitation. Together with local association members, he guided the farmers in advocating for their rights, culminating in a confrontation between the police and the people, which led to his arrest. Eventually, he traveled to China and adopted the name Li Weiguang.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" tabindex="0" alt="Advocating for Farmers and Workers" title="Advocating for Farmers and Workers">#Advocating for Farmers and Workers</div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0" alt="The Left and The Right" title="The Left and The Right">#The Left and The Right</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">If they live in today……</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[20]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Loa Ho</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-173-賴和-pic41.png" alt="Loa Ho" title="Loa Ho">
            <div class="content">
              <p>On the beautiful island, countless seeds are spread, </p>
              <p>Flowers of freedom and trees of equality, </p>
              <p>Eagerly waiting for our passion to nurture them.</p>
              <p>Loa Ho, "On behalf of my comrades, I present this to Mr. Lin Cheng-lu", 1924</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 7.2vw; top: 51%; left: 12.5%;" src="../assets/person-intro/20230424-人物介紹-物件-174-賴和-插畫61.png" alt="Loa Ho" title="Loa Ho">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-175-賴和-pic42.png" alt="Loa Ho" title="Loa Ho">
              <img class="zoom-2-btn" @click="showImg[20]=true" @keydown.enter="showImg[20]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of Loa Ho Cultural and Educational Foundation</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Loa Ho</p>
                <p class="year">1894-1943</p>
                <div class="text-1"><div class="dot"></div>Physicians and Christians</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">A graduate of Taipei Imperial Governor-General's Medical School, he found inspiration in the Chinese New Literature Movement while working in Xiamen. In 1919, he returned to his hometown of Changhua and opened Loa Ho Hospital. As a director of the Taiwan Cultural Association, he engaged in lectures and editing <i>Taiwan Min Pao</i>, encouraging the next generation to create new literary works.</p>
                <p class="text" style="margin-top: 1vw">Following the 1927 reorganization of the Taiwan Cultural Association, he took on roles as a temporary central committee member and a Taiwanese People's Party executive. He published literary works in <i>Taiwan Min Pao</i>, <i>Taiwan Popular Times</i>, and Nanyin, which depicted the living conditions of the underprivileged and conveyed a deep compassion for humanity.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-3')" @keydown.enter="routeBtn('/en/unit3-3')" tabindex="0" alt="Speech Forums" title="Speech Forums">#Speech Forums</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[21]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Lin Qi-zhang</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-177-林淇漳-pic43.png" alt="Lin Qi-zhang" title="Lin Qi-zhang">
            <div class="content">
              <p></p>
              <p></p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 6.5vw; top: 50.2%; left: 13%;" src="../assets/person-intro/20230424-人物介紹-物件-178-林淇漳-插畫62.png" alt="Lin Qi-zhang" title="Lin Qi-zhang">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-179-林淇漳-pic44.png" alt="Lin Qi-zhang" title="Lin Qi-zhang">
              <img class="zoom-2-btn" @click="showImg[21]=true" @keydown.enter="showImg[21]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of Lin Lu Xiu-lan</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Lin Qi-zhang</p>
                <p class="year">1899-1965</p>
                <div class="text-1"><div class="dot"></div>Physicians and Christians</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Dr. Lin Qi-zhang, the founder of Chiayi Changchun Hospital, was a supporter of the Cultural Association. After graduating from medical school in 1916, he served as a ship doctor on a commercial vessel. In 1925, he gave a lecture entitled "A Glimpse of the South Seas" at a Taiwan Cultural Association event. In 1927, he established Changchun Hospital and later converted to Christianity. After the war, he participated in local relief work.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-1')" @keydown.enter="routeBtn('/en/unit3-1')" tabindex="0" alt="Cultural Lectures" title="Cultural Lectures">#Cultural Lectures</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[22]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Yang Zhao-jia</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-181-楊肇嘉-pic45.png" alt="Yang Zhao-jia" title="Yang Zhao-jia">
            <div class="content">
              <p>In summary, the progress and happiness of Taiwan are not determined by others but by ourselves. We should embody the spirit of pioneers, be adventurous and enterprising, and value the principles of love, unity, and cooperation. Let's all work together to create our happiness; this is my greatest hope.</p>
              <p>Excerpt from My Hope</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 9.3vw; top: 49.5%; left: 9.8%;" src="../assets/person-intro/20230424-人物介紹-物件-182-楊肇嘉-插畫63.png" alt="Yang Zhao-jia" title="Yang Zhao-jia">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-183-楊肇嘉-pic46.png" alt="Yang Zhao-jia" title="Yang Zhao-jia">
              <img class="zoom-2-btn" @click="showImg[22]=true" @keydown.enter="showImg[22]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Yang Zhao-jia</p>
                <p class="year">1892-1976</p>
                <div class="text-1"><div class="dot"></div>Scholar-gentry</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Hailing from Qingshui and born into a wealthy, well-respected family, he assumed the role of Head of Qingshui Street in 1920. Inspired by Tsaii Hui-ju, he became involved in political movements. In 1926, he attended Waseda University in Tokyo and built connections with various political figures. In 1930, Yang Zhao-jia returned to Taiwan to lead the Taiwan Local Autonomy Alliance and manage the operations of Taiwan New Min Pao. He played a pivotal role in Taiwan's political movements, participating in the first local elections in 1935 and opposing the Governor-General's rice control policy in 1939.As a long-term supporter of arts and culture, he held significant influence within the arts and cultural community.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-4')" @keydown.enter="routeBtn('/en/unit2-4')" tabindex="0" alt="The Trend of Political Association" title="The Trend of Political Association">#The Trend of Political Association</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-1')" @keydown.enter="routeBtn('/en/unit3-1')" tabindex="0" alt="Cultural Lectures" title="Cultural Lectures">#Cultural Lectures</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-3')" @keydown.enter="routeBtn('/en/unit3-3')" tabindex="0" alt="Speech Forums" title="Speech Forums">#Speech Forums</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[23]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Jian Ji</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-185-簡吉-pic47.png" alt="Jian Ji" title="Jian Ji">
            <div class="content">
              <p>The situation is growing more complex, and I fear we will encounter greater challenges. I feel deeply uneasy, as I am powerless to help. Nevertheless, please remain strong and continue to persevere.</p>
              <p>From behind these iron bars, I will calmly, seriously, and earnestly write to you often, as the saying goes in books, "with all my passion."</p>
              <p>Excerpt from "Jian Ji's Prison Diary," a letter written by Jian Ji to Jian E, who was still working in various locations, dated January 10, 1930 (Showa 5).</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 11.5vw; top: 50.2%; left: 9.4%;" src="../assets/person-intro/20230424-人物介紹-物件-186-簡吉-插畫64.png" alt="Jian Ji" title="Jian Ji">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-187-簡吉-pic48.png" alt="Jian Ji" title="Jian Ji">
              <img class="zoom-2-btn" @click="showImg[23]=true" @keydown.enter="showImg[23]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source">Courtesy of CGCH Foundation for Education</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Jian Ji</p>
                <p class="year">1903-1951</p>
                <div class="text-1"><div class="dot"></div>Rebellious Youth</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">Born in Fengshan, Kaohsiung, he originally worked as a teacher at Fengshan Public School. Feeling empathy for the struggles of his students, he resigned from teaching to engage in social movements. In 1925, he organized the Fengshan Farmer's Association, developing a nationwide farmers' movement. Starting in 1928, he supported the Taiwanese Communist Party, established the Scientific Research Association and Red Aid Society, and coordinated civilian resistance among farmers, resulting in his frequent arrests. After the 228 Incident, Jian Ji joined a leftist militia organization, and in 1950, he was arrested and subsequently executed by firing squad the following year.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" tabindex="0" alt="Advocating for Farmers and Workers" title="Advocating for Farmers and Workers">#Advocating for Farmers and Workers</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-4')" @keydown.enter="routeBtn('/en/unit3-4')" tabindex="0" alt="Social Ballads" title="Social Ballads">#Social Ballads</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-5')" @keydown.enter="routeBtn('/en/unit3-5')" tabindex="0" alt="Esperanto" title="Esperanto">#Esperanto</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[24]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">Wang Min-chuan</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-189-王敏川-pic49.png" alt="Wang Min-chuan" title="Wang Min-chuan">
            <div class="content">
              <p>In this place, scores convene, with talents and strategies for reform.</p>
                <p>When we meet, we lament in silence, nodding our heads in the dark.</p>
                <p>I know the cage resists release; through quiet reading, doubts and suspicions are put aside. </p>
              <p>Excerpt from "Verses from Prison"</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 11.5vw; top: 50.2%; left: 9.4%;" src="../assets/person-intro/20230424-人物介紹-物件-190-王敏川-插畫65.png" alt="Wang Min-chuan" title="Wang Min-chuan">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-191-王敏川-pic50.png" alt="Wang Min-chuan" title="Wang Min-chuan">
              <img class="zoom-2-btn" @click="showImg[24]=true" @keydown.enter="showImg[24]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <p class="source"> Courtesy of Chiang Wei-shui Cultural Foundation</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">Wang Min-chuan</p>
                <p class="year">1889-1942</p>
                <div class="text-1"><div class="dot"></div>Rebellious Youth</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>Introduction</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="text">A native of Changhua, he initially taught at the Changhua Public School and later studied at Waseda University, where he was exposed to socialism. He was a long-term editor for Taiwan Youth and <i>Taiwan Min Pao</i>, his writings were powerful and influential. At the end of 1926, he pushed for a left-leaning Taiwan Cultural Association, publishing <i>Taiwan Popular Times</i>, and was repeatedly detained and imprisoned. In 1931, he was elected as the Central Committee chairman and finance minister during the fourth representative conference, strongly supporting labor, farmers' movements, and the Taiwanese Communist Party. He was later imprisoned for an extended period, leading to the end of Taiwan Cultural Association's operations. He was the last chairman of the Taiwan Cultural Association.</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>Story with Cultural Association</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0" alt="The Left and The Right" title="The Left and The Right">#The Left and The Right</div>
                  <div class="hashtag" @click="routeBtn('/en/unit3-3')" @keydown.enter="routeBtn('/en/unit3-3')" tabindex="0" alt="Speech Forums" title="Speech Forums">#Speech Forums</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="turn-page" v-if="page=='turn'">
      <div class="person person-1" v-if="personArr[0]">
        <div class="name-div">
          If<span>Lien Wen-ching</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">Linguist</div>
          <div class="line"></div>
          <div class="content-text">Hoping that the artificial language of Esperanto can transcend national beliefs and promote world peace, resolving disputes caused by differences in race and language.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-58-連溫卿-插畫3.png" alt="Linguist" title="Linguist">
        </div>
        <div class="content content-2">
          <div class="title">Leader</div>
          <div class="line"></div>
          <div class="content-text">Inviting or waiting for supporters to follow, hoping for the emergence of the right circumstances to lead a large-scale political organization and implement large-scale political actions.</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-59-連溫卿-插畫4.png" alt="Leader" title="Leader">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 8vw;" src="../assets/person-intro/20230424-人物介紹-物件-57-連溫卿-插畫2.png" alt="Lien Wen-chin" title="Lien Wen-chin">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[1]">
        <div class="name-div">
          If<span>Tsai Pei-huo</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">Folk Singer</div>
          <div class="line"></div>
          <div class="content-text">Can sing in celebration of Taiwan's autonomy and local culture, anytime, anywhere.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-65-蔡培火-插畫7.png" alt="Folk Singer" title="Folk Singer">
        </div>
        <div class="content content-2">
          <div class="title">Native Language Promoter</div>
          <div class="line2"></div>
          <div class="content-text">Popularize Taiwanese vernacular script.</div>
          <img class="img-21" src="../assets/person-intro/20230711-人物介紹-物件-66-蔡培火-插畫8.png" alt="Native Language Promoter" title="Native Language Promoter">
        </div>
        <div class="content content-3">
          <div class="title">Politician</div>
          <div class="line"></div>
          <div class="content-text">Must possess an expansive perspective, open-mindedness, determination, and perseverance, unafraid of obstacles and difficulties along the way. He or she must embrace challenges head-on, show respect for politics, and steadfastly adhere to their beliefs.</div>
          <img class="img-31" src="../assets/person-intro/20230711-人物介紹-物件-67-蔡培火-插畫9.png" alt="Politician" title="Politician">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 10.5vw; left: 6.5vw; " src="../assets/person-intro/20230424-人物介紹-物件-64-蔡培火-插畫6.png" alt="Tsai Pei-huo" title="Tsai Pei-huo">
        </div>
      </div>
      <!--img2-->
      <!--img3-->
      <div class="person person-1" v-if="personArr[4]">
        <div class="name-div">
          If<span>Hsieh Hsueh-hung</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">Tour Guide</div>
          <div class="line"></div>
          <div class="content-text">To advocate for Taiwan's democracy and strive for its autonomy, it is essential to assemble a strong team of dedicated activists who love their homeland and possess resilience.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-81-謝雪紅-插畫14.png" alt="Tour Guide" title="Tour Guide">
        </div>
        <div class="content content-2" style="left: 73.3%">
          <div class="title">Socially Concerned Individual</div>
          <div class="line2" style="width: 16vw"></div>
          <div class="content-text">As pioneers of an organization, it is crucial to remain updated on international social movement trends at all times.</div>
          <img class="img-21" src="../assets/person-intro/20230711-人物介紹-物件-82-謝雪紅-插畫15.png" alt="Socially Concerned Individual" title="Socially Concerned Individual">
        </div>

        <div class="circle">
          <img style="top: -2vw; width: 10vw; left: 6.5vw; " src="../assets/person-intro/20230424-人物介紹-物件-80-謝雪紅-插畫13.png" alt="Hsieh Hsueh-hung" title="Hsieh Hsueh-hung">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[5]">
        <div class="name-div">
          If<span>Jian E</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">Activist</div>
          <div class="line"></div>
          <div class="content-text">Her small stature belied a strong spirit and determination, fearless in the face of threats and courageous in taking action.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-88-簡娥-插畫18.png" alt="Activist" title="Activist">
        </div>
        <div class="content content-2">
          <div class="title">Modern Woman</div>
          <div class="line"></div>
          <div class="content-text">As a progressive woman of her time, she demonstrated remarkable adaptability and agility. To avoid police pursuit, adopting disguises and altering her appearance became a necessity.</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-89-簡娥-插畫19.png" alt="Modern Woman" title="Modern Woman">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 10vw; left: 7vw;" src="../assets/person-intro/20230424-人物介紹-物件-87-簡娥-插畫17.png" alt="Jian E" title="Jian E">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[6]">
        <div class="name-div">
          If<span>Chen Zhiqi</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">Passionate Youth</div>
          <div class="line"></div>
          <div class="content-text">Yearning for freedom and democracy, they actively pursue their own rights.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-95-陳植棋-插畫22.png" alt="Passionate Youth" title="Passionate Youth">
        </div>
        <div class="content content-2">
          <div class="title">Western Painter</div>
          <div class="line"></div>
          <div class="content-text">Famous quote: "Life is short, but art is eternal." A life dedicated to the pursuit of art.</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-96-陳植棋-插畫23.png" alt="Western Painter" title="Western Painter">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 17.2vw; left: 4vw; " src="../assets/person-intro/20230424-人物介紹-物件-94-陳植棋-插畫21.png" alt="Chen Zhiqi" title="Chen Zhiqi">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[7]">
        <div class="name-div">
          If<span>Chuang Sung-lin</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">YouTuber</div>
          <div class="line"></div>
          <div class="content-text">Through theater performances, the goal is to raise awareness about social classes and provide a fresh, eye-opening experience for everyone.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-102-莊松林-插畫26.png" alt="YouTuber" title="YouTuber">
        </div>
        <div class="content content-2">
          <div class="title">Language Learner</div>
          <div class="line"></div>
          <div class="content-text">Learning Esperanto starts with listening, aiming to bridge cultural divides through the use of this global language.</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-103-莊松林-插畫27.png" alt="Language Learner" title="Language Learner">
        </div>
        <div class="content content-3">
          <div class="title">Internet Commentator</div>
          <div class="line"></div>
          <div class="content-text">Engaging in extensive reading and active debates to challenge and break through rigid social norms.</div>
          <img class="img-31" src="../assets/person-intro/20230711-人物介紹-物件-104-莊松林-插畫28.png" alt="Internet Commentator" title="Internet Commentator">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 16.6vw; left: -1.5vw; " src="../assets/person-intro/20230711-人物介紹-物件-101-莊松林-插畫25.png" alt="Chuang Sung-lin" title="Chuang Sung-lin">
        </div>
      </div>
      <!--img8-->
      <!--img9-->
      <div class="person person-1" v-if="personArr[10]">
        <div class="name-div">
          If<span>Tsai Yuan-tang</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">Landowner</div>
          <div class="line"></div>
          <div class="content-text">Advocating not just for their own land, but also carefully determining the rightful benefits for sugarcane farmers.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-119-蔡淵騰-插畫34.png" alt="Landowner" title="Landowner">
        </div>
        <div class="content content-2">
          <div class="title">Agricultural Organizer</div>
          <div class="line"></div>
          <div class="content-text">To truly care for farmers, one must begin with hands-on experience, standing in their shoes to genuinely understand their circumstances.</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-118-蔡淵騰-插畫33.png" alt="Agricultural Organizer" title="Agricultural Organizer">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 9vw; left: 7.5vw; " src="../assets/person-intro/20230424-人物介紹-物件-117-蔡淵騰-插畫32.png" alt="Tsai Yuan-tang" title="Tsai Yuan-tang">
        </div>
      </div>
      <!--img11-->
      <div class="person person-1" v-if="personArr[12]">
        <div class="name-div">
          If<span>Chen Cheng-po</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">Lover of Taiwan's Scenery</div>
          <div class="line2"></div>
          <div class="content-text">He had a deep love for Taiwan's landscapes, and all of his works were painted outdoors. When a reporter inquired about his darker complexion, he attributed it to the strong sunlight in Chiayi.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-129-陳澄波-插畫38.png" alt="Lover of Taiwan's Scenery" title="Lover of Taiwan's Scenery">
        </div>
        <div class="content content-2">
          <div class="title">"Slashie"</div>
          <div class="line"></div>
          <div class="content-text">An artist/politician/social activist, bringing an artist's romanticism to the realms of politics and activism, advocating for the people's demands.</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-130-陳澄波-插畫39.png" alt="Slashie" title="Slashie">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 12.5vw; left: 5vw; " src="../assets/person-intro/20230424-人物介紹-物件-128-陳澄波-插畫37.png" alt="Chen Cheng-po" title="Chen Cheng-po">
        </div>
      </div>
      <!--img13-->
      <!--img14-->
      <div class="person person-1" v-if="personArr[15]">
        <div class="name-div">
          If<span>Su Bi-hui</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">Time Manager</div>
          <div class="line"></div>
          <div class="content-text">The Clock Tower, donated by Su Bi-hui and his family, is inscribed with the text "TEMPO ESTA  MONO", which means "Time is Money" in Esperanto.  </div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-144-蘇璧輝-插畫44.png" alt="Time Manager" title="Time Manager">
        </div>
        <div class="content content-2">
          <div class="title">Language Promoter</div>
          <div class="line"></div>
          <div class="content-text">Actively promoted Esperanto in the hopes it would become the medium for international communication.</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-145-蘇璧輝-插畫45.png" alt="Language Promoter" title="Language Promoter">
        </div>

        <div class="circle">
          <img style="top: -2vw; width: 10.2vw; left: 7vw; " src="../assets/person-intro/20230424-人物介紹-物件-143-蘇璧輝-插畫43.png" alt="Su Bi-hui" title="Su Bi-hui">
        </div>
      </div>
      <!--img16-->
      <div class="person person-1" v-if="personArr[17]">
        <div class="name-div">
          If<span>Chiang Wei-shui</span>live in today
        </div>
        <div class="content content-5">
          <div class="title">Doctor</div>
          <div class="line"></div>
          <div class="content-text">As a doctor, employed "Clinical Notes" as a metaphor to address Taiwan's issues, with the aim of awakening its people.</div>
          <img class="img-5" src="../assets/person-intro/20230711-人物介紹-物件-155-蔣渭水-插畫49.png" alt="Doctor" title="Doctor">
        </div>
        <div class="content content-11">
          <div class="title">Reformer</div>
          <div class="line"></div>
          <div class="content-text">Utilizes the keyboard, offers timely commentary, and commands swift responses online.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-157-蔣渭水-插畫51.png" alt="Reformer" title="Reformer">
        </div>
        <div class="content content-2">
          <div class="title">Folksinger</div>
          <div class="line"></div>
          <div class="content-text">Skillfully reworked lyrics to create memorable melodies for all, including the "Taiwan Cultural Association Anthem" and the "Labor Day Song."</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-156-蔣渭水-插畫50.png" alt="Folksinger" title="Folksinger">
        </div>
        <div class="content content-3">
          <div class="title">Social Movement Leader</div>
          <div class="line2"></div>
          <div class="content-text">Determined and unafraid of attacks or attempts to tarnish his reputation.</div>
          <img class="img-3" src="../assets/person-intro/20230711-人物介紹-物件-158-蔣渭水-插畫52.png" alt="Social Movement Leader" title="Social Movement Leader">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 10vw; left: 6vw; " src="../assets/person-intro/20230424-人物介紹-物件-154-蔣渭水-插畫48.png" alt="Chiang Wei-shui" title="Chiang Wei-shui">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[18]">
        <div class="name-div">
          If<span>Han Shi-quan</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">Doctor</div>
          <div class="line"></div>
          <div class="content-text">Listening to the inner thoughts of grassroots farmers and laborers, advocating for them.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-164-韓石泉-插畫55.png" alt="Doctor" title="Doctor">
        </div>
        <div class="content content-2">
          <div class="title">Medical Records and Books</div>
          <div class="line2"></div>
          <div class="content-text">Carrying medical records of farmers and laborers, along with books to disseminate healthy lifestyle ideas.</div>
          <img class="img-21" src="../assets/person-intro/20230711-人物介紹-物件-165-韓石泉-插畫56.png" alt="Medical Records and Books" title="Medical Records and Books">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 10vw; left: 7vw; " src="../assets/person-intro/20230424-人物介紹-物件-163-韓石泉-插畫54.png" alt="Han Shi-quan" title="Han Shi-quan">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[19]">
        <div class="name-div">
          If<span>Li Ying-zhang</span>live in today
        </div>
        <div class="content content-1">
          <div class="title">Activist</div>
          <div class="line"></div>
          <div class="content-text">Riding motorcycles to rural villages, much like modern doctors who visit remote areas for medical consultations, providing medical services while swiftly taking action for agricultural organizations.</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-171-李應章-插畫59.png" alt="Activist" title="Activist">
        </div>
        <div class="content content-2">
          <div class="title">Doctor</div>
          <div class="line"></div>
          <div class="content-text">Diagnosing the unfair treatment of farmers from a doctor's perspective and advocating for them.</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-172-李應章-插畫60.png" alt="Doctor" title="Doctor">
        </div>

        <div class="circle">
          <img style="top: -2vw; width: 15vw; left: 6.6vw; " src="../assets/person-intro/20230424-人物介紹-物件-170-李應章-插畫58.png" alt="Li Ying-zhang" title="Li Ying-zhang">
        </div>
      </div>
      <!--img20-->
      <!--img21-->
      <!--img22-->
      <!--img23-->
      <!--img24-->
      <div class="turn-div" @click="page='normal'" @keydown.enter="page='normal'" tabindex="0">
        <div style="margin-top: 1.1vw">Back</div>
        <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
        <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png"  :alt="page=='turn'?'Back':'If they live in today……'" :title="page=='turn'?'Back':'If they live in today……'">
      </div>
    </div>

    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-56-連溫卿-pic2-放大文物.png" alt="Lien Wen-chin" title="Lien Wen-chin">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Lien Wen-ching</div>
            <div class="noto-text" >Courtesy of National Tsing Hua University</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-63-蔡培火-pic4-放大文物.png" alt="Tsai Pei-huo" title="Tsai Pei-huo">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Tsai Pei-huo</div>
            <div class="noto-text" >Collection of the National Museum of Taiwan Literature Donated by Lin Zhang-feng</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-71-謝文達-pic6-放大文物.png" alt="Hsieh Wen-ta" title="Hsieh Wen-ta">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Hsieh Wen-ta</div>
            <div class="noto-text" >Courtesy of the National Central Library</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-75-林獻堂-pic8-放大文物.png" alt="Lin Hsien-tang" title="Lin Hsien-tang">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Lin Hsien-tang</div>
            <div class="noto-text" >Collection of the National Museum of Taiwan Literature Donated by Lin Zhang-feng</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
        <!-- <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-79-謝雪紅-pic10-放大文物.png" alt="Hsieh Hsueh-hung" title="Hsieh Hsueh-hung">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title">Hsieh Hsueh-hung</div>
            <div class="noto-text" >Courtesy of NUEVA VISION CO., LTD.</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-86-簡娥-pic12-放大文物.png" alt="Jian E" title="Jian E">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Jian E</div>
            <div class="noto-text" >Courtesy of the family of Zuang Yong-ming</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-93-陳植棋-pic14-放大文物.png" alt="Chen Zhiqi" title="Chen Zhiqi">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Chen Zhiqi</div>
            <div class="noto-text" >Academic Institute of Taiwan History, Academia Sinica Archives Courtesy of Chen Zi-zhi</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img7--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[7]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-100-莊松林-pic16-放大文物.png" alt="Chuang Sung-lin" title="Chuang Sung-lin">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Chuang Sung-lin</div>
            <div class="noto-text" >Courtesy of Zhuang Ming-zheng</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[7]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img8--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[8]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-108-盧丙丁-pic18-放大文物.png" alt="Lu Bing-ding" title="Lu Bing-ding">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Lu Bing-ding</div>
            <div class="noto-text" >Collection of the National Museum of Taiwan Literature Donated by Lin Zhang-feng</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[8]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img9--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[9]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-112-王詩琅-pic20-放大文物.png" alt="Wang Shi-lang" title="Wang Shi-lang">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Wang Shi-lang</div>
            <div class="noto-text" >Courtesy of the family of Zhuang Yong-ming</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[9]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img10--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[10]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-116-蔡淵騰-pic22-放大文物.png" alt="Tsai Yuan-tang" title="Tsai Yuan-tang">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Tsai Yuan-tang</div>
            <div class="noto-text" >Courtesy of Tsai Shih Chieh</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[10]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img11--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[11]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-123-蔡惠如-pic24-放大文物.png" alt="Tsai Hui-ju" title="Tsai Hui-ju">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Tsai Hui-ju</div>
            <div class="noto-text" >Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[11]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img12--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[12]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-127-陳澄波-pic26-放大文物.png" alt="Chen Cheng-po" title="Chen Cheng-po">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Chen Cheng-po</div>
            <div class="noto-text" >Courtesy of  Chen Cheng-po Cultural Foundation</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[12]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img13--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[13]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-134-陳懷澄-pic28-放大文物.png" alt="Chen Huai-cheng" title="Chen Huai-cheng">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Chen Huai-cheng</div>
            <div class="noto-text" >Courtesy of Li Zhao-rong</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[13]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img14--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[14]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-138-林幼春-pic30-放大文物.png" alt="Lin You-chun" title="Lin You-chun">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Lin You-chun</div>
            <div class="noto-text" >Collection of the National Museum of Taiwan Literature Donated by Lin Zhang-feng</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[14]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img15--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[15]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-142-蘇璧輝-pic32-放大文物.png" alt="Su Bi-hui" title="Su Bi-hui">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Su Bi-hui</div>
            <div class="noto-text" >Courtesy of Zheng Hui-mei</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[15]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img16--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[16]">
        <!-- <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-149-杜香國-pic34-放大文物.png" alt="Du Xiang-gu" title="Du Xiang-gu">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Du Xiang-guo</div>
            <div class="noto-text" >National Museum of Taiwan History Collection 2020.032.0252 </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[16]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img17--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[17]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-153-蔣渭水-pic36-放大文物.png" alt="Chiang Wei-shui" title="Chiang Wei-shui">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Chiang Wei-shui</div>
            <div class="noto-text" > Courtesy of Chiang Wei-shui Cultural Foundation</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[17]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img18--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[18]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-162-韓石泉-pic38-放大文物.png" alt="Han Shi-quan" title="Han Shi-quan">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Han Shi-quan</div>
            <div class="noto-text" > Courtesy of Han Liang-cheng</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[18]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img19--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[19]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-169-李應章-pic40-放大文物.png" alt="Li Ying-zhang" title="Li Ying-zhang">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Li Ying-zhang</div>
            <div class="noto-text" >Courtesy of Dr. Leon Chen, author of The Boy in the Corn Field</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[19]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img20--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[20]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-176-賴和-pic42-放大文物.png" alt="Loa Ho" title="Loa Ho">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Loa Ho</div>
            <div class="noto-text" >Courtesy of Loa Ho Cultural and Educational Foundation</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[20]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img21--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[21]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-180-林淇漳-pic44-放大文物.png" alt="Lin Qi-zhang" title="Lin Qi-zhang">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Lin Qi-zhang</div>
            <div class="noto-text" >Courtesy of Lin Lu Xiu-lan</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[21]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img22--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[22]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-184-楊肇嘉-pic46-放大文物.png" alt="Yang Zhao-jia" title="Yang Zhao-jia">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Yang Zhao-jia</div>
            <div class="noto-text" >Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[22]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img23--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[23]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-188-簡吉-pic48-放大文物.png" alt="Jian Ji" title="Jian Ji">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Jian Ji</div>
            <div class="noto-text" >Courtesy of CGCH Foundation for Education</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[23]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img24--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[24]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-192-王敏川-pic50-放大文物.png" alt="Wang Min-chuan" title="Wang Min-chuan">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 50%; transform: translate(-50%,0%); width: auto;">
            <div class="bold-title" >Wang Min-chuan</div>
            <div class="noto-text" > Courtesy of Chiang Wei-shui Cultural Foundation</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[24]=false">
          </div>
        </div>
      </div>
    </transition>
    <en-intro-btn ></en-intro-btn>
    <FooterFeature language='en' color="black"></FooterFeature>
  </div>
</template>

<script>
import router from "@/router";
import {reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import {useRoute} from "vue-router/dist/vue-router";
import myMixin from "@/assets/common/js/mixin";
import EnToolbar from "@/components/EnToolbar.vue";
import EnIntroBtn from "@/components/EnIntroBtn.vue";
export default {
  name: "PersonIntroView",
  mixins: [myMixin],
  components:{
    EnIntroBtn,
    EnToolbar,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const headPage = ref(0);
    const page = ref('normal');
    const personArr = reactive(Array.from({ length: 25 }, () => false));
    const showImg = reactive(Array.from({ length: 25 }, () => false));
    const route = useRoute();

    if(route.params.num == ''){
      personArr[0] = true;
    }else{
      personArr[route.params.num] = true;
      //personArr[0] = true;
      if(route.params.num > 17){
        headPage.value = 2;
      }
      else if(route.params.num > 8){
        headPage.value = 1;
      }
    }

    function routeBtn(path){
      router.push(path);
    }

    function rightHeadBtn(){
      headPage.value = Math.min(headPage.value+1, 2);
    }
    function leftHeadBtn(){
      headPage.value = Math.max(headPage.value-1, 0);
    }

    function changePerson(num){
      page.value = 'normal';
      for (let i=0; i<personArr.length;i++)
        personArr[i] = false;
      personArr[num] = true;
      routeBtn('/en/person-intro/'+num);
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }


    return{
      routeBtn,
      page,
      personArr,
      headPage,
      rightHeadBtn,
      leftHeadBtn,
      showImg,
      changePerson,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/personintro.css"></style>
