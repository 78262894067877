<template>
  <div class="wrap" >
    <toolbar type="2" unit="5" route-name="privacy" color="brown"></toolbar>
<!--    <img class="sample" src="../assets/common/sample/20231101-網站-共用按鈕-or-0-隱私權及安全政策宣示.jpg">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="content-text">
      <div class="title">隱私權及安全政策宣示</div>
      <div style="margin-top: 1vw">歡迎您光臨國立臺灣歷史博物館網站（以下簡稱本館網站）！本館網域所登載之資料歡迎連結，如要重製、複製或散佈請來文取得本館同意。為了讓您能夠更安心的使用本館網站所提供的各項服務措施，特此向您說明本館網站資訊安全政策如下：</div>
      <div class="content">
      <div class="subtitle" style="margin-top: 2.2vw"><span class="number2">一‧</span>關於政策適用範圍</div>
        <p>以下的隱私權政策，適用於您在本館網站活動時，所涉及的個人資料蒐集、運用與保護，但不適用於與本館網站功能連結之各政府機關網站或其他網站。凡經由本館連結之網站，不論是否由本館所經營，各網站均有其專屬之隱私權政策，本站不負任何連帶責任。當您在這些網站進行活動時，關於個人資料的保護，適用各該網站的隱私權政策。</p>
      </div>
      <div class="content">
      <div class="subtitle" style="margin-top: 0.2vw"><span class="number2">二‧</span>個人資料之蒐集及運用</div>
        <p>單純在本館網站的瀏覽及檔案下載行為，本館網站並不會蒐集任何有關個人的身份資料。</p>
        <p style="margin-top: 0.2vw">利用本館網站所提供的各項線上申請服務，需申請人提供個人資料時，各承辦機關會依案件辦理需求請您提供姓名、身分證字號、聯絡電話、e-mail、通訊住址等個人最新、最真實之資料。</p>
        <p style="margin-top: 0.1vw">如您透過電子郵件或服務信箱反映您的意見或詢問相關業務問題時，我們會保存您的通訊記錄，僅作為與您聯絡之用。</p>
        <p style="margin-top: 0.1vw">網站伺服器會記錄使用者上網的IP位址、上網時間以及在網站內所瀏覽的網頁等資料，這些資料係供本館網站管理單位內部作網站流量和網路行為調查的總量分析，以利於提昇本館網站的服務品質，且僅對全體使用者行為總和進行分析，並不會對個別使用者進行分析。</p>

        <p style="position:relative; margin-top: 0.1vw;left: 1vw;"><span class="number">1.</span>國立臺灣歷史博物館有義務保護各申請人隱私，非經您本人同意不會自行修改或刪除任何個人資料及檔案。除非符合以下情況始得為之：</p>

        <div class="item">
          <p class="relative" style="left: 1vw;"><span class="number">‧</span>違反本館網站規定事項，如在論壇中出現謾罵或做人身攻擊的言論時。</p>
          <p class="relative" style="left: 1vw;"><span class="number">‧</span>保護或防衛相關個人的權利或所有權。</p>
          <p class="relative" style="left: 1vw;"><span class="number">‧</span>為保護本館網站各相關單位之權益。</p>
        </div>

        <p style="position:relative; margin-top: 0.4vw;left: 1vw;"><span class="number">2.</span>國立臺灣歷史博物館絕不會任意提供出售、交換、或出租任何您的個人資料給其他團體、個人或私人企業。但有下列情形者除外：</p>

        <div class="item">
          <p class="relative" style="left: 1vw;"><span class="number">‧</span>配合司法單位合法的調查。</p>
          <p class="relative" style="left: 1vw;"><span class="number">‧</span>配合相關職權機關依職務需要之調查或使用。</p>
          <p class="relative" style="left: 1vw;"><span class="number">‧</span>基於善意相信揭露為法律需要，或為維護和改進網站服務而用於管理。</p>
        </div>
      </div>
      <div class="content">
      <div class="subtitle" style="margin-top: 0.2vw"><span class="number2">三‧</span>資訊安全及保護</div>
        <p>本館網站的伺服器設有「防火牆」作保護，防止非法入侵、破壞或竊取資料，當您使用本館網站之各項服務功能時，任何儲存於本館網站上之資料，會受到完善保護，以保障您的個人資料安全。</p>

      <div class="subtitle" style="margin-top: 0.2vw"><span class="number2">四‧</span>自我資料安全保護措施</div>
        <p>請妥善保管您的任何個人資料，切勿將個人資料提供給任何第三人，以保障您的權益。並提醒您，您自願於網際網路上（如在討論區、留言板、聊天區..等）透露個人資料，均可能會被他人蒐集和使用，您可能會收到他人主動提供的電子郵件。</p>

      <div class="subtitle" style="margin-top: 2.2vw"><span class="number2">五‧</span>隱私權保護政策之諮詢</div>
        <p>若您對國立臺灣歷史博物館的隱私權保護宣告有任何疑問，歡迎與我們聯絡！</p>
      </div>

    </div>

    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import Toolbar from "@/components/Toolbar";
import FooterFeature from "@/components/FooterFeature";

export default {
  name: "PrivacyView",
  components:{
    Toolbar,
    FooterFeature
  },
  setup(){


    return{

    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Privacy.css"></style>
