<template>
  <div class="wrap" @mousemove="handleMouseMove" @keydown.esc="escEvent">
    <toolbar type="2" unit="3" route-name="unit3-2" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit3-2/sample/20230414-對位-3-2-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">

      <div class="title-logo">演劇與電影</div>
      <img class="img-1 " src="../assets/Unit3-2/20230414-物件-3-2-25-首圖１.png" alt="演劇與電影" title="演劇與電影">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-2/3-2-0.json')" alt="演劇與電影" title="演劇與電影" ></LottieTest>
      </div>

      <div class="loop-img ">
        <img style="position: relative; width: 26.3vw; margin: 0.5vw 0 0 -0.8vw; max-width: none; opacity: 0" src="../assets/Unit3-2/20230414-物件-3-2-03-pic2.png"  alt="演劇與電影" title="演劇與電影">
        <img style="position: absolute; width: 26.3vw; top: -0.15%; left: -3.3%; max-width: none; " :style="loopImgStyle[0]" :src="loopImgSrc[0].value"  alt="演劇與電影" title="演劇與電影">
        <img style="position: absolute; width: 26.3vw; top: -0.15%; left: -3.3%; max-width: none; " :style="loopImgStyle[1]" :src="loopImgSrc[1].value"  alt="演劇與電影" title="演劇與電影">
      </div>
      <div class="part-color" style="position: absolute; bottom: 0; width: 20vw; height: 6vw; background: white;"></div>
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit3-2/20230414-物件-3-2-05-裝飾字1-文化劇.png" alt="文化劇" title="文化劇">
      <div class="content-1 content-style">臺灣傳統戲劇多以王侯將相故事，或陳腐的愛情戲劇為主，熱愛現代文藝的新時代青年，自然興起截然不同的娛樂活動。他們以日常語言編寫劇本，時裝登上舞臺演出，尤其愛批判頑固的社會倫理，稱為「文化劇」。活躍於草屯的「炎峰青年會」，新竹的「新光劇團」，臺南的「臺南文化劇團」、「安平劇團」等，都是由當地文藝家編劇、演出，觀眾回響也相當熱烈。</div>

      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-2/20230414-物件-3-2-07-pic5.png" alt="臺南文化劇團演出實況" title="臺南文化劇團演出實況">
        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png">
      </div>

      <div class="content-2">
        <p class="title-style" style="color: #267c68">臺南文化劇團演出實況<span class="year">1927年10月14-17日</span></p>
        <p class="subtitle-style" style="margin-top: 0.5vw"></p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣文學館典藏 林章峯捐贈</p>
        <p class="content-style" style="margin-top: 0.5vw;">臺南文化劇團在臺南市南座進行第二回演藝會表演，網羅了當時反殖民陣營不少「名角」同臺演出，盧丙丁（左1 ）、莊松林、林占鰲、蔡培火、梁加升、王受祿、黃金火、韓石泉等三十餘位共襄盛舉。</p>
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-2/3-2-1.json')" alt="文化劇" title="文化劇" ></LottieTest>
        <img class="json-img" src="../assets/Unit3-2/20230414-物件-3-2-06-pic4.png"  alt="文化劇" title="文化劇">
      </div>
    </div>

    <div class="part3 part-color">
      <div class="img-1">
        <img class="h-full" src="../assets/Unit3-2/20230414-物件-3-2-08-pic6.png" alt="文化劇演出現場直擊" title="文化劇演出現場直擊">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0"  style="width: 2.2vw; bottom: 2%; right: 4%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="img-2">
        <img class="h-full" src="../assets/Unit3-2/20230414-物件-3-2-09-pic7.png"  alt="文化劇演出現場直擊" title="文化劇演出現場直擊">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0"  style="width: 2.2vw; bottom: 2%; right: 4%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #267c68">文化劇演出現場直擊</p>
        <p class="subtitle-style" style="margin-top: 0.5vw"></p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣文學館典藏  林章峯捐贈</p>
        <p class="content-style" style="margin-top: 0.5vw;">此兩張照片出自於林氏好、盧丙丁夫婦的相簿，記錄當時文化劇演出的舞臺效果。畢業於臺灣總督府醫學校的韓石泉、黃金火、王受祿等3位醫師也經常擔任劇中要角。</p>
      </div>
    </div>

    <div class="part4">
      <img class="top-half" src="../assets/Unit3-2/HalfRound.png">
      <div class="section-1 part-color">
          <img class="logo-text" src="../assets/Unit3-2/20230616-物件-3-2-10-裝飾字2-活動寫真.png" alt="活動寫真" title="活動寫真">
          <div class="content-1 content-style">活動寫真，也就是電影。文化協會的電影播放起源於專務理事蔡培火購置的放映機與影片，1926年起巡迴各地，播放的電影內容有國外經濟情勢介紹、現代夫妻情誼、激勵學習，也穿插怡情的幽默片，一旁有解說員（辯士）從旁輔以本土情事，帶議題風向，現場也有警察監視，屢遭中止的情形。</div>
          <div class="json-2"><LottieTest :json="require('@/assets/Unit3-2/3-2-3.json')" ></LottieTest></div>
          <div class="json-3"><LottieTest :json="require('@/assets/Unit3-2/3-2-4.json')" ></LottieTest></div>
          <img class="img-1" src="../assets/Unit3-2/20230414-物件-3-2-12-插畫1.png" alt="活動寫真" title="活動寫真">
          <img class="img-2" src="../assets/Unit3-2/20230414-物件-3-2-14-插畫2.png" alt="活動寫真" title="活動寫真">
          <div class="video-div" >
            <video id="video"  class="w-full" ref="videoRef" @click="playVideo" @pause="videoPausedEvent">
              <source src="../assets/Unit3-2/video4.mp4"
                      type="video/mp4">
            </video>
          </div>
        <div class="video-touch"  @mousemove="customCursorStyle='opacity:1'" @mouseleave="customCursorStyle='display:none'" @click="playVideo" @keydown.enter="playVideo" tabindex="0"></div>
          <div class="content-2">
            <p class="title-style" style="color: #ffffff">活動寫真隊帶你去北極</p>
            <p class="subtitle-style" style="margin-top: 0.5vw">《北方的南奴克》</p>
            <p class="source-style" style="margin-top: 0.3vw">維基共享資源，公眾領域</p>
            <p class="content-style" style="margin-top: 0.5vw;">據記載，文協第一批的電影放映內容，有《丹麥之農耕情況》、《丹麥之合作事業》、《紅的十字架》、《北極動物之生態》等片名，但至今已不可考。這裡播放《北方的南奴克》（英語：Nanook of the North，1922）一片，感受當時觀影的氣氛。 本片以住在加拿大北方、名為南奴克的男性為主角，記載北極圈原住民因紐特人的日常生活，包括與白人的毛皮交易、冰天雪地中捕魚、獵海象等等，呈現其艱苦奮鬥的一天。 歡迎擔任「文協活寫隊」的辯士，來為大家解說這部電影吧。</p>
          </div>

        <img class="zoom-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 31%; right: 40.8%; z-index: 5" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">

      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-2/3-2-2.json')" ></LottieTest>
      </div>
    </div>

    <div class="part5">
      <img class="top-half" src="../assets/Unit3-2/HalfRound_2-02.png">
      <div class="section-1 part-color">
        <div class="json-1"><LottieTest :json="require('@/assets/Unit3-2/3-2-5.json')" ></LottieTest></div>

        <div class="content-1">
          <p class="title-style" style="color: #267c68">音樂普及新生活</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">美臺團音樂普及會會規與入會書</p>
          <p class="source-style" style="margin-top: 0.3vw">中央研究院臺灣史研究所檔案館 提供</p>
          <p class="content-style" style="margin-top: 0.5vw;">活動寫真隊是1925年蔡培火購置器材創辦，而後文協分裂，蔡另立「美臺團」巡迴演出。1933年訂定會規，為日文與漢文對照版本，以研究音樂、純化人心，特於家庭生活振作清新高雅之氣風為目的。</p>
        </div>

        <div class="img-1">
          <img class="w-full" src="../assets/Unit3-2/20230414-物件-3-2-20-pic9.png" alt="美臺團音樂普及會會規與入會書" title="美臺團音樂普及會會規與入會書">
          <img class="zoom-2-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png">
        </div>

        <div class="json-2"><LottieTest :json="require('@/assets/Unit3-2/3-2-6.json')" ></LottieTest></div>

        <div class="back-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0">
          <img src="../assets/common/left-arrow-green.png" alt="回廟埕拚文化" title="回廟埕拚文化">
          <p style="margin-left: 0.5vw">回廟埕拚文化</p>
        </div>
      </div>
    </div>
    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>

    <div class="custom-cursor" ref="customCursor" :style="customCursorStyle">
      <img class="w-full" :src="customCursorImg" >
    </div>

    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-02.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.7vw; margin:2.8vw 0 0 13.8vw" src="../assets/Unit3-2/20230704-物件-3-2-07-pic5-放大文物.png" alt="臺南文化劇團演出實況" title="臺南文化劇團演出實況">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 20.5vw; width: 22.1vw;">
            <div >
              <div class="bold-title">臺南文化劇團演出實況 1927年10月14-17日</div>
              <div class="noto-text" style="margin-top: 0.4vw">國立臺灣文學館典藏  林章峯捐贈</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-03.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.7vw; margin:2.8vw 0 0 13.8vw" src="../assets/Unit3-2/20230704-物件-3-2-08-pic6-放大文物.png" alt="文化劇演出現場直擊" title="文化劇演出現場直擊">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 24.8vw; width: 14.1vw;">
            <div >
              <div class="bold-title">文化劇演出現場直擊</div>
              <div class="noto-text" style="margin-top: 0.4vw">國立臺灣文學館典藏  林章峯捐贈</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-04.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.7vw; margin:2.8vw 0 0 13.8vw" src="../assets/Unit3-2/20230704-物件-3-2-09-pic7-放大文物.png" alt="文化劇演出現場直擊" title="文化劇演出現場直擊">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 24.8vw; width: 14.1vw;">
            <div >
              <div class="bold-title">文化劇演出現場直擊</div>
              <div class="noto-text" style="margin-top: 0.4vw">國立臺灣文學館典藏  林章峯捐贈</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--hor-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-06.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:12.5vw; margin:3.5vw 0 0 13.5vw" src="../assets/Unit3-2/20230704-物件-3-2-19-pic8.png" alt="大活動寫真隊" title="大活動寫真隊">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 38.7vw; width: 21vw;">
            <div class="bold-title">「大活動寫真隊」旗</div>
            <div class="noto-text" style="margin-top: 0vw">國立臺灣歷史博物館 館藏 2014.011.0105</div>
            <div class="content-style" style="margin-top: 0.8vw; text-align: justify">這一面「大活動寫真隊」，未能確認是臺灣文化協會所用，但仍可說是反映早期電影史的珍稀文物。在1920年代的照片中，包括文化協會的電影活動現場，常見有類似的長條型旗幟做為宣傳號召，也會懸掛在會場上。</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-05.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:47.2vw; margin:6.1vw 0 0 9.5vw" src="../assets/Unit3-2/20230704-物件-3-2-21-pic9-放大文物.png" alt="美臺團音樂普及會會規與入會書" title="美臺團音樂普及會會規與入會書">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 24vw; width: 16.5vw;">
            <div >
              <div class="bold-title">美臺團音樂普及會會規與入會書</div>
              <div class="noto-text" style="margin-top: 0.4vw">中央研究院臺灣史研究所檔案館   提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import FooterFeature from "@/components/FooterFeature";
import LottieTest from "@/components/LottieTest";
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue";
import ImgVertical from "@/components/ImgVertical";
import myMixin from "@/assets/common/js/mixin";
import {Vue3Marquee} from "vue3-marquee";
import 'vue3-marquee/dist/style.css'

export default {
  name: "Unit3-2",
  mixins: [myMixin],
  components:{
    ImgVertical,
    toolbar,
    IntroBtn,
    FooterFeature,
    LottieTest,
    Vue3Marquee,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const videoRef = ref();
    const customCursor = ref();
    const customCursorStyle = ref('display:none;');
    const videoPaused = ref(true);
    const customCursorImg = ref(require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png'));
    const showImg = reactive(Array.from({ length: 5 }, () => false));
    const loopImgSrc = reactive(Array.from({ length: 2 }, () => ref(null)));
    const loopImgStyle = reactive(Array.from({ length: 2 }, () => ''));
    loopImgSrc[0].value = require('../assets/Unit3-2/20230414-物件-3-2-01-pic1.png');
    loopImgSrc[1].value = require('../assets/Unit3-2/20230414-物件-3-2-03-pic2.png');
    loopImgStyle[1] = 'transform:translate(24.8vw, -3.3vw)';

    onMounted(()=>{
      videoRef.value.currentTime = 3;
    });

    function playVideo(){
      if(videoRef.value.paused){
        if(videoRef.value.currentTime == 3){
          videoRef.value.currentTime = 0;
        }
        videoRef.value.play();
        //customCursor.value.style.opacity = 0;
        customCursorImg.value = require('../assets/common/20230704-物件-6-0-15-影片暫停鍵.png');
        videoPaused.value = false;
        videoRef.value.controls = true;
      }else{
        videoRef.value.pause();
        videoPausedEvent();
      }
    }

    function videoPausedEvent(){
      customCursorImg.value = require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png');
      videoPaused.value = true;
      videoRef.value.controls = false;
    }


    function handleMouseMove(event) {
      customCursor.value.style.left = (event.pageX - customCursor.value.clientWidth /2) + "px" ;
      customCursor.value.style.top = (event.pageY - customCursor.value.clientHeight /2)  + "px";
    }

    function routeBtn(path){
      router.push(path);
    }

    let loopInterval = setInterval(()=>{
      loopAnim();
    }, 5000);
    let loopTimeout = null;


    onUnmounted(()=>{
      clearInterval(loopInterval);
      clearTimeout(loopTimeout);
    });

    let curCenter = 0;
    let nextImg = 1;

    async function loopAnim(){
      switch (nextImg){
        case 0:
          loopImgSrc[(curCenter+1)%2].value = require("../assets/Unit3-2/20230414-物件-3-2-01-pic1.png");
          break;
        case 1:
          loopImgSrc[(curCenter+1)%2].value = require("../assets/Unit3-2/20230414-物件-3-2-03-pic2.png");
          break;
        case 2:
          loopImgSrc[(curCenter+1)%2].value = require("../assets/Unit3-2/20230414-物件-3-2-03-pic3.png");
          break;
      }

      loopImgStyle[curCenter] = 'transform:translate(-25.3vw, 3.325vw); transition: all 0.5s ease-in-out';
      loopImgStyle[(curCenter+1)%2] = 'transform:translate(0vw, 0vw); transition: all 0.5s ease-in-out';

      curCenter = (curCenter+1) % 2;
      nextImg = (nextImg+1) %3;

      loopTimeout = setTimeout(()=>{
        loopImgStyle[(curCenter+1)%2] = 'transform:translate(24.8vw, -3.3vw)';
        loopImgStyle[curCenter] = 'transform:translate(0vw, 0vw)';
      }, 500);
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      videoRef,
      customCursor,
      customCursorStyle,
      playVideo,
      handleMouseMove,
      videoPaused,
      videoPausedEvent,
      customCursorImg,
      showImg,
      loopImgSrc,
      loopImgStyle,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit3-2.css"></style>
