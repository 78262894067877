// mixin.js
import {ref} from "vue";


export default {
    setup(){

        const scaleSize = ref(1)
        const x =  ref(0);
        const y =  ref(0);
        let startX = 0;
        let startY = 0;
        let isdrag = false;

        //加號事件
        window.addEventListener('keydown', function(event) {
            // 检查事件是否是按下 "+" 键（键码为 187）
            if (event.keyCode === 187 || event.key === "+") {
                changeSize('plus')
                event.preventDefault();
            }
        });

        //減號事件
        window.addEventListener('keydown', function(event) {
            // 检查事件是否是按下 "+" 键（键码为 187）
            if (event.keyCode === 189 || event.key === "-") {
                changeSize('minus')
                event.preventDefault();
            }
        });

        function changeSize(zoom){
            if(zoom == 'plus'){
                scaleSize.value = Math.min(scaleSize.value+0.1, 3);
            }else{
                scaleSize.value = Math.max(scaleSize.value-0.1, 1);
            }
        }

        function dragStart(e) {
            startX = e.clientX - x.value;
            startY = e.clientY - y.value;
            window.addEventListener('mousemove', drag);
            window.addEventListener('mouseup', dragEnd);
            isdrag = true;
        }

        function drag(e) {
            if(isdrag){
                x.value = e.clientX - startX;
                y.value = e.clientY - startY;
            }

        }

        function dragEnd() {
            window.removeEventListener('mousemove', drag);
            window.removeEventListener('mouseup', dragEnd);
            isdrag = false;
        }

        function resetPos(){
            x.value = 0;
            y.value = 0;
            scaleSize.value = 1;
        }

        return{
            dragStart,
            drag,
            dragEnd,
            x,
            y,
            resetPos,
            changeSize,
            scaleSize,
        }
    }
}

