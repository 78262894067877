<template>
  <div class="wrap" >
    <toolbar type="2" unit="5" route-name="copyright" color="brown"></toolbar>
<!--    <img class="sample" src="../assets/common/sample/20231101-網站-共用按鈕-or-0-著作權聲明內容.jpg">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="content-text">
      <div class="title">著作權聲明內容</div>
      <div class="subtitle" style="margin-top: 1vw"><span class="number2">一‧</span>《樂為世界人》網站（以下簡稱本網站）刊載之所有內容，除少數不受著作權法保護之情形（例示如本聲明第二項）外，其他包括文字敘述、圖片、照片、錄音、影像及其他著作，不論係以本館或其他個人創作者為著作人名義所發表者，均受著作權法之保護。任何人如欲利用該等受保護著作，除符合著作權法合理使用規定（例示如本聲明第三項）外，應事先取得各該著作著作財產權人之授權或同意，始得為之。</div>

      <div class="subtitle" style="margin-top: 0.2vw"><span class="number2">二‧</span>不受著作權法保護之情形，舉例如下：</div>
      <div class="content">
        <p class="relative" style="left: 0vw;"><span class="number">1.</span>屬於著作權法第9條所定不得為著作權標的之情形：例如，憲法、法律、命令或公文（公文，包括公務員於職務上草擬之文告、講稿、新聞稿等），不受著作權法保護，任何人均得自由利用。</p>
        <p class="relative" style="margin-top: 0.1vw;left: 0vw;"><span class="number">2.</span>著作財產權存續期間已屆滿之情形：例如攝影、視聽或錄音著作已公開發表超過50年，語文或美術著作之著作人已死亡超過50年者。但仍不得侵害其著作人格權，僅得自由利用其著作財產權（著作權法第18、43條規定）。</p>
      </div>

      <div class="subtitle" style="margin-top: 0.2vw"><span class="number2">三‧</span>依著作權法得合理使用本網站著作之情形，舉例說明如下：</div>
      <div class="content">
        <p class="relative" style="left: 0vw;"><span class="number">1.</span>本網站上所刊載之以本館為著作人名義公開公開發表之著作，在合理範圍內，得加以重製、公開播送或公開傳輸（著作權法第50條規定）。</p>
        <p class="relative" style="margin-top: 0.1vw;left: 0vw;"><span class="number">2.</span>為報導、評論、教學、研究或其他正當目的之必要，在合理範圍內，得引用已公開發表之本網站上著作（著作權法第52條規定）。</p>
        <p class="relative" style="margin-top: 0.1vw;left: 0vw"><span class="number">3.</span>其他得合理使用情形，請參考著作權法第44條至65條規定。</p>
        <p class="relative" style="margin-top: 0.1vw;left: 0vw"><span class="number">4.</span>凡依法合理使用本網站上著作，均應依法明示其出處，且尊重著作人著作人格權。</p>
        <p class="relative" style="margin-top: 0.1vw;left: 0vw"><span class="number">5.</span>利用人應自行確保其利用行為符合著作權法合理使用及相關規定。</p>
      </div>

      <div class="subtitle" style="margin-top: 0.1vw"><span class="number2">四‧</span>除了合於著作權法第80條之1第1項但書規定之情形（非移除或變更著作權利管理電子資訊即無法合法利用著作、或因錄製或傳輸系統轉換時之轉換技術上所必要者）外，本網站任何著作所標示之權利管理電子資訊，不得擅自移除或變更；對於業經非法移除或變更者，亦不得擅自散布、意圖散布而輸入或持有、公開播送、公開演出或公開傳輸。</div>


    </div>

    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import Toolbar from "@/components/Toolbar";
import FooterFeature from "@/components/FooterFeature";

export default {
  name: "PrivacyView",
  components:{
    Toolbar,
    FooterFeature
  },
  setup(){


    return{

    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Privacy.css"></style>
