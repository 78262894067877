<template xmlns="http://www.w3.org/1999/html">
  <div class="wrap" >
    <EnToolbar type="2" unit="5" route-name="privacy" color="brown"></EnToolbar>
<!--    <img class="sample" src="../assets/common/sample/20231107-臺史博-文協線上展-EN網站-對位-op-隱私權及安全政策宣示.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="content-text">
      <div class="title">Privacy Policy & Security Policy</div>
      <div style="margin-top: 1vw">Welcome to visit the website of the Blessed to be a Global Citizen – the 100th Anniversary of the Taiwan Cultural Association (here in after referred to as “the Website”)!</div>
      <div>For the respect and protection of your safety and privacy while browsing the internet, the Website particularly proclaims the privacy and security policy.</div>
      <div>The following notes state how you will be protected when you are using any services of the Website and how your personal information will be collected, applied and safeguarded. Please read through each of them carefully.</div>
      <div class="content">
      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">1.</span>Application of the Policy</div>
      <p>The privacy policy below is applied to your usage of the Website, involving the collection, use and protection of your personal information; however, any governmental and/or other websites linking through the Website are excluded in this policy. Those websites, no matter run by NMTH or not, formulate their individual privacy policy; NMTH exempts from the joint liability. When you are browsing a website, the protection of your personal information is under the guard of its specific privacy policy.</p>
      </div>
      <div class="content">
      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">2.</span>Collection and Use of Personal Information</div>
        <p>For those simply browsing information and/or downloading programs on the Website, your personal information will not be collected.</p>
        <p>If you make comments or inquiries on mailboxes of the Director or the Service, your personal contact record will be kept for the purpose of communication only.</p>
        <p>Our server will note down the user’s IP address, duration and any browsed pages of the Website. Such information is collected for the investigation of the network traffic and users’ behaviors, allowing the managing body of the Website to improve the quality of services. Any analysis will be conducted based on the entire users not on a singular case.</p>

        <p style="position:relative; margin-top: 0.4vw; left: 1.5vw"><span class="number">(1)</span>It is our obligation to protect the applicant’s privacy. Without your authorization, the Website will not alter or delete your personal information unless the following statuses occurred:</p>

        <div class="item">
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">a)</span>any violation to the regulations of the Website, such as making statements involving abuses or personal attacks,</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">b)</span>for the protection or defense of the related personal rights or ownerships, and</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">c)</span>for the protection of the rights of the related organizations.</p>
        </div>

        <p style="position:relative; margin-top: 0.4vw; left: 1.5vw;"><span class="number">(2)</span>The Website will not trade, exchange or rent out your personal information to any groups, individuals or private enterprises. However, the following situations will be exceptions.</p>

        <div class="item">
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">a)</span>To cooperate with the legal investigation of judicial organizations.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">b)</span>To cooperate with the investigation and usage of the related organization for the need ofadministration.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">c)</span>Based on a good will, it isbelieved that uncovering the personal information is necessary for the legal process or for the maintenance and improvement of internet services.</p>
        </div>
      </div>
      <div class="content">
      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">3.</span>Information Security</div>
      <p>The website employ the intrusion prevention system to monitor the network traffic for identifying any unauthorized attempts on uploading or modifying files or any destruction with intent. We also install firewalls to prevent the acts of illegal intrusion, destruction or theft for avoiding criminal usagesand protecting users’ rights and Install antivirus software toremove viruses periodically for providing users with a safe browsing network. The Website will follow the instruction of related operating systems to update fitting patch from time to time.</p>

      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">4.</span>Self-information Security Precaution</div>
      <p>Please be cautious of your personal information. Do not disclose your personal information to any third party for safe guarding your own rights. You are also advised that your personal information may be collected or used by others when you voluntarily reveal the information on the internet (e.g. on-line forum, bulletin board,chat room, etc.). You may receive e-mails from others taking the initiative.</p>

      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">5.</span>Consultation of Privacy and Security Policy</div>
      <p>If you have any doubt about our Privacy and Security Policy, please do not hesitate to contact us.</p>
      </div>
  </div>

    <FooterFeature language='en' color="black"></FooterFeature>
  </div>
</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import FooterFeature from "@/components/FooterFeature";

export default {
  name: "PrivacyView",
  components:{
    EnToolbar,
    FooterFeature
  },
  setup(){


    return{

    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/en/Privacy.css"></style>
