<template>
  <div  ref="container"></div>
</template>

<script>
import lottie from "lottie-web";
//import jsonData from "@/assets/Unit1-1/01.json";

export default {
  name:"LottieTest",
  props:{
    json:String
  },
  data(){
      return{
        anim:null
      }
  },
  mounted() {

    this.anim = lottie.loadAnimation({
      container: this.$refs.container,
      renderer:'svg',
      loop:true,
      autoplay:true,
      animationData:this.json,

    });
  }
}
</script>

<style scoped>

</style>
