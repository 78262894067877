<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="1" unit="3" route-name="unit4-4" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit4-4/sample/unit4-4.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="img-1" src="../assets/Unit4-4/20230417-4-4-物件-01-首圖1.png" alt="反普特刊廣告1930年9月4日" title="反普特刊廣告1930年9月4日">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-4/4-4-0.json')" alt="風俗改良" title="風俗改良"></LottieTest>
      </div>
      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2vw; top: 34%; left: 69.7%;" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
      <div class="title-logo">風俗改良</div>
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit4-4/20230417-4-4-物件-04-裝飾字1-破除陋習.png" alt="破除陋習" title="破除陋習">
      <div class="content-1 content-style">
        文協同志的主張，相當大一部分在於推動「陋習」的消滅，是對傳統生活方式全方位的檢討。
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-4/4-4-1.json')" alt="風俗改良" title="風俗改良"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <div class="info-window" style="top: 13%; left: 9.5%;" :class="cardStatus[0]=='front'?'':'flip'">
        <div class="front" >
          <img class="img" src="../assets/Unit4-4/20230417-4-4-物件-05-翻牌1_Alpha.png" alt="宗教活動" title="宗教活動">
          <div class="content">
            <p class="title-style" style="color:#ea5518;">宗教活動</p>
            <p class="content-style" style="margin-top: 0.5vw; text-align: justify;">臺灣人素著重實際的宗教活動，鋪張的建醮、繞境開銷均由百姓捐獻，文協青年認為這是百姓負擔的「神明債」，應加以革除。</p>
          </div>
          <div class="blue-btn" @click="cardStatus[0]='back'" @keydown.enter="cardStatus[0]='back'" tabindex="0" alt="宗教活動的鋪張" title="宗教活動的鋪張">宗教活動的鋪張</div>
        </div>
        <div class="back">
          <div class="content">
            <p class="title-style" style="color:#ea5518;">宗教活動的鋪張</p>
            <p class="content-style" style="margin-top: 0.5vw; text-align: justify;">臺灣本地的宗教活動原本都是屬於地方性質的小型慶典，但因為1910年代交通發展、商業熱絡，宗教活動愈形鋪張，甚至會炫富競爭，文協青年認為此種鋪張浪費的行為，不但迷信，更過度耗費社會資源，應該簡樸或廢止。</p>
            <div class="card-back-btn" @click="cardStatus[0]='front'" @keydown.enter="cardStatus[0]='front'" tabindex="0">
              <img src="../assets/common/left-arrow-green.png" alt="回宗教活動" title="回宗教活動">
              <p style="margin-left: 0.5vw">回宗教活動</p>
            </div>
          </div>
        </div>

      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-4/20230417-4-4-物件-07-pic2.png" alt="《反普特刊》" title="《反普特刊》">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.3vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #ffffff">打倒一切的迷信</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">《反普特刊》</p>
        <p class="source-style" style="margin-top: 0.3vw">莊明正 提供</p>
        <p class="content-style" style="margin-top: 0.5vw">在臺南的「赤崁勞働青年會」，1929年大力推動反普度行動，1930年9月將相關宣言論述集結成冊，編印成《反普特刊》，收錄莊松林、林宣鰲、林占鰲、林秋梧、朱點人、廖毓文的文章，由興文齋書局發行，是當時反普度運動的代表作。</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-4/4-4-2.json')" alt="宗教活動" title="宗教活動"></LottieTest>
      </div>
    </div>

    <div class="part4 part-color">
      <div class="content-1">
        <p class="title-style" style="color: #ffffff">代訂婚約的衝突</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">《臺灣民報》〈父母代訂的婚約要怎樣設法〉</p>
        <p class="source-style" style="margin-top: 0.3vw">《臺灣民報》第1冊66</p>
        <p class="content-style" style="margin-top: 0.5vw">這篇臺灣民報上的問答專欄，是一位女子來信詢問父母代訂婚約該如何處理，顯示出新舊風俗的衝突。</p>
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-4/20230417-4-4-物件-12-pic3.png" alt="《臺灣民報》〈父母代訂的婚約要怎樣設法〉" title="《臺灣民報》〈父母代訂的婚約要怎樣設法〉">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.3vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="info-window" style="top: 13%; left: 69%;" :class="cardStatus[1]=='front'?'':'flip'">
        <div class="front" >
          <img class="img" src="../assets/Unit4-4/20230417-4-4-物件-10-翻牌2_Alpha.png" alt="婚姻禮俗" title="婚姻禮俗">
          <div class="content">
            <p class="title-style" style="color:#ea5518;">婚姻禮俗</p>
            <p class="content-style" style="margin-top: 0.5vw; text-align: justify;">在婚姻禮俗上，強調父母應尊重自由戀愛，取消聘金，女性應進取於社會，學習新的家政與現代衛生觀念，但也應排斥過於激進、罔顧家庭責任的行動。</p>
          </div>
          <div class="blue-btn" style="margin-top: 0.3vw" @click="cardStatus[1]='back'" @keydown.enter="cardStatus[1]='back'" tabindex="0" alt="婚姻禮俗的改造" title="婚姻禮俗的改造">婚姻禮俗的改造</div>
        </div>
        <div class="back" >
          <div class="content">
            <p class="title-style" style="color:#ea5518;">婚姻禮俗的改造</p>
            <p class="content-style" style="margin-top: 0.5vw; text-align: justify;">當時的婚姻習慣，大多都是由媒人說媒、父母訂婚的方式進行，並由女方收取聘金。高額聘金如同「人身買賣」，宣導應廢除聘金制度。韓石泉等幾位文協成員也以身作則，以自己的婚禮作為戀愛結婚、簡化典禮的「文明結婚」案例。</p>
            <div class="card-back-btn" @click="cardStatus[1]='front'" @keydown.enter="cardStatus[1]='front'" tabindex="0" >
              <img src="../assets/common/left-arrow-green.png" alt="回婚姻禮俗" title="回婚姻禮俗">
              <p style="margin-left: 0.5vw">回婚姻禮俗</p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="part5 part-color">
      <div class="info-window" style="top: 13%; left: 9.5%;" :class="cardStatus[2]=='front'?'':'flip'">
        <div class="front" >
          <img class="img" src="../assets/Unit4-4/20230417-4-4-物件-13-翻牌3_Alpha.png" alt="歌仔戲" title="歌仔戲">
          <div class="content" >
            <p class="title-style" style="color:#ea5518;">歌仔戲</p>
            <p class="content-style" style="margin-top: 0.5vw; text-align: justify;">文協對當時巡迴演出的「歌仔戲」，認為他們總演出傷風敗俗的舊劇，男女成群遊走、穢亂社會秩序，而加以排斥。</p>
          </div>
          <div class="blue-btn" style="margin-top: 2.3vw" @click="cardStatus[2]='back'" @keydown.enter="cardStatus[2]='back'" tabindex="0" alt="歌仔戲的大眾魅力" title="歌仔戲的大眾魅力">歌仔戲的大眾魅力</div>
        </div>
        <div class="back"  >
          <div class="content" style="padding: 0 1.5vw 0 1.5vw">
            <p class="title-style" style="color:#ea5518;">歌仔戲的大眾魅力</p>
            <p class="content-style" style="margin-top: 0.5vw; text-align: justify;">歌仔戲為當時臺灣本土的劇種，是大眾最熱衷的娛樂，除了野台演出之外，也發展出售票的內台戲及職業劇團。當時雖然也有普遍被認為較有藝術價值的「正音劇」（京劇），文協更發展新式的「文化劇」，不過都不如歌仔戲活潑受大眾歡迎。</p>
            <div class="card-back-btn" @click="cardStatus[2]='front'" @keydown.enter="cardStatus[2]='front'" tabindex="0" >
              <img src="../assets/common/left-arrow-green.png" alt="回歌仔戲" title="回歌仔戲">
              <p style="margin-left: 0.5vw">回歌仔戲</p>
            </div>
          </div>
        </div>

      </div>

      <div class="content-1">
        <p class="title-style" style="color: #ffffff">抗議！有人演歌仔戲</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">大甲地方遏止歌仔戲聯署陳情書</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2019.031.0298</p>
        <p class="content-style" style="margin-top: 0.5vw">
          本文件為大正15年（1926年）大甲地方遏止歌仔戲聯署陳情書，由彭清靠等帶頭聯署，要求警察單位嚴加取締歌仔戲班的演出。內容指出戲劇本是懲惡勸善帶有藝術味的，而且有劇團演員誘拐良家婦女，更重要的是，當地婦女看戲入迷太晚睡，白天打瞌睡，有礙於藺草帽製造產業。所以要求警察單位嚴禁歌仔戲班在大甲地區活動。彭清靠（1890-1955）為彭明敏父親，1914年至1933年間在臺中大甲開設德仁醫院，在地方上頗有威望。杜清、杜香國、黃清波等文協、民眾黨的要員也有參加連署。</p>
      </div>

      <div class="book-wrap">
<!--        <img class="book w-full relative" :style="bookStyle[0]" :src="book[0].value" style="z-index: 7">-->
<!--        <img v-for="n in 6" :key="n" class="w-full absolute top-0 book " :style="bookStyle[n]+' z-index:'+(7-n)+';'"  :src="book[n].value">-->
        <div class="turnClass">
          <div id="flipbook" alt="大甲地方遏止歌仔戲聯署陳情書" title="大甲地方遏止歌仔戲聯署陳情書">
            <img class="page page-1 " :src="require('../assets/Unit4-4/17-pic5-翻頁動態效果/17-pic5-翻頁動態-00.png')">
            <img class="page page-2 " :src="require('../assets/Unit4-4/18-pic6-翻頁動態效果/18-pic6-翻頁動態-00.png')">
            <img class="page page-3 " :src="require('../assets/Unit4-4/19-pic7-翻頁動態效果/19-pic7-翻頁動態-00.png')">
            <img class="page page-4 " :src="require('../assets/Unit4-4/20-pic8-翻頁動態效果/20-pic8-翻頁動態-00.png')">
            <img class="page page-5 " :src="require('../assets/Unit4-4/21-pic9-翻頁動態效果/21-pic9-翻頁動態-00.png')">
            <img class="page page-6 " :src="require('../assets/Unit4-4/22-pic10-翻頁動態效果/22-pic10-翻頁動態-00.png')">
            <img class="page page-7 " :src="require('../assets/Unit4-4/20230417-4-4-物件-23-pic11.png')">
          </div>
        </div>

        <transition name="img-frame-fade"><img v-if="bookPage>1" @click="bookLeftBtn" @keydown.enter="bookLeftBtn" tabindex="0" class="left-arrow"  src="../assets/common/left-arrow-white.png" alt="上一頁" title="上一頁"></transition>
        <img class="zoom-2-btn zoom-fade " @click="showImg[bookPage+2]=true" @keydown.enter="showImg[bookPage+2]=true" tabindex="0"  style="width: 2.2vw; bottom: 5%; right: 3%; z-index: 10" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        <transition name="img-frame-fade"><img v-if="bookPage<7" @click="bookRightBtn" @keydown.enter="bookRightBtn" tabindex="0" class="right-arrow"  src="../assets/common/right-arrow-white.png" alt="下一頁" title="下一頁"></transition>
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-4/4-4-4.json')" alt="人" title="人" ></LottieTest>
      </div>

      <div class="back-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0" alt="回廟埕拚文化" title="回廟埕拚文化">
        <img src="../assets/common/left-arrow-white.png" alt="回廟埕拚文化" title="回廟埕拚文化">
        <p style="margin-left: 0.5vw">回廟埕拚文化</p>
      </div>
    </div>

    <!--寫這裡-->

    <!--img0--><!--hor-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--         <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-09.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:4.6vw 0 0 3.5vw" src="../assets/Unit4-4/20230703-4-4-物件-02-pic1.png" alt="反普特刊廣告1930年9月4日" title="反普特刊廣告1930年9月4日">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 38.8vw; width: 16.4vw;">
            <div class="bold-title">反普特刊廣告1930年9月4日</div>
            <div class="noto-text" style="margin-top: 0.2vw">莊明正 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--          <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-10.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.6vw; margin:2.4vw 0 0 23.3vw" src="../assets/Unit4-4/20230703-4-4-物件-07-pic2-放大文物.png" alt="《反普特刊》" title="《反普特刊》">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 28.9vw; width: 8.2vw;">
            <div >
              <div class="bold-title">《反普特刊》</div>
              <div class="noto-text" style="margin-top: 0.3vw">莊明正 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--          <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-18.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:42.7vw; margin:6.2vw 0 0 10.7vw" src="../assets/Unit4-4/20230703-4-4-物件-12-pic3-放大文物.png" alt="《臺灣民報》〈父母代訂的婚約要怎樣設法〉" title="《臺灣民報》〈父母代訂的婚約要怎樣設法〉">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 21vw; width: 22.6vw;">
            <div >
              <div class="bold-title">《臺灣民報》〈父母代訂的婚約要怎樣設法〉</div>
              <div class="noto-text" style="margin-top: 0.3vw">《臺灣民報》第 1 冊 66</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--          <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-11.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.5vw; margin:2.8vw 0 0 13.7vw" src="../assets/Unit4-4/20230703-4-4-物件-17-pic5-放大文物.png" alt="大甲地方遏止歌仔戲聯署陳情書" title="大甲地方遏止歌仔戲聯署陳情書">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.2vw; width: 17.4vw;">
            <div >
              <div class="bold-title">大甲地方遏止歌仔戲聯署陳情書</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[4]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-12.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37vw; margin:2.4vw 0 0 13.5vw" src="../assets/Unit4-4/20230703-4-4-物件-18-pic6-放大文物.png" alt="大甲地方遏止歌仔戲聯署陳情書" title="大甲地方遏止歌仔戲聯署陳情書">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.2vw; width: 17.4vw;">
            <div >
              <div class="bold-title">大甲地方遏止歌仔戲聯署陳情書</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-13.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.4vw; margin:2.4vw 0 0 13.3vw" src="../assets/Unit4-4/20230703-4-4-物件-19-pic7-放大文物.png" alt="大甲地方遏止歌仔戲聯署陳情書" title="大甲地方遏止歌仔戲聯署陳情書">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.2vw; width: 17.4vw;">
            <div >
              <div class="bold-title">大甲地方遏止歌仔戲聯署陳情書</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
        <!--         <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-14.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.4vw; margin:2.1vw 0 0 13.3vw" src="../assets/Unit4-4/20230703-4-4-物件-20-pic8-放大文物.png" alt="大甲地方遏止歌仔戲聯署陳情書" title="大甲地方遏止歌仔戲聯署陳情書">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.2vw; width: 17.4vw;">
            <div >
              <div class="bold-title">大甲地方遏止歌仔戲聯署陳情書</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img7--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[7]">
        <!--         <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-15.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.6vw; margin:2.4vw 0 0 13.1vw" src="../assets/Unit4-4/20230703-4-4-物件-21-pic9-放大文物.png" alt="大甲地方遏止歌仔戲聯署陳情書" title="大甲地方遏止歌仔戲聯署陳情書">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.2vw; width: 17.4vw;">
            <div >
              <div class="bold-title">大甲地方遏止歌仔戲聯署陳情書</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[7]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img8--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[8]">
        <!--                 <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.8vw; margin:2.5vw 0 0 13.5vw" src="../assets/Unit4-4/20230703-4-4-物件-22-pic10-放大文物.png" alt="大甲地方遏止歌仔戲聯署陳情書" title="大甲地方遏止歌仔戲聯署陳情書">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.2vw; width: 17.4vw;">
            <div >
              <div class="bold-title">大甲地方遏止歌仔戲聯署陳情書</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[8]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img9--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[9]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.5vw; margin:2.1vw 0 0 13.4vw" src="../assets/Unit4-4/20230703-4-4-物件-23-pic11-放大文物.png" alt="大甲地方遏止歌仔戲聯署陳情書" title="大甲地方遏止歌仔戲聯署陳情書">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.2vw; width: 17.4vw;">
            <div >
              <div class="bold-title">大甲地方遏止歌仔戲聯署陳情書</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[9]=false">
          </div>
        </div>
      </div>
    </transition>

    <FooterFeature color="black"></FooterFeature>
    <intro-btn></intro-btn>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import {onMounted, reactive, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import turn from "@/assets/utils/lib/turn";
import $ from "jquery";

export default {
  name: "Unit4-4",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));
    const cardStatus = reactive(Array.from({ length: 3 }, () => 'front'));
    const book = reactive(Array.from({ length: 7 }, () => ref()));
    const bookStyle = reactive(Array.from({ length: 7 }, () => ''));
    const flipAudio = require('@/assets/Unit3-1/insert_paper.mp3');
    const flipEle = new Audio(flipAudio);

    function onTurn(){
      $("#flipbook").turn({
        acceleration:true,
        display: "single",
        //gradients: true,
        autoCenter: true,
        height:646,
        width:1500,
        direction: "rtl",
      });


      $('#flipbook').turn('size', '100%', '100%');
      $("#flipbook").bind("turning", function(event, page, view) {
        bookPage.value = page;
        flipEle.play();
      });
      $("#flipbook").bind("start", function(event, pageObject, corner) {
        $('#flipbook').turn('size', 'auto', '100%');
      });

    }


    onMounted(()=>{
      bookInit();

      bookPage.value = 1;
      onTurn();
      window.addEventListener('resize', ()=>{
        $('#flipbook').turn('size', 'auto', '100%');
      });
    });

    function bookInit(){
      book[0].value = require('../assets/Unit4-4/17-pic5-翻頁動態效果/17-pic5-翻頁動態-00.png');
      book[1].value = require('../assets/Unit4-4/18-pic6-翻頁動態效果/18-pic6-翻頁動態-00.png');
      book[2].value = require('../assets/Unit4-4/19-pic7-翻頁動態效果/19-pic7-翻頁動態-00.png');
      book[3].value = require('../assets/Unit4-4/20-pic8-翻頁動態效果/20-pic8-翻頁動態-00.png');
      book[4].value = require('../assets/Unit4-4/21-pic9-翻頁動態效果/21-pic9-翻頁動態-00.png');
      book[5].value = require('../assets/Unit4-4/22-pic10-翻頁動態效果/22-pic10-翻頁動態-00.png');
      book[6].value = require('../assets/Unit4-4/20230417-4-4-物件-23-pic11.png');

      bookStyle[0] = '';
      bookStyle[1] = 'opacity:0;';
      bookStyle[2] = 'opacity:0;';
      bookStyle[3] = 'opacity:0;';
      bookStyle[4] = 'opacity:0;';
      bookStyle[5] = 'opacity:0;';
      bookStyle[6] = 'opacity:0;';
    }


    let bookId = null;
    let bookIndex = 0;
    let booking = false;

    const bookPage = ref(1);

    function bookRightBtn(){
      $("#flipbook").turn("next");
      return;
      if(booking || bookPage.value == 6) return;

      booking = true;
      bookIndex = 0;

      clearInterval(bookId);

      if(bookPage.value+1 <= 6){
        bookStyle[bookPage.value+1] = "";
      }

      bookId = setInterval(()=>{

        bookIndex = (bookIndex + 1) % (50+1);

        let img = null;
        switch (bookPage.value){
          case 0: img= require('../assets/Unit4-4/17-pic5-翻頁動態效果/17-pic5-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 1: img=require('../assets/Unit4-4/18-pic6-翻頁動態效果/18-pic6-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 2: img=require('../assets/Unit4-4/19-pic7-翻頁動態效果/19-pic7-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 3: img=require('../assets/Unit4-4/20-pic8-翻頁動態效果/20-pic8-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 4: img=require('../assets/Unit4-4/21-pic9-翻頁動態效果/21-pic9-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 5: img=require('../assets/Unit4-4/22-pic10-翻頁動態效果/22-pic10-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
        }


        if(bookPage.value < 6){
          book[bookPage.value].value = img;
        }

        if(bookIndex >=  50){
          bookStyle[bookPage.value] = 'opacity:0;';
          clearInterval(bookId);
          bookPage.value= Math.min(bookPage.value+1, 6);
          booking = false;
        }

      }, 50);

    }

    function bookLeftBtn(){
      $("#flipbook").turn("previous");
      return;
      if(booking || bookPage.value == 0) return;
      booking = true;

      bookIndex = 50;
      clearInterval(bookId);

      bookId = setInterval(()=>{

        bookIndex = bookIndex-1;

        if(bookPage.value-1 >= 0){
          bookStyle[bookPage.value-1] = "";
        }

        let img = null;
        switch (bookPage.value){
          case 1: img= require('../assets/Unit4-4/17-pic5-翻頁動態效果/17-pic5-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 2: img=require('../assets/Unit4-4/18-pic6-翻頁動態效果/18-pic6-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 3: img=require('../assets/Unit4-4/19-pic7-翻頁動態效果/19-pic7-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 4: img=require('../assets/Unit4-4/20-pic8-翻頁動態效果/20-pic8-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 5: img=require('../assets/Unit4-4/21-pic9-翻頁動態效果/21-pic9-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 6: img=require('../assets/Unit4-4/22-pic10-翻頁動態效果/22-pic10-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png');break;
        }

        book[bookPage.value-1].value = img;

        if(bookIndex <= 0){
          clearInterval(bookId);
          bookStyle[bookPage.value] = 'opacity:0;';
          bookPage.value= Math.max(bookPage.value-1, 0);
          booking = false;
        }

      }, 50);

    }


    function routeBtn(path){
      router.push(path);
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      cardStatus,
      book,
      bookStyle,
      bookPage,
      bookRightBtn,
      bookLeftBtn,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit4-4.css"></style>
