<template>
  <div class="back-btn" :style="style" @click="routeBtn('/enter')">
    <img src="../assets/unit3BackBtn.png"><div>回 34 單元入口</div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Unit3BackBtn",
  props:{
    style : String
  },
  setup(){

    function routeBtn(path){
      router.push(path);
    }

    return{
      routeBtn
    }
  }
}
</script>

<style scoped>

.back-btn{
  position: absolute;
  display: flex;
  align-items: center;
  width: 11%;
  //bottom: 1%;
  //left: 2.5%;
  font-size: calc(1vw * var(--font-size));
  cursor: pointer;
}

.back-btn img{
  width: 25.5%;
}
</style>
