<template>
  <div class="wrap w-full relative">
    <toolbar type="0"></toolbar>
    <img class="w-full relative" src="../assets/menuPage.png">

    <div class="test-btn home-btn" @click="routeBtn('/')"></div>
    <div class="test-btn unit1-1-btn" @click="routeBtn('/unit1-1')"></div>
    <div class="test-btn unit1-2-btn" @click="routeBtn('/unit1-2')"></div>

    <div class="test-btn unit2-1-btn" @click="routeBtn('/unit2-1')"></div>
    <div class="test-btn unit2-2-btn" @click="routeBtn('/unit2-2')"></div>
    <div class="test-btn unit2-3-btn" @click="routeBtn('/unit2-3')"></div>
    <div class="test-btn unit2-4-btn" @click="routeBtn('/unit2-4')"></div>
    <div class="test-btn unit2-5-btn" @click="routeBtn('/unit2-5')"></div>

    <div class="test-btn enter-btn" @click="routeBtn('/enter')"></div>
    <div class="test-btn unit3-1-btn" @click="routeBtn('/unit3-1')"></div>
    <div class="test-btn unit3-2-btn" @click="routeBtn('/unit3-2')"></div>
    <div class="test-btn unit3-3-btn" @click="routeBtn('/unit3-3')"></div>
    <div class="test-btn unit3-4-btn" @click="routeBtn('/unit3-4')"></div>
    <div class="test-btn unit3-5-btn" @click="routeBtn('/unit3-5')"></div>

    <div class="test-btn unit4-1-btn" @click="routeBtn('/unit4-1')"></div>
    <div class="test-btn unit4-2-btn" @click="routeBtn('/unit4-2')"></div>
    <div class="test-btn unit4-3-btn" @click="routeBtn('/unit4-3')"></div>
    <div class="test-btn unit4-4-btn" @click="routeBtn('/unit4-4')"></div>
    <div class="test-btn unit4-5-btn" @click="routeBtn('/unit4-5')"></div>

    <div class="test-btn unit6-2-btn" @click="routeBtn('/unit6-2')"></div>

    <intro-btn></intro-btn>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";

export default {
  name: "MenuView",
  components:{
    toolbar,
    IntroBtn
  },
  setup(){
    function routeBtn(path){
      router.push(path);
    }

    return{
      routeBtn
    }
  }
}
</script>

<style scoped>

.test-btn{
  position: absolute;
  //background: black;
  //opacity: 0.5;
  width: 12vw;
  height: 1.5vw;
  cursor: pointer;
}

.home-btn{
  top: 16.3%;
  left: 43%;
  height: 2vw;
}

.unit1-1-btn{
  top: 29.5%;
  left: 43%;
}

.unit1-2-btn{
  top: 33.5%;
  left: 43%;
}

.unit2-1-btn{
  top: 29.5%;
  left: 69%;
}

.unit2-2-btn{
  top: 33.5%;
  left: 69%;
}

.unit2-3-btn{
  top: 37.5%;
  left: 69%;
}

.unit2-4-btn{
  top: 41.5%;
  left: 69%;
}

.unit2-5-btn{
  top: 45.5%;
  left: 69%;
}

.enter-btn{
  top: 52.5%;
  left: 43%;
  width: 13.5vw;
  height: 2vw;
}

.unit3-1-btn{
  top: 58%;
  left: 43%;
}

.unit3-2-btn{
  top: 61.5%;
  left: 43%;
}

.unit3-3-btn{
  top: 65.5%;
  left: 43%;
}

.unit3-4-btn{
  top: 69.5%;
  left: 43%;
}

.unit3-5-btn{
  top: 73.5%;
  left: 43%;
}

.unit4-1-btn{
  top: 58%;
  left: 56.5%;
}

.unit4-2-btn{
  top: 61.5%;
  left: 56.5%;
}

.unit4-3-btn{
  top: 65.5%;
  left: 56.5%;
}

.unit4-4-btn{
  top: 69.5%;
  left: 56.5%;
}

.unit4-5-btn{
  top: 73.5%;
  left: 56.5%;
}

.unit6-2-btn{
  top: 58%;
  left: 69.5%;
  width: 21vw;
}


</style>
