<template>
  <div class="wrap" >
    <EnToolbar type="2" unit="5" route-name="AboutUs" color="brown"></EnToolbar>
<!--    <img class="sample" src="../assets/common/sample/20231107-臺史博-文協線上展-EN網站-對位-op-關於我們.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="content-text">
      <div class="title">About Us</div>
      <div class="content" style="margin-top: 1vw">Changing a person’s concept of the world can change that person’s behavior. A change in a group of people’s concepts of the world can change the destiny of a country. </div>
      <div class="content" style="margin-top: 0.1vw">
        <p>The Taiwan Cultural Association was one of many social movement organizations that existed between 1921 and 1931. Its membership barely exceeded 1,000, and it was not the first social movement grouping in Taiwan’s history. However, its members embraced new concepts of the modern world and had the courage and enthusiasm to put their thoughts into practice. They attempted to change the worldview of the era’s nearly four million Taiwanese people, while trying to alter the destiny not just of Taiwan, but also of the world.</p>
      </div>

      <div class="content" style="margin-top: 0.3vw">
        <p>When the group was founded, Chiang Wei-shui composed the song, "The Song of the Taiwan Cultural Association":</p>
        <div class="subtitle">We hope our missions will be fulfilled in the end, and we are blessed to be global citizens. Long live the people of the world, and Taiwan has a widely respected reputation.</div>
      </div>

      <div class="content" style="margin-top: 0.2vw">
        <p>What were their missions? What did they actually do and what was achieved? What spirit did they embrace? What changes took place in the process of enthusiastic participation? A century later, are we blessed to be global citizens as they hoped?</p>
      </div>

      <div class="content" style="margin-top: 0vw">
        <p>Looking back at that era, their conscious and dream-seeking journeys are displayed before us.</p>
      </div>

      <div class="content" style="margin-top: 0.2vw">
        <p>"Blessed to be a global citizen" exhibition website is an online exhibition based on the 2021"Blessed to be a Global Citizen – the 100th Anniversary of the Taiwan Cultural Association" exhibition at National Museum of Taiwan History. The website is built and run by National Museum of Taiwan History.</p>
      </div>

      <div class="content" style="margin-top: 2.5vw">
        <div class="subtitle" ><p>Contact</p></div>
        <p><b>Tel | </b>06-356-8889</p>
        <p><b>Email | </b>exhservice@nmth.gov.tw</p>
        <p><b>Address | </b>No.250, Sec 1, Changhe Rd., Annan District, Tainan City 709025</p>
        <p><b>Website | </b>www.nmth.gov.tw</p>
      </div>



    </div>

    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import FooterFeature from "@/components/FooterFeature";

export default {
  name: "PrivacyView",
  components:{
    EnToolbar,
    FooterFeature
  },
  setup(){


    return{

    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/en/Aboutus.css"></style>
