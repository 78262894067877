<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="1" unit="3" route-name="unit4-3" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit4-3/sample/unit4-3.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="img-1" src="../assets/Unit4-3/20230417-4-3-物件-01-首圖1.png" alt="交誼機關" title="交誼機關">
      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.1vw; bottom: 10.4%; right: 7.3%;" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-3/4-3-0.json')" alt="交誼機關" title="交誼機關"></LottieTest>
      </div>
      <div class="title-logo">交誼機關</div>
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit4-3/20230417-4-3-物件-04-裝飾字1-聚會場所.png" alt="聚會場所" title="聚會場所">
      <div class="content-1 content-style">
        臺北「蓬萊閣」及「江山樓」、臺南「松金樓」及臺中「永樂樓」都是日治時期著名的高級臺菜飯店，是文協同仁聚會之場所，若有重要聚會，也會在劇場舉行。1928年林獻堂環球旅行後的迎接洗塵會，還有臺灣工友聯盟的成立大會，都在蓬萊閣舉辦，足見這些聚會場所在文協活動中扮演的重要角色。
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-05-pic2.png" alt="臺北蓬萊閣橢圓腰子瓷碗" title="臺北蓬萊閣橢圓腰子瓷碗">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.3vw; bottom: 8%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-06-pic3.png" alt="臺北蓬萊閣圓形瓷碗" title="臺北蓬萊閣圓形瓷碗">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.3vw; bottom: 8%; right: -10%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #ffffff">高級飯店</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺北蓬萊閣橢圓腰子瓷碗、臺北蓬萊閣圓形瓷碗</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物 館藏 2020.006.0371、2020.006.0375</p>
        <p class="content-style" style="margin-top: 0.5vw">臺北「蓬萊閣」是日治時期大稻埕著名的高級臺菜飯店，許多文協在臺北的重要活動皆在此舉行。</p>
      </div>

      <div class="img-3">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-07-pic4.png" alt="《臺灣民報》刊載新竹文化食堂計劃" title="《臺灣民報》刊載新竹文化食堂計劃">
        <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.3vw; bottom: 10%; right: 1%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-3">
        <p class="title-style" style="color: #ffffff">聚餐場所的重要性</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">《臺灣民報》刊載新竹文化食堂計劃</p>
        <p class="content-style" style="margin-top: 0.5vw">因為新竹沒有適合聚餐的場所，曾有文協成員提出自行建立「文化食堂」的規劃，並視為一種生活改善的方式。聚會場所對文協成員的重要性可見一般。</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-3/4-3-1.json')" alt="聚會場所" title="聚會場所"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-08-pic5.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-09-pic6.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="img-3">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-10-pic7.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="img-4">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-11-pic8.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="img-5">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-12-pic9.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="img-6">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-13-pic10.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="img-7">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-14-pic11.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="img-8">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-15-pic12.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="img-9">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-16-pic13.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="img-10">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-17-pic14.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="img-11">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-18-pic15.png" alt="機關報的贊助者" title="機關報的贊助者">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #ffffff">機關報的贊助者</p>
        <p class="content-style" style="margin-top: 0.5vw">1922年至1927年間，文化協會以《臺灣民報》刊物為機關報，在其上刊登廣告者，也就是文協的贊助者。</p>
      </div>
    </div>

    <div class="part4 part-color">
      <img class="logo-text" src="../assets/Unit4-3/20230703-4-3-物件-19-裝飾字2-交流新知.png" alt="交流新知" title="交流新知">
      <div class="content content-style">
        <p>文協成立以來，成員便與海外留學生密切聯絡，這些留學生或有利用暑假返鄉演講者，於是各類新興思潮湧現——特別是社會主義與無政府主義思想格外活躍。這些青年懷抱高度理想，發展成小型、具高度行動力的組織，以研究會、讀書會、青年會等名，在文協當中發揮莫大的影響力。</p>
        <p>1923年7月連溫卿、謝文達、蔣渭水等組成的「社會問題研究會」，以研究為訴求發起，指陳社會制度的弊病，該組織因官方取締而無正式活動紀錄，但引發了深遠的思想衝擊。而後一些青年團體非常活躍，如「臺北青年會」、「臺北青年體育會」、「臺北青年讀書會」，有時又以「臺灣無產青年」的名號，其聲明多是議論時政時局，特別關注農工議題，提出批判資本主義政策的主張。</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-3/4-3-2.json')" alt="社會問題研究會" title="社會問題研究會" ></LottieTest>
      </div>
    </div>

    <div class="part5 part-color">
      <img class="logo-text" src="../assets/Unit4-3/20230417-4-3-物件-20-裝飾字3-社會組織.png" alt="社會組織" title="社會組織">
      <div class="content-1 content-style">
        還有許多依不同的社會議題成立創新的社會組織。集結對社會運動熱情知識青年的「勞動青年會」，致力推動降低房屋租金的「借屋人同盟」，推動祭儀改良，而具有互助會性質的「臺南弔慰部」，相當程度也是新的社會組織。這類小集團、多元形態的組織，在整體運動的趨勢中，發揮潛在而不可忽視的力量。
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-21-pic16.png" alt="臺南弔慰部申請單" title="臺南弔慰部申請單">
        <img class="zoom-2-btn" @click="showImg[15]=true" @keydown.enter="showImg[15]=true" tabindex="0" style="width: 2.3vw; bottom: 3%; right: 8%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #267c68">喪葬互助</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺南弔慰部申請單</p>
        <p class="source-style" style="margin-top: 0.3vw">莊明正 提供</p>
        <p class="content-style" style="margin-top: 0.5vw">經社運組織成員介紹，可向「臺南弔慰部」申請葬儀用具，據說主要是提供棺木挑擔的借用。</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-3/4-3-3.json')" alt="人群" title="人群"></LottieTest>
      </div>
    </div>

    <div class="part6 part-color">
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-22-pic17.png" alt="臺南借家人同盟規章" title="臺南借家人同盟規章">
        <img class="zoom-2-btn" @click="showImg[16]=true" @keydown.enter="showImg[16]=true" tabindex="0" style="width: 2.3vw; bottom: 3%; right: 1%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #267c68">租客們，團結起來！</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺南借家人同盟規章</p>
        <p class="source-style" style="margin-top: 0.3vw">莊明正 提供</p>
        <p class="content-style" style="margin-top: 0.5vw">組織目的在推動房租降價，據說成立後確實發揮功效，許多房東同意租金打8折。</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-3/4-3-4.json')" alt="人群" title="人群"></LottieTest>
      </div>

      <div class="back-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="回廟埕拚文化" title="回廟埕拚文化">
        <p style="margin-left: 0.5vw">回廟埕拚文化</p>
      </div>
    </div>

    <!--寫這裡-->
    <!--img0--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-02.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:33.3vw; margin:5vw 0 0 3.7vw" src="../assets/Unit4-3/20230703-4-3-物件_02-pic1-放大文物.png" alt="曾圭角攝於臺北江山樓空中花園照片" title="曾圭角攝於臺北江山樓空中花園照片">
          </div>

          <div class="content-text" style="position: absolute; top:5.35vw; left: 38.7vw; width: 18.5vw;">
            <div class="bold-title">曾圭角攝於臺北江山樓空中花園照片</div>
            <div class="noto-text" style="margin-top: 0.2vw">郭雙富 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-03.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:3.4vw 0 0 14.7vw" src="../assets/Unit4-3/20230703-4-3-物件-05-pic2-放大文物.png" alt="臺北蓬萊閣橢圓腰子瓷碗" title="臺北蓬萊閣橢圓腰子瓷碗">
          </div>

          <div class="content-text" style="position: absolute; top:31.1vw; left: 22.8vw; width: 16.6vw;">
            <div >
              <div class="bold-title">臺北蓬萊閣橢圓腰子瓷碗</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物 館藏 2020.006.0371</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[2]">
        <!-- <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-04.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:26vw; margin:2.8vw 0 0 18.7vw" src="../assets/Unit4-3/20230703-4-3-物件-06-pic3-放大文物.png" alt="臺北蓬萊閣圓形瓷碗" title="臺北蓬萊閣圓形瓷碗">
          </div>

          <div class="content-text" style="position: absolute; top:31.1vw; left: 23.8vw; width: 16.6vw;">
            <div >
              <div class="bold-title">臺北蓬萊閣圓形瓷碗</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物 館藏 2020.006.0375</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!-- <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-05.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:58.4vw; margin:6.1vw 0 0 2.7vw" src="../assets/Unit4-3/20230703-4-3-物件-07-pic4-放大文物.png" alt="《臺灣民報》刊載新竹文化食堂計劃" title="《臺灣民報》刊載新竹文化食堂計劃">
          </div>

          <div class="content-text" style="position: absolute; top:31.1vw; left: 23.1vw; width:19vw;">
            <div >
              <div class="bold-title">《臺灣民報》刊載新竹文化食堂計劃</div>
              <!--<div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物 館藏 2020.006.0375</div>-->
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img15--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[15]">
        <!--         <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:18.4vw; margin:2.5vw 0 0 22.4vw" src="../assets/Unit4-3/20230703-4-3-物件-21-pic16-放大文物.png" alt="臺南弔慰部申請單" title="臺南弔慰部申請單">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 26vw; width: 10.3vw;">
            <div >
              <div class="bold-title">臺南弔慰部申請單</div>
              <div class="noto-text" style="margin-top: 0.3vw">莊明正 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[15]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img16--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[16]">
        <!--    <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-18.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:58vw; margin:9vw 0 0 3vw" src="../assets/Unit4-3/20230703-4-3-物件-22-pic17-放大文物.png" alt="臺南借家人同盟規章" title="臺南借家人同盟規章">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 26.5vw; width: 11.3vw;">
            <div >
              <div class="bold-title">臺南借家人同盟規章</div>
              <div class="noto-text" style="margin-top: 0.3vw">莊明正 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[16]=false">
          </div>
        </div>
      </div>
    </transition>


    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import {reactive, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
export default {
  name: "Unit4-3",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));

    const pic = ref(1);

    function routeBtn(path){
      router.push(path);
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      pic,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit4-3.css"></style>
