<template>
  <div class="wrap">
    <toolbar type="1" unit="5" route-name="guide" color="brown"></toolbar>
<!--    <img class="sample" src="../assets/common/sample/20231101-網站-共用按鈕-or-0-網頁導覽.jpg">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="content-text">
      <div class="title">網站導覽</div>
      <div class="content-1">
        <p>網站依照「無障礙網頁開發規範」原則設計，遵循無障礙網站設計之規範提供網頁導盲磚（:::）、網站導覽（Site Navigator）、鍵盤快速鍵（Access Key）等設計方式。下列為本網站使用鍵盤快速鍵（Access Key）操作方式說明：</p>
        <p>Alt+U：上方主選單連結區，主要有網站導覽、語言選擇、主要選單。</p>
        <p>Alt+C：主要內容區，移至網頁之主要內容區位置。</p>
        <p>Alt+B：下方選單，包含快速選及國立臺灣歷史博物館聯絡資訊。</p>
        <p>+：彈出視窗文物放大。</p>
        <p>-：彈出視窗文物縮小。</p>
        <p>ESC鍵：彈出視窗文物關閉。</p>
      </div>

      <div style="padding: 0 0 0 2.2vw">
        <div class="subtitle" style="margin-top: 2.4vw" @click="routeBtn('/')" @keydown.enter="routeBtn('/')" tabindex="0"><span class="number" >零‧</span>首頁</div>
        <div class="subtitle" style="margin-top: 0.2vw" @click="routeBtn('/unit1-1')" @keydown.enter="routeBtn('/unit1-1')" tabindex="0"><span class="number">一‧</span>鳥瞰世界</div>
        <div class="unit">
          <p @click="routeBtn('/unit1-2')" @keydown.enter="routeBtn('/unit1-2')" tabindex="0"><span class="number2">1-1</span>思潮滾動世界</p>
        </div>

        <div class="subtitle" style="margin-top: 0.2vw" @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/unit2-0')" tabindex="0"><span class="number">二‧</span>文協向前衝</div>
        <div class="unit">
          <p @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0"><span class="number2" >2-1</span>臺灣人的臺灣</p>
          <p @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" tabindex="0"><span class="number2" >2-2</span>為農工大眾主張</p>
          <p @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0"><span class="number2" >2-3</span>左派與右派</p>
          <p @click="routeBtn('/unit2-4')" @keydown.enter="routeBtn('/unit2-4')" tabindex="0"><span class="number2" >2-4</span>政治結社的走向</p>
        </div>

        <div class="subtitle" style="margin-top: 0.2vw" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0"><span class="number">三‧</span>廟埕拼文化</div>
        <div class="unit">
          <p @click="routeBtn('/unit3-1')" @keydown.enter="routeBtn('/unit3-1')" tabindex="0"><span class="number2" >3-1</span>文化講演會</p>
          <p @click="routeBtn('/unit3-2')" @keydown.enter="routeBtn('/unit3-2')" tabindex="0"><span class="number2" >3-2</span>演劇與電影</p>
          <p @click="routeBtn('/unit3-3')" @keydown.enter="routeBtn('/unit3-3')" tabindex="0"><span class="number2" >3-3</span>言論機關</p>
          <p @click="routeBtn('/unit3-4')" @keydown.enter="routeBtn('/unit3-4')" tabindex="0"><span class="number2">3-4</span>社會歌謠</p>
          <p @click="routeBtn('/unit3-5')" @keydown.enter="routeBtn('/unit3-5')" tabindex="0"><span class="number2">3-5</span>世界語</p>
          <p @click="routeBtn('/unit4-1')" @keydown.enter="routeBtn('/unit4-1')" tabindex="0"><span class="number2">3-6</span>時鐘塔</p>
          <p @click="routeBtn('/unit4-2')" @keydown.enter="routeBtn('/unit4-2')" tabindex="0"><span class="number2">3-7</span>文化基地</p>
          <p @click="routeBtn('/unit4-3')" @keydown.enter="routeBtn('/unit4-3')" tabindex="0"><span class="number2">3-8</span>交誼機關</p>
          <p @click="routeBtn('/unit4-4')" @keydown.enter="routeBtn('/unit4-4')" tabindex="0"><span class="number2">3-9</span>風俗的改良</p>
          <p @click="routeBtn('/unit4-5')" @keydown.enter="routeBtn('/unit4-5')" tabindex="0"><span class="number2">3-10</span>自己的紀念日</p>
        </div>

        <div class="subtitle" style="margin-top: 0.2vw" @click="routeBtn('/unit6-2')" @keydown.enter="routeBtn('/unit6-2')" tabindex="0"><span class="number">四‧</span>番外篇</div>
        <div class="subtitle" style="margin-top: 0.2vw" @click="routeBtn('/person-intro-all')" @keydown.enter="routeBtn('/person-intro-all')" tabindex="0"><span class="number">五‧</span>文協人物介紹</div>
      </div>
    </div>

<!--    <div tabindex="0" >Test</div>-->
    <FooterFeature ></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import FooterFeature from "@/components/FooterFeature";
import router from "@/router";

export default {
  name: "GuideView",
  components: {
    toolbar,
    FooterFeature
  },
  setup(){

    function routeBtn(path){
      router.push(path);
    }

    return{
      routeBtn,
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Guide.css"></style>

