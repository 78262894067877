<template>
  <div class="wrap" @keydown.esc="escEvent">
    <EnToolbar type="1" unit="2" route-name="unit2-4" color="orange"></EnToolbar>

<!--    <img class="sample" src="../assets/Unit2-4/en/sample/20230706-EN網站-對位-2-4-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="people">
        <LottieTest :json="require('@/assets/Unit2-4/20230703-2-4-0.json')" alt="The Trend of Political Association" title="The Trend of Political Association"></LottieTest>
      </div>

      <img class="pic-1" src="../assets/Unit2-4/20230407-2-4-物件-op-01-首圖１.png" alt="The Inaugural Meeting of Taiwan Local Autonomy Alliance August 17,1930" title="The Inaugural Meeting of Taiwan Local Autonomy Alliance August 17,1930">
      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; left: 78.2%; z-index: 3" src="../assets/common/zoom-btn.png" alt="more information" title="more information">

      <div class="flag">
        <LottieTest :json="require('@/assets/Unit2-4/20230703-2-4-0-flag.json')" alt="The Trend of Political Association" title="The Trend of Political Association"></LottieTest>
      </div>


      <div class="title-logo">
        <p>The Trend of</p>
        <p style="margin-top: -1vw">Political Association</p>
      </div>
    </div>

    <div class="part2 part-color">
      <div class="title-img"><img style="width: 54.2vw" src="../assets/Unit2-4/en/20230706-EN網站-物件-2-4-03-裝飾字1-政治結社.png" alt="Political Associations" title="Political Associations"></div>
      <div class="content-text" ref="part2Class" tabindex="0">
        <div style="margin-left: 29vw">Taiwanese People’s Party</div>
        <div style="margin-left: 6vw; line-height: 2vw" >
          <p>The Taiwan Local</p>
          <p>Autonomy Alliance</p>
        </div>
      </div>
      <img class="pic-1 " :class="picInitAnim" :style="picStyle[0]" src="../assets/Unit2-4/20230407-2-4-物件-op-04-插圖1.png" alt="Taiwanese People’s Party" title="Taiwanese People’s Party">
      <img class="pic-2 " :class="picInitAnim"  :style="picStyle[1]" src="../assets/Unit2-4/20230407-2-4-物件-op-05-插圖２.png" alt="The Taiwan Local Autonomy Alliance" title="The Taiwan Local Autonomy Alliance">

      <div class="content-frame" ref="contentFrame">
        <div style="margin-right: 10vw">
          <p class="title">Taiwanese People’s Party</p>
          <p class="text" style="width: 26vw;  max-height: 13vw; overflow: scroll; padding-right:15px; ">The Taiwanese People’s Party was established in May 1927. Its objective was “establishing people-oriented politics, rational economic organization, and reforming the social system.” Under the leadership of Chiang Wei-shui, the party was akin to a national liberation movement. It also paid attention to agricultural and industrial movements, while developing workers’ associations, farmers’ associations, youth associations and other organizations, with tens of thousands of members. It used telegrams to appeal to the League of Nations about the opium policy of the Governor-General’s Office of Taiwan, and to protest the suppression of the Musha Incident. In February 1931, the Governor-General ordered the party to disband.</p>
        </div>
        <div>
          <p class="title">The Taiwan Local Autonomy Alliance</p>
          <p class="text">The Taiwan Local Autonomy Alliance, established in August 1930, focused on realizing local autonomy, promoting voting rights and implementing civic rights, and actively participating in elections at all levels. Due to financial problems and social changes, it was finally dissolved in August 1937.</p>
        </div>
      </div>

    </div>

    <div class="part3 part-color">
      <div class="content">
        <p class="en-title-style">Hurry Up and Stamp!</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Taiwanese People’s Party Leaflet: Wake up and demand genuine local autonomy!</p>
        <p class="en-source-style" style="margin-top: 0.3vw">National Museum of Taiwan History 2020.006.0200</p>
        <p class="en-content-style" style=" height: 7.5vw; overflow: auto;">This leaflet, printed for the Taiwanese People's Party, promotes the local autonomy movement. It emphasizes the establishment of a council with decision-making and budget review powers, and encourages the public to leave their stamp as a sign of approval (stamps in Taiwan hold the same weight as signatures in the West). For those who lack a stamp, the leaflet specifically mentions that handprints are also acceptable.</p>
      </div>

      <div class="pic-2">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-08-pic3.png" alt="Taiwanese People’s Party Leaflet: Wake up and demand genuine local autonomy!" title="Taiwanese People’s Party Leaflet: Wake up and demand genuine local autonomy!">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 6%; right: 4%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="pic-1">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-07-pic2.png" alt="Taiwanese People's Party Second Party Congress July 15, 1928" title="Taiwanese People's Party Second Party Congress July 15, 1928">
        <img class="zoom-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 11%; right: 4%;" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
      </div>


      <div class="people">
        <LottieTest :json="require('@/assets/Unit2-4/2-4-1.json')" alt="people" title="people"></LottieTest>
      </div>
    </div>

    <div class="part4 part-color">
      <div class="content-1">
        <p class="en-title-style" style="width: 50vw; font-size: calc(1.7vw * var(--font-size))">A Comprehensive Membership List from Across Taiwan</p>
        <p class="subtitle-style" style="margin-top: 0.5vw;width: 47vw; font-size: calc(1.09vw * var(--font-size))">Taiwanese People's Party Membership Roster, July 1928, showing a total of 707 members</p>
        <p class="en-source-style" style="margin-top: 0.3vw">National Museum of Taiwan History 2020.032.0258</p>
        <p class="en-content-style" style="width: 45vw;" >Among the social movement organizations active during Japanese rule, the Taiwanese People's Party had the most extensive membership roster. This 1928 roster includes 16 branches, with central executives listed under the Taipei branch. Members directly affiliated with the party were primarily from Wenshan County (now Xindian and Shenkeng in New Taipei City and Wenshan in Taipei City), Beidou County (and southern Changhua County), and Nenggao County (now Puli and Guoxing Townships in Nantou County).</p>
      </div>
      <div class="content-2">
        <p class="en-title-style" style="width: 110%; font-size: calc(1.7vw * var(--font-size))">Reject National Extinction, Oppose Opium!</p>
        <p class="subtitle-style" style="margin-top: 0.5vw;width: 107%; font-size: calc(1.09vw * var(--font-size))">Tricolor opium eradication leaflets, January 1930</p>
        <p class="en-source-style" style="margin-top: 0.3vw">National Museum of Taiwan History 2020.032.0277.0002-0004</p>
        <p class="en-content-style" style="width: 45vw">Opium had been prevalent in Taiwan since the late Qing Dynasty. The Taiwan Governor's Office implemented a gradual prohibition and monopoly system to passively control opium and profit from the resulting financial consortiums . On January 8, 1929, a regulation to reissue permits for opium smokers triggered public debate. Consequently, the League of Nations' Far East Opium Investigation Mission sent members to Taiwan for inspection. During the investigation, Chiang Wei-shui's Taiwanese People's Party actively protested, sent telegrams, and directly expressed their opposition to the mission. At the same time, they also launched a propaganda campaign, printing and distributing tricolor leaflets.</p>
      </div>
      <div class="pic-1">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-10-pic4.png" alt="Taiwanese People's Party Membership Roster, July 1928, showing a total of 707 members" title="Taiwanese People's Party Membership Roster, July 1928, showing a total of 707 members">
        <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 4%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>


      <div class="pic-2" :class="picClass[0]">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-11-pic5.png" alt="Tricolor opium eradication leaflets, January 1930" title="Tricolor opium eradication leaflets, January 1930">
      </div>
      <div class="pic-3" :class="picClass[1]">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-12-pic6.png" alt="Tricolor opium eradication leaflets, January 1930" title="Tricolor opium eradication leaflets, January 1930">
      </div>
      <div class="pic-4" :class="picClass[2]">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-08-pic3.png" alt="Tricolor opium eradication leaflets, January 1930" title="Tricolor opium eradication leaflets, January 1930">
      </div>

      <transition name="img-frame-fade"><img class="arrow left-arrow" v-if="picIndex > 0" @click="leftBtn" @keydown.enter="leftBtn" tabindex="0" src="../assets/common/left-arrow-white.png" alt="previous page" title="previous page"></transition>

      <img class="zoom-2-btn" @click="showImg[4+picIndex]=true" @keydown.enter="showImg[4+picIndex]=true" tabindex="0" style="width: 2.2vw; bottom: 10%; right: 17%; z-index: 4" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">

      <transition name="img-frame-fade"><img class="arrow right-arrow" v-if="picIndex < 2" @click="rightBtn" @keydown.enter="rightBtn" tabindex="0"  src="../assets/common/right-arrow-white.png" alt="next page" title="next page" ></transition>


    </div>

    <div class="part5 part-color">
      <div class="unit-btn" style="top: 23%; left: 11.5%; " @click="routeBtn('/en/unit2-0')" @keydown.enter="routeBtn('/en/unit2-0')" tabindex="0">
        <img class="w-full" src="../assets/Unit2-0/文協向前衝按鈕.png" alt="The Taiwan Cultural Association Forges Ahead" title="The Taiwan Cultural Association Forges Ahead">
        <p>The Taiwan Cultural</p>
        <p>Association Forges Ahead</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 38.5%; ">
        <img class="w-full" src="../assets/Unit2-0/臺灣人的臺灣按鈕.png" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0">
        <p>Taiwanese People’s</p>
        <p>Taiwan</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 65%; ">
        <img class="w-full" src="../assets/Unit2-0/為農工大眾主張按鈕.png" alt="Advocating for Farmers and Workers" title="Advocating for Farmers and Workers" @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" tabindex="0">
        <p>Advocating for</p>
        <p>Farmers and Workers</p>
      </div>
      <div class="unit-btn" style="top: 57.5%; left: 25%; ">
        <img class="w-full" src="../assets/Unit2-0/左派與右派按鈕.png" alt="The Left and The Right" title="The Left and The Right" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0">
        <p>The Left and The Right</p>
      </div>
      <div class="unit-btn" style="top: 57.5%; left: 52%; ">
        <img class="w-full"  src="../assets/Unit2-0/20230724-2-4-物件-24-政治結社的走向按鈕-淡.png" alt="The Trend of Political Association" title="The Trend of Political Association" @click="routeBtn('/en/unit2-4')" @keydown.enter="routeBtn('/en/unit2-4')" tabindex="0">
        <p>The Trend of</p>
        <p>Political Association</p>
      </div>
    </div>

    <!--img0--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-04.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35vw; margin:3.5vw 0 0 3.1vw" src="../assets/Unit2-4/20230703-物件-2-4-02-pic1-放大文物.png" alt="The Inaugural Meeting of Taiwan Local Autonomy Alliance" title="The Inaugural Meeting of Taiwan Local Autonomy Alliance">
          </div>

          <div class="content-text" style="top:4.5vw; width: 30.4vw; left: 56vw">
            <div class="bold-title"><p>The Inaugural Meeting of Taiwan Local Autonomy Alliance</p><p>August 17,1930</p></div>
            <div class="noto-text" >Authorized use provided by the Liu Ran Ju Archives</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-05.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:40.5vw; margin:1.6vw 0 0 11.8vw" src="../assets/Unit2-4/20230703-物件-2-4-09-pic3-放大文物.png" alt="Taiwanese People’s Party Leaflet: Wake up and demand genuine local autonomy!" title="Taiwanese People’s Party Leaflet: Wake up and demand genuine local autonomy!">
          </div>

          <div class="content-text" style=" top:30.6vw;  width: 41.7vw;">
            <div class="bold-title">Taiwanese People’s Party Leaflet: Wake up and demand genuine local autonomy!</div>
            <div class="noto-text" >National Museum of Taiwan History 2020.006.0200</div>
          </div>

            <div class="feature">
              <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
              <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
              <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
            </div>
          </div>
      </div>
    </transition>
    <!--img2--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-07.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:33.5vw; margin:4.6vw 0 0 3.4vw" src="../assets/Unit2-4/20230703-物件-2-4-07-pic2-放大文物.png" alt="Taiwanese People's Party Second Party Congress July 15, 1928" title="Taiwanese People's Party Second Party Congress July 15, 1928">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 60vw; width: 30vw;">
            <div class="bold-title-b"><p>Taiwanese People's Party Second Party Congress</p><p>July 15, 1928</p></div>
            <div class="noto-text" >National Museum of Taiwan Literature Donated by Lin Zhang-feng</div>
            <div class="sub-title" style="white-space: normal; text-align: left!important;" >The congress took place at Nanzuo (now Tainan Ximen Roundabout) in Tainan City, and this group photo was captured after the event. Reportedly, on the night of the congress, distinguished speakers from across Taiwan convened, with three separate venues hosting events simultaneously.</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-06.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:41.5vw; margin:1.4vw 0 0 11vw" src="../assets/Unit2-4/20230703-物件-2-4-10-pic4-放大文物.png" alt="Taiwanese People's Party Membership Roster, July 1928, showing a total of 707 members" title="Taiwanese People's Party Membership Roster, July 1928, showing a total of 707 members">
          </div>

          <div class="content-text" style=" top:30.6vw; width: 46vw;">
            <div class="bold-title">Taiwanese People's Party Membership Roster, July 1928, showing a total of 707 members</div>
            <div class="noto-text" >National Museum of Taiwan History 2020.032.0258</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-08.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37vw; margin:2vw 0 0 13vw" src="../assets/Unit2-4/20230703-物件-2-4-11-pic5-放大文物.png" alt="Tricolor opium eradication leaflets, January 1930" title="Tricolor opium eradication leaflets, January 1930">
          </div>

          <div class="content-text" style="top:30.6vw; width: 26vw;">
            <div class="bold-title">Tricolor opium eradication leaflets, January 1930</div>
            <div class="noto-text" >National Museum of Taiwan History 2020.032.0277.0002</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-08.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37vw; margin:2vw 0 0 13vw" src="../assets/Unit2-4/20230703-物件-2-4-12-pic6-放大文物.png" alt="Tricolor opium eradication leaflets, January 1930" title="Tricolor opium eradication leaflets, January 1930">
          </div>

          <div class="content-text" style=" top:30.6vw; width: 26vw;">
            <div class="bold-title">Tricolor opium eradication leaflets, January 1930</div>
            <div class="noto-text" >National Museum of Taiwan History 2020.032.0277.0003</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[6]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-08.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37vw; margin:2vw 0 0 13vw" src="../assets/Unit2-4/20230703-物件-2-4-13-pic7-放大文物.png" alt="Tricolor opium eradication leaflets, January 1930" title="Tricolor opium eradication leaflets, January 1930">
          </div>

          <div class="content-text" style=" top:30.6vw;width: 26vw;">
            <div class="bold-title">Tricolor opium eradication leaflets, January 1930</div>
            <div class="noto-text" >National Museum of Taiwan History 2020.032.0277.0004</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>

    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import router from "@/router";
import LottieTest from "@/components/LottieTest";
import {onMounted, onUnmounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import EnToolbar from "@/components/EnToolbar.vue";
import EnIntroBtn from "@/components/EnIntroBtn.vue";

export default {
  name: "Unit2-4",
  mixins: [myMixin],
  components:{
    EnToolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const contentFrame = ref(null);
    const picStyle = reactive(Array.from({ length: 2 }, () => ''));
    const part2Class = ref(null);
    const picInitAnim = ref('opacity-0');
    const picClass = reactive(Array.from({ length: 3 }, () => ''));
    const showImg = reactive(Array.from({ length: 11 }, () => false));
    const flipAudio = require('@/assets/Unit2-4/audio/book.mp3');
    const flipEle = new Audio(flipAudio);
    flipEle.volume = 0.5;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          //console.log('目標元素進入畫面');
          if(entry.target == part2Class.value){
            part2Class.value.classList.add('content-text-anim');
            picInitAnim.value = 'pic-init-anim';
          }
        }
      });
    }, {
      root: null, // 觀察器根節點，null 表示整個瀏覽器視窗
      threshold: 0.8 // 設定觸發 callback 的交叉比例，0 表示完全不交叉，1 表示完全交叉
    });

    function routeBtn(path){
      router.push(path);
    }

    onMounted(()=>{
      observer.observe(part2Class.value);

      scrollEvent();

      window.addEventListener('scroll', scrollEvent);
    });


    onUnmounted(()=>{
      window.removeEventListener('scroll', scrollEvent);
    });

    function scrollEvent(){
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollTriggerTop = contentFrame.value.offsetTop - window.innerHeight;
      const scrollTriggerMiddle = scrollTriggerTop + (contentFrame.value.offsetHeight * 0.5);
      const scrollPercentage = Math.max(0, Math.min(1, (scrollTop - scrollTriggerMiddle) / window.innerHeight));

      if(scrollPercentage >= 0.9){
        picStyle[0] = 'top:'+(21.5+(59.9-21.5)*scrollPercentage)+"%; left:"+(28.5+(21.5-28.5)*scrollPercentage)+"%; width:"+(20.5+(16.5-20.5)*scrollPercentage)+"vw;";
        picStyle[1] = 'top:'+(21.5+(59.9-21.5)*scrollPercentage)+"%; left:"+(51+(61.8-51)*scrollPercentage)+"%; width:"+(20.5+(16.5-20.5)*scrollPercentage)+"vw;";
      }else{
        picStyle[0] = '';
        picStyle[1] = '';
      }
    }

    const picIndex = ref(0);
    function rightBtn(){
      if(picIndex.value == 0){
        picClass[1] = 'pic-3-in';
        picClass[2] = 'pic-4-in';

        setTimeout(()=>{
          picClass[0] = 'move-out';

          setTimeout(()=>{
            picClass[2] = 'pic-4-out';
          }, 200);

          picIndex.value++;
        },200);

      }
      else if(picIndex.value == 1){
        picClass[2] = 'pic-4-in';
        picClass[1] = 'move-out';

        picIndex.value++;
      }
      flipEle.play();
    }

    function leftBtn(){
      //console.log(picIndex.value)
        if(picIndex.value == 1) {
          picClass[0] = 'pic-2-move-in';

          setTimeout(()=>{
            picClass[1] = 'pic-3-out';
          },500);

          picIndex.value--;
        }
        else if(picIndex.value == 2){
          picClass[1] = 'pic-3-move-in';
          setTimeout(()=>{
            picClass[2] = 'pic-4-out';
          },500);
          picIndex.value--;

        }
      flipEle.play();
      }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      contentFrame,
      picStyle,
      part2Class,
      picInitAnim,
      picClass,
      rightBtn,
      leftBtn,
      picIndex,
      showImg,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit2-4.css"></style>

