import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoadingView from "@/views/LoadingView";
import EnterView from '../views/EnterView.vue'
import MenuView from '../views/MenuView.vue'
import GuideView from '../views/GuideView.vue'
import PersonIntroAllView from "@/views/PersonIntroAllView";
import PersonIntroView from "@/views/PersonIntroView";
import Unit1_1 from "@/views/Unit1-1";
import Unit1_2 from "@/views/Unit1-2";
import Unit2_0 from "@/views/Unit2-0";
import Unit2_1 from "@/views/Unit2-1";
import Unit2_1_Game from "@/views/Unit2-1-game";
import Unit2_2 from "@/views/Unit2-2";
import Unit2_3 from "@/views/Unit2-3";
import Unit2_4 from "@/views/Unit2-4";
import Unit2_5 from "@/views/Unit2-5";
import Unit3_1 from "@/views/Unit3-1";
import Unit3_2 from "@/views/Unit3-2";
import Unit3_3 from "@/views/Unit3-3";
import Unit3_4 from "@/views/Unit3-4";
import Unit34Game from "@/views/Unit3-4-game";
import Unit3_5 from "@/views/Unit3-5";
import Unit4_1 from "@/views/Unit4-1";
import Unit4_2 from "@/views/Unit4-2";
import Unit4_3 from "@/views/Unit4-3";
import Unit4_4 from "@/views/Unit4-4";
import Unit4_5 from "@/views/Unit4-5";
import Unit6_2 from "@/views/Unit6-2";
import PrivacyView from "@/views/PrivacyView";
import CopyrightView from "@/views/CopyrightView";
import PersonalInfoView from "@/views/PersonalInfoView";

import EnHomeView from "@/views/EnHomeView";
import EnUnit11 from "@/views/EnUnit1-1";
import EnUnit12 from "@/views/EnUnit1-2.vue";
import EnUnit20 from "@/views/EnUnit2-0";
import EnUnit21 from "@/views/EnUnit2-1.vue";
import EnUnit22 from "@/views/EnUnit2-2.vue";
import EnUnit23 from "@/views/EnUnit2-3.vue";
import EnUnit24 from "@/views/EnUnit2-4";
import EnEnterView from "@/views/EnEnterView";
import EnUnit31 from "@/views/EnUnit3-1";
import EnUnit32 from "@/views/EnUnit3-2";
import EnUnit33 from "@/views/EnUnit3-3";
import Unit33More from "@/views/Unit3-3-more";
import EnUnit34 from "@/views/EnUnit3-4";
import EnUnit34Game from "@/views/EnUnit3-4-game";
import EnUnit35 from "@/views/EnUnit3-5";
import EnUnit62 from "@/views/EnUnit6-2.vue";
import EnUnit41 from "@/views/EnUnit4-1.vue";
import EnUnit42 from "@/views/EnUnit4-2.vue";
import EnUnit43 from "@/views/EnUnit4-3.vue";
import EnUnit44 from "@/views/EnUnit4-4.vue";
import EnUnit45 from "@/views/EnUnit4-5.vue";
import EnPersonIntroAllView from "@/views/EnPersonIntroAllView.vue";
import EnPersonIntroView from "@/views/EnPersonIntroView.vue";
import EnUnit33More from "@/views/EnUnit3-3-more";
import EnGuideView from "@/views/EnGuideView";
import EnPrivacyView from "@/views/EnPrivacyView";
import EnCopyrightView from "@/views/EnCopyrightView";
import EnPersonalInfoView from "@/views/EnPersonalInfoView";
import aboutUs from "@/views/AboutUs.vue";
import EnAboutUs from "@/views/EnAboutUs.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/loading',
    name: 'loading',
    component: LoadingView
  },
  {
    path: '/enter',
    name: 'enter',
    component: EnterView
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuView
  },
  {
    path: '/guide',
    name: 'guide',
    component: GuideView
  },
  {
    path: '/person-intro-all',
    name: 'person-intro-all',
    component: PersonIntroAllView
  },
  {
    path: '/person-intro/:num?',
    name: 'person-intro',
    component: PersonIntroView
  },
  {
    path: '/unit1-1',
    name: 'unit1-1',
    component: Unit1_1
  },
  {
    path: '/unit1-2',
    name: 'unit1-2',
    component: Unit1_2
  },
  {
    path: '/unit2-0',
    name: 'unit2-0',
    component: Unit2_0
  },
  {
    path: '/unit2-1',
    name: 'unit2-1',
    component: Unit2_1
  },
  {
    path: '/unit2-1-game',
    name: 'unit2-1-game',
    component: Unit2_1_Game
  },
  {
    path: '/unit2-2',
    name: 'unit2-2',
    component: Unit2_2
  },
  {
    path: '/unit2-3',
    name: 'unit2-3',
    component: Unit2_3
  },
  {
    path: '/unit2-4',
    name: 'unit2-4',
    component: Unit2_4
  },
  {
    path: '/unit2-5',
    name: 'unit2-5',
    component: Unit2_5
  },
  {
    path: '/unit3-1',
    name: 'unit3-1',
    component: Unit3_1
  },
  {
    path: '/unit3-2',
    name: 'unit3-2',
    component: Unit3_2
  },
  {
    path: '/unit3-3',
    name: 'unit3-3',
    component: Unit3_3
  },
  {
    path: '/unit3-3/more',
    name: 'unit3-3/more',
    component: Unit33More
  },
  {
    path: '/unit3-4',
    name: 'unit3-4',
    component: Unit3_4
  },
  {
    path: '/unit3-4/game',
    name: 'unit3-4-game',
    component: Unit34Game
  },
  {
    path: '/unit3-5',
    name: 'unit3-5',
    component: Unit3_5
  },
  {
    path: '/unit4-1',
    name: 'unit4-1',
    component: Unit4_1
  },
  {
    path: '/unit4-2',
    name: 'unit4-2',
    component: Unit4_2
  },
  {
    path: '/unit4-3',
    name: 'unit4-3',
    component: Unit4_3
  },
  {
    path: '/unit4-4',
    name: 'unit4-4',
    component: Unit4_4
  },
  {
    path: '/unit4-5',
    name: 'unit4-5',
    component: Unit4_5
  },
  {
    path: '/unit6-2',
    name: 'unit6-2',
    component: Unit6_2
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },
  {
    path: '/copyright',
    name: 'copyright',
    component: CopyrightView
  },
  {
    path: '/personal-info',
    name: 'personal-info',
    component: PersonalInfoView
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: aboutUs
  },


  {
    path: '/en',
    name: 'en-home',
    component: EnHomeView
  },
  {
    path: '/en/person-intro-all',
    name: 'en-person-intro-all',
    component: EnPersonIntroAllView
  },
  {
    path: '/en/person-intro/:num?',
    name: 'en-person-intro',
    component: EnPersonIntroView
  },
  {
    path: '/en/unit1-1',
    name: 'en-unit1-1',
    component: EnUnit11
  },
  {
    path: '/en/unit1-2',
    name: 'en-unit1-2',
    component: EnUnit12
  },
  {
    path: '/en/unit2-0',
    name: 'en-unit2-0',
    component: EnUnit20
  },
  {
    path: '/en/unit2-1',
    name: 'en-unit2-1',
    component: EnUnit21
  },
  {
    path: '/en/unit2-2',
    name: 'en-unit2-2',
    component: EnUnit22
  },
  {
    path: '/en/unit2-3',
    name: 'en-unit2-3',
    component: EnUnit23
  },
  {
    path: '/en/unit2-4',
    name: 'en-unit2-4',
    component: EnUnit24
  },
  {
    path: '/en/enter',
    name: 'en-enter',
    component: EnEnterView
  },
  {
    path: '/en/unit3-1',
    name: 'en-unit3-1',
    component: EnUnit31
  },
  {
    path: '/en/unit3-2',
    name: 'en-unit3-2',
    component: EnUnit32
  },
  {
    path: '/en/unit3-3',
    name: 'en-unit3-3',
    component: EnUnit33
  },
  {
    path: '/en/unit3-3/more',
    name: 'en/unit3-3/more',
    component: EnUnit33More
  },
  {
    path: '/en/unit3-4',
    name: 'en-unit3-4',
    component: EnUnit34
  },
  {
    path: '/en/unit3-4/game',
    name: 'en-unit3-4/game',
    component: EnUnit34Game
  },
  {
    path: '/en/unit3-5',
    name: 'en-unit3-5',
    component: EnUnit35
  },
  {
    path: '/en/unit4-1',
    name: 'en-unit4-1',
    component: EnUnit41
  },
  {
    path: '/en/unit4-2',
    name: 'en-unit4-2',
    component: EnUnit42
  },
  {
    path: '/en/unit4-3',
    name: 'en-unit4-3',
    component: EnUnit43
  },
  {
    path: '/en/unit4-4',
    name: 'en-unit4-4',
    component: EnUnit44
  },
  {
    path: '/en/unit4-5',
    name: 'en-unit4-5',
    component: EnUnit45
  },
  {
    path: '/en/unit6-2',
    name: 'en-unit6-2',
    component: EnUnit62
  },
  {
    path: '/en/guide',
    name: 'en-guide',
    component: EnGuideView
  },

  {
    path: '/en/privacy',
    name: 'en-privacy',
    component: EnPrivacyView
  },
  {
    path: '/en/copyright',
    name: 'en-copyright',
    component: EnCopyrightView
  },
  {
    path: '/en/personal-info',
    name: 'en-personal-info',
    component: EnPersonalInfoView
  },
  {
    path: '/en/aboutus',
    name: 'en-aboutus',
    component: EnAboutUs
  },
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async () => {
  // canUserAccess() 返回 `true` 或 `false`
  window.scrollTo(0,0)
})

export default router
