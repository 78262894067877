<template>
  <div class="wrap" @mousemove="handleMouseMove" @keydown.esc="escEvent">
    <toolbar type="2" unit="4" route-name="unit6-2" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit6-2/sample/20230417-對位-6-0-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1">
        <div class="video-div">
          <!--autoplay muted playsinline-->
          <video id="video"  class="w-full" ref="videoRef"  @click="playVideo" @pause="videoPausedEvent">
            <source src="../assets/Unit6-2/video.mp4"
                    type="video/mp4">
          </video>
        </div>
      <div class="video-touch"  @mousemove="customCursorStyle='opacity:1'" @mouseleave="customCursorStyle='display:none'" @click="playVideo" @keydown.enter="playVideo" tabindex="0"></div>

        <div class="content content-style">
          <p>2014年，黃土水塑高木友枝銅像，落腳在彰化高中。關於這座臺日博物館競相爭取的國寶銅像，背後的曲折故事，正是文協近百年之後一部精彩的番外篇。</p>
          <p>這座銅像長期由高木的後代收存。2011年彰化中學學生選擇高木友枝作為研究對象赴日進行踏查，後家屬有意捐贈文物，彰中學生熱情再訪，感動家屬，銅像與文物最終落腳彰化。</p>
        </div>

        <img class="pic-1" src="../assets/Unit6-2/20230417-6-0-物件-01-首圖1.png" alt="黃土水塑高木友枝銅像" title="黃土水塑高木友枝銅像">
    </div>

    <div class="part2">
      <img class="logo-img" src="../assets/Unit6-2/20230417-6-0-物件-03-裝飾字1-高木友枝.png" alt="高木友枝" title="高木友枝">

      <div class="content-1">
        <div class="year">1901 年</div>
        <div><div class="dot"></div>高木友枝（1858-1943），日本陸奧國 （今福島） 人，東京帝大醫學部畢業，曾任病理學家北里柴三郎助手、調查香港鼠疫，為代表性傳染病專家。因臺灣鼠疫流行，受後藤新平邀聘來臺。</div>
      </div>
      <div class="content-2">
        <div class="year">1902 年</div>
        <div><div class="dot"></div>因任臺灣總督府醫院院長、醫學校校長等職，他懷抱人格教育精神，高木在醫校期間重視學生品格教育，反對打壓本土語言文化，常對參與社會運動的活潑學生表示同情，出席文協開幕式時，高木直言組織的弱點，期許小巧精實的戰鬥團隊。賴和、林糊等日後文協活躍者曾特別為文留念。</div>
      </div>
      <div class="content-3">
        <div class="year">1919 年</div>
        <div><div class="dot"></div>高木獲派轉任臺灣電力株式會社社長。</div>
      </div>
      <div class="content-4">
        <div class="year">1921 年</div>
        <div><div class="dot"></div>高木參加文協成立大會，並上臺致詞：「世上凡所謂會者也甚難永續，只是『會』之字形頭大下窄就得明白。假定我對文化會設立被徵求意見，我就提議不要聚集千人，或千二百人之多數，只以二三十人之熱心家堅實維持一定時期便成。」</div>
      </div>
      <div class="content-5">
        <div class="year">1929 年</div>
        <div><div class="dot"></div>高木自臺電公司退休，黃土水受託當面為他塑像，由高木帶回日本。</div>
      </div>
      <div class="content-6">
        <p class="title-style" style="color: #4686ba">黃土水塑高木友枝銅像</p>
        <p class="subtitle-style" style="margin-top: 0.5vw"></p>
        <p class="source-style" style="margin-top: 0.3vw">彰化高中 藏</p>
        <p class="content-style" style="margin-top: 0.5vw">黃土水（1895-1930）是日本時代的臺灣雕塑家，曾於1921年入選帝展。高木友枝於臺灣電力株式會社退休時，臺電委託黃土水為高木塑銅像，贈送留念。銅像側面可以看到黃土水署名，背後亦有臺電的致贈落款。</p>
      </div>
      <div class="content-7">
        <p class="title-style" style="color: #4686ba">高木友枝墨寶</p>
        <p class="subtitle-style" style="margin-top: 0.5vw"></p>
        <p class="source-style" style="margin-top: 0.3vw">彰化高中 藏</p>
        <p class="content-style" style="margin-top: 0.5vw">高木友枝退休離臺之前，揮毫詠懷以明志，寫下這首短歌，要意為：「不管他人評價好或壞，有點力量就為斯土而用盡。」</p>
        <p class="content-style">臺灣生活28年歸京感懷</p>
      </div>

      <div class="img-1">
        <LottieTest :json="require('@/assets/Unit6-2/6-0-0.json')" alt="高木友枝" title="高木友枝"></LottieTest>
      </div>
      <div class="img-2">
        <LottieTest :json="require('@/assets/Unit6-2/6-0-1.json')" alt="高木友枝" title="高木友枝"></LottieTest>
      </div>
      <div class="img-3">
        <img class="w-full" src="../assets/Unit6-2/20230417-6-0-物件-05-pic1.png" alt="黃土水塑高木友枝銅像" title="黃土水塑高木友枝銅像">

        <div class="point-1" @click="zoomImg[0]=!zoomImg[0]; zoomImg[1]=false;" @keydown.enter="zoomImg[0]=!zoomImg[0]; zoomImg[1]=false;" tabindex="0" alt="點選看圖片" title="點選看圖片">
          <div></div>
          <img class="zoom-img" :class="zoomImg[0]==true?'open-zoom-img':''" src="../assets/Unit6-2/20230417-6-0-物件-10-pic3.png" alt="銅像側面黃土水署名" title="銅像側面黃土水署名">
        </div>
        <div class="point-2" @click="zoomImg[1]=!zoomImg[1]; zoomImg[0]=false;" @keydown.enter="zoomImg[1]=!zoomImg[1]; zoomImg[0]=false;" tabindex="0" alt="點選看圖片" title="點選看圖片">
          <div></div>
          <img class="zoom-img" :class="zoomImg[1]==true?'open-zoom-img':''" src="../assets/Unit6-2/20230417-6-0-物件-09-pic2.png" alt="銅像背後臺電的致贈落款" title="銅像背後臺電的致贈落款">
        </div>

        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 2%; right: 4%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">

      </div>
      <div class="img-4">
        <img class="w-full" src="../assets/Unit6-2/20230417-6-0-物件-11-pic4.png" alt="高木友枝墨寶" title="高木友枝墨寶">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 2%; right: 4%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
    </div>


    <div class="custom-cursor" ref="customCursor"  :style="customCursorStyle" >
      <img class="w-full" :src="customCursorImg">
    </div>

    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit6-2/sample/20230417-對位-6-0-op-06.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:16.2vw; margin:0.9vw 0 0 23.35vw" src="../assets/Unit6-2/20230704-物件-6-0-05-pic1-放大文物.png" alt="黃土水塑高木友枝銅像" title="黃土水塑高木友枝銅像">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 25vw; width: 12.3vw;">
            <div >
              <div class="bold-title">黃土水塑高木友枝銅像</div>
              <div class="noto-text" style="margin-top: 0.4vw">彰化高中 藏</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit6-2/sample/20230417-對位-6-0-op-07.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:7.1vw; margin:2.5vw 0 0 29vw" src="../assets/Unit6-2/20230704-物件-6-0-11-pic4-放大文物.png" alt="高木友枝墨寶" title="高木友枝墨寶">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 28.4vw; width: 8.2vw;">
            <div >
              <div class="bold-title">高木友枝墨寶</div>
              <div class="noto-text" style="margin-top: 0.4vw">彰化高中 藏</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>

    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import {onMounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import LottieTest from "@/components/LottieTest";
import myMixin from "@/assets/common/js/mixin";
export default {
  name: "Unit6-2",
  mixins: [myMixin],
  components:{
    LottieTest,
    toolbar,
    IntroBtn,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const videoRef = ref();
    const customCursor = ref();
    const customCursorStyle = ref('display:none;');
    const videoPaused = ref(true);
    const zoomImg = reactive(Array.from({ length: 2 }, () => false));
    const showImg = reactive(Array.from({ length: 2 }, () => false));
    const customCursorImg = ref(require('../assets/Unit6-2/20230417-6-0-物件-02-影片播放鍵.png'));

    onMounted(()=>{
        videoRef.value.currentTime = 1.5;
    });

    function routeBtn(path){
      router.push(path);
    }

    function playVideo(){
      if(videoRef.value.paused){
        if(videoRef.value.currentTime == 1.5){
          videoRef.value.currentTime = 0;
        }
        videoRef.value.play();
        //customCursor.value.style.opacity = 0;
        customCursorImg.value = require('../assets/Unit6-2/20230704-物件-6-0-15-影片暫停鍵.png');
        videoPaused.value = false;
        videoRef.value.controls = true;
      }else{
        videoRef.value.pause();
        videoPausedEvent();
      }
    }

    function videoPausedEvent(){
      customCursorImg.value = require('../assets/Unit6-2/20230417-6-0-物件-02-影片播放鍵.png');
      videoPaused.value = true;
      videoRef.value.controls = false;
    }

    function handleMouseMove(event) {
      // customCursor.value.style.opacity = 1;
      // const containerWidth = event.target.offsetWidth;
      // const containerHeight = event.target.offsetHeight;
      // customCursor.value.style.left = ((event.clientX - event.target.offsetLeft) / containerWidth * 100)-47 + "%";
      // customCursor.value.style.top = ((event.clientY - event.target.offsetTop) / containerHeight * 100 )-20 + "%";
      customCursor.value.style.left = (event.pageX - customCursor.value.clientWidth /2) + "px" ;
      customCursor.value.style.top = (event.pageY - customCursor.value.clientHeight /2)  + "px";
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      videoRef,
      playVideo,
      handleMouseMove,
      customCursor,
      customCursorStyle,
      videoPaused,
      videoPausedEvent,
      zoomImg,
      showImg,
      customCursorImg,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit6-2.css"></style>
