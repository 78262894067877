<template>
  <div class="unit2-0-wrap" @keydown.esc="escEvent">
  <toolbar type="1" unit="2" route-name="unit2-0"  color="orange"></toolbar>


<!--    <img class="sample" src="../assets/Unit2-0/sample/20230629-對位-2-0-op-01.png">-->


    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="title-logo">文協向前衝</div>
      <img class="building" src="../assets/Unit2-0/building.png">
      <img class="people-img-1" src="../assets/Unit2-0/people-img-1.png">
      <div class="people-1" >
        <LottieTest :json="require('@/assets/Unit2-0/2-0.json')" alt="臺灣文化協會" title="臺灣文化協會" ></LottieTest>
      </div>
      <img @click="showImg[0]=true" @keydown.enter="showImg[0]=true" :tabindex="tabindex" class="plus-btn" style="width: 2vw; top: 19.5%; left: 42.7%;"  src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
    </div>

    <div class="part2 part-color">
      <div class="content" :tabindex="tabindex">
        <img  src="../assets/Unit2-0/decorate-text-1.png" alt="結成" title="結成">
        <p>1921年10月17日下午，臺灣文化協會在臺北市大稻埕靜修女子學校舉行創立大會。會員共1,032人，現場出席共300多人，以總督府醫學專門學校、師範學校、商工學校、工業學校學生占多數。眾人審議組織章程、公推林獻堂為總理、選舉協理及理事、會員代表演講，之後舉辦成立典禮。</p>
        <p>這個匯聚臺灣各地知識菁英的組織，主張與目標多元，訴求可分作三個領域：普及平民教育、建立臺灣特種文化、擔負起世界變動中的使命。</p>
        <p>隨著組織的發展，面對殖民地處境，與資本階級對無產者的壓迫，文化協會又應採取什麼樣的態度？也成為組織改造、權力更迭的核心議題。</p>
      </div>

      <img class="building"  src="../assets/Unit2-0/building-2.png">
      <div class="people-2" >
        <LottieTest :json="require('@/assets/Unit2-0/2-0-1.json')" alt="人群" title="人群" ></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <img class="decorate-text"  src="../assets/Unit2-0/decorate-text-2.png"  alt="籌備" title="籌備">
      <p class="big-year">1920<span>年</span></p>
      <p class="next-year">1921<span>年</span></p>

      <div class="content" :tabindex="tabindex">
        <div>
          <p>11<span>月</span></p>
          <p>李應章、吳海水等醫學生討論組成文化組織事宜，後拜訪蔣渭水等人進行籌組規劃。</p>
        </div>
        <div style="margin-top: 7vw">
          <p>&ensp;7<span>月</span></p>
          <p style="margin-top: 0.7vw">蔣渭水等拜訪林獻堂，研擬手續、旨趣書與章程草案，並拜訪總督府警務局，初步了解態度後，正式創立辦事處，分寄資料徵求入會。</p>
        </div>
        <div style="margin-top: 1.5vw">
          <p>&ensp;8<span>月</span></p>
          <p>通知各警務、行政機構、報社及知名人士。</p>
        </div>
        <div style="margin-top: 1.5vw">
          <p>&ensp;9<span>月</span></p>
          <p>蔣渭水正式發信通知林獻堂，報告各地入會者已達500人以上，並擬會歌。</p>
        </div>
        <div style="margin-top: 1.7vw">
          <p>10<span>月</span></p>
          <p>10月17日開會，文化協會正式成立。</p>
        </div>
      </div>

      <div class="people-3" >
        <LottieTest :json="require('@/assets/Unit2-0/2-0-2.json')" alt="學生" title="學生"></LottieTest>
      </div>
    </div>

    <div class="part4 part-color">

      <div class="content">
        <p class="title">一起來寫會歌</p>
        <p class="subtitle" style="margin-top: 0.7vw">蔣渭水寄林獻堂書信</p>
        <p class="source">國立臺灣歷史博物館 館藏 2018.011.0014.0001-4</p>
        <p class="text" style="margin-top: 0.5vw">1921年9月21日蔣渭水給林獻堂信件，並附有蔣撰擬的文化協會會歌歌詞。此件為蔣渭水親手書寫與文化協會成立直接相關的唯一書信。</p>

        <p class="subtitle" style="margin-top: 1.2vw">臺灣文化協會會歌
          <span v-if="lyricsType==1" class="lyrics-btn" @click="lyricsBtn(2)" @keydown.enter="lyricsBtn(2)" :tabindex="tabindex">看歌詞</span>
          <span v-if="lyricsType==2" class="lyrics-btn" @click="lyricsBtn(1)" @keydown.enter="lyricsBtn(1)" :tabindex="tabindex">看手稿</span>
        </p>
        <p class="source" style="margin-top: 0.5vw">詞：蔣渭水 | 曲：一條慎三郎（始政紀念日） | 編曲：Edgar L. Macapili（萬益嘉） | 演唱：點心擔合唱團 |</p>
        <p class="source">國立臺灣歷史博物館  提供</p>
        <p class="text" style="margin-top: 0.5vw">蔣渭水於歌詞一旁註明是提交作為參考，後續由各界提供修改意見訂定。</p>
        <p class="text">文化協會成立當天通過的暫定會歌，是將二、三兩段歌詞濃縮合併，成為兩段歌詞的版本。</p>

        <AudioComp :audio-src="require('../assets/Unit2-0/song.mp3')" style-class="white-style"></AudioComp>

      </div>

      <div class="letter-div" :class="envelopeStyle" @mouseenter="letterUp" @mouseleave="letterDown" @click="()=>{lyricsBtn(1)}" @keydown.enter="()=>{lyricsBtn(1)}" :tabindex="tabindex">
        <img class="letter" :src="letterSrc" :class="letterFade==true?'letter-fade':''">
        <img class="envelope"   src="../assets/Unit2-0/envelope.png" alt="蔣渭水寄林獻堂書信" title="蔣渭水寄林獻堂書信">

        <transition name="img-frame-fade">
          <div class="point" v-if="envelopeStyle==''" alt="點選信件閱讀" title="點選信件閱讀">
            <div></div>
           <div>點選信件閱讀</div>
          </div>
        </transition>
      </div>

      <div class="letter-open-div" v-if="letterFade">
        <img v-if="lyricsType==1" class="lyrics-hand" src="../assets/Unit2-0/lyrics-hand.png" alt="臺灣文化協會會歌手稿" title="臺灣文化協會會歌手稿">
        <div v-if="lyricsType==2" class="lyrics-normal" >
          <p><span class="number">（一）</span>我等都是亞細亞　 黃色的人種；</p>
          <p>介在漢族一血脈　 日本的百姓</p>
          <p>所以天降大使命　 囑咱緊實行；</p>
          <p>發達文化振道德　 造就此才能</p>
          <p><span class="number">（二）</span>欲謀東洋永和平　 中日要親善；</p>
          <p>我等須當作連鎖　 和睦此弟兄</p>
          <p>糾合東亞諸民族　 締結大同盟；</p>
          <p>啟發文明比西洋　 兩兩得並行</p>
          <p><span class="number">（三）</span>可免黃白起戰爭　 世界就和平；</p>
          <p>我等一舉天下利　 豈可自暴棄</p>
          <p>但願最後完使命　 樂為世界人；</p>
          <p>世界人類萬萬歲　 台灣名譽馨</p>
        </div>

        <div class="pic-1" style="position:relative; width: 10.5vw; margin: 2.5% 0 0 2.5%">
          <img src="../assets/Unit2-0/letter-1.png" alt="蔣渭水寄林獻堂書信" title="蔣渭水寄林獻堂書信">
          <img class="zoom-btn" src="../assets/common/zoom-2-btn.png" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" :tabindex="tabindex" alt="詳細資訊" title="詳細資訊">
        </div>

        <div class="pic-1" style="position:relative; width: 9.7vw; margin: 2.5% 0 0 7%">
          <img src="../assets/Unit2-0/letter-2.png" alt="蔣渭水寄林獻堂書信" title="蔣渭水寄林獻堂書信">
          <img class="zoom-btn" src="../assets/common/zoom-2-btn.png" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" :tabindex="tabindex" alt="詳細資訊" title="詳細資訊">
        </div>

        <div class="pic-1" style="position:relative; width:11.2vw; margin: 2.5% 0 0 6%">
          <img src="../assets/Unit2-0/letter-3.png" alt="蔣渭水寄林獻堂書信" title="蔣渭水寄林獻堂書信">
          <img class="zoom-btn" src="../assets/common/zoom-2-btn.png" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" :tabindex="tabindex" alt="詳細資訊" title="詳細資訊">
        </div>

        <div class="pic-1" style="position:relative; width: 10.5vw; margin: 4.5% 0 0 2.1%">
          <img src="../assets/Unit2-0/letter-4.png" alt="蔣渭水寄林獻堂書信" title="蔣渭水寄林獻堂書信">
          <img class="zoom-btn" src="../assets/common/zoom-2-btn.png" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" :tabindex="tabindex" alt="詳細資訊" title="詳細資訊">
        </div>
      </div>

    </div>

    <div class="part5 part-color">
      <div class="content">
        <img style="width: 15vw" src="../assets/Unit2-0/decorate-text-3.png" alt="組織" title="組織">
        <p>文化協會成立後，設有「總理」作為領袖，「專務理事」主辦會務，大會選舉的「理事」定期召開理事會，另設有「評議員」，成為第一個以全體臺灣人為對象、由臺灣人自發成立的結社團體。</p>

        <img class="more-btn" id="more-btn" src="../assets/Unit2-0/btn-img-1.png" alt="臺灣的診斷書" title="臺灣的診斷書" @click="moreBtn" @keydown.enter="moreBtn" :tabindex="tabindex">
        <img class="people-img" src="../assets/Unit2-0/people-img-2.png" >
      </div>



      <transition name="img-frame-fade">
        <div class="video-div" v-show="videoIdx==1" ref="videoRef">

<!--          <video id="video" ref="videoRef" muted playsinline>-->
<!--            <source src="../assets/Unit2-0/2-0-5.mp4" type="video/mp4">-->
<!--          </video>-->
            <LottieOnce autoplay="false"  ref="videoJson" :json="require('@/assets/Unit2-0/2-0-5.json')" ></LottieOnce>
          <div class="video-text" alt="臺灣文化協會組織圖1921年版" title="臺灣文化協會組織圖1921年版">
            <p>臺灣文化協會組織圖</p>
            <p>1921</p>
            <p>創作初期之運作</p>
            <p>資料來源：臺灣文化協會會則（1921年版，1927年）</p>
          </div>

        </div>
      </transition>

      <transition name="img-frame-fade">
        <div class="video-div" v-show="videoIdx==2">

<!--          <video id="video" ref="video2Ref" muted playsinline>-->
<!--            <source src="../assets/Unit2-0/2-0-6.mp4" type="video/mp4">-->
<!--          </video>-->
          <LottieOnce  autoplay="false" ref="video2Ref"  :json="require('@/assets/Unit2-0/2-0-6.json')" ></LottieOnce>
          <div class="video-text" alt="臺灣文化協會組織圖1927年版" title="臺灣文化協會組織圖1927年版">
            <p>臺灣文化協會組織圖</p>
            <p>1927</p>
            <p>改組後文化組織</p>
            <p>資料來源：臺灣文化協會會則（1921年版，1927年）</p>
          </div>
        </div>
      </transition>



      <img v-show="videoIdx==2" @click="videoIdx=1"  @keydown.enter="videoIdx=1" :tabindex="tabindex" class="arrow" style="top: 52%; left: 37.7%" src="../assets/common/left-arrow-white.png" alt="上一頁" title="上一頁">
      <img v-show="videoIdx==1" @click="videoRightBtn" @keydown.enter="videoRightBtn" :tabindex="tabindex" class="arrow" style="top: 52%; left: 88.6%" src="../assets/common/right-arrow-white.png" alt="下一頁" title="下一頁">
    </div>

    <div class="part6 part-color">
      <transition name="img-frame-fade" >
        <div class="circle">
          <div>
            <p>您認識哪位</p>
            <p>文協成員?</p>
          </div>
          <div>
            <p>圖片中的文協成員都是相當重要的文協人物，您認識幾位呢？滑動滑鼠至人物身上，就會知道他們是誰。</p>
          </div>
        </div>
      </transition>

      <div class="member">
        <img  class="relative w-full" src="../assets/Unit2-0/member.png" alt="臺灣文化協會第一屆理事會成員" title="臺灣文化協會第一屆理事會成員">



        <img class="absolute cursor-pointer member-img" style="top: -5.4%; left: 9%; width: 6.2vw" src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-61-陳虛谷.png" :tabindex="tabindex" alt="陳虛谷" title="陳虛谷">
        <img class="absolute cursor-pointer member-img" style="top: -6.3%; left: 39.6%; width: 7.2vw" src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-62-丁瑞圖.png" :tabindex="tabindex" alt="丁瑞圖" title="丁瑞圖">
        <img class="absolute cursor-pointer member-img" style="top: 0%; left: 47.9%; width: 7vw"  src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-63-林資彬.png" :tabindex="tabindex" alt="林資彬" title="林資彬">


        <div class="absolute cursor-pointer member-img" style="top: -6.2%; left: 54.5%; width: 5.8vw" @click="routeBtn('/person-intro/14')" @keydown.enter="routeBtn('/person-intro/14')" :tabindex="tabindex">
          <img class="w-full"  src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-64-林幼春.png" alt="林幼春" title="林幼春">
          <img class="member-btn" style="top: -46%; left: -14%" src="../assets/Unit2-0/20230629-2-0-物件-104-人物介紹按鈕.png" alt="人物介紹按鈕" title="人物介紹按鈕">
        </div>

        <div  class="absolute cursor-pointer member-img" style="top: -2.05%; left: 56.55%; width: 7.9vw"  @click="routeBtn('/person-intro/24')" @keydown.enter="routeBtn('/person-intro/24')" :tabindex="tabindex">
          <img class="w-full" src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-65-王敏川.png" alt="王敏川" title="王敏川">
          <img class="member-btn" style="top: 46%; left: 3%" src="../assets/Unit2-0/20230629-2-0-物件-104-人物介紹按鈕.png" alt="人物介紹按鈕" title="人物介紹按鈕">
        </div>

        <img class="absolute cursor-pointer member-img" style="top: -6.15%; left: 63.7%; width: 6.85vw" src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-66-鄭汝南.png" :tabindex="tabindex" alt="鄭汝南" title="鄭汝南">
        <img class="absolute cursor-pointer member-img" style="top: -6.15%; left: 70.7%; width: 8.5vw" src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-67-陳逢源.png" :tabindex="tabindex" alt="陳逢源" title="陳逢源">

        <div class="absolute cursor-pointer member-img" style="top: -0.9%; left: 80.1%; width: 4.5vw" @click="routeBtn('/person-intro/20')" @keydown.enter="routeBtn('/person-intro/20')" :tabindex="tabindex">
          <img class="w-full"  src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-68-賴和.png" alt="賴和" title="賴和">
          <img class="member-btn" style="top: -35%; left: 24%" src="../assets/Unit2-0/20230629-2-0-物件-104-人物介紹按鈕.png" alt="人物介紹按鈕" title="人物介紹按鈕">
        </div>

        <img class="absolute cursor-pointer member-img" style="top: -6.4%; left: 84.8%; width: 7.6vw" src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-69-謝春木.png" :tabindex="tabindex" alt="謝春木" title="謝春木">

        <div  class="absolute cursor-pointer member-img" style="top: 7%; left: 0.6%; width: 6.85vw" @click="routeBtn('/person-intro/1')" @keydown.enter="routeBtn('/person-intro/1')" :tabindex="tabindex">
          <img class="w-full" src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-70-蔡培火.png" alt="蔡培火" title="蔡培火">
          <img class="member-btn" style="top: 16%; left: 24%" src="../assets/Unit2-0/20230629-2-0-物件-104-人物介紹按鈕.png" alt="人物介紹按鈕" title="人物介紹按鈕">
        </div>

        <img class="absolute cursor-pointer member-img" style="top: 23%; left: 9%; width: 8.35vw" src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-71-洪元煌.png" :tabindex="tabindex" alt="洪元煌" title="洪元煌">
        <img class="absolute cursor-pointer member-img" style="top: 22%; left: 23.6%; width:7.3vw" src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-72-黃呈聰.png" :tabindex="tabindex" alt="黃呈聰" title="黃呈聰">
        <div class="absolute cursor-pointer member-img" style="top: 23.3%; left: 36.7%; width: 6.5vw" @click="routeBtn('/person-intro/17')" @keydown.enter="routeBtn('/person-intro/17')" :tabindex="tabindex">
          <img class="w-full"  src="../assets/Unit2-0/memeber-outline/20230424-2-0-物件-op-73-蔣渭水.png" alt="蔣渭水" title="蔣渭水">
          <img class="member-btn" style="top: 20%; left: 19%" src="../assets/Unit2-0/20230629-2-0-物件-104-人物介紹按鈕.png" alt="人物介紹按鈕" title="人物介紹按鈕">
        </div>

        <div class="absolute cursor-pointer member-img" style="top: 25.2%; left: 44.9%; width: 9.1vw " @click="routeBtn('/person-intro/3')" @keydown.enter="routeBtn('/person-intro/3')" :tabindex="tabindex">
          <img class="w-full"  src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-74-林獻堂.png" alt="林獻堂" title="林獻堂">
          <img class="member-btn" style="top: 20%; left: 36%" src="../assets/Unit2-0/20230629-2-0-物件-104-人物介紹按鈕.png" alt="人物介紹按鈕" title="人物介紹按鈕">
        </div>

        <div class="absolute cursor-pointer member-img" style="top: 26%; left: 60.5%; width: 8.4vw" @click="routeBtn('/person-intro/0')" @keydown.enter="routeBtn('/person-intro/0')" :tabindex="tabindex">
          <img class="w-full"  src="../assets/Unit2-0/memeber-outline/20230406-2-0-物件-op-75-連溫卿.png" alt="連溫卿" title="連溫卿">
          <img class="member-btn" style="top: 17%; left: 15%" src="../assets/Unit2-0/20230629-2-0-物件-104-人物介紹按鈕.png" alt="人物介紹按鈕" title="人物介紹按鈕">
        </div>

        <img class="zoom-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" :tabindex="tabindex" style="width: 2.2vw; top: 63%; left: 93%" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>

      <div class="source">
        <p style="font-size: calc(1vw * var(--font-size))" >臺灣文化協會第一屆理事會</p>
        <p style="margin-top: 0.5vw">資料來源：國立清華大學珍藏資料，引自《人間蔣渭水：蔣渭水歷史影像集》</p>

      </div>
    </div>

    <div class="part7 part-color">
      <div class="unit-btn" style="top: 23%; left: 12%; " >
        <img class="w-full"  src="../assets/Unit2-0/20230721-2-0-物件-76-文協向前衝按鈕-淡.png" alt="文協向前衝" title="文協向前衝" @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/unit2-0')" :tabindex="tabindex">
        <p>文協向前衝</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 38.5%; ">
        <img class="w-full" src="../assets/Unit2-0/臺灣人的臺灣按鈕.png" alt="臺灣人的臺灣" title="臺灣人的臺灣" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" :tabindex="tabindex">
        <p>臺灣人的</p>
        <p style="top:47%">臺灣</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 65%; ">
        <img class="w-full" src="../assets/Unit2-0/為農工大眾主張按鈕.png" alt="為農工大眾主張" title="為農工大眾主張" @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" :tabindex="tabindex">
        <p>為農工大眾</p>
        <p style="top:47%">主張</p>
      </div>
      <div class="unit-btn" style="top: 57%; left: 25%; ">
        <img class="w-full" src="../assets/Unit2-0/左派與右派按鈕.png" alt="左派與右派" title="左派與右派" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" :tabindex="tabindex">
        <p>左派與右派</p>
      </div>
      <div class="unit-btn" style="top: 57%; left: 51.5%; ">
        <img class="w-full" src="../assets/Unit2-0/政治結社的走向按鈕.png" alt="政治結社的走向" title="政治結社的走向" @click="routeBtn('/unit2-4')" @keydown.enter="routeBtn('/unit2-4')" :tabindex="tabindex">
        <p>政治結社的</p>
        <p style="top:47%">走向</p>
      </div>
    </div>

    <!--More-->
    <transition name="img-frame-fade">
    <div class="more-window" v-if="showMore">
<!--      <img class="sample" src="../assets/Unit2-0/sample/20230629-對位-2-0-op-16.png">-->
      <div class="more-div">
        <div class="content">
          <p class="title-style title">世界文化的低能兒?</p>
          <p class="content-style" style="margin-top: 0.8vw">蔣渭水在《會報》發佈〈臨床講義〉，形容臺灣島得了「智識營養不良症」，有什麼症狀及治療方法呢？</p>

          <transition name="img-frame-fade"><img class="arrow-btn" style="left: 9%"  src="../assets/common/left-arrow.png" alt="上一頁" title="上一頁" v-if="morePage>0" @click="arrowBtn(-1)" @keydown.enter="arrowBtn(-1)" tabindex="0"></transition>

          <div class="img-content">
            <img class="relative w-full" src="../assets/Unit2-0/more/20230629-2-0-物件-13-臺灣診斷書-白紙.png">
            <div class="page page-1" :class="pageClass[0]" >
              <div class="left-wrapper">
                <p class="title">診斷</p>
                <p class="content-style"  style="margin-top: 1.2vw">姓名 : 臺灣島</p>
                <div class="content-style box-1" >診斷:世界文化的低能兒</div>
                <div class="content-style box-2">原因:智識營養不良</div>
              </div>

              <img class="human" src="../assets/Unit2-0/more/20230629-2-0-物件-19-臺灣診斷書-人.png" >

              <div class="right-wrapper">
                <p class="content-style"><span>豫後</span> 素質純良，應及時適當地治療，要是療法不對又荏苒拖延的話，會病入膏肓，有死亡的可能。</p>
                <p class="content-style" style="margin-top: 4vw"><span>療法</span> 根治療法。</p>
              </div>

              <div class="source">
                <p>主治醫生 : 蔣渭水</p>
                <div class="line"></div>
                <p class="year">1921.11.30</p>
                <p>資料來源:《蔣渭水遺集》</p>
              </div>
            </div>
            <div class="page page-2" :class="pageClass[1]" >
              <div class="human-circle"></div>
              <div class="left-wrapper">
                <p class="title">主訴</p>
              </div>

              <div class="tip content-style">點選圓圈，看病例說明。</div>

              <img class="human" src="../assets/Unit2-0/more/20230629-2-0-物件-19-臺灣診斷書-人.png">
              <transition name="img-frame-fade"><img v-if="humanText[0]==true" class="line-1" src="../assets/Unit2-0/more/20230629-2-0-物件-23-臺灣診斷書-圓線2.png"></transition>
              <transition name="img-frame-fade"><img v-if="humanText[1]==true" class="line-2" src="../assets/Unit2-0/more/20230629-2-0-物件-22-臺灣診斷書-圓線1.png"></transition>
              <transition name="img-frame-fade"><img v-if="humanText[2]==true" class="line-3" src="../assets/Unit2-0/more/20230629-2-0-物件-24-臺灣診斷書-圓線3.png"></transition>
              <transition name="img-frame-fade"><img v-if="humanText[3]==true" class="line-4" src="../assets/Unit2-0/more/20230629-2-0-物件-25-臺灣診斷書-圓線4.png"></transition>

              <div class="blue-dot" style="top: 24.9%; left: 50.8%;" @click="humanBtn(0)" @keydown.enter="humanBtn(0)" :tabindex="morePage==1?'0':'-1'" alt="點選圓圈，看病例說明。" title="點選圓圈，看病例說明。"></div>
              <div class="blue-dot" style="top: 29%; left: 46.3%;" @click="humanBtn(1)" @keydown.enter="humanBtn(1)" :tabindex="morePage==1?'0':'-1'" alt="點選圓圈，看病例說明。" title="點選圓圈，看病例說明。"></div>
              <div class="blue-dot" style="top: 66.3%; left: 46%;" @click="humanBtn(2)" @keydown.enter="humanBtn(2)" :tabindex="morePage==1?'0':'-1'" alt="點選圓圈，看病例說明。" title="點選圓圈，看病例說明。"></div>
              <div class="blue-dot" style="top: 61.6%; left: 49.8%;" @click="humanBtn(3)" @keydown.enter="humanBtn(3)" :tabindex="morePage==1?'0':'-1'" alt="點選圓圈，看病例說明。" title="點選圓圈，看病例說明。"></div>

              <transition name="img-frame-fade">
              <div v-if="humanText[0]==true" class="human-text" style="top: 19%; left: 69.5%;">
                <p class="content-style"  style="margin-top: 1.2vw">頭骨大，但是內容空虛、腦髓不充實。</p>
                <p class="content-style">比較困難的哲學、數學、科學，還有世界形勢論，一聽就頭暈、頭痛。</p>
              </div>
              </transition>
              <transition name="img-frame-fade">
              <div v-if="humanText[1]==true" class="human-text" style="top: 18%; left: 7.5%;">
                <p class="content-style"  style="margin-top: 1.2vw">頭痛、眩暈、腹內有飢餓感。</p>
                <p class="content-style">診斷了一下頭部比身體大，應該有很好的思考能力才對，但是提問2、3個常識問題，回答都不得要領。由此可知患者是個愚蠢的低能兒。</p>
              </div>
              </transition>
              <transition name="img-frame-fade">
              <div v-if="humanText[2]==true" class="human-text" style="top: 62%; left: 7.5%;">
                <p class="content-style"  style="margin-top: 1.2vw">手腳肥大，這是因為過度勞動的關係而導致。</p>
              </div>
              </transition>
              <transition name="img-frame-fade">
              <div v-if="humanText[3]==true" class="human-text" style="top: 19%; left: 69.5%;">
                <p class="content-style"  style="margin-top: 1.2vw">腔胴很瘦，凹進去。腹壁都是皺紋，好像孕婦的皮膚一樣出現白線條。我想像是大正五年來歐洲大戰的一時僥倖，肥胖了起來；從去年夏季颳講和風，像是腸病毒感冒，加上腹瀉，腹部極度膨脹，突然又縮起的原故。</p>
              </div>
              </transition>

              <div class="source">
                <p>主治醫生 : 蔣渭水</p>
                <div class="line"></div>
                <p class="year">1921.11.30</p>
                <p>資料來源:《蔣渭水遺集》</p>
              </div>
            </div>
            <div class="page page-3" :class="pageClass[2]" >
              <div class="left-wrapper">
                <p class="title">症狀</p>
              </div>
              <img class="human" src="../assets/Unit2-0/more/20230629-2-0-物件-19-臺灣診斷書-人.png">

              <transition name="circle">
                <div class="circle-wrapper" v-if="morePage==2">
                  <div style="width: 15.7vw">
                    <img class="circle-scale-1" src="../assets/Unit2-0/more/20230629-2-0-物件-26-臺灣診斷書-圓1.png" alt="道德敗壞" title="道德敗壞">
                    <img class="circle-scale-1" src="../assets/Unit2-0/more/20230629-2-0-物件-31-臺灣診斷書-圓6.png" alt="恬不知恥" title="恬不知恥">
                  </div>
                  <div style="width: 25.7vw">
                    <img class="circle-scale-2" src="../assets/Unit2-0/more/20230629-2-0-物件-27-臺灣診斷書-圓2.png" alt="完全無朝氣" title="完全無朝氣">
                    <img class="circle-scale-2" src="../assets/Unit2-0/more/20230629-2-0-物件-32-臺灣診斷書-圓7.png" alt="墮落怠惰" title="墮落怠惰">
                  </div>
                  <div style="width: 32.7vw; margin-top: 1.5vw">
                    <img class="circle-scale-3" src="../assets/Unit2-0/more/20230629-2-0-物件-28-臺灣診斷書-圓3.png" alt="缺乏精神生活" title="缺乏精神生活">
                    <img class="circle-scale-3" src="../assets/Unit2-0/more/20230629-2-0-物件-33-臺灣診斷書-圓8.png" alt="人心刻薄" title="人心刻薄">
                  </div>
                  <div  style="width: 29.7vw; margin-top: 3vw">
                    <img class="circle-scale-4" src="../assets/Unit2-0/more/20230629-2-0-物件-29-臺灣診斷書-圓4.png" alt="風俗醜態" title="風俗醜態">
                    <img class="circle-scale-4" src="../assets/Unit2-0/more/20230629-2-0-物件-34-臺灣診斷書-圓9.png" alt="惰氣滿滿" title="惰氣滿滿">
                  </div>
                  <div style="width: 19.7vw; margin-top: 1vw">
                    <img class="circle-scale-5" src="../assets/Unit2-0/more/20230629-2-0-物件-30-臺灣診斷書-圓5.png" alt="智力淺薄" title="智力淺薄">
                    <img class="circle-scale-5" src="../assets/Unit2-0/more/20230629-2-0-物件-35-臺灣診斷書-圓10.png" alt="意氣消沈" title="意氣消沈">
                  </div>
                </div>
              </transition>

              <div class="source">
                <p>主治醫生 : 蔣渭水</p>
                <div class="line"></div>
                <p class="year">1921.11.30</p>
                <p>資料來源:《蔣渭水遺集》</p>
              </div>
            </div>
            <div class="page page-4" :class="pageClass[3]" >
              <div class="left-wrapper">
                <p class="title">給臺灣的處方</p>
              </div>
              <div class="tip content-style">點選藥瓶，即可看處方。</div>
              <div class="bottle-wrapper">
                <img src="../assets/Unit2-0/more/20230629-2-0-物件-36-臺灣診斷書-處方1.png" alt="受正規學校教育" title="受正規學校教育" @click="bottleBtn(0)" @keydown.enter="bottleBtn(0)" :tabindex="morePage==3?'0':'-1'">
                <img src="../assets/Unit2-0/more/20230629-2-0-物件-38-臺灣診斷書-處方2.png" alt="圖書館" title="圖書館" @click="bottleBtn(1)" @keydown.enter="bottleBtn(1)" :tabindex="morePage==3?'0':'-1'">
                <img src="../assets/Unit2-0/more/20230629-2-0-物件-40-臺灣診斷書-處方3.png" alt="幼兒園" title="幼兒園" @click="bottleBtn(2)" @keydown.enter="bottleBtn(2)" :tabindex="morePage==3?'0':'-1'">
                <img src="../assets/Unit2-0/more/20230629-2-0-物件-42-臺灣診斷書-處方4.png" alt="補習教育" title="補習教育" @click="bottleBtn(3)" @keydown.enter="bottleBtn(3)" :tabindex="morePage==3?'0':'-1'">
                <img src="../assets/Unit2-0/more/20230629-2-0-物件-44-臺灣診斷書-處方5.png" alt="讀報社" title="讀報社" @click="bottleBtn(4)" @keydown.enter="bottleBtn(4)" :tabindex="morePage==3?'0':'-1'">
              </div>

              <transition name="img-frame-fade"><img v-if="bottleImg!=''" alt="受正規學校教育、圖書館、幼兒園、補習教育、讀報社" title="受正規學校教育、圖書館、幼兒園、補習教育、讀報社" class="bottle-img" :src="bottleImg"></transition>

              <div class="source">
                <p>主治醫生 : 蔣渭水</p>
                <div class="line"></div>
                <p class="year">1921.11.30</p>
                <p>資料來源:《蔣渭水遺集》</p>
              </div>
            </div>
            <div class="page page-5" :class="pageClass[4]" >
              <div class="left-wrapper">
                <p class="title">給臺灣的處方</p>
              </div>
              <img class="img-1" src="../assets/Unit2-0/more/20230629-2-0-物件-46-臺灣診斷書-pic.png" alt="〈臨床講義〉" title="〈臨床講義〉">
              <img class="zoom-2-btn" @click="showImg[6]=true" @keydown.enter="showImg[6]=true" :tabindex="morePage==4?'0':'-1'" style="width: 2.2vw; right: 20vw; bottom: 9.5vw" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <div class="img-text">
                <div class="bold-title">〈臨床講義〉</div>
                <div class="sub-title" >蔣渭水文化基金會 提供</div>
              </div>

            </div>
          </div>

          <transition name="img-frame-fade"><img class="arrow-btn" style="right: 9%"  src="../assets/common/right-arrow.png" alt="下一頁" title="下一頁" v-if="morePage<4" @click="arrowBtn(1)" @keydown.enter="arrowBtn(1)" tabindex="0"></transition>
          <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showMore=false">
        </div>


      </div>
    </div>
    </transition>

    <!--img0-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample9.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:21.1vw; margin:3.7vw 0 0 12vw" src="../assets/Unit2-0/20230629-2-0-物件-83-pic8-放大文物.png" alt="臺灣文化協會入會宣言書" title="臺灣文化協會入會宣言書">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 38.7vw; width: 21vw;">
            <div class="bold-title">臺灣文化協會入會宣言書</div>
            <div class="sub-title" >六然資料室 提供</div>
            <div class="noto-text text-left" style="calc(1.05vw * var(--font-size)); margin-top: 0.7vw; line-height: 2vw;">宣言內容:「鄙人此次贊同貴會宗旨，願意入會中以為會員，自當遵守會則以及決行事件，竭其心力已盡責任，特此宣言。」</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--      <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample5.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:19.2vw; margin:2.5vw 0 0 22vw" src="../assets/Unit2-0/20230629-2-0-物件-86-pic11-放大文物.png" alt="蔣渭水寄林獻堂書信" title="蔣渭水寄林獻堂書信">
          </div>
          <div class="content-text" style="position: absolute; top:30.6vw; left: 21.5vw; width: 20vw;">
            <div class="bold-title">蔣渭水寄林獻堂書信</div>
            <div class="noto-text ">國立臺灣歷史博物館 館藏 2018.011.0014.0003</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample6.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:18.5vw; margin:2.3vw 0 0 22.5vw" src="../assets/Unit2-0/20230629-2-0-物件-85-pic10-放大文物.png" alt="蔣渭水寄林獻堂書信" title="蔣渭水寄林獻堂書信">
          </div>
          <div class="content-text" style="position: absolute; top:30.6vw; left: 21.5vw; width: 20vw;">
            <div class="bold-title">蔣渭水寄林獻堂書信</div>
            <div class="noto-text ">國立臺灣歷史博物館 館藏 2018.011.0014.0002</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample7.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:20.3vw; margin:0vw 0 0 21.5vw" src="../assets/Unit2-0/20230629-2-0-物件-84-pic9-放大文物.png" alt="蔣渭水寄林獻堂書信" title="蔣渭水寄林獻堂書信">
          </div>
          <div class="content-text" style="position: absolute; top:30.6vw; left: 21.5vw; width: 20vw;">
            <div class="bold-title">蔣渭水寄林獻堂書信</div>
            <div class="noto-text ">國立臺灣歷史博物館 館藏 2018.011.0014.0001</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample8.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:31.6vw; margin:2.5vw 0 0 16.5vw" src="../assets/Unit2-0/20230629-2-0-物件-87-pic12-放大文物.png" alt="蔣渭水寄林獻堂書信" title="蔣渭水寄林獻堂書信">
          </div>
          <div class="content-text" style="position: absolute; top:30.6vw; left: 21.5vw; width: 20vw;">
            <div class="bold-title">蔣渭水寄林獻堂書信</div>
            <div class="noto-text ">國立臺灣歷史博物館 館藏 2018.011.0014.0004</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample9.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:41vw; margin:4.5vw 0 0 0vw" src="../assets/Unit2-0/20230629-2-0-物件-88-pic13-放大文物.png" alt="臺灣文化協會第一屆理事會" title="臺灣文化協會第一屆理事會">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 44.7vw; width: auto">
            <div class="bold-title">臺灣文化協會第一屆理事會</div>
            <div class="sub-title" >國立清華大學圖書館 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample9.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full flex justify-center items-center" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:41vw; top:-2vw" src="../assets/Unit2-0/more/20230629-2-0-物件-46-臺灣診斷書-pic.png" alt="〈臨床講義〉" title="〈臨床講義〉">
          </div>

          <div class="content-text absolute" style="top: 30.7vw; width: auto; left: 50%;  transform: translate(-50%,0%);">
            <div class="bold-title">〈臨床講義〉</div>
            <div class="sub-title" >蔣渭水文化基金會  提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>

    <intro-btn ></intro-btn>

    <FooterFeature color="white"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import LottieTest from "@/components/LottieTest";
import LottieOnce from "@/components/LottieOnce";
import {nextTick, onMounted, reactive, ref} from "vue";
import letterSrc from "@/assets/Unit2-0/letter-move/2-0-4-信位移動態-00.png";
import IntroBtn from "@/components/IntroBtn";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import AudioComp from "@/components/AudioComp";


export default {
  name: "Unit2-0",
  mixins: [myMixin],
  components:{
    LottieOnce,
    toolbar,
    LottieTest,
    IntroBtn,
    FooterFeature,
    AudioComp
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();

    const letterSrc = ref(null);
    letterSrc.value = require('@/assets/Unit2-0/letter-move/2-0-4-信位移動態-00.png');
    const envelopeStyle = ref('');
    const letterFade = ref(false);
    const lyricsType = ref(2);
    const videoRef = ref(null);
    const video2Ref = ref(null);
    const videoIdx = ref(1);
    const videoJson = ref(null);

    const showImg = reactive(Array.from({ length: 20 }, () => false));

    const showMore = ref(false);
    const humanText =  reactive(Array.from({ length: 4 }, () => false));
    const bottleImg = ref(require('@/assets/Unit2-0/more/20230629-2-0-物件-37-臺灣診斷書-處方答1.png')) ;
    const pageClass =  reactive(Array.from({ length: 5 }, () => 'scale-0'));
    const morePage =  ref(0);
    pageClass[morePage.value] = '';

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if(entry.target == videoRef.value){
            videoJson.value.anim.play()
          }
        }
      });
    }, {
      root: null, // 觀察器根節點，null 表示整個瀏覽器視窗
      threshold: 0.5 // 設定觸發 callback 的交叉比例，0 表示完全不交叉，1 表示完全交叉
    });

    const tabindex = ref(0);


    onMounted(() => {
      observer.observe(videoRef.value);
    });


    function routeBtn(path){
      router.push(path);
    }


    let letterInterval = null;
    let letterIndex = 0;
    let letterOpen = false;

    function letterUp() {
      if(letterOpen == true) return;
      clearInterval(letterInterval);
      letterIndex = 0;

      letterInterval = setInterval(()=>{

        letterIndex = (letterIndex + 1);

        letterSrc.value = require('@/assets/Unit2-0/letter-move/2-0-4-信位移動態-'+letterIndex.toString().padStart(2, '0') +'.png');

        if(letterIndex >= 12){
          clearInterval(letterInterval);
        }
      }, 13);
    }

    function letterDown() {
      if(letterOpen == true) return;
      clearInterval(letterInterval);
      letterIndex = 13;

      letterInterval = setInterval(()=>{

        letterIndex = (letterIndex - 1);

        letterSrc.value = require('@/assets/Unit2-0/letter-move/2-0-4-信位移動態-'+letterIndex.toString().padStart(2, '0') +'.png');

        if(letterIndex <= 0){
          clearInterval(letterInterval);
        }
      }, 13);
    }

    function envelopeOpen() {
      if(letterOpen == true) return;
      clearInterval(letterInterval);
      letterIndex = 1;
      letterOpen = true;

      letterInterval = setInterval(()=>{

        letterIndex = (letterIndex + 1);

        letterSrc.value = require('@/assets/Unit2-0/letter-open/2-0-4-信展開動態-'+letterIndex.toString().padStart(2, '0') +'.png');


        if(letterIndex >= 40){
          letterFade.value = true;
          clearInterval(letterInterval);
        }
      }, 40);
    }

    function videoRightBtn(){
      videoIdx.value=2;
      video2Ref.value.anim.play();
    }

    function humanBtn(index){
      for(let i=0; i <humanText.length; i++)
        humanText[i] = false;
      humanText[index] = true;
    }

    async function bottleBtn(num){
      bottleImg.value = '';
      await nextTick();

      switch (num){
        case 0: bottleImg.value = require('@/assets/Unit2-0/more/20230629-2-0-物件-37-臺灣診斷書-處方答1.png'); break;
        case 1: bottleImg.value = require('@/assets/Unit2-0/more/20230629-2-0-物件-39-臺灣診斷書-處方答2.png'); break;
        case 2: bottleImg.value = require('@/assets/Unit2-0/more/20230629-2-0-物件-41-臺灣診斷書-處方答3.png'); break;
        case 3: bottleImg.value = require('@/assets/Unit2-0/more/20230629-2-0-物件-43-臺灣診斷書-處方答4.png'); break;
        case 4: bottleImg.value = require('@/assets/Unit2-0/more/20230629-2-0-物件-45-臺灣診斷書-處方答5.png'); break;
      }
    }

    function arrowBtn(dir){
      if(dir==-1){
        pageClass[morePage.value] = "page-left-out";
        morePage.value -= 1;
        pageClass[morePage.value] = "page-left-in";
      }
      else if(dir == 1){
        pageClass[morePage.value] = "page-right-out";
        morePage.value += 1;
        pageClass[morePage.value] = "page-right-in";
      }
    }

    function moreBtn(){
      showMore.value = true;
      morePage.value = 0;
      for (let i=0; i <5; i++){
        pageClass[i] = 'scale-0';
      }
      pageClass[0] = '';
      tabindex.value = -1;
    }

    let firstLyrics = false;
    function lyricsBtn(type){
      if(!firstLyrics){
        envelopeStyle.value ='letter-div-open';
        envelopeOpen();
        lyricsType.value = 1;
        firstLyrics = true;
      }else{
        lyricsType.value = type;
      }
    }

    function escEvent(){
      if(showMore.value && !showImg[6]){
        showMore.value = false;
        tabindex.value = 0;
        document.getElementById('more-btn').focus();
      }

      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }


    return{
      routeBtn,
      letterSrc,
      letterUp,
      letterDown,
      envelopeStyle,
      envelopeOpen,
      letterFade,
      lyricsType,
      showImg,
      videoRef,
      video2Ref,
      videoIdx,
      videoRightBtn,
      videoJson,
      humanText,
      humanBtn,
      bottleImg,
      bottleBtn,
      morePage,
      showMore,
      moreBtn,
      arrowBtn,
      pageClass,
      lyricsBtn,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      tabindex,
      escEvent,
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit2-0.css"></style>

<!--<style scoped>-->

<!--.wrap{-->
<!--  position: relative;-->
<!--  width: 100%;-->
<!--}-->

<!--.sample{-->
<!--  position: relative;-->
<!--  width: 100%;-->
<!--}-->

<!--.test-btn{-->
<!--  position: absolute;-->
<!--  //background: black;-->
<!--  width: 59vw;-->
<!--  height: 31vw;-->
<!--  bottom: 6%;-->
<!--  left: 14%;-->
<!--  cursor: pointer;-->
<!--}-->


<!--</style>-->
