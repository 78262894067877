<template>
  <div class="unit1-1-wrap relative" @keydown.esc="escEvent" >
    <toolbar type="1" unit="1" route-name="unit1-1" color="brown"  :style="toolbarStyle" ></toolbar>

<!--    <img class="sample" src="../assets/Unit1-1/20230920-對位-1-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="video-wrap w-full relative z-10" >
      <video muted loop autoplay  playsinline class="w-full">
        <source src="../assets/Unit1-1/video.mp4" type="video/mp4">
      </video>
      <div class="big-title">鳥瞰世界</div>
    </div>

    <div class="relative w-full z-10" >
      <img class="absolute w-full" style=" top: -13vw" src="../assets/Unit1-1/cloud.png" alt="雲" title="雲">
    </div>

    <div class="part1">
      <div class="airplane-1"  alt="飛機" title="飛機">
          <img src='@/assets/Unit1-1/airplane-1.png' alt="飛機" title="飛機">
          <div class="pilot" alt="飛行員" title="飛行員">
            <LottieTest :json="require('@/assets/Unit1-1/01.json')" ></LottieTest>
          </div>
        </div>

      <div class="content">
        <div>
          <img src="../assets/Unit1-1/logo-1.png" alt="飛行" title="飛行" >
        </div>
        <div tabindex="0">
          <p>1917年，臺中有名16歲的少年謝文達，仰望天空，看著來自美國的特技飛行員亞瑟史密斯的飛行表演。3年後，他自日本的飛行學校畢業，成為史上第一位臺灣出身的飛行員。</p>
          <p>1920年8月至10月間謝文達在臺中、臺北舉辦訪問飛行，引起社會矚目，臺北醫學校、師範學校的學生尤其激動， 掀起團結應援的浪潮，9月23日舉辦上千人的「謝文達飛行後援會」，為有史以來最大型的青年集會。 10月17日，  謝文達駕駛「新高號」在臺中進行「鄉土訪問飛行表演」， 後來又北上在「臺北練兵場」飛行表演。</p>
          <p>「臺灣文化協會」的成立，就定在他「鄉土訪問飛行」一週年的這天。</p>
        </div>
      </div>

      <div class="down-arrow" alt="請繼續往下探索" title="請繼續往下探索">
        <div>請繼續往下探索</div>
        <LottieTest :json="require('@/assets/Unit1-1/down.json')" ></LottieTest>
      </div>

    </div>

    <div class="part2">
      <img class="airplane-2" src='@/assets/Unit1-1/airplane-2.png' alt="飛機" title="飛機">
      <div class="cloud-anim-1" alt="雲" title="雲">
        <LottieTest :json="require('@/assets/Unit1-1/02.json')" ></LottieTest>
      </div>
      <div class="cloud-anim-2" alt="雲" title="雲">
        <LottieTest :json="require('@/assets/Unit1-1/03.json')" ></LottieTest>
      </div>

      <div class="content" tabindex="0">
        <div>協力，從一場飛行開始 ……</div>
        <div>謝文達1920年座機「新高號」（伊藤惠美5型），這部螺旋槳飛機， 帶我們拉高視野鳥瞰百年前的臺灣。這是臺灣第一位飛行士謝文達返鄉翱翔的場面。</div>
        <div>謝文達光榮返鄉的座機，為美國生產，由飛行學校創辦人伊藤音次郎購入，經改裝而成型，據說謝家當年是出售了三個糖廍才購得。</div>
      </div>

      <div class="people" alt="人物插畫" title="人物插畫">
        <LottieTest :json="require('@/assets/Unit1-1/04.json')" ></LottieTest>
      </div>
    </div>

    <div class="part3">

    </div>


    <div class="part4" >
      <div class="fly-paper-wrap" >
<!--        <img class="fly-paper" :style="flyPaperStyle" src="../assets/Unit1-1/fly-paper.png" alt="紙" title="紙">-->
        <transition name="img-frame-fade">
          <img v-if="flyPaperSrc!=''" class="fly-paper" :style="flyPaperStyle" :src="flyPaperSrc" alt="紙" title="紙">
        </transition>
      </div>

      <img class="airplane-3" src="../assets/Unit1-1/airplane-3.png" alt="飛機與駕駛" title="飛機與駕駛">
      <img class="paper-2" src="../assets/Unit1-1/paper-2.png" alt="謝文達飛行記事報導" title="謝文達飛行記事報導">
      <img class="paper-1" src="../assets/Unit1-1/paper-1.png" alt="謝文達飛行記事報導" title="謝文達飛行記事報導">
      <img class="paper-3" src="../assets/Unit1-1/paper-3.png" alt="謝文達飛行記事報導" title="謝文達飛行記事報導">
      <img class="zoom-btn" @click="showPaperImg=true" @keydown.enter="showPaperImg=true; " tabindex="0" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">

      <div class="content" ref="flypaper">
        <img class="logo" src="../assets/Unit1-1/logo-2.png" alt="臺北號" title="臺北號">
        <div tabindex="0">
          在謝文達即將演出前，新聞指出他的座駕老舊修葺困難，為了贊助飛行的宏願，飛行後援會獲得各界熱烈捐款，募集了2萬5千多圓，向日本伊藤飛行製作所購置三座位式飛機致贈謝文達， 後命名「臺北號」。熱烈的募資行動，與青年結社的盛況，象徵文化協會的成立已是箭在弦上。
        </div>
      </div>

      <img class="airplane-4" src="../assets/Unit1-1/airplane-4.png" alt="飛機" title="飛機">



    </div>

    <div class="part5 part-color">
      <img class="tree-1" src="../assets/Unit1-1/tree-1.png" alt="樹" title="樹">
      <img class="tree-2" src="../assets/Unit1-1/tree-2.png" alt="樹" title="樹">

      <div class="people" alt="人物插畫" title="人物插畫">
        <LottieTest :json="require('@/assets/Unit1-1/05.json')" ></LottieTest>
      </div>

      <div class="cloud" alt="雲" title="雲">
        <LottieTest :json="require('@/assets/Unit1-1/06.json')" ></LottieTest>
      </div>
      <div class="people-2" alt="人物插畫" title="人物插畫">
        <LottieTest :json="require('@/assets/Unit1-1/07.json')" ></LottieTest>
      </div>
      <div class="paper" ref="paper" alt="傳單" title="傳單">
        <LottieTest :json="require('@/assets/Unit1-1/08.json')" ></LottieTest>
      </div>

      <img class="airplane-5" ref="airplane5"  src="../assets/Unit1-1/airplane-5.png" alt="飛機" title="飛機">


      <div class="content">
        <img class="logo" src="../assets/Unit1-1/logo-3.png" alt="使命" title="使命">
        <div tabindex="0">
          1923年2月謝文達駕臺北號在東京散發「排擊總督獨裁政治、 要求臺灣議會設置」傳單，宣揚議會請願運動、跟日本統治者決裂，而後他也參與過社會問題研究會，自詡投入「為無產者而活動」的政治運動事業。
        </div>
      </div>
    </div>

    <div class="part6 part-color">
      <div class="unit-btn-wrap">
        <div class="unit-btn" style="width: 16.3vw" @click="routeBtn('/unit1-1')" @keydown.enter="routeBtn('/unit1-1')" tabindex="0">
          <img src="../assets/Unit1-1/20230801-1-鳥瞰世界-物件-21-鳥瞰世界按紐-淡.jpg"  alt="鳥瞰世界" title="鳥瞰世界">
          <div>鳥瞰世界</div>
        </div>
        <div class="unit-btn" @click="routeBtn('/unit1-2')" @keydown.enter="routeBtn('/unit1-2')" tabindex="0">
          <img src="../assets/Unit1-1/unit1-2.png"  alt="思潮滾動世界" title="思潮滾動世界">
          <div>思潮滾動世界</div>
        </div>
      </div>
    </div>

    <transition name="img-frame-fade"   @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showPaperImg" >
        <!--                <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit1-1/sample-2.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="paper-1" src="../assets/Unit1-1/paper-zoom-1.png" alt="謝文達飛行記事報導" title="謝文達飛行記事報導">
            <img class="paper-2" src="../assets/Unit1-1/paper-zoom-2.png" alt="謝文達飛行記事報導" title="謝文達飛行記事報導">
            <img class="paper-3" src="../assets/Unit1-1/paper-zoom-3.png" alt="謝文達飛行記事報導" title="謝文達飛行記事報導">
          </div>
          <div class="content-text">
            <div class="title">謝文達飛行記事報導</div>
            <div class="subtitle">臺灣日日新報新聞剪輯</div>
            <div class="subtitle">1920年08月28日 日刊 07 版 | 1920年08月29日 日刊 06 版 |</div>
            <div class="subtitle">1920年09月12日 日刊 05 版 |</div>
            <div class="text">從飛行演出、飛機維修到募集採購新機，到 1923 年在東京為臺灣議會設置請願灑下傳單、瀟灑宣布引退，三年間謝文達的飛行動態都是新聞焦點。</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')" >
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')" >
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showPaperImg=false" >
          </div>

        </div>
      </div>
    </transition>


    <intro-btn></intro-btn>

    <footer-feature></footer-feature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import IntroBtn from "@/components/IntroBtn";
import router from "@/router";
import {onMounted, onUnmounted, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";

export default {
  name: "Unit1-1",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();

    const showPaperImg = ref(false);
    const toolbarStyle = ref("background: rgba(255,255,255,0.75)");

    function routeBtn(path){
      router.push(path);
    }

    const airplane5 = ref(null);
    const paper = ref(null);
    const flyPaperStyle = ref('');
    const flyPaperSrc = ref('');
    const flypaper = ref(null);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          //console.log('目標元素進入畫面');
          if(entry.target == airplane5.value){
            airplane5.value.classList.add('airplane-5-anim');
          }
          else if(entry.target == paper.value){
            paper.value.classList.add('paper-anim');
          }
          else if(entry.target == flypaper.value){
            flyPaperAnim();
          }
        }
      });
    }, {
      root: null, // 觀察器根節點，null 表示整個瀏覽器視窗
      threshold: 0.5 // 設定觸發 callback 的交叉比例，0 表示完全不交叉，1 表示完全交叉
    });

    onMounted(() => {
      // 在組件挂載後開始觀察目標元素
      observer.observe(airplane5.value);
      observer.observe(paper.value);
      observer.observe(flypaper.value)

      //window.addEventListener('scroll', handleScroll);

      window.addEventListener('scroll', titleScroll);
    });

    // function  handleScroll() {
    //   const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; // 获取窗口高度
    //   const fullHeight = document.documentElement.scrollHeight || document.body.scrollHeight; // 获取页面总高度
    //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // 获取滚动距离
    //   this.scrollPercentage = (scrollTop / (fullHeight - windowHeight)) * 100; // 计算滚动页面百分比
    //   //this.imgStyle.transform = `translateY(${this.scrollPercentage * 0.3}px)`; // 根据滚动页面百分比计算图片的位移
    //   if(this.scrollPercentage >= 50 && this.scrollPercentage <= 60){
    //     //console.log(this.scrollPercentage);
    //     let percent = (this.scrollPercentage - 50)/(60-50);
    //     //console.log(percent)
    //     flyPaperStyle.value = 'top:'+ (-70+(79*percent)).toString() + "%; left:" + (15+(32*percent)).toString()+"%;";
    //   }
    // }


    let flypaperIndex = 1;
    let flypaperInterval = null;
    function flyPaperAnim(){
      clearInterval(flypaperInterval);
      flypaperIndex = 0;

      flypaperInterval = setInterval(()=>{

        flypaperIndex = (flypaperIndex + 1) % (45+1);

        flyPaperSrc.value = require('../assets/Unit1-1/12-插畫/12-插畫-'+flypaperIndex.toString() +'.png');

        if(flypaperIndex == 45){
          clearInterval(flypaperInterval);
        }
      }, 60);
    }

    function escEvent(){
      showPaperImg.value = false;
    }

    onUnmounted(() => {
      // 在組件卸載前解除 Intersection Observer
      observer.disconnect();
      clearInterval(flypaperInterval);

      window.removeEventListener('scroll', titleScroll);
    });

    function titleScroll() {
      const windowHeight = (document.documentElement && document.documentElement.clientHeight) || document.body.clientHeight;
      const docHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
      const windowBottom = windowHeight + window.pageYOffset;

      // 这里我们取滚动的百分比
      const scrollPercent = (windowBottom / docHeight) * 100;

      //console.log(scrollPercent)

      if (scrollPercent > 25) { // 当页面滚动超过50%时
        toolbarStyle.value = "background: rgba(255,255,255,0)";
      } else {
        toolbarStyle.value = "background: rgba(255,255,255,0.75)";
      }
    }

    return{
      routeBtn,
      showPaperImg,
      airplane5,
      paper,
      flyPaperStyle,
      flyPaperSrc,
      flypaper,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent,
      toolbarStyle
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit1-1.css"></style>
