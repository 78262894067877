<template>
      <audio
          ref="audio"
          @timeupdate="updateTime"
          @loadedmetadata="updateTime"
      >
      <source  :src="audioSrc" type="audio/mpeg">
      </audio>
      <div class="audio-div">
        <img @click="playAudio" @keydown.enter="playAudio" tabindex="0" class="play-btn" :src="playBtnImg" alt="播放(play)/暫停(pause)" title="播放(play)/暫停(pause)">
        <p >{{formatTime(currentTime)}}</p>
        <div class="progress-bar">
          <progress class="progress" :value="currentTime" :max="duration"></progress>
          <input class="progress-input" type="range" min="0" :max="duration" step="0.1" v-model="currentTime" @input="seekAudio" tabindex="-1">
        </div>

        <p>{{formatTime(duration)}}</p>

        <img class="volume-btn" src="../assets/common/20230629-2-0-物件-100-音量鍵.png" @click="showVolume=!showVolume" @keydown.enter="showVolume=!showVolume" tabindex="0" alt="音量鍵(volume button)" title="音量鍵(volume button)">
        <transition name="img-frame-fade">
        <div class="volume-bar" v-if="showVolume" :style="volumeStyle">
          <progress class="volume-progress" :class="styleClass" :value="volume"  :max="1" ></progress>
          <input class="volume-input" type="range" min="0" max="1" step="0.1" v-model="volume" @input="setVolume" tabindex="0" alt="按方向鍵上/下調整(Press the arrow keys up/down to adjust the volume)" title="按方向鍵上/下調整(Press the arrow keys up/down to adjust the volume)">
        </div>
        </transition>

      </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "AudioComp",
  props:{
    audioSrc:{
      type:String,
      default:''
    },
    styleClass:{
      type:String,
      default :'white-style'
    },
    volumeStyle:{
      type:String,
      default:''
    }
  },
  setup(){
    const audio = ref();
    const currentTime = ref(0);
    const duration = ref(0);
    const audioStyle = ref('');
    const playBtnImg = ref(require("../assets/common/play-btn.png"));
    const volume = ref(100);
    const showVolume = ref(false);

    function playAudio(){
      if(!audio.value.paused){
        audio.value.pause();
        playBtnImg.value = require("../assets/common/play-btn.png");
      }else{
        audio.value.play();
        playBtnImg.value = require("../assets/common/20230629-2-0-物件-99-音樂暫停鍵.png");
      }
    }

    function updateTime() {
      currentTime.value = audio.value.currentTime;
      duration.value = audio.value.duration;
      audioStyle.value = "width:" + (currentTime.value/duration.value)*100 +"%";
    }

    function formatTime(time){
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    function seekAudio(){
      audio.value.currentTime = currentTime.value;
    }

    function setVolume(){
      audio.value.volume = volume.value;
    }

    return{
      audio,
      playAudio,
      updateTime,
      formatTime,
      currentTime,
      duration,
      audioStyle,
      playBtnImg,
      volume,
      setVolume,
      showVolume,
      seekAudio,
    }
  }
}
</script>

<style src="../assets/css/audio-comp.css"></style>
