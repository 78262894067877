<template>
  <div class="home-wrap">
    <EnToolbar type="1" unit="enter" route-name="enter" style="background: rgba(255,255,255,0.75)"></EnToolbar>

<!--    <img style="position: absolute; width: 100%; top: 0%; opacity: 0.4; z-index: 5" src="../assets/enter/sample/20230503-臺史博-文協線上展-EN網站-廟口拼文化(34單元入口)-op-1.png">-->

    <img class="map" src="../assets/enter/map.png" alt="">

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="map-content">
      <div class="p20"><LottieTest :json="require('@/assets/enter/0-15.json')" ></LottieTest></div>
      <div class="tree-1"><img class="w-full" src="../assets/enter/tree-1.png"></div>
      <div class="tree-2"><img class="w-full" src="../assets/enter/tree-2.png"></div>
      <div class="h8"><img class="w-full" src="../assets/enter/h8.png" alt="龍山寺" title="龍山寺"></div>
      <div class="p1"><LottieTest :json="require('@/assets/enter/0-5.json')" ></LottieTest></div>
      <div class="h5"><img class='w-full' src="../assets/enter/h5.png" alt="新竹座" title="新竹座"></div>
      <div class="h6"><img class="w-full" src="../assets/enter/h6.png" alt="學校" title="學校"></div>

      <div class="p2"><LottieTest :json="require('@/assets/enter/0-2.json')" ></LottieTest></div>
      <div class="p3"><LottieTest :json="require('@/assets/enter/0-4.json')" ></LottieTest></div>
      <div class="p4"><LottieTest :json="require('@/assets/enter/0-12.json')" ></LottieTest></div>
      <div class="h10"><img class="w-full" src="../assets/enter/h10.png" alt="讀報社" title="讀報社"></div>
      <div class="h3"><img class="w-full" src="../assets/enter/h3.png" alt="沙龍" title="沙龍"></div>
      <div class="p14"><LottieTest :json="require('@/assets/enter/0-3.json')" ></LottieTest></div>
      <div class="p5"><LottieTest :json="require('@/assets/enter/0-13.json')" ></LottieTest></div>
      <div class="h4"><img class="w-full" src="../assets/enter/h4.png" alt="房子" title="房子"></div>
      <div class="h7"><img class="w-full" src="../assets/enter/h7.png" alt="糖廠" title="糖廠"></div>
      <div class="p6"><LottieTest :json="require('@/assets/enter/0-16.json')" ></LottieTest></div>
<!--      <div class="p6"><img class='w-full' src="../assets/enter/gif/1-6.gif" alt="" title=""></div>-->
<!--      <div class="p7"><img class='w-full' src="../assets/enter/gif/1-7.gif" alt="" title=""></div>-->
      <div class="h9"><img class="w-full" src="../assets/enter/h9.png" alt="江山樓" title="江山樓"></div>
      <div class="h2"><img class="w-full" src="../assets/enter/h2.png" alt="講演會" title="講演會"></div>
      <div class="p8"><LottieTest :json="require('@/assets/enter/0-14.json')" ></LottieTest></div>
      <div class="p9"><LottieTest :json="require('@/assets/enter/0-18.json')" ></LottieTest></div>
      <div class="h1"><img class="w-full" src="../assets/enter/h1.png" alt="大安醫院" title="大安醫院"></div>
      <div class="p10"><LottieTest :json="require('@/assets/enter/0-19.json')" ></LottieTest></div>
      <div class="p12"><LottieTest :json="require('@/assets/enter/0-11.json')" ></LottieTest></div>
      <div class="p13"><LottieTest :json="require('@/assets/enter/0-10.json')" ></LottieTest></div>
      <div class="p15"><LottieTest :json="require('@/assets/enter/0-9.json')" ></LottieTest></div>
      <div class="p16"><LottieTest :json="require('@/assets/enter/0-8.json')" ></LottieTest></div>
      <div class="p17"><LottieTest :json="require('@/assets/enter/0-7.json')" ></LottieTest></div>
      <div class="p18"><LottieTest :json="require('@/assets/enter/0-6.json')" ></LottieTest></div>
      <div class="p19"><LottieTest :json="require('@/assets/enter/0-17.json')" ></LottieTest></div>
      <div class="airplane"><LottieTest :json="require('@/assets/enter/0-1-飛機.json')" ></LottieTest></div>



      <div class="name-btn name-1" @click="routeBtn('/en/person-intro/8')" @keydown.enter="routeBtn('/en/person-intro/8')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-盧丙丁-按鈕.png"></div>
      <div class="name-btn name-2" @click="routeBtn('/en/person-intro/12')" @keydown.enter="routeBtn('/en/person-intro/12')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-陳澄波-按鈕.png"></div>
      <div class="name-btn name-3" @click="routeBtn('/en/person-intro/2')" @keydown.enter="routeBtn('/en/person-intro/2')" tabindex="0"><img style="top:2vw; left: 7.6vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-謝文達-按鈕.png"></div>
      <div class="name-btn name-4" @click="routeBtn('/en/person-intro/15')" @keydown.enter="routeBtn('/en/person-intro/15')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-蘇璧輝-按鈕.png"></div>
      <div class="name-btn name-5" @click="routeBtn('/en/person-intro/18')" @keydown.enter="routeBtn('/en/person-intro/18')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-韓石泉-按鈕.png"></div>
      <div class="name-btn name-6" @click="routeBtn('/en/person-intro/1')" @keydown.enter="routeBtn('/en/person-intro/1')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-蔡培火-按鈕.png"></div>
      <div class="name-btn name-7" @click="routeBtn('/en/person-intro/4')" @keydown.enter="routeBtn('/en/person-intro/4')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-謝雪紅-按鈕.png"></div>
      <div class="name-btn name-8" @click="routeBtn('/en/person-intro/5')" @keydown.enter="routeBtn('/en/person-intro/5')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-簡娥-按鈕.png"></div>
      <div class="name-btn name-9" @click="routeBtn('/en/person-intro/6')" @keydown.enter="routeBtn('/en/person-intro/6')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-陳植棋-按鈕.png"></div>
      <div class="name-btn name-10" @click="routeBtn('/en/person-intro/0')" @keydown.enter="routeBtn('/en/person-intro/0')" tabindex="0"><img style="top: -3.8vw; left: -1.1vw;" src="../assets/enter/en/name-btn/20230503-EN網站-物件-連溫卿-按鈕.png"></div>
      <div class="name-btn name-11" @click="routeBtn('/en/person-intro/7')" @keydown.enter="routeBtn('/en/person-intro/7')" tabindex="0"><img style="top: -3.8vw; left: -1.5vw;" src="../assets/enter/en/name-btn/20230503-EN網站-物件-莊松林-按鈕.png"></div>
      <div class="name-btn name-12" @click="routeBtn('/en/person-intro/9')" @keydown.enter="routeBtn('/en/person-intro/9')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-王詩琅-按鈕.png"></div>
      <div class="name-btn name-13" @click="routeBtn('/en/person-intro/10')" @keydown.enter="routeBtn('/en/person-intro/10')" tabindex="0"><img style="top:-1.5vw; left: 2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-蔡淵騰-按鈕.png"></div>
      <div class="name-btn name-14" @click="routeBtn('/en/person-intro/13')" @keydown.enter="routeBtn('/en/person-intro/13')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-陳懷澄-按鈕.png"></div>
      <div class="name-btn name-15" @click="routeBtn('/en/person-intro/14')" @keydown.enter="routeBtn('/en/person-intro/14')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-林幼春-按鈕.png"></div>
      <div class="name-btn name-16" @click="routeBtn('/en/person-intro/16')" @keydown.enter="routeBtn('/en/person-intro/16')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-杜香國-按鈕.png"></div>
      <div class="name-btn name-17" @click="routeBtn('/en/person-intro/11')" @keydown.enter="routeBtn('/en/person-intro/11')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-蔡惠如-按鈕.png"></div>
      <div class="name-btn name-18" @click="routeBtn('/en/person-intro/19')" @keydown.enter="routeBtn('/en/person-intro/19')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-李應章-按鈕.png"></div>
      <div class="name-btn name-19" @click="routeBtn('/en/person-intro/20')" @keydown.enter="routeBtn('/en/person-intro/20')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-賴和-按鈕.png"></div>
      <div class="name-btn name-20" @click="routeBtn('/en/person-intro/17')" @keydown.enter="routeBtn('/en/person-intro/17')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-蔣渭水-按鈕.png"></div>
      <div class="name-btn name-21" @click="routeBtn('/en/person-intro/21')" @keydown.enter="routeBtn('/en/person-intro/21')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-林淇漳-按鈕.png"></div>
      <div class="name-btn name-22" @click="routeBtn('/en/person-intro/3')" @keydown.enter="routeBtn('/en/person-intro/3')" tabindex="0"><img style="top:-4.2vw" src="../assets/enter/en/name-btn/20230503-EN網站-物件-林獻堂-按鈕.png"></div>


      <div class="enter-btn">
        <div class="btn-2" @click="routeBtn('/en/unit3-1')" @keydown.enter="routeBtn('/en/unit3-1')" tabindex="0"><img class='w-full' src="../assets/enter/en/enter-btn/1-2.png" alt="Cultural Lectures" title="Cultural Lectures"></div>
        <div class="btn-9" @click="routeBtn('/en/unit3-2')" @keydown.enter="routeBtn('/en/unit3-2')" tabindex="0"><img class='w-full' src="../assets/enter/en/enter-btn/1-9.png" alt="Stage Performances and Films" title="Stage Performances and Films"></div>
        <div class="btn-4" @click="routeBtn('/en/unit3-3')" @keydown.enter="routeBtn('/en/unit3-3')" tabindex="0"><img class='w-full' src="../assets/enter/en/enter-btn/1-4.png" alt="Speech Forums" title="Speech Forums"></div>
        <div class="btn-5" @click="routeBtn('/en/unit3-4')" @keydown.enter="routeBtn('/en/unit3-4')" tabindex="0"><img class='w-full' src="../assets/enter/en/enter-btn/1-5.png" alt="Social Ballads" title="Social Ballads"></div>
        <div class="btn-10" @click="routeBtn('/en/unit3-5')" @keydown.enter="routeBtn('/en/unit3-5')" tabindex="0"><img class='w-full' src="../assets/enter/en/enter-btn/1-10.png" alt="Esperanto" title="Esperanto"></div>
        <div class="btn-8" @click="routeBtn('/en/unit4-1')" @keydown.enter="routeBtn('/en/unit4-1')" tabindex="0"><img class='w-full' src="../assets/enter/en/enter-btn/1-8.png" alt="Monument of Time" title="Monument of Time"></div>
        <div class="btn-1" @click="routeBtn('/en/unit4-2')" @keydown.enter="routeBtn('/en/unit4-2')" tabindex="0"><img class='w-full' src="../assets/enter/en/enter-btn/1-1.png" alt="Cultural Bases" title="Cultural Bases"></div>
        <div class="btn-7" @click="routeBtn('/en/unit4-3')" @keydown.enter="routeBtn('/en/unit4-3')" tabindex="0"><img class='w-full' src="../assets/enter/en/enter-btn/1-7.png" alt="Social Forums" title="Social Forums"></div>
        <div class="btn-6" @click="routeBtn('/en/unit4-4')" @keydown.enter="routeBtn('/en/unit4-4')" tabindex="0"><img class='w-full' src="../assets/enter/en/enter-btn/1-6.png" alt="Changing Customs" title="Changing Customs"></div>
        <div class="btn-3" @click="routeBtn('/en/unit4-5')" @keydown.enter="routeBtn('/en/unit4-5')" tabindex="0"><img class='w-full' src="../assets/enter/en/enter-btn/1-3.png" alt="Our Own Memorial Days" title="Our Own Memorial Days"></div>
      </div>

    </div>

<!--    <div class="feature">-->
<!--      <div class="museum-logo" @click="openUrl('https://www.nmth.gov.tw/')"><img class="w-full" src="../assets/enter/museum-logo.png" alt="https://www.nmth.gov.tw/ (另開新視窗)" title="https://www.nmth.gov.tw/ (另開新視窗)"></div>-->
<!--      <div class="open-btn"  alt="開啟下方選單區" title="開啟下方選單區"><img class="w-full" src="../assets/enter/open.png" alt="開啟下方選單區" title="開啟下方選單區"></div>-->
<!--      <div class="font-btn"  alt="縮放字級" title="縮放字級"><img class="w-full" src="../assets/enter/font.png" alt="縮放字級" title="縮放字級"></div>-->
<!--      <div class="share-btn" alt="分享" title="分享"><img class="w-full" src="../assets/enter/share.png" alt="分享" title="分享"></div>-->
<!--    </div>-->

    <FooterFeature language="en"></FooterFeature>
    <EnIntroBtn></EnIntroBtn>

<!--    <div class="intro-window" v-if="showIntroWindow" @click="closeIntroWindow">-->
<!--      <img class="h-full" src="../assets/enter/intro-window.png">-->
<!--    </div>-->
  </div>


<!--  <img class="sample" src="../assets/sample.jpg">-->
</template>

<script>


import {ref} from "vue";
import router from "@/router";
import EnToolbar from "@/components/EnToolbar.vue";
import EnIntroBtn from "@/components/EnIntroBtn.vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";

export default {
  name: 'HomeView',
  components: {
    EnToolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature
  },
  setup(){

    /*調整css變數
    const root = document.documentElement;
    root.style.setProperty('--font-size', '5');
     */

    function openUrl(url, type){
      window.open(url, type);
    }

    function routeBtn(path){
      router.push(path);
    }

    return{
      openUrl,
      routeBtn,
    }
  }

}
</script>

<style scoped src="@/assets/css/en/enter.css"></style>

<!--<style>-->
<!--:root {-->
<!--  &#45;&#45;font-size: 1;-->
<!--}-->
<!--div{-->
<!--  font-size: calc(2vw * var(&#45;&#45;font-size));-->
<!--}-->
<!--</style>-->
