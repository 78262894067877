<template>
  <div class="unit2-1-wrap" @keydown.esc="escEvent">
    <toolbar type="1" unit="2" route-name="unit2-1"  color="orange"></toolbar>

<!--    <img class="sample" src="../assets/Unit2-1/sample4.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="building" src="../assets/Unit2-1/20230407-物件-2-1-01-首圖1.png">

      <div class="taiwan" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-0.json')" alt="臺灣人的臺灣" title="臺灣人的臺灣" ></LottieTest>
      </div>

      <div class="title-logo">臺灣人的臺灣</div>
    </div>

    <div class="part2 part-color">
      <div class="content">
          <img style="width: 31.5vw" src="../assets/Unit2-1/20230407-物件-2-1-02-裝飾字1-議會請願.png" alt="議會請願" title="議會請願">
          <p tabindex="0" style="margin-top:2.1vw">文化協會組成後的最初4年間，以讀報會、講習會、夏季學校等平民教育活動為主，政治主張較不明顯，以倡導臺灣自治、議會設置為核心。</p>
          <p>議會請願是日治時期代表性的政治運動，1921年1月林獻堂領銜的簽署書，提交日本帝國議會，訴求要「設置由臺灣民選之議員所組織之臺灣議會」，要有具特別立法、預算協贊等實質議會權利。爾後至1934年的14年間共請願15次，聯署人最高達2,400多人。大張旗鼓的請願運動，使臺灣統治根本問題受到日本國會討論，更啟發臺灣人自治自覺的政治意識。</p>
      </div>

      <img class="building" src="../assets/Unit2-1/20230407-物件-2-1-03-pic1.png" title="1925年臺灣議會請願團請願運動抵達東京車站的情景" alt="1925年臺灣議會請願團請願運動抵達東京車站的情景">

      <div class="people" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-1.json')" alt="人群" title="人群" ></LottieTest>
      </div>

      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
    </div>

    <div class="part3 part-color">
          <div class="pics-div">
            <img src="../assets/Unit2-1/20230407-物件-2-1-05-pic2.png" title="林獻堂邀杜香國參加議會請願委員洗塵宴信" alt="林獻堂邀杜香國參加議會請願委員洗塵宴信">
            <img style="margin-left: 1.5vw" src="../assets/Unit2-1/20230407-物件-2-1-06-pic3.png" title="林獻堂邀杜香國參加議會請願委員洗塵宴信" alt="林獻堂邀杜香國參加議會請願委員洗塵宴信">
          </div>
          <img class="zoom-2-btn" @click="showImg[1]=true"  @keydown.enter="showImg[1]=true" tabindex="0" style="top: 51.3%; left: 26.6%; width: 2.1vw" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
          <img class="zoom-2-btn" @click="showImg[2]=true"  @keydown.enter="showImg[2]=true" tabindex="0" style="top: 52%; left: 81.3%; width: 2.1vw" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">

          <div class="content">
            <p>請願回國來聚餐</p>
            <p>林獻堂邀杜香國參加議會請願委員洗塵宴信</p>
            <p>國立臺灣歷史博物館 館藏 2020.032.0143.0001~2</p>
            <p>此為林獻堂邀請杜香國參加請願委員洗塵宴的書信，委員有蔣渭水、蔡培火及洪元煌等人請願委員，聚餐地點在臺中永樂樓，聚餐費用則採會費制。林獻堂也在信中請杜香國邀請相關人士一起來參加，由此可見文協成員交誼的方式及對議會請願運動的重視。</p>
          </div>
        </div>

    <div class="part4 part-color" ref="part4">
      <div class="circle-wrap">
        <div class="circle up-semi part-color" >

          <div class="content">
            <img style="width: 26.5%" src="../assets/Unit2-1/20230407-物件-2-1-08-裝飾字2-治警事件.png" alt="治警事件" title="治警事件">
            <p tabindex="0">為長期推動議會設置請願，蔡培火、蔣渭水在1923年發起成立「臺灣議會期成同盟會」，在東京與臺灣各地舉辦活動，同年治安警察法實施，在總督府要求下，文協承諾不涉及政治議題，不過在該年年底，議會期成同盟遭到取締，蔡培火、蔣渭水等文協主力被捕，纏訟一年多後，幾位核心人物被判刑，史稱「治警事件」。而治警事件中入獄的蔣渭水、蔡培火、陳逢源、林幼春、王敏川等，分別留下許多詩詞作品。</p>
          </div>

          <div class="people" >
            <LottieTest :json="require('@/assets/Unit2-1/2-1-2.json')" alt="治警事件" title="治警事件"></LottieTest>
          </div>

        </div>
      </div>


    </div>

    <div class="part5 part-color" >
      <img class="head-img" src="../assets/Unit2-1/20230407-物件-2-1-09-pic4.png" alt="蔡培火" title="蔡培火">

      <div class="people" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-3.json')" alt="蔡培火被逮捕" title="蔡培火被逮捕" ></LottieTest>
      </div>

      <div class="content">
        <p class="title">憤慨的監獄之歌</p>
        <p class="subtitle" style="margin-top: 0.8vw">〈臺灣自治歌〉  蔡培火錄音清唱</p>
        <p class="source" style="margin-top: 0.4vw">聲音：蔡富花  提供｜照片：維基共享資源，公眾領域</p>
        <p class="text" style="margin-top: 0.4vw">蔡培火在臺南獄中寫下〈臺灣自治歌〉，後親自譜曲成歌謠，此為蔡培火晚年親自錄唱此曲的聲音。</p>
        <div class="lyrics">
          <p><span class="number">（一）</span>蓬萊美島真可愛　 祖先基業在</p>
          <p>田園阮開樹阮栽　 勞苦代過代</p>
          <p>著理解　著理解　 阮是開拓者　 毋是憨奴才</p>
          <p>臺灣全島快自治　 公事阮掌是應該</p>
          <p><span class="number">（二）</span>玉山崇高蓋扶桑　 我們意氣揚</p>
          <p>通身熱烈愛鄉血　 豈怕強權旺</p>
          <p>誰阻擋　誰阻擋　 齊起倡自治　 同聲直標榜</p>
          <p>百般義務咱著盡　 自治權力應當享</p>
        </div>

        <audio-comp :audio-src="require('../assets/Unit2-1/song.mp3')" style-class="orange-style" volume-style="left:81%;"></audio-comp>

      </div>

    </div>

    <div class="part6 part-color" ref="part6">
<!--      <img class="absolute" style="top: -1%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample5.png">-->
      <div class="circle-wrap">
        <div class="circle down-semi part-color" >

          <transition name="img-frame-fade" >
            <div class="black-circle" v-show="showMember==1" id="black-circle">
              <div>
                <p>出獄紀念照</p>
              </div>
              <div>
                <p>這張照片是治警事件後，第一次假釋的出獄接風紀念照，細看照片，你找得出來誰是剛出獄的治警事件被告者嗎？</p>
              </div>

              <div class="answer-btn" @click="showMember=2;" @keydown.enter="showMember=2;" tabindex="0" alt="看解答" title="看解答">看解答</div>
            </div>
          </transition>

          <transition name="img-frame-fade" >
            <div class="black-circle" v-show="showMember==2" id="black-circle">
              <div>
                <p>脫帽者是被告者</p>
              </div>
              <div>
                <p>照片為1924年2月18日治警事件第一次假釋，脫帽者為被告，由左至右為鄭松筠、石煥長、蔣渭水、蔡培火，帶帽者為出迎者。</p>
              </div>
            </div>
          </transition>

          <div :class="showMember==2? 'show-answer' : ''">
            <img class="question-img" src="../assets/Unit2-1/20230407-物件-2-1-16-pic4.png" title="治警事件出獄紀念照片" alt="治警事件出獄紀念照片">
            <img class="focus-img" src="../assets/Unit2-1/20230407-物件-2-1-19-pic6.png" alt="由左至右為鄭松筠、石煥長、蔣渭水、蔡培火" title="由左至右為鄭松筠、石煥長、蔣渭水、蔡培火">
            <img class="focus-zoom-img" src="../assets/Unit2-1/20230407-物件-2-1-20-pic7.png" alt="由左至右為鄭松筠、石煥長、蔣渭水、蔡培火" title="由左至右為鄭松筠、石煥長、蔣渭水、蔡培火">
            <img class="zoom-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="top: 65%; left: 85.2%; width: 1.5%" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
          </div>

          <div class="provide">蔣渭水文化基金會 提供</div>
        </div>
      </div>


    </div>

    <div class="part7 part-color">
      <div class="content">
        <img src="../assets/Unit2-1/20230407-物件-2-1-21-裝飾字3-無力者大會.png" alt="無力者大會" title="無力者大會">
        <p tabindex="0">1923年12月第二次大會後，文協總部移至臺南，發展青年組織、演講會，頻繁舉辦演講會，勸募訂購《臺灣民報》。1924年6月，面對總督府及其協力者對自治主張的輿論攻擊，文協召集「無力者大會」作為反制，而受到社會各界廣泛的迴響。1924年7月3日文化協會在臺北、臺中、臺南同步舉行「無力者大會」，反擊由辜顯榮等組織之公益會，稱其為「偽造輿論、蹂躪正義」。 </p>
      </div>
      <div class="people" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-4.json')" alt="無力者大會" title="無力者大會" ></LottieTest>
      </div>
    </div>

    <div class="part8 part-color">
      <div class="content-1">
        <img style="width: 18.7vw" src="../assets/Unit2-1/20230407-物件-2-1-22-pic8.png" title="無力者大會宣傳單" alt="無力者大會宣傳單">
        <div>
          <p>對抗有力者大會</p>
          <P>無力者大會宣傳單</P>
          <p>秋惠文庫 提供</p>
          <p>宣傳單明白指出是為了對付前月舉行之有力者大會，北中南三場活動都準備了辨士與講者可謂是文協的一次大集結。林獻堂也出借林家祠堂作為場地，據報導有超過千人參加。</p>
        </div>
        <img class="zoom-2-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="bottom: 2.5%; right: 60.5%; width: 2.2vw" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>

      <div class="content-2">
        <img style="width: 9.2vw;"  src="../assets/Unit2-1/20230407-物件-2-1-23-pic9.png" title="有力者大會決議剪報" alt="有力者大會決議剪報" >
        <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="bottom: 4.5%; right: 9.5%; width: 2.2vw" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="source">
        <p>有力者大會決議剪報</p>
        <p>《臺灣民報》1924年08月11日</p>
      </div>

      <div class="content-3">
        <p>全島無力者大會宣言書</p>
        <p>對政治法律，毫無理解，對人道正義，敢樹反逆之旗幟，在廿世紀之紳士階級，此種腐敗分子，料應絕滅。不圖最近有辜某，用自私自利之魂膽，敢自稱為「有力者」，倡開大會，而欲反對最合理的最有秩序的運動。辜某既往之歷史，世所周知，固不足深責，然若任其張牙舞爪，竊恐使一般人士，抱疑惑之念。故吾人為欲喚起全島兄弟之注意，不得不為相當之表示。</p>
      </div>

      <img class="left-text-img" src="../assets/Unit2-1/20230407-物件-2-1-25-插圖1.png" alt="無力者大會決議" title="無力者大會決議">
      <img class="right-text-img" src="../assets/Unit2-1/20230407-物件-2-1-26-插圖2.png" alt="有力者大會決議" title="有力者大會決議">

      <div class="left-people" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-6.json')" alt="無力者" title="無力者"></LottieTest>
      </div>
      <div class="right-people" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-5.json')" alt="有力者" title="有力者"></LottieTest>
      </div>

    </div>

    <div class="part9 part-color">
      <div class="unit-btn" style="top: 23%; left: 12%; " @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/unit2-0')" tabindex="0">
        <img class="w-full"  src="../assets/Unit2-0/文協向前衝按鈕.png" alt="文協向前衝" title="文協向前衝">
        <p>文協向前衝</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 38.5%; ">
        <img class="w-full"  src="../assets/Unit2-0/20230721-2-1-物件-30-臺灣人的臺灣按鈕-淡.png" alt="臺灣人的臺灣" title="臺灣人的臺灣" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0">
        <p>臺灣人的</p>
        <p style="top:47%">臺灣</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 65%; ">
        <img class="w-full" src="../assets/Unit2-0/為農工大眾主張按鈕.png" alt="為農工大眾主張" title="為農工大眾主張" @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" tabindex="0">
        <p>為農工大眾</p>
        <p style="top:47%">主張</p>
      </div>
      <div class="unit-btn" style="top: 57%; left: 25%; ">
        <img class="w-full" src="../assets/Unit2-0/左派與右派按鈕.png" alt="左派與右派" title="左派與右派" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0">
        <p>左派與右派</p>
      </div>
      <div class="unit-btn" style="top: 57%; left: 51.5%; ">
        <img class="w-full" src="../assets/Unit2-0/政治結社的走向按鈕.png" alt="政治結社的走向" title="政治結社的走向" @click="routeBtn('/unit2-4')" @keydown.enter="routeBtn('/unit2-4')" tabindex="0">
        <p>政治結社的</p>
        <p style="top:47%">走向</p>
      </div>
    </div>

    <!--img1-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample6.png">-->

        <div class="black-bg"></div>
        <div class="frame">
          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34.1vw; margin:3.7vw 0 0 4vw;"    src="../assets/Unit2-1/20230703-物件-2-1-03-pic1-放大文物.png" alt="1925年臺灣議會請願團請願運動抵達東京車站的情景" title="1925年臺灣議會請願團請願運動抵達東京車站的情景">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 42vw; width: 21vw;">
            <div class="bold-title">1925年臺灣議會請願團請願運動抵達</div>
            <div class="bold-title">東京車站的情景</div>
            <div class="noto-text" style="margin-top: 0.4vw">中央研究院臺灣史研究所檔案館 提供</div>
            <div class="sub-title" style="margin-top: 1vw; line-height: 2vw; text-align: justify">旅日臺灣人在東京車站歡迎請願團，手執大小旗幟，大旗上寫著「歡迎臺灣議會請願團」，小旗上寫著「自由」、「平等」、「正義」等醒目標語，象徵其所嚮往與熱愛的目標。</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample7.png">-->

        <div class="black-bg"></div>
        <div class="frame">

          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:11.6vw; margin:2.3vw 0 0 26.5vw" src="../assets/Unit2-1/20230703-物件-2-1-05-pic2-放大文物.png" alt="林獻堂邀杜香國參加議會請願委員洗塵宴信" title="林獻堂邀杜香國參加議會請願委員洗塵宴信">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 21.5vw; width: 21.6vw;">
            <div class="bold-title">林獻堂邀杜香國參加議會請願委員洗塵宴信</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2020.032.0143.0001</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample8.png">-->

        <div class="black-bg"></div>
        <div class="frame">

          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:42.6vw; margin:8.5vw 0 0 10.8vw" src="../assets/Unit2-1/20230703-物件-2-1-06-pic3-放大文物.png" alt="林獻堂邀杜香國參加議會請願委員洗塵宴信" title="林獻堂邀杜香國參加議會請願委員洗塵宴信">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 21.5vw; width: 21.6vw;">
            <div class="bold-title">林獻堂邀杜香國參加議會請願委員洗塵宴信</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2020.032.0143.0002</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample9.png">-->

        <div class="black-bg"></div>
        <div class="frame">

          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:34.1vw; margin:3.7vw 0 0 4vw" src="../assets/Unit2-1/20230703-物件-2-1-17-pic4-放大文物.png" alt="治警事件出獄紀念照片" title="治警事件出獄紀念照片">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 42vw; width: 21vw;">
            <div class="bold-title">治警事件出獄紀念照片</div>
            <div class="noto-text" >蔣渭水文化基金會 提供</div>
            <div class="sub-title" style="margin-top: 0.5vw; line-height: 2vw; text-align: justify">蔣渭水因1923年治警事件兩次入獄，照片為1924年2月18日蔣渭水遭逮捕留64天後第一次假釋，立者中四位脫帽者為被告,由左至右為鄭松筠、石煥長、蔣渭水、蔡培火，帶帽者為出迎者。</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample10.png">-->

        <div class="black-bg"></div>
        <div class="frame">

          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:29.5vw; margin:1.3vw 0 0 17.4vw" src="../assets/Unit2-1/20230703-物件-2-1-22-pic8-放大文物.png" alt="無力者大會宣傳單" title="無力者大會宣傳單">
          </div>

          <div class="content-text" style="position: absolute; top:33vw; left: 26vw; width: 10.25vw;">
            <div class="bold-title">無力者大會宣傳單</div>
            <div class="noto-text" style="margin-top: 0.4vw">秋惠文庫 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
<!--          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample11.png">-->

        <div class="black-bg"></div>
        <div class="frame">

          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:6.6vw; margin:2.5vw 0 0 29.2vw" src="../assets/Unit2-1/20230703-物件-2-1-23-pic9-放大文物.png" alt="有力者大會決議剪報" title="有力者大會決議剪報">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 26.5vw; width: 12vw;">
            <div class="bold-title">有力者大會決議剪報</div>
            <div class="noto-text" style="margin-top: 0.4vw">臺灣民報 1924年08月11日</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>


    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import {onMounted, onUnmounted, reactive, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import AudioComp from "@/components/AudioComp";


export default {
  name: "Unit2-1",
  mixins: [myMixin],
  components:{
    AudioComp,
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();

    const pic = ref(1);
    const part4 = ref();
    const part6 = ref();


    const showMember = ref(1);
    const showImg = reactive(Array.from({ length: 20 }, () => false));

    function routeBtn(path){
      router.push(path);
    }

    function nextBtn(){
      pic.value  += 1;
      if(pic.value >= 3){
        pic.value = 3;
      }
    }

    function prevBtn(){
      pic.value -= 1;
      if(pic.value <= 1){
        pic.value = 1;
      }
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }


    onMounted(()=>{
      window.addEventListener('scroll', scrollEvent);
    });

    onUnmounted(()=>{
      window.removeEventListener('scroll', scrollEvent)
    });

    function scrollEvent(){
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollTriggerTop = part4.value.offsetTop - window.innerHeight;
      const scrollPercentage = Math.max(0, Math.min(1, (scrollTop - scrollTriggerTop) / window.innerHeight));
      const upSemi = document.querySelector('.up-semi');
      upSemi.style.width = (117*scrollPercentage)+"vw";
      upSemi.style.height = (105*scrollPercentage)+"vw";


      const scrollTop2 = window.pageYOffset || document.documentElement.scrollTop;
      const scrollTriggerTop2 = part6.value.offsetTop - window.innerHeight;
      const scrollTriggerMiddle2 = scrollTriggerTop2 + (part6.value.offsetHeight * 0.9);
      const scrollPercentage2 = Math.max(0, Math.min(1, (scrollTop2 - scrollTriggerMiddle2) / window.innerHeight));

      const downSemi = document.querySelector('.down-semi');
      //document.getElementById('black-circle').style.fontSize = (1-(1*scrollPercentage2*1.5))+"vw";
      //console.log((1-(1*scrollPercentage2)))
      downSemi.style.width = (117-(117*scrollPercentage2))+"vw";
      downSemi.style.height = (105-(105*scrollPercentage2))+"vw";
    }


    return{
      routeBtn,
      pic,
      part4,
      part6,
      nextBtn,
      prevBtn,
      showMember,
      showImg,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>



<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit2-1.css"></style>




