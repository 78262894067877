<template>
  <div class="wrap" @keydown.esc="escEvent">
    <Entoolbar type="2" unit="3" route-name="unit4-2" color="brown"></Entoolbar>

    <!--    <img class="sample" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit4-2/4-2-1.json')" alt="Cultural Bases" title="Cultural Bases" ></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit4-2/20230703-物件-4-2-01-首圖１.png" alt="Cultural Bases" title="Cultural Bases">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-2/4-2-0.json')" alt="Cultural Bases" title="Cultural Bases" ></LottieTest>
      </div>
      <div class="title-logo">Cultural Bases</div>
      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.1vw; bottom: 26%; right: 51.2%;" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit4-2/en/20230708-EN網站-4-2-物件-04-裝飾字1-讀報社.png" alt="Newspaper Reading Clubs" title="Newspaper Reading Clubs">
      <div class="content content-style">
        <p>After its founding, the Taiwan Cultural Association continued to expand Chiang Wei-shui’s concept of “cultural enterprises.” They set up newspaper-reading clubs in various places to provide news and magazines. Within two months of its establishment, the association had set up such clubs in Beidou, Caotun, Changhua, Shetou, Yuanli and Yuanlin in central Taiwan, and Chiayi and Kaohsiung in south Taiwan. The network of clubs grew in response to local requests. </p>
        <p style="margin-top: 1.6vw">In the early days, the clubs provided newspapers and magazines from Taiwan and Japan, together with some publications from China. Articles about colonial liberation movements were highlighted with red ink to draw readers’ attention. Newspaper reading clubs became gathering places for people who shared the ideals of the Taiwan Cultural Association. Many of them also doubled as lecture venues and offices of the <i>Taiwan Min Pao</i> newspaper.</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-2/4-2-2.json')" alt="Newspaper Reading Clubs" title="Newspaper Reading Clubs" ></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit4-2/en/20230708-EN網站-4-2-物件-05-插畫１.png" alt="Taiwan map" title="Taiwan map">
    </div>

    <div class="part3 part-color">
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-2/20230415-物件-4-2-06-pic2.png" alt="Inauguration of the Beigang Newspaper Reading Club - May 16, 1927" title="Inauguration of the Beigang Newspaper Reading Club - May 16, 1927">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 6%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #267c68">Let's Read the Newspaper</p>
        <p class="subtitle-style" style="margin-top: 0.5vw; white-space: nowrap">Inauguration of the Beigang Newspaper Reading Club - May 16, 1927</p>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of Chiang Wei-shui's Cultural Foundation</p>
        <p class="content-style" style="margin-top: 0.5vw">Lin Li-ming and Cai Shao-ting founded the Beigang Newspaper Reading Club and Book Club. They invited Zheng Song-yun and Chiang Wei-shui (front row, 5th and 6th from the left) to attend the opening ceremony.</p>
      </div>

      <div class="content-2">
        <p class="title-style" style="color: #267c68">All You Can Read</p>
        <p class="subtitle-style" style="margin-top: 0.5vw; white-space: nowrap">Dajia Newspaper Reading Club Regulations - October 5, 1924</p>
        <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan History 2020.032.0145</p>
        <p class="content-style" style="margin-top: 0.5vw">The club had a president, two executive secretaries, and two permanent staff members. Ordinary members paid a monthly fee of 50 cents, which allowed them unlimited access to newspapers and magazines available at the club.</p>
      </div>

      <div class="book-wrap">
<!--        <img class="w-full relative" :style="book1Style" :src="book1">-->
<!--        <img class="w-full absolute top-0" :style="book2Style"  :src="book2">-->
<!--        <img class="w-full absolute top-0" :style="book3Style"  :src="book3">-->
        <div class="turnClass">
          <div id="flipbook" alt="Dajia Newspaper Reading Club Regulations - October 5, 1924" title="Dajia Newspaper Reading Club Regulations - October 5, 1924">
            <img class="page page-1 " :src="require('../assets/Unit4-2/20230703-物件-4-2-op-08-pic3-放大文物.png')">
            <img class="page page-2 " :src="require('../assets/Unit4-2/20230703-物件-4-2-op-09-pic4-放大文物.png')">
            <img class="page page-3 " :src="require('../assets/Unit4-2/20230703-物件-4-2-op-10-pic5-放大文物.png')">
          </div>
        </div>

        <transition name="img-frame-fade"><img v-if="bookPage>1" @click="bookLeftBtn" @keydown.enter="bookLeftBtn" tabindex="0" class="left-arrow"  src="../assets/common/left-arrow.png"></transition>
        <img class="zoom-2-btn zoom-fade " @click="showImg[bookPage+1]=true" @keydown.enter="showImg[bookPage+1]=true" tabindex="0" style="width: 2.2vw; bottom: 5%; right: 3%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        <transition name="img-frame-fade"><img v-if="bookPage<3" @click="bookRightBtn" @keydown.enter="bookRightBtn" tabindex="0" class="right-arrow"  src="../assets/common/right-arrow.png"></transition>
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-2/4-2-3.json')"  alt="Let's Read the Newspaper" title="Let's Read the Newspaper"></LottieTest>
      </div>
    </div>

    <div class="part4 part-color">
      <img class="logo-text" src="../assets/Unit4-2/en/20230708-EN網站-4-2-物件-13-裝飾字2-書局.png" alt="Bookstore" title="Bookstore">
      <div class="content-1 content-style">
        After 1926, cultural institutions became more popular. Leading members of the Taiwan Culture Association in Taichung raised funds to establish the Central Bookstore, and Chiang Wei-shui also established the Cultural Bookstore in Taipei. Xingwenzhai Bookstore was established in Tainan by Lin Zhan-ao, who had long supported proletarian youth. These cultural institutions enjoyed long, profitable existences, and became cultural bases that influenced modern cultural history.
      </div>

      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-2/20230415-物件-4-2-14-pic6.png" alt="Institution for Promoting New Culture An advertisement for the Cultural Bookstore published in the Taiwan Min Pao dated July 11, 1926" title="Institution for Promoting New Culture An advertisement for the Cultural Bookstore published in the Taiwan Min Pao dated July 11, 1926">
        <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.2vw; bottom: 10%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #ffffff">Institution for</p>
        <p class="title-style" style="color: #ffffff; white-space: nowrap">Promoting New Culture</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">An advertisement for the Cultural Bookstore published in the <span style="font-style: italic">Taiwan Min Pao</span> dated  July 11, 1926</p>
        <p class="source-style" style="margin-top: 0.3vw"><i>Taiwan Min Pao</i></p>
      </div>
      <div class="img-2">
        <img class="w-full" style="margin-left: 3vw" src="../assets/Unit4-2/20230415-物件-4-2-17-pic7.png" alt="An advertisement for the International Bookstore published in Taiwan Popular Times" title="An advertisement for the International Bookstore published in Taiwan Popular Times">
        <!--        <img class="zoom-2-btn" style="width: 2.2vw; bottom: 4%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="content-3" style="margin-left: 2vw">
        <p class="title-style" style="color: #ffffff">Taiwan's Sole</p>
        <p class="title-style" style="color: #ffffff; white-space: nowrap">Activist Bookseller</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">An advertisement for the International Bookstore published in <span style="font-style: italic">Taiwan Popular Times</span></p>
        <p class="source-style" style="margin-top: 0.3vw;"><i>Taiwan Popular Times</i></p>
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-2/4-2-4.json')" ></LottieTest>
      </div>

      <div class="back-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-white.png" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
        <p style="margin-left: 0.5vw">Back</p>
      </div>
    </div>

    <!--寫這裡-->
    <!--img0--><!--hor-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-05.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:21vw; margin:4.3vw 0 0 7vw" src="../assets/Unit4-2/20230703-物件-4-2-op-03-pic1.png" alt="Commemorative Photo of the Founding of Chiayi Newspaper Reading Club" title="Commemorative Photo of the Founding of Chiayi Newspaper Reading Club">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 50vw; width: auto;">
            <div class="bold-title">Commemorative Photo of the Founding of Chiayi Newspaper Reading Club</div>
            <div class="noto-text" >Collection of the National Museum of Taiwan Literature Donated by Wu Ke</div>
            <div class="sub-title" style="text-align: left">The Chiayi Newspaper Reading Club also served as the wholesale distributor for <i>Taiwan Min Pao</i>. In the photograph, Wang Kan-tang, a director of the Taiwan Cultural Association in 1925, can be seen seated on the left.</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--  <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-04.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34.2vw; margin:3.2vw 0 0 14.9vw" src="../assets/Unit4-2/20230703-物件-4-2-op-06-pic2-放大文物.png" alt="Inauguration of the Beigang Newspaper Reading Club - May 16, 1926" title="Inauguration of the Beigang Newspaper Reading Club - May 16, 1926">
          </div>

          <div class="content-text" style="position: absolute; width: auto;">
            <div >
              <div class="bold-title">Inauguration of the Beigang Newspaper Reading Club - May 16, 1926</div>
              <div class="noto-text" >Courtesy of Chiang Wei-shui's Cultural Foundation</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-06.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.9vw; margin:2.3vw 0 0 14.2vw" src="../assets/Unit4-2/20230703-物件-4-2-op-08-pic3-放大文物.png" alt="Dajia Newspaper Reading Club Regulations - October 5, 1924" title="Dajia Newspaper Reading Club Regulations - October 5, 1924">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Dajia Newspaper Reading Club Regulations - October 5, 1924</div>
              <div class="noto-text" >National Museum of Taiwan History 2020.032.0145</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-07.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.2vw; margin:2.3vw 0 0 14.2vw" src="../assets/Unit4-2/20230703-物件-4-2-op-09-pic4-放大文物.png" alt="Dajia Newspaper Reading Club Regulations - October 5, 1924" title="Dajia Newspaper Reading Club Regulations - October 5, 1924">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Dajia Newspaper Reading Club Regulations - October 5, 1924</div>
              <div class="noto-text" >National Museum of Taiwan History 2020.032.0145</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-08.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.2vw; margin:2.4vw 0 0 13.7vw" src="../assets/Unit4-2/20230703-物件-4-2-op-10-pic5-放大文物.png" alt="Dajia Newspaper Reading Club Regulations - October 5, 1924" title="Dajia Newspaper Reading Club Regulations - October 5, 1924">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Dajia Newspaper Reading Club Regulations - October 5, 1924</div>
              <div class="noto-text" >National Museum of Taiwan History 2020.032.0145</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--                <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-09.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:52.6vw; margin:6.5vw 0 0 6.3vw" src="../assets/Unit4-2/20230703-物件-4-2-op-14-pic6-放大文物.png" alt="Institution for Promoting New Culture An advertisement for the Cultural Bookstore published in the Taiwan Min Pao dated  July 11, 1926" title="Institution for Promoting New Culture An advertisement for the Cultural Bookstore published in the Taiwan Min Pao dated  July 11, 1926">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Institution for Promoting New Culture An advertisement for the Cultural Bookstore published in the <i>Taiwan Min Pao</i> dated  July 11, 1926</div>
              <div class="noto-text" ><i>Taiwan Min Pao</i></div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>

    <FooterFeature language='en'  color="white"></FooterFeature>
    <EnIntroBtn></EnIntroBtn>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn";
import LottieTest from "@/components/LottieTest";
import {onMounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import $ from "jquery";
import turn from "@/assets/utils/lib/turn";
import flipAudio from "@/assets/Unit3-1/insert_paper.mp3";

export default {
  name: "Unit4-1",
  mixins: [myMixin],
  components:{
    Entoolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));



    const book1 = ref(require('../assets/Unit4-2/08-pic3-翻頁動態/08-pic3-翻頁動態-00.png'));
    const book2 = ref(require('../assets/Unit4-2/09-pic4-翻頁動態/09-pic4-翻頁動態-00.png'));
    const book3 = ref(require('../assets/Unit4-2/20230415-物件-4-2-10-pic5.png'));
    const book1Style = ref('z-index:3;');
    const book2Style = ref('z-index:2; opacity:0;');
    const book3Style = ref('z-index:1; opacity:0;');
    const flipAudio = require('@/assets/Unit3-1/insert_paper.mp3');
    const flipEle = new Audio(flipAudio);

    function routeBtn(path){
      router.push(path);
    }

    function onTurn(){
      $("#flipbook").turn({
        acceleration:true,
        display: "single",
        //gradients: true,
        autoCenter: true,
        height:646,
        width:896,
        direction: "rtl",
      });


      $('#flipbook').turn('size', '100%', '100%');
      $("#flipbook").bind("turning", function(event, page, view) {
        bookPage.value = page;
        flipEle.play();
      });
      $("#flipbook").bind("start", function(event, pageObject, corner) {
        $('#flipbook').turn('size', 'auto', '100%');
      });

    }

    onMounted(()=>{
      onTurn();
      window.addEventListener('resize', ()=>{
        $('#flipbook').turn('size', 'auto', '100%');
      });
    });

    let bookId = null;
    let bookIndex = 0;
    const bookPage = ref(1);
    let booking = false;
    function bookRightBtn(){
      $("#flipbook").turn("next");
      return;
      if(booking || bookPage.value == 2) return;

      booking = true;
      bookIndex = 0;

      if(bookPage.value == 0){
        clearInterval(bookId);

        book2Style.value = 'z-index:2;';
        book3Style.value = 'z-index:1;';

        bookId = setInterval(()=>{

          bookIndex = (bookIndex + 1) % (47+1);

          book1.value = require('../assets/Unit4-2/08-pic3-翻頁動態/08-pic3-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex >=  47){
            book1Style.value = 'opacity:0;';
            clearInterval(bookId);
            bookPage.value=1;
            booking = false;
          }

        }, 50);
      }else if(bookPage.value==1){

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = (bookIndex + 1) % (47+1);
          book2.value = require('../assets/Unit4-2/09-pic4-翻頁動態/09-pic4-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex >=  47){
            book2Style.value = 'opacity:0;';
            clearInterval(bookId);
            bookPage.value=2;
            booking = false;
          }

        }, 50);
      }


    }

    function bookLeftBtn(){
      $("#flipbook").turn("previous");
      return;
      if(booking || bookPage.value == 0) return;
      booking = true;

      if(bookPage.value == 1){
        bookIndex = 47;

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = bookIndex-1;

          book1Style.value = "z-index:3";
          book1.value = require('../assets/Unit4-2/08-pic3-翻頁動態/08-pic3-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex <= 0){
            clearInterval(bookId);
            bookPage.value=0;
            booking = false;
          }

        }, 50);
      }else if(bookPage.value==2){
        bookIndex = 47;

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = bookIndex-1;

          book2Style.value = "z-index:2";
          book2.value = require('../assets/Unit4-2/09-pic4-翻頁動態/09-pic4-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex <= 0){
            clearInterval(bookId);
            bookPage.value=1;
            booking = false;
          }

        }, 50);
      }

    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      book1,
      book2,
      book3,
      book1Style,
      book2Style,
      book3Style,
      bookPage,
      bookRightBtn,
      bookLeftBtn,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit4-2.css"></style>
