<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="1" unit="2" route-name="unit2-2" color="orange"></toolbar>

<!--    <img class="sample" src="../assets/Unit2-2/sample2.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">

      <img class="pic-1" src="../assets/Unit2-2/20230407-2-2-物件-op-01-首圖１.png">

      <div class="people-2" >
        <LottieTest :json="require('@/assets/Unit2-2/2-2-1.json')" alt="為農工大眾主張" title="為農工大眾主張" ></LottieTest>
      </div>

      <img class="pic-2" src="../assets/Unit2-2/20230407-2-2-物件-op-02-首圖2.png">

      <div class="people-1" >
        <LottieTest :json="require('@/assets/Unit2-2/2-2-0.json')" alt="為農工大眾主張" title="為農工大眾主張" ></LottieTest>
      </div>
      <img class="pic-3" src="../assets/Unit2-2/20230407-2-2-物件-op-03-首圖3.png">
      <div class="title-logo">為農工大眾主張</div>

    </div>

    <div class="part2 part-color">
      <div class="content-1">
        <img style="width: 16.4%" src="../assets/Unit2-2/20230407-2-2-物件-op-04-裝飾字1-蔗農.png" alt="蔗農" title="蔗農">
        <p>1925年10月，發生在彰化二林、蔗農與警察間的衝突事件，除了現場蔗農之外，文協成員李應章、詹奕侯、劉崧甫等在地農民組織者也被捕起訴，土地爭議、蔗農生計待遇問題，乃至於總體農民、工人、無產階級遭資本家壓迫的社會議題，猛然浮上檯面，成為大眾關心的焦點。</p>
        <a href="https://youtu.be/yyaFposN27Q" target="_blank" tabindex="0" title="臺灣吧二林事件介紹影片(另開新視窗)" alt="臺灣吧二林事件介紹影片(另開新視窗)">
          <img class="video-btn" src="../assets/Unit2-2/20230407-2-2-物件-op-06-臺灣吧按鈕-藍.png">
        </a>
      </div>

      <div class="content-2">
        <img style="height: 17.7vw" src="../assets/Unit2-2/20230407-2-2-物件-op-07-pic1.png" title="南下製糖的板橋林家" alt="南下製糖的板橋林家">
        <div style="margin-left: 2.5vw">
          <p class="title-style">南下製糖的板橋林家</p>
          <p class="subtitle-style">林本源製糖株式會社股票</p>
          <p class="source-style" style="margin-top: 0.5vw">蔣渭水文化基金會 提供</p>
          <p class="content-style" style="margin-top: 0.5vw">因總督府強力推動糖業政策，臺灣知名巨商板橋林本源家族，在北斗郡溪州庄（今溪州鄉）投資創設「林本源製糖株式會社」。該公司在二林的甘蔗原料收購價格較鄰近區域低，以致農民拒絕採收，而與警方發生衝突。</p>
        </div>
        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 6%; right: 63.5%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <img class="absolute" style="width: 20vw; top: 10.5%; right: 0%;" src="../assets/Unit2-2/20230407-2-2-物件-op-05-插圖1.png">
      <div class="people-1" >
        <LottieTest :json="require('@/assets/Unit2-2/2-2-2.json')" alt="蔗農與警察間的衝突事件" title="蔗農與警察間的衝突事件"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <div class="content-1">
        <img style="height: 24.6vw" src="../assets/Unit2-2/20230407-2-2-物件-op-09-pic2.png" title="《臺灣民報》第一百二十二號" alt="《臺灣民報》第一百二十二號">
        <div style="margin-left: 1.5vw">
          <p class="title-style">審判結果出來了</p>
          <p class="subtitle-style">《臺灣民報》第一百二十二號</p>
          <p class="source-style" style="margin-top: 0.5vw">國立臺灣歷史博物館 館藏 2019.011.0002</p>
          <p class="content-style" style="margin-top: 0.5vw">二林事件被捕者多人被判刑入獄，1926年9月20日臺灣民報發行關於二林事件一審結果與過程的專號。此次事件影響關心社會運動的青年投入文協、農民組合，推動了文協的改組與左傾。</p>
        </div>
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 6%; right: 72%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>

      <div class="content-2">
        <img style="height: 23.8vw" src="../assets/Unit2-2/20230407-2-2-物件-op-10-pic3.png" title="二林事件第二次公判合照" alt="二林事件第二次公判合照">
        <transition name="img-frame-fade"><img v-if="showName" class="name-line h-full" src="../assets/Unit2-2/name-line.png" alt="農民姓名" title="農民姓名"></transition>
        <div style="margin-left: 2.5vw">
          <p class="title-style">農民是運動的主角！</p>
          <p class="subtitle-style relative">二林事件第二次公判合照<img class="detail-btn" @click="showName=!showName" @keydown.enter="showName=!showName" tabindex="0" src="../assets/Unit2-2/20230407-2-2-物件-op-23-細讀照片按鈕-藍.png" title="細讀照片" alt="細讀照片"></p>
          <p class="source-style" style="margin-top: 0.5vw">大石進 提供</p>
          <p class="content-style" style="margin-top: 0.5vw">這張「二林蔗農事件」代表性的二審公判合照，以往多只能辨識知識菁英，經近年熱絡的田野工作，可以指出更多第一線抗爭的農民。本張照片的特殊之處，在於律師、醫生等社會名流皆排列在後方，在第一線參與事件中而受審的農民、運動者則站於前排。這樣的安排與之前文化協會相關研究及照片，多聚焦於社會菁英大不相同。</p>
        </div>
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 4%; right: 50.5%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
    </div>

    <div class="part4 part-color">
      <div class="content-1">
        <img style="width: 30.5vw" src="../assets/Unit2-2/20230407-2-2-物件-op-25-裝飾字2-無產青年.png" alt="無產青年" title="無產青年">
        <p>1923年起，文協成員當中就有左派思想的討論，早期以研究會、讀書會的方式運作，各界發展的青年組織也關注階級議題。總督府對相關活動的壓迫格外嚴厲，動輒強力解散、關押主事者。1926年6月，青年團體在《臺灣民報》提出「無產青年公開狀」，抨擊文協中央對左翼運動的態度消極，左翼與右翼對立的情況也正式浮上檯面。</p>
      </div>

      <div class="content-2">
        <img style="width: 30.5vw" src="../assets/Unit2-2/20230407-2-2-物件-op-26-pic4.png" title="〈無產青年公開狀〉《臺灣民報》第一一六號" alt="〈無產青年公開狀〉《臺灣民報》第一一六號">
        <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom:9%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>

      <div class="content-3">
        <img style="width:22.2vw; margin: 4.5vw 0 0 39%" src="../assets/Unit2-2/20230407-2-2-物件-op-28-pic6.png">
        <img class="zoom-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 1.9vw; bottom: 20%; right: -28%" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
        <div class="people absolute top-0" style="width: 13.5vw">
          <LottieTest :json="require('@/assets/Unit2-2/2-2-4.json')" alt="青年" title="青年" ></LottieTest>
        </div>
      </div>

      <div class="content-4">
        <p class="title-style" style="color: #ea5518">無產青年是誰？</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">〈無產青年公開狀〉《臺灣民報》第一一六號</p>
        <p class="source-style" style="margin-top: 0.3vw">出自《臺灣民報》第一一六號</p>
        <p class="content-style" style="margin-top: 0.5vw;">內文提到無產青年不是一個會，也與文化協會沒有關係，是一種主張與自我認同。內文強調無產青年們的主張與文協不同，不可誤認，可以視為是針對文協組織的一種諷刺。</p>
      </div>

      <div class="content-5">
        <img class="w-full" src="../assets/Unit2-2/20230407-2-2-物件-op-27-pic5.png" title="臺灣工友總聯盟代表歡迎大會" alt="臺灣工友總聯盟代表歡迎大會"/>
        <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.2vw; bottom: 8%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>

      <div class="content-6">
        <p class="title-style" style="color: #ea5518">同胞須團結，團結真有力</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺灣工友總聯盟代表歡迎大會</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣文學館典藏  林章峯捐贈</p>
        <p class="content-style" style="margin-top: 0.5vw;">1928年2月19日，臺灣史上第一個全島性工會組織——臺灣工友總聯盟創立。《臺灣新民報》稱盧丙丁（第3排中戴墨鏡者）為工運的「頭兄」，蔣渭水則是「產婆」，足見其對勞工運動的貢獻。蔣渭水的名言「同胞須團結，團結真有力」也被製作為會場看板。</p>
      </div>

    </div>

    <div class="part5 part-color">
      <div class="unit-btn" style="top: 23%; left: 12%; " @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/unit2-0')" tabindex="0">
        <img class="w-full"  src="../assets/Unit2-0/文協向前衝按鈕.png" alt="文協向前衝" title="文協向前衝">
        <p>文協向前衝</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 38.5%; ">
        <img class="w-full"  src="../assets/Unit2-0/臺灣人的臺灣按鈕.png" alt="臺灣人的臺灣" title="臺灣人的臺灣" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0">
        <p>臺灣人的</p>
        <p style="top:47%">臺灣</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 65%; ">
        <img class="w-full"  src="../assets/Unit2-0/20230724-2-2-物件-32-為農工大眾主張按鈕-淡.png" alt="為農工大眾主張" title="為農工大眾主張" @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" tabindex="0">
        <p>為農工大眾</p>
        <p style="top:47%">主張</p>
      </div>
      <div class="unit-btn" style="top: 57%; left: 25%; ">
        <img class="w-full" src="../assets/Unit2-0/左派與右派按鈕.png" alt="左派與右派" title="左派與右派" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0">
        <p>左派與右派</p>
      </div>
      <div class="unit-btn" style="top: 57%; left: 51.5%; ">
        <img class="w-full" src="../assets/Unit2-0/政治結社的走向按鈕.png" alt="政治結社的走向" title="政治結社的走向" @click="routeBtn('/unit2-4')" @keydown.enter="routeBtn('/unit2-4')" tabindex="0">
        <p>政治結社的</p>
        <p style="top:47%">走向</p>
      </div>
    </div>

    <!--img0-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-032.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:38vw; margin:2.5vw 0 0 12.8vw" src="../assets/Unit2-2/20230703-2-2-物件-07-pic1-放大文物.png" alt="林本源製糖株式會社股票" title="林本源製糖株式會社股票">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 26.2vw; width: 13.4vw;">
            <div class="bold-title">林本源製糖株式會社股票</div>
            <div class="noto-text" style="margin-top: 0.4vw">蔣渭水文化基金會 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-042.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:20.5vw; margin:1vw 0 0 21.7vw" src="../assets/Unit2-2/20230703-2-2-物件-09-pic2-放大文物.png" alt="《臺灣民報》第一百二十二號" title="《臺灣民報》第一百二十二號">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 23vw; width: 17.4vw;">
            <div class="bold-title">《臺灣民報》第一百二十二號</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2019.011.0002</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-052.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:33.5vw; margin:2.5vw 0 0 15vw" src="../assets/Unit2-2/20230703-2-2-物件-10-pic3-放大文物.png" alt="二林事件第二次公判合照" title="二林事件第二次公判合照">
            <transition name="img-frame-fade"><img v-if="showName" class="name-line" style="position:absolute; top: 6%; left: 23%; width: 34vw;" src="../assets/Unit2-2/name-line.png"></transition>
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 25vw; width: 13.4vw;">
            <div class="bold-title">二林事件第二次公判合照</div>
            <div class="noto-text" style="margin-top: 0.4vw">大石進 提供</div>
          </div>

          <img class="detail-btn" style="position: absolute; top:85%; left: 69%;" @click="showName=!showName" src="../assets/Unit2-2/20230407-2-2-物件-op-23-細讀照片按鈕-藍.png" title="細讀照片" alt="細讀照片">

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-072.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:33.5vw; margin:2.5vw 0 0 15vw" src="../assets/Unit2-2/20230703-2-2-物件-26-pic4-放大文物.png" alt="〈無產青年公開狀〉《臺灣民報》第一一六號" title="〈無產青年公開狀〉《臺灣民報》第一一六號">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 20.5vw; width: 22.6vw;">
            <div class="bold-title">〈無產青年公開狀〉《臺灣民報》第一一六號</div>
            <div class="noto-text" style="margin-top: 0.4vw">出自《臺灣民報》第一一六號</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-082.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:33.5vw; margin:4.5vw 0 0 3.6vw" src="../assets/Unit2-2/20230703-2-2-物件-28-pic6-放大文物.png" alt="臺南木材工友會等參與國際勞動節活動合照" title="臺南木材工友會等參與國際勞動節活動合照">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 38.7vw; width: 22vw;">
            <div class="bold-title">臺南木材工友會等參與國際勞動節活動合照</div>
            <div class="noto-text" style="margin-top: 0vw">國立臺灣文學館典藏 林章峯捐贈</div>
            <div class="sub-title" style="margin-top: 0.9vw; line-height: 2vw; text-align: justify">五一勞動節為勞工運動的重要節日，文協、工友聯盟勢必要舉辦大型活動，象徵勞工的團結精神。</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-06.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:2.5vw 0 0 15vw" src="../assets/Unit2-2/20230703-2-2-物件-27-pic5-放大文物.png" alt="臺灣工友總聯盟代表歡迎大會" title="臺灣工友總聯盟代表歡迎大會">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 24.5vw; width: 15.4vw;">
            <div class="bold-title">臺灣工友總聯盟代表歡迎大會</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣文學館典藏 林章峯捐贈</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>

    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import {reactive, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";

export default {
  name: "Unit2-2",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();

    const audio = ref();
    const showImg = reactive(Array.from({ length: 10 }, () => false));
    const showName = ref(false);

    function routeBtn(path){
      router.push(path);
    }

    function playVoice(){
      if(!audio.value.paused){
        audio.value.pause();
      }else{
        audio.value.play();
      }
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      playVoice,
      audio,
      showImg,
      showName,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent,
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit2-2.css"></style>
