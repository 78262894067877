<template>
  <div class="wrap" >
    <toolbar type="2" unit="5" route-name="AboutUs" color="brown"></toolbar>
<!--    <img class="sample" src="../assets/common/sample/20231101-網站-共用按鈕-or-0-關於我們.jpg">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="content-text">
      <div class="title">關於我們</div>
      <div class="content" style="margin-top: 1vw">一個人的世界觀的改變，會改變一個人的行為；一群人的世界觀的改變，能改變一個國家的命運。</div>
      <div class="content" style="margin-top: 0vw">
        <p>臺灣文化協會，是1921年至31年間眾多社會運動組織中的一個，會員最多時也不過一千多位，放在近代臺灣史當中，不是最早、人數也不算多。不過，這群人懷抱現代世界的新知，更有著實踐的勇氣與熱情。他們企圖改變當時近四百萬臺灣人的世界觀，推動改變整體臺灣人的命運，甚至藉此要翻轉世界變局。</p>
      </div>

      <div class="content" style="margin-top: 0.3vw">
        <p>創立時蔣渭水擬〈臺灣文化協會會歌〉這麼唱：</p>
        <div class="subtitle">「但願最後完使命 樂為世界人 世界人類萬萬歲 臺灣名譽馨」。</div>
      </div>

      <div class="content" style="margin-top: 0vw">
        <p>他們的使命是什麼？實際做了什麼、達成了什麼？懷抱什麼樣的精神？又在熱忱參與的過程裡，發生了什麼樣的改變？百年後的我們，是不是真如其所願—「樂為世界人」呢？</p>
      </div>

      <div class="content" style="margin-top: 0vw">
        <p>回顧那個年代，那些人們自覺與逐夢的歷程，新的世紀之夢，也將在我們的面前展開。</p>
      </div>

      <div class="content" style="margin-top: 0.3vw">
        <p>「樂為世界人」線上展覽網站，是以2021年國立臺灣歷史博物館舉辦的《樂為世界人—臺灣文化協會百年特展》為基礎所建置的線上展覽，由國立臺灣歷史博物館建置及管理。</p>
      </div>

      <div class="content" style="margin-top: 2.2vw">
        <div class="subtitle" ><p>聯絡資訊</p></div>
        <p><b>電話 | </b>06-356-8889</p>
        <p><b>服務信箱 | </b>exhservice@nmth.gov.tw</p>
        <p><b>地 址 | </b>709025臺南市安南區長和路一段250號</p>
        <p><b>網 站 | </b>www.nmth.gov.tw</p>
      </div>



    </div>

    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import Toolbar from "@/components/Toolbar";
import FooterFeature from "@/components/FooterFeature";

export default {
  name: "PrivacyView",
  components:{
    Toolbar,
    FooterFeature
  },
  setup(){


    return{

    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Aboutus.css"></style>
