<template>
  <div class="wrap" @keydown.esc="escEvent">
    <EnToolbar type="2" unit="3" route-name="unit3-1" color="brown"></EnToolbar>

<!--    <img class="sample" src="../assets/Unit3-1/en/sample/20230708-EN網站-對位-3-1-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="pic-1" src="../assets/Unit3-1/20230411-3-1-物件-01-首圖1.png" alt="Cultural Lectures" title="Cultural Lectures">

      <div class="title-logo">Cultural Lectures</div>

      <div class="wave">
        <LottieTest :json="require('@/assets/Unit3-1/3-1-0.json')" alt="Cultural Lectures" title="Cultural Lectures"></LottieTest>
      </div>
    </div>

    <div class="part2 part-color">
        <div class="content">
          <img style="width: 45vw" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件_02-裝飾字1-造勢活動.png" alt="Political Outreach" title="Political Outreach">
          <p class="content-style" style="margin-top: 2.8vw">In March 1923, Huang Chien-tsong and Wang Min-chuan organized frequent and highly popular lecture tours. At that time, the literacy rate in Taiwan was not high, so lectures which conveyed direct messages appealed to many people. They were also powerful propaganda for local politics.</p>
          <p class="content-style" style="margin-top: 1.5vw">The association’s representatives in Taipei, Taichung, and Tainan sent speakers on lecture tours. After the date had been confirmed, the speakers paid transportation expenses by themselves, and local people provided accommodation. Flags, carts, and firecrackers often heralded a speech, showing the importance attached to such occasions by local people.</p>
          <p class="content-style" style="margin-top: 1.5vw">The colonial authorities usually ordered the police to conducted surveillance on these approved events. If the public were over-excited, they would ask to stop the event, or even disperse the crowd immediately if the situation was serious. They sometimes detained speakers or audience members for prosecution. To avoid police interference, in November 1927, Lien Wen-ching and other leaders of the Taiwan Cultural Association tried to mobilize the masses to surround a police station. This heightened conflict between the police and the people, and speech venues became the frontline of political protest.</p>
        </div>
      <div class="people">
        <LottieTest :json="require('@/assets/Unit3-1/3-1-1.json')" alt="Political Outreach" title="Political Outreach"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
<!--      <img class="sample" src="../assets/Unit3-1/sample/20230411-對位-3-1-03.png">-->
      <div class="content-1">
        <div style="margin-right: 3vw">
          <img class="h-full" src="../assets/Unit3-1/20230411-3-1-物件-03-pic1.png" alt="Taiwan Local Autonomy Alliance lecture held on July 23, 1933" title="Taiwan Local Autonomy Alliance lecture held on July 23, 1933">
          <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
        <div >
          <img class="h-full" src="../assets/Unit3-1/20230411-3-1-物件-04-pic2.png" alt="Taiwan Local Autonomy Alliance lecture held on July 23, 1933" title="Taiwan Local Autonomy Alliance lecture held on July 23, 1933">
          <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
        <transition name="img-frame-fade"><img v-if="showText[0]==true" class="img-1" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-06-到處都是監視者-説明.png" alt="Surveillance Everywhere" title="Surveillance Everywhere"></transition>
        <transition name="img-frame-fade"><img v-if="showText[1]==true" class="img-2" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-07-細讀照片-説明1.png" alt="Examine the Photo Closely" title="Examine the Photo Closely"></transition>
        <transition name="img-frame-fade"><img v-if="showText[1]==true" class="img-3" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-07-細讀照片-説明2.png" alt="Examine the Photo Closely" title="Examine the Photo Closely"></transition>
      </div>
      <div class="content-2">
          <p class="title-style" style="color: #2A806B">Large-Scale Lecture</p>
          <p class="subtitle-style" style="margin-top: 0.5vw;">Taiwan Local Autonomy Alliance  lecture held on July 23, 1933<span class="btn" style="left: 34vw; width: auto;white-space: nowrap" @click="showText[0]=!showText[0];showText[1]=false;" @keydown.enter="showText[0]=!showText[0];showText[1]=false;" tabindex="0" alt="Surveillance Everywhere" title="Surveillance Everywhere">Surveillance Everywhere</span><span class="btn" style="left: 47vw; width: auto;white-space: nowrap" @click="showText[1]=!showText[1];showText[0]=false;" @keydown.enter="showText[1]=!showText[1];showText[0]=false;" tabindex="0" alt="Examine the Photo Closely" title="Examine the Photo Closely">Examine the Photo Closely</span></p>
          <p class="source-style" style="margin-top: 0.3vw; width: 38.5vw;">Courtesy of Liu Ran Ju Archives</p>
          <div class="content-style" style="margin-top: 0.5vw; width: 38.5vw;height: 8vw; overflow: auto"><p>This is the scene of a central regional lecture held by the Taiwan Local Autonomy Alliance, attended by enthusiastic residents from all over the island.</p>
            <p>The background of the venue is filled with banners listing lecture topics and speakers. At least three police officers can be seen on stage and on the sidelines monitoring the event.</p>
          </div>
      </div>
    </div>

    <div class="part4 part-color">
      <div class="content-1">
        <img class="w-full" src="../assets/Unit3-1/20230411-3-1-物件-08-pic3-放大文物.png" alt="Taiwan Cultural Association Hsinchu Branch hosts “International Students' Group Lecture” leaflet" title="Taiwan Cultural Association Hsinchu Branch hosts “International Students' Group Lecture” leaflet">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 4%; right: 6%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #2A806B;width: 34vw">Learn New Ideas From International Students</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Taiwan Cultural Association Hsinchu Branch hosts "International Students' Group Lecture" leaflet</p>
        <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan History 2003.016.0105</p>
        <p class="content-style" style="margin-top: 0.5vw;">Leaflet for the "International Students' Group Lecture" event hosted by the Taiwan Cultural Association Hsinchu Branch from August 12-14, 1927.</p>
        <p class="content-style">Speakers included Cheng Chang-yan of Tokyo University of Commerce, Lai Yuan-hui and Wu Hong-lu of Tokyo Chuo University, and Hsinchu locals Lin Dong-gui, Peng Yu-dong, and Li Chuan-hsing.</p>
      </div>
      <div class="people">
        <LottieTest :json="require('@/assets/Unit3-1/3-1-2.json')" alt="Taiwan Cultural Association Hsinchu Branch hosts “International Students' Group Lecture” leaflet" title="Taiwan Cultural Association Hsinchu Branch hosts “International Students' Group Lecture” leaflet"></LottieTest>
      </div>
    </div>

    <div class="part5 part-color">
      <div class="pic-1">
        <img class="w-full" src="../assets/Unit3-1/20230411-3-1-物件-09-pic4.png" alt="My Hope" title="My Hope">
        <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 10%; right: -4%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="audio-div">
        <audio-comp :audio-src="require('../assets/Unit3-1/我的希望.mp3')" style-class="orange-style" volume-style="left:98%;"></audio-comp>
      </div>

      <div class="content-1">
        <p class="title-style" style="color: #267c68">Speaking Across Time</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Yang Zhao-jia's "My Hope" record and transcript, 1935</p>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of Hsu Teng-Fang and Liu Ran Ju Archives</p>
        <div class="content-style" style="margin-top: 0.5vw;width: 36vw;  max-height: 13vw; overflow: scroll; padding-right: 15px;">
          <p>Yang Zhao-jia, a political activist hailing from Qingshui, was a passionate promoter of new media and patron of the arts. In 1935, he took advantage of a trip to Tokyo to hire a record company to record one of his political speeches.</p>
          <p>He carefully drafted his manuscript and had his portrait taken at a studio, so he could use his imposing speaking posture to illustrate the record’s liner notes. The record, lasting approximately seven minutes on both sides, concisely expressed his political thoughts and his expectations for the next generation.</p>
        </div>
      </div>

      <div class="turnClass">
        <div id="flipbook">
              <img class="page-1" :src="require('@/assets/Unit3-1/翻書-1.png')" alt="Yang Zhao-jia's “My Hope” transcript, 1935" title="Yang Zhao-jia's “My Hope” transcript, 1935">
              <img class="page-2" :src="require('@/assets/Unit3-1/翻書-2.png')" alt="Yang Zhao-jia's “My Hope” transcript, 1935" title="Yang Zhao-jia's “My Hope” transcript, 1935">
              <img class="page-3" :src="require('@/assets/Unit3-1/翻書-3.png')" alt="Yang Zhao-jia's “My Hope” transcript, 1935" title="Yang Zhao-jia's “My Hope” transcript, 1935">
              <img class="page-4" :src="require('@/assets/Unit3-1/翻書-4.png')" alt="Yang Zhao-jia's “My Hope” transcript, 1935" title="Yang Zhao-jia's “My Hope” transcript, 1935">
        </div>

        <transition name="img-frame-fade"><img @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" v-if="booking==false && bookPage==1" class="zoom-2-btn zoom-fade "  style="width: 2.2vw; bottom: 2.5%; right: 27%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information"></transition>
        <transition name="img-frame-fade"><img @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" v-if="booking==false && bookPage==2 || bookPage==3" class="zoom-2-btn zoom-fade "  style="width: 2.2vw; bottom: 2.5%; right: 2%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information"></transition>
        <transition name="img-frame-fade"><img @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" v-if="booking==false && bookPage==4" class="zoom-2-btn zoom-fade "  style="width: 2.2vw; bottom: 2.5%; right: 27%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information"></transition>

        <transition name="img-frame-fade"><img v-if="bookPage==2 || bookPage==3" class="arrow left-arrow-center"  @click="bookLeftBtn" @keydown.enter="bookLeftBtn" tabindex="0" src="../assets/common/left-arrow.png" alt="previous page" title="previous page"></transition>
        <transition name="img-frame-fade"><img v-if="bookPage==4" class="arrow left-arrow"  @click="bookLeftBtn" @keydown.enter="bookLeftBtn" tabindex="0" src="../assets/common/left-arrow.png" alt="previous page" title="previous page"></transition>

        <transition name="img-frame-fade"><img v-if="bookPage==1"  class="arrow right-arrow" @click="bookRightBtn" @keydown.enter="bookRightBtn" tabindex="0"  src="../assets/common/right-arrow.png" alt="next page" title="next page"></transition>
        <transition name="img-frame-fade"><img v-if="bookPage==2 || bookPage==3"  class="arrow right-arrow-center" @click="bookRightBtn" @keydown.enter="bookRightBtn" tabindex="0" src="../assets/common/right-arrow.png" alt="next page" title="next page"></transition>


      </div>
    </div>

    <div class="part6 part-color">
<!--      <img class="sample" style="z-index: 0" src="../assets/Unit3-1/sample/20230411-對位-3-1-06.png">-->
      <div class="content-1">
        <p class="title-style" style="color: #267c68; width: 23vw">Audience Distribution for Cultural Lectures</p>
        <p class="content-style" style="color: #267c68; margin-top: 0.3vw;width: 34vw">Number:Cumulative number of People
          () : Cumulative number of events
        </p>
        <div class="item" :class="mapNum==0?'item-selected':''" @click="mapBtn(0)" @keydown.enter="mapBtn(0)" tabindex="0" alt="Taihoku (Taipei) Prefecture" title="Taihoku (Taipei) Prefecture"><div class="option"></div>Taihoku (Taipei) Prefecture</div>
        <div class="item" :class="mapNum==1?'item-selected':''" @click="mapBtn(1)" @keydown.enter="mapBtn(1)" tabindex="0" alt="Shinchiku (Hsinchu) Prefecture" title="Shinchiku (Hsinchu) Prefecture"><div class="option"></div>Shinchiku (Hsinchu) Prefecture</div>
        <div class="item" :class="mapNum==2?'item-selected':''" @click="mapBtn(2)" @keydown.enter="mapBtn(2)" tabindex="0" alt="Taichu (Taichung) Prefecture" title="Taichu (Taichung) Prefecture"><div class="option"></div>Taichu (Taichung) Prefecture</div>
        <div class="item" :class="mapNum==3?'item-selected':''" @click="mapBtn(3)" @keydown.enter="mapBtn(3)" tabindex="0" alt="Tainan Prefecture" title="Tainan Prefecture"><div class="option"></div>Tainan Prefecture</div>
        <div class="item" :class="mapNum==4?'item-selected':''" @click="mapBtn(4)" @keydown.enter="mapBtn(4)" tabindex="0" alt="Takao (Kaohsiung) Prefecture" title="Takao (Kaohsiung) Prefecture"><div class="option"></div>Takao (Kaohsiung) Prefecture</div>
      </div>
      <div class="map-div">
        <img class="w-full" src="../assets/Unit3-1/20230411-3-1-物件-17-臺灣.png">

        <transition name="img-frame-fade"><img v-if="mapNum==-1" class="absolute" style="height: 8%; top: 10%; left: 65%;" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-18-臺北州.png" alt="Taihoku (Taipei) Prefecture" title="Taihoku (Taipei) Prefecture"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==-1" class="absolute" style="height: 8%; top: 16.7%; left: 35.7%;" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-19-新竹州.png" alt="Shinchiku (Hsinchu) Prefecture" title="Shinchiku (Hsinchu) Prefecture"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==-1" class="absolute" style="height: 8%; top: 30%; left: 25%;" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-20-臺中州.png" alt="Taichu (Taichung) Prefecture" title="Taichu (Taichung) Prefecture"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==-1" class="absolute" style="height: 8%; top: 48%; left: 12%;" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-21-臺南州.png" alt="Tainan Prefecture" title="Tainan Prefecture"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==-1" class="absolute" style="height: 8%; top: 68%; left: 12%;" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-22-高雄州.png" alt="Takao (Kaohsiung) Prefecture" title="Takao (Kaohsiung) Prefecture"></transition>

        <transition name="img-frame-fade"><img v-if="mapNum==0" class="absolute" style="width:7.55vw; top: 0.5%; left: 62%;" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-18-臺北州-地圖.png" alt="Taihoku (Taipei) Prefecture" title="Taihoku (Taipei) Prefecture"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==1" class="absolute" style="width: 8.8vw; top: 6.6%; left: 29.7%;" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-19-新竹州-地圖.png" alt="Shinchiku (Hsinchu) Prefecture" title="Shinchiku (Hsinchu) Prefecture"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==2" class="absolute" style="width:11.9vw; top: 24.2%; left: 11.65%;" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-20-臺中州-地圖.png" alt="Taichu (Taichung) Prefecture" title="Taichu (Taichung) Prefecture"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==3" class="absolute" style="width:10.14vw; top: 41.5%; left: -1%;" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-21-臺南州-地圖.png" alt="Tainan Prefecture" title="Tainan Prefecture"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==4" class="absolute" style="width:9.36vw; top: 52.2%; left: 6.2%;" src="../assets/Unit3-1/en/20230708-EN網站-3-1-物件-22-高雄州-地圖.png" alt="Takao (Kaohsiung) Prefecture" title="Takao (Kaohsiung) Prefecture"></transition>
      </div>
      <transition name="img-frame-fade">
        <div class="content-2" v-if="mapPage==0">
          <p class="content-style">Starting in 1923, the number of lectures increased every year, peaking in 1925 and 1926 with more than 300 lectures island-wide. They were making ground, indicating that lectures were being given in response to the cultural school. The Historical Logs of the Governor-General Police contain the following categories of statistics about the cultural lectures: “number of lectures, number of times they were disbanded, and number of individuals disciplined”, “number of speakers and number of suspensions”, as well as the “total size and average size of audiences”.</p>
        </div>
      </transition>

      <div class="content-3" v-if="mapPage==1">
        <transition name="img-frame-fade">
          <div class="chart-div chart-1" v-if="mapNum==0">
            <div class="chart">
              <div class="count">
                <p>4000pl</p>
                <p>(4)</p>
              </div>
              <div class="bar" :style="barStyle[0]"></div>
              <div class="year">1923</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>13950pl</p>
                <p>(51)</p>
              </div>
              <div class="bar" :style="barStyle[1]"></div>
              <div class="year">1924</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>27920pl</p>
                <p>(99)</p>
              </div>
              <div class="bar" :style="barStyle[2]"></div>
              <div class="year">1925</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>24915pl</p>
                <p>(97)</p>
              </div>
              <div class="bar" :style="barStyle[3]"></div>
              <div class="year">1926</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>24850pl</p>
                <p>(83)</p>
              </div>
              <div class="bar" :style="barStyle[4]"></div>
              <div class="year">1927</div>
            </div>
          </div>
        </transition>
        <transition name="img-frame-fade">
          <div class="chart-div chart-2" v-if="mapNum==1">
            <div class="chart">
              <div class="count">
                <p>18410pl</p>
                <p>(22)</p>
              </div>
              <div class="bar" :style="barStyle[5]"></div>
              <div class="year">1925</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>28630pl</p>
                <p>(68)</p>
              </div>
              <div class="bar" :style="barStyle[6]"></div>
              <div class="year">1926</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>42000pl</p>
                <p>(73)</p>
              </div>
              <div class="bar" :style="barStyle[7]"></div>
              <div class="year">1927</div>
            </div>
          </div>
        </transition>
        <transition name="img-frame-fade">
          <div class="chart-div chart-3" v-if="mapNum==2">
            <div class="chart">
              <div class="count">
                <p>14113pl</p>
                <p>(25)</p>
              </div>
              <div class="bar" :style="barStyle[10]"></div>
              <div class="year">1923</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>22525pl</p>
                <p>(47)</p>
              </div>
              <div class="bar" :style="barStyle[11]"></div>
              <div class="year">1924</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>36440pl</p>
                <p>(102)</p>
              </div>
              <div class="bar" :style="barStyle[12]"></div>
              <div class="year">1925</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>13360pl</p>
                <p>(27)</p>
              </div>
              <div class="bar" :style="barStyle[13]"></div>
              <div class="year">1926</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>15940pl</p>
                <p>(47)</p>
              </div>
              <div class="bar" :style="barStyle[14]"></div>
              <div class="year">1927</div>
            </div>
          </div>
        </transition>
        <transition name="img-frame-fade">
          <div class="chart-div chart-4" v-if="mapNum==3">
            <div class="chart">
              <div class="count">
                <p>2773pl</p>
                <p>(6)</p>
              </div>
              <div class="bar" :style="barStyle[15]"></div>
              <div class="year">1923</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>7575pl</p>
                <p>(34)</p>
              </div>
              <div class="bar" :style="barStyle[16]"></div>
              <div class="year">1924</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>32840pl</p>
                <p>(67)</p>
              </div>
              <div class="bar" :style="barStyle[17]"></div>
              <div class="year">1925</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>36870pl</p>
                <p>(88)</p>
              </div>
              <div class="bar" :style="barStyle[18]"></div>
              <div class="year">1926</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>11890pl</p>
                <p>(34)</p>
              </div>
              <div class="bar" :style="barStyle[19]"></div>
              <div class="year">1927</div>
            </div>
          </div>
        </transition>
        <transition name="img-frame-fade">
          <div class="chart-div chart-5" v-if="mapNum==4">
            <div class="chart">
              <div class="count">
                <p>200pl</p>
                <p>(1)</p>
              </div>
              <div class="bar" :style="barStyle[20]"></div>
              <div class="year">1923</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>2270pl</p>
                <p>(24)</p>
              </div>
              <div class="bar" :style="barStyle[22]"></div>
              <div class="year">1925</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>9190pl</p>
                <p>(35)</p>
              </div>
              <div class="bar" :style="barStyle[23]"></div>
              <div class="year">1926</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>2380pl</p>
                <p>(8)</p>
              </div>
              <div class="bar" :style="barStyle[24]"></div>
              <div class="year">1927</div>
            </div>
          </div>
        </transition>

      </div>

      <div class="source">Source: “Historical Logs of the Governor-General Police” The list of Taiwan Cultural Association lecture</div>
      <div class="back-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
        <p style="margin-left: 0.5vw">Back</p>
      </div>
    </div>




    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-11.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36vw; margin:2.9vw 0 0 13.3vw" src="../assets/Unit3-1/20230704-3-1-物件-03-pic1-放大文物.png" alt="Taiwan Local Autonomy Alliance  lecture held on July 23, 1933" title="Taiwan Local Autonomy Alliance  lecture held on July 23, 1933">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Taiwan Local Autonomy Alliance  lecture held on July 23, 1933</div>
              <div class="noto-text" >Courtesy of Liu Ran Ju Archives</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-12.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36vw; margin:2.9vw 0 0 13.3vw" src="../assets/Unit3-1/20230704-3-1-物件-04-pic2-放大文物.png" alt="Taiwan Local Autonomy Alliance  lecture held on July 23, 1933" title="Taiwan Local Autonomy Alliance  lecture held on July 23, 1933">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Taiwan Local Autonomy Alliance  lecture held on July 23, 1933</div>
              <div class="noto-text" >Courtesy of Liu Ran Ju Archives</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-13.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:29.7vw; margin:2.5vw 0 0 16.9vw" src="../assets/Unit3-1/20230704-3-1-物件-08-pic3-放大文物.png" alt="Taiwan Cultural Association Hsinchu Branch hosts “International Students' Group Lecture” leaflet" title="Taiwan Cultural Association Hsinchu Branch hosts “International Students' Group Lecture” leaflet">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Taiwan Cultural Association Hsinchu Branch hosts "International Students' Group Lecture" leaflet</div>
              <div class="noto-text" >National Museum of Taiwan History 2003.016.0105</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-14.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:27.2vw; margin:2.5vw 0 0 18vw" src="../assets/Unit3-1/20230704-3-1-物件-09-pic4-放大文物.png">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Yang Zhao-jia's "My Hope" record, 1935</div>
              <div class="noto-text" >Courtesy of Hsu Teng-Fang</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-15.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.2vw; margin:2.5vw 0 0 13.5vw" src="../assets/Unit3-1/20230704-3-1-物件-10-pic5-放大文物.png" alt="Yang Zhao-jia's “My Hope” transcript, 1935" title="Yang Zhao-jia's “My Hope” transcript, 1935">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Yang Zhao-jia's "My Hope" transcript, 1935</div>
              <div class="noto-text" >Courtesy of Liu Ran Ju Archives</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.2vw; margin:2.5vw 0 0 13.5vw" src="../assets/Unit3-1/20230704-3-1-物件-11-pic6-放大文物.png" alt="Yang Zhao-jia's “My Hope” transcript, 1935" title="Yang Zhao-jia's “My Hope” transcript, 1935">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Yang Zhao-jia's "My Hope" transcript, 1935</div>
              <div class="noto-text" >Courtesy of Liu Ran Ju Archives</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>

    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import EnIntroBtn from "@/components/EnIntroBtn.vue";
import router from "@/router";
import Unit3BackBtn from "@/components/Unit3BackBtn";
import FooterFeature from "@/components/FooterFeature";
import LottieTest from "@/components/LottieTest";
import {nextTick, onBeforeUnmount, onMounted, onUnmounted, reactive, ref} from "vue";
import myMixin from "@/assets/common/js/mixin";
import AudioComp from "@/components/AudioComp";
import $ from "jquery";
import turn from "@/assets/utils/lib/turn";
import flipAudio from "@/assets/Unit3-1/insert_paper.mp3";


export default {
  name: "Unit3-1",
  mixins: [myMixin],
  components:{
    EnToolbar,
    EnIntroBtn,
    Unit3BackBtn,
    FooterFeature,
    LottieTest,
    AudioComp,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));
    const showText = reactive(Array.from({ length: 2 }, () => false));

    const book1 = ref(require('@/assets/Unit3-1/10-pic5-翻書動態/10-pic5-翻頁效果-01.png'));

    const mapNum = ref(-1);
    const barStyle = reactive(Array.from({ length: 25 }, () => ''));
    const mapPage = ref(0);
    let progress = [5,7,10.5,9.8,9.6,
                    8.5,11,14,0,0,
                    7.5,9,12.5,7,8,
                    3,5.5,12,12.5,6.5,
                    1.3,0,2,6,2.5];

    const flipAudio = require('@/assets/Unit3-1/insert_paper.mp3');
    const flipEle = new Audio(flipAudio);


    function onTurn(){


      $("#flipbook").turn({
        acceleration:true,
        gradients: true,
        autoCenter: true,
        height:646,
        width:896,
        direction: "rtl"
      });

      $('#flipbook').turn('size', '100%', '100%');
      $("#flipbook").bind("turning", function(event, page, view) {
        bookPage.value = page;
        flipEle.play();
      });

    }


    onMounted(()=>{

      //$("#flipbook").turn("destroy");
      if ($('#flipbook').turn('is')) {
        $("#flipbook").turn("destroy");
      }
      onTurn();


      window.addEventListener('resize', ()=>{
        $('#flipbook').turn('size', 'auto', '100%');
      });

    });



    function chartAnim(){
      for (let i=0; i<barStyle.length; i++){
        barStyle[i] = 'height:' + progress[i] + "vw;";
      }
    }

    function mapBtn(num){
      mapNum.value = num;
      mapPage.value = 1;

      for (let i=0; i<barStyle.length; i++){
        barStyle[i] = 'height:0vw;';
      }

      setTimeout(()=>{
        chartAnim();
      }, 300);
    }

    function routeBtn(path){
      router.push(path);
    }

    let bookId = null;
    let bookIndex = 1;
    const bookPage = ref(1);
    const booking = ref(false);
    function bookRightBtn(){
      $("#flipbook").turn("next");
      // if(bookPage.value == 3){
      //   return;
      // }
      // bookPage.value += 1;

      return;
      if(booking.value || bookPage.value == 2) return;

      booking.value = true;
      bookIndex = 1;

      if(bookPage.value == 0){
        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = (bookIndex + 1) % (59+1);

          book1.value = require('@/assets/Unit3-1/10-pic5-翻書動態/10-pic5-翻頁效果-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex >=  59){
            //book1Style.value = 'opacity:0;';
            clearInterval(bookId);
            bookPage.value=1;
            booking.value = false;
          }

        }, 50);
      }else if(bookPage.value==1){

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = (bookIndex + 1) % (59+1);

          book1.value = require('@/assets/Unit3-1/11-pic6-翻書動態/11-pic6-翻頁效果-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex >=  59){
            //book1Style.value = 'opacity:0;';
            clearInterval(bookId);
            bookPage.value=2;
            booking.value = false;
          }

        }, 50);
      }


    }

    function bookLeftBtn(){
      $("#flipbook").turn("previous");
      // if(bookPage.value == 1){
      //   return;
      // }
      // bookPage.value -= 1;

      return;
      if(booking.value || bookPage.value == 0) return;
      booking.value = true;

      if(bookPage.value == 1){
        bookIndex = 59;

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = bookIndex-1;

          book1.value = require('@/assets/Unit3-1/10-pic5-翻書動態/10-pic5-翻頁效果-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex <= 1){
            clearInterval(bookId);
            bookPage.value=0;
            booking.value = false;
          }

        }, 50);
      }else if(bookPage.value==2){
        bookIndex = 59;

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = bookIndex-1;

          book1.value = require('@/assets/Unit3-1/11-pic6-翻書動態/11-pic6-翻頁效果-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex <= 1){
            clearInterval(bookId);
            bookPage.value=1;
            booking.value = false;
          }

        }, 50);
      }

    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      book1,
      bookRightBtn,
      bookLeftBtn,
      bookPage,
      booking,
      mapNum,
      barStyle,
      mapPage,
      mapBtn,
      showImg,
      showText,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit3-1.css"></style>
