<template>
  <div class="wrap w-full">
    <en-toolbar type="2" unit="5"  route-name="person-intro-all" color="brown"></en-toolbar>
<!--    <img class="absolute w-full top-0 opacity-30" src="../assets/person-intro/sample/en/20230712-EN網站-對位-人物介紹-入口頁面-or-01-.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div style="height: 49.6vw; overflow: hidden">
      <div class="title-div">
        <p class="title">People of Cultural Association</p>
        <div>
          <p class="sort-btn" @click="changePage(0)" @keydown.enter="changePage(0)" tabindex="0" alt="See All" title="See All">See All</p>
          <p class="sort-btn" @click="changePage(1)" @keydown.enter="changePage(1)" tabindex="0" alt="People of Cultural Association" title="People of Cultural Association">People of Cultural Association</p>
          <p class="sort-btn" @click="changePage(2)" @keydown.enter="changePage(2)" tabindex="0" alt="Scholar-gentry" title="Scholar-gentry">Scholar-gentry</p>
          <p class="sort-btn" @click="changePage(3)" @keydown.enter="changePage(3)" tabindex="0" alt="Rebellious Youth" title="Rebellious Youth">Rebellious Youth</p>
        </div>
        <div style="width: 36%; margin-left: 0.2vw;">
          <p class="sort-btn" @click="changePage(4)" @keydown.enter="changePage(4)" tabindex="0" alt="Physicians and Christians" title="Physicians and Christians">Physicians and Christians</p>
          <p class="sort-btn" @click="changePage(5)" @keydown.enter="changePage(5)" tabindex="0" alt="Exploring Gender Boundaries" title="Exploring Gender Boundaries">Exploring Gender Boundaries</p>
          <p class="sort-btn" @click="changePage(6)" @keydown.enter="changePage(6)" tabindex="0" alt="Sketches of Laborers" title="Sketches of Laborers">Sketches of Laborers</p>
        </div>




      </div>

      <transition name="img-frame-fade"> <div v-if="page[0]==true" class="content content-1 " >
        <img @click="routeBtn('/en/person-intro/11')" @keydown.enter="routeBtn('/en/person-intro/11')" tabindex="0" class="head person-1 vibrate-1" src="../assets/person-intro/head/20230424-入口頁面-物件-18-蔡惠如-白.png" alt="Tsai Hui-ju" title="Tsai Hui-ju">
        <img @click="routeBtn('/en/person-intro/24')" @keydown.enter="routeBtn('/en/person-intro/24')" tabindex="0" class="head person-2 vibrate-2" src="../assets/person-intro/head/20230424-入口頁面-物件-31-王敏川-白.png" alt="Wang Min-chuan" title="Wang Min-chuan">
        <img @click="routeBtn('/en/person-intro/0')" @keydown.enter="routeBtn('/en/person-intro/0')" tabindex="0" class="head person-3 vibrate-3" src="../assets/person-intro/head/20230424-入口頁面-物件-07-連溫卿-白.png" alt="Lien Wen-ching" title="Lien Wen-ching">
        <img @click="routeBtn('/en/person-intro/14')" @keydown.enter="routeBtn('/en/person-intro/14')" tabindex="0" class="head person-4 vibrate-4" src="../assets/person-intro/head/20230424-入口頁面-物件-21-林幼春-白.png" alt="Lin You-chun" title="Lin You-chun">
        <img @click="routeBtn('/en/person-intro/9')" @keydown.enter="routeBtn('/en/person-intro/9')" tabindex="0" class="head person-5 vibrate-5" src="../assets/person-intro/head/20230424-入口頁面-物件-16-王詩琅-白.png" alt="Wang Shi-lang" title="Wang Shi-lang">
        <img @click="routeBtn('/en/person-intro/10')" @keydown.enter="routeBtn('/en/person-intro/10')" tabindex="0" class="head person-6 vibrate-1" src="../assets/person-intro/head/20230424-入口頁面-物件-17-蔡淵騰-白.png" alt="Tsai Yuan-tang" title="Tsai Yuan-tang">
        <img @click="routeBtn('/en/person-intro/8')" @keydown.enter="routeBtn('/en/person-intro/8')" tabindex="0" class="head person-7 vibrate-2" src="../assets/person-intro/head/20230424-入口頁面-物件-15-盧丙丁-白.png" alt="Lu Bing-ding" title="Lu Bing-ding">
        <img @click="routeBtn('/en/person-intro/2')" @keydown.enter="routeBtn('/en/person-intro/2')" tabindex="0" class="head person-8 vibrate-3" src="../assets/person-intro/head/20230424-入口頁面-物件-09-謝文達-白.png" alt="Hsieh Wen-ta" title="Hsieh Wen-ta">
        <img @click="routeBtn('/en/person-intro/16')" @keydown.enter="routeBtn('/en/person-intro/16')" tabindex="0" class="head person-9 vibrate-4" src="../assets/person-intro/head/20230424-入口頁面-物件-23-杜香國-白.png" alt="Du Xiang-guo" title="Du Xiang-guo">
        <img @click="routeBtn('/en/person-intro/17')" @keydown.enter="routeBtn('/en/person-intro/17')" tabindex="0" class="head person-10 vibrate-5" src="../assets/person-intro/head/20230424-入口頁面-物件-24-蔣渭水-白.png" alt="Chiang Wei-shui" title="Chiang Wei-shui">
        <img @click="routeBtn('/en/person-intro/6')" @keydown.enter="routeBtn('/en/person-intro/6')" tabindex="0" class="head person-11 vibrate-1" src="../assets/person-intro/head/20230424-入口頁面-物件-13-陳植棋-白.png" alt="Chen Zhiqi" title="Chen Zhiqi">
        <img @click="routeBtn('/en/person-intro/20')" @keydown.enter="routeBtn('/en/person-intro/20')" tabindex="0" class="head person-12 vibrate-2" src="../assets/person-intro/head/20230424-入口頁面-物件-27-賴和-白.png" alt="Loa Ho" title="Loa Ho">
        <img @click="routeBtn('/en/person-intro/21')" @keydown.enter="routeBtn('/en/person-intro/21')" tabindex="0" class="head person-13 vibrate-3" src="../assets/person-intro/head/20230424-入口頁面-物件-28-林淇漳-白.png" alt="Lin Qi-zhang" title="Lin Qi-zhang">
        <img @click="routeBtn('/en/person-intro/15')" @keydown.enter="routeBtn('/en/person-intro/15')" tabindex="0" class="head person-14 vibrate-4" src="../assets/person-intro/head/20230424-入口頁面-物件-22-蘇璧輝-白.png" alt="Su Bi-hui" title="Su Bi-hui">
        <img @click="routeBtn('/en/person-intro/23')" @keydown.enter="routeBtn('/en/person-intro/23')" tabindex="0" class="head person-15 vibrate-5" src="../assets/person-intro/head/20230424-入口頁面-物件-30-簡吉-白.png" alt="Jian Ji" title="Jian Ji">
        <img @click="routeBtn('/en/person-intro/3')" @keydown.enter="routeBtn('/en/person-intro/3')" tabindex="0" class="head person-16 vibrate-1" src="../assets/person-intro/head/20230424-入口頁面-物件-10-林獻堂-白.png" alt="Lin Hsien-tang" title="Lin Hsien-tang">
        <img @click="routeBtn('/en/person-intro/1')" @keydown.enter="routeBtn('/en/person-intro/1')" tabindex="0" class="head person-17 vibrate-2" src="../assets/person-intro/head/20230424-入口頁面-物件-08-蔡培火-白.png" alt="Tsai Pei-huo" title="Tsai Pei-huo">
        <img @click="routeBtn('/en/person-intro/13')" @keydown.enter="routeBtn('/en/person-intro/13')" tabindex="0" class="head person-18 vibrate-3" src="../assets/person-intro/head/20230424-入口頁面-物件-20-陳懷澄-白.png" alt="Chen Huai-cheng" title="Chen Huai-cheng">
        <img @click="routeBtn('/en/person-intro/18')" @keydown.enter="routeBtn('/en/person-intro/18')" tabindex="0" class="head person-19 vibrate-4" src="../assets/person-intro/head/20230424-入口頁面-物件-25-韓石泉-白.png" alt="Han Shi-quan" title="Han Shi-quan">
        <img @click="routeBtn('/en/person-intro/19')" @keydown.enter="routeBtn('/en/person-intro/19')" tabindex="0" class="head person-20 vibrate-5" src="../assets/person-intro/head/20230424-入口頁面-物件-26-李應章-白.png" alt="Li Ying-zhang" title="Li Ying-zhang">
        <img @click="routeBtn('/en/person-intro/22')" @keydown.enter="routeBtn('/en/person-intro/22')" tabindex="0" class="head person-21 vibrate-1" src="../assets/person-intro/head/20230424-入口頁面-物件-29-楊肇嘉-白.png" alt="Yang Zhao-jia" title="Yang Zhao-jia">
        <img @click="routeBtn('/en/person-intro/4')" @keydown.enter="routeBtn('/en/person-intro/4')" tabindex="0" class="head person-22 vibrate-2" src="../assets/person-intro/head/20230424-入口頁面-物件-11-謝雪紅-白.png" alt="Hsieh Hsueh-hung" title="Hsieh Hsueh-hung">
        <img @click="routeBtn('/en/person-intro/12')" @keydown.enter="routeBtn('/en/person-intro/12')" tabindex="0" class="head person-23 vibrate-3" src="../assets/person-intro/head/20230424-入口頁面-物件-19-陳澄波-白.png" alt="Chen Cheng-po" title="Chen Cheng-po">
        <img @click="routeBtn('/en/person-intro/5')" @keydown.enter="routeBtn('/en/person-intro/5')" tabindex="0" class="head person-24 vibrate-4" src="../assets/person-intro/head/20230424-入口頁面-物件-12-簡娥-白.png" alt="Jian E" title="Jian E">
        <img @click="routeBtn('/en/person-intro/7')" @keydown.enter="routeBtn('/en/person-intro/7')" tabindex="0" class="head person-25 vibrate-5" src="../assets/person-intro/head/20230424-入口頁面-物件-14-莊松林-白.png" alt="Chuang Sung-lin" title="Chuang Sung-lin">

        <p class="text-1">Click on the person to see their profile</p>
      </div></transition>

      <transition name="img-frame-fade"><div v-if="page[1]==true" class="content content-2 " >
        <img @click="routeBtn('/en/person-intro/0')" @keydown.enter="routeBtn('/en/person-intro/0')" tabindex="0" class="head person-3" src="../assets/person-intro/head/20230424-入口頁面-物件-07-連溫卿-白.png" alt="Lien Wen-ching" title="Lien Wen-ching">
        <img @click="routeBtn('/en/person-intro/2')" @keydown.enter="routeBtn('/en/person-intro/2')" tabindex="0" class="head person-8" src="../assets/person-intro/head/20230424-入口頁面-物件-09-謝文達-白.png" alt="Hsieh Wen-ta" title="Hsieh Wen-ta">
        <img @click="routeBtn('/en/person-intro/3')" @keydown.enter="routeBtn('/en/person-intro/3')" tabindex="0" class="head person-16" src="../assets/person-intro/head/20230424-入口頁面-物件-10-林獻堂-白.png" alt="Lin Hsien-tang" title="Lin Hsien-tang">
        <img @click="routeBtn('/en/person-intro/1')" @keydown.enter="routeBtn('/en/person-intro/1')" tabindex="0" class="head person-17" src="../assets/person-intro/head/20230424-入口頁面-物件-08-蔡培火-白.png" alt="Tsai Pei-huo" title="Tsai Pei-huo">

<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">Click on the person to see their profile</p>
      </div></transition>

      <transition name="img-frame-fade"><div v-if="page[2]==true" class="content content-3 " >
        <div class="content-text en-content-style">
          <p>In the early days of Japanese colonial rule, there were great changes in society. Nonetheless, landowning families in various regions still had the power to integrate local economic power with social and cultural standing. The new generation of intellectuals, who were deeply rooted in traditional Confucian thought, also accepted modern education, to become progressive individuals who knew something and held to the spirit of being fair to the world.</p>
          <p style="margin-top: 1vw">Moral and financial support from the local gentry was essential to the development and operation of the Taiwan Cultural Association. They contributed time and money, and most of them hoped for self-government and civil rights. However, they were often criticized by radical young intellectuals for their moderation and willingness to compromise with Taiwan’s colonial rulers, as it seemed they were only concerned about their own interests as middle-class landowners. In truth, they stood up for the local political movements, promoted autonomy, and helped popularize the concept of civic rights.</p>
        </div>

        <img @click="routeBtn('/en/person-intro/11')" @keydown.enter="routeBtn('/en/person-intro/11')" tabindex="0" class="head person-1" src="../assets/person-intro/head/20230424-入口頁面-物件-18-蔡惠如-白.png" alt="Tsai Hui-ju" title="Tsai Hui-ju">
        <img @click="routeBtn('/en/person-intro/14')" @keydown.enter="routeBtn('/en/person-intro/14')" tabindex="0" class="head person-4" src="../assets/person-intro/head/20230424-入口頁面-物件-21-林幼春-白.png" alt="Lin You-chun" title="Lin You-chun">
        <img @click="routeBtn('/en/person-intro/16')" @keydown.enter="routeBtn('/en/person-intro/16')" tabindex="0" class="head person-9" src="../assets/person-intro/head/20230424-入口頁面-物件-23-杜香國-白.png" alt="Du Xiang-guo" title="Du Xiang-guo">
        <img @click="routeBtn('/en/person-intro/15')" @keydown.enter="routeBtn('/en/person-intro/15')" tabindex="0" class="head person-14" src="../assets/person-intro/head/20230424-入口頁面-物件-22-蘇璧輝-白.png" alt="Su Bi-hui" title="Su Bi-hui">
        <img @click="routeBtn('/en/person-intro/13')" @keydown.enter="routeBtn('/en/person-intro/13')" tabindex="0" class="head person-18" src="../assets/person-intro/head/20230424-入口頁面-物件-20-陳懷澄-白.png" alt="Chen Huai-cheng" title="Chen Huai-cheng">
        <img @click="routeBtn('/en/person-intro/12')" @keydown.enter="routeBtn('/en/person-intro/12')" tabindex="0" class="head person-23" src="../assets/person-intro/head/20230424-入口頁面-物件-19-陳澄波-白.png" alt="Chen Cheng-po" title="Chen Cheng-po">
        <img @click="routeBtn('/en/person-intro/22')" @keydown.enter="routeBtn('/en/person-intro/22')" tabindex="0" class="head person-21" src="../assets/person-intro/head/20230424-入口頁面-物件-29-楊肇嘉-白.png" alt="Yang Zhao-jia" title="Yang Zhao-jia">

<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">Click on the person to see their profile</p>
      </div></transition>

      <transition name="img-frame-fade"><div v-if="page[3]==true" class="content content-4 " >
        <div class="content-text en-content-style">
          <p>Young students were the most passionate and radical activists in the Taiwan Cultural Association. Of the association’s thousand-plus members at the time of its founding, more than a quarter were students, most of them attending Taipei Normal School. In 1922, a campus upheaval occurred at Taipei Normal University. The police criticized the association for instigating the ruckus and students were forced to quit the association. However, student organizations (named reading clubs or research clubs) continued guerilla-like discussions of social issues.</p>
          <p style="margin-top: 1vw">The radical activities of the youth reflected their powerlessness when faced by colonial domination and a rigid political environment. They were often regarded as “proletarian youths,” and some of them claimed to campaign for a better world. However, subsequent social movements were unsustainable. They devoted themselves to literature and fine arts, showing enthusiasm for research, lonely writing, and solitary creativity, eventually becoming pioneers of the Taiwan New Literary Movement.</p>
        </div>

        <img @click="routeBtn('/en/person-intro/24')" @keydown.enter="routeBtn('/en/person-intro/24')" tabindex="0" class="head person-2" src="../assets/person-intro/head/20230424-入口頁面-物件-31-王敏川-白.png" alt="Wang Min-chuan" title="Wang Min-chuan">
        <img @click="routeBtn('/en/person-intro/6')" @keydown.enter="routeBtn('/en/person-intro/6')" tabindex="0" class="head person-11" src="../assets/person-intro/head/20230424-入口頁面-物件-13-陳植棋-白.png" alt="Chen Zhiqi" title="Chen Zhiqi">
        <img @click="routeBtn('/en/person-intro/23')" @keydown.enter="routeBtn('/en/person-intro/23')" class="head person-15" src="../assets/person-intro/head/20230424-入口頁面-物件-30-簡吉-白.png" alt="Jian Ji" title="Jian Ji">



<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">Click on the person to see their profile</p>
      </div></transition>

      <transition name="img-frame-fade"><div v-if="page[4]==true" class="content content-5 " >
        <div class="content-text en-content-style">
          <p>The medical and teaching professions were important channels for social mobility in Taiwan during the period of colonial rule. Those who did well in school could enter higher education. Therefore, medical schools and normal schools cultivated the era’s elites. Since teachers were civil servants, and strictly controlled by the government, physicians became key participants in social movements.</p>
          <p style="margin-top: 1vw">Based on their in-depth scientific education, physicians generally chose to pursue an ideal lifestyle. They usually adopted more practical movements with flexibility, especially caring for minorities.</p>
          <p style="margin-top: 1vw">In addition, Christians were among the first Taiwanese to be exposed to cultures beyond East Asia. They embraced a spirit of sacrifice and dedication and were also at the heart of early political movements. They promoted grassroots organizations and legal demonstrations, their goal being equal participation in political and public affairs, which is the spirit of modern democracy.</p>
        </div>

        <img @click="routeBtn('/en/person-intro/17')" @keydown.enter="routeBtn('/en/person-intro/17')" class="head person-10" src="../assets/person-intro/head/20230424-入口頁面-物件-24-蔣渭水-白.png" alt="Chiang Wei-shui" title="Chiang Wei-shui">
        <img @click="routeBtn('/en/person-intro/20')" @keydown.enter="routeBtn('/en/person-intro/20')" class="head person-12" src="../assets/person-intro/head/20230424-入口頁面-物件-27-賴和-白.png" alt="Loa Ho" title="Loa Ho">
        <img @click="routeBtn('/en/person-intro/21')" @keydown.enter="routeBtn('/en/person-intro/21')" class="head person-13" src="../assets/person-intro/head/20230424-入口頁面-物件-28-林淇漳-白.png" alt="Lin Qi-zhang" title="Lin Qi-zhang">
        <img @click="routeBtn('/en/person-intro/18')" @keydown.enter="routeBtn('/en/person-intro/18')" class="head person-19" src="../assets/person-intro/head/20230424-入口頁面-物件-25-韓石泉-白.png" alt="Han Shi-quan" title="Han Shi-quan">
        <img @click="routeBtn('/en/person-intro/19')" @keydown.enter="routeBtn('/en/person-intro/19')" class="head person-20" src="../assets/person-intro/head/20230424-入口頁面-物件-26-李應章-白.png" alt="Li Ying-zhang" title="Li Ying-zhang">

<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">Click on the person to see their profile</p>
      </div></transition>

      <transition name="img-frame-fade"><div v-if="page[5]==true" class="content content-6 " >
        <div class="content-text en-content-style">
          <p>Gender issues, especially women’s rights and marriage issues, had been widely debated since the founding of the Taiwan Cultural Association. Under the influence of the association, Changhua Women’s Encouragement Association and Zhuluo Women’s Association were established in Changhua and Chiayi, respectively, to organize lectures, evening classes, and other activities. Some members ran away and left their families, causing much discussion. After the Taiwan Cultural Association was reorganized in 1927, a women’s department was established. The women’s association in Chiayi was reorganized and renamed the Taiwan Women’s Association, making it the first islandwide women’s group in Taiwan.</p>
          <p style="margin-top: 1vw">Among the Taiwan Cultural Associations and left-wing groups, there were many outstanding female activists, reflecting the rise of young, educated women. Often called “free girls,” “civilized girls” or “modern girls” by the media, their brilliant and active participation in social movements challenged gender boundaries, loosened established social structures, and hastened the revolution of self-identity in everyone’s hearts.</p>
        </div>

        <img @click="routeBtn('/en/person-intro/4')" @keydown.enter="routeBtn('/en/person-intro/4')" class="head person-22" src="../assets/person-intro/head/20230424-入口頁面-物件-11-謝雪紅-白.png" alt="Hsieh Hsueh-hung" title="Hsieh Hsueh-hung">
        <img @click="routeBtn('/en/person-intro/5')" @keydown.enter="routeBtn('/en/person-intro/5')" class="head person-24" src="../assets/person-intro/head/20230424-入口頁面-物件-12-簡娥-白.png" alt="Jian E" title="Jian E">


<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">Click on the person to see their profile</p>
      </div></transition>

      <transition name="img-frame-fade"> <div v-if="page[6]==true" class="content content-7" >
        <div class="content-text en-content-style">
          <p>The Taiwan Cultural Association was initially dominated by the intellectual class. As it developed, social issues concerning farmers and laborers’ rights gradually become the focus of the association and other organizations, and they indeed formed a strong alliance. By 1928, farmers’ associations had 24,000 members, and there were more than 30 labor unions outside the Taiwanese People’s Party, with more than 10,000 members in total.</p>
          <p style="margin-top: 1vw">The number of participants suggests that farmers and laborers brought about the heyday of the Taiwan Cultural Association. When reviewing history, however, they have only left a blurry outline. These sketches remind us that there were groups of farmers and laborers not only dedicated their work, but who also tried to tackle social issues and unjust systems in order to improve their lives, despite great pressure from the colonial authorities.</p>
        </div>

        <img @click="routeBtn('/en/person-intro/9')" @keydown.enter="routeBtn('/en/person-intro/9')" class="head person-5" src="../assets/person-intro/head/20230424-入口頁面-物件-16-王詩琅-白.png" alt="Wang Shi-lang" title="Wang Shi-lang">
        <img @click="routeBtn('/en/person-intro/10')" @keydown.enter="routeBtn('/en/person-intro/10')" class="head person-6" src="../assets/person-intro/head/20230424-入口頁面-物件-17-蔡淵騰-白.png" alt="Tsai Yuan-tang" title="Tsai Yuan-tang">
        <img @click="routeBtn('/en/person-intro/7')" @keydown.enter="routeBtn('/en/person-intro/7')" class="head person-25" src="../assets/person-intro/head/20230424-入口頁面-物件-14-莊松林-白.png" alt="Chuang Sung-lin" title="Chuang Sung-lin">
        <img @click="routeBtn('/en/person-intro/8')" @keydown.enter="routeBtn('/en/person-intro/8')" class="head person-7" src="../assets/person-intro/head/20230424-入口頁面-物件-15-盧丙丁-白.png" alt="Lu Bing-ding" title="Lu Bing-ding">

<!--        <img class="back-btn" @click="changePage(0)" src="../assets/common/left-arrow.png">-->
        <p class="text-1">Click on the person to see their profile</p>
      </div></transition>

    </div>

    <FooterFeature language='en' color="black"></FooterFeature>
  </div>
</template>

<script>
import router from "@/router";
import FooterFeature from "@/components/FooterFeature";
import {reactive} from "vue";
import EnToolbar from "@/components/EnToolbar.vue";


export default {
  name: "PersonIntroAllView",
  components:{
    EnToolbar,
    FooterFeature,
  },
  setup(){
    const page = reactive(Array.from({ length: 7 }, () => false));
    page[0] = true;

    function changePage(pageIndex){
      for (let i=0; i<page.length; i++)
        page[i] = false;
      page[pageIndex] = true;
    }

    function routeBtn(path){
      router.push(path);
    }
    return{
      routeBtn,
      page,
      changePage
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/en/personintro-all.css"></style>
