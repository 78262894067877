<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="2" unit="3" route-name="unit4-1" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit4-1/sample/20230415-對位-4-1-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="title-logo">時鐘塔</div>
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-1.json')" alt="時鐘塔" title="時鐘塔"></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit4-1/20230415-4-1-物件-01-首圖１.png" alt="時鐘塔" title="時鐘塔">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-0.json')" alt="時鐘塔" title="時鐘塔" ></LottieTest>
      </div>
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit4-1/20230415-4-1-物件-03-裝飾字1-時鐘塔.png" alt="時鐘塔" title="時鐘塔">
      <div class="content content-style">
        <p>在萬華（艋舺）龍山寺廣場一側，有一座存留的石碑，是1927年12月樹立，石碑體上刻有「時間厲行」四個大字、還有對照的世界語「TEMPO ESTAS MONO」（時間就是金錢）。</p>
        <p>這座石碑是當地商人蘇璧輝家族捐建，立塔這年的文化協會新章程裡揭櫫「普及大眾之文化」的實行綱領，也列有「獎勵恪守時間」一項。這座鐘塔，是對懈怠生活習性的提醒，更可說是文協一代人努力奮進，力圖打破本土社會陳舊觀念，攻城掠地的灘頭堡。</p>
      </div>
      <img class="img-1" src="../assets/Unit4-1/20230415-4-1-物件-04-插畫１.png" alt="時鐘塔" title="時鐘塔">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-2.json')" alt="時間厲行" title="時間厲行"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <div class="content-1">
        <p class="title-style" style="color: #267c68">與時鐘塔的相遇</p>
        <p class="content-style" style="margin-top: 0.5vw;">萬華龍山寺外有座隱世已久的石塔，臺史博團隊無意間看到網友討論此塔上的篆刻詞意，才發現這個時鐘塔與文協時代的關聯。經由龍山寺授權、現場測繪、歷史資訊的推論，再現時鐘塔1927 年初建時的樣貌，作為文協進行社會改造運動的精神指標。</p>
      </div>

      <div class="marquee">
        <vue3-marquee class="h-full">
          <img class="h-full" src="../assets/Unit4-1/20230415-4-1-物件-05-插畫2.png" alt="TEMPO ESTAS MONO" title="TEMPO ESTAS MONO">
        </vue3-marquee>
      </div>

    </div>

    <div class="part4 part-color">
      <img class="img-1" src="../assets/Unit4-1/20230415-4-1-物件-07-插畫3.png" alt="時鐘塔" title="時鐘塔">
      <img class="img-2" src="../assets/Unit4-1/20230415-4-1-物件-08-pic2.png" alt="時鐘塔" title="時鐘塔">
      <img class="img-3" src="../assets/Unit4-1/20230415-4-1-物件-09-pic3.png" alt="時鐘塔" title="時鐘塔">
      <div class="content-1">
        <p class="title-style" style="color: #267c68">「TEMPO ESTAS MONO」</p>
        <p class="source-style" style="margin-top: 0.3vw; margin-left: 1.2vw">周俊偉 提供</p>
        <p class="content-style" style="margin-top: 0.5vw; margin-left: 1.2vw">這座石塔上刻畫了「時間厲行」、「TEMPO ESTAS MONO」，是世界語「時間就是金錢」 之意。1970 年石塔經修整，將原本的日本紀年改為民國，並移置到廟埕右側水池後做為造景的一部分，後來草木遮掩，漸為人所遺忘。</p>
      </div>
    </div>

    <div class="part5 part-color">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-3.json')" alt="蘇璧輝" title="蘇璧輝"></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit4-1/20230415-4-1-物件-10-pic4.png" alt="蘇璧輝" title="蘇璧輝">
      <div class="img-2">
        <img class="w-full" src="../assets/Unit4-1/20230415-4-1-物件-11-pic5.png" alt="蘇家於時鐘塔修建落成典禮留念 1970年" title="蘇家於時鐘塔修建落成典禮留念 1970年">
        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #267c68">我們找到蘇璧輝了</p>
        <p class="source-style" style="margin-top: 0.3vw; ">鄭慧美 提供</p>
        <p class="content-style" style="margin-top: 0.5vw;">時鐘塔捐贈者蘇璧輝，為萬華當地商人、文協理事、世界語推廣者。資料搜集過程中，臺史博團隊遍尋不得其樣貌，透過一篇網路上紀念父親蘇璧輝的文章才連繫上其家屬。 </p>
        <p class="content-style" >蘇璧輝1937 年在廈門經商，捲入中日衝突，被國民政府殺害，家族後代因此為文紀念，希望能傳達他的故事。</p>
        <a class="blue-btn" style="margin-top: 1.2vw" href="http://www.eastgate.org.tw/f2/index.php?load=read&id=1169" target="_blank" alt="蘇碧輝的故事(另開新視窗)" title="蘇碧輝的故事(另開新視窗)" tabindex="0">蘇璧輝的故事</a>
      </div>
    </div>

    <div class="part6 part-color">
<!--      <img class="sample" src="../assets/Unit4-1/sample/20230703-對位-4-1-op-03.png">-->

      <img class="center-clock" src="../assets/Unit4-1/20230415-4-1-物件-14-pic6.png" alt="塔頂裝飾雕像之謎" title="塔頂裝飾雕像之謎">
      <div class="content-1">
        <p class="title-style" style="color: #267c68">塔頂裝飾雕像之謎</p>
        <p class="content-style" style="margin-top: 0.5vw;">仿製工作中，仍有許多細節無法定奪，如頂端雕像造型。1927年樹立時上面有個有翅膀的雕像，1970年代石塔被移置到廟埕外的水池，更換了上頭的雕刻為龍形，並填實了時鐘部分，不過目前艋舺龍山寺時鐘塔上的時鐘已不復存在。</p>
        <p class="content-style" >當時石塔上裝飾是某種鳥、是鳳凰、還是某個神像？至今對我們來說仍是個謎，您覺得石塔上的裝飾是什麼呢？</p>

<!--        <div class="option-div content-style">-->
<!--          <div class="option"><div></div><div style="margin-left: 0.8vw">鳥</div><div class="bar" style="width: 3vw"><div>30%</div></div></div>-->
<!--          <div class="option"><div></div><div style="margin-left: 0.8vw">龍</div><div class="bar" style="width: 21.5vw"><div>100%</div></div></div>-->
<!--          <div class="option"><div></div><div style="margin-left: 0.8vw">鳳凰</div><div class="bar" style="width: 14.2vw;margin-left: 2vw"><div>40%</div></div></div>-->
<!--          <div class="option"><div></div><div style="margin-left: 0.8vw">某神像</div><div class="bar" style="width: 14.2vw;margin-left: 1vw"><div>55%</div></div></div>-->
<!--          <div class="blue-btn">我選好了</div>-->
<!--          <div class="blue-btn hidden">看大家的想像</div>-->
<!--        </div>-->
      </div>

      <img class="ani-img " :class="aniImg[0]" style="width: 6.4vw; top: 28.2%; left: 22.9%;" src="../assets/Unit4-1/20230415-4-1-物件-22-鳥插畫４.png" alt="鳥插畫" title="鳥插畫">
      <img class="ani-img " :class="aniImg[1]" style="width: 6.5vw; top: 28.9%; left: 23%;" src="../assets/Unit4-1/20230415-4-1-物件-23-龍插畫５.png" alt="龍插畫" title="龍插畫">
      <img class="ani-img " :class="aniImg[2]" style="width: 6.4vw; top: 28.2%; left: 22.9%;" src="../assets/Unit4-1/20230415-4-1-物件-25-某個神像插畫７.png" alt="某個神像插畫" title="某個神像插畫">
      <img class="ani-img " :class="aniImg[3]" style="width: 6.4vw; top: 28.2%; left: 22.9%;" src="../assets/Unit4-1/20230415-4-1-物件-24-鳳凰插畫６.png" alt="鳳凰插畫" title="鳳凰插畫">

      <div class="circle-name" :style="circleStyle[0]">{{circleName}}</div>
      <div class="tip content-style">點選圓圈，看石塔的裝飾。</div>
      <div class="circle-div" :style="circleStyle[1]">
        <img class="relative w-full" src="../assets/Unit4-1/20230415-4-1-物件-15-大圓線-01.png">
          <div class="small-circle" :class="circleSelect[0]" style="top: 48%; left: -1.5%" @click="circleBtn(0)" @keydown.enter="circleBtn(0)" tabindex="0" alt="點選圓圈，看石塔的裝飾。" title="點選圓圈，看石塔的裝飾。"></div>
          <div class="small-circle" :class="circleSelect[1]" style="top: -1.5%; left: 48%" @click="circleBtn(1)" @keydown.enter="circleBtn(1)" tabindex="0" alt="點選圓圈，看石塔的裝飾。" title="點選圓圈，看石塔的裝飾。"></div>
        <div class="small-circle" :class="circleSelect[2]" style="top: 48%; right: -1.5%" @click="circleBtn(2)" @keydown.enter="circleBtn(2)" tabindex="0" alt="點選圓圈，看石塔的裝飾。" title="點選圓圈，看石塔的裝飾。"></div>
        <div class="small-circle" :class="circleSelect[3]" style="bottom: -1.5%; left: 48%" @click="circleBtn(3)" @keydown.enter="circleBtn(3)" tabindex="0" alt="點選圓圈，看石塔的裝飾。" title="點選圓圈，看石塔的裝飾。"></div>
      </div>
    </div>

    <div class="part7 part-color">
      <img class="img-1" src="../assets/Unit4-1/20230415-4-1-物件-30-裝飾字2-記憶之塔.png" alt="記憶之塔" title="記憶之塔">
      <div class="content-1 content-style">石塔佇立在龍山寺廟埕30多年，為廟埕增添現代意涵，時鐘塔出現在陳澄波的畫作中，也出現在80年後素人畫家謝招治的畫裡，象徵文協新時空的創生。</div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit4-1/20230415-4-1-物件-31-pic7.png" alt="陳澄波油畫《龍山寺》圖像" title="陳澄波油畫《龍山寺》圖像">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #ffffff">消失的《龍山寺》</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">陳澄波油畫《龍山寺》圖像</p>
        <p class="source-style" style="margin-top: 0.3vw">陳澄波文化基金會 提供</p>
        <p class="content-style" style="margin-top: 0.5vw;">陳澄波在1928年現場寫生油畫作品〈龍山寺〉，為受艋舺當地人聯合委託，完成後入選第二屆臺展， 獲得特選。後與黃土水作品「釋尊」並列於龍山寺會場。該油畫已下落不明，從當時畫冊中可以看到，畫面中央即是鐘塔。</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-4.json')" alt="陳澄波" title="陳澄波" ></LottieTest>
      </div>
    </div>

    <div class="part8 part-color">
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-1/20230415-4-1-物件-32-pic8.png" alt="《廟口的早晨》" title="《廟口的早晨》">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 5%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #ffffff"> 謝招治女士的畫作《廟口的早晨》</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">《廟口的早晨》</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2017.022.0002</p>
        <p class="content-style" style="margin-top: 0.5vw;">愛來臺史博的觀眾，肯定對謝招治阿嬤不陌生。1929年出生在艋舺龍山寺旁的她，65歲開始畫畫，用畫筆記錄她童年記憶中的臺灣。這幅《廟口的早晨》，紀錄的是她記憶中1940年前後的景象。</p>
        <p class="content-style">當時她的父親在廟旁擺攤賣油條，她常清晨4、5點起床幫忙，直到7點多才能去上學。招治阿嬤回憶：「小時候為了不想遲到，每到上學時刻，總會跟父親爭執。」</p>
        <p class="content-style">她記得清晨6點，龍山寺門上的收音機都會播放做早操的音樂，晨起運動的人在廟前做操，這時所有賣早餐的攤子都出來了……。她的時間記憶裡，這座時鐘塔不那麼高大、造型簡單，親切地站在畫面的焦點上。</p>
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-1/4-1-5.json')" alt="時鐘塔" title="時鐘塔"></LottieTest>
      </div>

      <div class="back-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-white.png" alt="回廟埕拚文化" title="回廟埕拚文化">
        <p style="margin-left: 0.5vw">回廟埕拚文化</p>
      </div>
    </div>

    <img class="clock" src="../assets/Unit4-1/20230415-4-1-物件-06-pic1.png" alt="時鐘塔" title="時鐘塔" :style="clockStyle">


    <!--寫這裡-->
    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--            <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-1/sample/20230703-對位-4-1-op-07.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:2.8vw 0 0 15vw" src="../assets/Unit4-1/20230703-4-1-op-11-pic5-放大文物.png" alt="蘇家於時鐘塔修建落成典禮留念 1970年" title="蘇家於時鐘塔修建落成典禮留念 1970年">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 21.3vw; width: 20.2vw;">
            <div >
              <div class="bold-title">蘇家於時鐘塔修建落成典禮留念 1970年</div>
              <div class="noto-text" style="margin-top: 0.3vw">鄭慧美 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--     <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-02.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:2.8vw 0 0 15vw" src="../assets/Unit4-1/20230703-4-1-op-31-pic7-放大文物.png" alt="陳澄波油畫《龍山寺》圖像" title="陳澄波油畫《龍山寺》圖像">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 24.3vw; width: 14.4vw;">
            <div >
              <div class="bold-title">陳澄波油畫《龍山寺》圖像</div>
              <div class="noto-text" style="margin-top: 0.3vw">陳澄波文化基金會 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--     <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-02.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:2.8vw 0 0 15vw" src="../assets/Unit4-1/20230703-4-1-op-32-pic8-放大文物.png" alt="《廟口的早晨》" title="《廟口的早晨》">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 22.3vw; width: 17.4vw;">
            <div >
              <div class="bold-title">《廟口的早晨》</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2017.022.0002 </div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>


    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import FooterFeature from "@/components/FooterFeature";
import LottieTest from "@/components/LottieTest";
import {Vue3Marquee} from "vue3-marquee";
import 'vue3-marquee/dist/style.css'
import {effect, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import myMixin from "@/assets/common/js/mixin";
export default {
  name: "Unit4-1",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    FooterFeature,
    LottieTest,
    Vue3Marquee
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));
    const clockStyle = ref('');
    const circleName = ref('');
    const circleSelect = reactive(Array.from({ length: 4 }, () => ''));
    const circleStyle = reactive(Array.from({ length: 2 }, () => ''));
    const aniImg = reactive(Array.from({ length: 4 }, () => ''));

    onMounted(()=>{
      window.addEventListener('scroll', handleScroll);


      aniImg[1] = "ani-img-in";
      circleSelect[1] = "selected";
      circleStyle[0] = 'opacity:1';
      circleName.value = "龍";

      setAnimInterval();
    });

    onUnmounted(()=>{
      clearAnimInterval();
    });

    function routeBtn(path){
      router.push(path);
    }

    let top = 106;
    let left = 40.1;
    let rotate = 0;
    let scale = 1;

    function  handleScroll() {
      const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; // 获取窗口高度
      const fullHeight = document.documentElement.scrollHeight || document.body.scrollHeight; // 获取页面总高度
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // 获取滚动距离
      let scrollPercentage = (scrollTop / (fullHeight - windowHeight)) * 100; // 计算滚动页面百分比

      let percent = 0;
      /*Rot*/
      if(scrollPercentage >= 30 && scrollPercentage <= 38){
        percent = (scrollPercentage - 30)/(38-30);
        rotate = (0+((30-0)*percent));
        scale = (1-((1-0.9)*percent));
      }else  if(scrollPercentage >= 38 && scrollPercentage <= 42){
        percent = (scrollPercentage - 38)/(42-38);
        rotate = (30-((30-0)*percent));
        scale = (0.9-((0.9-0.65)*percent));
      }else  if(scrollPercentage >= 50 && scrollPercentage <= 57){
        percent = (scrollPercentage - 50)/(57-50);
        rotate = (0+((0-12)*percent));
        scale = (0.65+((0.68-0.65)*percent));
      }

      /*Move*/
      if(scrollPercentage >= 30 && scrollPercentage <= 42){
        percent = (scrollPercentage - 30)/(42-30);
        top = (106+((165-106)*percent));
        left = (40.1-((40.1-9.1)*percent));
        clockStyle.value = 'top:'+ top + "vw; left:" + left +"vw; transform:rotate("+ rotate+"deg) scale("+scale + ")";
      } else if(scrollPercentage >= 50 && scrollPercentage <= 57){
        percent = (scrollPercentage - 50)/(57-50);
        top = (165+((199.7-165)*percent));
        left = (9.1+((20.4-9.1)*percent));
        clockStyle.value = 'top:'+ top + "vw; left:" + left +"vw; transform:rotate("+ rotate+"deg) scale("+scale + ")";
      }

      //console.log(scrollPercentage)
    }

    let preAniImg = 1;
    let nextDeg = 0;
    let nextName = '';

    function circleBtn(circleNum){
      clearAnimInterval();


      for (let i=0; i<4; i++){
        circleSelect[i] = "";
      }


      circleStyle[0] = 'opacity:0; ';
      setTimeout(()=>{
        circleStyle[0] = 'opacity:1';
        circleName.value = nextName;
        // if(nextDeg == -360){
        //   circleStyle[1] = "transform: rotate("+0+"deg);";
        // }
        setAnimInterval();
      },3000);

      if(preAniImg != -1){
        aniImg[preAniImg] = "ani-img-out";
        //preAniImg = -1;
      }

      switch (circleNum){
        case 0:
          nextName = "鳥";
          circleSelect[0] = "selected";
          aniImg[0] = "ani-img-in";
          preAniImg = 0;
          nextDeg = -270;
          break;
        case 1:
          nextName = "龍";
          circleSelect[1] = "selected";
          aniImg[1] = "ani-img-in";
          preAniImg = 1;
          nextDeg = 0;
          break;
        case 2:
          nextName = "某個神像";
          circleSelect[2] = "selected";
          aniImg[2] = "ani-img-in";
          preAniImg = 2;
          nextDeg = -90;
          break;
        case 3:
          nextName = "鳳凰";
          circleSelect[3] = "selected";
          aniImg[3] = "ani-img-in";
          preAniImg = 3;
          nextDeg = -180;
          break;

      }

      //circleStyle[1] = "transform: rotate("+nextDeg+"deg);";
      targetAngle.value = nextDeg;
      updateRotation(previousAngle.value, targetAngle.value);
    }

    const rotationAngle = ref(0);
    const previousAngle = ref(0); // 上一次的旋转角度
    const targetAngle = ref(-90); // 目标逆时针旋转的角度
    const animationDuration = 3000; // 动画持续时间，单位为毫秒

    let animInterval = null;

    function clearAnimInterval(){
      clearInterval(animInterval);
      animInterval = null;
    }

    function setAnimInterval(){

      clearAnimInterval();

      if(animInterval == null){
        animInterval  = setTimeout(()=>{
          let nextBtn = (preAniImg+1)%4;
          circleBtn(nextBtn);
        }, 5000);
      }
    }


    const updateRotation = (startAngle, endAngle) => {
      const startTime = performance.now();
      const clockwise = startAngle < endAngle; // 判断是否为顺时针旋转

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / animationDuration, 1);
        let angle = lerp(startAngle, endAngle, progress, clockwise);

        rotationAngle.value = angle;
        circleStyle[1] = "transform: rotate("+angle+"deg);";
        previousAngle.value = angle;

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    };

    const lerp = (start, end, amount, clockwise) => {
      if (clockwise) {
        // 如果为顺时针旋转，则取反角度差，以确保逆时针旋转
        end -= 360;
      }

      return (1 - amount) * start + amount * end;
    };

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      clockStyle,
      circleBtn,
      circleName,
      circleSelect,
      circleStyle,
      aniImg,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit4-1.css"></style>
