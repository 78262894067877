<template>
  <div class="home-wrap">
<!--    <toolbar type="-1"></toolbar>-->

    <div>
      <menubar-comp color="white"></menubar-comp>
    </div>

    <div style="position: relative">
      <img class="logo" src="../assets/home/logo.png" alt="樂為世界人" title="樂為世界人">
      <div class="word"><LottieOnce :json="word" ></LottieOnce></div>

      <model-viewer
          tabindex="-1" alt="地球儀" title="地球儀"
          src="/Globe2.glb"
          autoplay
      ></model-viewer>


      <img class="map" src="../assets/home/map.png" alt="地圖" title="地圖">

      <img class="png-anim anim-fade" :src="currentFrame"  />
      <img class="pic-1" src="../assets/home/pic-1.png" alt="照片" title="照片">
      <img class="pic-2" src="../assets/home/pic-2.png" alt="照片" title="照片">
      <div class="electric"><LottieTest :json="electric" ></LottieTest></div>
      <img class="pic-3" src="../assets/home/pic-3.png" alt="照片" title="照片">
      <div class="speak anim-fade"><LottieTest :json="speak" ></LottieTest></div>
      <img class="pic-4 anim-fade" src="../assets/home/pic-4.png" alt="照片" title="照片">
      <img class="pic-5" src="../assets/home/pic-5.png" alt="照片" title="照片">
      <div class="drama"><LottieTest :json="drama" ></LottieTest></div>
      <img class="pic-6" src="../assets/home/pic-6.png" alt="飛機" title="飛機">
      <div class="timetower"><LottieTest :json="timetower" ></LottieTest></div>

      <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
      <div class="unit1-btn btn-scale"><img @click="routeBtn('/unit1-1')" @keydown.enter="routeBtn('/unit1-1')"  tabindex="0" class="w-full unit-btn" src="../assets/home/unit1-btn.png" alt="鳥瞰世界" title="鳥瞰世界"></div>
      <div class="unit2-btn btn-scale"><img @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/unit2-0')"  tabindex="0" class="w-full unit-btn" src="../assets/home/unit2-btn.png" alt="文協向前衝" title="文協向前衝"></div>
      <div class="unit3-btn btn-scale"><img @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0" class="w-full unit-btn" src="../assets/home/unit3-btn.png" alt="廟埕拼文化" title="廟埕拼文化"></div>
      <div class="unit4-btn btn-scale"><img @click="routeBtn('/unit6-2')" @keydown.enter="routeBtn('/unit6-2')" tabindex="0" class="w-full unit-btn" src="../assets/home/unit4-btn.png" alt="番外篇" title="番外篇"></div>

<!--      <video id="video"  class="w-full"  autoplay muted playsinline>-->
<!--        <source src="../assets/home/video.mp4"-->
<!--                type="video/mp4">-->
<!--      </video>-->

<!--      <div v-if="showBtn" class="w-full ">-->
<!--        <div class="test-btn btn-1" @click="routeBtn('/unit1-1')"><img src="../assets/home/enter-btn-1.png"></div>-->
<!--        <div class="test-btn btn-2" @click="routeBtn('/unit2-1')"><img src="../assets/home/enter-btn-2.png"></div>-->
<!--        <div class="test-btn btn-3" @click="routeBtn('/enter')"><img src="../assets/home/enter-btn-3.png"></div>-->
<!--        <div class="test-btn btn-4" @click="routeBtn('/unit6-2')"><img src="../assets/home/enter-btn-4.png"></div>-->
<!--      </div>-->



    </div>

    <intro-btn></intro-btn>

    <FooterFeature color="white"></FooterFeature>




<!--    <div class="test-btn btn-5" @click="routeBtn('/person-intro-all')"></div>-->
  </div>

<!--  <img class="sample" src="../assets/common/sample2.png">-->
</template>

<script>


import {nextTick, onBeforeUnmount, ref} from "vue";
import router from "@/router";
import toolbar from "@/components/Toolbar";
import IntroBtn from "@/components/IntroBtn";
import FooterFeature from "@/components/FooterFeature";
import LottieOnce from "@/components/LottieOnce";
import LottieTest from "@/components/LottieTest";
import { ModelViewer } from '@google/model-viewer';
import MenubarComp from "@/components/MenubarComp";

export default {
  name: 'HomeView',
  components: {
    MenubarComp,
    LottieTest,
    toolbar,
    IntroBtn,
    FooterFeature,
    LottieOnce,
    ModelViewer,
  },
  setup(){

    const word = require('@/assets/home/word.json');
    const electric = require('@/assets/home/Electric.json');
    const speak = require('@/assets/home/speak.json');
    const drama = require('@/assets/home/drama.json');
    const timetower = require('@/assets/home/timetower.json');
    const currentFrame = ref(null);
    let pngAnimIndex = 0;
    let intervalId = null;

    /*調整css變數
    const root = document.documentElement;
    root.style.setProperty('--font-size', '5');
     */

    if(sessionStorage.getItem('loading') != '1'){
      router.push('/loading');
      return;
    }


    const showBtn = ref(false);


    init();

    async function init(){
      await nextTick();

      document.getElementById('video').addEventListener('ended',()=>{
        showBtn.value = true;
      });

      setTimeout(()=>{
        window.addEventListener('click', function (){
          document.getElementById('video').currentTime = 46;
        })
      }, 1000);
    }

    function openUrl(url, type){
      window.open(url, type);
    }

    function routeBtn(path){
      router.push(path);
    }


    intervalId = setInterval(nextFrame, 32);

    function nextFrame() {
      // Cycle through the frames
      pngAnimIndex = (pngAnimIndex + 1) % 180;
      currentFrame.value = require('@/assets/home/png-anim/20230330-臺史博-文協線上展-物件動態-李應章'+ pngAnimIndex.toString().padStart(4, '0') +'.png');

      if(pngAnimIndex >= 179){
        clearInterval(intervalId);
      }
    }

    onBeforeUnmount(()=>{
      clearInterval(intervalId);
    });

    return{
      openUrl,
      showBtn,
      routeBtn,
      word,
      electric,
      speak,
      drama,
      timetower,
      currentFrame,
    }
  }

}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Home.css"></style>

<!--<style scoped>-->
<!--.sample{-->
<!--  position: absolute;-->
<!--  top: 0;-->
<!--  left: 0;-->
<!--  width: 100%;-->
<!--  opacity: 0.3;-->
<!--}-->

<!--.test-btn{-->
<!--  position: absolute;-->
<!--  /*background: black;*/-->
<!--  /*opacity: 0.5;*/-->
<!--  cursor: pointer;-->
<!--}-->

<!--.btn-1{-->
<!--  top: 29.5vw;-->
<!--  left: 8%;-->
<!--  width: 19vw;-->
<!--  height: 10vw;-->
<!--}-->

<!--.btn-1 img{-->
<!--  position: absolute;-->
<!--  top: -8%;-->
<!--  left: 38%;-->
<!--  width: 26.5%;-->
<!--  opacity: 0;-->
<!--  transition: opacity 0.3s;-->
<!--}-->

<!--.btn-1:hover img{-->
<!--  opacity: 1;-->
<!--}-->

<!--.btn-2{-->
<!--  top: 27vw;-->
<!--  left: 30%;-->
<!--  width: 32vw;-->
<!--  height: 15vw;-->
<!--}-->

<!--.btn-2 img{-->
<!--  position: relative;-->
<!--  top: -37%;-->
<!--  left: 49%;-->
<!--  width: 20.6%;-->
<!--  opacity: 0;-->
<!--  transition: opacity 0.3s;-->
<!--}-->

<!--.btn-2:hover img{-->
<!--  opacity: 1;-->
<!--}-->

<!--.btn-3{-->
<!--  top: 10vw;-->
<!--  left: 74%;-->
<!--  width: 22vw;-->
<!--  height: 16vw;-->
<!--}-->

<!--.btn-3 img{-->
<!--  position: absolute;-->
<!--  top: -3%;-->
<!--  left: 70%;-->
<!--  width: 29.9%;-->
<!--  opacity: 0;-->
<!--  transition: opacity 0.3s;-->
<!--}-->

<!--.btn-3:hover img{-->
<!--  opacity: 1;-->
<!--}-->

<!--.btn-4{-->
<!--  top: 28vw;-->
<!--  left: 75%;-->
<!--  width: 8vw;-->
<!--  height: 7vw;-->
<!--}-->

<!--.btn-4 img{-->
<!--  position: absolute;-->
<!--  top: -38%;-->
<!--  left: 67%;-->
<!--  width: 63%;-->
<!--  opacity: 0;-->
<!--  transition: opacity 0.3s;-->
<!--}-->

<!--.btn-4:hover img{-->
<!--  opacity: 1;-->
<!--}-->

<!--.btn-5{-->
<!--  top: 42vw;-->
<!--  left: 94%;-->
<!--  width: 4vw;-->
<!--  height: 13vw;-->
<!--}-->




<!--</style>-->


