<template>
  <div class="wrap" @keydown.esc="escEvent">
    <Entoolbar type="1" unit="2" route-name="unit2-2" color="orange"></Entoolbar>

<!--    <img class="sample" src="../assets/Unit2-2/sample2.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">

      <img class="pic-1" src="../assets/Unit2-2/20230407-2-2-物件-op-01-首圖１.png">

      <div class="people-2" >
        <LottieTest :json="require('@/assets/Unit2-2/2-2-1.json')" ></LottieTest>
      </div>

      <img class="pic-2" src="../assets/Unit2-2/20230407-2-2-物件-op-02-首圖2.png">

      <div class="people-1" >
        <LottieTest :json="require('@/assets/Unit2-2/2-2-0.json')" ></LottieTest>
      </div>
      <img class="pic-3" src="../assets/Unit2-2/20230407-2-2-物件-op-03-首圖3.png">
      <div class="title-logo"><p>Advocating for</p><p> Farmers and Workers</p></div>

    </div>

    <div class="part2 part-color">
      <div class="content-1">
        <img style="width: 55%" src="../assets/Unit2-2/en/20230706-EN網站-2-2-物件-04-裝飾字1-蔗農.png" alt="Sugarcane Farmers" title="Sugarcane Farmers">
        <p class="en-bar" style="height: 8vw; overflow: auto">In October 1925, there was a clash between sugarcane farmers and police in Erlin, Changhua. Li Ying-cheung, a member of the Taiwan Culture Association, Zhan Yi-hou and Liu Song-fu — organizers of the farmers’ association — were arrested. Disputes over land policy, farmers’ livelihoods, oppression, and class exploitation suddenly became the focus of these movements.</p>
        <a href="https://youtu.be/yyaFposN27Q" target="_blank" tabindex="0" title="Taiwan Bar Erhlin Peasants Movement Video Introduction(open in a new window)" alt="Taiwan Bar Erhlin Peasants Movement Video Introduction(open in a new window)">
          <img class="video-btn" src="../assets/Unit2-2/en/20230706-EN網站-2-2-物件-06-臺灣吧按鈕-藍.png">
        </a>
      </div>

      <div class="content-2">
        <img style="height: 17.7vw" src="../assets/Unit2-2/20230407-2-2-物件-op-07-pic1.png" title="Lin Ben Yuan Sugar Corporation stock certificate" alt="Lin Ben Yuan Sugar Corporation stock certificate">
        <div style="margin-left: 2.5vw">
          <p class="title-style">Lin Family of Banqiao Heads South to Produce Sugar</p>
          <p class="subtitle-style">Lin Ben Yuan Sugar Corporation stock certificate</p>
          <p class="source-style" style="margin-top: 0.5vw">Courtesy of Chiang Wei-shui Cultural Foundation</p>
          <p class="content-style" style="margin-top: 0.5vw">Spurred by the Governor-General's Office's promotion of the sugar industry, the well-known, prosperous  Banqiao Lin family established the Lin Ben Yuan Sugar Corporation in Xizhou Village, Beidou County (now Xizhou Township). The company's sugarcane purchase price in Erlin was lower than in neighboring regions, so local farmers refused to harvest their crops, which led to conflicts with the police.</p>
        </div>
        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 14%; right: 63.5%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <img class="absolute" style="width: 20vw; top: 10.5%; right: 0%;" src="../assets/Unit2-2/20230407-2-2-物件-op-05-插圖1.png">
      <div class="people-1" >
        <LottieTest :json="require('@/assets/Unit2-2/2-2-2.json')" alt="a clash between sugarcane farmers and police" title="a clash between sugarcane farmers and police"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <div class="content-1">
        <img style="height: 24.6vw" src="../assets/Unit2-2/20230407-2-2-物件-op-09-pic2.png" title="Taiwan Min Pao, Volume 122" alt="Taiwan Min Pao, Volume 122">
        <div style="margin-left: 1.5vw">
          <p class="title-style">Verdict Reached!</p>
          <p class="subtitle-style"><i>Taiwan Min Pao</i>, Volume 122</p>
          <p class="source-style" style="margin-top: 0.5vw">National Museum of Taiwan History 2019.011.0002</p>
          <p class="content-style" style="margin-top: 0.5vw">Many people arrested in the Erlin Incident were sentenced to prison terms. On September 20, 1926, <i>Taiwan Min Pao</i> published a special issue on the initial trial results and events surrounding the Erlin Incident. The incident attracted many socially-concerned young people to join the Taiwan Cultural Association and farmers' associations, which spurred reorganization of the Taiwan Cultural Association and pushed its politics to the left.</p>
        </div>
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 6%; right: 72%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>

      <div class="content-2">
        <img style="height: 23.8vw" src="../assets/Unit2-2/20230407-2-2-物件-op-10-pic3.png" title="Group photo of the second Erlin Incident public trial" alt="Group photo of the second Erlin Incident public trial">
        <transition name="img-frame-fade"><img v-if="showName" class="name-line h-full" src="../assets/Unit2-2/en/EN_ALL.png" alt="Names of farmers" title="names of farmers"></transition>
        <div style="margin-left: 2.5vw; margin-top: -0.7vw">
          <p class="title-style">Farmers: The Central Figures of the Movement!</p>
          <p class="subtitle-style relative">Group photo of the second Erlin Incident public trial<img class="detail-btn" @click="showName=!showName" @keydown.enter="showName=!showName" tabindex="0" src="../assets/Unit2-2/en/20230706-EN網站-2-2-物件-23-細讀照片按鈕-藍.png" title="Examine the Photo Carefully" alt="Examine the Photo Carefully"></p>
          <p class="source-style" style="margin-top: 0.5vw">Courtesy of Oishi Susumi</p>
          <p class="content-style" style="margin-top: 0.5vw; height:15vw; overflow: auto">This group photo is representative of those taken during the second trial of the "Erlin Sugarcane Farmers Incident". In the past, only the intellectual elite such as doctors and lawyers in the photo had been identified. In recent years, following intensive field work, more front-line farmers in the resistance can now be identified. A unique feature of this photograph is that elites such as doctors and lawyers are in the back, while those at the front are the farmers and activists who had stood on the front lines of the incident. This arrangement is quite different from previous research into the Cultural Association that focused mostly on social elites.</p>
        </div>

      </div>
      <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 9%; right: 39.5%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
    </div>

    <div class="part4 part-color">
      <div class="content-1">
        <img style="width: 45vw;margin-left: -0.7vw" src="../assets/Unit2-2/en/20230706-EN網站-2-2-物件-25-裝飾字2-無產青年.png" alt="Proletarian Youth" title="Proletarian Youth">
        <p>From 1923, the Taiwan Cultural Association began to formulate left-wing ideas. It operated in the form of study clubs and reading clubs. Youth organizations also paid attention to issues of social inequality. The Governor-General’s forces confronted the related movements. In June 1926, the youth group proposed the “Public Statement of Proletarian Youth” in <i>Taiwan Min Pao</i>, criticizing the Governor-General’s Office of Taiwan for its negative attitude toward left-wing movements. The struggle between left and right came to the fore.</p>
      </div>

      <div class="content-2">
        <img style="width: 30.5vw" src="../assets/Unit2-2/20230407-2-2-物件-op-26-pic4.png" title="“An Open Letter From the Proletarian Youth” Taiwan Min Pao, Volume 116" alt="“An Open Letter From the Proletarian Youth” Taiwan Min Pao, Volume 116">
        <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom:9%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>

      <div class="content-3">
        <img style="width:22.2vw; margin: 4.5vw 0 0 39%" src="../assets/Unit2-2/20230407-2-2-物件-op-28-pic6.png">
        <img class="zoom-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 1.9vw; bottom: 20%; right: -28%" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
        <div class="people absolute top-0" style="width: 13.5vw">
          <LottieTest :json="require('@/assets/Unit2-2/2-2-4.json')" alt="Youth" title="Youth" ></LottieTest>
        </div>
      </div>

      <div class="content-4">
        <p class="title-style" style="color: #ea5518; width: 120%">Who Are The Proletarian Youth?</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">“An Open Letter From the Proletarian Youth” <i>Taiwan Min Pao</i>, Volume 116</p>
        <p class="source-style" style="margin-top: 0.3vw"><i>Taiwan Min Pao</i> No.116</p>
        <p class="content-style" style="margin-top: 0.5vw;">The article clarifies that the proletarian youth are not an organization nor are they affiliated with the Taiwan Cultural Association. Instead, it is a set of beliefs and self-identification. The letter further emphasizes that proletarian youth’s viewpoints differ from those of the Taiwan Cultural Association and the two should not be conflated. It can be interpreted as a satirical critique of the organization. </p>
      </div>

      <div class="content-5">
        <img class="w-full" src="../assets/Unit2-2/20230407-2-2-物件-op-27-pic5.png" title="Welcome ceremony for representatives of the Taiwan Workers' Federation" alt="Welcome ceremony for representatives of the Taiwan Workers' Federation"/>
        <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.2vw; bottom: 8%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>

      <div class="content-6">
        <p class="title-style" style="color: #ea5518">Unity Among Compatriots Is Powerful</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Welcome ceremony for representatives of the Taiwan Workers' Federation</p>
        <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan Literature Donated by Lin Zhang-feng</p>
        <p class="content-style" style="margin-top: 0.5vw;">On February 19, 1928, the first island-wide labor union organization in Taiwan's history, the Taiwan Workers' Federation, was founded. Taiwan Shin Min Pao referred to Lu Bing-ding (third row, wearing sunglasses) as the “big brother” of the labor movement, while Chiang Wei-shui was called the “midwife,” highlighting their contributions to the labor movement. Chiang Wei-shui famous quote, “Compatriots must unite; unity is power,” was also made into a banner for the event.</p>
      </div>

    </div>

    <div class="part5 part-color">
      <div class="unit-btn" style="top: 23%; left: 12%; " @click="routeBtn('/en/unit2-0')" @keydown.enter="routeBtn('/en/unit2-0')" tabindex="0">
        <img class="w-full"  src="../assets/Unit2-0/文協向前衝按鈕.png" alt="The Taiwan Cultural Association Forges Ahead" title="The Taiwan Cultural Association Forges Ahead">
        <p>The Taiwan Cultural</p>
        <p>Association Forges Ahead</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 38.5%; ">
        <img class="w-full" src="../assets/Unit2-0/臺灣人的臺灣按鈕.png" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0">
        <p>Taiwanese People’s</p>
        <p>Taiwan</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 65%; ">
        <img class="w-full"  src="../assets/Unit2-0/20230724-2-2-物件-32-為農工大眾主張按鈕-淡.png" alt="Advocating for Farmers and Workers" title="Advocating for Farmers and Workers" @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" tabindex="0">
        <p>Advocating for</p>
        <p>Farmers and Workers</p>
      </div>
      <div class="unit-btn" style="top: 57.5%; left: 25%; ">
        <img class="w-full" src="../assets/Unit2-0/左派與右派按鈕.png" alt="The Left and The Right" title="The Left and The Right" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0">
        <p>The Left and The Right</p>
      </div>
      <div class="unit-btn" style="top: 57.5%; left: 52%; ">
        <img class="w-full" src="../assets/Unit2-0/政治結社的走向按鈕.png" alt="The Trend of Political Association" title="The Trend of Political Association" @click="routeBtn('/en/unit2-4')" @keydown.enter="routeBtn('/en/unit2-4')" tabindex="0">
        <p>The Trend of</p>
        <p>Political Association</p>
      </div>
    </div>

    <!--img0-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-032.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:38vw; margin:2.5vw 0 0 12.8vw" src="../assets/Unit2-2/20230703-2-2-物件-07-pic1-放大文物.png" alt="Lin Ben Yuan Sugar Corporation stock certificate" title="Lin Ben Yuan Sugar Corporation stock certificate">
          </div>

          <div class="content-text">
            <div class="bold-title">Lin Ben Yuan Sugar Corporation stock certificate</div>
            <div class="noto-text" >Courtesy of Chiang Wei-shui Cultural Foundation</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-042.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:20.5vw; margin:1vw 0 0 21.7vw" src="../assets/Unit2-2/20230703-2-2-物件-09-pic2-放大文物.png" alt="Taiwan Min Pao, Volume 122" title="Taiwan Min Pao, Volume 122">
          </div>

          <div class="content-text">
            <div class="bold-title"><i>Taiwan Min Pao</i>, Volume 122</div>
            <div class="noto-text" >National Museum of Taiwan History 2019.011.0002</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-052.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:33.5vw; margin:2.5vw 0 0 15vw" src="../assets/Unit2-2/20230703-2-2-物件-10-pic3-放大文物.png" alt="Group photo of the second Erlin Incident public trial" title="Group photo of the second Erlin Incident public trial">
            <transition name="img-frame-fade"><img v-if="showName" class="name-line" style="position:absolute; top: 6%; left: 23%; width: 34vw;" src="../assets/Unit2-2/en/EN_ALL.png"></transition>
          </div>

          <div class="content-text">
            <div class="bold-title">Group photo of the second Erlin Incident public trial</div>
            <div class="noto-text" >Courtesy of Oishi Susumi</div>
          </div>

          <img class="detail-btn" style="position: absolute; top:85%; left: 85%;" @click="showName=!showName" src="../assets/Unit2-2/en/20230706-EN網站-2-2-物件-23-細讀照片按鈕-藍.png" title="Examine the Photo Carefully" alt="Examine the Photo Carefully">

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-072.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:33.5vw; margin:2.5vw 0 0 15vw" src="../assets/Unit2-2/20230703-2-2-物件-26-pic4-放大文物.png" alt="“An Open Letter From the Proletarian Youth” Taiwan Min Pao, Volume 116" title="“An Open Letter From the Proletarian Youth” Taiwan Min Pao, Volume 116">
          </div>

          <div class="content-text">
            <div class="bold-title">“An Open Letter From the Proletarian Youth” <i>Taiwan Min Pao</i>, Volume 116</div>
            <div class="noto-text" ><i>Taiwan Min Pao</i> No.116</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-082.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:33.5vw; margin:4.5vw 0 0 3.6vw" src="../assets/Unit2-2/20230703-2-2-物件-28-pic6-放大文物.png" alt="Group Photo of Tainan Wood Workers' Association and Other International Labor Day Participants" title="Group Photo of Tainan Wood Workers' Association and Other International Labor Day Participants">
          </div>

          <div class="content-text-2">
            <div class="bold-title">Group Photo of Tainan Wood Workers' Association and Other International Labor Day Participants </div>
            <div class="noto-text">National Museum of Taiwan Literature Donated by Lin Zhang-feng</div>
            <div class="sub-title" style="text-align: left">Labor Day, celebrated on May 1st, is a significant holiday for the labor movement. It was customary for the Taiwan Cultural Association and labor unions to organize large-scale events, symbolizing the unity and spirit of the workers.
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-2/sample/20230407-對位-2-2-op-06.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:2.5vw 0 0 15vw" src="../assets/Unit2-2/20230703-2-2-物件-27-pic5-放大文物.png" alt="Welcome ceremony for representatives of the Taiwan Workers' Federation" title="Welcome ceremony for representatives of the Taiwan Workers' Federation">
          </div>

          <div class="content-text">
            <div class="bold-title">Welcome ceremony for representatives of the Taiwan Workers' Federation</div>
            <div class="noto-text" >National Museum of Taiwan Literature Donated by Lin Zhang-feng</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>

    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language='en' color="black"></FooterFeature>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn";
import {reactive, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";

export default {
  name: "Unit2-2",
  mixins: [myMixin],
  components:{
    EnIntroBtn,
    Entoolbar,
    LottieTest,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();

    const audio = ref();
    const showImg = reactive(Array.from({ length: 10 }, () => false));
    const showName = ref(false);

    function routeBtn(path){
      router.push(path);
    }

    function playVoice(){
      if(!audio.value.paused){
        audio.value.pause();
      }else{
        audio.value.play();
      }
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      playVoice,
      audio,
      showImg,
      showName,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit2-2.css"></style>
