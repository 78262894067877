<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="1" unit="3" route-name="unit3-3" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit3-3/sample/202304123-3-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="title-logo">言論機關</div>
      <img class="img-1" src="../assets/Unit3-3/20230412-3-3-物件-01-言論機關.png" alt="言論機關" title="言論機關">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-0.json')" alt="言論機關" title="言論機關" ></LottieTest>
      </div>

    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit3-3/20230412-3-3-物件-02-裝飾字1-會報.png" alt="會報" title="會報">
      <div class="content-1 content-style">
        <p>文化協會創辦初期，曾發行有《會報》，蔣渭水的著名文章〈臨床講義〉，便刊載於第一期，然而發展兩年多來，至少發行過四期，但各期都受到官方嚴厲的行政處分，1923年之後便改以《臺灣民報》兼作機關報，來發布文化協會的消息，持續至1927年改組為止。</p>
        <p>1923年4月創立的《臺灣民報》成立，以使用中國白話文為主，成為新知識份子發表議論的園地，號稱「臺灣人的唯一言論機關」，而後自月刊逐步發展為旬報、週刊。</p>
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-03-插圖1.png" alt="臺灣之文化" title="臺灣之文化">
        <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2vw; top: 12%; left: 26%" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-1.json')" alt="插圖" title="插圖"></LottieTest>
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-05-pic2.png" alt="《臺灣民報》發行年曆廣告" title="《臺灣民報》發行年曆廣告">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        <div class="source ">
          <p class="subtitle-style">《臺灣民報》發行年曆廣告</p>
          <p class="source-style">國立臺灣歷史博物館 館藏 2006.003.0139</p>
        </div>
      </div>
      <img class="paper-1" src="../assets/Unit3-3/20230412-3-3-物件-07-插圖2.png" alt="插圖" title="插圖">
      <img class="paper-2" src="../assets/Unit3-3/20230412-3-3-物件-08-插圖3.png"  alt="插圖" title="插圖">
    </div>

    <div class="part3 part-color">
      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-09-pic3.png" alt="臺灣民報發行所（開設於蔣渭水開設的大稻埕大安醫院旁）" title="臺灣民報發行所（開設於蔣渭水開設的大稻埕大安醫院旁）">
        <img class="zoom-2-btn" @click="showImg[11]=true" @keydown.enter="showImg[11]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #ffffff">突破一萬份！</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺灣民報發行所（開設於蔣渭水開設的大稻埕大安醫院旁）</p>
        <p class="source-style" style="margin-top: 0.3vw">蔣渭水文化基金會 提供</p>
        <p class="content-style" style="margin-top: 0.5vw;">1925年1月《臺灣民報》發送實況。這年8月《臺灣民報》特別製作「發行五週年及發行量突破一萬份」紀念專刊，是當時臺灣發行量第三大的報刊，這張照片在於彰顯當時發派出去的榮景。</p>
      </div>
    </div>

    <div class="part4">
      <img class="half-top" src="../assets/Unit3-3/20230412-3-3-物件-10-插圖4.png" alt="插圖" title="插圖">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-2.json')" alt="插圖" title="插圖"></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit3-3/20230412-3-3-物件-11-pic4.png" alt="臺灣文化協會演講團於新竹的民報批發處前留影1925年6月7日" title="臺灣文化協會演講團於新竹的民報批發處前留影1925年6月7日">
      <img class="zoom-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; top: 13%; right: 7%" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
      <div class="section-1 part-color">
        <img class="logo-text" src="../assets/Unit3-3/20230412-3-3-物件-13-裝飾字2-大眾時報.png" alt="大眾時報" title="大眾時報">
        <div class="content-1 content-style">左傾後的文化協會，活動不常見於大眾媒體，機關報也常受到嚴厲的審駁，1928年3月在東京成立「大眾時報社」做為總部發行《臺灣大眾時報》、1931年發行  《新臺灣大眾時報》作為機關雜誌，報導罷工新聞、宣揚階級思想，內容益形激進，在臺灣成為地下流通的刊物。「言論機關」的紛陳，反映當時社會運動的路線歧異。</div>
        <div class="img-1">
          <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-14-pic4.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 23%; right: 8%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
        <div class="content-2">
          <p class="title-style" style="color: #267c68">創刊號被蹂躪！</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">《臺灣大眾時報》</p>
          <p class="source-style" style="margin-top: 0.3vw">南天書局復刻出版</p>
          <p class="content-style" style="margin-top: 0.5vw;">於東京發行創刊號，運至臺灣時卻被押收並遭警察「割銷不妥（？）文字」，可見當時總督府查禁相關刊物的嚴厲。</p>
        </div>

        <transition name="img-frame-fade"><img v-if="part4ImgIndex>0" class="arrow-btn" @click="part4ArrowBtn(-1)" @keydown.enter="part4ArrowBtn(-1)" tabindex="0" style="width:2.5vw; top: 24%; left: 58.5%" src="../assets/common/left-arrow.png" alt="向左" title="向左"></transition>
        <transition  name="img-frame-fade">
          <div class="img-2" v-if="part4Img[0]==true">
          <img class="h-full" src="../assets/Unit3-3/20230412-3-3-物件-15-pic5.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          <img class="zoom-2-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 2.2vw; bottom: 9%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
        </transition>
        <transition  name="img-frame-fade">
        <div class="img-3" v-if="part4Img[1]==true">
          <img class="h-full" src="../assets/Unit3-3/20230412-3-3-物件-16-pic6.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.2vw; bottom: 9%; right: 10%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
        </transition>
        <transition  name="img-frame-fade">
        <div class="img-4" v-if="part4Img[2]==true">
          <img class="h-full" src="../assets/Unit3-3/20230412-3-3-物件-17-pic７.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          <img class="zoom-2-btn" @click="showImg[6]=true" @keydown.enter="showImg[6]=true" tabindex="0" style="width: 2.2vw; bottom: 9%; right: 10%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
        </transition>
        <transition  name="img-frame-fade">
          <div class="img-4" v-if="part4Img[3]==true">
            <img class="h-full" src="../assets/Unit3-3/20230412-3-3-物件-18-pic8.png" alt="臺灣大眾時報" title="臺灣大眾時報">
            <img class="zoom-2-btn" @click="showImg[7]=true" @keydown.enter="showImg[7]=true" tabindex="0" style="width: 2.2vw; bottom: 9%; right: 9%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
          </div>
        </transition>
        <div class="content-3">
          <p class="title-style" style="color: #267c68">地下流通</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">《臺灣大眾時報》及《新臺灣大眾時報》之封面</p>
          <p class="source-style" style="margin-top: 0.3vw">南天書局復刻出版</p>
          <p class="content-style" style="margin-top: 0.5vw;">文化協會在1928年後發行的《臺灣大眾時報》及《新臺灣大眾時報》，封面圖像及內容遣詞皆具有濃厚的左派色彩。</p>
        </div>
        <transition name="img-frame-fade"><img v-if="part4ImgIndex<3" class="arrow-btn" @click="part4ArrowBtn(1)" @keydown.enter="part4ArrowBtn(1)" tabindex="0" style="width:2.5vw; top: 24%; left: 88.5%" src="../assets/common/right-arrow.png" alt="向右" title="向右"></transition>
      </div>
    </div>

    <div class="part5 part-color">


      <div class="ver-text-1" style="top: 20%; left: 13.6%">
        <div>思</div>
        <div>想</div>
        <div>的</div>
        <div>自</div>
        <div>由</div>
      </div>
      <div class="ver-text-1" style="top: 28.6%; left: 23.3%">
        <div><span v-if="gameStep!=2">限</span><span v-if="gameStep==2">&ensp;</span></div>
        <div><span v-if="gameStep!=2">制</span><span v-if="gameStep==2">&ensp;</span></div>
        <div>我</div>
        <div>們</div>
        <div>大</div>
        <div>眾</div>
        <div>的</div>
      </div>
      <div class="ver-text-2" style="top: 10.3%; left: 30%">
        <div>就</div>
        <div>是</div>
      </div>
      <div class="ver-text-2" style="top: 17.3%; left: 67%">
        <div>出</div>
        <div>版</div>
        <div>法</div>
        <div>外</div>
      </div>
      <div class="ver-text-1" style="top: 21.6%; left: 76.7%">
        <div>新</div>
        <div>聞</div>
        <div>紙</div>
        <div>法</div>
      </div>
      <div class="ver-text-2" style="top: 15%; left: 86.5%">
        <div>關</div>
        <div>於</div>
        <div>臺</div>
        <div>灣</div>
        <div><span v-if="gameStep!=2">的</span><span v-if="gameStep==2">&ensp;</span></div>
        <div>法</div>
      </div>

      <transition name="img-frame-fade" >
        <div class="circle" v-if="gameStep==0">
          <div>
            <p>OOXX填填看</p>
          </div>
          <div>
            <p>報紙要出版了，但還缺一段文字，邀請您來寫稿吧！在空白處寫下您的文字。</p>
          </div>
          <div class="play-btn" @click="gameStep=1" @keydown.enter="gameStep=1" tabindex="0" alt="填填看" title="填填看">PLAY</div>
        </div>
      </transition>


      <div class="content-1">
        <div class="ver-text-3" style="top: 0.4%; left: 54.8%">
          <div>但</div>
          <div>這</div>
          <div>種</div>
          <div>的</div>
        </div>
        <transition name="img-frame-fade">
        <div class="input-box" style="top: 33.8%; left: 54.8%" v-if="gameStep==1">
          <input type="text"  maxlength="1">
          <input type="text"  maxlength="1" style=" top: -0.5vw;">
        </div>
        </transition>
        <div class="ver-text-3" style="top: 47.8%; left: 54.8%">
          <div>、</div>
          <div>不</div>
          <div>只</div>
          <div>是</div>
          <div>關</div>
          <div>於</div>
        </div>
        <div class="ver-text-3" style="top: 0.4%; left: 20.8%">
          <div>經</div>
          <div>營</div>
          <div>者</div>
          <div>自</div>
          <div>己</div>
          <div>的</div>
          <div>問</div>
          <div>題</div>
        </div>
      </div>

      <div class="content-2">
        <div class="ver-text-3" style="top: 0%; left: 32.8%">
          <div>言</div>
          <div>論</div>
          <div>出</div>
          <div>版</div>
          <div>的</div>
        </div>
        <transition name="img-frame-fade">
        <div class="input-box" style="top: 40%; left: 32.8%" v-if="gameStep==1">
          <input type="text"  maxlength="1">
          <input type="text"  maxlength="1" style=" top: -0.5vw;">
          <input type="text"  maxlength="1" style=" top: -1vw;">
          <input type="text"  maxlength="1" style=" top: -1.5vw;">
        </div>
        </transition>
        <div class="ver-text-3" style="top: 71%; left: 32.8%">
          <div>的</div>
          <div>抗</div>
          <div>議</div>
        </div>
      </div>


      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-3.json')" alt="插圖" title="插圖"></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit3-3/20230412-3-3-物件-44-插圖8.png" alt="插圖" title="插圖">
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-4.json')" alt="插圖" title="插圖"></LottieTest>
      </div>

<!--      <img class="sample" src="../assets/Unit3-3/sample/202304123-3-06.png">-->

      <transition name="img-frame-fade"><div v-if="gameStep==1" class="blue-btn" style="top:89.5%; left: 35.5%; padding: 0.1vw 0.6vw 0.1vw 0.6vw" @click="gameStep=2" @keydown.enter="gameStep=2" tabindex="0" alt="完成" title="完成">完成</div></transition>

      <transition name="img-frame-fade">
        <div v-if="gameStep==2">
        <div  class="blue-btn" style="top:89.5%; left: 32.8%; padding: 0.1vw 0.6vw 0.1vw 0.6vw" @click="showImg[10]=true" @keydown.enter="showImg[10]=true" tabindex="0" alt="文字ＯＸ了?" title="文字ＯＸ了?">文字ＯＸ了?</div>

          <img class="absolute" style="width:2.5vw; top: 33.5%; left: 24%;" src="../assets/Unit3-3/cross.png" alt="X" title="X">
          <img class="absolute" style="width:2.5vw; top: 42.5%; left: 24%;" src="../assets/Unit3-3/cross.png" alt="X" title="X">

          <img class="absolute" style="width:2.5vw; top: 48.5%; left: 46.5%;" src="../assets/Unit3-3/cross.png" alt="X" title="X">
          <img class="absolute" style="width:2.5vw; top: 54.5%; left: 46.5%;" src="../assets/Unit3-3/cross.png" alt="X" title="X">


          <img class="absolute" style="width:3vw; top: 42.5%; left: 57.2%;" src="../assets/Unit3-3/20230412-3-3-物件-45-ooxx.png" alt="OOXX" title="OOXX">

          <img class="absolute" style="width:2.5vw; top: 41.5%; left: 86.8%;" src="../assets/Unit3-3/cross.png" alt="X" title="X">
        </div>
      </transition>

    </div>

    <div class="part6 part-color">
      <img class="logo-text" src="../assets/Unit3-3/20230412-3-3-物件-28-裝飾字3-臺灣新民報.png" alt="臺灣新民報" title="臺灣新民報">
      <div class="content-1 content-style">
        臺灣民報於1927年與文協運作脫鉤，1927年7月發行所遷回臺灣，發行量有所擴大，1930年改名為《臺灣新民報》，1932年從雜誌逐步轉型為日刊，報導內容依舊對總督府施政有所批評與諷刺，為本土政治文化運動的代表性媒體。另一方面，左傾的文協成員也有批評的聲音，曾發起拒買運動。
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #ffffff"></p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺灣新民報社獎紀念章</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2004.001.0241</p>
        <p class="content-style" style="margin-top: 0.5vw;">本件徽章正面雕刻一隻鷹，上寫「賞」字，背面上方有一「民」字的符號，下方寫「臺灣新民報社」。</p>
        <p class="content-style" >《臺灣民報》從1930年3月29日的第306期起，改稱為《臺灣新民報》，並在1932年4月15日開始發行日刊。</p>
      </div>

      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-29-pic10.png" alt="臺灣新民報社獎紀念章" title="臺灣新民報社獎紀念章">
        <img class="zoom-2-btn" @click="showImg[8]=true" @keydown.enter="showImg[8]=true" tabindex="0" style="width: 2.2vw; bottom: 7%; right: -1%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>

      <div class="img-2">
        <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-30-pic11.png" alt="臺灣新民報社獎紀念章" title="臺灣新民報社獎紀念章">
        <img class="zoom-2-btn" @click="showImg[9]=true" @keydown.enter="showImg[9]=true" tabindex="0"  style="width: 2.2vw; bottom: 7%; right: -5%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-5.json')" alt="模擬選舉" title="模擬選舉"></LottieTest>
      </div>

      <img class="img-3" src="../assets/Unit3-3/20230412-3-3-物件-32-插畫4.png" alt="模擬選舉" title="模擬選舉">
      <img class="more-btn btn-hover" @click="moreBtn" @keydown.enter="moreBtn" tabindex="0" src="../assets/Unit3-3/20230412-3-3-物件-33-模擬選舉.png"  alt="模擬選舉" title="模擬選舉">

      <div class="back-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="回廟埕拚文化" title="回廟埕拚文化">
        <p style="margin-left: 0.5vw">回廟埕拚文化</p>
      </div>
    </div>

    <!--img0--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-20.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:18.7vw; margin:4.6vw 0 0 12.5vw" src="../assets/Unit3-3/20230705-3-3-物件-04-pic1.png" alt="臺灣之文化" title="臺灣之文化">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 38.7vw; width: 21.5vw;">
            <div class="bold-title">臺灣文化協會會報第4號(1922年7月發行，書名為《臺灣之文化》)</div>
            <div class="noto-text" style="margin-top: 0.5vw">蔣渭水文化基金會 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-10.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:18.2vw; margin:2.71vw 0 0 22.8vw" src="../assets/Unit3-3/20230705-3-3-物件-05-pic2-放大文物.png" alt="臺灣民報" title="臺灣民報">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 23vw; width: 17.4vw;">
            <div >
              <div class="bold-title">《臺灣民報》發行年曆廣告</div>
              <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2006.003.0139</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-11.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 26.2vw; margin:2.71vw 0 0 6.8vw" src="../assets/Unit3-3/20230705-3-3-物件-11-pic4-放大文物.png" alt="臺灣文化協會演講團於新竹的民報批發處前留影1925年6月7日" title="臺灣文化協會演講團於新竹的民報批發處前留影1925年6月7日">
          </div>

          <div class="content-text-2" >
              <div class="bold-title">臺灣文化協會演講團於新竹的民報批發處前留影1925年6月7日</div>
              <div class="noto-text" style="margin-top: 0.4vw">中央研究院臺灣史研究所檔案館 提供</div>
              <div class="content-style" style="margin-top: 0.4vw; text-align: justify">成員包含林獻堂（坐者1）、蔡惠如（坐者2）、楊肇嘉（坐者3）、蔡式穀（坐者4）等人。</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-12.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 24.2vw; margin:4.71vw 0 0 6vw" src="../assets/Unit3-3/20230705-3-3-物件-14-pic4-放大文物.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 27vw; width: 10.3vw;">
            <div >
              <div class="bold-title">《臺灣大眾時報》</div>
              <div class="noto-text" style="margin-top: 0.4vw">南天書局復刻出版</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--                <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-13.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 27.4vw; margin:2.5vw 0 0 17vw" src="../assets/Unit3-3/20230705-3-3-物件-15-pic5-放大文物.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 27vw; width: 10.3vw;">
            <div >
              <div class="bold-title">《臺灣大眾時報》</div>
              <div class="noto-text" style="margin-top: 0.4vw">南天書局復刻出版</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
<!--          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-14.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 26.4vw; margin:2.5vw 0 0 23.7vw" src="../assets/Unit3-3/20230705-3-3-物件-16-pic6-放大文物.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 26.5vw; width: 10.3vw;">
            <div >
              <div class="bold-title">《臺灣大眾時報》</div>
              <div class="noto-text" style="margin-top: 0.4vw">南天書局復刻出版</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-15.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 26.4vw; margin:2.5vw 0 0 23.7vw" src="../assets/Unit3-3/20230705-3-3-物件-17-pic７-放大文物.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 28vw; width: auto;">
            <div >
              <div class="bold-title">《新臺灣大眾時報》</div>
              <div class="noto-text" style="margin-top: 0.4vw">南天書局復刻出版</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img7--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[7]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 26.4vw; margin:2.5vw 0 0 22.7vw" src="../assets/Unit3-3/20230705-3-3-物件-18-pic8-放大文物.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 26vw; width: auto">
            <div >
              <div class="bold-title">《新臺灣大眾時報》</div>
              <div class="noto-text" style="margin-top: 0.4vw">南天書局復刻出版</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[7]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img8--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[8]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 28.4vw; margin:1.5vw 0 0 21.5vw" src="../assets/Unit3-3/20230705-3-3-物件-29-pic10-放大文物.png" alt="臺灣新民報社獎紀念章" title="臺灣新民報社獎紀念章">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 24vw; width: 19.3vw;">
            <div >
              <div class="bold-title">臺灣新民報社獎紀念章</div>
              <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2004.001.0241</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[8]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img9--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[9]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 28.4vw; margin:1.5vw 0 0 21.5vw" src="../assets/Unit3-3/20230705-3-3-物件-31-pic11-放大文物.png" alt="臺灣新民報社獎紀念章" title="臺灣新民報社獎紀念章">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 24vw; width: 19.3vw;">
            <div >
              <div class="bold-title">臺灣新民報社獎紀念章</div>
              <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2004.001.0241</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[9]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img10--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[10]">
<!--        <img style="position: fixed; width: 100%; top: 0; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/20230703-對位-3-3-op-07.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:28.7vw; margin:6vw 0 0 3.5vw" src="../assets/Unit3-3/20230705-3-3-物件-27-pic9.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          </div>

          <div class="content-text" style="position: absolute; top:7vw; left: 43.7vw; width: 28.5vw;">
            <div class="bold-title">《新臺灣大眾時報》  1931年創刊號</div>
            <div class="noto-text" style="margin-top: 0.5vw">出自《新臺灣大眾時報》</div>
            <div class="content-style" style="margin-top: 0.8vw; text-align: justify">日本時代出版規則與執行相當嚴密，當時的政論雜誌，多半在日本印行送審，進入臺灣時要再審查一次，涉及批評時政即被查禁，經過蔡培火的努力爭取，《臺灣民報》在1927年始獲准在臺灣印行，而文協的新機關報《臺灣大眾時報》仍封鎖在海外。</div>
            <div class="content-style" style="text-align: justify">為躲避審查機制順利發賣，文協的編輯們形成一種穿插「xx」、「oo」的文體。</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[10]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img11--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[11]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 28.4vw; margin:1.5vw 0 0 17.5vw" src="../assets/Unit3-3/20230412-3-3-物件-09-pic3-放大文物.png" alt="臺灣大眾時報" title="臺灣大眾時報">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 22vw; width: auto;">
            <div >
              <div class="bold-title">臺灣民報發行所 （開設於蔣渭水開設的大稻埕大安醫院旁）</div>
              <div class="noto-text" style="margin-top: 0.4vw">蔣渭水文化基金會 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[11]=false">
          </div>
        </div>
      </div>
    </transition>


    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import {onMounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import LottieTest from "@/components/LottieTest";
import myMixin from "@/assets/common/js/mixin";

export default {
  name: "Unit3-3",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    FooterFeature,
    LottieTest
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 12 }, () => false));
    const part4Img = reactive(Array.from({ length: 3 }, () => false));
    const part4ImgIndex = ref(0);
    const gameStep = ref(0);

    onMounted(()=>{
      part4Img[0] = true;
    });

    function routeBtn(path){
      router.push(path);
    }

    function part4ArrowBtn(dir){
      if(dir==1){
        part4ImgIndex.value = Math.min(part4ImgIndex.value+1, 3);
      }
      else{
        part4ImgIndex.value = Math.max(part4ImgIndex.value-1, 0);
      }
      for (let i=0; i<part4Img.length; i++){
        part4Img[i] = false;
      }
      part4Img[part4ImgIndex.value] = true;
    }

    function moreBtn(){
      router.push('/unit3-3/more');
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      part4Img,
      part4ArrowBtn,
      part4ImgIndex,
      showImg,
      moreBtn,
      gameStep,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit3-3.css"></style>
