<template>
  <div class="wrap" @keydown.esc="escEvent">
    <Entoolbar type="1" unit="3" route-name="unit4-5" color="brown"></Entoolbar>

<!--    <img class="sample" src="../assets/Unit4-5/sample/20230703-對位-4-5-op-01-自己的紀念日.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="img-1" src="../assets/Unit4-5/20230703-4-5-物件-01-首圖1.png" alt="International Youth Day Lecture Promotional Leaflet" title="International Youth Day Lecture Promotional Leaflet">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-5/4-5-0.json')" alt="Our Own Memorial Days" title="Our Own Memorial Days" ></LottieTest>
      </div>

      <div class="title-logo">
        <p>Our Own</p>
        <p>Memorial Days</p>
      </div>
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit4-5/en/20230708-EN網站-4-5-物件-02-裝飾子2-新紀念日.png" alt="New Commemorations" title="New Commemorations">
      <div class="content-1 content-style" style="margin-top: -2vw">
        The Taiwan Cultural Association celebrated many anniversaries. Some were international days, while some marked events during the association’s development. Among the latter were: Police Incident Anniversary (December 16); the Taiwan Cultural Association Reorganization Day (January 3, Culture Day); <i>Taiwan Min Pao</i> Day (July 16 , when the first issue of Taiwanese Youth, the newspaper’s predecessor, appeared); and June 17’s Shameful Politics Day, the anniversary of a confrontation with the Governor-General’s Office of Taiwan. The establishment of a “new anniversary” marked the association’s progress, while signifying its gradual emergence as a political group.
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-5/4-5-1.json')" alt="New Commemorations" title="New Commemorations"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">

      <div class="arrow-btn" style="left: 11%; z-index: 2" @click="part3Page--" @keydown.enter="part3Page--" tabindex="0" v-if="part3Page>0">
        <p>{{leftArrowText[part3Page]}}</p>
        <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/left-arrow.png" alt="previous page" title="previous page">
      </div>

      <transition name="img-frame-fade">
      <div class="page page-1" v-if="part3Page==0">
        <img class="bg w-full" src="../assets/Unit4-5/en/20230708-EN網站-4-5-物件-03-插畫1.png" alt="International Women's Day" title="International Women's Day">
        <div class="content-1">
          <p class="title-style" style="color: #267c68">International Women's Day</p>
          <p class="subtitle-style" style="margin-top: 0.5vw"><i>New Taiwan Popular Times</i> 2 of 1 March Issue March Anniversaries</p>
          <p class="subtitle-style"><i>Taiwan Min Pao</i> Issue 7, 211 Chiayi Lectures on Women’s Issues</p>
          <p class="source-style" style="margin-top: 0.3vw">From <i>New Taiwan Popular Times</i> and <i>Taiwan Min Pao</i></p>
          <p class="content-style" style="margin-top: 0.5vw;height: 10.5vw;overflow: auto">From the early stages of the Taiwan Cultural Association's development, gender issues, particularly women's rights and marriage problems, were widely discussed. Influenced by the association, Changhua and Chiayi successively established the "Changhua Women's Mutual Support Association" and the "Zhuluo Women's Advancement Association," organizing lectures, evening classes, and other activities. International Women's Day became one of the significant commemorative days observed in March.</p>
        </div>
        <div class="img-1">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-05-pic1.png" alt="New Taiwan Popular Times 2 of 1 March Issue March Anniversaries" title="New Taiwan Popular Times 2 of 1 March Issue March Anniversaries">
          <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 20%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
        <div class="img-2">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-06-pic2.png" alt="Taiwan Min Pao Issue 7, 211 Chiayi Lectures on Women’s Issues" title="Taiwan Min Pao Issue 7, 211 Chiayi Lectures on Women’s Issues">
          <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.3vw; bottom: 8%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
      </div>
      </transition>

      <transition name="img-frame-fade">
      <div class="page page-2" v-if="part3Page==1">
        <img class="bg w-full" src="../assets/Unit4-5/en/20230708-EN網站-4-5-物件-09-插畫2.png" alt="International Workers' Day" title="International Workers' Day">
        <div class="content-1">
          <p class="title-style" style="color: #267c68">International Workers' Day</p>
          <p class="subtitle-style" style="margin-top: 0.5vw"><i>Taiwan Popular Times</i> Various May Day Reports</p>
          <p class="source-style" style="margin-top: 0.3vw">From <i>Taiwan Popular Times</i></p>
          <p class="content-style" style="margin-top: 0.5vw">Following the Taiwan Cultural Association's shift to the left in 1927, Labor Day (referred to as May Day in the text) became the most important commemorative day for the organization. Each year on May 1st, a variety of activities took place across numerous locations. Groups identifying as "proletarian youth" embarked on speaking tours, and their publications extensively promoted the fervent atmosphere that was flourishing throughout Taiwan.</p>
        </div>

        <div class="arrow-btn arrow-btn-2" style="left: 49.6%;" @click="page3Img--" @keydown.enter="page3Img--" tabindex="0" v-if="page3Img>0">
          <img class="w-full"  src="../assets/common/left-arrow.png" alt="previous page" title="previous page">
        </div>

        <transition name="img-frame-fade"><div class="img" v-if="page3Img==0" style="top:3%; left: 63.7%;">
          <img class="h-full" src="../assets/Unit4-5/20230703-4-5-物件-10-pic3.png" alt="Taiwan Popular Times Various May Day Reports" title="Taiwan Popular Times Various May Day Reports">
          <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div></transition>
        <transition name="img-frame-fade"><div class="img" v-if="page3Img==1" style="top:3%; left: 56%;">
          <img class="h-full" src="../assets/Unit4-5/20230703-4-5-物件-11-pic4.png" alt="Taiwan Popular Times Various May Day Reports" title="Taiwan Popular Times Various May Day Reports">
          <img class="zoom-2-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div></transition>
        <transition name="img-frame-fade"><div class="img" v-if="page3Img==2" style="top:3%; left: 56%;">
          <img class="h-full" src="../assets/Unit4-5/20230703-4-5-物件-13-pic6.png" alt="Taiwan Popular Times Various May Day Reports" title="Taiwan Popular Times Various May Day Reports">
          <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div></transition>
        <transition name="img-frame-fade"><div class="img" v-if="page3Img==3" style="top:3%; left: 56%;">
          <img class="h-full" src="../assets/Unit4-5/20230703-4-5-物件-12-pic5.png" alt="Taiwan Popular Times Various May Day Reports" title="Taiwan Popular Times Various May Day Reports">
          <img class="zoom-2-btn" @click="showImg[6]=true" @keydown.enter="showImg[6]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div></transition>


        <div class="arrow-btn arrow-btn-2" style="right: 6%;" @click="page3Img++" @keydown.enter="page3Img++" tabindex="0"  v-if="page3Img<3">
          <img class="w-full"  src="../assets/common/right-arrow.png" alt="next page" title="next page">
        </div>
      </div>
      </transition>

      <transition name="img-frame-fade">
      <div class="page page-3" v-if="part3Page==2">
        <img class="bg w-full" src="../assets/Unit4-5/en/20230708-EN網站-4-5-物件-14-插畫3.png" alt="“Day of Disgrace” Start of Japanese Rule" title="“Day of Disgrace” Start of Japanese Rule">
        <div class="content-1">
          <p class="title-style-s" style="color: #267c68">“Day of Disgrace” Start of Japanese Rule</p>
          <p class="subtitle-style-s" style="margin-top: 0.5vw"><i>New Taiwan Popular Times</i> 2 of 3 June Issue 4 “That @#$%  617 Holiday is Coming Again”</p>
          <p class="subtitle-style-s" style="margin-top: 0.5vw">617 Day of Disgrace Commemorative Events and Slogans </p>
          <p class="source-style" style="margin-top: 0.3vw">From <i>Police History Chronicles</i> and <i>New Taiwan Popular Times</i></p>
          <p class="content-style" style="margin-top: 0.5vw; max-height: 10vw; overflow: scroll; padding-right: 20px ">On June 17, 1895, the Taiwan Governor-General’s Office officially established its administration in Taiwan. Each year on this day, the government and Japanese groups held celebratory events, while the response from the Taiwanese people remained relatively muted. In 1926, the youth members of the Taiwan Cultural Association began organizing large-scale lectures and political speeches as a direct challenge to the colonial system of the Governor-General’s Office. This particularly agitated the police, leading to the annual June 17 events becoming more elaborate over time and resulting in a game of cat and mouse with law enforcement. In 1929, Wang Min-chuan and other members of the Taiwan Cultural Association were arrested by the police in early June and were not released until after the anniversary, an action that could be considered "preventative detention."</p>
        </div>
        <div class="img-1">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-15-pic7.png" alt="New Taiwan Popular Times 2 of 3 June Issue 4 “That @#$% 617 Holiday is Coming Again”" title="New Taiwan Popular Times 2 of 3 June Issue 4 “That @#$% 617 Holiday is Coming Again”">
          <img class="zoom-2-btn" @click="showImg[7]=true" @keydown.enter="showImg[7]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
        <div class="img-2">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-16-pic8.png" alt="617 Day of Disgrace Commemorative Events and Slogans" title="617 Day of Disgrace Commemorative Events and Slogans">
          <img class="zoom-2-btn" @click="showImg[8]=true" @keydown.enter="showImg[8]=true" tabindex="0" style="width: 2.3vw; bottom: 15%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
      </div>
      </transition>

      <transition name="img-frame-fade">
      <div class="page page-4" v-if="part3Page==3">
        <img class="bg w-full" src="../assets/Unit4-5/en/20230708-EN網站-4-5-物件-17-插畫4.png" alt="International Youth Day" title="International Youth Day">
        <div class="content-1">
          <p class="title-style" style="color: #267c68">International Youth Day</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">International Youth Day Lecture Promotional Leaflet</p>
          <p class="source-style" style="margin-top: 0.3vw">Courtesy of Zhuang Ming-zheng</p>
          <p class="content-style" style="margin-top: 0.5vw">The first Sunday in September was celebrated as International Youth Day, particularly by communist youth, in commemoration of the 1915 anti-imperialist congress and demonstrations.</p>
          <p class="content-style" >*The United Nations currently designates August 12th as International Youth Day.</p>
        </div>
        <div class="img-1">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-18-pic9.png" alt="International Youth Day Lecture Promotional Leaflet" title="International Youth Day Lecture Promotional Leaflet">
          <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.3vw; bottom: 3%; right: 0%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
      </div>
      </transition>

      <transition name="img-frame-fade">
      <div class="page page-5" v-if="part3Page==4">
        <img class="bg w-full" src="../assets/Unit4-5/en/20230708-EN網站-4-5-物件-18-插畫5.png" alt="Taiwan cultural Day" title="Taiwan cultural Day">
        <div class="content-1">
          <p class="title-style" style="color: #267c68">Taiwan cultural Day</p>
          <p class="content-style" style="margin-top: 0.5vw">In 2011, the Ministry of Culture designated October 17th as Taiwan Culture Day in commemoration of the Taiwan Cultural Association's establishment in 1921. This day celebrates the beliefs pursued by the association, with the hope that culture can serve as a driving force for unity and progress among the people of Taiwan.</p>
        </div>
        <div class="json-1">
          <LottieTest :json="require('@/assets/Unit4-5/4-5-2.json')" alt="Taiwan cultural Day" title="Taiwan cultural Day"></LottieTest>
        </div>

      </div>
      </transition>

      <transition name="img-frame-fade">
      <div class="page page-6" v-if="part3Page==5">
        <img class="bg w-full" src="../assets/Unit4-5/en/20230708-EN網站-4-5-物件-19-插畫6.png" alt="Security Police Incident Memorial Day" title="Security Police Incident Memorial Day">
        <div class="content-1">
          <p class="title-style" style="color: #267c68; white-space: nowrap">Security Police Incident Memorial Day</p>
          <p class="subtitle-style" style="margin-top: 0.5vw"><i>Taiwan Min Pao</i> Issue 7, 301 Security Police Incident Memorial Day</p>
          <p class="source-style" style="margin-top: 0.3vw">From <i>Taiwan Min Pao</i></p>
          <div class="content-style" style="margin-top: 0.5vw;height: 12vw; overflow: auto">
            <p>On December 16, 1923, the Taiwan Parliament Founding Alliance was banned. Key figures of the Taiwan Cultural Association, such as Tsai Pei-huo and Chiang Wei-shui, were arrested and the Governor's Office initiated legal proceedings against people accused of political activities. This was known as the Security Police Incident.</p>
            <p>Security Police Incident Memorial Day was also considered Social Movement Commemoration Day. Each year around this time, Taiwan Cultural Association members and those accused during the Security Police Incident held commemorative events to advocate for the goals of popular movements.</p>
          </div>

        </div>
        <div class="img-1">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-20-pic10.png" alt="Taiwan Min Pao Issue 7, 301 Security Police Incident Memorial Day" title="Taiwan Min Pao Issue 7, 301 Security Police Incident Memorial Day">
          <img class="zoom-2-btn" @click="showImg[9]=true" @keydown.enter="showImg[9]=true" tabindex="0" style="width: 2.3vw; bottom: 10%; right: 6%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
      </div>
      </transition>


      <div class="arrow-btn" style="right: 12%;" @click="part3Page++" @keydown.enter="part3Page++" tabindex="0" v-if="part3Page<5">
        <p style="display: flex; flex-direction: column; align-items: center">{{rightArrowText[part3Page]}}</p>
        <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/right-arrow.png" alt="next page" title="next page">
      </div>

      <div class="back-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
        <img src="../assets/common/left-arrow-green.png" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
        <p style="margin-left: 0.5vw">Back</p>
      </div>
    </div>


    <!--寫這裡-->

    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-10.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:4vw 0 0 14.6vw" src="../assets/Unit4-5/20230703-4-5-物件-18-pic9-放大文物.png" alt="International Youth Day Lecture Promotional Leaflet" title="International Youth Day Lecture Promotional Leaflet">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">International Youth Day Lecture Promotional Leaflet</div>
              <div class="noto-text" >Courtesy of Zhuang Ming-zheng</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-11.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:11.3vw; margin:1.7vw 0 0 25.9vw" src="../assets/Unit4-5/20230703-4-5-物件-05-pic1-放大文物.png" alt="New Taiwan Popular Times 2 of 1 March Issue March Anniversaries" title="New Taiwan Popular Times 2 of 1 March Issue March Anniversaries">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>New Taiwan Popular Times</i> 2 of 1 March Issue March Anniversaries</div>
              <div class="noto-text">From <i>New Taiwan Popular Times</i></div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-12.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:46vw; margin:7.5vw 0 0 9.3vw" src="../assets/Unit4-5/20230703-4-5-物件-06-pic2-放大文物.png" alt="Taiwan Min Pao Issue 7, 211 Chiayi Lectures on Women’s Issues" title="Taiwan Min Pao Issue 7, 211 Chiayi Lectures on Women’s Issues">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Min Pao</i> Issue 7, 211 Chiayi Lectures on Women’s Issues</div>
              <div class="noto-text" >From <i>Taiwan Min Pao</i></div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-13.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.8vw 0 0 23.1vw" src="../assets/Unit4-5/20230703-4-5-物件-10-pic3-放大文物.png" alt="Taiwan Popular Times Various May Day Reports" title="Taiwan Popular Times Various May Day Reports">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Popular Times</i>  Various May Day Reports</div>
              <div class="noto-text" >From <i>Taiwan Popular Times</i></div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-14.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.3vw; margin:2.7vw 0 0 14vw" src="../assets/Unit4-5/20230703-4-5-物件-11-pic4-放大文物.png" alt="Taiwan Popular Times Various May Day Reports" title="Taiwan Popular Times Various May Day Reports">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Popular Times</i>  Various May Day Reports</div>
              <div class="noto-text" >From <i>Taiwan Popular Times</i></div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-15.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.5vw; margin:2.7vw 0 0 13.1vw" src="../assets/Unit4-5/20230703-4-5-物件-13-pic6-放大文物.png" alt="Taiwan Popular Times Various May Day Reports" title="Taiwan Popular Times Various May Day Reports">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Popular Times</i>  Various May Day Reports</div>
              <div class="noto-text" >From <i>Taiwan Popular Times</i></div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
        <!--                <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.4vw; margin:2.6vw 0 0 14vw" src="../assets/Unit4-5/20230703-4-5-物件-12-pic5-放大文物.png" alt="Taiwan Popular Times Various May Day Reports" title="Taiwan Popular Times Various May Day Reports">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Popular Times</i>  Various May Day Reports</div>
              <div class="noto-text" >From <i>Taiwan Popular Times</i></div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img7yet--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[7]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:16.5vw; margin:2.6vw 0 0 24.1vw" src="../assets/Unit4-5/20230703-4-5-物件-15-pic7-放大文物.png" alt="New Taiwan Popular Times 2 of 3 June Issue 4 “That @#$% 617 Holiday is Coming Again”" title="New Taiwan Popular Times 2 of 3 June Issue 4 “That @#$% 617 Holiday is Coming Again”">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>New Taiwan Popular Times</i> 2 of 3 June Issue 4 “That @#$%  617 Holiday is Coming Again”</div>
              <div class="noto-text" >From <i>Taiwan Popular Times</i></div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[7]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img8--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[8]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-18.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:45.2vw; margin:2.9vw 0 0 9.5vw" src="../assets/Unit4-5/20230703-4-5-物件-16-pic8-放大文物.png" alt="617 Day of Disgrace Commemorative Events and Slogans" title="617 Day of Disgrace Commemorative Events and Slogans">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">617 Day of Disgrace Commemorative Events and Slogans</div>
              <div class="noto-text">From <i>Police History Chronicles</i> and <i>New Taiwan Popular Times</i></div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[8]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img9--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[9]">
        <!--     <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-19.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:39.3vw; margin:2.8vw 0 0 12.7vw" src="../assets/Unit4-5/20230703-4-5-物件-20-pic10-放大文物.png" alt="Taiwan Min Pao Issue 7, 301 Security Police Incident Memorial Day" title="Taiwan Min Pao Issue 7, 301 Security Police Incident Memorial Day">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Min Pao</i> Issue 7, 301 Security Police Incident Memorial Day</div>
              <div class="noto-text" >From <i>Taiwan Popular Times</i></div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[9]=false">
          </div>
        </div>
      </div>
    </transition>


    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language='en' color="black"></FooterFeature>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn";
import LottieTest from "@/components/LottieTest";
import {reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
export default {
  name: "Unit4-5",
  mixins: [myMixin],
  components:{
    Entoolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));
    const page3Img = ref(0);
    const part3Page = ref(0);
    const leftArrowText = reactive(['','March 8','May 1','June 17','September 1','October 17']);
    const rightArrowText = reactive(['May 1','June 17','September 1','October 17','December 16','']);

    function routeBtn(path){
      router.push(path);
    }


    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      page3Img,
      part3Page,
      leftArrowText,
      rightArrowText,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit4-5.css"></style>
