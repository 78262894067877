<template>
  <div class="menu-wrap" :class="menuClass=='menu-panel-open'? 'menu-wrap-open':''" v-if="color=='white'" >
<!--    <router-link accesskey="U"  to="" >-->
<!--    <div class="blind-btn btn"  ><img class="w-full" src="../assets/common/blind-btn.png" alt=":::上方選單區" title=":::上方選單區" ></div>-->
<!--    </router-link>-->
    <div class="en-btn btn" tabindex="0" @click="routerBtn('/en/'+routeName)" @keydown.enter="routerBtn('/en/'+routeName)"><img class="w-full"  src="../assets/common/en-btn.png" alt="語言切換至英文" title="語言切換至英文"></div>
    <div class="menu-btn btn" tabindex="0" @click="changeMenu"  @keydown.enter="changeMenu"><img class="menu-icon w-full" src="../assets/common/menu-btn.png"  alt="點選打開導覽列" title="點選打開導覽列">
<!--      <div class="menu-btn-text" v-if="menuClass!=''">Close</div>-->
          <div class="menu-btn-text" v-if="menuClass!=''"><img class="w-full" src="../assets/common/menu-btn-text.png" alt="關閉導覽列" title="關閉導覽列"> </div>
    </div>
  </div>

  <div class="menu-wrap" :class="menuClass=='menu-panel-open'? 'menu-wrap-open':''" v-if="color=='brown'" >
<!--    <router-link accesskey="U"  to=""  >-->
<!--    <div class="blind-btn btn" ><img class="w-full" src="../assets/common/blind-btn-brown.png" alt=":::上方選單區" title=":::上方選單區" ></div>-->
<!--    </router-link>-->
    <div class="en-btn btn" tabindex="0"  @click="routerBtn('/en/'+routeName)" @keydown.enter="routerBtn('/en/'+routeName)"><img class="w-full" src="../assets/common/en-btn-brown.png"  alt="語言切換至英文" title="語言切換至英文"></div>
    <div class="menu-btn btn" tabindex="0"  @click="changeMenu" @keydown.enter="changeMenu"><img class="menu-icon w-full" src="../assets/common/menu-btn-brown.png" alt="點選打開導覽列" title="點選打開導覽列">
<!--      <div class="menu-btn-text" v-if="menuClass!=''">Close</div>-->
      <div class="menu-btn-text" v-if="menuClass!=''"><img class="w-full" src="../assets/common/menu-btn-text.png" alt="關閉導覽列" title="關閉導覽列"> </div>
    </div>
  </div>

  <div v-if="menuClass == 'menu-panel-open'" class="menu-panel-bg" @click="changeMenu"></div>

  <div class="menu-panel" :class="menuClass" >
    <div class="menu-panel-wrap">
      <div class="label-title" @mouseenter="labelBtn(1)" @click="routerBtn('/unit1-1')" @keydown.enter="routerBtn('/unit1-1')" :tabindex="menuClass==''?1:0">鳥瞰世界</div>
      <div class="sub-label-div" :class="labelIndex==1?'sub-label-1-div-open':''"  @mouseleave="labelBtn(0)">
<!--        <div class="sub-label" @click="routerBtn('/unit1-1')">鳥瞰世界</div>-->
        <div class="sub-label" @click="routerBtn('/unit1-2')" @keydown.enter="routerBtn('/unit1-2')" @focus="labelBtn(1)" tabindex="0">思潮滾動世界</div>
      </div>
      <div class="label-title" @mouseenter="labelBtn(2)" @click="routerBtn('/unit2-0')" @keydown.enter="routerBtn('/unit2-0')" :tabindex="menuClass==''?1:0">文協向前衝</div>
      <div class="sub-label-div" :class="labelIndex==2?'sub-label-2-div-open':''"  @mouseleave="labelBtn(0)">
<!--        <div class="sub-label" @click="routerBtn('/unit2-0')">文協向前衝</div>-->
        <div class="sub-label" @click="routerBtn('/unit2-1')" @keydown.enter="routerBtn('/unit2-1')" @focus="labelBtn(2)" tabindex="0">臺灣人的臺灣</div>
        <div class="sub-label" @click="routerBtn('/unit2-2')" @keydown.enter="routerBtn('/unit2-2')" @focus="labelBtn(2)" tabindex="0">為農工大眾主張</div>
        <div class="sub-label" @click="routerBtn('/unit2-3')" @keydown.enter="routerBtn('/unit2-3')" @focus="labelBtn(2)" tabindex="0">左派與右派</div>
        <div class="sub-label" @click="routerBtn('/unit2-4')" @keydown.enter="routerBtn('/unit2-4')" @focus="labelBtn(2)" tabindex="0">政治結社的走向</div>
      </div>
      <div class="label-title" @mouseenter="labelBtn(3)" @click="routerBtn('/enter')" @keydown.enter="routerBtn('/enter')" :tabindex="menuClass==''?1:0">廟埕拚文化</div>
      <div class="sub-label-div" :class="labelIndex==3?'sub-label-3-div-open':''"  @mouseleave="labelBtn(0)">
<!--        <div class="sub-label" @click="routerBtn('/enter')">廟埕拚文化</div>-->
        <div class="sub-label" @click="routerBtn('/unit3-1')" @keydown.enter="routerBtn('/unit3-1')" @focus="labelBtn(3)" tabindex="0">文化講演會</div>
        <div class="sub-label" @click="routerBtn('/unit3-2')" @keydown.enter="routerBtn('/unit3-2')" @focus="labelBtn(3)" tabindex="0">演劇與電影</div>
        <div class="sub-label" @click="routerBtn('/unit3-3')" @keydown.enter="routerBtn('/unit3-3')" @focus="labelBtn(3)" tabindex="0">言論機關</div>
        <div class="sub-label" @click="routerBtn('/unit3-4')" @keydown.enter="routerBtn('/unit3-4')" @focus="labelBtn(3)" tabindex="0">社會歌謠</div>
        <div class="sub-label" @click="routerBtn('/unit3-5')" @keydown.enter="routerBtn('/unit3-5')" @focus="labelBtn(3)" tabindex="0">世界語</div>
        <div class="sub-label" @click="routerBtn('/unit4-1')" @keydown.enter="routerBtn('/unit4-1')" @focus="labelBtn(3)" tabindex="0">時鐘塔</div>
        <div class="sub-label" @click="routerBtn('/unit4-2')" @keydown.enter="routerBtn('/unit4-2')" @focus="labelBtn(3)" tabindex="0">文化基地</div>
        <div class="sub-label" @click="routerBtn('/unit4-3')" @keydown.enter="routerBtn('/unit4-3')" @focus="labelBtn(3)" tabindex="0">交誼機關</div>
        <div class="sub-label" @click="routerBtn('/unit4-4')" @keydown.enter="routerBtn('/unit4-4')" @focus="labelBtn(3)" tabindex="0">風俗改良</div>
        <div class="sub-label" @click="routerBtn('/unit4-5')" @keydown.enter="routerBtn('/unit4-5')" @focus="labelBtn(3)" tabindex="0">自己的紀念日</div>
      </div>

      <div class="label-title" @mouseenter="labelBtn(4)" @click="routerBtn('/unit6-2')" @keydown.enter="routerBtn('/unit6-2')" :tabindex="menuClass==''?1:0">番外篇</div>
<!--      <div class="sub-label-div" :class="labelIndex==4?'sub-label-4-div-open':''"  @mouseleave="labelBtn(0)">-->
<!--        <div class="sub-label" @click="routerBtn('/unit6-2')">番外篇</div>-->
<!--      </div>-->
      <div class="label-btn" @click="openUrl('https://my.matterport.com/show/?m=eHr7iN2hzzC&lang=encomshow/?m=eHr7iN2hzzC&lang=en')" @keydown.enter="openUrl('https://my.matterport.com/show/?m=eHr7iN2hzzC&lang=encomshow/?m=eHr7iN2hzzC&lang=en')" :tabindex="menuClass==''?1:0" alt="展覽環景(另開新視窗)" title="展覽環景(另開新視窗)">展覽環景</div>
      <div class="label-btn" @click="routerBtn('/AboutUs')" @keydown.enter="routerBtn('/AboutUs')"  :tabindex="menuClass==''?1:0" alt="關於我們" title="關於我們" style="margin-top: 1vw" >關於我們</div>
      <div class="label-btn" @click="routerBtn('/guide')" @keydown.enter="routerBtn('/guide')" :tabindex="menuClass==''?1:0" alt="網站導覽" title="網站導覽" style="margin-top: 1vw">網站導覽</div>

    </div>
  </div>
</template>

<script>
import {onBeforeUnmount, ref} from "vue";
import router from "@/router";

export default {
  name: "MenubarComp",
  props:{
    color:{
      type:String,
      default:'brown'
    },
    routeName:{
      type:String,
      default: "",
    },
  },
  setup(){

    const labelIndex = ref(0);
    const menuClass = ref('');

    // const closeFrame = ref(require('@/assets/common/menu-btn.png'));
    // let closeInterval = null;
    // let closeAnimIndex = 1;

    function labelBtn(index){
      labelIndex.value= index;
    }

    function changeMenu(){
      if(menuClass.value == ''){
        menuClass.value = 'menu-panel-open';
      }else{
        labelIndex.value = 0;
        menuClass.value = '';
      }
    }

    function routerBtn(path){
      router.push(path);
    }

    function openUrl(url){
      window.open(url);
    }


    //closeInterval = setInterval(nextFrame, 32);
    // function nextFrame() {
    //   closeAnimIndex = (closeAnimIndex + 1) % 10;
    //   closeFrame.value = require('@/assets/common/close/close-'+ closeAnimIndex.toString().padStart(2, '0') +'.png');
    //   console.log(closeAnimIndex)
    //   if(closeAnimIndex >= 9){
    //     clearInterval(closeInterval);
    //   }
    // }

    // eslint-disable-next-line no-unreachable
    // onBeforeUnmount(()=>{
    //   clearInterval(closeInterval);
    // });


    return{
      labelBtn,
      labelIndex,
      changeMenu,
      menuClass,
      routerBtn,
      openUrl,
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped>
.menu-wrap {
  position: fixed;
  top: 3.8%;
  right: 1.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 15;
  width: 4vw;
}

.btn{
  cursor: pointer;
  width: 1.2vw;
}

.blind-btn:hover{
  content: url('@/assets/common/blind-btn-brown.png');
}

.en-btn:hover{
  content: url('@/assets/common/en-btn-brown.png');
}

.menu-btn{
  position: relative;
}

.menu-btn .menu-icon:hover{
  content: url('@/assets/common/menu-btn-brown.png');
}

.menu-wrap-open .blind-btn{
  content: url('@/assets/common/blind-btn-brown.png');
}

.menu-wrap-open .en-btn{
  content: url('@/assets/common/en-btn-brown.png');
}

.menu-wrap-open .menu-btn .menu-icon{
  content: url('@/assets/common/close/close-00.png');
}
.menu-btn-text{
  position: absolute;
  top: 0.55vw;
  left: 50%;
  width: 1.6vw;
  transform: scale(0.7) translateX(-67%);
  color:#552519;
}

.menu-wrap-open .menu-btn:active img{
  transform: scaleY(0.7);

}


.menu-panel-bg{
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9;
  top: 0;
}

.menu-panel{
  position: fixed;
  top: 0%;
  right: -13.4vw;
  width: 13.4vw;
  height: 100vh;
  background: rgba(255,255,255,0.85);
  z-index: 10;
  transition: all 0.2s ease-in-out;
}

.menu-panel-open{
  right: 0vw;
}

.menu-panel-wrap{
  text-align: left;
  padding: 6.4vw 0 0 2.9vw;
}

.label-title{
  position: relative;
  color:#552519;
  font-family: NotoSansTC-Bold;
  font-size: calc(1.3vw * var(--font-size));
  margin-bottom: 0.5vw;
  cursor: pointer;
  z-index: 2;
}

.label-title:hover{
  color: rgba(234,85,24, 1);
}


.label-btn{
  position: relative;
  width: fit-content;
  padding: 0 0.4vw 0 0.4vw;
  color:#552519;
  font-family: NotoSansTC-Bold;
  font-size: calc(0.8vw * var(--font-size));
  border:1px solid #552519;
  border-radius: 1vw;
  margin-bottom: 1vw;
  cursor: pointer;
  z-index: 2;
}

.label-btn:hover{
  background: #552519;
  color: white;
}

.sub-label-div{
  height: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  margin-top: -0.3vw;
  margin-bottom: 0.3vw;
}

.sub-label{
  position: relative;
  color:#552519;
  font-size: calc(1vw * var(--font-size));
  font-family: NotoSansTC-Regular;
  margin-bottom: 0.1vw;
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}

.sub-label:hover{
  color: rgba(234,85,24, 1);
}

.sub-label-1-div-open{
  height: calc(1.8vw * var(--font-size));
}

.sub-label-2-div-open{
  height: calc(6.6vw * var(--font-size));
}

.sub-label-3-div-open{
  height:calc(16.2vw * var(--font-size));
}

.sub-label-4-div-open{
  height:calc(2.2vw * var(--font-size));
}


</style>
