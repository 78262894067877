<template>
  <router-view/>
</template>

<script>


export default {
  metaInfo: {
    title: 'Default App Title',
  },
  mounted() {
    const loadingElement = document.getElementById('loading-web');
    if (loadingElement) {
      loadingElement.style.display = 'none';
    }

    console.log('Base URL:', process.env.BASE_URL);

  },
  // ... 其他選項
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
