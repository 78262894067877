<template>
  <div class="unit2-0-wrap" @keydown.esc="escEvent">
  <en-toolbar type="1" unit="2" route-name="unit2-0"  color="orange"></en-toolbar>

<!--    <img class="sample" src="../assets/Unit2-0/en/sample/20230822-EN網站-對位-2-0-op-01.png">-->


    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="title-logo">
        <p>The Taiwan Cultural</p>
        <p style="margin-top: -1vw">Association</p>
        <P style="margin-top: -1vw">Forges Ahead</p>
      </div>
      <img class="building" src="../assets/Unit2-0/building.png">
      <img class="people-img-1" src="../assets/Unit2-0/people-img-1.png">
      <div class="people-1" >
        <LottieTest :json="require('@/assets/Unit2-0/2-0.json')" alt="Taiwan Cultural Association" title="Taiwan Cultural Association"></LottieTest>
      </div>
      <img @click="showImg[0]=true" @keydown.enter="showImg[0]=true" :tabindex="tabindex" class="plus-btn" style="width: 2vw; top: 19.5%; left: 42.7%;"  src="../assets/common/zoom-btn.png" alt="more information" title="more information">
    </div>

    <div class="part2 part-color">
      <div class="content" :tabindex="tabindex">
        <img  src="../assets/Unit2-0/en/20230706-EN網站-2-0-物件-03-裝飾字1-結成.png" alt="Inaugurated" title="Inaugurated">
        <p>On the afternoon of October 17, 1921, the Taiwan Cultural Association was inaugurated at Blessed Imelda’s School in Dadaocheng, Taipei. There was a total of 1,032 members and more than 300 people attended the event, with students from the Taiwan Governor-General’s Medical College, the Normal School, the Business School, and the Industrial School accounting for the majority. They reviewed the articles of association, nominated Lin Hsien-tang as chairman, director of election affairs, and the association’s representative.</p>
        <p style="margin-top: 1vw">This organization brought together elites from all over Taiwan, and had diverse ideas and objectives. There were three major demands: popularizing civilian education, establishing Taiwan’s distinctive culture, and assuming the mission of changing the world.</p>
        <p style="width: 20vw; margin-top: 1vw">What attitude should the Taiwan Cultural Association adopt when facing the difficult situation of colonization, as well as oppression and class exploitation? This became the core issue of organizational transformation and transfer of authority.</p>
      </div>

      <img class="building"  src="../assets/Unit2-0/building-2.png">
      <div class="people-2" >
        <LottieTest :json="require('@/assets/Unit2-0/2-0-1.json')" alt="people" title="people"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <img class="decorate-text"  src="../assets/Unit2-0/en/20230718-EN網站-2-0-物件-05-裝飾字2-籌備.png"  alt="Preparations" title="Preparations">
      <p class="big-year">1920</p>
      <p class="next-year">1921</p>

      <div class="content" :tabindex="tabindex">
        <div>
          <p>November</p>
          <p>After a group of medical students, including Li Ying-cheung and Wu Hai-shui discussed the formation of a cultural organization, they visited Chiang Wei-shui to begin the planning process.</p>
        </div>
        <div style="margin-top: 3vw">
          <p>July</p>
          <p style="margin-top: 1.2vw">Chiang Wei-shui and others paid a visit to Lin Hsien-tang to formulate procedures, a statement of purpose, and a draft charter. They also called upon the Government-General Department of Police to get an initial read of their attitude, then officially established an office and sent out recruitment material to potential members.</p>
        </div>
        <div style="margin-top: 0.2vw">
          <p>August</p>
          <p>Informed police and administrative agencies, newspapers, and prominent figures.</p>
        </div>
        <div style="margin-top: 2vw">
          <p>September</p>
          <p>Chiang Wei-shui officially sent a letter to Lin Hsien-tang reporting that TCA had reached over 500 members and he also composed the Taiwan Cultural Association anthem.</p>
        </div>
        <div style="margin-top: 0.3vw">
          <p>October</p>
          <p>The Taiwan Cultural Association was officially established during a meeting on October 17.</p>
        </div>
      </div>

      <div class="people-3" >
        <LottieTest :json="require('@/assets/Unit2-0/2-0-2.json')" alt="student" title="student"></LottieTest>
      </div>
    </div>

    <div class="part4 part-color">

      <div class="content">
        <p class="title">Working Together on Our Anthem</p>
        <p class="subtitle" style="margin-top: 0.4vw">Chiang Wei-shui's Letter to Lin Hsien-tang</p>
        <p class="source">National Museum of Taiwan History 2018.011.0014.0001-4
        </p>
        <p class="text" style="margin-top: 0.7vw">On September 21, 1921, Chiang Wei-shui sent a letter to Lin Hsien-tang that included the lyrics he had composed for the Taiwan Cultural Association's anthem. This letter is the only one directly related to the establishment of the association, written by Chiang Wei-shui himself.</p>

        <p class="subtitle" style="margin-top: 1.1vw">Taiwan Cultural Association Anthem
          <span v-if="lyricsType==1" class="lyrics-btn" @click="lyricsBtn(2)" @keydown.enter="lyricsBtn(2)" :tabindex="tabindex">Read the lyrics</span>
          <span v-if="lyricsType==2" class="lyrics-btn" @click="lyricsBtn(1)" @keydown.enter="lyricsBtn(1)" :tabindex="tabindex">See the manuscript</span>
        </p>
        <div class="source" style="margin-top: 0.4vw; line-height: 1vw;">
          <p> Lyrics:Chiang Wei-shui</p>
          <p> Music:Shinzaburō Ichijō (Commencement of Governance Day)</p>
          <p> Arrangement: Edgar L. Macapili</p>
          <p> Performed by: Dianshindan Choir</p>
        </div>
        <p class="source">Courtesy of the National Museum of Taiwan History</p>
        <p class="text" style="margin-top: 0.8vw">He mentioned that the lyrics were submitted for consideration and would be revised based on feedback from various sources.</p>


        <AudioComp :audio-src="require('../assets/Unit2-0/song.mp3')" style-class="white-style"></AudioComp>

      </div>

      <div class="letter-div" :class="envelopeStyle" @mouseenter="letterUp" @mouseleave="letterDown" @click="()=>{lyricsBtn(1)}">
        <img class="letter" :src="letterSrc" :class="letterFade==true?'letter-fade':''">
        <img class="envelope"   src="../assets/Unit2-0/envelope.png" alt="Chiang Wei-shui's Letter to Lin Hsien-tang" title="Chiang Wei-shui's Letter to Lin Hsien-tang">

        <transition name="img-frame-fade">
          <div class="point" v-if="envelopeStyle==''" alt="Click the Letter" title="Click the Letter">
            <div></div>
           <div>Click the Letter</div>
          </div>
        </transition>
      </div>

      <div class="letter-open-div" v-if="letterFade">
        <img v-if="lyricsType==1" class="lyrics-hand" src="../assets/Unit2-0/lyrics-hand.png" alt="manuscript" title="manuscript">
        <div v-if="lyricsType==2" class="lyrics-normal" >
          <p>Verse 1:</p>
          <p>We are all people of Asia,</p>
          <p>Mediating between Han and Japanese.</p>
          <p>Heaven has sent us a mission, mandating us urgently</p>
          <p>To advance culture, revitalize morality, cultivate capabilities.</p>
          <p style="margin-top: 1vw">Verse 2:</p>
          <p>Seeking permanent peace in East Asia, friendship between China and Japan,</p>
          <p>We must act as a chain, linking brothers in harmony,</p>
          <p>Uniting all nations of East Asia to forge a great alliance,</p>
          <p>To generate a civilization side by side with the West.</p>
          <p style="margin-top: 1vw">Verse 3:</p>
          <p>Preventing war between East and West, Peace in the world,</p>
          <p>We bring benefit to all under heaven; how could we give in to despair?</p>
          <p>But we hope the mission will finally be completed, happily for the people of the world.</p>
          <p>Long live the world and mankind; Taiwan’s reputation is forever fragrant.</p>
        </div>

        <div class="pic-1" style="position:relative; width: 10.5vw; margin: 2.5% 0 0 2.5%">
          <img src="../assets/Unit2-0/letter-1.png" alt="Chiang Wei-shui's Letter to Lin Hsien-tang" title="Chiang Wei-shui's Letter to Lin Hsien-tang">
          <img class="zoom-btn" src="../assets/common/zoom-2-btn.png" @click="showImg[1]=true"  @keydown.enter="showImg[1]=true" :tabindex="tabindex">
        </div>

        <div class="pic-1" style="position:relative; width: 9.7vw; margin: 2.5% 0 0 7%">
          <img src="../assets/Unit2-0/letter-2.png" alt="Chiang Wei-shui's Letter to Lin Hsien-tang" title="Chiang Wei-shui's Letter to Lin Hsien-tang">
          <img class="zoom-btn" src="../assets/common/zoom-2-btn.png" @click="showImg[2]=true"  @keydown.enter="showImg[2]=true" :tabindex="tabindex">
        </div>

        <div class="pic-1" style="position:relative; width:11.2vw; margin: 2.5% 0 0 6%">
          <img src="../assets/Unit2-0/letter-3.png" alt="Chiang Wei-shui's Letter to Lin Hsien-tang" title="Chiang Wei-shui's Letter to Lin Hsien-tang">
          <img class="zoom-btn" src="../assets/common/zoom-2-btn.png" @click="showImg[3]=true"  @keydown.enter="showImg[3]=true" :tabindex="tabindex">
        </div>

        <div class="pic-1" style="position:relative; width: 10.5vw; margin: 4.5% 0 0 2.1%">
          <img src="../assets/Unit2-0/letter-4.png" alt="Chiang Wei-shui's Letter to Lin Hsien-tang" title="Chiang Wei-shui's Letter to Lin Hsien-tang">
          <img class="zoom-btn" src="../assets/common/zoom-2-btn.png" @click="showImg[4]=true"  @keydown.enter="showImg[4]=true" :tabindex="tabindex">
        </div>
      </div>

    </div>

    <div class="part5 part-color">
      <div class="content">
        <img style="width: 35vw" src="../assets/Unit2-0/en/20230706-EN網站-2-0-物件-11-裝飾字3-組織.png" alt="Organization" title="Organization">
        <p>The association was led by a chairman, and an executive director dealt with committee matters. Directors and councilors organized regular committee meetings. It was the first association for all of Taiwan’s people, spontaneously established by Taiwanese.</p>

        <img class="more-btn" id="more-btn" src="../assets/Unit2-0/en/20230706-EN網站-2-0-物件-12-臺灣診斷書.png" alt="Taiwan’s Medical Diagnosis" title="Taiwan’s Medical Diagnosis" @click="moreBtn" @keydown.enter="moreBtn" :tabindex="tabindex">
        <img class="people-img" src="../assets/Unit2-0/people-img-2.png" >
      </div>



      <transition name="img-frame-fade">
        <div class="video-div" v-show="videoIdx==1" ref="videoRef">

<!--          <video id="video" ref="videoRef" muted playsinline>-->
<!--            <source src="../assets/Unit2-0/2-0-5.mp4" type="video/mp4">-->
<!--          </video>-->
            <LottieOnce autoplay="false"  ref="videoJson" :json="require('@/assets/Unit2-0/en/EN2-0-5.json')" ></LottieOnce>
          <div class="video-text" alt="Taiwan Cultural Association Organizational Chart(1921 edition)" title="Taiwan Cultural Association Organizational Chart(1921 edition)">
            <p>Taiwan Cultural Association Organizational Chart</p>
            <p>1921</p>
            <p>Operations in the early years</p>
            <p>Source:Taiwan Cultural Association Rules (1921 edition, 1927)</p>
          </div>

        </div>
      </transition>

      <transition name="img-frame-fade">
        <div class="video-div" v-show="videoIdx==2">

<!--          <video id="video" ref="video2Ref" muted playsinline>-->
<!--            <source src="../assets/Unit2-0/2-0-6.mp4" type="video/mp4">-->
<!--          </video>-->
          <LottieOnce  autoplay="false" ref="video2Ref"  :json="require('@/assets/Unit2-0/en/EN-2-0-6.json')" ></LottieOnce>
          <div class="video-text" alt="Taiwan Cultural Association Organizational Chart(1927)" title="Taiwan Cultural Association Organizational Chart(1927)">
            <p>Taiwan Cultural Association Organizational Chart</p>
            <p>1927</p>
            <p>Taiwan Cultural Association following restructuring</p>
            <p>Source:Taiwan Cultural Association Rules (1921 edition, 1927)</p>
          </div>
        </div>
      </transition>



      <img v-show="videoIdx==2" @click="videoIdx=1"  @keydown.enter="videoIdx=1" :tabindex="tabindex" class="arrow" style="top: 52%; left: 37.7%" src="../assets/common/left-arrow-white.png" alt="previous page" title="previous page">
      <img v-show="videoIdx==1" @click="videoRightBtn" @keydown.enter="videoRightBtn" :tabindex="tabindex" class="arrow" style="top: 52%; left: 88.6%" src="../assets/common/right-arrow-white.png" alt="next page" title="next page">
    </div>

    <div class="part6 part-color">
      <transition name="img-frame-fade" >
        <div class="circle" >
          <div>
            <p>How many</p>
            <p style="margin-top: 0.4vw; line-height: 1vw;">members do you</p>
            <p style="margin-top: 1.5vw; line-height: 1vw;">recognize?</p>
          </div>
          <div>
            <p>This photo captured some of the most important member of the Taiwan Cultural Association. How many members do you recognize? Click on the person and see their name.</p>
          </div>
        </div>
      </transition>

      <div class="member">
        <img  class="relative w-full" src="../assets/Unit2-0/member.png" alt="The member of the First Board of Directors of the Taiwan Cultural Association" title="The member of the First Board of Directors of the Taiwan Cultural Association">


        <img class="absolute cursor-pointer member-img" style="top: -5%; left: 9%; width: 9vw" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-61-陳虛谷.png" :tabindex="tabindex" alt="Chen Xu-gu" title="Chen Xu-gu">
        <img class="absolute cursor-pointer member-img" style="top: -6.3%; left: 39.6%; width: 10vw" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-62-丁瑞圖.png" :tabindex="tabindex" alt="Ding Rui-tu" title="Ding Rui-tu">
        <img class="absolute cursor-pointer member-img" style="top: -0.5%; left: 47.9%; width: 9vw" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-63-林資彬.png" :tabindex="tabindex" alt="Lin Zi-bin" title="Lin Zi-bin">
        <div class="absolute cursor-pointer member-img" style="top: -6.2%; left: 54.5%; width: 9.8vw" @click="routeBtn('/en/person-intro/14')" @keydown.enter="routeBtn('/en/person-intro/14')" :tabindex="tabindex">
          <img class="w-full" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-64-林幼春.png" alt="Lin You-chun" title="Lin You-chun">
          <img class="member-btn" style="top: -46%; left: -8%" src="../assets/Unit2-0/en/20230629-EN網站-2-0-物件-104-人物介紹按鈕.png" alt="Introduction" title="Introduction">
        </div>
        <div class="absolute cursor-pointer member-img" style="top: -2.05%; left: 56.55%; width: 13.7vw" @click="routeBtn('/en/person-intro/24')" @keydown.enter="routeBtn('/en/person-intro/24')" :tabindex="tabindex">
          <img class="w-full" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-65-王敏川.png" alt="Wang Min-chuan" title="Wang Min-chuan">
          <img class="member-btn" style="top: 45%; left: 2%" src="../assets/Unit2-0/en/20230629-EN網站-2-0-物件-104-人物介紹按鈕.png" alt="Introduction" title="Introduction">
        </div>

        <img class="absolute cursor-pointer member-img" style="top: -6.15%; left: 63.7%; width: 11.5vw" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-66-鄭汝南.png" :tabindex="tabindex" alt="Zheng Ru-nan" title="Zheng Ru-nan">
        <img class="absolute cursor-pointer member-img" style="top: -6.15%; left: 70.7%; width: 14.5vw" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-67-陳逢源.png" :tabindex="tabindex" alt="Chen Feng-yuan" title="Chen Feng-yuan">

        <div class="absolute cursor-pointer member-img" style="top: -1%; left: 77%; width: 6.1vw" @click="routeBtn('/en/person-intro/20')" @keydown.enter="routeBtn('/en/person-intro/20')" :tabindex="tabindex">
          <img class="w-full" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-68-賴和.png" alt="Loa Ho" title="Loa Ho">
          <img class="member-btn" style="top: -36%; left: 45%" src="../assets/Unit2-0/en/20230629-EN網站-2-0-物件-104-人物介紹按鈕.png" alt="Introduction" title="Introduction">
        </div>
        <img class="absolute cursor-pointer member-img" style="top: -6.4%; left: 84.8%; width: 11.6vw" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-69-謝春木.png" :tabindex="tabindex" alt="Xie Chun-mu" title="Xie Chun-mu">

        <div class="absolute cursor-pointer member-img" style="top: 7%; left: 0.6%; width: 8.5vw" @click="routeBtn('/en/person-intro/1')" @keydown.enter="routeBtn('/en/person-intro/1')" :tabindex="tabindex">
          <img class="w-full"  src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-70-蔡培火.png" alt="Tsai Pei-huo" title="Tsai Pei-huo">
          <img class="member-btn" style="top: 15%; left: 19%" src="../assets/Unit2-0/en/20230629-EN網站-2-0-物件-104-人物介紹按鈕.png" alt="Introduction" title="Introduction">
        </div>

        <img class="absolute cursor-pointer member-img" style="top: 23%; left: 9%; width: 13vw" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-71-洪元煌.png" :tabindex="tabindex" alt="Hong Yuan-huang" title="Hong Yuan-huang">
        <img class="absolute cursor-pointer member-img" style="top: 22%; left: 23.6%; width:14.6vw" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-72-黃呈聰.png" :tabindex="tabindex" alt="Huang Cheng-cong" title="Huang Cheng-cong">
        <div class="absolute cursor-pointer member-img" style="top: 23.3%; left: 36.7%; width: 11.8vw" @click="routeBtn('/en/person-intro/17')" @keydown.enter="routeBtn('/en/person-intro/17')" :tabindex="tabindex">
          <img class="w-full"  src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-73-蔣渭水.png" alt="Chiang Wei-shui" title="Chiang Wei-shui">
          <img class="member-btn" style="top: 18%; left: 10%" src="../assets/Unit2-0/en/20230629-EN網站-2-0-物件-104-人物介紹按鈕.png" alt="Introduction" title="Introduction">
        </div>
        <div class="absolute cursor-pointer member-img" style="top: 25.2%; left: 44.9%; width: 11.6vw " @click="routeBtn('/en/person-intro/3')" @keydown.enter="routeBtn('/en/person-intro/3')" :tabindex="tabindex">
          <img class="w-full"  src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-74-林獻堂.png" alt="Lin Hsien-tang" title="Lin Hsien-tang">
          <img class="member-btn" style="top: 16%; left: 28%" src="../assets/Unit2-0/en/20230629-EN網站-2-0-物件-104-人物介紹按鈕.png" alt="Introduction" title="Introduction">
        </div>
        <div class="absolute cursor-pointer member-img" style="top: 26%; left: 60.5%; width: 13.8vw" @click="routeBtn('/en/person-intro/0')" @keydown.enter="routeBtn('/en/person-intro/0')" :tabindex="tabindex">
          <img class="w-full" src="../assets/Unit2-0/en/memeber-outline/20230706-EN網站-2-0-物件-75-連溫卿.png" alt="Lien Wen-ching" title="Lien Wen-ching">
          <img class="member-btn" style="top: 17%; left: 8%" src="../assets/Unit2-0/en/20230629-EN網站-2-0-物件-104-人物介紹按鈕.png" alt="Introduction" title="Introduction">
        </div>

        <img class="zoom-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" :tabindex="tabindex" style="width: 2.2vw; top: 63%; left: 93%" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
      </div>

      <div class="source">
        <p style="font-size: calc(1.05vw * var(--font-size))" >First Board of Directors of the Taiwan Cultural Association</p>
        <p style="margin-top: 0.5vw" >Source: Courtesy of NTHU Library</p>
        <p>Chiang Wei-shui in the World: Historical Image Collection</p>
      </div>
    </div>

    <div class="part7 part-color">
      <div class="unit-btn" style="top: 23%; left: 12%; " >
        <img class="w-full"  src="../assets/Unit2-0/20230721-2-0-物件-76-文協向前衝按鈕-淡.png" alt="The Taiwan Cultural Association Forges Ahead" title="The Taiwan Cultural Association Forges Ahead" @click="routeBtn('/en/unit2-0')" @keydown.enter="routeBtn('/en/unit2-0')" :tabindex="tabindex">
        <p>The Taiwan Cultural</p>
        <p>Association Forges Ahead</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 38.5%; ">
        <img class="w-full" src="../assets/Unit2-0/臺灣人的臺灣按鈕.png" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" :tabindex="tabindex">
        <p>Taiwanese People’s</p>
        <p>Taiwan</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 65%; ">
        <img class="w-full" src="../assets/Unit2-0/為農工大眾主張按鈕.png" alt="Advocating for Farmers and Workers" title="Advocating for Farmers and Workers" @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" :tabindex="tabindex">
        <p>Advocating for</p>
        <p>Farmers and Workers</p>
      </div>
      <div class="unit-btn" style="top: 57.5%; left: 25%; ">
        <img class="w-full" src="../assets/Unit2-0/左派與右派按鈕.png" alt="The Left and The Right" title="The Left and The Right" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" :tabindex="tabindex">
        <p>The Left and The Right</p>
      </div>
      <div class="unit-btn" style="top: 57.5%; left: 52%; ">
        <img class="w-full" src="../assets/Unit2-0/政治結社的走向按鈕.png" alt="The Trend of Political Association" title="The Trend of Political Association" @click="routeBtn('/en/unit2-4')" @keydown.enter="routeBtn('/en/unit2-4')" :tabindex="tabindex">
        <p>The Trend of</p>
        <p>Political Association</p>
      </div>
    </div>

    <transition name="img-frame-fade">
    <div class="more-window" v-if="showMore">
<!--      <img class="sample" src="../assets/Unit2-0/en/sample/20230706-EN網站-對位-2-0-文協向前衝-op-08.png">-->
      <div class="more-div">
        <div class="content">
          <p class="title-style title">Are we the world’s underachiever?</p>
          <p class="en-content-style" style="margin-top: 1.5vw; width: 82%">Chiang Wei-shui published “Clinical Notes” in the Association newsletter Conference News, which describes Taiwan as a patient suffering from “intellectual malnutrition”. What are the symptoms of this serious malady and is any treatment available?</p>

          <transition name="img-frame-fade"><img class="arrow-btn" style="left: 9%"  src="../assets/common/left-arrow.png" alt="previous page" title="previous page" v-if="morePage>0" @click="arrowBtn(-1)" @keydown.enter="arrowBtn(-1)" tabindex="0"></transition>

          <div class="img-content">
            <img class="relative w-full" src="../assets/Unit2-0/more/20230629-2-0-物件-13-臺灣診斷書-白紙.png">
            <div class="page page-1" :class="pageClass[0]" >
              <div class="left-wrapper">
                <p class="title">Diagnosis</p>
                <p class="en-content-style"  style="margin-top: 1.6vw">Patient Name: Taiwan</p>
                <div class="en-content-style box-1" >Diagnosis: The world’s cultural underachiever</div>
                <div class="en-content-style box-2">Reason: Intellectual malnutrition</div>
              </div>

              <img class="human" src="../assets/Unit2-0/more/20230629-2-0-物件-19-臺灣診斷書-人.png">

              <div class="right-wrapper">
                <p class="en-content-style"><span>Prognosis</span> </p>
                  <p class="en-content-style" style="margin-top: 0.3vw; font-size: calc(0.92vw * var(--font-size));">The patient has a good constitution, but must receive proper care immediately. If the treatment is incorrect or the illness is allowed to drag on, it will become severe and possibly fatal.</p>
                <p class="en-content-style" style="margin-top: 1.6vw"><span>Therapeutics</span></p>
                  <p class="en-content-style" style="margin-top: 0.5vw; font-size: calc(0.92vw * var(--font-size));">Radical therapy</p>
              </div>

              <div class="source">
                <p>Attending physician: Chiang Wei-shui</p>
                <div class="line"></div>
                <p class="year" style="margin-top: 0.6vw">Nov. 30, 1921</p>
                <p style="margin-top: 0.3vw" >Source: Posthumous Collection of Chiang Wei-shui's Writings</p>
              </div>
            </div>
            <div class="page page-2" :class="pageClass[1]" >
              <div class="human-circle"></div>
              <div class="left-wrapper">
                <p class="title">Chief Complaint</p>
              </div>

              <div class="tip en-content-style">Click on the circle to review the case information.</div>

              <img class="human" src="../assets/Unit2-0/more/20230629-2-0-物件-19-臺灣診斷書-人.png">
              <transition name="img-frame-fade"><img v-if="humanText[0]==true" class="line-1" src="../assets/Unit2-0/more/20230629-2-0-物件-23-臺灣診斷書-圓線2.png"></transition>
              <transition name="img-frame-fade"><img v-if="humanText[1]==true" class="line-2" src="../assets/Unit2-0/more/20230629-2-0-物件-22-臺灣診斷書-圓線1.png"></transition>
              <transition name="img-frame-fade"><img v-if="humanText[2]==true" class="line-3" src="../assets/Unit2-0/more/20230629-2-0-物件-24-臺灣診斷書-圓線3.png"></transition>
              <transition name="img-frame-fade"><img v-if="humanText[3]==true" class="line-4" src="../assets/Unit2-0/more/20230629-2-0-物件-25-臺灣診斷書-圓線4.png"></transition>

              <div class="blue-dot" style="top: 24.9%; left: 50.8%;" @click="humanBtn(0)" @keydown.enter="humanBtn(0)" :tabindex="morePage==1?'0':'-1'" alt="Click on the circle to review the case information." title="Click on the circle to review the case information."></div>
              <div class="blue-dot" style="top: 29%; left: 46.3%;" @click="humanBtn(1)" @keydown.enter="humanBtn(1)" :tabindex="morePage==1?'0':'-1'" alt="Click on the circle to review the case information." title="Click on the circle to review the case information."></div>
              <div class="blue-dot" style="top: 66.3%; left: 46%;" @click="humanBtn(2)" @keydown.enter="humanBtn(2)" :tabindex="morePage==1?'0':'-1'" alt="Click on the circle to review the case information." title="Click on the circle to review the case information."></div>
              <div class="blue-dot" style="top: 61.6%; left: 49.8%;" @click="humanBtn(3)" @keydown.enter="humanBtn(3)" :tabindex="morePage==1?'0':'-1'" alt="Click on the circle to review the case information." title="Click on the circle to review the case information."></div>

              <transition name="img-frame-fade">
              <div v-if="humanText[0]==true" class="human-text" style="top: 19%; left: 69.5%;">
                <p class="en-content-style"  style="margin-top: 1.2vw">The patient has a large skull, which appears to be nearly empty, clearly lacking in brains.</p>
                <p class="en-content-style">Relatively difficult topics in philosophy, mathematics, science and world politics make the patient dizzy and give him a headache.</p>
              </div>
              </transition>
              <transition name="img-frame-fade">
              <div v-if="humanText[1]==true" class="human-text" style="top: 18%; left: 7.5%;">
                <p class="en-content-style"  style="margin-top: 1.2vw">Headache, dizziness, a feeling of hunger in the belly.</p>
                <p class="en-content-style">Examining the patient, we can see his head is larger than his body, so he ought to have a fine capacity for thought, but the patient’s answers to a couple simple questions all missed the mark. This clearly shows the patient is an intellectual underachiever. </p>
              </div>
              </transition>
              <transition name="img-frame-fade">
              <div v-if="humanText[2]==true" class="human-text" style="top: 62%; left: 7.5%;">
                <p class="en-content-style"  style="margin-top: 1.2vw">The hands and feet are enlarged due to overwork.</p>
              </div>
              </transition>
              <transition name="img-frame-fade">
              <div v-if="humanText[3]==true" class="human-text" style="top: 19%; left: 69.5%;">
                <p class="en-content-style"  style="margin-top: 1.2vw; line-height: 1.2vw">The patent’s chest is skeletal and concave. The abdomen is covered white stretch marks, much like the belly of a pregnant woman. I imagine the patient had a stroke of luck in 1916 and was able to gain quite a bit of weight, but the winds began to change last summer. Just as with an intestinal virus and diarrhea, the abdomen becomes extremely distended, then suddenly shrinks back down again.</p>
              </div>
              </transition>

              <div class="source">
                <p>Attending physician: Chiang Wei-shui</p>
                <div class="line"></div>
                <p class="year" style="margin-top: 0.6vw">Nov. 30, 1921</p>
                <p style="margin-top: 0.3vw" >Source: Posthumous Collection of Chiang Wei-shui's Writings</p>
              </div>
            </div>
            <div class="page page-3" :class="pageClass[2]" >
              <div class="left-wrapper">
                <p class="title">Symptoms</p>
              </div>
              <img class="human" src="../assets/Unit2-0/more/20230629-2-0-物件-19-臺灣診斷書-人.png">

              <transition name="circle">
                <div class="circle-wrapper" v-if="morePage==2">
                  <div style="width: 15.7vw">
                    <img class="circle-scale-1" src="../assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-26-臺灣診斷書-圓1.png" alt="Moral decay" title="Moral decay">
                    <img class="circle-scale-1" src="../assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-31-臺灣診斷書-圓6.png" alt="Shamelessness" title="Shamelessness">
                  </div>
                  <div style="width: 25.7vw">
                    <img class="circle-scale-2" src="../assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-27-臺灣診斷書-圓2.png" alt="Listlessness" title="Listlessness">
                    <img class="circle-scale-2" src="../assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-32-臺灣診斷書-圓7.png" alt="Depravity and laziness" title="Depravity and laziness">
                  </div>
                  <div style="width: 32.7vw; margin-top: 1.5vw">
                    <img class="circle-scale-3" src="../assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-28-臺灣診斷書-圓3.png" alt="Lack of spiritual life" title="Lack of spiritual life">
                    <img class="circle-scale-3" src="../assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-33-臺灣診斷書-圓8.png" alt="Mean spiritedness" title="Mean spiritedness">
                  </div>
                  <div  style="width: 29.7vw; margin-top: 3vw">
                    <img class="circle-scale-4" src="../assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-29-臺灣診斷書-圓4.png" alt="Shameful customs" title="Shameful customs">
                    <img class="circle-scale-4" src="../assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-34-臺灣診斷書-圓9.png" alt="Inertia" title="Inertia">
                  </div>
                  <div style="width: 19.7vw; margin-top: 1vw">
                    <img class="circle-scale-5" src="../assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-30-臺灣診斷書-圓5.png" alt="Superficial intellect" title="Superficial intellect">
                    <img class="circle-scale-5" src="../assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-35-臺灣診斷書-圓10.png" alt="Dejection and depression" title="Dejection and depression">
                  </div>
                </div>
              </transition>

              <div class="source">
                <p>Attending physician: Chiang Wei-shui</p>
                <div class="line"></div>
                <p class="year" style="margin-top: 0.6vw">Nov. 30, 1921</p>
                <p style="margin-top: 0.3vw" >Source: Posthumous Collection of Chiang Wei-shui's Writings</p>
              </div>
            </div>
            <div class="page page-4" :class="pageClass[3]" >
              <div class="left-wrapper">
                <p class="title" style="width: 57%">A Prescription for Taiwan</p>
              </div>
              <div class="tip en-content-style">Click on the medicine bottles to see the prescription.</div>
              <div class="bottle-wrapper">
                <img src="../assets/Unit2-0/more/20230629-2-0-物件-36-臺灣診斷書-處方1.png" alt="Receipt of formal education" title="Receipt of formal education" @click="bottleBtn(0)" @keydown.enter="bottleBtn(0)" :tabindex="morePage==3?'0':'-1'">
                <img src="../assets/Unit2-0/more/20230629-2-0-物件-38-臺灣診斷書-處方2.png" alt="Libraries" title="Libraries" @click="bottleBtn(1)" @keydown.enter="bottleBtn(1)" :tabindex="morePage==3?'0':'-1'">
                <img src="../assets/Unit2-0/more/20230629-2-0-物件-40-臺灣診斷書-處方3.png" alt="Kindergarten" title="Kindergarten" @click="bottleBtn(2)" @keydown.enter="bottleBtn(2)" :tabindex="morePage==3?'0':'-1'">
                <img src="../assets/Unit2-0/more/20230629-2-0-物件-42-臺灣診斷書-處方4.png" alt="Remedial education" title="Remedial education" @click="bottleBtn(3)" @keydown.enter="bottleBtn(3)" :tabindex="morePage==3?'0':'-1'">
                <img src="../assets/Unit2-0/more/20230629-2-0-物件-44-臺灣診斷書-處方5.png" alt="Reading the newspaper" title="Reading the newspaper" @click="bottleBtn(4)" @keydown.enter="bottleBtn(4)" :tabindex="morePage==3?'0':'-1'">
              </div>

              <transition name="img-frame-fade"><img v-if="bottleImg!=''" alt="Receipt of formal education、Libraries、Kindergarten、Remedial education、Reading the newspaper" title="Receipt of formal education、Libraries、Kindergarten、Remedial education、Reading the newspaper" class="bottle-img" :src="bottleImg"></transition>

              <div class="source">
                <p>Attending physician: Chiang Wei-shui</p>
                <div class="line"></div>
                <p class="year" style="margin-top: 0.6vw">Nov. 30, 1921</p>
                <p style="margin-top: 0.3vw" >Source: Posthumous Collection of Chiang Wei-shui's Writings</p>
              </div>
            </div>
            <div class="page page-5" :class="pageClass[4]" >
              <div class="left-wrapper">
                <p class="title">A Prescription for Taiwan</p>
              </div>
              <img class="img-1" src="../assets/Unit2-0/more/20230629-2-0-物件-46-臺灣診斷書-pic.png" alt="Clinical Notes" title="Clinical Notes">
              <img class="zoom-2-btn" @click="showImg[6]=true" @keydown.enter="showImg[6]=true" :tabindex="morePage==4?'0':'-1'" style="width: 2.2vw; right: 20vw; bottom: 9.5vw" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
              <div class="img-text">
                <div class="bold-title">Clinical Notes</div>
                <div class="noto-text" >Chiang Wei-shui Cultural Foundation</div>
              </div>

            </div>
          </div>

          <transition name="img-frame-fade"><img class="arrow-btn" style="right: 9%"  src="../assets/common/right-arrow.png" alt="next page" title="next page" v-if="morePage<4" @click="arrowBtn(1)" @keydown.enter="arrowBtn(1)" tabindex="0"></transition>
          <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showMore=false">

        </div>

      </div>
    </div>
    </transition>

    <!--img0-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample9.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:21.1vw; margin:3.7vw 0 0 12vw" src="../assets/Unit2-0/20230629-2-0-物件-83-pic8-放大文物.png" alt="Taiwan Cultural Association Membership Pledge" title="Taiwan Cultural Association Membership Pledge">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 50.7vw; width: 27vw;">
            <div class="bold-title">Taiwan Cultural Association Membership Pledge</div>
            <div class="noto-text" >Authorized use provided by the Liu Ran Ju Archives</div>
            <div class="sub-title" style=" white-space: normal; text-align: left!important;">Content of the pledge: “I hereby endorse the aims of your esteemed association and wish to become a member. I will abide by the association’s rules and decisions, and do my utmost to fulfill my responsibilities.”</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--      <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample5.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:19.2vw; margin:2.5vw 0 0 22vw" src="../assets/Unit2-0/20230629-2-0-物件-86-pic11-放大文物.png" alt="Chiang Wei-shui's Letter to Lin Hsien-tang" title="Chiang Wei-shui's Letter to Lin Hsien-tang">
          </div>
          <div class="content-text" >
            <div class="bold-title">Chiang Wei-shui's Letter to Lin Hsien-tang</div>
            <div class="noto-text ">National Museum of Taiwan History 2018.011.0014.0003</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample6.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:18.5vw; margin:2.3vw 0 0 22.5vw" src="../assets/Unit2-0/20230629-2-0-物件-85-pic10-放大文物.png" alt="Chiang Wei-shui's Letter to Lin Hsien-tang" title="Chiang Wei-shui's Letter to Lin Hsien-tang">
          </div>
          <div class="content-text" >
            <div class="bold-title">Chiang Wei-shui's Letter to Lin Hsien-tang</div>
            <div class="noto-text ">National Museum of Taiwan History 2018.011.0014.0002</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample7.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:20.3vw; margin:0vw 0 0 21.5vw" src="../assets/Unit2-0/20230629-2-0-物件-84-pic9-放大文物.png" alt="Chiang Wei-shui's Letter to Lin Hsien-tang" title="Chiang Wei-shui's Letter to Lin Hsien-tang">
          </div>
          <div class="content-text" >
            <div class="bold-title">Chiang Wei-shui's Letter to Lin Hsien-tang</div>
            <div class="noto-text ">National Museum of Taiwan History 2018.011.0014.0001</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample8.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:31.6vw; margin:2.5vw 0 0 16.5vw" src="../assets/Unit2-0/20230629-2-0-物件-87-pic12-放大文物.png" alt="Chiang Wei-shui's Letter to Lin Hsien-tang" title="Chiang Wei-shui's Letter to Lin Hsien-tang">
          </div>
          <div class="content-text" >
            <div class="bold-title">Chiang Wei-shui's Letter to Lin Hsien-tang</div>
            <div class="noto-text ">National Museum of Taiwan History 2018.011.0014.0004</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample9.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full flex justify-center items-center" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:41vw; left: -15vw; top: 2.5vw" src="../assets/Unit2-0/20230629-2-0-物件-88-pic13-放大文物.png" alt="First Board of Directors of the Taiwan Cultural Association" title="First Board of Directors of the Taiwan Cultural Association">
          </div>

          <div class="content-text-2" >
            <div class="bold-title">First Board of Directors of the Taiwan Cultural Association</div>
            <div class="noto-text" >Courtesy of NTHU Library</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-0/sample9.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full flex justify-center items-center" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:41vw; top:-2vw" src="../assets/Unit2-0/more/20230629-2-0-物件-46-臺灣診斷書-pic.png" alt="Clinical Notes" title="Clinical Notes">
          </div>

          <div class="content-text">
            <div class="bold-title">Clinical Notes</div>
            <div class="noto-text" >Chiang Wei-shui Cultural Foundation</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>

    <en-intro-btn ></en-intro-btn>

    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import router from "@/router";
import LottieTest from "@/components/LottieTest";
import LottieOnce from "@/components/LottieOnce";
import {nextTick, onMounted, reactive, ref} from "vue";
// import letterSrc from "@/assets/Unit2-0/letter-move/2-0-4-信位移動態-00.png";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import AudioComp from "@/components/AudioComp";
import EnToolbar from "@/components/EnToolbar.vue";
import EnIntroBtn from "@/components/EnIntroBtn.vue";

export default {
  name: "Unit2-0",
  mixins: [myMixin],
  components:{
    LottieOnce,
    EnIntroBtn,
    EnToolbar,
    LottieTest,
    FooterFeature,
    AudioComp
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();

    const letterSrc = ref(null);
    letterSrc.value = require('@/assets/Unit2-0/letter-move/2-0-4-信位移動態-00.png');
    //letterSrc.value = require('@/assets/Unit2-0/letter-open/2-0-4-信展開動態-01.png');
    const envelopeStyle = ref('');
    const letterFade = ref(false);
    const lyricsType = ref(2);
    const videoRef = ref(null);
    const video2Ref = ref(null);
    const videoIdx = ref(1);
    const videoJson = ref(null);

    const showImg = reactive(Array.from({ length: 20 }, () => false));

    const showMore = ref(false);
    const humanText =  reactive(Array.from({ length: 4 }, () => false));
    const bottleImg = ref(require('@/assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-37-臺灣診斷書-處方答1.png'));
    const pageClass =  reactive(Array.from({ length: 5 }, () => 'scale-0'));
    const morePage =  ref(0);
    pageClass[morePage.value] = '';

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if(entry.target == videoRef.value){
            videoJson.value.anim.play()
          }
        }
      });
    }, {
      root: null, // 觀察器根節點，null 表示整個瀏覽器視窗
      threshold: 0.5 // 設定觸發 callback 的交叉比例，0 表示完全不交叉，1 表示完全交叉
    });

    const tabindex = ref(0);

    onMounted(() => {
      observer.observe(videoRef.value);
    });

    function routeBtn(path){
      router.push(path);
    }


    let letterInterval = null;
    let letterIndex = 0;
    let letterOpen = false;

    function letterUp() {
      if(letterOpen == true) return;
      clearInterval(letterInterval);
      letterIndex = 0;

      letterInterval = setInterval(()=>{

        letterIndex = (letterIndex + 1);

        letterSrc.value = require('@/assets/Unit2-0/letter-move/2-0-4-信位移動態-'+letterIndex.toString().padStart(2, '0') +'.png');

        if(letterIndex >= 12){
          clearInterval(letterInterval);
        }
      }, 13);
    }

    function letterDown() {
      if(letterOpen == true) return;
      clearInterval(letterInterval);
      letterIndex = 13;

      letterInterval = setInterval(()=>{

        letterIndex = (letterIndex - 1);

        letterSrc.value = require('@/assets/Unit2-0/letter-move/2-0-4-信位移動態-'+letterIndex.toString().padStart(2, '0') +'.png');

        if(letterIndex <= 0){
          clearInterval(letterInterval);
        }
      }, 13);
    }

    function envelopeOpen() {
      if(letterOpen == true) return;
      clearInterval(letterInterval);
      letterIndex = 1;
      letterOpen = true;

      letterInterval = setInterval(()=>{

        letterIndex = (letterIndex + 1);

        letterSrc.value = require('@/assets/Unit2-0/letter-open/2-0-4-信展開動態-'+letterIndex.toString().padStart(2, '0') +'.png');


        if(letterIndex >= 40){
          letterFade.value = true;
          clearInterval(letterInterval);
        }
      }, 40);
    }

    function videoRightBtn(){
      videoIdx.value=2;
      video2Ref.value.anim.play();
    }

    function humanBtn(index){
      for(let i=0; i <humanText.length; i++)
        humanText[i] = false;
      humanText[index] = true;
    }

    async function bottleBtn(num){
      bottleImg.value = '';
      await nextTick();

      switch (num){
        case 0: bottleImg.value = require('@/assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-37-臺灣診斷書-處方答1.png'); break;
        case 1: bottleImg.value = require('@/assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-39-臺灣診斷書-處方答2.png'); break;
        case 2: bottleImg.value = require('@/assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-41-臺灣診斷書-處方答3.png'); break;
        case 3: bottleImg.value = require('@/assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-43-臺灣診斷書-處方答4.png'); break;
        case 4: bottleImg.value = require('@/assets/Unit2-0/en/more/20230706-EN網站-2-0-物件-45-臺灣診斷書-處方答5.png'); break;
      }
    }

    function arrowBtn(dir){
      if(dir==-1){
        pageClass[morePage.value] = "page-left-out";
        morePage.value -= 1;
        pageClass[morePage.value] = "page-left-in";
      }
      else if(dir == 1){
        pageClass[morePage.value] = "page-right-out";
        morePage.value += 1;
        pageClass[morePage.value] = "page-right-in";
      }
    }

    function moreBtn(){
      showMore.value = true;
      morePage.value = 0;
      for (let i=0; i <5; i++){
        pageClass[i] = 'scale-0';
      }
      pageClass[0] = '';
      tabindex.value = -1;
    }

    let firstLyrics = false;
    function lyricsBtn(type){
      if(!firstLyrics){
        envelopeStyle.value ='letter-div-open';
        envelopeOpen();
        lyricsType.value = 1;
        firstLyrics = true;
      }else{
        lyricsType.value = type;
      }
    }

    function escEvent(){
      if(showMore.value && !showImg[6]){
        showMore.value = false;
        tabindex.value = 0;
        document.getElementById('more-btn').focus();
      }

      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }


    return{
      routeBtn,
      letterSrc,
      letterUp,
      letterDown,
      envelopeStyle,
      envelopeOpen,
      letterFade,
      lyricsType,
      showImg,
      videoRef,
      video2Ref,
      videoIdx,
      videoRightBtn,
      videoJson,
      humanText,
      humanBtn,
      bottleImg,
      bottleBtn,
      morePage,
      showMore,
      lyricsBtn,
      moreBtn,
      arrowBtn,
      pageClass,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      tabindex,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit2-0.css"></style>

<!--<style scoped>-->

<!--.wrap{-->
<!--  position: relative;-->
<!--  width: 100%;-->
<!--}-->

<!--.sample{-->
<!--  position: relative;-->
<!--  width: 100%;-->
<!--}-->

<!--.test-btn{-->
<!--  position: absolute;-->
<!--  //background: black;-->
<!--  width: 59vw;-->
<!--  height: 31vw;-->
<!--  bottom: 6%;-->
<!--  left: 14%;-->
<!--  cursor: pointer;-->
<!--}-->


<!--</style>-->
