<template>
  <div v-if="show=='true'" style="position: absolute; width: 100%; height: 100%; z-index: 1" @click="closeBtn"> </div>

<!--  :class="show=='true'?'map-info-open':'map-info-close'"-->
  <transition name="img-frame-fade">
    <div v-if="language=='ch' && show=='true'" class="map-info " :style="pos" >
      <img :src="img">
      <div class="name">{{ name }} <img class="close" src="../assets/Unit1-1/close.png" @click="closeBtn" @keydown.enter="closeBtn" :tabindex="show=='true'?0:-1" alt="關閉" title="關閉"></div>
      <div class="relative flex">
        <div class="year" :style="type==1?'':'width:4vw'">{{year}}</div>
        <div class="content" v-html="content" :style="type==1?'':'width:8.5vw'"></div>
      </div>
    </div>
  </transition>


  <transition name="img-frame-fade">
    <div v-if="language=='en' && show=='true'" class="map-info en " :style="pos" >
      <img :src="img">
      <div class="name">{{ name }} <img class="close" src="../assets/Unit1-1/close.png" @click="closeBtn" @keydown.enter="closeBtn" :tabindex="show=='true'?0:-1" alt="Close" title="Close"></div>
      <div class="relative flex">
        <div class="year" :style="type==1?'':'width:4vw'">{{year}}</div>
        <div class="content" v-html="content" :style="type==1?'':'width:8.5vw'"></div>
      </div>
    </div>
  </transition>


</template>

<script>


import {getCurrentInstance, onBeforeMount} from "vue";

export default {
  name: "MapInfo",
  props:{
    name:String,
    year:String,
    content:String,
    img:String,
    pos:String,
    show:String,
    type:{
      type:Number,
      default:1,
    },
    language:{
      type:String,
      default:"ch",
    }
  },

  setup(){

    let instance = null;

    onBeforeMount(()=>{
      instance = getCurrentInstance();
    });

    function closeBtn() {

      if(instance != null){
        instance.emit('close-event');
      }
    }

    return{
      closeBtn
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped>
.map-info{
  position: absolute;
  width: 17.2vw;
  border-radius: 1vw;
  padding: 1.5vw 0 0 1.9vw;
  font-family: NotoSansTC-Bold;
  font-size: calc(0.9vw * var(--font-size));
  //opacity: 0;
  //z-index: 1;
  transition: opacity 0.2s ease-in-out;
}

.map-info-open{
  opacity: 1;
  z-index: 1;
}

.map-info-close{
  opacity: 0;
}


.map-info img{
  position: absolute;
  width: 100%;
  top: 0%;
  left: 0%;
}

.map-info .name{
  position: relative;
  color: #ea5518;
  text-align: left;
}

.map-info .year{
  position: relative;
  text-align: left;
  width: 3vw;
}

.map-info .content{
  position: relative;
  text-align: justify;
  width: 10.5vw;
}

.en .name{
  position: relative;
  font-family: Arial-Bold;
  color: #ea5518;
  text-align: left;
}

.en .year{
  position: relative;
  font-family: Arial-Bold;
  text-align: left;
  width: 3vw;
  font-size: calc(0.85vw * var(--font-size));
}

.en .content{
  position: relative;
  font-family: Arial;
  text-align: left;
  width: 9.5vw;
  font-size: calc(0.77vw * var(--font-size));
}

.map-info .close{
  position: absolute;
  top: 24%;
  left: 82%;
  width: 1vw;
  cursor: pointer;
}

.map-info .close:hover {
  filter: brightness(0.6);
}

.map-info .close-panel{

}
</style>
