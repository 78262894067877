<template>
  <div class="nav-wrap" :style='style' >
<!--    <img v-if="type==1" @click="routeBtn('/')" class="logo" src="../assets/enter/logo.png" title="樂為世界人 Blessed to be a Global Citizen" alt="樂為世界人 Blessed to be a Global Citizen">-->
<!--    <img v-if="type==2" @click="routeBtn('/')" class="logo" src="../assets/logo-white.png" title="樂為世界人 Blessed to be a Global Citizen" alt="樂為世界人 Blessed to be a Global Citizen">-->
    <div class="jump-to-main" tabindex="0" @click="focusRouterLinkById('router-link-c')" @keydown.enter="focusRouterLinkById('router-link-c')" alt="跳至中央內容區塊" title="跳至中央內容區塊">跳至中央內容區塊</div>

    <router-link class="blind-btn btn" accesskey="U"  to="" >
      <img class="w-full" src="../assets/common/blind-btn.png" alt=":::上方選單區" title=":::上方選單區" >
    </router-link>


    <div class="title-logo" @click="routeBtn('/')"  @keydown.enter="routeBtn('/')" ref="imgRef" tabindex="0">
      <img v-if="(titleLogo=='orange') || (titleLogo==''&&color=='orange')"  class="w-full" src="@/assets/common/logo-1.png" alt="樂為世界人 首頁" title="樂為世界人 首頁">
      <img v-if="(titleLogo=='brown') || (titleLogo==''&&color=='brown')"  class="w-full" src="@/assets/common/logo-2.png" alt="樂為世界人 首頁" title="樂為世界人 首頁">
      <img v-if="(titleLogo=='white') || (titleLogo==''&&color=='white')"  class="w-full" src="@/assets/common/logo-3.png" alt="樂為世界人 首頁" title="樂為世界人 首頁">
    </div>

<!--    :style="color=='brown'||color=='orange'?'color: #4e241f; text-shadow:0.05vw 0.05vw #ffffff;':'color: #ffffff; text-shadow:0.05vw 0.05vw #4e241f;'"-->
    <div style="position: absolute; top: 25%; right: 10%; width: 24.6vw; display: flex; justify-content: space-between;" :style="unitBtn()">
      <div class="unit-btn" @click="routeBtn('/unit1-1')" @keydown.enter="routeBtn('/unit1-1')" tabindex="0">
<!--        style="height: 8.7vw;"-->
        <div class="unit-bar" >
          <div class="icon-space"></div>
<!--          <div class="small-unit-btn" @click="routeBtn('/unit1-1')">鳥瞰世界</div>-->
          <div class="small-unit-btn" @click.stop="routeBtn('/unit1-2')" tabindex="0">思潮滾動世界</div>
        </div>
        <img class="w-full relative" :src="unit=='1' ? require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第一單元MENU按鈕-彩.png'):require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第一單元MENU按鈕-白.png')" alt="鳥瞰世界" title="鳥瞰世界">
        <div class="unit-label" >鳥瞰世界</div>
      </div>
      <div class="unit-btn" @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/unit2-0')" tabindex="0">
<!--        style="height: 12.7vw;"-->
        <div class="unit-bar" >
          <div class="icon-space" ></div>
<!--          <div class="small-unit-btn" @click="routeBtn('/unit2-0')">文協向前衝</div>-->
          <div class="small-unit-btn" @click.stop="routeBtn('/unit2-1')" tabindex="0">臺灣人的臺灣</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit2-2')" tabindex="0">為農工大眾主張</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit2-3')" tabindex="0">左派與右派</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit2-4')" tabindex="0">政治結社的走向</div>
        </div>
        <img class="w-full relative" :src="unit=='2' ? require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第二單元MENU按鈕-彩.png'):require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第二單元MENU按鈕-白.png')" alt="文協向前衝" title="文協向前衝">
        <div class="unit-label">文協向前衝</div>
      </div>
      <div class="unit-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0">
<!--        style="height: 20.3vw;"-->
        <div class="unit-bar" >
          <div class="icon-space"></div>
<!--          <div class="small-unit-btn" @click="routeBtn('/enter')">廟埕拚文化</div>-->
          <div class="small-unit-btn" @click.stop="routeBtn('/unit3-1')" tabindex="0">文化講演會</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit3-2')" tabindex="0">演劇與電影</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit3-3')" tabindex="0">言論機關</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit3-4')" tabindex="0">社會歌謠</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit3-5')" tabindex="0">世界語</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit4-1')" tabindex="0">時鐘塔</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit4-2')" tabindex="0">文化基地</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit4-3')" tabindex="0">交誼機關</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit4-4')" tabindex="0">風俗改良</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/unit4-5')" tabindex="0">自己的紀念日</div>
        </div>
        <img class="w-full relative" :src="unit=='3' ? require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第三單元MENU按鈕-彩.png'):require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第三單元MENU按鈕-白.png')" alt="廟埕拚文化" title="廟埕拚文化">
        <div class="unit-label">廟埕拚文化</div>
      </div>
      <div class="unit-btn" @click="routeBtn('/unit6-2')" @keydown.enter="routeBtn('/unit6-2')" tabindex="0">
<!--        style="height: 7.2vw;"-->
        <div class="unit-bar" >
          <div class="icon-space" style="height: 5.8vw"></div>
<!--          <div class="small-unit-btn" @click="routeBtn('/unit6-2')">番外篇</div>-->
        </div>
        <img  class="w-full relative" :src="unit=='4' ? require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第四單元MENU按鈕-彩.png'):require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第四單元MENU按鈕-白.png')" alt="番外篇" title="番外篇">
        <div class="unit-label">番外篇</div></div>
    </div>


    <div v-if="(titleLogo==''&&(color=='brown' || color=='orange')) || titleLogo=='brown' || titleLogo=='orange' " ><menubar-comp :route-name="routeName"></menubar-comp></div>
    <div v-if="(titleLogo==''&&color=='white') || titleLogo=='white'" ><menubar-comp color="white" :route-name="routeName"></menubar-comp></div>

  </div>

</template>

<script>

import router from "@/router";
import MenubarComp from "@/components/MenubarComp";
import {onMounted, ref} from "vue";

export default {
  name: "ToolbarComponent",
  components:{
    MenubarComp
  },
  props:{
    type:String,
    style:String,
    unit:{
      type:String,
      default:'1',
    },
    color:{
      type:String,
      default: "orange",
    },
    routeName:{
      type:String,
      default: "",
    },
    fontColor:{
      type:String,
      default:'#4e241f',
    }
  },
  setup(props){

    const titleLogo = ref('');

    function openUrl(url, type){
      window.open(url, type);
    }

    function routeBtn(path){
      router.push(path);
    }

    function findContainingDiv() {
      const imgElement = document.querySelector(".title-logo");
      const containers = document.querySelectorAll(".part-color");

      for (const container of containers) {
        const containerRect = container.getBoundingClientRect();
        const imgRect = imgElement.getBoundingClientRect();

        if (imgRect.bottom >= containerRect.top && imgRect.bottom <= containerRect.bottom) {
          let hex = rgbToHex(window.getComputedStyle(container).backgroundColor);
          //console.log(hex);

          switch (hex){
            case "#ffffff":
              titleLogo.value = "orange";
              break;
            case "#dfe5d7":
              titleLogo.value = "brown";
              break;
            default:
              titleLogo.value = "white";
              break;
          }
          //break;
        }
      }

      function rgbToHex(rgb) {
        // 將RGB格式的顏色值拆分成三個部分：r, g, b
        const rgbValues = rgb.match(/\d+/g).map(Number);

        // 將十進制的 r, g, b 值轉換成十六進制
        const r = rgbValues[0].toString(16).padStart(2, '0');
        const g = rgbValues[1].toString(16).padStart(2, '0');
        const b = rgbValues[2].toString(16).padStart(2, '0');

        // 組合成 HEX 格式並返回
        return `#${r}${g}${b}`;
      }
    }

    function unitBtn(){
      if(titleLogo.value == ''){
        return "color:"+props.fontColor+";";
      }else{
        switch (titleLogo.value){
          case "orange":
            return "color:#4e241f;";
          case "white":
            return "color:#ffffff;";
          case "brown":
            return "color:#4e241f;";
        }
      }
    }

    onMounted(() => {
      findContainingDiv();

      window.addEventListener("scroll", findContainingDiv);
    });

    function focusRouterLinkById(id) {
      var routerLink = document.getElementById(id);

      if (routerLink) {
        routerLink.focus();
      }
    }

    return{
      openUrl,
      routeBtn,
      titleLogo,
      unitBtn,
      focusRouterLinkById
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/toolbar.css"></style>
