<template>
  <!--img0--><!--ver-->
  <transition name="img-frame-fade"  >
    <div class="img-frame-div"  v-show="show">
      <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-02.png">-->

      <div class="black-bg" @click="show=false"></div>
      <div class="frame">

        <img class="relative" style="width:35.7vw; margin:2.8vw 0 0 13.8vw" src="../assets/Unit3-2/20230414-物件-3-2-07-pic5-放大文物.png">

        <div class="content-text" style="position: absolute; top:30.7vw; left: 21.5vw; width: 25.5vw;">
          <div >
            <div class="bold-title">臺南文化劇團演出實況 1927年10月14-17日</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣文學館典藏  林章峯捐贈</div>
          </div>
        </div>

        <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="關閉視窗" title="關閉視窗" >
        <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="關閉視窗" title="關閉視窗" >
        <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="show=false">
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ImgVertical",
  data(){
    return{
      show:true,
    }
  },
}

</script>

<style src="../assets/css/common.css"></style>
<style scoped>
.img-frame-div  .black-bg{
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
}

.img-frame-div .frame .content-text{
  color: white;
}
</style>
