<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="1" unit="2" route-name="unit2-4" color="orange"></toolbar>

<!--    <img class="sample" src="../assets/Unit2-4/sample2.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="people">
        <LottieTest :json="require('@/assets/Unit2-4/20230703-2-4-0.json')" alt="政治結社的走向" title="政治結社的走向"></LottieTest>
      </div>

      <img class="pic-1" src="../assets/Unit2-4/20230407-2-4-物件-op-01-首圖１.png" alt="臺灣地方自治聯盟發起人大會 1930年8月17日" title="臺灣地方自治聯盟發起人大會 1930年8月17日">
      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; left: 78.2%; z-index: 3" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">

      <div class="flag">
        <LottieTest :json="require('@/assets/Unit2-4/20230703-2-4-0-flag.json')" alt="政治結社的走向" title="政治結社的走向"></LottieTest>
      </div>


      <div class="title-logo">政治結社的走向</div>
    </div>

    <div class="part2 part-color">
      <div class="title-img"><img style="width: 31vw" src="../assets/Unit2-4/20230407-2-4-物件-op-03-裝飾字1-政治結社.png" alt="政治結社" title="政治結社"></div>
      <div class="content-text" ref="part2Class" tabindex="0">
        <div style="margin-left: 32.5vw">臺灣民眾黨</div>
        <div style="margin-left: 7.4vw">臺灣地方自治聯盟</div>
      </div>
      <img class="pic-1 " :class="picInitAnim" :style="picStyle[0]" src="../assets/Unit2-4/20230407-2-4-物件-op-04-插圖1.png" alt="臺灣民眾黨" title="臺灣民眾黨">
      <img class="pic-2 " :class="picInitAnim"  :style="picStyle[1]" src="../assets/Unit2-4/20230407-2-4-物件-op-05-插圖２.png" alt="臺灣地方自治聯盟" title="臺灣地方自治聯盟">

      <div class="content-frame" ref="contentFrame" tabindex="0">
        <div style="margin-right: 10vw">
          <p class="title">臺灣民眾黨</p>
          <p class="text">1927年5月成立的「臺灣民眾黨」，綱領為「確立民本政治、合理經濟組織、改革社會制度」，以蔣渭水為中心，偏向民族解放運動路線，也關心農工運動，發展工友會、農民協會、青年會等組織，加盟團體會員達上萬人，曾以電報向國際聯盟申述總督府的鴉片政策，抗議鎮壓霧社事件，1931年2月遭總督府發令解散。</p>
        </div>
        <div>
          <p class="title">臺灣地方自治聯盟</p>
          <p class="text">1930年8月成立的「臺灣地方自治聯盟」，由林獻堂、楊肇嘉及蔡式穀等人組成，著眼於地方自治理想的落實，主張民主主義，以推動普通選舉權、落實公民權為目標，1935年積極參與各級協議會選舉，1937年8月因長期財政支絀與總體環境的改變，終告解散。</p>
        </div>
      </div>

    </div>

    <div class="part3 part-color">
      <div class="content">
        <p class="title-style">快快來蓋章！</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">民眾黨宣傳單：大家覺醒起來要求真正的地方自治吧！</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2020.006.0200</p>
        <p class="content-style" style="margin-top: 0.5vw;">為臺灣民眾黨印製自治促進運動宣傳單，內容提到要推動具有議決與預算審查權力的議會，鼓勵民眾來蓋章聯署，特別強調若無印章蓋手印也可以。</p>
      </div>

      <div class="pic-2">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-08-pic3.png" alt="民眾黨宣傳單：大家覺醒起來要求真正的地方自治吧！" title="民眾黨宣傳單：大家覺醒起來要求真正的地方自治吧！">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 6%; right: 4%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="pic-1">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-07-pic2.png" alt="臺灣民眾黨第二屆黨員大會 1928年7月15日" title="臺灣民眾黨第二屆黨員大會 1928年7月15日">
        <img class="zoom-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 11%; right: 4%;" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>


      <div class="people">
        <LottieTest :json="require('@/assets/Unit2-4/2-4-1.json')" alt="人群" title="人群"></LottieTest>
      </div>
    </div>

    <div class="part4 part-color">
      <div class="content-1">
        <p class="title-style">遍布全臺的黨員名單</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">民眾黨黨員名簿 1928年7月，共707名</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2020.032.0258</p>
        <p class="content-style" style="margin-top: 0.5vw;">日治時期民間社會運動機構中，臺灣民眾黨留有最詳盡的成員名冊。這份1928年的名冊，共包含16個支部，中央幹部列名於臺北支部，直屬黨員以文山郡（今新北市新店、深坑、臺北市文山等地）、北斗郡（及彰化縣南部一帶）、能高郡（今南投縣埔里鎮、國姓鄉等地）較多。</p>
      </div>
      <div class="content-2">
        <p class="title-style">拒絕亡國滅種，反鴉片！</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">鴉片吸食撲滅3色傳單 1930年1月</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2020.032.0277.0002-0004</p>
        <p class="content-style" style="margin-top: 0.5vw;">清末以來臺灣鴉片流行民間，臺灣總督府以漸禁、專賣制度消極管控，並牟利於財團。1929年1月8日頒布施行規則，重新發給吸食者許可證，激起民間多方的議論，國際聯盟遠東鴉片調查團於是派員抵臺調查。在國聯調查期間，蔣渭水率領的民眾黨積極抗議，發布電文，向調查團當面表達抗議。同時也在民間發動宣傳，印製三種顏色的宣傳單發送。</p>
      </div>
      <div class="pic-1">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-10-pic4.png" alt="民眾黨黨員名簿 1928年7月，共707名" title="民眾黨黨員名簿 1928年7月，共707名">
        <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 4%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>


      <div class="pic-2" :class="picClass[0]">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-11-pic5.png" alt="鴉片吸食撲滅3色傳單 1930年1月" title="鴉片吸食撲滅3色傳單 1930年1月" >
      </div>
      <div class="pic-3" :class="picClass[1]">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-12-pic6.png" alt="鴉片吸食撲滅3色傳單 1930年1月" title="鴉片吸食撲滅3色傳單 1930年1月">
      </div>
      <div class="pic-4" :class="picClass[2]">
        <img class="w-full" src="../assets/Unit2-4/20230407-2-4-物件-op-08-pic3.png" alt="鴉片吸食撲滅3色傳單 1930年1月" title="鴉片吸食撲滅3色傳單 1930年1月">
      </div>

      <transition name="img-frame-fade"><img class="arrow left-arrow" v-if="picIndex > 0" @click="leftBtn" @keydown.enter="leftBtn" tabindex="0" src="../assets/common/left-arrow-white.png" alt="上一頁" title="上一頁"></transition>

      <img class="zoom-2-btn" @click="showImg[4+picIndex]=true" @keydown.enter="showImg[4+picIndex]=true" tabindex="0"  style="width: 2.2vw; bottom: 10%; right: 17%; z-index: 4" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">

      <transition name="img-frame-fade"><img class="arrow right-arrow" v-if="picIndex < 2" @click="rightBtn" @keydown.enter="rightBtn" tabindex="0"  src="../assets/common/right-arrow-white.png" alt="下一頁" title="下一頁"></transition>


    </div>

    <div class="part5 part-color">
      <div class="unit-btn" style="top: 23%; left: 12%; " @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/unit2-0')" tabindex="0">
        <img class="w-full"  src="../assets/Unit2-0/文協向前衝按鈕.png" alt="文協向前衝" title="文協向前衝">
        <p>文協向前衝</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 38.5%; ">
        <img class="w-full"  src="../assets/Unit2-0/臺灣人的臺灣按鈕.png" alt="臺灣人的臺灣" title="臺灣人的臺灣" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0">
        <p>臺灣人的</p>
        <p style="top:47%">臺灣</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 65%; ">
        <img class="w-full"  src="../assets/Unit2-0/為農工大眾主張按鈕.png" alt="為農工大眾主張" title="為農工大眾主張" @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" tabindex="0">
        <p>為農工大眾</p>
        <p style="top:47%">主張</p>
      </div>
      <div class="unit-btn" style="top: 57%; left: 25%; ">
        <img class="w-full"  src="../assets/Unit2-0/左派與右派按鈕.png" alt="左派與右派" title="左派與右派" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0">
        <p>左派與右派</p>
      </div>
      <div class="unit-btn" style="top: 57%; left: 51.5%; ">
        <img class="w-full"  src="../assets/Unit2-0/20230724-2-4-物件-24-政治結社的走向按鈕-淡.png" alt="政治結社的走向" title="政治結社的走向" @click="routeBtn('/unit2-4')" @keydown.enter="routeBtn('/unit2-4')" tabindex="0">
        <p>政治結社的</p>
        <p style="top:47%">走向</p>
      </div>
    </div>

    <!--img0--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-04.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35vw; margin:3.5vw 0 0 3.1vw" src="../assets/Unit2-4/20230703-物件-2-4-02-pic1-放大文物.png" alt="臺灣地方自治聯盟發起人大會" title="臺灣地方自治聯盟發起人大會">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 40vw; width: 15.4vw;">
            <div class="bold-title">臺灣地方自治聯盟發起人大會</div>
            <div class="bold-title">1930年8月17日</div>
            <div class="noto-text" style="margin-top: 0vw">六然居資料室 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-05.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:40.5vw; margin:1.6vw 0 0 11.8vw" src="../assets/Unit2-4/20230703-物件-2-4-09-pic3-放大文物.png" alt="民眾黨宣傳單：大家覺醒起來要求真正的地方自治吧！" title="民眾黨宣傳單：大家覺醒起來要求真正的地方自治吧！">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 18.5vw; width: 27.7vw;">
            <div class="bold-title">民眾黨宣傳單：大家覺醒起來要求真正的地方自治吧！</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2020.006.0200</div>
          </div>

            <div class="feature">
              <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
              <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
              <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
            </div>
          </div>
      </div>
    </transition>
    <!--img2--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-07.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:33.5vw; margin:4.6vw 0 0 3.4vw" src="../assets/Unit2-4/20230703-物件-2-4-07-pic2-放大文物.png" alt="臺灣民眾黨第二屆黨員大會" title="臺灣民眾黨第二屆黨員大會">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 38.7vw; width: 21vw;">
            <div class="bold-title">臺灣民眾黨第二屆黨員大會</div>
            <div class="bold-title">1928年7月15日</div>
            <div class="noto-text" style="margin-top: 0vw">國立臺灣文學館典藏  林章峯捐贈</div>
            <div class="content-style" style="margin-top: 0.5vw;text-align: justify">於臺南市南座(位於今臺南西門圓環)召開，此為會後人員合照。據報載當夜講演會，全臺眾名嘴雲集，連開三處會場 。</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-06.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:41.5vw; margin:1.4vw 0 0 11vw" src="../assets/Unit2-4/20230703-物件-2-4-10-pic4-放大文物.png" alt="民眾黨黨員名簿 1928年7月，共707名" title="民眾黨黨員名簿 1928年7月，共707名">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 21.5vw; width: 19.5vw;">
            <div class="bold-title">民眾黨黨員名簿 1928年7月，共707名</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2020.032.0258</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-08.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37vw; margin:2vw 0 0 13vw" src="../assets/Unit2-4/20230703-物件-2-4-11-pic5-放大文物.png" alt="鴉片吸食撲滅3色傳單 1930年1月" title="鴉片吸食撲滅3色傳單 1930年1月">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 21.5vw; width: 19.6vw;">
            <div class="bold-title">鴉片吸食撲滅3色傳單 1930年1月</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2020.032.0277.0002</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-08.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37vw; margin:2vw 0 0 13vw" src="../assets/Unit2-4/20230703-物件-2-4-12-pic6-放大文物.png" alt="鴉片吸食撲滅3色傳單 1930年1月" title="鴉片吸食撲滅3色傳單 1930年1月">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 21.5vw; width: 19.6vw;">
            <div class="bold-title">鴉片吸食撲滅3色傳單 1930年1月</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2020.032.0277.0003</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[6]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-4/sample/2023040-對位-2-4-op-08.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37vw; margin:2vw 0 0 13vw" src="../assets/Unit2-4/20230703-物件-2-4-13-pic7-放大文物.png" alt="鴉片吸食撲滅3色傳單 1930年1月" title="鴉片吸食撲滅3色傳單 1930年1月">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 21.5vw; width: 19.6vw;">
            <div class="bold-title">鴉片吸食撲滅3色傳單 1930年1月</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2020.032.0277.0004</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>

    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import LottieTest from "@/components/LottieTest";
import {onMounted, onUnmounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import flipAudio from "@/assets/Unit3-1/insert_paper.mp3";

export default {
  name: "Unit2-4",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const contentFrame = ref(null);
    const picStyle = reactive(Array.from({ length: 2 }, () => ''));
    const part2Class = ref(null);
    const picInitAnim = ref('opacity-0');
    const picClass = reactive(Array.from({ length: 3 }, () => ''));
    const showImg = reactive(Array.from({ length: 11 }, () => false));
    const flipAudio = require('@/assets/Unit2-4/audio/book.mp3');
    const flipEle = new Audio(flipAudio);
    flipEle.volume = 0.5;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          //console.log('目標元素進入畫面');
          if(entry.target == part2Class.value){
            part2Class.value.classList.add('content-text-anim');
            picInitAnim.value = 'pic-init-anim';
          }
        }
      });
    }, {
      root: null, // 觀察器根節點，null 表示整個瀏覽器視窗
      threshold: 0.8 // 設定觸發 callback 的交叉比例，0 表示完全不交叉，1 表示完全交叉
    });

    function routeBtn(path){
      router.push(path);
    }

    onMounted(()=>{
      observer.observe(part2Class.value);

      scrollEvent();

      window.addEventListener('scroll', scrollEvent);
    });

    onUnmounted(()=>{
      window.removeEventListener('scroll', scrollEvent);
    });

    function scrollEvent(){
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollTriggerTop = contentFrame.value.offsetTop - window.innerHeight;
      const scrollTriggerMiddle = scrollTriggerTop + (contentFrame.value.offsetHeight * 0.5);
      const scrollPercentage = Math.max(0, Math.min(1, (scrollTop - scrollTriggerMiddle) / window.innerHeight));

      if(scrollPercentage >= 0.9){
        picStyle[0] = 'top:'+(21.5+(59.9-21.5)*scrollPercentage)+"%; left:"+(28.5+(21.5-28.5)*scrollPercentage)+"%; width:"+(20.5+(16.5-20.5)*scrollPercentage)+"vw;";
        picStyle[1] = 'top:'+(21.5+(59.9-21.5)*scrollPercentage)+"%; left:"+(51+(61.8-51)*scrollPercentage)+"%; width:"+(20.5+(16.5-20.5)*scrollPercentage)+"vw;";
      }else{
        picStyle[0] = '';
        picStyle[1] = '';
      }
    }

    const picIndex = ref(0);
    function rightBtn(){
      if(picIndex.value == 0){
        picClass[1] = 'pic-3-in';
        picClass[2] = 'pic-4-in';

        setTimeout(()=>{
          picClass[0] = 'move-out';

          setTimeout(()=>{
            picClass[2] = 'pic-4-out';
          }, 200);

          picIndex.value++;
        },200);

      }
      else if(picIndex.value == 1){
        picClass[2] = 'pic-4-in';
        picClass[1] = 'move-out';

        picIndex.value++;
      }
      flipEle.play();
    }

    function leftBtn(){
      //console.log(picIndex.value)
        if(picIndex.value == 1) {
          picClass[0] = 'pic-2-move-in';

          setTimeout(()=>{
            picClass[1] = 'pic-3-out';
          },500);

          picIndex.value--;
        }
        else if(picIndex.value == 2){
          picClass[1] = 'pic-3-move-in';
          setTimeout(()=>{
            picClass[2] = 'pic-4-out';
          },500);
          picIndex.value--;

        }
      flipEle.play();
      }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }


    return{
      routeBtn,
      contentFrame,
      picStyle,
      part2Class,
      picInitAnim,
      picClass,
      rightBtn,
      leftBtn,
      picIndex,
      showImg,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit2-4.css"></style>

