<template>
  <div class="wrap"  @mousemove="handleMouseMove" @keydown.esc="escEvent">
    <EnToolbar type="1" unit="3" route-name="unit3-4" color="brown"></EnToolbar>

<!--    <img class="sample" src="../assets/Unit3-4/sample/20230920-EN網站-對位3-4-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="title-logo" style="z-index: 2">Social Ballads</div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-4/3-4-0.json')" alt="Social Ballads" title="Social Ballads" ></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit3-4/20230424-3-4-物件-01-社會歌謠2.png" alt="Social Ballads" title="Social Ballads" >
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit3-4/3-4-1.json')"  alt="Social Ballads" title="Social Ballads"  ></LottieTest>
      </div>
    </div>

    <div class="part2 part-color">
        <img class="logo-text" src="../assets/Unit3-4/en/20230708-EN網站-3-4-物件-02-裝飾字1-歌謠對抗.png" alt="Music Fighting" title="Music Fighting">
        <div class="content en-content-style">
          In political movements, crafting new songs can be a potent propaganda tool to garner public support and recognition. “The Song of the Taiwan Cultural Association” was written by Chiang Wei-shui when the association was founded. Its tune was taken from “Brave Sailor,” a Japanese military anthem familiar to young people in Taiwan at that time, and the “The First Political Commemoration Day,” which appeared in a singing textbook. Chiang’s song showed a significant shift in mindset. In 1929, the Taiwan Education Association, which promoted national education, held a “Song of Taiwan” songwriting contest. It received a great deal of publicity, and inspired Tsai Pei-huo to write new songs. His classic work is “Our Taiwan.”
        </div>
        <img class="img-1" src="../assets/Unit3-4/20230413-3-4-物件-03-插畫１2.png" alt="Music Fighting" title="Music Fighting">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-4/3-4-2.json')" alt="Music Fighting" title="Music Fighting"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
<!--          <img class="sample" src="../assets/Unit3-4/sample/20230413-對位-3-4-12.png">-->
      <img class="circle-anim" :src="circleAnim">

      <transition name="img-frame-fade"><img v-if="imgType==1 && cdNum==0" class="cd-1" src="../assets/Unit3-4/20230413-3-4-物件-05-pic1-祭典歌2.png" alt="“Festival Day” Columbia Records 1929" title="“Festival Day” Columbia Records 1929"></transition>
      <transition name="img-frame-fade"><img v-if="imgType==2 && cdNum==0" class="img-1" src="../assets/Unit3-4/20230413-3-4-物件-06-pic2-祭典歌歌譜2.png" alt="“Festival Day” Columbia Records 1929 Lyrics" title="“Festival Day” Columbia Records 1929 Lyrics"></transition>

      <transition name="img-frame-fade"><img v-if="imgType==1 && cdNum==1" class="cd-1" src="../assets/Unit3-4/20230413-3-4-物件-07-pic4-水牛歌2.png" alt="“Water Buffalo”Columbia Records, 1929" title="“Water Buffalo”Columbia Records, 1929"></transition>
      <transition name="img-frame-fade"><img v-if="imgType==2 && cdNum==1" class="img-1" src="../assets/Unit3-4/20230413-3-4-物件-08-pic5-水牛歌歌譜2.png" alt="“Water Buffalo”Columbia Records, 1929 Lyrics" title="“Water Buffalo”Columbia Records, 1929 Lyrics"></transition>


      <transition name="img-frame-fade"><img v-if="cdNum==2" class="cd-1" src="../assets/Unit3-4/20230413-3-4-物件-09-pic6-咱臺灣2.png" alt="“Our Taiwan” Columbia Records, 1935" title="“Our Taiwan” Columbia Records, 1935"></transition>
      <transition name="img-frame-fade"><img v-if="cdNum==3" class="img-2" src="../assets/Unit3-4/20230413-3-4-物件-10-pic7-臺灣文化協會會歌2.png" alt="“Our Taiwan” Columbia Records, 1935 Lyrics" title="“Our Taiwan” Columbia Records, 1935 Lyrics" ></transition>

      <div class="content" >
        <div v-if="cdNum==0" style="padding-left: 1vw">
          <p class="content-style" style="margin-top: 1.2vw; display: flex; align-items: center;font-family: Arial-Bold">“Festival Day” Columbia Records 1929
            <span v-if="imgType==1" class="blue-btn" @click="imgType=2" @keydown.enter="imgType=2" tabindex="0" style="height: 1.5vw; display: flex;" alt="Lyrics" title="Lyrics">Lyrics</span>
            <span v-if="imgType==2" class="blue-btn" @click="imgType=1" @keydown.enter="imgType=1" tabindex="0" alt="Record" title="Record">Record</span>
          </p>
          <div class="source-style" style="margin-top: 0.3vw"><p>Lyrics:Mifune Takeshi｜Song:Guo Ming-feng</p>
            <p>Record label and recording courtesy of Hsu Teng-Fang</p>
            <p>Lyric Book:NMTH 2019.031.0179</p>
          </div>
          <p class="content-style" style="margin-top: 0.3vw">Sung by Hontsune Elementary School with orchestra</p>
          <div class="lyrics-div content-style"  >
            <p>Verse1:</p><p>臺灣神社の　 お祭日</p>
            <p>At a Taiwanese temple on festival day,</p>
            <p style="margin-top: 0.6vw">太鼓はどんどん　 なつてゐる</p>
            <p>Drums boom, shaking the heavens,</p>
            <p style="margin-top: 0.6vw">今年は豐年　よい年だ</p>
            <p>This year brings a bountiful harvest, a good year,</p>
            <p style="margin-top: 0.6vw">蓬菜米を 供へませう</p>
            <p>Offerings of Penglai rice, oh!</p>
            <p style="margin-top: 0.6vw">御輿かついで　 えつさつさ</p>
            <p>Carrying the palanquin, heave-ho, heave-ho,</p>
            <p style="margin-top: 0.6vw">太鼓か  ついで　 えつさつさ</p>
            <p>carrying the drums, heave-ho, heave-ho.</p>
            <p style="margin-top: 1vw">Verse2:</p><p>臺灣神社の　 お祭日</p>
            <p>At a Taiwanese temple on festival day,</p>
            <p style="margin-top: 0.6vw">太鼓はどんどん　 なつてゐる</p>
            <p>Drums boom, shaking the heavens,</p>
            <p style="margin-top: 0.6vw">蜜柑や文旦　 斗柚など</p>
            <p>Tangerines and pomelos, large citruses,</p>
            <p style="margin-top: 0.6vw">市場へ買ひに　 行きませう</p>
            <p>Buy them at the market, then head to the festival,</p>
            <p style="margin-top: 0.6vw">御輿かついで　 えつさつさ</p>
            <p>Carrying the palanquin, heave-ho, heave-ho,</p>
            <p style="margin-top: 0.6vw">太鼓かついで　 えつさつさ</p>
            <p>carrying the drums, heave-ho, heave-ho.</p>
            <p style="margin-top: 1vw">Verse3:</p><p>臺灣神社の　 お祭日</p>
            <p>At a Taiwanese temple on festival day,</p>
            <p style="margin-top: 0.6vw">太鼓はどんどん　 なつてゐる</p>
            <p>Drums boom, shaking the heavens,</p>
            <p style="margin-top: 0.6vw">バナナ、や鳳梨　 木瓜など</p>
            <p>Bananas, pineapples, and papayas,</p>
            <p style="margin-top: 0.6vw">お背戸へ行って　 もぎませう</p>
            <p>Go pick some fruit in the backyard</p>
            <p style="margin-top: 0.6vw">御輿かついで　 えつさつさ</p>
            <p>Carrying the palanquin, heave-ho, heave-ho,</p>
            <p style="margin-top: 0.6vw">御輿かついで　 えつさつさ</p>
            <p>carrying the drums, heave-ho, heave-ho.</p>
            <p style="margin-top: 0.6vw">臺灣神社の　 お祭日</p>
            <p>At a Taiwanese temple on festival day.</p>
          </div>
          <div class="arrow-btn-R"  @click="cdNumBtn(1)" @keydown.enter="cdNumBtn(1)" tabindex="0">
            <p>“Water Buffalo”</p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/right-arrow.png" alt="next page" title="next page">
          </div>
        </div>

        <div v-if="cdNum==1" style="padding-left: 1vw">
          <p class="content-style" style="margin-top: 1.2vw; display: flex; align-items: center;font-family: Arial-Bold;">“Water Buffalo”Columbia Records, 1929
            <span v-if="imgType==1" class="blue-btn" @click="imgType=2" @keydown.enter="imgType=2" tabindex="0" style="height: 1.5vw; display: flex;" alt="Lyrics" title="Lyrics">Lyrics</span>
            <span v-if="imgType==2" class="blue-btn" @click="imgType=1" @keydown.enter="imgType=1" tabindex="0" alt="Record" title="Record">Record</span>
          </p>
          <div class="source-style" style="margin-top: 0.3vw"><p>Lyrics:Mifune Takeshi｜Song:Guo Ming-feng</p>
            <p>Record label and recording courtesy of Hsu Teng-Fang</p>
            <p>Lyric Book:NMTH 2019.031.0179</p>
          </div>
          <p class="content-style" style="margin-top: 0.3vw">Sung by Hontsune Elementary School with orchestra</p>
          <div class="lyrics-div content-style"  >
            <p>Verse1:</p><p>お風呂の好きな水牛は</p>
            <p>Water buffalo loves to bath</p>
            <p style="margin-top: 0.6vw">野原のお風呂でいい気持ち</p>
            <p>Comfortably soaking in the grassland’s bathing pool</p>
            <p style="margin-top: 0.6vw">肩までトップリいい気持</p>
            <p>Comfortably immersing the entire shoulder</p>
            <p style="margin-top: 1vw">Verse2:</p><p>暑い暑い真昼時</p>
            <p>At noon on a searing day</p>
            <p style="margin-top: 0.6vw">水牛番は木の葉陰</p>
            <p>The cowherd hides in the shade of a tree</p>
            <p style="margin-top: 0.6vw">夢のジヤンクでコックリコ</p>
            <p>Dozing off and dreaming about the sampan</p>
            <p style="margin-top: 1vw">Verse3:</p><p>水牛のお風呂はたんぼ中</p>
            <p>Water buffalos bathing in the paddy field</p>
            <p style="margin-top: 0.6vw">濁り泥水 泥の池</p>
            <p>Murky water and muddy pond</p>
            <p style="margin-top: 0.6vw">蛙が背を流します、</p>
            <p>Frogs help to flush their backs</p>
            <p style="margin-top: 1vw">Verse4:</p><p>あんまの白鷺を呼びましょか</p>
            <p>Calling out to the egrets above</p>
            <p style="margin-top: 0.6vw">水牛のおぢさんいい気持</p>
            <p>Uncle Water Buffalo is so comfortable</p>
            <p style="margin-top: 0.6vw">おめめ細めていい気持</p>
            <p>Squinting his eyes, looking so cozy</p>
          </div>
          <div class="arrow-btn-L"  @click="cdNumBtn(-1)" @keydown.enter="cdNumBtn(-1)" tabindex="0">
            <p>“Festival Day”</p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/left-arrow.png" alt="previous page" title="previous page">
          </div>
          <div class="arrow-btn-R"  @click="cdNumBtn(1)" @keydown.enter="cdNumBtn(1)" tabindex="0">
            <p>“Our Taiwan”</p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/right-arrow.png" alt="next page" title="next page">
          </div>
        </div>

        <div v-if="cdNum==2" style="padding-left: 1vw">
          <p class="content-style" style="margin-top: 1.2vw; display: flex; align-items: center;font-family: Arial-Bold;width: 30vw">“Our Taiwan” Columbia Records, 1935
<!--            <span v-if="imgType==1" class="blue-btn" @click="imgType=2" style="height: 1.5vw; display: flex;">Lyrics</span>-->
<!--            <span v-if="imgType==2" class="blue-btn" @click="imgType=1">Record</span>-->
          </p>
          <div class="source-style" style="margin-top: 0.3vw ">
            <p>Lyrics and song:Tsai Pei-huo｜Sung by Lin Shi-Hao</p>
            <p>Record label courtesy of Hsu Teng-Fang</p>
            <p>Recording courtesy of Tsai Fu-hua</p>
          </div>
          <p class="content-style" style="margin-top: 0.3vw">Record Number:80298</p>
          <div class="lyrics-div content-style" >
            <p>Verse1:</p><p>Taiwan, Taiwan, our Taiwan, the sea is vast, the mountains are steep  Big and small ships pass through</p>
            <p >Visitors from afar praise your beauty, Sun Moon Lake, Alishan</p>
            <p >The grass and trees are always lush, white egrets fly over the paddy fields</p>
            <p >Crows perched upon buffaloes call out, a peaceful village on the Pacific Ocean, the sea is vast, the mountains are tall</p>
            <p style="margin-top: 1vw"><p>Verse2:</p>Beautiful Island, a treasure trove, gold and silver trees and bountiful mountains and lakes</p>
            <p >Tea-picking children sing mountain songs</p>
            <p >Harvesting twice-a-year rice never ends, fruits and fish are abundant</p>
            <p >In the Ming Dynasty, Koxinga saved the nation and built the imperial capital</p>
            <p >Strategically cultivating and managing the land, Heaven protects this beautiful island, a treasure trove</p>
            <p style="margin-top: 1vw"><p>Verse3:</p>Penglai Island, the sky is so pure, near Fujian Province to the west, peace in Kyushu to the northeast</p>
            <p >Brothers still young at heart, candle fire replaced by electric light</p>
            <p >Everyone's heart yearns for peace, like gathered stones, we support each other</p>
            <p >Switzerland of the East prospers, the snow is pure white, the mountains are bright, Penglai Island, the sky is so pure</p>
          </div>
          <div class="arrow-btn-L"  @click="cdNumBtn(-1)" @keydown.enter="cdNumBtn(-1)" tabindex="0">
            <p>“Water Buffalo”</p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/left-arrow.png" alt="previous page" title="previous page">
          </div>
          <div class="arrow-btn-R" style="top: 34.5%" @click="cdNumBtn(1)" @keydown.enter="cdNumBtn(1)" tabindex="0">
            <p style="display: flex; flex-direction: column; align-items: center;">Taiwan Cultural<span>Association Anthem</span><span>Ceremonial Version</span></p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/right-arrow.png" alt="next page" title="next page">
          </div>
        </div>

        <div v-if="cdNum==3" style="padding-left: 1vw">
          <p class="content-style" style="margin-top: 1.2vw; display: flex; align-items: center;font-family: Arial-Bold;width: 30vw">Taiwan Cultural Association Anthem Ceremonial Version
            <!--            <span v-if="imgType==1" class="blue-btn" @click="imgType=2" style="height: 1.5vw; display: flex;">Lyrics</span>-->
            <!--            <span v-if="imgType==2" class="blue-btn" @click="imgType=1">Record</span>-->
          </p>
          <div class="source-style" style="margin-top: 0.3vw"><p>Lyrics:Chiang wei-sui｜Melody:Ichijo Shinzaburo</p>
            <p>Sung by Tiám-sim-tànn Choir</p>
            <p>Lyric script: NMTH 2018.011.0014.0004</p>
            <br />

          </div>
          <div class="lyrics-div content-style" style="margin-top: 0.5vw">
            <p>Verse1:</p><p>We are all people of Asia,</p>
            <p>Mediating between Han and Japanese.</p>
            <p>Heaven has sent us a mission, mandating us urgently</p>
            <p>To advance culture, revitalize morality, cultivate capabilities.</p>
            <p style="margin-top: 1vw" >Verse2:</p><p>Seeking permanent peace in East Asia, friendship between China and Japan,</p>
            <p>We must act as a chain, linking brothers in harmony,</p>
            <p>Uniting all nations of East Asia to forge a great alliance,</p>
            <p>To generate a civilization side by side with the West.</p>
            <p style="margin-top: 1vw">Verse3:</p><p>Preventing war between East and West, Peace in the world,</p>
            <p>We bring benefit to all under heaven; how could we give in to despair?</p>
            <p>But we hope the mission will finally be completed, happily for the people of the world.</p>
            <p>Long live the world and mankind; Taiwan’s reputation is forever fragrant.</p>
          </div>
          <div class="arrow-btn-L"  @click="cdNumBtn(-1)" @keydown.enter="cdNumBtn(-1)" tabindex="0">
            <p>“Our Taiwan”</p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/left-arrow.png" alt="previous page" title="previous page">
          </div>
        </div>

        <audio
            :ref="audio[0]"
            @timeupdate="updateTime(0)"
            @loadedmetadata="updateTime(0)"
        >
          <source  src="../assets/Unit3-4/audio/01-お祭日.mp3" type="audio/mpeg">
        </audio>
        <audio
            :ref="audio[1]"
            @timeupdate="updateTime(1)"
            @loadedmetadata="updateTime(1)"
        >
          <source  src="../assets/Unit3-4/audio/02-水牛.mp3" type="audio/mpeg">
        </audio>
        <audio
            :ref="audio[2]"
            @timeupdate="updateTime(2)"
            @loadedmetadata="updateTime(2)"
        >
          <source  src="../assets/Unit3-4/audio/03-咱臺灣-林氏好版.mp3" type="audio/mpeg">
        </audio>
        <audio
            :ref="audio[3]"
            @timeupdate="updateTime(3)"
            @loadedmetadata="updateTime(3)"
        >
          <source  src="../assets/Unit3-4/audio/tainan.mp3" type="audio/mpeg">
        </audio>


        <div class="audio-div">
          <img @click="playAudio(cdNum)" @keydown.enter="playAudio(cdNum)" tabindex="0" class="play-btn" :src="playBtnImg" alt="播放(play)/暫停(pause)" title="播放(play)/暫停(pause)">
          <p >{{formatTime(currentTime[cdNum])}}</p>
          <div class="progress-bar">
            <progress class="progress" :value="currentTime[cdNum]" :max="duration[cdNum]"></progress>
            <input  class="progress-input"  type="range" min="0" :max="duration[cdNum]" step="0.1" v-model="currentTime[cdNum]" @input="seekAudio(cdNum)" tabindex="-1">
          </div>

          <p>{{formatTime(duration[cdNum])}}</p>

          <img class="volume-btn" src="../assets/common/20230629-2-0-物件-100-音量鍵.png" @click="showVolume=!showVolume" alt="音量鍵(volume button)" title="音量鍵(volume button)">
          <transition name="img-frame-fade">
            <div class="volume-bar" v-if="showVolume" >
              <progress class="volume-progress white-style"  :value="volume"  :max="1"></progress>
              <input class="volume-input" type="range" min="0" max="1" step="0.1" v-model="volume" @input="setVolume" tabindex="-1" alt="按方向鍵上/下調整(Press the arrow keys up/down to adjust the volume)" title="按方向鍵上/下調整(Press the arrow keys up/down to adjust the volume)">
            </div>
          </transition>
        </div>
      </div>

    </div>

    <div class="part4 part-color">
      <img class="logo-text" src="../assets/Unit3-4/en/20230708-EN網站-3-4-物件-21-裝飾字2-爭取農民.png" alt="Competing for Farmers' Loyalty" title="Competing for Farmers' Loyalty">
      <div class="content-1 en-content-style" style="margin-top: -5vw">
        During the 1920s in Taiwan under Japanese rule, writing folk songs to win over farmers was a highly competitive endeavor. Pro-government and reformist tenant farming organizations produced songs like the "Tenant March" and the "Small Tenant Improvement Song," distributing records widely to promote their cause. Meanwhile, members of the grassroots Taiwan Farmers' Association composed the "Three-Character Collection," secretly printing and distributing it in the mountains. By employing text that aligned with the local language and cultural context, they aimed to effectively spread socialist ideas among the farmers.
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-4/20230413-3-4-物件-22-pic82.png" alt="The Small Tenant Problem in Taiwan - 1933" title="The Small Tenant Problem in Taiwan - 1933">
        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 6%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #ffffff">The Savior of Tenant Farmers</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">The Small Tenant Problem in Taiwan - 1933</p>
        <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan History 2010.006.0578</p>
        <p class="en-content-style" style="margin-top: 0.5vw;">This book, co-authored by Shigeno Shinichi and Lin Chao-ching, addresses Taiwan's tenant farming issues. "Tenant farming" refers to small farmers who cultivate land owned by others or who farm their own land. The book discusses Taiwan's agricultural challenges and developments, including land reclamation, land system evolution, the emergence of various tenant farmers, and the growth and impact of tenant agriculture. An illustration within the book compares the "Tenant Farmers Association" to a thousand-armed Bodhisattva, symbolizing the association's potential to improve farmers' situations and representing the integration of ordinary people into the system through institutional reforms.</p>
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit3-4/20230413-3-4-物件-23-pic92.png" alt="The Small Tenant Problem in Taiwan - 1933" title="The Small Tenant Problem in Taiwan - 1933">
        <img class="zoom-2-btn"  @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: -2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
    </div>

    <div class="part5 part-color">
<!--      <img class="sample" src="../assets/Unit3-4/sample/20230413-對位-3-4-13.png">-->
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-4/3-4-3.json')" alt="Farmers" title="Farmers"></LottieTest>
      </div>
      <div class="content-1 en-content-style">
        <p> In 1931, members from both the Cultural Association and the Farmers' Association collaborated to establish the "Red Aid Society." Key figure Chen Jie, under the guidance of Jian Ji, ventured deep into the Zhuqi mountain region in Chiayi. There, he used steel plates to print newspapers and magazines in a workshop originally for drying longan fruits. They covertly printed Truth Magazine, "Two-Character Collection," and "Three-Character Collection," then sent them to the Taichung Agricultural Group headquarters via Zhushan for discreet distribution. Starting in September of that year, police began seizing these printed materials. By December, the individuals involved were apprehended.</p>
        <p style="margin-top: 1.6vw"> Nevertheless, the three-character rhyming verses in the form of recited songs may have already spread among the population.</p>
        <div class="btn-div">
          <div class="blue-small-btn" @click="routeBtn('/en/unit3-4/game')" @keydown.enter="routeBtn('/en/unit3-4/game')" tabindex="0" alt="Join the Fun of Gathering the “Three-Character Collection” Together!" title="Join the Fun of Gathering the “Three-Character Collection” Together!">Join the Fun of Gathering the “Three-Character Collection” Together!</div>

        </div>
        <div class="btn-div" style="margin-top: 1vw">
          <div class="blue-small-btn" @click="openVideo(0)" @keydown.enter="openVideo(0)" tabindex="0" alt="Play Two-Character Collection" title="Play Two-Character Collection">Play Two-Character Collection</div>
          <div class="blue-small-btn" @click="openVideo(1)" @keydown.enter="openVideo(1)" tabindex="0" alt="Play Three-Character Collection" title="Play Three-Character Collection">Play Three-Character Collection</div>
        </div>
      </div>

      <div class="back-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
        <p style="margin-left: 0.5vw">Back</p>
      </div>

    </div>

    <transition name="img-frame-fade"><div class="video-bg"  v-if="showVideo[0]" >
      <div class="video-div" >
        <video id="video"  class="w-full" ref="videoRef" @click="playVideo()" @keydown.enter="playVideo()" tabindex="0" @pause="videoPausedEvent">
          <source src="../assets/Unit3-4/video/雙字集全.mp4" >
                  type="video/mp4">
        </video>

        <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showVideo[0]=false" >
      </div>
    </div></transition>
    <transition name="img-frame-fade"><div class="video-bg"  v-if="showVideo[1]">
      <div class="video-div" >
        <video id="video"  class="w-full" ref="videoRef" @click="playVideo2()" @keydown.enter="playVideo2()" tabindex="0" @pause="videoPausedEvent">
          <source src="../assets/Unit3-4/video/三字集全.mp4" >
          type="video/mp4">
        </video>

        <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showVideo[1]=false;" >
      </div>
    </div></transition>
    <div class="video-touch" v-if="showVideo[0]==true || showVideo[1]==true"  @mousemove="customCursorStyle='opacity:1'"  @mouseleave="customCursorStyle='display:none'"  @click="playVideo" @keydown.enter="playVideo" tabindex="0"></div>


    <div class="custom-cursor" ref="customCursor"  :style="customCursorStyle" >
      <img class="w-full" :src="customCursorImg">
    </div>


    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-20.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:18.2vw; margin:2.71vw 0 0 22.8vw"  src="../assets/Unit3-4/20230629-3-4-物件-op-22-pic8-放大文物.png" alt="The Small Tenant Problem in Taiwan - 1933" title="The Small Tenant Problem in Taiwan - 1933">
          </div>

          <div class="content-text">
            <div class="bold-title">The Small Tenant Problem in Taiwan - 1933</div>
            <div class="noto-text" >National Museum of Taiwan History 2010.006.0578</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-10.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:15.5vw; margin:2.71vw 0 0 24.8vw" src="../assets/Unit3-4/20230629-3-4-物件-op-23-pic9-放大文物.png" alt="The Small Tenant Problem in Taiwan - 1933" title="The Small Tenant Problem in Taiwan - 1933">
          </div>

          <div class="content-text" >
            <div >
              <div class="bold-title">The Small Tenant Problem in Taiwan - 1933</div>
              <div class="noto-text" >National Museum of Taiwan History 2010.006.0578</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>


    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language="en" color="black"></FooterFeature>
  </div>
</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn.vue";
import LottieTest from "@/components/LottieTest";
import {reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
export default {
  name: "Unit3-4",
  mixins: [myMixin],
  components:{
    EnToolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const circleAnim = ref(require('@/assets/Unit3-4/04-同心圓動態/20230413-04-物件-同心圓動態-07.png'));
    const imgType = ref(1);
    const cdNum = ref(0);
    const showImg = reactive(Array.from({ length: 20 }, () => false));


    let circleAnimIndex = 0;
    let circleAnimId = null;

    const audio = reactive(Array.from({ length: 4 }, () => ref()));
    const currentTime = reactive(Array.from({ length: 4 }, () => 0));
    const duration = reactive(Array.from({ length: 4 }, () => 0));
    const audioStyle = reactive(Array.from({ length: 12 }, () => ''));
    const playBtnImg = ref(require("../assets/common/play-btn.png"));
    const volume = ref(100);
    const showVolume = ref(false);

    const videoRef = ref();
    const videoRef2 = ref();
    const customCursor = ref();
    const customCursorStyle = ref('display:none;');
    const videoPaused = ref(true);
    const customCursorImg = ref(require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png'));
    const showVideo = reactive(Array.from({ length: 2 }, () => false));

    playCircleAnim();

    function routeBtn(path){
      router.push(path);
    }

    function playCircleAnim(){
      circleAnimIndex = 1;
      clearInterval(circleAnimId);

      circleAnimId = setInterval(()=>{

          circleAnimIndex = (circleAnimIndex + 1) % (55+1);

        circleAnim.value = require('@/assets/Unit3-4/04-同心圓動態/20230413-04-物件-同心圓動態-'+circleAnimIndex.toString().padStart(2, '0') +'.png');

        }, 50);
      }

    function playAudio(index){
      if(!audio[index].value.paused){
        audio[index].value.pause();
        playBtnImg.value = require("../assets/common/play-btn.png");
      }else{
        audio[index].value.play();
        playBtnImg.value = require("../assets/common/20230629-2-0-物件-99-音樂暫停鍵.png");
      }
    }

    function updateTime(index) {
      currentTime[index] = audio[index].value.currentTime;
      duration[index] = audio[index].value.duration;
      audioStyle[index] = "width:" + (currentTime[index]/duration[index])*100 +"%";
    }

    function formatTime(time){
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    function seekAudio(index){
      audio[index].value.currentTime = currentTime[index];
    }

    function cdNumBtn(num){
        if(num==1){
          cdNum.value  = Math.min(cdNum.value+1, 3);
        }else{
          cdNum.value  = Math.max(cdNum.value-1, 0);
        }

        for (let i=0; i<4; i++){
          audio[i].value.pause();
        }
      playBtnImg.value = require("../assets/common/play-btn.png");
    }

    function setVolume(){
      for (let i=0; i <audio.length; i++){
        audio[i].value.volume = volume.value;
      }
    }

    function playVideo(){
      if(videoRef.value.paused){
        videoRef.value.play();
        //customCursor.value.style.opacity = 0;
        videoPaused.value = false;
        videoRef.value.controls = true;
        customCursorImg.value = require('../assets/common/20230704-物件-6-0-15-影片暫停鍵.png');
      }else{
        videoRef.value.pause();
        videoPausedEvent();
      }
    }

    function videoPausedEvent(){
      customCursorImg.value = require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png');
      videoPaused.value = true;
      videoRef.value.controls = false;
    }

    function playVideo2(){
      if(videoRef2.value.paused){
        videoRef2.value.play();
        //customCursor.value.style.opacity = 0;
        videoPaused.value = false;
        videoRef2.value.controls = true;
        customCursorImg.value = require('../assets/common/20230704-物件-6-0-15-影片暫停鍵.png');
      }else{
        videoRef2.value.pause();
        videoPaused.value = true;
        videoRef2.value.controls = false;
        customCursorImg.value = require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png');
      }
    }


    function handleMouseMove(event) {
      customCursor.value.style.left = (event.pageX - customCursor.value.clientWidth /2) + "px" ;
      customCursor.value.style.top = (event.pageY - customCursor.value.clientHeight /2)  + "px";
    }


    function openVideo(num){
      showVideo[num] = true;
      customCursorImg.value = require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png');
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
      showVideo[0] = false;
      showVideo[1] = false;
    }

    return{
      routeBtn,
      circleAnim,
      imgType,
      audio,
      currentTime,
      duration,
      audioStyle,
      playAudio,
      updateTime,
      formatTime,
      seekAudio,
      playBtnImg,
      volume,
      setVolume,
      showVolume,
      showImg,
      cdNum,
      cdNumBtn,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      videoRef,
      videoRef2,
      customCursor,
      customCursorStyle,
      playVideo,
      playVideo2,
      handleMouseMove,
      videoPaused,
      videoPausedEvent,
      customCursorImg,
      showVideo,
      openVideo,
      escEvent,
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit3-4.css"></style>
<style scoped src="../assets/css/audio-comp.css"></style>
