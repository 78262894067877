<template>
  <div class="wrap"  @mousemove="handleMouseMove" @keydown.esc="escEvent">
    <toolbar type="1" unit="3" route-name="unit3-4" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit3-4/sample/20230413-對位-3-4-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="title-logo">社會歌謠</div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-4/3-4-0.json')" alt="社會歌謠" title="社會歌謠"></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit3-4/20230424-3-4-物件-01-社會歌謠2.png" alt="社會歌謠" title="社會歌謠">
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit3-4/3-4-1.json')" alt="社會歌謠" title="社會歌謠"></LottieTest>
      </div>
    </div>

    <div class="part2 part-color">
        <img class="logo-text" src="../assets/Unit3-4/20230413-3-4-物件-02-裝飾字1-歌謠對抗2.png" alt="歌謠對抗" title="歌謠對抗">
        <div class="content content-style">
          在政治運動上創作新歌謠成為宣傳的利器，用來爭取大眾的認同。文協創辦時蔣渭水所提議撰寫的〈臺灣文化協會會歌〉，特別挑選了當時青年都會唱的日本軍歌〈勇敢的水兵〉，與唱歌科教材〈始政紀念日〉作為曲調，有以知覺翻轉、與殖民者爭鋒的意味。1929年推動國民教育的「臺灣教育會」舉辦「臺灣之歌」歌曲創作徵選，入選歌曲都是日語歌曲， 當中〈水牛〉、〈お祭日〉兩首歌，是臺灣人公學校老師的作曲。蔡培火受到這樣的刺激，也開始創作新調，完成代表作品〈咱臺灣〉。
        </div>
        <img class="img-1" src="../assets/Unit3-4/20230413-3-4-物件-03-插畫１2.png"  alt="歌謠對抗" title="歌謠對抗">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-4/3-4-2.json')" alt="歌謠對抗" title="歌謠對抗"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
<!--          <img class="sample" src="../assets/Unit3-4/sample/20230413-對位-3-4-12.png">-->
      <img class="circle-anim" :src="circleAnim">

      <transition name="img-frame-fade"><img v-if="imgType==1 && cdNum==0" class="cd-1" src="../assets/Unit3-4/20230413-3-4-物件-05-pic1-祭典歌2.png" alt="祭典歌" title="祭典歌"></transition>
      <transition name="img-frame-fade"><img v-if="imgType==2 && cdNum==0" class="img-1" src="../assets/Unit3-4/20230413-3-4-物件-06-pic2-祭典歌歌譜2.png" alt="祭典歌歌譜" title="祭典歌歌譜"></transition>

      <transition name="img-frame-fade"><img v-if="imgType==1 && cdNum==1" class="cd-1" src="../assets/Unit3-4/20230413-3-4-物件-07-pic4-水牛歌2.png" alt="水牛歌" title="水牛歌"></transition>
      <transition name="img-frame-fade"><img v-if="imgType==2 && cdNum==1" class="img-1" src="../assets/Unit3-4/20230413-3-4-物件-08-pic5-水牛歌歌譜2.png" alt="水牛歌歌譜" title="水牛歌歌譜"></transition>


      <transition name="img-frame-fade"><img v-if="cdNum==2" class="cd-1" src="../assets/Unit3-4/20230413-3-4-物件-09-pic6-咱臺灣2.png" alt="咱臺灣" title="咱臺灣"></transition>
      <transition name="img-frame-fade"><img v-if="cdNum==3" class="img-2" src="../assets/Unit3-4/20230413-3-4-物件-10-pic7-臺灣文化協會會歌2.png" alt="臺灣文化協會會歌" title="臺灣文化協會會歌"></transition>

      <div class="content">
        <div v-if="cdNum==0">
          <p class="subtitle-style" style="margin-top: 1.2vw; display: flex; align-items: center">〈お祭日〉古倫美亞  1929年
            <span v-if="imgType==1" class="blue-btn" @click="imgType=2" @keydown.enter="imgType=2" tabindex="0" style="height: 1.5vw; display: flex;" alt="看歌譜" title="看歌譜">看歌譜</span>
            <span v-if="imgType==2" class="blue-btn" @click="imgType=1" @keydown.enter="imgType=1" tabindex="0" alt="看唱片" title="看唱片">看唱片</span>
          </p>
          <p class="content-style" style="margin-top: 0.3vw">詞：御船武｜曲：郭明峯｜唱片圓標、錄音：徐登芳 提供｜歌譜：館藏</p>
          <p class="content-style">唱片編號 25611 東京市本恆小學校生徒演唱，管弦樂團伴奏</p>
          <div class="lyrics-div content-style" >
            <p><span class="number">（一）</span>臺灣神社の　 お祭日</p>
            <p>臺灣神社 祭典日</p>
            <p style="margin-top: 0.6vw">太鼓はどんどん　 なつてゐる</p>
            <p>太鼓咚咚 震天響</p>
            <p style="margin-top: 0.6vw">今年は豐年　よい年だ</p>
            <p>今年豐收 好年頭</p>
            <p style="margin-top: 0.6vw">蓬菜米を 供へませう</p>
            <p>供奉蓬萊米喲~</p>
            <p style="margin-top: 0.6vw">御輿かついで　 えつさつさ</p>
            <p>扛著神轎 嘿咻嘿咻</p>
            <p style="margin-top: 0.6vw">太鼓か  ついで　 えつさつさ</p>
            <p>扛著太鼓 嘿咻嘿咻</p>
            <p style="margin-top: 0.6vw"><span class="number">（二）</span>臺灣神社の　 お祭日</p>
            <p>臺灣神社 祭典日</p>

            <p style="margin-top: 0.6vw">太鼓はどんどん　 なつてゐる</p>
            <p>太鼓咚咚 震天響</p>
            <p style="margin-top: 0.6vw">蜜柑や文旦　 斗柚など</p>
            <p>蜜柑啊文旦 斗柚啊</p>
            <p style="margin-top: 0.6vw">市場へ買ひに　 行きませう</p>
            <p>到市場買了 去祭典吧</p>
            <p style="margin-top: 0.6vw">御輿かついで　 えつさつさ</p>
            <p>扛著神轎 嘿咻嘿咻</p>
            <p style="margin-top: 0.6vw">太鼓かついで　 えつさつさ</p>
            <p>太鼓咚咚 震天響</p>
            <p style="margin-top: 0.6vw"><span class="number">（三）</span>臺灣神社の　 お祭日</p>
            <p>臺灣神社 祭典日</p>
            <p style="margin-top: 0.6vw">太鼓はどんどん　 なつてゐる</p>
            <p>太鼓咚咚 震天響</p>
            <p style="margin-top: 0.6vw">バナナ、や鳳梨　 木瓜など</p>
            <p>香蕉、鳳梨和木瓜</p>
            <p style="margin-top: 0.6vw">お背戸へ行って　 もぎませう</p>
            <p>到後院去 摘水果吧</p>
            <p style="margin-top: 0.6vw">御輿かついで　 えつさつさ</p>
            <p>扛著神轎 嘿咻嘿咻</p>
            <p style="margin-top: 0.6vw">御輿かついで　 えつさつさ</p>
            <p>扛著太鼓 嘿咻嘿咻</p>
            <p>臺灣神社の　 お祭日</p>
            <p>臺灣神社 祭典日</p>
          </div>
          <div class="arrow-btn" style="right: -9.5%;" @click="cdNumBtn(1)" @keydown.enter="cdNumBtn(1)" tabindex="0">
            <p>〈水牛〉</p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/right-arrow.png" alt="向右" title="向右">
          </div>
        </div>

        <div v-if="cdNum==1">
          <p class="subtitle-style" style="margin-top: 1.2vw; display: flex; align-items: center">〈水牛〉古倫美亞  1929年
            <span v-if="imgType==1" class="blue-btn" @click="imgType=2" @keydown.enter="imgType=2" tabindex="0" style="height: 1.5vw; display: flex;" alt="看歌譜" title="看歌譜">看歌譜</span>
            <span v-if="imgType==2" class="blue-btn" @click="imgType=1" @keydown.enter="imgType=1" tabindex="0" alt="看唱片" title="看唱片">看唱片</span>
          </p>
          <p class="content-style" style="margin-top: 0.3vw">詞：御船武｜曲：郭明峯｜唱片圓標、錄音：徐登芳 提供｜歌譜：館藏</p>
          <p class="content-style">唱片編號 25611 東京市本恆小學校生徒演唱，管弦樂團伴奏</p>
          <div class="lyrics-div content-style" >
            <p><span class="number">（一）</span>お風呂の好きな水牛は</p>
            <p>喜歡泡澡的水牛</p>
            <p style="margin-top: 0.6vw">野原のお風呂でいい気持ち</p>
            <p>泡在大草原的浴池裡 好舒服</p>
            <p style="margin-top: 0.6vw">肩までトップリいい気持</p>
            <p>整個肩膀都浸泡著 好舒服</p>
            <p style="margin-top: 0.6vw"><span class="number">（二）</span>暑い暑い真昼時</p>
            <p>炎熱的正午時分</p>
            <p style="margin-top: 0.6vw">水牛番は木の葉陰</p>
            <p>趕牛人在樹蔭下</p>
            <p style="margin-top: 0.6vw">夢のジヤンクでコックリコ</p>
            <p>乘著夢中的舢舨打著盹</p>
            <p style="margin-top: 0.6vw"><span class="number">（三）</span>水牛のお風呂はたんぼ中</p>
            <p>水牛的浴池在水田裡</p>
            <p style="margin-top: 0.6vw">濁り泥水 泥の池</p>
            <p>渾濁的泥水 泥巴池塘</p>
            <p style="margin-top: 0.6vw">蛙が背を流します、</p>
            <p>青蛙替牠們沖背脊</p>
            <p style="margin-top: 0.6vw"><span class="number">（四）</span>あんまの白鷺を呼びましょか</p>
            <p>叫喚頭上的白鷺鷥吧</p>
            <p style="margin-top: 0.6vw">水牛のおぢさんいい気持</p>
            <p>水牛叔叔 好舒服</p>
            <p style="margin-top: 0.6vw">おめめ細めていい気持</p>
            <p>瞇著眼睛 好舒服</p>
          </div>
          <div class="arrow-btn" style="left: -12.5%;" @click="cdNumBtn(-1)" @keydown.enter="cdNumBtn(-1)" tabindex="0">
            <p>〈お祭日〉</p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/left-arrow.png" alt="向左" title="向左">
          </div>
          <div class="arrow-btn" style="right: -9.5%;" @click="cdNumBtn(1)" @keydown.enter="cdNumBtn(1)" tabindex="0">
            <p>〈咱臺灣〉</p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/right-arrow.png" alt="向右" title="向右">
          </div>
        </div>

        <div v-if="cdNum==2">
          <p class="subtitle-style" style="margin-top: 1.2vw; display: flex; align-items: center">〈咱臺灣〉古倫美亞  1935年
<!--            <span v-if="imgType==1" class="blue-btn" @click="imgType=2" style="height: 1.5vw; display: flex;">看歌譜</span>-->
<!--            <span v-if="imgType==2" class="blue-btn" @click="imgType=1">看唱片</span>-->
          </p>
          <p class="content-style" style="margin-top: 0.3vw">詞、曲：蔡培火｜ 演唱：林氏好｜ 唱片圓標：徐登芳｜ 錄音：蔡富花 提供</p>
          <p class="content-style">唱片編號 80298</p>
          <div class="lyrics-div content-style" >
            <p><span class="number">（一）</span>臺灣臺灣  咱臺灣  海真闊 山真懸   大船小船的路關</p>
            <p style="margin-top: 0.3vw">遠來人客講汝美  日月潭   阿里山 </p>
            <p style="margin-top: 0.3vw">草木不時青眺眺  白鷺鷥   過水田</p>
            <p style="margin-top: 0.3vw">水牛腳脊烏秋叫  太平洋上和平村  海真闊   山真高</p>
            <p style="margin-top: 0.3vw"><span class="number">（二）</span>美麗島   是寶庫  金銀大樹滿山湖    挽茶囝仔唱山歌</p>
            <p style="margin-top: 0.3vw">雙冬稻籽刈袂了   果子魚生較濟土</p>
            <p style="margin-top: 0.3vw">當時明朝鄭國姓   愛救國   建帝都</p>
            <p style="margin-top: 0.3vw">開墾經營大計謀   上天特別相看顧  美麗島   是寶庫</p>
            <p style="margin-top: 0.3vw"><span class="number">（三）</span>蓬萊島   天真清  西近福建省   九州東北平</p>
            <p style="margin-top: 0.3vw">山內兄弟猶細漢  燭仔火換電燈</p>
            <p style="margin-top: 0.3vw">大家心肝著和平  石頭拾倚來相拱</p>
            <p style="margin-top: 0.3vw">東洋瑞士穩當成  雪極白   山極明  蓬萊島   天真清</p>
          </div>
          <div class="arrow-btn" style="left: -12.5%;" @click="cdNumBtn(-1)" @keydown.enter="cdNumBtn(-1)" tabindex="0">
            <p>〈水牛〉</p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/left-arrow.png" alt="向左" title="向左">
          </div>
          <div class="arrow-btn" style="right: -9.5%;" @click="cdNumBtn(1)" @keydown.enter="cdNumBtn(1)" tabindex="0">
            <p style="display: flex; flex-direction: column; align-items: center">〈臺灣文化協會會歌〉 <span>儀式版</span></p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/right-arrow.png" alt="向右" title="向右">
          </div>
        </div>

        <div v-if="cdNum==3">
          <p class="subtitle-style" style="margin-top: 1.2vw; display: flex; align-items: center">〈臺灣文化協會會歌〉儀式版
            <!--            <span v-if="imgType==1" class="blue-btn" @click="imgType=2" style="height: 1.5vw; display: flex;">看歌譜</span>-->
            <!--            <span v-if="imgType==2" class="blue-btn" @click="imgType=1">看唱片</span>-->
          </p>
          <p class="content-style" style="margin-top: 0.3vw">詞：蔣渭水｜曲：一條慎三郎（ 始政紀念日 ）｜演唱：點心擔合唱團｜</p>
          <p class="content-style">歌詞：國立臺灣歷史博物館 提供</p>
          <div class="lyrics-div content-style" >
            <p><span class="number">（一）</span>我等都是亞細亞　黃色的人種</p>
            <p>介在漢族一血脈　日本的百姓</p>
            <p>所以天降大使命　囑咱緊實行</p>
            <p>發達文化振道德　造就此才能</p>
            <p><span class="number">（二）</span>欲謀東洋永和平　中日要親善</p>
            <p>我等須當作連鎖　和睦此弟兄</p>
            <p>糾合東亞諸民族　締結大同盟</p>
            <p>啟發文明比西洋　兩兩得並行</p>
            <p><span class="number">（三）</span>可免黃白起戰爭　世界就和平</p>
            <p>我等一舉天下利　豈可自暴棄</p>
            <p>但願最後完使命　樂為世界人</p>
            <p>世界人類萬萬歲　臺灣名譽馨</p>
          </div>
          <div class="arrow-btn" style="left: -12.5%;" @click="cdNumBtn(-1)" @keydown.enter="cdNumBtn(-1)" tabindex="0">
            <p>〈咱臺灣〉</p>
            <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/left-arrow.png" alt="向左" title="向左">
          </div>
        </div>

        <audio
            :ref="audio[0]"
            @timeupdate="updateTime(0)"
            @loadedmetadata="updateTime(0)"
        >
          <source  src="../assets/Unit3-4/audio/01-お祭日.mp3" type="audio/mpeg">
        </audio>
        <audio
            :ref="audio[1]"
            @timeupdate="updateTime(1)"
            @loadedmetadata="updateTime(1)"
        >
          <source  src="../assets/Unit3-4/audio/02-水牛.mp3" type="audio/mpeg">
        </audio>
        <audio
            :ref="audio[2]"
            @timeupdate="updateTime(2)"
            @loadedmetadata="updateTime(2)"
        >
          <source  src="../assets/Unit3-4/audio/03-咱臺灣-林氏好版.mp3" type="audio/mpeg">
        </audio>
        <audio
            :ref="audio[3]"
            @timeupdate="updateTime(3)"
            @loadedmetadata="updateTime(3)"
        >
          <source  src="../assets/Unit3-4/audio/tainan.mp3" type="audio/mpeg">
        </audio>


        <div class="audio-div">
          <img @click="playAudio(cdNum)" @keydown.enter="playAudio(cdNum)" tabindex="0" class="play-btn" :src="playBtnImg" alt="播放(play)/暫停(pause)" title="播放(play)/暫停(pause)">
          <p >{{formatTime(currentTime[cdNum])}}</p>
          <div class="progress-bar">
            <progress class="progress" :value="currentTime[cdNum]" :max="duration[cdNum]"></progress>
            <input  class="progress-input"  type="range" min="0" :max="duration[cdNum]" step="0.1"  v-model="currentTime[cdNum]" @input="seekAudio(cdNum)" tabindex="-1">
          </div>

          <p>{{formatTime(duration[cdNum])}}</p>

          <img class="volume-btn" src="../assets/common/20230629-2-0-物件-100-音量鍵.png" @click="showVolume=!showVolume" @keydown.enter="showVolume=!showVolume" tabindex="0" alt="音量鍵(volume button)" title="音量鍵(volume button)">
          <transition name="img-frame-fade">
            <div class="volume-bar" v-if="showVolume" style="left: 98%">
              <progress class="volume-progress white-style"  :value="volume"  :max="1"></progress>
              <input class="volume-input" type="range" min="0" max="1" step="0.1" v-model="volume" @input="setVolume" tabindex="0" alt="按方向鍵上/下調整(Press the arrow keys up/down to adjust the volume)" title="按方向鍵上/下調整(Press the arrow keys up/down to adjust the volume)">
            </div>
          </transition>
        </div>
      </div>

    </div>

    <div class="part4 part-color">
      <img class="logo-text" src="../assets/Unit3-4/20230413-3-4-物件-21-裝飾字2-爭取農民2.png" alt="爭取農民" title="爭取農民">
      <div class="content-1 content-style">
        關於農民的歌謠創作交鋒也相當熱烈。親官方、改良主義立場的業佃組織，創作〈業佃行進曲〉、〈小作慣行改良歌〉，製作唱片四處放送，大肆對內宣傳。民間組織群眾的臺灣農民組合成員則編寫《三字集》，在山間秘密印製發送，藉著符合本土語言運用的文字，來推廣社會主義思想。
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-4/20230413-3-4-物件-22-pic82.png" alt="臺灣的小作問題" title="臺灣的小作問題">
        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 6%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #ffffff">業佃大菩薩</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">《臺灣的小作問題》 1933年</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2010.006.0578</p>
        <p class="content-style" style="margin-top: 0.5vw;">為臺灣小作問題相關書籍，由茂野信一和林朝卿共同編著。「小作」為佃農、自耕農之意，該書介紹臺灣農業問題及發展，包含臺灣土地的開墾與土地制度發展，大小租戶與佃農的形成，小作農業的發展與影響等。本書中有一幅把「業佃會」比喻成千手菩薩的插畫。象徵業佃會可以改善農民處境，也是制度改革將老百姓吸納進體制的象徵。</p>
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit3-4/20230413-3-4-物件-23-pic92.png" alt="業佃大菩薩" title="業佃大菩薩">
        <img class="zoom-2-btn"  @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: -2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
    </div>

    <div class="part5 part-color">
<!--      <img class="sample" src="../assets/Unit3-4/sample/20230413-對位-3-4-13.png">-->
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-4/3-4-3.json')" alt="農民" title="農民" ></LottieTest>
      </div>
      <div class="content-1 content-style">
        1931年間文協與農民組合成員合流，組成「赤色救援會」。關鍵人物陳結受簡吉所託，深入嘉義竹崎山區，在烘培龍眼乾的工寮裡刻鋼板、油印報刊，秘密發印《真理》雜誌、《二字集》、《三字集》，經由竹山送往臺中農組本部，秘密發出。同年9月起，這份印刷品開始被警方查獲，12月相關人士遭到逮捕，但三字一句的韻文化身為唸謠，可能已在民間流傳。
        <div class="btn-div">
          <div class="blue-small-btn" @click="routeBtn('/unit3-4/game')" @keydown.enter="routeBtn('/unit3-4/game')" tabindex="0" alt="一起來收集三字集" title="一起來收集三字集">一起來收集三字集</div>
          <div class="blue-small-btn" @click="openVideo(0)" @keydown.enter="openVideo(0)" tabindex="0" alt="播放雙字集" title="播放雙字集">播放雙字集</div>
          <div class="blue-small-btn" @click="openVideo(1)" @keydown.enter="openVideo(1)" tabindex="0" alt="播放三字集" title="播放三字集">播放三字集</div>
        </div>
      </div>

      <div class="back-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="回廟埕拚文化" title="回廟埕拚文化">
        <p style="margin-left: 0.5vw">回廟埕拚文化</p>
      </div>

    </div>

    <transition name="img-frame-fade"><div class="video-bg"  v-if="showVideo[0]" >
      <div class="video-div" >
        <video id="video"  class="w-full" ref="videoRef" @click="playVideo()" @keydown.enter="playVideo()" tabindex="0" @pause="videoPausedEvent">
          <source src="../assets/Unit3-4/video/雙字集全.mp4" >
                  type="video/mp4">
        </video>

        <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" @click="showVideo[0]=false" alt="關閉" title="關閉">
      </div>
    </div></transition>
    <transition name="img-frame-fade"><div class="video-bg"  v-if="showVideo[1]">
      <div class="video-div" >
        <video id="video"  class="w-full" ref="videoRef" @click="playVideo2()" @keydown.enter="playVideo2()" tabindex="0" @pause="videoPausedEvent">
          <source src="../assets/Unit3-4/video/三字集全.mp4" >
          type="video/mp4">
        </video>

        <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" @click="showVideo[1]=false;" alt="關閉" title="關閉">
      </div>
    </div></transition>
    <div class="video-touch" v-if="showVideo[0]==true || showVideo[1]==true"  @mousemove="customCursorStyle='opacity:1'"  @mouseleave="customCursorStyle='display:none'"  @click="playVideo" @keydown.enter="playVideo()" tabindex="0"></div>


    <div class="custom-cursor" ref="customCursor"  :style="customCursorStyle" >
      <img class="w-full" :src="customCursorImg">
    </div>


    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-20.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:18.2vw; margin:2.71vw 0 0 22.8vw"  src="../assets/Unit3-4/20230629-3-4-物件-op-22-pic8-放大文物.png" alt="臺灣的小作問題" title="臺灣的小作問題">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 22.8vw; width: 17.4vw;">
            <div class="bold-title">《臺灣的小作問題》1933年</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2010.006.0578</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-10.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:15.5vw; margin:2.71vw 0 0 24.8vw" src="../assets/Unit3-4/20230629-3-4-物件-op-23-pic9-放大文物.png" alt="臺灣的小作問題" title="臺灣的小作問題">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 23.8vw; width: 17.4vw;">
            <div >
              <div class="bold-title">《臺灣的小作問題》1933年</div>
              <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2010.006.0578</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>


    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import LottieTest from "@/components/LottieTest";
import {reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
export default {
  name: "Unit3-4",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const circleAnim = ref(require('@/assets/Unit3-4/04-同心圓動態/20230413-04-物件-同心圓動態-07.png'));
    const imgType = ref(1);
    const cdNum = ref(0);
    const showImg = reactive(Array.from({ length: 20 }, () => false));


    let circleAnimIndex = 0;
    let circleAnimId = null;

    const audio = reactive(Array.from({ length: 4 }, () => ref()));
    const currentTime = reactive(Array.from({ length: 4 }, () => 0));
    const duration = reactive(Array.from({ length: 4 }, () => 0));
    const audioStyle = reactive(Array.from({ length: 12 }, () => ''));
    const playBtnImg = ref(require("../assets/common/play-btn.png"));
    const volume = ref(100);
    const showVolume = ref(false);

    const videoRef = ref();
    const videoRef2 = ref();
    const customCursor = ref();
    const customCursorStyle = ref('display:none;');
    const videoPaused = ref(true);
    const customCursorImg = ref(require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png'));
    const showVideo = reactive(Array.from({ length: 2 }, () => false));

    playCircleAnim();

    function routeBtn(path){
      router.push(path);
    }

    function playCircleAnim(){
      circleAnimIndex = 1;
      clearInterval(circleAnimId);

      circleAnimId = setInterval(()=>{

          circleAnimIndex = (circleAnimIndex + 1) % (55+1);

        circleAnim.value = require('@/assets/Unit3-4/04-同心圓動態/20230413-04-物件-同心圓動態-'+circleAnimIndex.toString().padStart(2, '0') +'.png');

        }, 50);
      }

    function playAudio(index){
      if(!audio[index].value.paused){
        audio[index].value.pause();
        playBtnImg.value = require("../assets/common/play-btn.png");
      }else{
        audio[index].value.play();
        playBtnImg.value = require("../assets/common/20230629-2-0-物件-99-音樂暫停鍵.png");
      }
    }

    function updateTime(index) {
      currentTime[index] = audio[index].value.currentTime;
      duration[index] = audio[index].value.duration;
      audioStyle[index] = "width:" + (currentTime[index]/duration[index])*100 +"%";
    }

    function formatTime(time){
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    function seekAudio(index){
      audio[index].value.currentTime = currentTime[index];
    }

    function cdNumBtn(num){
        if(num==1){
          cdNum.value  = Math.min(cdNum.value+1, 3);
        }else{
          cdNum.value  = Math.max(cdNum.value-1, 0);
        }

        for (let i=0; i<4; i++){
          audio[i].value.pause();
        }
      playBtnImg.value = require("../assets/common/play-btn.png");
    }

    function setVolume(){
      for (let i=0; i <audio.length; i++){
        audio[i].value.volume = volume.value;
      }
    }

    function playVideo(){
      if(videoRef.value.paused){
        videoRef.value.play();
        //customCursor.value.style.opacity = 0;
        videoPaused.value = false;
        videoRef.value.controls = true;
        customCursorImg.value = require('../assets/common/20230704-物件-6-0-15-影片暫停鍵.png');
      }else{
        videoRef.value.pause();
        videoPausedEvent();
      }
    }

    function videoPausedEvent(){
      customCursorImg.value = require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png');
      videoPaused.value = true;
      videoRef.value.controls = false;
    }

    function playVideo2(){
      if(videoRef2.value.paused){
        videoRef2.value.play();
        //customCursor.value.style.opacity = 0;
        videoPaused.value = false;
        videoRef2.value.controls = true;
        customCursorImg.value = require('../assets/common/20230704-物件-6-0-15-影片暫停鍵.png');
      }else{
        videoRef2.value.pause();
        videoPaused.value = true;
        videoRef2.value.controls = false;
        customCursorImg.value = require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png');
      }
    }


    function handleMouseMove(event) {
      customCursor.value.style.left = (event.pageX - customCursor.value.clientWidth /2) + "px" ;
      customCursor.value.style.top = (event.pageY - customCursor.value.clientHeight /2)  + "px";
    }


    function openVideo(num){
      showVideo[num] = true;
      customCursorImg.value = require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png');
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
      showVideo[0] = false;
      showVideo[1] = false;
    }

    return{
      routeBtn,
      circleAnim,
      imgType,
      audio,
      currentTime,
      duration,
      audioStyle,
      playAudio,
      updateTime,
      formatTime,
      seekAudio,
      playBtnImg,
      volume,
      setVolume,
      showVolume,
      showImg,
      cdNum,
      cdNumBtn,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      videoRef,
      videoRef2,
      customCursor,
      customCursorStyle,
      playVideo,
      playVideo2,
      handleMouseMove,
      videoPaused,
      videoPausedEvent,
      customCursorImg,
      showVideo,
      openVideo,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit3-4.css"></style>
<style scoped src="../assets/css/audio-comp.css"></style>
