<template>
  <div class="wrap" @keydown.esc="escEvent">
<!--    <toolbar type="1" style="position: fixed; z-index: 10; top:4.5%;"></toolbar>-->
    <toolbar type="1" unit="1" route-name="unit1-2" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit1-2/20230920-對位-1-1-op-臺灣在這裡複本.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1">
      <img class="bg-1" src="../assets/Unit1-2/bg-1.png" alt="地圖" title="地圖">
      <div class="title-logo">思潮滾動世界</div>

      <model-viewer
          ref="modelViewerRef"
          tabindex="-1" alt="地球儀" title="地球儀"
          src="/Globe2.glb"
          autoplay
      ></model-viewer>

      <div class="model-btn">
        <img v-if="modelPlaying==true" @click="modelBtn(false)" @keydown.enter="modelBtn(false)" tabindex="0" class="w-full cursor-pointer" src="../assets/common/202300920-78-暫停鍵.png" alt="暫停鍵" title="暫停鍵">
        <img v-if="modelPlaying==false" @click="modelBtn(true)" @keydown.enter="modelBtn(true)" tabindex="0" class="w-full cursor-pointer" src="../assets/common/202300920-79-播放鍵.png" alt="播放鍵" title="播放鍵">
      </div>

      <div class="people-1" alt="人群" title="人群">
        <LottieTest :json="require('@/assets/Unit1-2/1-1-1.json')" ></LottieTest>
      </div>
    </div>
    <div class="part2">
      <img class="globe" src="../assets/Unit1-2/globe.png" alt="地球儀" title="地球儀">
      <div class="content">
        <p>臺灣在這裡</p>
        <p>日治時期地球儀</p>
        <p>秋惠文庫 寄存 T2018.001.3215</p>
        <p>日治時期的木製地球儀，上面將臺灣標示出來並註明為「新日本」，是當時對新世界的一種理解。</p>
      </div>
      <div class="point" @click="globeImg=!globeImg"  @keydown.enter="globeImg=!globeImg" tabindex="0" alt="點選看圖片" title="點選看圖片">
        <div></div>

        <img class="globe-img" :class="globeImg==true?'globe-img-open':''" src="../assets/Unit1-2/globe-img.png" alt="臺灣在這裡" title="臺灣在這裡">

        <!--        <div class="globe-img">-->
        <!--        </div>-->
      </div>
      <img class="zoom-btn"  @click="showGlobeImg=true" @keydown.enter="showGlobeImg=true" tabindex="0" src="../assets/Unit1-2/20230629-77-3D-icon.png" alt="彈出視窗" title="彈出視窗">

      <transition name="img-fade-div">
        <div class="model-btn" v-if="showGlobeImg">
          <img v-if="modelPlaying2==true" @click="modelBtn2(false)" @keydown.enter="modelBtn2(false)" tabindex="0" class="w-full cursor-pointer" src="../assets/common/202300920-78-暫停鍵.png" alt="暫停鍵" title="暫停鍵">
          <img v-if="modelPlaying2==false" @click="modelBtn2(true)" @keydown.enter="modelBtn2(true)" tabindex="0" class="w-full cursor-pointer" src="../assets/common/202300920-79-播放鍵.png" alt="播放鍵" title="播放鍵">
        </div>
      </transition>


    </div>

    <div class="part3">
      <img class="text-1" src="../assets/Unit1-2/text-1.png" alt="世界樣貌" title="世界樣貌">
      <div class="content" tabindex="0">
        這時世界大戰（1914-1918）剛落幕、 國際聯盟組成， 在新國際版圖重組過程中，美國威爾遜總統提出的「民族自決」原則，令殖民地青年見得解放的契機。前仆後繼的朝鮮獨立運動，中國掀起的反帝國、反封建運動，成為鼓動青年奮進的範示。俄國蘇維埃政權的建立，在國際間掀起共產主義革命浪潮串聯，以階級意識剖析社會、為無產者發聲的左派青年聲勢也逐日高漲。
      </div>

      <img class="poster" src="../assets/Unit1-2/poster.png" title="三一運動中的聽演講的民眾與俄國革命70周年海報" alt="三一運動中的聽演講的民眾與俄國革命70周年海報">
      <div class="people-2" alt="人" title="人">
        <LottieTest :json="require('@/assets/Unit1-2/1-1-2.json')" ></LottieTest>
      </div>

      <img class="zoom-btn-1"  @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
      <img class="zoom-btn-2"  @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
    </div>

    <div class="part4">
      <img class="map" src="../assets/Unit1-2/map.png" alt="世界地圖" title="世界地圖">

      <transition name="start-panel-fade" >
        <div class="start-panel" v-show="showStartPanel" >
          <div class="circle" >
            <div>
              <p>世界思潮</p>
              <p>覺醒吧!</p>
            </div>
            <div>
              <p>看新思潮如何影響世界。</p>
              <p><span style="border-bottom: 0.15vw solid white; ">點選地點</span>看事件細節。</p>
              <p><span style="border-bottom: 0.15vw solid white; ">點選下方年代</span>看世界思潮的發展。</p>
            </div>

            <img @click="showStartPanel=false;yearBtn(0)" @keydown.enter="showStartPanel=false;yearBtn(0);" tabindex="0"  class="circle-close" src="../assets/Unit1-1/close.png" alt="關閉" title="關閉" >
          </div>
        </div>
      </transition>

      <div class="map-point p-mexico" :class="mapPointClass[0]" @click="mapBtn([-1,-1,-1,18,-1,-1])" @keydown.enter="mapBtn([-1,-1,-1,18,-1,-1])" :tabindex="mapPointClass[0]==''?-1:0"><div class="inner-point"></div><p>墨西哥</p></div>
      <div class="map-point p-us" :class="mapPointClass[1]" @click="mapBtn([-1,2,13,-1,-1,-1])" @keydown.enter="mapBtn([-1,2,13,-1,-1,-1])" :tabindex="mapPointClass[1]==''?-1:0"><div class="inner-point"></div><p>美國</p></div>
      <div class="map-point p-finland" :class="mapPointClass[2]" @click="mapBtn([-1,3,-1,-1,-1,-1])" @keydown.enter="mapBtn([-1,3,-1,-1,-1,-1])" :tabindex="mapPointClass[2]==''?-1:0"><div class="inner-point"></div><p>芬蘭</p></div>
      <div class="map-point p-switzerland" :class="mapPointClass[3]" @click="mapBtn([-1,-1,-1,20,-1,-1])" @keydown.enter="mapBtn([-1,-1,-1,20,-1,-1])" :tabindex="mapPointClass[3]==''?-1:0"><div class="inner-point"></div><p>瑞士</p></div>
      <div class="map-point p-hungary" :class="mapPointClass[4]" @click="mapBtn([-1,5,16,-1,-1,-1])" @keydown.enter="mapBtn([-1,5,16,-1,-1,-1])" :tabindex="mapPointClass[4]==''?-1:0"><div class="inner-point"></div><p style="left: 160%">匈牙利</p></div>
      <div class="map-point p-ireland" :class="mapPointClass[5]" @click="mapBtn([-1,9,14,-1,-1,23])" @keydown.enter="mapBtn([-1,9,14,-1,-1,23])" :tabindex="mapPointClass[5]==''?-1:0"><div class="inner-point"></div><p>愛爾蘭</p></div>
      <div class="map-point p-turkey" :class="mapPointClass[6]" @click="mapBtn([-1,-1,24,24,24,24])" @keydown.enter="mapBtn([-1,-1,24,24,24,24])" :tabindex="mapPointClass[6]==''?-1:0"><div class="inner-point"></div><p style="left: 170%">土耳其</p></div>
      <div class="map-point p-germany" :class="mapPointClass[7]" @click="mapBtn([-1,10,17,-1,-1,-1])" @keydown.enter="mapBtn([-1,10,17,-1,-1,-1])" :tabindex="mapPointClass[7]==''?-1:0"><div class="inner-point"></div><p>德國</p></div>
      <div class="map-point p-bulgaria" :class="mapPointClass[8]" @click="mapBtn([-1,8,-1,-1,-1,-1])" @keydown.enter="mapBtn([-1,8,-1,-1,-1,-1])" :tabindex="mapPointClass[8]==''?-1:0"><div class="inner-point"></div><p style="left: 250%">保加利亞</p></div>
      <div class="map-point p-poland" :class="mapPointClass[9]" @click="mapBtn([-1,7,-1,-1,-1,-1])" @keydown.enter="mapBtn([-1,7,-1,-1,-1,-1])" :tabindex="mapPointClass[9]==''?-1:0"><div class="inner-point"></div><p>波蘭</p></div>
      <div class="map-point p-india" :class="mapPointClass[10]" @click="mapBtn([-1,6,-1,19,-1,-1])" @keydown.enter="mapBtn([-1,6,-1,19,-1,-1])" :tabindex="mapPointClass[10]==''?-1:0"><div class="inner-point"></div><p>印度</p></div>
      <div class="map-point p-russia" :class="mapPointClass[11]" @click="mapBtn([0,-1,11,-1,-1,-1])" @keydown.enter="mapBtn([0,-1,11,-1,-1,-1])" :tabindex="mapPointClass[11]==''?-1:0"><div class="inner-point"></div><p>蘇聯</p></div>
      <div class="map-point p-china" :class="mapPointClass[12]" @click="mapBtn([-1,-1,12,-1,21,-1])" @keydown.enter="mapBtn([-1,-1,12,-1,21,-1])" :tabindex="mapPointClass[12]==''?-1:0"><div class="inner-point"></div><p>中國</p></div>
      <div class="map-point p-hongkong" :class="mapPointClass[13]" @click="mapBtn([-1, 1,-1,-1,-1,-1])" @keydown.enter="mapBtn([-1, 1,-1,-1,-1,-1])" :tabindex="mapPointClass[13]==''?-1:0"><div class="inner-point"></div><p>香港</p></div>
      <div class="map-point p-korean" :class="mapPointClass[14]" @click="mapBtn([-1,-1,15,-1,-1,-1])" @keydown.enter="mapBtn([-1,-1,15,-1,-1,-1])" :tabindex="mapPointClass[14]==''?-1:0"><div class="inner-point"></div><p>朝鮮</p></div>
      <div class="map-point p-japan" :class="mapPointClass[15]" @click="mapBtn([-1,4,-1,-1, 22,-1])" @keydown.enter="mapBtn([-1,4,-1,-1, 22,-1])" :tabindex="mapPointClass[15]==''?-1:0"><div class="inner-point"></div><p>日本</p></div>


      <map-info @close-event="mapClose" :show="showArr[0]" name="蘇聯" year="1917" pos="top:23.2%;left:68.2%" content="十月社會主義革命，建立蘇維埃社會主義共和國。" :img="require('@/assets/Unit1-2/map-info/20230329-39-1917蘇聯.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[1]" name="香港" year="1918" pos="top:49.2%;left:75.2%" content="七月間爆發搶米風潮" :img="require('@/assets/Unit1-2/map-info/20230329-42-1918香港.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[2]" name="美國" year="1918" pos="top:36.2%;left:30.8%" content="歐戰後期，威爾遜提出14點和平原則揭櫫民族自決原則。" :img="require('@/assets/Unit1-2/map-info/20230329-49-1918美國.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[3]" name="芬蘭" year="1918" pos="top:23.2%;left:53.5%" content="芬蘭獨立，爆發左右派內戰。" :img="require('@/assets/Unit1-2/map-info/20230329-44-1918芬蘭.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[4]" name="日本" year="1918" pos="top:49.2%;left:77.3%" content="爆發全國性的米騷動暴亂事件" :img="require('@/assets/Unit1-2/map-info/20230329-41-1918日本.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[5]" name="匈牙利" year="1918" pos="top:37.2%;left:51.3%" content="菊花革命成立匈牙利第一共和國" :img="require('@/assets/Unit1-2/map-info/20230329-46-1918匈牙利.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[6]" name="印度" year="1918" pos="top:48.2%;left:65.7%" content="甘地推動公民不服從、非暴力不合作運動。" :img="require('@/assets/Unit1-2/map-info/20230329-43-1918印度.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[7]" name="波蘭" year="1918" pos="top:30.8%;left:51.7%" content="波蘭復國運動，建立波蘭共和國。" :img="require('@/assets/Unit1-2/map-info/20230329-40-1918波蘭.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[8]" name="保加利亞" year="1918" pos="top:39.8%;left:52.7%" content="各地爆發民族革命軍，向協約國求和。" :img="require('@/assets/Unit1-2/map-info/20230329-47-1918保加利亞.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[9]" name="愛爾蘭" year="1918" pos="top:30.2%;left:45.3%" content="愛爾蘭議會不承認大英國協統治權" :img="require('@/assets/Unit1-2/map-info/20230329-48-1918愛爾蘭.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[10]" name="德國" year="1918" pos="top:33.2%;left:50.1%" content="各地發生罷工與叛變，建立議會共和，德意志帝國滅亡。" :img="require('@/assets/Unit1-2/map-info/20230329-45-1918德國.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[11]" name="蘇聯" year="1919" pos="top:23.2%;left:68.2%" content="列寧成立共產第三國際，總部設於莫斯科。" :img="require('@/assets/Unit1-2/map-info/20230329-52-1919蘇聯.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[12]" name="中國" year="1919" pos="top:42.2%;left:74.3%" content="五四運動爆發，知識界激起民族主義運動。" :img="require('@/assets/Unit1-2/map-info/20230329-51-1919中國.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[13]" name="美國" year="1919" pos="top:36.2%;left:30.8%" content="鋼鐵業和肉類工人舉行大罷工，芝加哥等地爆發種族騷亂。" :img="require('@/assets/Unit1-2/map-info/20230329-57-1919美國.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[14]" name="愛爾蘭" year="1919" pos="top:30.2%;left:45.3%" content="愛爾蘭共和軍發動游擊戰式的獨立戰爭" :img="require('@/assets/Unit1-2/map-info/20230329-56-1919愛爾蘭.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[15]" name="朝鮮" year="1919" pos="top:40.2%;left:78.3%" content="獨立人士在首爾遊行發布獨立宣言書，史稱「三一運動」。<br>大韓民國臨時政府成立於中國上海。" :img="require('@/assets/Unit1-2/map-info/20230329-53-1919朝鮮.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[16]" name="匈牙利" year="1919" pos="top:37.2%;left:51.3%" content="共產黨革命推翻政府，成立匈牙利蘇維埃共和國(維持四個月)。" :img="require('@/assets/Unit1-2/map-info/20230329-55-1919匈牙利.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[17]" name="德國" year="1919" pos="top:33.2%;left:50.1%" content="威瑪政府成立共和國。<br>巴伐利亞蘇維埃共和國成立遭鎮壓。" :img="require('@/assets/Unit1-2/map-info/20230329-54-1919德國.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[18]" name="墨西哥" year="1920" pos="top:51.2%;left:23.7%" content="鐵路工人罷工掀起反政府浪潮，總統卡蘭薩被暗殺。" :img="require('@/assets/Unit1-2/map-info/20230329-60-1920墨西哥.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[19]" name="印度" year="1920" pos="top:48.2%;left:65.7%" content="印度國大黨轉型為大眾政黨，推動獨立運動。" :img="require('@/assets/Unit1-2/map-info/20230329-58-1920印度.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[20]" name="瑞士" year="1920" pos="top:42.2%;left:30.9%" content="國際聯盟成立，以維護世界和平為主要任務的國際組織。" :img="require('@/assets/Unit1-2/map-info/20230329-59-1920瑞士.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[21]" name="中國" year="1921" pos="top:42.2%;left:74.3%" content="中國共產黨成立於上海" :img="require('@/assets/Unit1-2/map-info/20230329-61-1921中國.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[22]" name="日本" year="1921" pos="top:49.2%;left:77.3%" content="閔元植暗殺事件" :img="require('@/assets/Unit1-2/map-info/20230329-62-1921日本.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[23]" name="愛爾蘭" year="1922" pos="top:30.2%;left:45.3%" content="愛爾蘭自由邦成立，成為大英國協的自治領土。" :img="require('@/assets/Unit1-2/map-info/20230329-63-1922愛爾蘭.png')"></map-info>
      <map-info @close-event="mapClose" :show="showArr[24]" name="土耳其" year="1919-23" pos="top:40.2%;left:54.3%" content="土耳其國民運動，成立共和國。" :img="require('@/assets/Unit1-2/map-info/20230329-50-1918土耳其.png')" type=2></map-info>



      <div class="timeline-wrap">
        <img class="bg" src="../assets/Unit1-2/timeline.png">
        <div class="year-btn year-btn-1" :class="curYear=='1917'?'active':''" @click="yearBtn(0)" @keydown.enter="yearBtn(0)" tabindex="0"><div>1917</div></div>
        <div class="year-btn year-btn-2" :class="curYear=='1918'?'active':''" @click="yearBtn(1)" @keydown.enter="yearBtn(1)" tabindex="0"><div>1918</div></div>
        <div class="year-btn year-btn-3" :class="curYear=='1919'?'active':''" @click="yearBtn(2)" @keydown.enter="yearBtn(2)" tabindex="0"><div>1919</div></div>
        <div class="year-btn year-btn-4" :class="curYear=='1920'?'active':''" @click="yearBtn(3)" @keydown.enter="yearBtn(3)" tabindex="0"><div>1920</div></div>
        <div class="year-btn year-btn-5" :class="curYear=='1921'?'active':''" @click="yearBtn(4)" @keydown.enter="yearBtn(4)" tabindex="0"><div>1921</div></div>
        <div class="year-btn year-btn-6" :class="curYear=='1922'?'active':''" @click="yearBtn(5)" @keydown.enter="yearBtn(5)" tabindex="0"><div>1922</div></div>
      </div>



    </div>

    <div class="part5">
      <div class="content">
        <p>臺灣也有地下革命黨？</p>
        <p>《獨立新聞》(於上海發行)  1922年12月13日</p>
        <p>大韓民國歷史博物館 提供</p>
        <p>當時朝鮮為日本所統治，於上海發行的韓國獨立運動刊物《獨立新聞》報導臺灣抗日組織。文中說到有一位名為「林建堂」的抗日領袖（應是指林獻堂），稱他一面反對總督獨裁、  推動自治運動，一面秘密徵兵，同時彰化發生破壞御遺跡碑的事件， 象徵臺灣即將爆發革命。文中稱日本官方為「倭官」、北白川宮能久是「倭將」。</p>
      </div>

      <div class="pic-1" v-show="part5Img==1">
        <img class="img" src="../assets/Unit1-2/pic-img-3.png" alt="《獨立新聞》(於上海發行) 1922年12月13日" title="《獨立新聞》(於上海發行) 1922年12月13日">
        <img class="zoom-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" src="../assets/Unit1-2/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
        <img class="right-arrow" @click="part5Img=2" @keydown.enter="part5Img=2" tabindex="0" src="../assets/common/right-arrow.png" alt="下一頁" title="下一頁">
      </div>

      <div class="pic-2" v-show="part5Img==2">
        <img class="img" src="../assets/Unit1-2/pic-img-4.png" alt="《獨立新聞》(於上海發行) 1922年12月13日" title="《獨立新聞》(於上海發行) 1922年12月13日">
        <img class="zoom-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" src="../assets/Unit1-2/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
        <img class="left-arrow" @click="part5Img=1" @keydown.enter="part5Img=1" tabindex="0" src="../assets/common/left-arrow.png" alt="上一頁" title="上一頁">
      </div>
    </div>

    <div class="part6">
      <div class="part6-wrap">
        <div class="content">
          <img src="../assets/Unit1-2/logo-text.png" alt="青年奮起" title="青年奮起">
          <p tabindex="0">飛行後援熱潮之後，李應章、吳海水、何禮棟等臺灣總督府醫學專門學校學生，計畫組成推動臺灣人文化向上的青年團體。在大稻埕執業的醫師蔣渭水，鼓勵擴大組織，並參考世界大勢、串聯全臺有志之士加入。</p>
          <p tabindex="0">同一期間，東京臺灣青年組成的「新民會」發刊的《臺灣青年》，在發刊詞中急切的問：「臺灣的青年！高砂的健兒！我們還可靜默著不奮起嗎？」</p>
        </div>

        <div class="book" alt="臺灣青年" title="臺灣青年">
          <LottieTest :json="require('@/assets/Unit1-2/1-1-3.json')" ></LottieTest>
        </div>

      </div>
    </div>

    <div class="part7">
      <div class="unit-btn-wrap">
        <div class="unit-btn" @click="routeBtn('/unit1-1')" @keydown.enter="routeBtn('/unit1-1')" tabindex="0">
          <img src="../assets/Unit1-1/unit1-1.png" alt="鳥瞰世界" title="鳥瞰世界">
          <div>鳥瞰世界</div>
        </div>
        <div class="unit-btn" style="width: 16vw" @click="routeBtn('/unit1-2')" @keydown.enter="routeBtn('/unit1-2')" tabindex="0">
          <img src="../assets/Unit1-1/20230801-1-鳥瞰世界-物件-23-思潮滾動世界按紐－淡.jpg"  alt="思潮滾動世界" title="思潮滾動世界">
          <div>思潮滾動世界</div>
        </div>
      </div>
    </div>




    <!--寫這裡-->
      <!--img0--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos">
      <div class="img-frame-div"  v-if="showGlobeImg">
<!--              <img style="position: fixed; top: 0%; left: 0%; z-index: 10; opacity: 0.5" src="../assets/Unit1-2/20230920-對位-1-1-op-臺灣在這裡複本.png">-->
        <div class="black-bg" ></div>
        <div class="frame">
<!--          <img class="relative" style="width:18.2vw; margin:2.71vw 0 0 22.8vw" src="../assets/Unit1-2/globe.png">-->
          <div class="content-text">
            <div class="title" style="text-align: center">日治時期地球儀</div>
            <div class="subtitle">秋惠文庫 寄存 T2018.001.3215</div>
          </div>

          <model-viewer
              ref="modelViewerRef2"
              src="/Globe2.glb"
              autoplay
              :camera-controls="true"
          ></model-viewer>
          <div class="feature">
<!--              <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">-->
<!--              <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">-->
              <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showGlobeImg=false">
          </div>
        </div>
      </div>
    </transition>
     <!--img0--><!--hor-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[0]">
<!--         <img style="position: fixed; top: 0%; left: 0%; z-index: 10; opacity: 0.5" src="../assets/Unit1-2/20230629&#45;&#45;1-18-pic彈跳視窗.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full"  :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:24.5vw; margin:4.3vw 0 0 13.6vw" src="../assets/Unit1-2/20230629-18-pic.png" alt="三一運動中的聽演講的民眾" title="三一運動中的聽演講的民眾">
          </div>

        <div class="content-text" style="position: absolute; top:7.5vw; left: 43.7vw; width: 14.5vw;">
          <div class="bold-title">三一運動中的聽演講的民眾</div>
          <div class="noto-text" style="margin-top: 0.5vw; text-align: center;">大韓民國歷史博物館 提供</div>
        </div>
          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>

        </div>
      </div>
    </transition>
    <!--img1--><!--hor-->
    <transition name="img-frame-fade" @before-enter="resetPos"  >
      <div class="img-frame-div"  v-show="showImg[1]">
<!--         <img style="position: fixed; top: 0%; left: 0%; z-index: 10; opacity: 0.5" src="../assets/Unit1-2/20230629-1-1-11-pic彈跳視窗.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full"  :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:40.7vw; margin:4.5vw 0 0 -3.5vw" src="../assets/Unit1-2/20230629-11-pic.png" alt="俄國革命70周年海報" title="俄國革命70周年海報">
          </div>

          <div class="content-text" style="position: absolute; top:8.5vw; left: 43.7vw; width: 29vw;">
            <div class="bold-title" style="text-align: center">俄國革命70周年海報</div>
            <div class="subtitle" style="text-align: center">朱家煌 提供</div>
            <div class="noto-text">紅底海報上的文字，左上角俄文意譯為「自由世界、一切權力歸蘇維埃、土地」。右側分別為Mir vrede beke (德文：我要和平)、Paz peace (葡萄牙文：和平)、Mir Hòa Bình (越南文：和平)、Paix frieden (德文：自由)、 Pokój(波蘭文：和平)。</div>
          </div>
          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>

        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos"  >
      <div class="img-frame-div"  v-show="showImg[2]">
<!--    <img style="position: fixed; top: 0%; left: 0%; z-index: 10; opacity: 0.5" src="../assets/Unit1-2/20230629-1-1-20-pic彈跳視窗.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full"  :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:24.8vw; margin:0vw 0 0 19.5vw" src="../assets/Unit1-2/20230629-20-pic-放大文物.png" alt="《獨立新聞》(於上海發行) 1922年12月13日" title="《獨立新聞》(於上海發行) 1922年12月13日">
          </div>
          <div class="content-text" style="position: absolute; top:35vw; left: 19.8vw; width: 24vw;">
            <div>
              <div class="bold-title" style="text-align: center;">《獨立新聞》(於上海發行) 1922年12月13日</div>
              <div class="noto-text" style="margin-top: 0.4vw; text-align: center">大韓民國歷史博物館 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos"  >
      <div class="img-frame-div"  v-show="showImg[3]">
<!--         <img style="position: fixed; top: 0%; left: 0%; z-index: 10; opacity: 0.5" src="../assets/Unit1-2/20230629-1-1-21-pic彈跳視窗.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full"  :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:59.2vw; margin:3vw 0 0 3.2vw" src="../assets/Unit1-2/20230629-21-pic-放大文物.png" alt="《獨立新聞》(於上海發行) 1922年12月13日" title="《獨立新聞》(於上海發行) 1922年12月13日">
          </div>
          <div class="content-text" style="position: absolute; top:35vw; left: 19.8vw; width: 24vw;">
            <div >
              <div class="bold-title" style="text-align: center;">《獨立新聞》(於上海發行) 1922年12月13日</div>
              <div class="noto-text" style="margin-top: 0.4vw;text-align: center;">大韓民國歷史博物館 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')" >
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>


    <intro-btn ></intro-btn>

    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import IntroBtn from "@/components/IntroBtn";
import router from "@/router";
import LottieTest from "@/components/LottieTest";
import { ModelViewer } from '@google/model-viewer';
import {onUnmounted, reactive, ref} from "vue";
import MapInfo from "@/components/MapInfo";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";


export default {
  name: "Unit1-2",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    ModelViewer,
    MapInfo,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();

    const globeImg = ref(false);
    const showGlobeImg = ref(false);
    const showImg = reactive(Array.from({ length: 10 }, () => false));
    const part5Img = ref(1);

    const mapPointClass = reactive(Array(16).fill(''));
    const showArr = reactive(Array(25).fill('false'));
    const curYear = ref('1917');

    const showStartPanel = ref(true);
    const modelViewerRef = ref(null);
    const modelPlaying = ref(true);
    const modelViewerRef2 = ref(null);
    const modelPlaying2 = ref(true);

    let animArr = [
      [0],
      [6,2,3,1,4,8,7,5,10,9],
      [14,11,15,16,12,24,13,10],
      [24,19,20,18],
      [24,22,21],
      [24,23]
    ]
    let animIndex = 0;
    let mapAnimInterval = null;

    function routeBtn(path){
      router.push(path);
    }

    //yearBtn(0);

    function yearBtn(index){
      mapPointClass.forEach((item, index) => {mapPointClass[index] = "";});

      switch (index){
        case 0:
          curYear.value = '1917';
          mapPointClass[11] = 'map-point-active';
          break;
        case 1:
          curYear.value = '1918';
          mapPointClass[9] = 'map-point-active';
          mapPointClass[15] = 'map-point-active';
          mapPointClass[13] = 'map-point-active';
          mapPointClass[10] = 'map-point-active';
          mapPointClass[2] = 'map-point-active';
          mapPointClass[7] = 'map-point-active';
          mapPointClass[4] = 'map-point-active';
          //mapPointClass[6] = 'map-point-active';
          mapPointClass[8] = 'map-point-active';
          mapPointClass[5] = 'map-point-active';
          mapPointClass[1] = 'map-point-active';
          break;
        case 2:
          curYear.value = '1919';
          mapPointClass[12] = 'map-point-active';
          mapPointClass[11] = 'map-point-active';
          mapPointClass[14] = 'map-point-active';
          mapPointClass[7] = 'map-point-active';
          mapPointClass[4] = 'map-point-active';
          mapPointClass[5] = 'map-point-active';
          mapPointClass[1] = 'map-point-active';
          mapPointClass[6] = 'map-point-active';
          break;
        case 3:
          curYear.value = '1920';
          mapPointClass[10] = 'map-point-active';
          mapPointClass[3] = 'map-point-active';
          mapPointClass[0] = 'map-point-active';
          mapPointClass[6] = 'map-point-active';
          break;
        case 4:
          curYear.value = '1921';
          mapPointClass[15] = 'map-point-active';
          mapPointClass[12] = 'map-point-active';
          mapPointClass[6] = 'map-point-active';
          break;
        case 5:
          curYear.value = '1922';
          mapPointClass[5] = 'map-point-active';
          mapPointClass[6] = 'map-point-active';
          break;

      }

      animIndex = 0;
      clearInterval(mapAnimInterval);
      mapAnimInterval = setInterval(mapAnim, 3000);
    }

    onUnmounted(()=>{
      clearInterval(mapAnimInterval);
    });

    function mapAnim(){
      /*暫時關閉倫波動畫*/
      return;

      let curIndex = 0;

      mapClose();
      switch (curYear.value){
        case '1917': curIndex = 0; break;
        case '1918': curIndex = 1; break;
        case '1919': curIndex = 2; break;
        case '1920': curIndex = 3; break;
        case '1921': curIndex = 4; break;
        case '1922': curIndex = 5; break;
      }

      let next = animIndex;
      if(next >= animArr[curIndex].length){
        next = 0;
        animIndex = 0;

        switch (curYear.value){
          case '1917': curYear.value='1918'; curIndex = 1; break;
          case '1918': curYear.value='1919'; curIndex = 2; break;
          case '1919': curYear.value='1920'; curIndex = 3; break;
          case '1920': curYear.value='1921'; curIndex = 4; break;
          case '1921': curYear.value='1922'; curIndex = 5; break;
          case '1922': curYear.value='1917'; curIndex = 0; break;
        }
      }

      showArr[animArr[curIndex][next]] = 'true';
      animIndex++;
    }

    function mapBtn(mapArr){
      mapClose();

      let curIndex = -1;

      switch (curYear.value){
        case '1917': curIndex = 0; break;
        case '1918': curIndex = 1; break;
        case '1919': curIndex = 2; break;
        case '1920': curIndex = 3; break;
        case '1921': curIndex = 4; break;
        case '1922': curIndex = 5; break;
      }
      if(curIndex != -1){
        showArr[mapArr[curIndex]] = 'true';
      }

      clearInterval(mapAnimInterval);
      mapAnimInterval = setInterval(mapAnim, 5000);
    }

    function mapClose(){
      showArr.forEach((arr, index)=>{
        showArr[index] = 'false';
      });
    }

    function escEvent(){
      showGlobeImg.value = false;
      for (let i=0; i<showImg.length;i++)
        showImg[i] = false;
    }


    function modelBtn(playing){
      if(playing){
        modelViewerRef.value.play();
      }else{
        modelViewerRef.value.pause();
      }
      modelPlaying.value = playing;
    }

    function modelBtn2(playing){
      if(playing){
        modelViewerRef2.value.play();
      }else{
        modelViewerRef2.value.pause();
      }
      modelPlaying2.value = playing;
    }

    return{
      routeBtn,
      globeImg,
      showGlobeImg,
      mapPointClass,
      yearBtn,
      curYear,
      mapBtn,
      showArr,
      mapClose,
      showStartPanel,
      part5Img,
      showImg,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent,
      modelViewerRef,
      modelPlaying,
      modelBtn,
      modelViewerRef2,
      modelPlaying2,
      modelBtn2
    }

  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit1-2.css"></style>
<style scoped>


.test-btn{
  position: absolute;
  //background: black;
  width: 30vw;
  height: 18vw;
  bottom: 4%;
  left: 14%;
  cursor: pointer;
}


</style>
