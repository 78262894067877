<template>
  <div class="wrap" @mousemove="handleMouseMove" @keydown.esc="escEvent">
    <entoolbar type="2" unit="4" route-name="unit6-2" color="brown"></entoolbar>

<!--    <img class="sample" src="../assets/Unit6-2/sample/20230428-EN網站-6-0-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1">
        <div class="video-div" @mousemove="handleMouseMove" @mouseleave="customCursor.style.opacity=0">
          <!--autoplay muted playsinline-->
          <video id="video"  class="w-full" ref="videoRef"  @click="playVideo"  @pause="videoPausedEvent">
            <source src="../assets/Unit6-2/video.mp4"
                    type="video/mp4">
          </video>
        </div>

      <div class="video-touch"  @mousemove="customCursorStyle='opacity:1'" @mouseleave="customCursorStyle='display:none'" @click="playVideo" @keydown.enter="playVideo" tabindex="0"></div>

        <div class="content en-content-style">
          <p>In 2014, a bronze statue crafted by Huang Tu-shui portraying Takagi Tomoe was placed at Changhua Senior High School. The tale behind this sought-after national treasure, pursued by various Taiwanese and Japanese museums, unfolds as a fascinating side story in the nearly century-long history of the Taiwan Cultural Association.</p>
          <p style="margin-top: 1.2vw">For generations, Takagi's descendants had safeguarded this bronze statue. In 2011, students from Changhua Senior High School selected Takagi Tomoe as their research subject and traveled to Japan for a field study. The family later expressed interest in donating cultural artifacts. Touched by the enthusiasm of the Changhua students during a subsequent visit, the family ultimately chose to donate the bronze statue and cultural relics to Changhua.</p>
        </div>

        <img class="pic-1" src="../assets/Unit6-2/20230417-6-0-物件-01-首圖1.png" alt="Bronze bust of Takagi Tomoe sculpted by Huang Tu-shui" title="Bronze bust of Takagi Tomoe sculpted by Huang Tu-shui">
    </div>

    <div class="part2">
      <img class="logo-img" src="../assets/Unit6-2/en/20230428-EN網站-6-0-物件-03-裝飾字1-高木友枝.png" alt="Takagi Tomoe" title="Takagi Tomoe">

      <div class="content-1">
        <div class="year">1901</div>
        <div><div class="dot"></div>Takagi Tomoe (1858-1943) was born in the Mutsu Province of Japan (now Fukushima). A graduate of the School of Medicine at Tokyo Imperial University, he once served as an assistant to the renowned pathologist Kitasato Shibasaburou. He was also involved in the investigation of the bubonic plague in Hong Kong and was recognized as a leading expert in infectious diseases. Goto Shinpei invited Takagi Tomoe to Taiwan due to the prevalence of bubonic plague on the island.</div>
      </div>
      <div class="content-2">
        <div class="year">1902</div>
        <div><div class="dot"></div>Holding positions such as Director of the Taiwan Governor-General's Hospital and Principal of the Medical School, Takagi championed character education, emphasizing its importance during his tenure at the medical school. He opposed the suppression of local language and culture, frequently expressing sympathy for spirited students involved in social movements. At the Taiwan Cultural Association's opening ceremony, Takagi openly addressed the organization's weaknesses and advocated for a small yet elite team. Loa Ho, Lin Hu, and other future active members of the association took photos to commemorate the event.</div>
      </div>
      <div class="content-3">
        <div class="year">1919</div>
        <div><div class="dot"></div>Takagi was named President of the Taiwan Power Company.</div>
      </div>
      <div class="content-4">
        <div class="year">1921</div>
        <div><div class="dot"></div>Takagi attended the inaugural meeting of the Taiwan Cultural Association. In a speech at the event, he said, "It's quite challenging for any organization to endure indefinitely. The character '會' (hui), meaning 'Association', has a broad top and a narrow bottom, which should make this clear. If asked for my opinion on founding a cultural association, I would suggest not amassing a large group of a thousand or twelve hundred people, but instead depending on a strong team of twenty to thirty passionate individuals who can sustain it for a certain period of time.”</div>
      </div>
      <div class="content-5">
        <div class="year">1929</div>
        <div><div class="dot"></div>Takagi retired from the Taiwan Power Company, and Huang Tu-shui was commissioned to create a sculpture of him, which Takagi then took back to Japan.</div>
      </div>
      <div class="content-6">
        <p class="en-title-style" style="color: #4686ba; white-space: nowrap;">Bronze bust of Takagi </p>
        <p class="en-title-style" style="color: #4686ba; white-space: nowrap;">Tomoe sculpted by</p>
        <p class="en-title-style" style="color: #4686ba; white-space: nowrap;">Huang Tu-shui</p>
        <p class="en-subtitle-style" style="margin-top: 0.4vw"></p>
        <p class="en-source-style" style="margin-top: 0.3vw">Located at Changhua Senior High School</p>
        <p class="en-content-style" style="margin-top: 0vw;height: 13.5vw; overflow: auto">Huang Tu-shui (1895-1930), a sculptor during the Japanese period, was selected to show his work at the Imperial Academy art exhibition in 1921. The Taiwan Electric Power Company hired Huang Tu-shui to sculpt this bust to be presented to Takagi Tomoe as a retirement gift. Visible on the side of the sculpture is Huang Tu-shui’s name and on the back an inscription from the Taiwan Electric Power Company.</p>
      </div>
      <div class="content-7">
        <p class="en-title-style" style="color: #4686ba;">Calligraphy by Takagi Tomoe</p>
        <p class="en-subtitle-style" style="margin-top: 0.4vw"></p>
        <p class="en-source-style" style="margin-top: 0.3vw">Located at Changhua Senior High School</p>
        <p class="en-content-style" style="margin-top: 0.5vw">Prior to departing from Taiwan after retirement, Takagi Tomoe wrote this tanka: “Don’t worry about what others think. Use the little power you have to do what you can for this land.” -Reflections on my return to Tokyo after 28 years in Taiwan.</p>
<!--        <p class="content-style">臺灣生活28年歸京感懷</p>-->
      </div>

      <div class="img-1">
        <LottieTest :json="require('@/assets/Unit6-2/6-0-0.json')" alt="Takagi Tomoe" title="Takagi Tomoe"></LottieTest>
      </div>
      <div class="img-2">
        <LottieTest :json="require('@/assets/Unit6-2/6-0-1.json')" alt="Takagi Tomoe" title="Takagi Tomoe"></LottieTest>
      </div>
      <div class="img-3">
        <img class="w-full" src="../assets/Unit6-2/20230417-6-0-物件-05-pic1.png" alt="Bronze bust of Takagi Tomoe sculpted by Huang Tu-shui" title="Bronze bust of Takagi Tomoe sculpted by Huang Tu-shui">

        <div class="point-1" @click="zoomImg[0]=!zoomImg[0];zoomImg[1]=false;" @keydown.enter="zoomImg[0]=!zoomImg[0];zoomImg[1]=false;" tabindex="0" alt="Click to see picture" title="Click to see picture">
          <div></div>
          <img class="zoom-img" :class="zoomImg[0]==true?'open-zoom-img':''" src="../assets/Unit6-2/20230417-6-0-物件-10-pic3.png" alt="Visible on the side of the sculpture is Huang Tu-shui’s name" title="Visible on the side of the sculpture is Huang Tu-shui’s name">
        </div>
        <div class="point-2" @click="zoomImg[1]=!zoomImg[1];zoomImg[0]=false;" @keydown.enter="zoomImg[1]=!zoomImg[1];zoomImg[0]=false;" tabindex="0" alt="Click to see picture" title="Click to see picture">
          <div></div>
          <img class="zoom-img" :class="zoomImg[1]==true?'open-zoom-img':''" src="../assets/Unit6-2/20230417-6-0-物件-09-pic2.png" alt="Visible on the back of the sculpture  an inscription from the Taiwan Electric Power Company" title="Visible on the back of the sculpture  an inscription from the Taiwan Electric Power Company">
        </div>

        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 2%; right: 4%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">

      </div>
      <div class="img-4">
        <img class="w-full" src="../assets/Unit6-2/20230417-6-0-物件-11-pic4.png" alt="Calligraphy by Takagi Tomoe" title="Calligraphy by Takagi Tomoe">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0"  style="width: 2.2vw; bottom: 2%; right: 4%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
    </div>

    <div class="custom-cursor" ref="customCursor"  :style="customCursorStyle" >
      <img class="w-full" :src="customCursorImg">
    </div>

    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit6-2/sample/20230417-對位-6-0-op-06.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:16.2vw; margin:0.9vw 0 0 23.35vw" src="../assets/Unit6-2/20230704-物件-6-0-05-pic1-放大文物.png" alt="Bronze bust of Takagi Tomoe sculpted by Huang Tu-shui" title="Bronze bust of Takagi Tomoe sculpted by Huang Tu-shui">
          </div>

          <div class="content-text" style="top:30.7vw;">
            <div >
              <div class="bold-title">Bronze bust of Takagi Tomoe sculpted by Huang Tu-shui</div>
              <div class="noto-text" >Located at Changhua Senior High School</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit6-2/sample/20230417-對位-6-0-op-07.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:7.1vw; margin:2.5vw 0 0 29vw" src="../assets/Unit6-2/20230704-物件-6-0-11-pic4-放大文物.png" alt="Calligraphy by Takagi Tomoe" title="Calligraphy by Takagi Tomoe">
          </div>

          <div class="content-text" style="top:30.7vw; ">
            <div >
              <div class="bold-title">Calligraphy by Takagi Tomoe</div>
              <div class="noto-text" >Located at Changhua Senior High School</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>

    <en-intro-btn></en-intro-btn>
    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn";
import {onMounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import LottieTest from "@/components/LottieTest";
import myMixin from "@/assets/common/js/mixin";
export default {
  name: "Unit6-2",
  mixins: [myMixin],
  components:{
    LottieTest,
    Entoolbar,
    EnIntroBtn,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const videoRef = ref();
    const customCursor = ref();
    const customCursorStyle = ref('display:none;');
    const videoPaused = ref(true);
    const zoomImg = reactive(Array.from({ length: 2 }, () => false));
    const showImg = reactive(Array.from({ length: 2 }, () => false));
    const customCursorImg = ref(require('../assets/Unit6-2/20230417-6-0-物件-02-影片播放鍵.png'));

    onMounted(()=>{
        videoRef.value.currentTime = 1.5;
    });

    function routeBtn(path){
      router.push(path);
    }

    function playVideo(){
      if(videoRef.value.paused){
        if(videoRef.value.currentTime == 1.5){
          videoRef.value.currentTime = 0;
        }
        videoRef.value.play();
        //customCursor.value.style.opacity = 0;
        customCursorImg.value = require('../assets/Unit6-2/20230704-物件-6-0-15-影片暫停鍵.png');
        videoPaused.value = false;
        videoRef.value.controls = true;
      }else{
        videoRef.value.pause();
        videoPausedEvent();
      }
    }

    function videoPausedEvent(){
      customCursorImg.value = require('../assets/Unit6-2/20230417-6-0-物件-02-影片播放鍵.png');
      videoPaused.value = true;
      videoRef.value.controls = false;
    }

    function handleMouseMove(event) {
      // customCursor.value.style.opacity = 1;
      // const containerWidth = event.target.offsetWidth;
      // const containerHeight = event.target.offsetHeight;
      // customCursor.value.style.left = ((event.clientX - event.target.offsetLeft) / containerWidth * 100)-47 + "%";
      // customCursor.value.style.top = ((event.clientY - event.target.offsetTop) / containerHeight * 100 )-20 + "%";
      customCursor.value.style.left = (event.pageX - customCursor.value.clientWidth /2) + "px" ;
      customCursor.value.style.top = (event.pageY - customCursor.value.clientHeight /2)  + "px";
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      videoRef,
      playVideo,
      handleMouseMove,
      customCursor,
      customCursorStyle,
      videoPaused,
      videoPausedEvent,
      zoomImg,
      showImg,
      customCursorImg,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit6-2.css"></style>
