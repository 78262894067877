<template>
  <div class="wrap" @keydown.esc="escEvent">
    <EnToolbar type="1" unit="3" route-name="unit3-5" color="brown"></EnToolbar>

<!--    <img class="sample" src="../assets/Unit3-5/sample/20230704-對位-3-5-op-01.png">-->
<!--    <a href="http://122.117.191.93:6981" target="_blank"><div style="position: absolute; bottom: 0%; left: 0; width: 100%; height: 49.6vw; cursor: pointer"></div></a>-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-5/3-1-0.json')" ></LottieTest>
      </div>
      <img class="img-2" src="../assets/Unit3-5/20230411-3-5-物件-02-插圖１.png" alt="Esperanto" title="Esperanto">
      <div class="title-logo">Esperanto</div>
    </div>

    <div class="part2 part-color">
      <div class="section-1">
        <div class="content">
          <img class="logo-text" src="../assets/Unit3-5/en/20230708-EN網站-3-5-物件-03-裝飾字1-語文革命.png" alt="Language Revolution" title="Language Revolution">
          <div class="content-style" style="width: 50%; margin-top: 2.2vw">
            <p>Members of the Taiwan Cultural Association historically advocated for various language movements, including Modern Chinese and Taiwanese. A more revolutionary and lesser-known effort among these was the Esperanto Movement.</p>
            <p style="margin-top: 1vw">Esperanto is an artificial language created by L.L. Zamenhof (1859-1917), a Polish doctor. A simple and easy-to-learn language, it was designed to breakdown communication barriers and thereby promote mutual understanding. Esperanto is regarded as a cultural movement that pursues equality, freedom of expression, choice of language, and cross-ethnic education, while conveying the concept that “The world is as one family.”</p>
            <p style="margin-top: 1vw">Lien Wen-ching, an important member of the Taiwan Cultural Association, and Su Bi-hui were leading Esperanto enthusiasts in Taiwan. Chuang Sung-lin — a core member of the Tainan Association of Literature and Art — and biologist Wang Yu-qing also promoted Esperanto. Materials created to promote Esperanto show their courage to change mindsets and establish a global culture.</p>
          </div>
        </div>
              <img class="img-1" src="../assets/Unit3-5/20230411-3-5-物件-04-插圖5.png" alt="World map" title="World map">
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-5/3-5-1.json')" ></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <div class="content-1">
        <p class="title-style" style="color: #267c68">Exploring Taiwan and the World through Esperanto</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Taiwan Esperanto Association Magazine <i>La Verda Ombro</i> (The Green Shade) - October 1920</p>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</p>
        <p class="content-style" style="max-height:10vw; overflow: scroll; margin-top: 0.5vw">Simple, handwritten hectographstencil printed publication. This collection encompasses a diverse range of topics, from global affairs and the movement to establish a Taiwanese parliament, to the ideas of Esperanto's founder Zamenhof and Indian leader Gandhi, as well as stories about Taiwanese indigenous people. <i>La Verda Ombro</i> was the most enduring Esperanto publication in Taiwan during that period. A total of 39 issues have been preserved, with publication dates spanning from January 1920 to March 1926.  The image on the left page features artifacts from Taiwanese indigenous people. In the text, they are respectfully referred to as "indiĝeno en Formoso" (Taiwanese indigenous people) rather than the more derogatory term "savaĝulo" (savage) commonly used at the time, reflecting a forward-thinking perspective.</p>
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-5/20230411-3-5-物件-05-pic1.png" alt="Exploring Taiwan and the World through Esperanto" title="Exploring Taiwan and the World through Esperanto">
        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit3-5/20230411-3-5-物件-07-pic2.png" alt="Esperanto Textbook" title="Esperanto Textbook">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0"  style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #267c68">Esperanto Textbook</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Published by the Taiwan Esperanto Association's Ministry of Education for Esperanto Learning - May 1932</p>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</p>
        <p class="content-style" style="margin-top: 0.5vw">This Esperanto study guide, published by Lien Wen-ching on behalf of the "Taiwan Esperanto Association's Ministry of Education," features an introduction in Japanese, with the remaining content in Esperanto. The guide comprises practice exercises and Esperanto vocabulary.</p>
      </div>
      <div class="content-3">
        <p class="title-style" style="color: #267c68">Let's Speak Esperanto Together</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Group photo from the Tainan Esperanto ESP Seminar</p>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of Zhuang Ming-zheng</p>
        <p class="content-style" style="margin-top: 0.5vw">Chuang Sung-lin (1910-1974), a graduate of Tainan Commercial Vocational School who later studied in China, stated that he had attended the Esperanto ESP seminars twice, in 1931 and 1935. Chuang Sung-lin is the second from the left in the back row, while Wang Yu-qing is seated in the center of the front row, and his Japanese wife, Saeki Misao, is the second from the left.</p>
      </div>
      <div class="img-3">
        <img class="w-full" src="../assets/Unit3-5/20230411-3-5-物件-08-pic3.png" alt="Let's Speak Esperanto Together" title="Let's Speak Esperanto Together">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0"  style="width: 2.2vw; bottom: 3%; right: 3%; z-index:5;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="img-4">
        <img class="w-full" src="../assets/Unit3-5/20230411-3-5-物件-09-插圖3.png" alt="Let's Speak Esperanto Together" title="Let's Speak Esperanto Together">
        <img class="zoom-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0"  style="width: 2.2vw; bottom: 49.5%; left: 27.4%;" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
      </div>
    </div>

    <div class="part4 part-color">
<!--      <img class="img-2" src="../assets/Unit3-5/20230411-3-5-物件-16-插圖3.png">-->
<!--      <img class="img-1" @click="openUrl()" src="../assets/Unit3-5/20230411-3-5-物件-14-翻譯處.png" alt="開啟新視窗" title="開啟新視窗">-->
      <transition name="img-frame-fade" >
        <div class="circle" v-if="showStartPanel==true">
          <div>
            <p>How to speak</p>
            <p>in Esperanto?</p>
          </div>
          <div>
            <p>Do you know the Esperanto of </p>
            <p>“Blessed to be global citizens”?</p>
            <p>Let’s check it.</p>
          </div>
          <div class="play-btn" @click="openUrl()" @keydown.enter="openUrl()" tabindex="0" alt="Search" title="Search">Search</div>
<!--          <img @click="showStartPanel=false" class="circle-close" src="../assets/Unit1-1/close.png" >-->
        </div>
      </transition>

      <img class="img-1" src="../assets/Unit3-5/20230704-3-5-物件-25-插畫４.png" alt="World map" title="World map">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-5/3-5-2.json')" ></LottieTest>
      </div>
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit3-5/3-5-3.json')" ></LottieTest>
      </div>
      <div class="json-3">
        <LottieTest :json="require('@/assets/Unit3-5/20230704-3-5-4.json')" ></LottieTest>
      </div>

      <div class="back-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
        <p style="margin-left: 0.5vw">Back</p>
      </div>
    </div>

    <!--寫這裡-->
    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--     <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-02.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:2.8vw 0 0 15vw" src="../assets/Unit3-5/20230704-3-5-物件-05-pic1-放大文物.png" alt="Taiwan Esperanto Association Magazine" title="Taiwan Esperanto Association Magazine">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Taiwan Esperanto Association Magazine <i>La Verda Ombro </i> (The Green Shade) - October 1920</div>
              <div class="noto-text" >Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--                                <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-03.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:18.1vw; margin:2.8vw 0 0 22.7vw" src="../assets/Unit3-5/20230704-3-5-物件-07-pic2-放大文物.png" alt="Published by the Taiwan Esperanto Association's Ministry of Education for Esperanto Learning - May 1932" title="Published by the Taiwan Esperanto Association's Ministry of Education for Esperanto Learning - May 1932">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Published by the Taiwan Esperanto Association's Ministry of Education for Esperanto Learning - May 1932</div>
              <div class="noto-text" >Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--                                        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-04.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.5vw; margin:2.9vw 0 0 14vw" src="../assets/Unit3-5/20230704-3-5-物件-08-pic3-放大文物.png" alt="Group photo from the Tainan Esperanto ESP Seminar" title="Group photo from the Tainan Esperanto ESP Seminar">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Group photo from the Tainan Esperanto ESP Seminar</div>
              <div class="noto-text" >Courtesy of Zhuang Ming-zheng</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--                        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-05.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:20vw; margin:4vw 0 0 7vw" src="../assets/Unit3-5/20230704-3-5-物件-10-pic4-放大文物.png" alt="Verda Insulo" title="Verda Insulo">
          </div>

          <div class="content-text" style="top:4.5vw; left: 45vw; width: 32vw;">
            <div class="bold-title"><i>Verda Insulo</i> (The Green Island), 2nd Year, 2nd Issue</div>
            <div class="noto-text" >Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</div>
            <div class="sub-title" style="text-align: left">In 1932, the Esperanto Promotion Society Tainan Branch was founded at Tamachi Sekai TheaterWorld Hall in Tamachi Tainan. After its establishment, members started preparing for the publication of the magazine Verda Insulo. Wang Yu-qing, a teacher and biologist at Chang Jung Senior High School, took on the roles of editor, publisher, and printer. The magazine was issued by the Tainan Esperanto Society.</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>

    <EnIntroBtn></EnIntroBtn>

    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn.vue";
import {reactive, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";

export default {
  name: "Unit3-5",
  mixins: [myMixin],
  components:{
    EnToolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));
    const showStartPanel = ref(true);

    function routeBtn(path){
      router.push(path);
    }

    function openUrl(){

        window.open("https://taiwanfuntake.com/language/#/en/", "_self");

    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      showStartPanel,
      openUrl,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit3-5.css"></style>
