<template>
  <div class="wrap" @keydown.esc="escEvent">
    <EnToolbar type="1" unit="3" route-name="unit3-3" color="brown"></EnToolbar>

<!--    <img class="sample" src="../assets/Unit3-3/sample/202304123-3-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="title-logo" style="z-index: 2">Speech Forums</div>
      <img class="img-1" src="../assets/Unit3-3/20230412-3-3-物件-01-言論機關.png" alt="Speech Forums" title="Speech Forums">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-0.json')" alt="Speech Forums" title="Speech Forums"> </LottieTest>
      </div>

    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit3-3/en/20230711-臺EN網站-3-3-物件-02-裝飾字1-會報.png" alt="Conference News" title="Conference News">
      <div class="content-1 content-style">
        <p>During its early days, the Taiwan Cultural Association issued a publication called Conference News. Chiang Wei-shui's well-known essay, “Clinical Notes”, appeared in the first issue. Over a span of more than two years, at least four issues were released, but each faced strict administrative penalties from the authorities. After 1923, the association used <i>Taiwan Min Pao</i> as its official newspaper for sharing information, which continued until its reorganization in 1927.</p>
        <p  style="margin-top: 1.6vw">Established in April 1923, <i>Taiwan Min Pao</i> was written primarily in Modern Chinese and served as a platform for new intellectuals to express their opinions. It was known as "the only speech forum for Taiwanese." Over time, the publication evolved from a monthly format to a biweekly and eventually a weekly edition.</p>
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-03-插圖1.png" alt="Taiwan Cultural Association’s Conference News Issue 4 (Published July 1922 titled The Culture of Taiwan)" title="Taiwan Cultural Association’s Conference News Issue 4 (Published July 1922 titled The Culture of Taiwan)">
        <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2vw; top: 12%; left: 26%" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-1.json')" alt="police" title="police" ></LottieTest>
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-05-pic2.png" alt="Calendar advertisement published by Taiwan Minpao" title="Calendar advertisement published by Taiwan Minpao">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        <div class="source ">
          <p class="subtitle-style">Calendar advertisement published by <i>Taiwan Minpao</i></p>
          <p class="source-style">National Museum of Taiwan History 2006.003.0139</p>
        </div>
      </div>
      <img class="paper-1" src="../assets/Unit3-3/20230412-3-3-物件-07-插圖2.png" alt="Taiwan Minpao" title="Taiwan Minpao">
      <img class="paper-2" src="../assets/Unit3-3/20230412-3-3-物件-08-插圖3.png" alt="Taiwan Minpao" title="Taiwan Minpao">
    </div>

    <div class="part3 part-color">
      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-09-pic3.png" alt="Taiwan Min Pao Distribution Office(Located next to the Dadaocheng Da'an Hospital founded by Chiang Wei-shui)" title="Taiwan Min Pao Distribution Office(Located next to the Dadaocheng Da'an Hospital founded by Chiang Wei-shui)">
        <img class="zoom-2-btn" @click="showImg[11]=true" @keydown.enter="showImg[11]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #ffffff">Breaking Through 10,000 Copies!</p>
        <div class="subtitle-style" style="margin-top: 0.5vw">
          <p><i><i><i>Taiwan Min Pao</i></i></i> Distribution Office</p>
          <p>(Located next to the Dadaocheng Da'an Hospital founded by Chiang Wei-shui)</p>
        </div>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of the Chiang Wei-shui Cultural Foundation</p>
        <p class="content-style" style="margin-top: 0.5vw;">This photo, taken in January 1925, captures stacks of <i>Taiwan Min Pao</i> ready to be distributed.  In August of the same year, the newspaper produced a special commemorative issue to celebrate its 5th anniversary and reaching a circulation of 10,000 copies. At the time, <i>Taiwan Min Pao</i> was the third largest publication in Taiwan, and this image highlights the impressive scene of newspapers being sent out.</p>
      </div>
    </div>

    <div class="part4">
      <img class="half-top" src="../assets/Unit3-3/20230412-3-3-物件-10-插圖4.png" alt="picture" title="picture">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-2.json')" alt="picture" title="picture"></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit3-3/20230412-3-3-物件-11-pic4.png" alt="Photo captured of the Taiwan Cultural Association Lecture Team in front of the Hsinchu Taiwan Min Pao distribution center on June 7, 1925" title="Photo captured of the Taiwan Cultural Association Lecture Team in front of the Hsinchu Taiwan Min Pao distribution center on June 7, 1925">
      <img class="zoom-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; top: 13%; right: 7%" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
      <div class="section-1 part-color">
        <img class="logo-text" src="../assets/Unit3-3/en/20230711-臺EN網站-3-3-物件-13-裝飾字2-大眾時報.png" alt="Taiwan Popular Times" title="Taiwan Popular Times">
        <div class="content-1 content-style" style="margin-top: -4vw">After leaning left, the Taiwan Cultural Association's activities were less frequently seen in mainstream media, and its official newspaper often faced strict scrutiny. In March 1928, the Popular Times Agency was established in Tokyo as the headquarters for the publication of <i>Taiwan Popular Times</i>, and in 1931, <i>New Taiwan Popular Times</i> was published as the official magazine. The publications reported on strike news and promoted class-consciousness, becoming increasingly radical in content and circulating underground in Taiwan. The disputes among platforms were indicative of the diverging directions in social movements at that time.</div>
        <div class="img-1">
          <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-14-pic4.png" alt="Taiwan Popular Times" title="Taiwan Popular Times">
          <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 23%; right: 8%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
        <div class="content-2">
          <p class="title-style-s" style="color: #267c68">Inaugural Issue Desecrated!</p>
          <p class="subtitle-style" style="margin-top: 0.5vw"><i>Taiwan Popular Times</i></p>
          <p class="source-style" style="margin-top: 0.3vw">Republished by SMC Publishing</p>
          <p class="content-style" style="margin-top: 0.5vw;">The first issue of <i>Taiwan Popular Times</i> was published in Tokyo, but upon its arrival in Taiwan, it was seized and subjected to police censorship, with "inappropriate text" being removed. This event underscores the strict measures taken by the Governor-General's Office against similar publications of the time.</p>
        </div>

        <transition name="img-frame-fade"><img v-if="part4ImgIndex>0" class="arrow-btn" @click="part4ArrowBtn(-1)" @keydown.enter="part4ArrowBtn(-1)" tabindex="0" style="width:2.5vw; top: 24%; left: 58.5%" src="../assets/common/left-arrow.png"></transition>
        <transition  name="img-frame-fade">
          <div class="img-2" v-if="part4Img[0]==true">
          <img class="h-full" src="../assets/Unit3-3/20230412-3-3-物件-15-pic5.png" alt="Taiwan Popular Times" title="Taiwan Popular Times">
          <img class="zoom-2-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 2.2vw; bottom: 9%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
        </transition>
        <transition  name="img-frame-fade">
        <div class="img-3" v-if="part4Img[1]==true">
          <img class="h-full" src="../assets/Unit3-3/20230412-3-3-物件-16-pic6.png" alt="Taiwan Popular Times" title="Taiwan Popular Times">
          <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.2vw; bottom: 9%; right: 10%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
        </transition>
        <transition  name="img-frame-fade">
        <div class="img-4" v-if="part4Img[2]==true">
          <img class="h-full" src="../assets/Unit3-3/20230412-3-3-物件-17-pic７.png" alt="Taiwan Popular Times" title="Taiwan Popular Times">
          <img class="zoom-2-btn" @click="showImg[6]=true" @keydown.enter="showImg[6]=true" tabindex="0" style="width: 2.2vw; bottom: 9%; right: 10%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>
        </transition>
        <transition  name="img-frame-fade">
          <div class="img-4" v-if="part4Img[3]==true">
            <img class="h-full" src="../assets/Unit3-3/20230412-3-3-物件-18-pic8.png" alt="Taiwan Popular Times" title="Taiwan Popular Times">
            <img class="zoom-2-btn" @click="showImg[7]=true" @keydown.enter="showImg[7]=true" tabindex="0" style="width: 2.2vw; bottom: 9%; right: 9%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
          </div>
        </transition>
        <div class="content-3">
          <p class="title-style-s" style="color: #267c68">Underground Circulation</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">Covers of <i>Taiwan Popular Times</i> and <i>New Taiwan Popular Times</i></p>
          <p class="source-style" style="margin-top: 0.3vw">Republished by SMC Publishing</p>
          <p class="content-style" style="margin-top: 0.5vw;">Both the covers and contents of <i>Taiwan Popular Times</i> and <i>New Taiwan Popular Times</i> editions published after 1928 by the Taiwan Cultural Association had a distinct leftist character.</p>
        </div>
        <transition name="img-frame-fade"><img v-if="part4ImgIndex<3" class="arrow-btn" @click="part4ArrowBtn(1)" @keydown.enter="part4ArrowBtn(1)" tabindex="0" style="width:2.5vw; top: 24%; left: 88.5%" src="../assets/common/right-arrow.png"></transition>
      </div>
    </div>

    <div class="part5 part-color">
      <div class="ver-text-1" style="top: 20%; left: 13.6%">
        <div>思</div>
        <div>想</div>
        <div>的</div>
        <div>自</div>
        <div>由</div>
      </div>
      <div class="ver-text-1" style="top: 28.6%; left: 23.3%">
        <div><span v-if="gameStep!=2">限</span><span v-if="gameStep==2">&ensp;</span></div>
        <div><span v-if="gameStep!=2">制</span><span v-if="gameStep==2">&ensp;</span></div>
        <div>我</div>
        <div>們</div>
        <div>大</div>
        <div>眾</div>
        <div>的</div>
      </div>
      <div class="ver-text-2" style="top: 10.3%; left: 30%">
        <div>就</div>
        <div>是</div>
      </div>
      <div class="ver-text-2" style="top: 17.3%; left: 67%">
        <div>出</div>
        <div>版</div>
        <div>法</div>
        <div>外</div>
      </div>
      <div class="ver-text-1" style="top: 21.6%; left: 76.7%">
        <div>新</div>
        <div>聞</div>
        <div>紙</div>
        <div>法</div>
      </div>
      <div class="ver-text-2" style="top: 15%; left: 86.5%">
        <div>關</div>
        <div>於</div>
        <div>臺</div>
        <div>灣</div>
        <div><span v-if="gameStep!=2">的</span><span v-if="gameStep==2">&ensp;</span></div>
        <div>法</div>
      </div>

      <transition name="img-frame-fade" >
        <div class="circle" v-if="gameStep==0">
          <div>
            <p>Publication Censorship</p>
          </div>
          <div>
            <p>enforcement were extremely strict, to bypass censorship, editors from the Taiwan Cultural Association devised a writing style that interspersed "xx" and "oo" throughout the text.</p>
          </div>
          <div class="play-btn" @click="gameStep=1" @keydown.enter="gameStep=1" tabindex="0" alt="PLAY" title="PLAY">PLAY</div>
        </div>
      </transition>

      <div class="content-1">
        <div class="ver-text-3" style="top: 0.4%; left: 54.8%">
          <div>但</div>
          <div>這</div>
          <div>種</div>
          <div>的</div>
        </div>
        <transition name="img-frame-fade">
        <div class="input-box" style="top: 33.8%; left: 54.8%" v-if="gameStep==1">
          <input type="text"  maxlength="1">
          <input type="text"  maxlength="1" style=" top: -0.5vw;">
        </div>
        </transition>
        <div class="ver-text-3" style="top: 47.8%; left: 54.8%">
          <div>、</div>
          <div>不</div>
          <div>只</div>
          <div>是</div>
          <div>關</div>
          <div>於</div>
        </div>
        <div class="ver-text-3" style="top: 0.4%; left: 20.8%">
          <div>經</div>
          <div>營</div>
          <div>者</div>
          <div>自</div>
          <div>己</div>
          <div>的</div>
          <div>問</div>
          <div>題</div>
        </div>
      </div>

      <div class="content-2">
        <div class="ver-text-3" style="top: 0%; left: 32.8%">
          <div>言</div>
          <div>論</div>
          <div>出</div>
          <div>版</div>
          <div>的</div>
        </div>
        <transition name="img-frame-fade">
        <div class="input-box" style="top: 40%; left: 32.8%" v-if="gameStep==1">
          <input type="text"  maxlength="1">
          <input type="text"  maxlength="1" style=" top: -0.5vw;">
          <input type="text"  maxlength="1" style=" top: -1vw;">
          <input type="text"  maxlength="1" style=" top: -1.5vw;">
        </div>
        </transition>
        <div class="ver-text-3" style="top: 71%; left: 32.8%">
          <div>的</div>
          <div>抗</div>
          <div>議</div>
        </div>
      </div>



      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-3.json')" alt="picture" title="picture"></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit3-3/20230412-3-3-物件-44-插圖8.png" alt="picture" title="picture">
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-4.json')" alt="picture" title="picture"></LottieTest>
      </div>

<!--      <img class="sample" src="../assets/Unit3-3/sample/202304123-3-06.png">-->

      <transition name="img-frame-fade"><div v-if="gameStep==1" class="blue-btn" style="top:89.5%; left: 35.5%; padding: 0.1vw 0.6vw 0.1vw 0.6vw" @click="gameStep=2" @keydown.enter="gameStep=2" tabindex="0" alt="Finish" title="Finish">Finish</div></transition>

      <transition name="img-frame-fade">
        <div v-if="gameStep==2">
          <div  class="blue-btn" style="top:89.5%; left: 29%; padding: 0.1vw 0.6vw 0.1vw 0.6vw" @click="showImg[10]=true" @keydown.enter="showImg[10]=true" tabindex="0" alt="Read the censored newspaper" title="Read the censored newspaper"><p>Read the</p><p style="margin-top: -0.5vw;">censored newspaper</p></div>

          <img class="absolute" style="width:2.5vw; top: 33.5%; left: 24%;" src="../assets/Unit3-3/cross.png" alt="X" title="X">
          <img class="absolute" style="width:2.5vw; top: 42.5%; left: 24%;" src="../assets/Unit3-3/cross.png" alt="X" title="X">

          <img class="absolute" style="width:2.5vw; top: 48.5%; left: 46.5%;" src="../assets/Unit3-3/cross.png" alt="X" title="X">
          <img class="absolute" style="width:2.5vw; top: 54.5%; left: 46.5%;" src="../assets/Unit3-3/cross.png" alt="X" title="X">


          <img class="absolute" style="width:3vw; top: 42.5%; left: 57.2%;" src="../assets/Unit3-3/20230412-3-3-物件-45-ooxx.png" alt="OOXX" title="OOXX">

          <img class="absolute" style="width:2.5vw; top: 41.5%; left: 86.8%;" src="../assets/Unit3-3/cross.png" alt="X" title="X">
        </div>
      </transition>

    </div>

    <div class="part6 part-color">
      <img class="logo-text" src="../assets/Unit3-3/en/20230711-臺EN網站-3-3-物件-28-裝飾字3-臺灣新民報.png" alt="Taiwan New Min Pao" title="Taiwan New Min Pao">
      <div class="content-1 content-style" style="margin-top: -5vw">
        In 1927, Taiwan Min Pao became independent from the Taiwan Cultural Association, and in July of the same year, its distribution office moved back to Taiwan. Circulation expanded, and in 1930, the publication was renamed Taiwan New Min Pao. In 1932, it gradually transitioned from a magazine to a daily newspaper, continuing to criticize and satirize the Governor-General's policies. The newspaper became a representative media outlet for local political and cultural movements. Meanwhile, left-leaning members of the Taiwan Cultural Association also expressed criticism and launched boycott campaigns.
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #ffffff"></p>
        <p class="subtitle-style" style="margin-top: 0.5vw"><i>Taiwan New Min Pao</i> Commemorative Medal</p>
        <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan History 2004.001.0241</p>
        <p class="content-style" style="margin-top: 0.5vw;">This medal showcases an eagle engraving on the front, accompanied by the character for "award" above. The reverse side features the character for "people" at the top and "<i>Taiwan New Min Pao</i>" inscribed at the bottom. From its 306th issue on March 29, 1930, <i>Taiwan Min Pao</i> was rebranded as <i>Taiwan New Min Pao</i> and began publishing daily editions on April 15, 1932.</p>
      </div>

      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-29-pic10.png" alt="Taiwan New Min Pao Commemorative Medal" title="Taiwan New Min Pao Commemorative Medal">
        <img class="zoom-2-btn" @click="showImg[8]=true" @keydown.enter="showImg[8]=true" tabindex="0"  style="width: 2.2vw; bottom: 7%; right: -1%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>

      <div class="img-2">
        <img class="w-full" src="../assets/Unit3-3/20230412-3-3-物件-30-pic11.png" alt="Taiwan New Min Pao Commemorative Medal" title="Taiwan New Min Pao Commemorative Medal">
        <img class="zoom-2-btn" @click="showImg[9]=true" @keydown.enter="showImg[9]=true" tabindex="0"   style="width: 2.2vw; bottom: 7%; right: -5%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-3/3-3-5.json')" alt="picture" title="picture"></LottieTest>
      </div>

      <img class="img-3" src="../assets/Unit3-3/20230412-3-3-物件-32-插畫4.png" alt="Mock Election" title="Mock Election">
      <img class="more-btn btn-hover" @click="moreBtn" @keydown.enter="moreBtn" tabindex="0" src="../assets/Unit3-3/en/20230711-臺EN網站-3-3-物件-33-模擬選舉.png" alt="Mock Election" title="Mock Election">

      <div class="back-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
        <p style="margin-left: 0.5vw">Back</p>
      </div>
    </div>

    <!--img0--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-20.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:18.7vw; margin:4.6vw 0 0 12.5vw" src="../assets/Unit3-3/20230705-3-3-物件-04-pic1.png" alt="Taiwan Cultural Association’s Conference News Issue 4 (Published July 1922 titled The Culture of Taiwan)" title="Taiwan Cultural Association’s Conference News Issue 4 (Published July 1922 titled The Culture of Taiwan)">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 55vw; ">
            <div class="bold-title" style=" width: 30vw; white-space: normal">Taiwan Cultural Association’s <i>Conference News</i> Issue 4 (Published July 1922 titled <i>The Culture of Taiwan</i>)</div>
            <div class="noto-text" >Courtesy of the Chiang Wei-shui Cultural Foundation</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-10.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:18.2vw; margin:2.71vw 0 0 22.8vw" src="../assets/Unit3-3/20230705-3-3-物件-05-pic2-放大文物.png" alt="Calendar advertisement published by Taiwan Minpao" title="Calendar advertisement published by Taiwan Minpao">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Calendar advertisement published by <i>Taiwan Minpao</i></div>
              <div class="noto-text" >National Museum of Taiwan History 2006.003.0139</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-11.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34.1vw; margin:3.7vw 0 0 4vw;" src="../assets/Unit3-3/20230705-3-3-物件-11-pic4-放大文物.png" alt="Photo captured of the Taiwan Cultural Association Lecture Team in front of the Hsinchu Taiwan Min Pao distribution center on June 7, 1925" title="Photo captured of the Taiwan Cultural Association Lecture Team in front of the Hsinchu Taiwan Min Pao distribution center on June 7, 1925">
          </div>

          <div class="content-text-2">
            <div >
              <div class="bold-title">Photo captured of the Taiwan Cultural Association Lecture Team in front of the Hsinchu <i>Taiwan Min Pao</i> distribution center on June 7, 1925</div>
              <div class="noto-text" >Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</div>
              <div class="sub-title" style="text-align: left">Includes Lin Hsien-tang (seated 1), Tsai Hui-ju (seated 2), Yang Zhao-jia (seated 3), and Cai Shi-gu (seated 4).</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-12.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 24.2vw; margin:4.71vw 0 0 6vw" src="../assets/Unit3-3/20230705-3-3-物件-14-pic4-放大文物.png" alt="Taiwan Popular Times" title="Taiwan Popular Times">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Popular Times</i></div>
              <div class="noto-text" >Republished by SMC Publishing</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--                <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-13.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 27.4vw; margin:2.5vw 0 0 17vw" src="../assets/Unit3-3/20230705-3-3-物件-15-pic5-放大文物.png" alt="Taiwan Popular Times" title="Taiwan Popular Times">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Popular Times</i></div>
              <div class="noto-text" >Republished by SMC Publishing</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
<!--          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-14.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 26.4vw; margin:2.5vw 0 0 23.7vw" src="../assets/Unit3-3/20230705-3-3-物件-16-pic6-放大文物.png" alt="Taiwan Popular Times" title="Taiwan Popular Times">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Popular Times</i></div>
              <div class="noto-text">Republished by SMC Publishing</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-15.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 26.4vw; margin:2.5vw 0 0 22vw" src="../assets/Unit3-3/20230705-3-3-物件-17-pic７-放大文物.png" alt="New Taiwan Popular Times" title="New Taiwan Popular Times">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>New Taiwan Popular Times</i></div>
              <div class="noto-text" >Republished by SMC Publishing</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img7--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[7]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 26.4vw; margin:2.5vw 0 0 22.7vw" src="../assets/Unit3-3/20230705-3-3-物件-18-pic8-放大文物.png" alt="New Taiwan Popular Times" title="New Taiwan Popular Times">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>New Taiwan Popular Times</i></div>
              <div class="noto-text" >Republished by SMC Publishing</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[7]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img8--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[8]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 28.4vw; margin:1.5vw 0 0 20vw" src="../assets/Unit3-3/20230705-3-3-物件-29-pic10-放大文物.png" alt="Taiwan New Min Pao Commemorative Medal" title="Taiwan New Min Pao Commemorative Medal">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan New Min Pao</i> Commemorative Medal</div>
              <div class="noto-text" >National Museum of Taiwan History 2004.001.0241</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[8]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img9--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[9]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 28.4vw; margin:1.5vw 0 0 20vw" src="../assets/Unit3-3/20230705-3-3-物件-31-pic11-放大文物.png" alt="Taiwan New Min Pao Commemorative Medal" title="Taiwan New Min Pao Commemorative Medal">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan New Min Pao</i> Commemorative Medal</div>
              <div class="noto-text">National Museum of Taiwan History 2004.001.0241</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[9]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img10--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[10]">
<!--        <img style="position: fixed; width: 100%; top: 0; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/20230703-對位-3-3-op-07.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:28.7vw; margin:3.5vw 0 0 3.5vw" src="../assets/Unit3-3/20230705-3-3-物件-27-pic9.png" alt="New Taiwan Popular Times Inaugural Issue, 1931" title="New Taiwan Popular Times Inaugural Issue, 1931">
          </div>

          <div class="content-text" style="position: absolute; top: 4.5vw; left: 50vw; width: 32vw">
            <div class="bold-title"><i>New Taiwan Popular Times</i> Inaugural Issue, 1931</div>
            <div class="noto-text" >From <i>New Taiwan Popular Times</i></div>
            <div class="sub-title" style="text-align: left;">During the Japanese era, publication rules and enforcement were extremely strict. Most political commentary magazines of that time were printed in Japan and reviewed before being sent to Taiwan, where they underwent another review. Any content critical of the government would be banned. Owing to Tsai Pei-huo's efforts, <i>Taiwan Min Pao</i> received permission to be printed in Taiwan in 1927. However, printing of Taiwan Cultural Association's new official newspaper, <i>Taiwan Popular Times</i>, remained in Japan.</div>
            <div class="sub-title" style="text-align: left;">To bypass censorship and ensure their publications could be offered for sale, editors from the Taiwan Cultural Association devised a writing style that interspersed "xx" and "oo" throughout the text.</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[10]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img11--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[11]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 28.4vw; margin:1.5vw 0 0 14vw" src="../assets/Unit3-3/20230412-3-3-物件-09-pic3-放大文物.png" alt="Taiwan Min Pao Distribution Office(Located next to the Dadaocheng Da'an Hospital founded by Chiang Wei-shui)" title="Taiwan Min Pao Distribution Office(Located next to the Dadaocheng Da'an Hospital founded by Chiang Wei-shui)">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Min Pao</i> Distribution Office(Located next to the Dadaocheng Da'an Hospital founded by Chiang Wei-shui)
              </div>
              <div class="noto-text" >Courtesy of the Chiang Wei-shui Cultural Foundation</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[11]=false">
          </div>
        </div>
      </div>
    </transition>



    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn.vue";
import {onMounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import LottieTest from "@/components/LottieTest";
import myMixin from "@/assets/common/js/mixin";

export default {
  name: "Unit3-3",
  mixins: [myMixin],
  components:{
    EnToolbar,
    EnIntroBtn,
    FooterFeature,
    LottieTest
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 12 }, () => false));
    const part4Img = reactive(Array.from({ length: 3 }, () => false));
    const part4ImgIndex = ref(0);
    const gameStep = ref(0);

    onMounted(()=>{
      part4Img[0] = true;
    });

    function routeBtn(path){
      router.push(path);
    }

    function part4ArrowBtn(dir){
      if(dir==1){
        part4ImgIndex.value = Math.min(part4ImgIndex.value+1, 3);
      }
      else{
        part4ImgIndex.value = Math.max(part4ImgIndex.value-1, 0);
      }
      for (let i=0; i<part4Img.length; i++){
        part4Img[i] = false;
      }
      part4Img[part4ImgIndex.value] = true;
    }

    function moreBtn(){
      router.push('/en/unit3-3/more');
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      part4Img,
      part4ArrowBtn,
      part4ImgIndex,
      showImg,
      moreBtn,
      gameStep,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit3-3.css"></style>
