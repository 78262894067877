<template>
  <div class="wrap" @keydown.esc="escEvent">
<!--    <img class="sample" src="../assets/Unit3-3/sample/20230710-對位-3-3-op-21.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="text-content">
      <p class="title-style" style="color: #4e241f">Mock Election</p>
      <p class="content-style" style="margin-top: 1vw">In November 1930, Taiwan New Min Pao organized a mock election. Voting slips were printed in the newspaper, and subscribers had to cut them out, fill them in, and send them back to the newspaper office. The voting period lasted two months, with approximately 191,000 ballots received. On January 17, 1931, the results were announced, with over 400 simulated provincial and city councilors elected.</p>
      <p class="content-style" style="margin-top: 1vw">During this time, the divided factions of the Taiwanese People's Party and the Local Autonomy Alliance actively participated in the election campaign. The newspaper frequently reported on leading candidates and vote counts in various districts, showcasing the excitement of the time. However, the Taiwan Cultural Association disagreed with this approach, seeing it as a publicity stunt by the newspaper and capitalists.</p>
    </div>

    <div class="img-content" >
      <img class="w-full" src="../assets/Unit3-3/20230710--3-3-物件-36-插圖５.png" @load="imgLoad">


      <div class="title-style subtitle" style=" top: 0.7%; left: 5.4%;">Phase 1 Launch</div>
      <div class="title-style subtitle" style=" top: 18.2%; left: 51.5%;">Phase 2 Cut out and complete the ballot</div>
      <div class="title-style subtitle" style=" top: 37.7%; left: 5.4%;">Phase 3 Predictions</div>
      <div class="title-style subtitle" style=" top: 58.2%; left: 51.5%;">Phase 4 Results</div>



      <img class="img-1" src="../assets/Unit3-3/20230710--3-3-物件-37-插圖6.png" alt="Phase 1 Launch" title="Phase 1 Launch">
      <div class="json-1" :class="imgClass[0]">
        <LottieTest :json="require('@/assets/Unit3-3/20230710-3-3-6.json')" alt="Phase 1 Launch" title="Phase 1 Launch" ></LottieTest>
      </div>
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit3-3/20230710-3-3-7.json')" alt="Phase 2 Cut out and complete the ballot" title="Phase 2 Cut out and complete the ballot"></LottieTest>
      </div>
      <div class="json-3" :ref="imgRef[1]">
        <LottieTest :json="require('@/assets/Unit3-3/20230720-3-3-8.json')" alt="Phase 3 Predictions" title="Phase 3 Predictions" ></LottieTest>
      </div>
      <div class="json-4" :ref="imgRef[2]">
        <LottieTest :json="require('@/assets/Unit3-3/20230720-3-3-9.json')" alt="Phase 4 Results" title="Phase 4 Results"></LottieTest>
      </div>

      <div class="stage-2-text-1">Mail back to the newspaper</div>
      <div class="stage-2-text-2">Cast into the prepared ballot boxes</div>

      <div :class="img2Div"><img class="img-2" src="../assets/Unit3-3/20230710--3-3-物件-38-插圖7.png" alt="Phase 2 Cut out and complete the ballot" title="Phase 2 Cut out and complete the ballot" :class="imgClass[1]"></div>
      <img class="img-3" src="../assets/Unit3-3/20230710--3-3-物件-38-插圖7.png" alt="Phase 2 Cut out and complete the ballot" title="Phase 2 Cut out and complete the ballot" :class="imgClass[2]">
      <img class="img-5" src="../assets/Unit3-3/43-剪報紙動態/20230412-3-3-物件動態-43-剪報紙動態-op.gif" :ref="imgRef[0]" alt="Phase 2 Cut out and complete the ballot" title="Phase 2 Cut out and complete the ballot">

      <img class="img-4" src="../assets/Unit3-3/20230710--3-3-物件-39-pic12.png" alt="Leaflet Criticizing the Mock Election" title="Leaflet Criticizing the Mock Election">
      <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2vw; bottom: 5%; right: 54%" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      <div class="content-1">
        <p class="subtitle-style" style="margin-top: 0.5vw">Leaflet Criticizing the Mock Election</p>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of Zhuang Ming-zheng</p>
        <p class="content-style" style="margin-top: 0.5vw;">A leaflet issued by Taiwan Xingwenzhai targets the <i>Taiwan New Min Pao</i>, criticizing it as the mouthpiece of the Taiwanese People's Party and Taiwan Local Autonomy Alliance. The leaflet argues that the mock election serves solely to boost the newspaper's profits and is a ploy by the affluent and powerful to manipulate public opinion.</p>
      </div>

      <div class="back-btn" @click="routerBtn('/en/unit3-3')" @keydown.enter="routerBtn('/en/unit3-3')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="Back to Speech Forums" title="Back to Speech Forums">
        <p style="margin-left: 0.5vw">Back to Speech Forums</p>
      </div>
    </div>
    <!--img12--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-3/sample/202304123-3-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="height: 28.4vw; margin:1.5vw 0 0 10.5vw" src="../assets/Unit3-3/20230720-3-3-物件-67-pic11.png" alt="Leaflet Criticizing the Mock Election" title="Leaflet Criticizing the Mock Election">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Leaflet Criticizing the Mock Election</div>
              <div class="noto-text" >Courtesy of Zhuang Ming-zheng</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <img class="window-close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="routerBtn('/en/unit3-3')" @keydown.enter="routerBtn('/en/unit3-3')" tabindex="0">
  </div>
</template>

<script>
import router from "@/router";
import LottieTest from "@/components/LottieTest";
import {onMounted, reactive, ref} from "vue";
import myMixin from "@/assets/common/js/mixin";

export default {
  name: "Unit3-3-more",
  mixins: [myMixin],
  components:{
    LottieTest
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 12 }, () => false));
    const pageLoaded = ref(false);
    const imgRef = reactive(Array.from({ length: 3 }, () => ref(null)));
    const imgClass = reactive(Array.from({ length: 3 }, () => ''));
    const img2Div = ref('');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if(entry.target == imgRef[0].value){
              imgClass[0] = 'json-1-move';
          }
          else if(entry.target == imgRef[1].value && imgClass[1]==''){
            imgClass[1] = 'img-2-move';
          }
          else if(entry.target == imgRef[2].value){
            img2Div.value = 'fade-out';
            imgClass[2] = 'img-3-move';
          }
        }
      });
    }, {
      root: null, // 觀察器根節點，null 表示整個瀏覽器視窗
      threshold: 0.5 // 設定觸發 callback 的交叉比例，0 表示完全不交叉，1 表示完全交叉
    });


    function routerBtn(path){
      router.push(path);
    }

    function imgLoad(){
      pageLoaded.value = true;
      observer.observe(imgRef[0].value);
      observer.observe(imgRef[1].value);
      observer.observe(imgRef[2].value);
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routerBtn,
      imgRef,
      imgClass,
      imgLoad,
      img2Div,
      showImg,
      pageLoaded,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit3-3-more.css"></style>
