<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="1" unit="2" route-name="unit2-3" color="orange"></toolbar>

<!--    <img class="sample" src="../assets/Unit2-3/sample2.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="pic-1" src="../assets/Unit2-3/20230407-物件-2-3-01-首圖1.png">
      <div class="people">
        <LottieTest :json="require('@/assets/Unit2-3/2-3-0.json')" alt="左派與右派" title="左派與右派" ></LottieTest>
      </div>

      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; top: 33.5%; left: 67.5%" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">

      <div class="title-logo">左派與右派</div>
    </div>

    <div class="part2 part-color">
      <div class="content">
        <img style="margin-left: 2.3vw" src="../assets/Unit2-3/20230407-物件-2-3-04-裝飾字1-改組.png" alt="改組" title="改組">
        <p tabindex="0" class="content-style" style="margin-top: 1.4vw">1927年1月文協改組，崛起的左派青年取得主導權，提出「走向實際運動」的口號，文協改組為「中央委員制」，由連溫卿、王敏川等集體領導，總部設於臺中。</p>
        <p class="content-style">1928年3月至7月間發行《大眾時報》，訴求作為「農工、小商人及小資產階級後盾的戰鬥團體」，全力抨擊總督獨裁、反對土地政策、發展工會組織，也有無政府主義等多元的主張。</p>
        <p class="content-style">文協分裂後，左派青年更清楚提出批評帝國主義、批判資本家的口號，批評民眾黨是在民眾、總督府間想要兩面討好，批評地方自治同盟會是地主資產階級。</p>
      </div>

      <div class="people">
        <LottieTest :json="require('@/assets/Unit2-3/2-3-1.json')" alt="連溫卿、王敏川" title="連溫卿、王敏川" ></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
<!--      <img class="absolute top-0" src="../assets/Unit2-3/sample3.png">-->
      <img class="pic-1" src="../assets/Unit2-3/20230407-物件-2-3-05-插圖1.png" alt="未寄出的回郵" title="未寄出的回郵">

      <div class="content">
        <p class="title-style" style="color: #ea5518">未寄出的回郵</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺灣文化協會臨時常任委員會寄林獻堂通知1927年4月8日</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 2018.011.0014.0005-7</p>
        <p class="content-style" style="margin-top: 0.5vw;">臺灣文化協會「臨時常任委員會」寫給「臨時中央委員」林獻堂的信件，是關於文協活動寫真（電影播放）器材的移交問題。信中提及1927年2月臨時中央委員會決議「引繼」（移交）受到蔡培火反對，附回郵詢問委員意見。林獻堂並未將回郵寄出而留在手中，對文協事務已見灰心。</p>
      </div>

      <div class="letter-div" :class="envelopeStyle" @mouseenter="letterUp" @mouseleave="letterDown" @click="()=>{envelopeStyle ='letter-div-open'; envelopeOpen();}" @keydown.enter="()=>{envelopeStyle ='letter-div-open'; envelopeOpen();}" tabindex="0">
        <img  class="letter" :src="letterSrc" :class="letterFade==true?'letter-fade':''">
        <img  v-if="envelopeStyle!=''"  class="letter-open"  :src="letterOpenSrc" :class="letterFade==true?'letter-fade':''">
        <img class="envelope"   src="../assets/Unit2-3/envelope.png" alt="臺灣文化協會臨時常任委員會寄林獻堂通知1927年4月8日" title="臺灣文化協會臨時常任委員會寄林獻堂通知1927年4月8日">

        <transition name="img-frame-fade">
          <div class="point" v-if="envelopeStyle==''" alt="點選信件閱讀" title="點選信件閱讀">
            <div></div>
            <div>點選信件閱讀</div>
          </div>
        </transition>
      </div>

      <div class="letter-open-div" v-if="letterFade">
        <div style="position:relative; height: 13.5vw" >
          <img class="h-full" src="../assets/Unit2-3/20230407-物件-2-3-07-pic3.png" alt="臺灣文化協會臨時常任委員會寄林獻堂通知1927年4月8日" title="臺灣文化協會臨時常任委員會寄林獻堂通知1927年4月8日">
          <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 5%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
        <div style="position:relative; height: 11vw; margin-left: 1vw;" >
          <img class="h-full"  src="../assets/Unit2-3/20230407-物件-2-3-08-pic4.png" alt="臺灣文化協會臨時常任委員會寄林獻堂通知1927年4月8日" title="臺灣文化協會臨時常任委員會寄林獻堂通知1927年4月8日">
          <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0"  style="width: 2.2vw; bottom: 5%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
          <div style="position:relative; height: 15vw; margin-left: 2vw;" >
            <img class="h-full"  src="../assets/Unit2-3/20230407-物件-2-3-10-pic6.png" alt="臺灣文化協會臨時常任委員會寄林獻堂通知1927年4月8日" title="臺灣文化協會臨時常任委員會寄林獻堂通知1927年4月8日">
            <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0"  style="width: 2.2vw; bottom: 5%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
          </div>
      </div>
    </div>

    <div class="part4 part-color">
<!--      <img class="absolute top-0 opacity-30 z-10 w-full" src="../assets/Unit2-3/sample7.png">-->

      <transition name="img-frame-fade">
        <div class="arrow-btn" style="left: 3%;" v-if="curPart4Page>0">
          <p>{{part4PageName[0]}}</p>
          <img class="w-full arrow-left" style="margin-top: 0.3vw" src="../assets/Unit2-3/20230703-物件-2-3-50-左鍵-白.png" alt="上一頁" title="上一頁" @click="nextArrowBtn(-1)" @keydown.enter="nextArrowBtn(-1)" tabindex="0">
        </div>
      </transition>

      <transition name="test" >
        <div class="content content-1" v-if="part4Content[0]">
          <div class="top-content">
            <img style="width: 14.2vw" src="@/assets/Unit2-3/20230407-物件-2-3-13-插圖2.png" alt="臺灣農民組合、臺灣同志會、彰化有志者、改組後文協" title="臺灣農民組合、臺灣同志會、彰化有志者、改組後文協">
            <img class="left-line" :src="leftLineSrc">
            <img class="left-bottom-line" :src="leftBottomLineSrc">
            <div class="wire-div">
              <img style="width: 15.4vw"  src="../assets/Unit2-3/20230407-物件-2-3-14-虛線圓.png">
              <img class="absolute" style="width: 10vw" src="../assets/Unit2-3/20230407-物件-2-3-19-pic7.png" alt="反對矢內原忠雄演講傳單" title="反對矢內原忠雄演講傳單">
            </div>
            <img class="right-line" :src="rightLineSrc">
            <img style="width:26.5vw; margin-left: -0.5vw" src="../assets/Unit2-3/20230407-物件-2-3-15-插圖3.png" alt="臺灣民眾黨、臺灣地方自治聯盟" title="臺灣民眾黨、臺灣地方自治聯盟">
          </div>
          <div class="down-content">
              <div class="pic-1">
                <img class="w-full"  src="../assets/Unit2-3/20230407-物件-2-3-19-pic7.png" alt="反對矢內原忠雄演講傳單" title="反對矢內原忠雄演講傳單">
                <img class="zoom-2-btn zoom-fade" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 2.2vw; bottom: 7%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              </div>
            <div class="pic-2">
              <img class="w-full" src="../assets/Unit2-3/20230407-物件-2-3-20-pic8.png" alt="反對矢內原忠雄演講傳單" title="反對矢內原忠雄演講傳單">
              <img class="zoom-2-btn zoom-fade" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.2vw; bottom: 7%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
            </div>

            <div class="content-text">
              <p class="title-style" style="color: #ea5518">衝突引爆！</p>
              <p class="subtitle-style" style="margin-top: 0.5vw">反對矢內原忠雄演講傳單</p>
              <p class="source-style" style="margin-top: 0.3vw">中央研究院臺灣史研究所檔案館 提供</p>
              <p class="content-style" style="margin-top: 0.5vw;">1927年8月，蔡培火等邀請知名社會經濟學者矢內原忠雄來臺演講，遭到左傾的文協成員反對。號稱「臺灣同志會」與「彰化有志者」的青年發傳單鬧場。傳單中指控矢內原是資本主義、殖民政策的學者，批評他「沒有誠意為臺灣人本位謀幸福」。</p>
              <img class="content-btn" @click="explainOpen=!explainOpen" @keydown.enter="explainOpen=!explainOpen" tabindex="0" src="../assets/Unit2-3/20230407-物件-2-3-16-失內按鈕-藍.png" alt="矢內原忠雄是誰?(開啟/收合說明)" title="矢內原忠雄是誰?(開啟/收合說明)">
              <div class="explain" :class="explainOpen==true?'explain-open':''">
                <p class="content-style">矢內原忠雄是知名社會經濟學者，他主張「被壓迫者獲得解放，沈淪者得以上升，進而以獨立自主者來和平結合。」這樣的思想雖抱持對殖民地的同情，但對當時臺灣的左派青年而言，卻因立場不同而大肆批判。</p>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="test" >
        <div class="content content-2" v-if="part4Content[1]">
        <div class="top-content">
          <img style="width: 14.2vw" src="@/assets/Unit2-3/20230407-物件-2-3-13-插圖2.png" alt="臺灣農民組合、臺灣同志會、彰化有志者、改組後文協" title="臺灣農民組合、臺灣同志會、彰化有志者、改組後文協">
          <img class="left-line" :src="leftLine2Src">
          <img class="left-bottom-line" :src="leftBottomLine2Src">
          <div class="wire-div">
            <img style="width: 15.4vw" src="../assets/Unit2-3/20230407-物件-2-3-14-虛線圓.png">
            <img class="absolute" style="width: 8.5vw" src="../assets/Unit2-3/20230407-物件-2-3-21-pic9.png" alt="彰化街有志者警告傳單" title="彰化街有志者警告傳單">
          </div>
          <img class="right-line" :src="rightLine2Src">
          <img style="width:26.5vw; margin-left: -0.5vw" src="../assets/Unit2-3/20230407-物件-2-3-15-插圖3.png" alt="臺灣民眾黨、臺灣地方自治聯盟" title="臺灣民眾黨、臺灣地方自治聯盟">
        </div>
        <div class="down-content">
          <div class="pic-1">
            <img class="w-full" src="../assets/Unit2-3/20230407-物件-2-3-21-pic9.png" alt="彰化街有志者警告傳單" title="彰化街有志者警告傳單">
            <img class="zoom-2-btn zoom-fade" @click="showImg[6]=true" @keydown.enter="showImg[6]=true" tabindex="0" style="width: 2.2vw; bottom: 7%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
          </div>

          <div class="content-text">
            <p class="title-style" style="color: #ea5518">右派的反擊！</p>
            <p class="subtitle-style" style="margin-top: 0.5vw">彰化街有志者警告傳單</p>
            <p class="source-style" style="margin-top: 0.3vw">中研院臺史所檔案館 提供</p>
            <p class="content-style" style="margin-top: 0.5vw;">批評文化協會的傳單，內容為「文協是甚麼正体？狂狗主義、亂來主義、陷害主義的不純團體。」</p>
          </div>
        </div>
      </div>
      </transition>

      <transition name="test" >
        <div class="content content-3" v-if="part4Content[2]">
          <div class="top-content">
            <img style="width: 14.2vw" src="@/assets/Unit2-3/20230407-物件-2-3-13-插圖2.png" alt="臺灣農民組合、臺灣同志會、彰化有志者、改組後文協" title="臺灣農民組合、臺灣同志會、彰化有志者、改組後文協">
            <img class="left-line" :src="leftLineSrc">
            <img class="left-top-line" :src="leftTopLine3Src">
            <div class="wire-div">
              <img style="width: 15.4vw" src="../assets/Unit2-3/20230407-物件-2-3-14-虛線圓.png">
              <img class="absolute" style="width: 10vw" src="../assets/Unit2-3/20230407-物件-2-3-22-pic10.png" alt="臺灣文化協會與臺灣農民組合公告反對臺灣地方自治聯盟聲明書" title="臺灣文化協會與臺灣農民組合公告反對臺灣地方自治聯盟聲明書">
            </div>
            <img class="right-line" :src="rightLineSrc">
            <img style="width:26.5vw; margin-left: -0.5vw" src="../assets/Unit2-3/20230407-物件-2-3-15-插圖3.png" alt="臺灣民眾黨、臺灣地方自治聯盟" title="臺灣民眾黨、臺灣地方自治聯盟">
          </div>
          <div class="down-content">
            <div class="pic-1">
              <img class="w-full" src="../assets/Unit2-3/20230407-物件-2-3-22-pic10.png" alt="臺灣文化協會與臺灣農民組合公告反對臺灣地方自治聯盟聲明書" title="臺灣文化協會與臺灣農民組合公告反對臺灣地方自治聯盟聲明書">
              <img class="zoom-2-btn zoom-fade"  @click="showImg[7]=true" @keydown.enter="showImg[7]=true" tabindex="0" style="width: 2.2vw; bottom: 7%; right: 5%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
            </div>

            <div class="content-text">
              <p class="title-style" style="color: #ea5518">我反對你的反對</p>
              <p class="subtitle-style" style="margin-top: 0.5vw">臺灣文化協會與臺灣農民組合公告反對臺灣地方自治聯盟聲明書</p>
              <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2020.006.0199</p>
              <p class="content-style" style="margin-top: 0.5vw;">此份傳單為文協與農民組合聯合聲明，反對當時剛成立的臺灣地方自治聯盟，批評他們是「掩耳盜鈴式的地方自治」。文中提到雖然臺灣民眾黨也反對地方自治聯盟，但這只是資本家的內鬨，文協及農組極力反對兩個組織。</p>
            </div>
          </div>
        </div>
      </transition>

      <transition name="test" >
        <div class="content content-4" v-if="part4Content[3]">
        <div class="top-content">
          <img style="width: 14.2vw" src="@/assets/Unit2-3/20230407-物件-2-3-13-插圖2.png" alt="臺灣農民組合、臺灣同志會、彰化有志者、改組後文協" title="臺灣農民組合、臺灣同志會、彰化有志者、改組後文協">
          <img class="left-line" :src="leftLineSrc">
          <div class="wire-div">
            <img style="width: 15.4vw" src="../assets/Unit2-3/20230407-物件-2-3-14-虛線圓.png">
            <img class="absolute" style="width: 9.7vw" src="../assets/Unit2-3/24-pic12-翻頁效果/24-pic12-翻頁效果-00.png" alt="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單" title="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單">
          </div>
          <img class="right-line" :src="rightLineSrc">
          <img style="width:26.5vw; margin-left: -0.5vw" src="../assets/Unit2-3/20230407-物件-2-3-15-插圖3.png" alt="臺灣民眾黨、臺灣地方自治聯盟" title="臺灣民眾黨、臺灣地方自治聯盟">
        </div>
        <div class="down-content">
<!--          <div class="pic-1">-->
<!--            <img class="w-full relative" :style="book1Style" :src="book1">-->
<!--            <img class="w-full absolute top-0" :style="book2Style"  :src="book2">-->
<!--            <img class="w-full absolute top-0" :style="book3Style"  :src="book3">-->
<!--            <img class="zoom-2-btn zoom-fade " @click="showImg[bookPage+8] = true" style="width: 2.2vw; bottom: 5%; right: 3%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
<!--          </div>-->
          <transition name="img-frame-fade"><img v-if="bookPage>1" class="left-arrow"  @click="bookLeftBtn" @keydown.enter="bookLeftBtn" tabindex="0" src="../assets/common/left-arrow.png" alt="上一頁" title="上一頁"></transition>

          <div class="turnClass">
            <img class="zoom-2-btn zoom-fade " @click="showImg[bookPage+7] = true" @keydown.enter="showImg[bookPage+7] = true" tabindex="0" style="width: 2.2vw; bottom: 5%; right: 3%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
            <div id="flipbook">
              <img class="page page-1 " :src="require('@/assets/Unit2-3/24-pic12-翻頁效果/24-pic12-翻頁效果-00.png')" alt="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單" title="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單">
              <img class="page page-2 " :src="require('@/assets/Unit2-3/23-pic11-翻頁效果/23-pic11-翻頁效果-00.png')" alt="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單" title="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單">
              <img class="page page-3 " :src="require('@/assets/Unit2-3/20230407-物件-2-3-26-pic13.png')" alt="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單" title="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單">
            </div>
          </div>

          <transition name="img-frame-fade"><img v-if="bookPage<3" class="right-arrow" @click="bookRightBtn" @keydown.enter="bookRightBtn" tabindex="0"  src="../assets/common/right-arrow.png" alt="下一頁" title="下一頁"></transition>

          <div class="content-text">
            <p class="title-style" style="color: #ea5518">昔日同志的反對</p>
            <p class="subtitle-style" style="margin-top: 0.5vw">臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單</p>
            <p class="source-style" style="margin-top: 0.3vw">六然居資料室 提供</p>
            <p class="content-style" style="margin-top: 0.5vw;">此份文件內容為文協反對臺灣民眾黨的傳單，不過卻寫在臺灣民眾黨的專用稿紙上。為何如此不得而知，可能是民眾黨內部依舊在意文協的批評而留下的抄錄文件。</p>
          </div>
        </div>
      </div>
      </transition>

      <!--      <transition name="img-frame-fade"><div v-if="part4Content[3]==false" @click="nextArrowBtn" class="next-arrow"></div></transition>-->

      <transition name="img-frame-fade">
      <div class="arrow-btn" style="right: 3%;" v-if="curPart4Page<3">
        <p>{{part4PageName[1]}}</p>
        <img class="w-full arrow-right" style="margin-top: 0.3vw" src="../assets/Unit2-3/20230703-物件-2-3-49-右鍵-白.png" alt="下一頁" title="下一頁" @click="nextArrowBtn(1)" @keydown.enter="nextArrowBtn(1)" tabindex="0">
      </div>
      </transition>
    </div>

    <div class="part5 part-color">
      <img class="pic-1" src="../assets/Unit2-3/20230407-物件-2-3-32-插圖4.png" alt="解散" title="解散">
      <div class="content">
        <img style="width: 15vw" src="../assets/Unit2-3/20230407-物件-2-3-29-裝飾字2-解散.png" alt="解散" title="解散">
        <p tabindex="0" class="content-style">歷經新竹演講會的警民衝突（1927.11）、臺南反對墓地拆遷的逮捕（1928.5）、農組大逮捕（1929.2），文協重要成員陸續被捕、斷續遭到關押。1929年11月之後，親近共產主義革命派的幹部活躍，獲得文協主導權，文協的政治行動益行激進，遭到總督府更嚴厲的檢舉，在1931年底逐漸解散。</p>
      </div>
    </div>

    <div class="part6 part-color">
      <div class="unit-btn" style="top: 23%; left: 12%; " @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/unit2-0')" tabindex="0">
        <img class="w-full"  src="../assets/Unit2-0/文協向前衝按鈕.png" alt="文協向前衝" title="文協向前衝">
        <p>文協向前衝</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 38.5%; ">
        <img class="w-full"  src="../assets/Unit2-0/臺灣人的臺灣按鈕.png" alt="臺灣人的臺灣" title="臺灣人的臺灣" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0">
        <p>臺灣人的</p>
        <p style="top:47%">臺灣</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 65%; ">
        <img class="w-full"  src="../assets/Unit2-0/為農工大眾主張按鈕.png" alt="為農工大眾主張" title="為農工大眾主張" @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" tabindex="0">
        <p>為農工大眾</p>
        <p style="top:47%">主張</p>
      </div>
      <div class="unit-btn" style="top: 57%; left: 25%; ">
        <img class="w-full" src="../assets/Unit2-0/20230724-2-3-物件-36-左派與右派按鈕-淡.png" alt="左派與右派" title="左派與右派" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0">
        <p>左派與右派</p>
      </div>
      <div class="unit-btn" style="top: 57%; left: 51.5%; ">
        <img class="w-full" src="../assets/Unit2-0/政治結社的走向按鈕.png" alt="政治結社的走向" title="政治結社的走向" @click="routeBtn('/unit2-4')" @keydown.enter="routeBtn('/unit2-4')" tabindex="0">
        <p>政治結社的</p>
        <p style="top:47%">走向</p>
      </div>
    </div>




    <!--img0--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample8.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34.5vw; margin:3.5vw 0 0 3.2vw" src="../assets/Unit2-3/20230703-物件-2-3-03-pic1.png" alt="打倒臺灣地方自治聯盟傳單1930年" title="打倒臺灣地方自治聯盟傳單1930年">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 40vw; width: 18.3vw;">
            <div class="bold-title">打倒臺灣地方自治聯盟傳單1930年</div>
            <div class="noto-text" style="margin-top: 0vw">六然居資料室 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample9.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:21.5vw; margin:2.5vw 0 0 21vw" src="../assets/Unit2-3/20230703-物件-2-3-07-pic3-放大文物.png" alt="臺灣文化協會臨時常任委員會寄林獻堂通知 1927年4月8日" title="臺灣文化協會臨時常任委員會寄林獻堂通知 1927年4月8日">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 17.6vw; width: 28.6vw;">
            <div class="bold-title">臺灣文化協會臨時常任委員會寄林獻堂通知 1927年4月8日</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2018.011.0014.0005</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample10.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.3vw; margin:2.5vw 0 0 23.5vw" src="../assets/Unit2-3/20230703-物件-2-3-09-pic5-放大文物.png" alt="臺灣文化協會臨時常任委員會寄林獻堂通知 1927年4月8日" title="臺灣文化協會臨時常任委員會寄林獻堂通知 1927年4月8日">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 17.6vw; width: 28.6vw;">
            <div class="bold-title">臺灣文化協會臨時常任委員會寄林獻堂通知 1927年4月8日</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2018.011.0014.0006</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample11.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35vw; margin:2vw 0 0 14.5vw" src="../assets/Unit2-3/20230703-物件-2-3-11-pic6-放大文物.png" alt="臺灣文化協會臨時常任委員會寄林獻堂通知 1927年4月8日" title="臺灣文化協會臨時常任委員會寄林獻堂通知 1927年4月8日">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 17.6vw; width: 28.6vw;">
            <div class="bold-title">臺灣文化協會臨時常任委員會寄林獻堂通知 1927年4月8日</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2018.011.0014.0007</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample12.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.5vw; margin:2vw 0 0 13vw" src="../assets/Unit2-3/20230703-物件-2-3-19-pic7-放大文物.png" alt="反對矢內原忠雄演講傳單" title="反對矢內原忠雄演講傳單">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 23.5vw; width: 15.8vw;">
            <div class="bold-title">反對矢內原忠雄演講傳單</div>
            <div class="noto-text" style="margin-top: 0.4vw">中央研究院臺灣史研究所檔案館 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
<!--                <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample13.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:28vw; margin:1.5vw 0 0 18vw" src="../assets/Unit2-3/20230703-物件-2-3-20-pic8-放大文物.png" alt="反對矢內原忠雄演講傳單" title="反對矢內原忠雄演講傳單">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 24vw; width: 15.8vw;">
            <div class="bold-title">反對矢內原忠雄演講傳單</div>
            <div class="noto-text" style="margin-top: 0.4vw">中央研究院臺灣史研究所檔案館 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
<!--          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample14.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:28vw; margin:1.5vw 0 0 18vw" src="../assets/Unit2-3/20230703-物件-2-3-21-pic9-放大文物.png" alt="彰化街有志者警告傳單" title="彰化街有志者警告傳單">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 24vw; width: 15.8vw;">
            <div class="bold-title">彰化街有志者警告傳單</div>
            <div class="noto-text" style="margin-top: 0.4vw">中央研究院臺灣史研究所檔案館 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img7--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[7]">
<!--                  <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample15.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:40.5vw; margin:2.5vw 0 0 12vw" src="../assets/Unit2-3/20230703-物件-2-3-22-pic10-放大文物.png" alt="臺灣文化協會與臺灣農民組合公告反對臺灣地方自治聯盟聲明書" title="臺灣文化協會與臺灣農民組合公告反對臺灣地方自治聯盟聲明書">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 16.5vw; width: 30.9vw;">
            <div class="bold-title">臺灣文化協會與臺灣農民組合公告反對臺灣地方自治聯盟聲明書</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2020.006.
              0199</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[7]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img8--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[8]">
<!--                          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.8vw; margin:2vw 0 0 13.5vw" src="../assets/Unit2-3/20230703-物件-2-3-23-pic11-放大文物.png" alt="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單" title="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 17.5vw; width: 28.8vw;">
            <div class="bold-title">臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單</div>
            <div class="noto-text" style="margin-top: 0.4vw">六然居資料室 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[8]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img9--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[9]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.8vw; margin:2vw 0 0 13.5vw" src="../assets/Unit2-3/20230703-物件-2-3-25-pic12-放大文物.png" alt="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單" title="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 18vw; width: 28.8vw;">
            <div class="bold-title">臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單</div>
            <div class="noto-text" style="margin-top: 0.4vw">六然居資料室 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[9]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img10--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[10]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-3/sample16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.8vw; margin:2vw 0 0 13.5vw" src="../assets/Unit2-3/20230703-物件-2-3-26-pic13-放大文物.png" alt="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單" title="臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單">
          </div>

          <div class="content-text" style="position: absolute; top:30.6vw; left: 18vw; width: 28.8vw;">
            <div class="bold-title">臺灣文化協會本部印行絕對反對臺灣民眾黨的地方自治傳單</div>
            <div class="noto-text" style="margin-top: 0.4vw">六然居資料室 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[10]=false">
          </div>
        </div>
      </div>
    </transition>

    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import LottieTest from "@/components/LottieTest";
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import $ from "jquery";
import turn from "@/assets/utils/lib/turn";
import flipAudio from "@/assets/Unit3-1/insert_paper.mp3";

export default {
  name: "Unit2-2",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const letterSrc = ref(null);
    const letterOpenSrc = ref(null);
    letterSrc.value = require('@/assets/Unit2-3/letter-move/06-信件抽拉動態-00.png');
    letterOpenSrc.value = require('@/assets/Unit2-3/letter-open/06-信件抽拉動態-2_00.png');
    const envelopeStyle = ref('');
    const letterFade = ref(false);
    const explainOpen = ref(false);

    const showImg = reactive(Array.from({ length: 11 }, () => false));
    const part4Content = reactive(Array.from({ length: 4 }, () => false));
    part4Content[0] = true;
    const curPart4Page = ref(0);
    const part4PageName  = reactive(Array.from({ length: 2 }, () => ''));

    let letterIndex = 0;
    let letterOpen = false;

    const book1 = ref(require('@/assets/Unit2-3/24-pic12-翻頁效果/24-pic12-翻頁效果-00.png'));
    const book2 = ref(require('@/assets/Unit2-3/23-pic11-翻頁效果/23-pic11-翻頁效果-00.png'));
    const book3 = ref(require('@/assets/Unit2-3/20230407-物件-2-3-26-pic13.png'));
    const book1Style = ref('z-index:3;');
    const book2Style = ref('z-index:2; opacity:0;');
    const book3Style = ref('z-index:1; opacity:0;');
    const flipAudio = require('@/assets/Unit3-1/insert_paper.mp3');
    const flipEle = new Audio(flipAudio);

    let intervalMap = new Map();

    function routeBtn(path){
      router.push(path);
    }

    function letterUp() {
      if(letterOpen == true) return;
      if(intervalMap.has('letterUp')){
        clearInterval(intervalMap['letterUp']);
      }

      letterIndex = 0;

      intervalMap['letterUp'] = setInterval(()=>{

        letterIndex = Math.min(letterIndex + 1, 14);

        letterSrc.value = require('@/assets/Unit2-3/letter-move/06-信件抽拉動態-'+letterIndex.toString().padStart(2, '0') +'.png');
        //letterSrc.value = letterMouseUp[letterIndex].src;

        if(letterIndex >= 14){
          clearInterval(intervalMap['letterUp']);
        }
        console.log(111)
      }, 30);
    }

    function letterDown() {
      if(letterOpen == true) return;

      if(intervalMap.has('letterDown')){
        clearInterval(intervalMap['letterDown']);
      }

      letterIndex = 14;

      intervalMap['letterDown'] = setInterval(()=>{

        letterIndex = Math.min(letterIndex - 1, 14);

        console.log(letterIndex)

        letterSrc.value = require('@/assets/Unit2-3/letter-move/06-信件抽拉動態-'+letterIndex.toString().padStart(2, '0') +'.png');
        //letterSrc.value = letterMouseUp[letterIndex].src;

        if(letterIndex <= 0){
          clearInterval(intervalMap['letterDown']);
        }
      }, 30);
    }

    function envelopeOpen() {
      if(letterOpen == true) return;

      if(intervalMap.has('letter')){
        clearInterval(intervalMap['letter']);
      }

      letterIndex = 1;
      letterOpen = true;

      intervalMap['letter'] = setInterval(()=>{

        letterIndex = (letterIndex + 1);

        letterOpenSrc.value = require('@/assets/Unit2-3/letter-open/06-信件抽拉動態-2_'+letterIndex.toString().padStart(2, '0') +'.png');
        //letterSrc.value = test[letterIndex].src;


        if(letterIndex >= 55){
          letterFade.value = true;
          clearInterval(intervalMap['letter']);
        }
      }, 30);
    }

    const letterMouseUp = reactive([]);

    function onTurn(){
      $("#flipbook").turn({
        acceleration:true,
        display: "single",
        //gradients: true,
        autoCenter: true,
        height:646,
        width:896,
        direction: "rtl",
      });


      $('#flipbook').turn('size', '100%', '100%');
      $("#flipbook").bind("turning", function(event, page, view) {
        bookPage.value = page;
        flipEle.play();
      });

    }

    onUnmounted(()=>{
      for (const key in intervalMap) {
          clearIntervalId(key);
        }
    });

    function clearIntervalId(name){
      if(intervalMap.has(name)){
        clearInterval(intervalMap[name]);
      }
    }

    /****Content-1 Png動畫***/
    let leftLineIndex = 0;
    const leftLineSrc = ref('');
    content1LeftLine();
    function content1LeftLine(){
        clearIntervalId('leftLine');
        leftLineIndex = 0;

        intervalMap['leftLine'] = setInterval(()=>{

          leftLineIndex = (leftLineIndex + 1) % (55+1);

          leftLineSrc.value = require('@/assets/Unit2-3/19-pic7-動態線/19-pic7-動態線-發行-左邊-'+leftLineIndex.toString().padStart(2, '0') +'.png');
        }, 50);
    }

    let leftBottomLineIndex = 0;
    const leftBottomLineSrc = ref('');
    content1LeftBottomLine();
    function content1LeftBottomLine(){
      clearIntervalId('leftBottomLine');
      leftBottomLineIndex = 0;

      intervalMap['leftBottomLine'] = setInterval(()=>{

        leftBottomLineIndex = (leftBottomLineIndex + 1) % (55+1);

        leftBottomLineSrc.value = require('@/assets/Unit2-3/19-pic7-動態線/21-pic9-動態線-發行-左下00'+leftBottomLineIndex.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    let rightLineIndex = 0;
    const rightLineSrc = ref('');
    content1RightLine();
    function content1RightLine(){
      clearIntervalId('rightLine');
      rightLineIndex = 0;

      intervalMap['rightLine'] = setInterval(()=>{

        rightLineIndex = (rightLineIndex + 1) % (55+1);

        rightLineSrc.value = require('@/assets/Unit2-3/19-pic7-動態線/19-pic7-動態線-批評-右邊-'+rightLineIndex.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    /****Content-2 Png動畫***/
    let leftLine2Index = 0;
    const leftLine2Src = ref('');
    content2LeftLine();
    function content2LeftLine(){
      clearIntervalId('leftLine2');
      leftLine2Index = 0;

      intervalMap['leftLine2'] = setInterval(()=>{

        leftLine2Index = (leftLine2Index + 1) % (55+1);

        leftLine2Src.value = require('@/assets/Unit2-3/21-pic9-動態線/21-pic9-動態線-批評-左邊 00'+leftLine2Index.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    let leftBottomLine2Index = 0;
    const leftBottomLine2Src = ref('');
    content2LeftBottomLine();
    function content2LeftBottomLine(){
      clearIntervalId('leftBottom2');
      leftBottomLineIndex = 0;

      intervalMap['leftBottom2'] = setInterval(()=>{

        leftBottomLine2Index = (leftBottomLine2Index + 1) % (55+1);

        leftBottomLine2Src.value = require('@/assets/Unit2-3/21-pic9-動態線/21-pic9-動態線-批評-左下00'+leftBottomLine2Index.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    let rightLine2Index = 0;
    const rightLine2Src = ref('');
    content2RightLine();
    function content2RightLine(){
      clearIntervalId('rightLine2');
      rightLine2Index = 0;

      intervalMap['rightLine2'] = setInterval(()=>{

        rightLine2Index = (rightLine2Index + 1) % (55+1);

        rightLine2Src.value = require('@/assets/Unit2-3/21-pic9-動態線/21-pic9-動態線-發行-右邊00'+rightLine2Index.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    /****Content-3 Png動畫***/
    let leftTopLine3Index = 0;
    const leftTopLine3Src = ref('');
    content3LeftTopLine();
    function content3LeftTopLine(){
      clearIntervalId('leftTopLine3');
      leftTopLine3Index = 0;

      intervalMap['leftTopLine3'] = setInterval(()=>{

        leftTopLine3Index = (leftTopLine3Index + 1) % (55+1);

        leftTopLine3Src.value = require('@/assets/Unit2-3/22-pic10-動態線/22-pic10-動態線-發行-左上-'+leftTopLine3Index.toString().padStart(2, '0') +'.png');
      }, 50);
    }

    let rightText = ['右派的反擊！','我反對你的反對','昔日同志的反對',''];
    let leftText = ['','衝突引爆！','右派的反擊！','我反對你的反對'];
    part4PageName[1] = rightText[0];

    async function nextArrowBtn(dir){
      for (let i=0; i<4; i++)
        part4Content[i] = false;

      if(dir == 1){
        curPart4Page.value = Math.min(curPart4Page.value+1, 3);
      }
      else {
        curPart4Page.value = Math.max(curPart4Page.value-1, 0);
      }

      part4PageName[0] = leftText[curPart4Page.value];
      part4PageName[1] = rightText[curPart4Page.value];

      part4Content[curPart4Page.value] = true;

      await nextTick();

      if(curPart4Page.value==3 ){
        bookPage.value = 1;
        onTurn();
        window.addEventListener('resize', ()=>{
          $('#flipbook').turn('size', 'auto', '100%');
        });
      }
    }

    const bookPage = ref(1);
    function bookRightBtn(){
      $("#flipbook").turn("next");
    }

    function bookLeftBtn(){
      $("#flipbook").turn("previous");
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }


    return{
      routeBtn,
      letterSrc,
      letterUp,
      letterDown,
      envelopeStyle,
      envelopeOpen,
      letterFade,
      letterMouseUp,
      letterOpenSrc,
      explainOpen,
      leftLineSrc,
      leftBottomLineSrc,
      rightLineSrc,
      leftLine2Src,
      leftBottomLine2Src,
      rightLine2Src,
      leftTopLine3Src,
      part4Content,
      book1,
      book2,
      book3,
      book1Style,
      book2Style,
      book3Style,
      bookPage,
      bookRightBtn,
      bookLeftBtn,
      showImg,
      nextArrowBtn,
      curPart4Page,
      part4PageName,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit2-3.css"></style>


