<template>
  <div class="wrap" @keydown.esc="escEvent">
    <Entoolbar type="1" unit="3" route-name="unit4-3" color="brown"></Entoolbar>

<!--    <img class="sample" src="../assets/Unit4-3/sample/unit4-3.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="img-1" src="../assets/Unit4-3/20230417-4-3-物件-01-首圖1.png" alt="Social Forums" title="Social Forums">
      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.1vw; bottom: 10.4%; right: 7.3%;" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-3/4-3-0.json')" alt="Social Forums" title="Social Forums"></LottieTest>
      </div>
      <div class="title-logo">Social Forums</div>
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit4-3/en/20230708-EN網站-物件-4-3-04-裝飾字1-聚會場所.png" alt="Gathering Places" title="Gathering Places">
      <div class="content-1 content-style" >During the Japanese period, Taipei's Penglai Pavilion and Kang San Lau, Tainan's Songjin Lou, and Taichung's Yongle Lou were all renowned high-end Taiwanese restaurants that served as meeting places for Taiwan Cultural Association members. Important meetings were also held in theaters. In 1928, following Lin Hsien-tang's global journey, a welcome home party was held at Penglai Pavilion, as was the founding conference of the Taiwan Workers' Alliance, which highlights the significance of these gathering spots.
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-05-pic2.png" alt="Taipei Penglai Pavilion Porcelain Bowl" title="Taipei Penglai Pavilion Porcelain Bowl">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.3vw; bottom: 8%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-06-pic3.png" alt="Taipei Penglai Pavilion Round Porcelain Bowl" title="Taipei Penglai Pavilion Round Porcelain Bowl">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.3vw; bottom: 8%; right: -10%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #ffffff">Upscale Restaurants</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Taipei Penglai Pavilion Porcelain Bowl & Taipei Penglai Pavilion Round Porcelain Bowl</p>

        <p class="source-style" style="margin-top: 0.3vw;width: 36vw">Collection of the National Museum of Taiwan History 2020.006.0371、2020.006.0375</p>
        <p class="content-style" style="margin-top: 0.5vw; height: 5vw;overflow: auto">In Taipei, Penglai Pavilion was a famous high-end Taiwanese restaurant in Dadaocheng during the Japanese colonial period. Many important Taiwan Cultural Association activities in Taipei were held there.</p>
      </div>

      <div class="img-3">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-07-pic4.png" alt="Taiwan Min Pao article about plans for a cultural cafeteria in Hsinchu" title="Taiwan Min Pao article about plans for a cultural cafeteria in Hsinchu">
        <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.3vw; bottom: 10%; right: 1%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-3">
        <p class="title-style" style="color: #ffffff;width: 40vw">The importance of a gathering place</p>
        <p class="subtitle-style" style="margin-top: 0.5vw"><i>Taiwan Min Pao</i> article about plans for a cultural cafeteria in Hsinchu</p>
        <p class="content-style" style="margin-top: 0.5vw;height: 5vw;overflow: auto">Due to the lack of suitable restaurants in Hsinchu, some members of the Taiwan Cultural Association proposed building their own "cultural cafeteria." This again underlines the importance of gathering places to the association.</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-3/4-3-1.json')" alt="Gathering Places" title="Gathering Places"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-08-pic5.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-09-pic6.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="img-3">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-10-pic7.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="img-4">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-11-pic8.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="img-5">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-12-pic9.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="img-6">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-13-pic10.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="img-7">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-14-pic11.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="img-8">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-15-pic12.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="img-9">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-16-pic13.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="img-10">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-17-pic14.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="img-11">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-18-pic15.png" alt="Sponsors of the Official Publication" title="Sponsors of the Official Publication">
<!--        <img class="zoom-2-btn" style="width: 2.3vw; bottom: -3%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">-->
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #ffffff; white-space: nowrap; margin-left: -15%">Sponsors of the Official Publication</p>
        <p class="content-style" style="margin-top: 0.5vw; margin-left: -15%">From 1922 to 1927, <i>Taiwan Min Pao</i> served as the official Taiwan Cultural Association publication. By supporting the newspaper, advertisers were also sponsors of the Taiwan Cultural Association.</p>
      </div>
    </div>

    <div class="part4 part-color">
      <img class="logo-text" src="../assets/Unit4-3/en/20230708-EN網站-物件-4-3-19-裝飾字2-交流新知.png" alt="Exchanging Fresh Perspectives" title="Exchanging Fresh Perspectives">
      <div class="content content-style">
        <p>Members of the Taiwan Cultural Association stayed in touch with students overseas and lecturers who returned home in the summer vacation. Various new trends of thought emerged — especially socialism and anarchism — and those who embraced high ideals developed small yet active organizations. These study clubs, book reading clubs, and youth clubs had a great influence on the Taiwan Cultural Association.</p>
        <p style="margin-top: 1.6vw">In July 1923, the Social Issues Study Club formed by Lien Wen-ching, Hsieh Wen-ta, Chiang Wei-shui and others began to examine problems in society. The club was banned by the Governor-General’s Office of Taiwan, but not before it had exerted great influence. Activists also formed Taipei Youth Club, Taipei Youth Sports Club, and Taipei Youth Reading Club. They sometimes issued statements on behalf of Taiwan’s proletarian youth. These statements mostly commented on current politics, especially agricultural and industrial issues, and criticized capitalism.</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-3/4-3-2.json')" alt="The Social Issues Study Club" title="The Social Issues Study Club"></LottieTest>
      </div>
    </div>

    <div class="part5 part-color">
      <img class="logo-text" src="../assets/Unit4-3/en/20230708-EN網站-物件-4-3-20-裝飾字3-社會組織.png" alt="Social Organizations" title="Social Organizations">
      <div class="content-1 content-style">
        Innovative social organizations focused on different issues. The Working Youth Association gathered young people who were passionate about social movements, committed to the reduction of housing rents and the refinement of traditional rituals. The Tainan Comfort Club was a mutual aid association, a new kind of social organization. The role of multifaceted groups within the overall movement cannot be ignored.
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-21-pic16.png" alt="Tainan Condolence Department Application Form" title="Tainan Condolence Department Application Form">
        <img class="zoom-2-btn" @click="showImg[15]=true" @keydown.enter="showImg[15]=true" tabindex="0" style="width: 2.3vw; bottom: 3%; right: 8%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #267c68">Funeral Support</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Tainan Condolence Department Application Form</p>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of Zhuang Ming-zheng</p>
        <p class="content-style" style="margin-top: 0.5vw">Through connections with members of social movement organizations, one could request assistance from the Tainan Condolence Department for funeral supplies. It is believed that their main offering was the provision of supports to carry coffins.</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-3/4-3-3.json')" alt="people" title="people"></LottieTest>
      </div>
    </div>

    <div class="part6 part-color">
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-3/20230417-4-3-物件-22-pic17.png" alt="Tainan Tenants' Alliance Regulations" title="Tainan Tenants' Alliance Regulations">
        <img class="zoom-2-btn" @click="showImg[16]=true" @keydown.enter="showImg[16]=true" tabindex="0" style="width: 2.3vw; bottom: 3%; right: 1%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #267c68">Tenants, Unite! </p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Tainan Tenants' Alliance Regulations </p>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of Zhuang Ming-zheng</p>
        <p class="content-style" style="margin-top: 0.5vw">The organization's goal was to advocate for rent reduction. It is said that following its establishment, it indeed had a considerable impact, as many landlords agreed to provide a 20% discount on rent.</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-3/4-3-4.json')" alt="people" title="people"></LottieTest>
      </div>

      <div class="back-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
        <p style="margin-left: 0.5vw">Back</p>
      </div>
    </div>

    <!--寫這裡-->
    <!--img0--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-02.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:33.3vw; margin:5vw 0 0 3.7vw" src="../assets/Unit4-3/20230703-4-3-物件_02-pic1-放大文物.png" alt="A photo taken by Tseng Kuei-chiao at the rooftop garden of Taipei's Kang San Lau." title="A photo taken by Tseng Kuei-chiao at the rooftop garden of Taipei's Kang San Lau.">
          </div>

          <div class="content-text" style="position: absolute; top:5.35vw; left:55vw; width: 28.5vw;">
            <div class="bold-title">
              <p>A photo taken by Tseng Kuei-chiao</p>
              <p>at the rooftop garden of Taipei's Kang San Lau.</p>
            </div>
            <div class="noto-text" >Courtesy of Guo Shuang-fu</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-03.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:3.4vw 0 0 14.7vw" src="../assets/Unit4-3/20230703-4-3-物件-05-pic2-放大文物.png" alt="Taipei Penglai Pavilion Porcelain Bowl" title="Taipei Penglai Pavilion Porcelain Bowl">
          </div>

          <div class="content-text" >
            <div >
              <div class="bold-title">Taipei Penglai Pavilion Porcelain Bowl</div>
              <div class="noto-text" >Collection of the National Museum of Taiwan History 2020.006.0371</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[2]">
        <!-- <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-04.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:26vw; margin:2.8vw 0 0 18.7vw" src="../assets/Unit4-3/20230703-4-3-物件-06-pic3-放大文物.png" alt="Taipei Penglai Pavilion Round Porcelain Bowl" title="Taipei Penglai Pavilion Round Porcelain Bowl">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Taipei Penglai Pavilion Round Porcelain Bowl</div>
              <div class="noto-text" >Collection of the National Museum of Taiwan History 2020.006.0375</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!-- <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-05.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:58.4vw; margin:6.1vw 0 0 2.7vw" src="../assets/Unit4-3/20230703-4-3-物件-07-pic4-放大文物.png" alt="Taiwan Min Pao article about plans for a cultural cafeteria in Hsinchu" title="Taiwan Min Pao article about plans for a cultural cafeteria in Hsinchu">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Min Pao</i> article about plans for a cultural cafeteria in Hsinchu</div>
              <!--<div class="noto-text" >國立臺灣歷史博物 館藏 2020.006.0375</div>-->
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img15--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[15]">
        <!--         <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:18.4vw; margin:2.5vw 0 0 22.4vw" src="../assets/Unit4-3/20230703-4-3-物件-21-pic16-放大文物.png" alt="Tainan Condolence Department Application Form" title="Tainan Condolence Department Application Form">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Tainan Condolence Department Application Form</div>
              <div class="noto-text" >Courtesy of Zhuang Ming-zheng</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[15]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img16--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[16]">
        <!--    <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-3/sample/20230417-對位-4-3-op-18.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:58vw; margin:9vw 0 0 3vw" src="../assets/Unit4-3/20230703-4-3-物件-22-pic17-放大文物.png" alt="Tainan Tenants' Alliance Regulations" title="Tainan Tenants' Alliance Regulations">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Tainan Tenants' Alliance Regulations</div>
              <div class="noto-text" >Courtesy of Zhuang Ming-zheng</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[16]=false">
          </div>
        </div>
      </div>
    </transition>


    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn";
import {reactive, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
export default {
  name: "Unit4-3",
  mixins: [myMixin],
  components:{
    Entoolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));

    const pic = ref(1);

    function routeBtn(path){
      router.push(path);
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      pic,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit4-3.css"></style>
