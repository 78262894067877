<template>
  <div class="wrap w-full" @keydown.esc="escEvent">
    <toolbar type="2" unit="5" route-name="person-intro" color="brown" style="background: #FFAB35"></toolbar>

<!--    <img style="position: absolute; top: 0; width: 100%; opacity: 0.4; z-index: 10" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿2.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="top-bar">
      <img v-if="headPage>0" @click="leftHeadBtn" @keydown.enter="leftHeadBtn" tabindex="0" class="arrow left-arrow" src="../assets/common/left-arrow.png">

      <div class="content " v-if="headPage==0">
        <div class="person person-1" @click="changePerson(0)" @keydown.enter="changePerson(0)" tabindex="0"><img class="w-full" :class="personArr[0]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-07-連溫卿-白.png" alt="連溫卿" title="連溫卿"><p>連溫卿</p></div>
        <div class="person person-2" @click="changePerson(1)" @keydown.enter="changePerson(1)" tabindex="0"><img class="w-full" :class="personArr[1]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-08-蔡培火-白.png" alt="蔡培火" title="蔡培火"><p>蔡培火</p></div>
        <div class="person person-3" @click="changePerson(2)" @keydown.enter="changePerson(2)" tabindex="0"><img class="w-full" :class="personArr[2]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-09-謝文達-白.png" alt="謝文達" title="謝文達"><p>謝文達</p></div>
        <div class="person person-4" @click="changePerson(3)" @keydown.enter="changePerson(3)" tabindex="0"><img class="w-full" :class="personArr[3]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-10-林獻堂-白.png" alt="林獻堂" title="林獻堂"><p>林獻堂</p></div>
        <div class="person person-5" @click="changePerson(4)" @keydown.enter="changePerson(4)" tabindex="0"><img class="w-full" :class="personArr[4]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-11-謝雪紅-白.png" alt="謝雪紅" title="謝雪紅"><p>謝雪紅</p></div>
        <div class="person person-6" @click="changePerson(5)" @keydown.enter="changePerson(5)" tabindex="0"><img class="w-full" :class="personArr[5]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-12-簡娥-白.png" alt="簡娥" title="簡娥"><p>簡娥</p></div>
        <div class="person person-7" @click="changePerson(6)" @keydown.enter="changePerson(6)" tabindex="0"><img class="w-full" :class="personArr[6]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-13-陳植棋-白.png" alt="陳植棋" title="陳植棋"><p>陳植棋</p></div>
        <div class="person person-8" @click="changePerson(7)" @keydown.enter="changePerson(7)" tabindex="0"><img class="w-full" :class="personArr[7]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-14-莊松林-白.png" alt="莊松林" title="莊松林"><p>莊松林</p></div>
        <div class="person person-9" @click="changePerson(8)" @keydown.enter="changePerson(8)" tabindex="0"><img class="w-full" :class="personArr[8]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-15-盧丙丁-白.png" alt="盧丙丁" title="盧丙丁"><p>盧丙丁</p></div>
      </div>
      <div class="content" v-if="headPage==1">
        <div class="person person-10" @click="changePerson(9)" @keydown.enter="changePerson(9)" tabindex="0"><img class="w-full" :class="personArr[9]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-16-王詩琅-白.png" alt="王詩琅" title="王詩琅"><p>王詩琅</p></div>
        <div class="person person-11" @click="changePerson(10)" @keydown.enter="changePerson(10)" tabindex="0"><img class="w-full" :class="personArr[10]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-17-蔡淵騰-白.png" alt="蔡淵騰" title="蔡淵騰"><p>蔡淵騰</p></div>
        <div class="person person-12" @click="changePerson(11)" @keydown.enter="changePerson(11)" tabindex="0"><img class="w-full" :class="personArr[11]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-18-蔡惠如-白.png" alt="蔡惠如" title="蔡惠如"><p>蔡惠如</p></div>
        <div class="person person-13" @click="changePerson(12)" @keydown.enter="changePerson(12)" tabindex="0"><img class="w-full" :class="personArr[12]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-19-陳澄波-白.png" alt="陳澄波" title="陳澄波"><p>陳澄波</p></div>
        <div class="person person-14" @click="changePerson(13)" @keydown.enter="changePerson(13)" tabindex="0"><img class="w-full" :class="personArr[13]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-20-陳懷澄-白.png" alt="陳懷澄" title="陳懷澄"><p>陳懷澄</p></div>
        <div class="person person-15" @click="changePerson(14)" @keydown.enter="changePerson(14)" tabindex="0"><img class="w-full" :class="personArr[14]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-21-林幼春-白.png" alt="林幼春" title="林幼春"><p>林幼春</p></div>
        <div class="person person-16" @click="changePerson(15)" @keydown.enter="changePerson(15)" tabindex="0"><img class="w-full" :class="personArr[15]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-22-蘇璧輝-白.png" alt="蘇璧輝" title="蘇璧輝"><p>蘇璧輝</p></div>
        <div class="person person-17" @click="changePerson(16)" @keydown.enter="changePerson(16)" tabindex="0"><img class="w-full" :class="personArr[16]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-23-杜香國-白.png" alt="杜香國" title="杜香國"><p>杜香國</p></div>
        <div class="person person-18" @click="changePerson(17)" @keydown.enter="changePerson(17)" tabindex="0"><img class="w-full" :class="personArr[17]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-24-蔣渭水-白.png" alt="蔣渭水" title="蔣渭水"><p>蔣渭水</p></div>
      </div>
      <div class="content" v-if="headPage==2">
        <div class="person person-19" @click="changePerson(18)" @keydown.enter="changePerson(18)" tabindex="0"><img class="w-full" :class="personArr[18]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-25-韓石泉-白.png" alt="韓石泉" title="韓石泉"><p>韓石泉</p></div>
        <div class="person person-20" @click="changePerson(19)" @keydown.enter="changePerson(19)" tabindex="0"><img class="w-full" :class="personArr[19]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-26-李應章-白.png" alt="李應章" title="李應章"><p>李應章</p></div>
        <div class="person person-21" @click="changePerson(20)" @keydown.enter="changePerson(20)" tabindex="0"><img class="w-full" :class="personArr[20]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-27-賴和-白.png" alt="賴和" title="賴和"><p>賴和</p></div>
        <div class="person person-22" @click="changePerson(21)" @keydown.enter="changePerson(21)" tabindex="0"><img class="w-full" :class="personArr[21]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-28-林淇漳-白.png" alt="林淇漳" title="林淇漳"><p>林淇漳</p></div>
        <div class="person person-23" @click="changePerson(22)" @keydown.enter="changePerson(22)" tabindex="0"><img class="w-full" :class="personArr[22]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-29-楊肇嘉-白.png" alt="楊肇嘉" title="楊肇嘉"><p>楊肇嘉</p></div>
        <div class="person person-24" @click="changePerson(23)" @keydown.enter="changePerson(23)" tabindex="0"><img class="w-full" :class="personArr[23]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-30-簡吉-白.png" alt="簡吉" title="簡吉"><p>簡吉</p></div>
        <div class="person person-25" @click="changePerson(24)" @keydown.enter="changePerson(24)" tabindex="0"><img class="w-full" :class="personArr[24]==true?'selected':''" src="../assets/person-intro/head/20230424-入口頁面-物件-31-王敏川-白.png" alt="王敏川" title="王敏川"><p>王敏川</p></div>
      </div>

      <img v-if="headPage<2" @click="rightHeadBtn" @keydown.enter="rightHeadBtn" tabindex="0" class="arrow right-arrow" src="../assets/common/right-arrow.png" alt="下一頁" title="下一頁">
    </div>

    <!--img0--><!--ver-->
    <div class="normal-page" v-if="page=='normal'">
        <div class="person-1" v-if="personArr[0]">
          <div class="top-content">
            <div class="person">
              <div class="person-name">連溫卿</div>
              <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-53-連溫卿-pic1.png" alt="連溫卿" title="連溫卿">
              <div class="content">
                <p>這時候世界語的宣傳有一瀉千里之勢、而加速度普及於環球、可以說是那狹小的國家觀念的反動、和『民族自决』互相響應的結果。</p>
                <p>連溫卿，〈怎麼是世界語主義？〉 《臺灣民報》1926年</p>
              </div>
            </div>
          </div>

          <div class="bottom-content">
            <div class="person">
              <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-54-連溫卿-插畫1.png" alt="連溫卿" title="連溫卿">
              <div class="center-person-div">
                <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-55-連溫卿-pic2.png" alt="連溫卿" title="連溫卿">
                <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
                <p class="source">國立清華大學 提供</p>
              </div>
              <div class="content">
                <div class="row-1">
                  <p class="person-name">連溫卿</p>
                  <p class="year">1894-1957</p>
                  <div class="text-1"><div class="dot"></div>文協人物</div>
                </div>
                <div class="row-2">
                  <div class="dot"></div>
                  <div>人物簡介</div>
                  <div class="line"></div>
                </div>
                <div class="row-3">
                  <p class="content-style">公學校畢業後自習語文與社會科學理論，早期主要關心世界語運動，1923年籌組社會問題研究會、臺北青年會，是文協左傾的關鍵人物。</p>
                  <p class="content-style">在主持文協期間，以青年、工會組織最為活絡，力推全島罷工、總工會成立，並與農民組合密切合作，辦演講會與警察周旋。1929年後退出社運圈。1955年編撰日治社運始末，1988年出版為《臺灣政治運動史》。</p>
                </div>
                <div class="row-4">
                  <div>
                    <div class="dot" style="background: black"></div>
                    <div>與文協的故事</div>
                  </div>
                  <div>
                    <div class="hashtag" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0" alt="左派與右派" title="左派與右派">#左派與右派</div>
                    <div class="hashtag" @click="routeBtn('/unit3-5')" @keydown.enter="routeBtn('/unit3-5')" tabindex="0" alt="世界語" title="世界語">#世界語</div>
                  </div>
                </div>
              </div>
              <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
                <div style="margin-top: 1.1vw">變身請進入</div>
                <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
                <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              </div>
            </div>
          </div>
        </div>
        <div class="person-1" v-if="personArr[1]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">蔡培火</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-60-蔡培火-pic3.png" alt="蔡培火" title="蔡培火">
            <div class="content">
              <p>臺灣是帝國的臺灣，同時是我們臺灣人的臺灣</p>
              <p>蔡培火，〈我島と我等〉《臺灣青年》1921年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-61-蔡培火-插畫5.png" alt="蔡培火" title="蔡培火">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-62-蔡培火-pic4.png" alt="蔡培火" title="蔡培火">
              <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">國立臺灣文學館典藏 林章峯捐贈</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">蔡培火</p>
                <p class="year">1889-1983</p>
                <div class="text-1"><div class="dot"></div>文協人物</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">北港出生長居臺南，原係公學校教師，1914年加入臺灣同化會，遭解散後離職，前往日本留學。1920年擔任《臺灣青年》編輯兼發行人，發表「臺灣是臺灣人的臺灣」言論，成為當時重要倡議，後更主導發行《臺灣民報》。</p>
                <p class="content-style">1923年起任文化協會專務理事，總部也因此設於臺南，發起以電影巡迴放映、推廣新知的宣傳方式，受到各地歡迎，更著力推廣臺語白話字(教會羅馬字)。治警事件入獄期間創作〈臺灣自治歌〉，後編唱不少社運歌曲，有〈咱臺灣〉等歌曲傳世。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0" alt="臺灣人的臺灣" title="臺灣人的臺灣">#臺灣人的臺灣</div>
                  <div class="hashtag" @click="routeBtn('/unit3-2')" @keydown.enter="routeBtn('/unit3-2')" tabindex="0" alt="演劇與電影" title="演劇與電影">#演劇與電影</div>
                  <div class="hashtag" @click="routeBtn('/unit3-4')" @keydown.enter="routeBtn('/unit3-4')" tabindex="0" alt="社會歌謠" title="社會歌謠">#社會歌謠</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">變身請進入</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[2]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">謝文達</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-68-謝文達-pic5.png" alt="謝文達" title="謝文達">
            <div class="content">
              <p>我們所遭受到的各種困境，是上帝為使我們成長而給予的試煉機會，需以正面思考來積極衝刺才行。</p>
              <p>謝文達，〈飛機的和平使命及其文化所帶來的影響〉《臺灣文化協會會報》第4號  1922年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 10vw; top: 38%; left: 10%;" src="../assets/person-intro/20230424-人物介紹-物件-69-謝文達-插畫10.png" alt="謝文達" title="謝文達">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-70-謝文達-pic6.png" alt="謝文達" title="謝文達">
              <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">國家圖書館  提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">謝文達</p>
                <p class="year">1901-1983</p>
                <div class="text-1"><div class="dot"></div>文協人物</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">謝文達1919年臺中中學校畢業後，赴日本就讀伊藤飛行機研究所，以優異成績畢業，成為臺灣第一位飛行員。</p>
                <p class="content-style">1923年第二次臺灣議會設置請願運動，謝文達駕駛「臺北號」在東京上空散發上萬張空飄傳單，上書「臺灣人呻吟在暴戾政治之下久矣！」、「給臺灣人議會吧！」等文字。這件事遭到總督府嚴厲批判，逼使謝文達離臺，而後遷居中國。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit1-1')" @keydown.enter="routeBtn('/unit1-1')" tabindex="0" alt="鳥瞰世界" title="鳥瞰世界">#鳥瞰世界</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[3]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">林獻堂</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-72-林獻堂-pic7.png" alt="林獻堂" title="林獻堂">
            <div class="content">
              <p>在獨立自主中鞏固團結，此事當不可或忘，以相互扶助之精神指導我們同胞。</p>
              <p>林獻堂（在第三回夏季學校開學茶會）〈臺灣人青年應走的路〉1923年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-73-林獻堂-插畫11.png" alt="林獻堂" title="林獻堂">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-74-林獻堂-pic8.png" alt="林獻堂" title="林獻堂">
              <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">國立臺灣文學館典藏 林章峯捐贈</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">林獻堂</p>
                <p class="year">1881-1956</p>
                <div class="text-1"><div class="dot"></div>文協人物</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">霧峰林家頂厝的大家長，長年爭取臺灣人在日本統治下的平等待遇，1921年起領導長達15年的「臺灣議會設置請願運動」，臺灣文化協會成立時被公推為最高代表「總理」，是初創期的實質領袖。</p>
                <p class="content-style">林獻堂出錢出力，亦將宅邸霧峰林宅出借為也文協最大規模講習營隊「夏季學校」地點，文協理事會也曾在霧峰林宅舉辦，並長期出任《臺灣民報》社長。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/unit2-0')" tabindex="0">#文協向前衝</div>
                  <div class="hashtag" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0">#臺灣人的臺灣</div>
                  <div class="hashtag" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0">#左派與右派</div>
                  <div class="hashtag" @click="routeBtn('/unit2-4')" @keydown.enter="routeBtn('/unit2-4')" tabindex="0">#政治結社的走向</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[4]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">謝雪紅</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-76-謝雪紅-pic9.png" alt="謝雪紅" title="謝雪紅">
            <div class="content">
              <p>「謝，你在1928年回臺後，就經常說，人生應是不斷探求真理的旅行。」</p>
              <p>楊克煌，北京，1971</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-77-謝雪紅-插畫12.png" alt="謝雪紅" title="謝雪紅">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-78-謝雪紅-pic10.png" alt="謝雪紅" title="謝雪紅">
              <img class="zoom-2-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">新世語文化有限公司 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">謝雪紅</p>
                <p class="year">1901-1970</p>
                <div class="text-1"><div class="dot"></div>性別的追尋</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">貧苦孤女出身，隨夫遊歷神戶、青島，自修日文、漢文，擔任過洋服裁縫機業務，1925年赴上海，輾轉赴莫斯科學習共產主義，1928年為臺灣共產黨創始黨員之一，返臺後開設國際書局繼續革命事業，於1931年再度被捕，遭判刑13年，1940年出獄。</p>
                <p class="content-style">戰後於228事件中積極投入，後離臺，在香港成立臺灣民主自治同盟，文化大革命中遭嚴厲批鬥。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0" alt="左派與右派" title="左派與右派">#左派與右派</div>
                  <div class="hashtag" @click="routeBtn('/unit4-2')" @keydown.enter="routeBtn('/unit4-2')" tabindex="0" alt="文化基地" title="文化基地">#文化基地</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">變身請進入</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[5]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">簡娥</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-83-簡娥-pic11.png" alt="簡娥" title="簡娥">
            <div class="content">
              <p>我自今以後，決參加農民運動。已經不得以我為生徒看待。</p>
              <p>簡娥於離家後寄高女校方之明信片</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-84-簡娥-插畫16.png" alt="簡娥" title="簡娥">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-85-簡娥-pic12.png" alt="簡娥" title="簡娥">
              <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">莊永明家屬  提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">簡娥</p>
                <p class="year">1909-2004</p>
                <div class="text-1"><div class="dot"></div>性別的追尋</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">就讀高雄高女時，和同學張玉蘭參加農民組合和文協演講，受到感召而後參加讀書會。張玉蘭上臺演講遭學校退學後，簡娥主動退學，積極投入農民運動。</p>
                <p class="content-style">1929年212事件，全臺三百處的臺灣農民組合機構遭到警查突擊搜查，簡娥潛伏於桃園一帶發展組織，參加過臺灣共產黨，後被捕入獄。
                  戰後投入家庭沉潛，丈夫曾涉政治案件被捕，1980年代遷居美國。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" tabindex="0" alt="為農工大眾主張" title="為農工大眾主張">#為農工大眾主張</div>
                  <div class="hashtag" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0" alt="左派與右派" title="左派與右派">#左派與右派</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">變身請進入</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[6]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">陳植棋</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-90-陳植棋-pic13.png" alt="陳植棋" title="陳植棋">
            <div class="content">
              <p>如果生命是細而長的話，我寧願短而亮，我嚮往迸發的生命力。</p>
              <p>陳植棋</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 8.5vw; top: 50%; left: 11%;"  src="../assets/person-intro/20230424-人物介紹-物件-91-陳植棋-插畫20.png" alt="陳植棋" title="陳植棋">>
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-92-陳植棋-pic14.png" alt="陳植棋" title="陳植棋">>
              <img class="zoom-2-btn" @click="showImg[6]=true" @keydown.enter="showImg[6]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source" >中央研究院臺灣史研究所檔案館</p>
              <p class="source" style="bottom: -14%">陳子智 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">陳植棋</p>
                <p class="year">1906-1931</p>
                <div class="text-1"><div class="dot"></div>叛逆青年</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">在校時受教於石川欽一郎，展露美術才能。臺北師範學校畢業前，因率領學潮遭退學，之後考進東京美術學校，參與「文運革新會」，是倡言「破壞成為奴隸養成所的學校」、「破壞壓迫者的經濟建設」的政治組織。</p>
                <p class="content-style">1928年起作品先後兩度入選帝國美展，也在臺展中大放異彩，任臺灣本土美術家團體「赤島社」負責人。1930年赴日參加帝展時病倒，過世時僅25歲。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0" alt="左派與右派" title="左派與右派">#左派與右派</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">變身請進入</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[7]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">莊松林</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-97-莊松林-pic16.png" alt="莊松林" title="莊松林">
            <div class="content">
              <p> </p>
              <p> </p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-98-莊松林-插畫24.png" alt="莊松林" title="莊松林">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-99-莊松林-pic16.png" alt="莊松林" title="莊松林">
              <img class="zoom-2-btn" @click="showImg[7]=true" @keydown.enter="showImg[7]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">莊明正 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">莊松林</p>
                <p class="year">1910-1974</p>
                <div class="text-1"><div class="dot"></div>勞動者的剪影</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">畢業於廈門集美中學，自廈門回臺後，1929年參加「赤嵌勞働青年會」發起的「反對中元普度」運動，並參與創辦左派期刊《赤道報》，屢被查禁，亦被捕多次，兩度違反出版規則，為免於被政府放逐，赴鐵工廠當外務員。</p>
                <p class="content-style">之後埋首研究新文學、世界語，並鑽研臺灣民俗文獻，以朱鋒為筆名發表研究成果，戰後受聘為臺南市文獻委員會委員。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0" alt="左派與右派" title="左派與右派">#左派與右派</div>
                  <div class="hashtag" @click="routeBtn('/unit3-3')" @keydown.enter="routeBtn('/unit3-3')" tabindex="0" alt="言論機關" title="言論機關">#言論機關</div>
                  <div class="hashtag" @click="routeBtn('/unit3-5')" @keydown.enter="routeBtn('/unit3-5')" tabindex="0" alt="世界語" title="世界語">#世界語</div>

                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">變身請進入</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[8]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">盧丙丁</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-105-盧丙丁-pic17.png" alt="盧丙丁" title="盧丙丁">
            <div class="content">
              <p>驚是驚今仔日 頭毛烏烏相見</p>
              <p>此後去的 更再相會 敢會白髮的時</p>
              <p>咱現時是 抱著心願 各人東走西去</p>
              <p>致使咱歡喜的過日 變成乾燥無味</p>
              <p>著這世間欠了知己 親像無光的時</p>
              <p>惜別惜離 只好合唱 離別的歌詩</p>
              <p>盧丙丁，〈離別詩〉</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 11vw; top: 50%; left: 8%;" src="../assets/person-intro/20230424-人物介紹-物件-106-盧丙丁-插畫29.png" alt="盧丙丁" title="盧丙丁">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-107-盧丙丁-pic18.png" alt="盧丙丁" title="盧丙丁">
              <img class="zoom-2-btn" @click="showImg[8]=true" @keydown.enter="showImg[8]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">國立臺灣文學館典藏 林章峯捐贈</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">盧丙丁</p>
                <p class="year">1901-?</p>
                <div class="text-1"><div class="dot"></div>勞動者的剪影</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">曾任公學校教員，後為全力投入文協活動而辭去教職，深入參與講演會、臺南新劇運動，擔任電影辯士尤獲好評，人稱「丙丁仙」。</p>
                <p class="content-style">1927年臺灣民眾黨成立，盧丙丁擔任民眾黨中央常務委員，負責宣傳部，並特別投入勞工運動，屢遭日警拘捕。1932年之後消失音訊，可能遭警方迫害並強制送入樂生院，後遭遣送廈門。他的妻子林氏好也積極參與社會運動，後為知名歌唱家。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit3-1')" @keydown.enter="routeBtn('/unit3-1')" tabindex="0" alt="文化講演會" title="文化講演會">#文化講演會</div>
                  <div class="hashtag" @click="routeBtn('/unit3-2')" @keydown.enter="routeBtn('/unit3-2')" tabindex="0" alt="演劇與電影" title="演劇與電影">#演劇與電影</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[9]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">王詩琅</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-109-王詩琅-pic19.png" alt="王詩琅" title="王詩琅">
            <div class="content">
              <p></p>
              <p></p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 7vw; top: 50%; left: 13%;" src="../assets/person-intro/20230424-人物介紹-物件-110-王詩琅-插畫30.png" alt="王詩琅" title="王詩琅">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-111-王詩琅-pic20.png" alt="王詩琅" title="王詩琅">
              <img class="zoom-2-btn" @click="showImg[9]=true" @keydown.enter="showImg[9]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">莊永明家屬 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">王詩琅</p>
                <p class="year">1908-1984</p>
                <div class="text-1"><div class="dot"></div>勞動者的剪影</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">公學校畢業，具漢文學養，憑自學廣涉知識，接觸俄國文學，而有「無政府主義」思想，1926年參加無政府主義團體「臺灣黑色青年聯盟」，因組織被認定為非法，前後被捕入獄三次，監禁1年6個月，又涉入「臺灣勞動互助社」被判刑10個月。</p>
                <p class="content-style">他主要以文藝作品參與運動，作品有批判寫實主義風格，藉由小説社運分子、罷工工人、銀行小職員、妓女老婊頭等人物，以自身的經驗和時代結合，反映當時的社會現象，以及當時社會運動受到壓迫的挫敗感。戰後擔任臺北市、臺灣省文獻委員，編撰通誌，蒐集日治社會運動資料，是文協一手史料得以留世的關鍵人物。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" tabindex="0" alt="為農工大眾主張" title="為農工大眾主張">#為農工大眾主張</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[10]">
          <div class="top-content">
            <div class="person">
              <div class="person-name">蔡淵騰</div>
              <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-113-蔡淵騰-pic22.png" alt="蔡淵騰" title="蔡淵騰">
              <div class="content">
                <p></p>
                <p></p>
              </div>
            </div>
          </div>

          <div class="bottom-content">
            <div class="person">
              <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-114-蔡淵騰-插畫31.png" alt="蔡淵騰" title="蔡淵騰">
              <div class="center-person-div">
                <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-115-蔡淵騰-pic22.png" alt="蔡淵騰" title="蔡淵騰">
                <img class="zoom-2-btn" @click="showImg[10]=true" @keydown.enter="showImg[10]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
                <p class="source">蔡詩傑 提供</p>
              </div>
              <div class="content">
                <div class="row-1">
                  <p class="person-name">蔡淵騰</p>
                  <p class="year">1896-1971</p>
                  <div class="text-1"><div class="dot"></div>勞動者的剪影</div>
                </div>
                <div class="row-2">
                  <div class="dot"></div>
                  <div>人物簡介</div>
                  <div class="line"></div>
                </div>
                <div class="row-3">
                  <p class="content-style">1896年生二林人，家境富有，曾有家產40多甲地被日本資本家「源成農場」賤價收購。1925年擔任蔗農組合理事，代表蔗農向會社爭取權益，二林事件後遭到逮捕， 獲判無罪出獄，受約半年的牢獄之災，留下「自思此禍本難逃，為唱民權坐黑牢」詩句。</p>
                  <p class="content-style">蔡淵騰牢獄之災後仍熱心公益，擔任保正、社會教會委員等地方職務。戰後爭取源成農場中被強制收購的祖產，未能獲准，又遭遇土地改革打擊，曾任首屆二林鎮民代表。</p>
                </div>
                <div class="row-4">
                  <div>
                    <div class="dot" style="background: black"></div>
                    <div>與文協的故事</div>
                  </div>
                  <div>
                    <div class="hashtag" @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" tabindex="0" alt="為農工大眾主張" title="為農工大眾主張">#為農工大眾主張</div>
                  </div>
                </div>
              </div>
              <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
                <div style="margin-top: 1.1vw">變身請進入</div>
                <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
                <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              </div>
            </div>
          </div>
        </div>
        <div class="person-1" v-if="personArr[11]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">蔡惠如</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-120-蔡惠如-pic23.png" alt="蔡惠如" title="蔡惠如">
            <div class="content">
              <p>吾同胞當自覺居何地位，而當有所努力，不論政治經濟其他種種方面，視有當改革者當不憚改革之。</p>
              <p>蔡惠如，〈新竹盛開文化講演會〉 《臺灣民報》1925年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-121-蔡惠如-插畫35.png" alt="蔡惠如" title="蔡惠如">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-122-蔡惠如-pic24.png" alt="蔡惠如" title="蔡惠如">
              <img class="zoom-2-btn" @click="showImg[11]=true" @keydown.enter="showImg[11]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">中央研究院臺灣史研究所檔案館 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">蔡惠如</p>
                <p class="year">1881-1929</p>
                <div class="text-1"><div class="dot"></div>鄉紳儒士</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">蔡惠如是清水巨商，1914年參與過林獻堂成立的同化會，1918年與霧峰林家的林獻堂、林幼春成立臺灣文社，發行以古典漢詩為主的《臺灣文藝叢誌》，為開創性的漢文雜誌。</p>
                <p class="content-style">蔡惠如鼓吹旅日留學生成立啟發會、應聲會，1920年成立新民會。他也是《臺灣青年》雜誌創辦的重要金主。治警事件時遭判刑3個月，有大批民眾送行，獄中詩詞作品傳世。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0" alt="臺灣人的臺灣" title="臺灣人的臺灣">#臺灣人的臺灣</div>
                  <div class="hashtag" @click="routeBtn('/unit3-3')" @keydown.enter="routeBtn('/unit3-3')" tabindex="0" alt="言論機關" title="言論機關">#言論機關</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[12]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">陳澄波</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-124-陳澄波-pic25.png" alt="陳澄波" title="陳澄波">
            <div class="content">
              <p>作為一個藝術家，必須擴大生活領域，更需寬容關懷事物。</p>
              <p>陳澄波，〈藝術觀〉1921年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 16.8vw; top: 50%; left: 2%;" src="../assets/person-intro/20230424-人物介紹-物件-125-陳澄波-插畫36.png" alt="陳澄波" title="陳澄波">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-126-陳澄波-pic26.png" alt="陳澄波" title="陳澄波">
              <img class="zoom-2-btn" @click="showImg[12]=true" @keydown.enter="showImg[12]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">陳澄波文化基金會 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">陳澄波</p>
                <p class="year">1895-1947</p>
                <div class="text-1"><div class="dot"></div>鄉紳儒士</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">在校時受教於石川欽一郎，後任公學校教師，近三十歲時考入東京美術學校，1927年以〈嘉義街外〉入選帝國美展，一舉成名。在校期間串連臺灣美術人才，關心議會請願運動，發表臺灣各地寫生作品。畢業後赴上海任教，1933年返臺參加「臺陽美術協會」。戰後積極參與社會事務，當選嘉義市參議員，228事件任「和平使」協助調停，卻遭逮捕、槍決。</p>
                <p class="content-style"></p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0" alt="臺灣人的臺灣" title="臺灣人的臺灣">#臺灣人的臺灣</div>
                  <div class="hashtag" @click="routeBtn('/unit4-1')" @keydown.enter="routeBtn('/unit4-1')" tabindex="0" alt="時鐘塔" title="時鐘塔">#時鐘塔</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">變身請進入</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[13]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">陳懷澄</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-131-陳懷澄-pic27.png" alt="陳懷澄" title="陳懷澄">
            <div class="content">
              <p>灌園（林獻堂）在京寄示議會請願書</p>
              <p>《陳懷澄先生日記》中紀錄林獻堂送出議會請願書，1921年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 7.3vw; top: 51.3%; left: 12.3%;" src="../assets/person-intro/20230424-人物介紹-物件-132-陳懷澄-插畫40.png" alt="陳懷澄" title="陳懷澄">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-133-陳懷澄-pic28.png" alt="陳懷澄" title="陳懷澄">
              <img class="zoom-2-btn" @click="showImg[13]=true" @keydown.enter="showImg[13]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">李昭容 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">陳懷澄</p>
                <p class="year">1877-1940</p>
                <div class="text-1"><div class="dot"></div>鄉紳儒士</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">陳懷澄家族在鹿港具名望，為地方領導階層，與日人關係也頗為友好，成為地方制度改正後的首任鹿港街長。任內對地方建設付出極大心力，建樹頗多。</p>
                <p class="content-style">雖為官方任命街長，無法直接參與文協活動，陳懷澄仍持續關心文協發展。文協幾度在鹿港文廟舉行文化演講，彰化郡役所警察知情後，均向陳懷澄施壓，後仍在他的支持下順利舉辦，可說是文協在鹿港活動的最大後盾。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit3-1')" @keydown.enter="routeBtn('/unit3-1')" tabindex="0" alt="文化講演會" title="文化講演會">#文化講演會</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[14]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">林幼春</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-135-林幼春-pic29.png" alt="林幼春" title="林幼春">
            <div class="content">
              <p>繫久懸知景物非，強揩病眼弔斜暉。九旬化碧將為厲，舉國招魂未忍飛。歷劫尚當甘墜落，幾生修得到芳菲？因風寄謝枝頭鳥，極口催歸何處歸？</p>
              <p>林幼春，〈獄中感春賦落花詩以自遣〉1925年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 12.5vw; top: 51.3%; left: 6%;" src="../assets/person-intro/20230424-人物介紹-物件-136-林幼春-插畫41.png" alt="林幼春" title="林幼春">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-137-林幼春-pic30.png" alt="林幼春" title="林幼春">
              <img class="zoom-2-btn" @click="showImg[14]=true" @keydown.enter="showImg[14]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">國立臺灣文學館典藏 林章峯捐贈</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">林幼春</p>
                <p class="year">1880-1939</p>
                <div class="text-1"><div class="dot"></div>鄉紳儒士</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">林幼春出身霧峰林家，漢詩文長才，與蔡惠如等櫟社同仁在1918年創立臺灣文社。</p>
                <p class="content-style">1921年參加臺灣文化協會，擔任協理，後在臺灣議會期成同盟會相當活躍，治警事件遭判刑3個月，留下闡述近代民主思潮的近體詩。出獄後，林幼春辭退官職，1927年曾任臺灣民眾黨顧問，而後隱居霧峰，先後贊助過張深切、楊逵創辦的新文藝期刊。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0" alt="臺灣人的臺灣" title="臺灣人的臺灣">#臺灣人的臺灣</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[15]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">蘇璧輝</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-139-蘇璧輝-pic31.png" alt="蘇璧輝" title="蘇璧輝">
            <div class="content">
              <p>TEMPO ESTAS MONO</p>
              <p>時間厲行</p>
              <p>蘇璧輝、蘇齊熙、蘇哲修、蘇璧琮，時鐘塔上石碑文</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style=" top: 51.3%; left: 10%;" src="../assets/person-intro/20230424-人物介紹-物件-140-蘇璧輝-插畫42.png" alt="蘇璧輝" title="蘇璧輝">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-141-蘇璧輝-pic32.png" alt="蘇璧輝" title="蘇璧輝">
              <img class="zoom-2-btn" @click="showImg[15]=true" @keydown.enter="showImg[15]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">鄭慧美 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">蘇璧輝</p>
                <p class="year">1884-1937</p>
                <div class="text-1"><div class="dot"></div>鄉紳儒士</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">1884年生，艋舺人，以農產品國際貿易起家，是臺灣世界語主要推動者之一，1919年與連溫卿等人組織「臺灣世界語學會」，曾任《綠蔭》雜誌編輯、發行人，設立「世界語商業仲介所」。有時以筆名「S生」，發表與世界語推廣有關文章。</p>
                <p class="content-style">1921年蘇璧輝參與文化協會發起，擔任理事，也參與議會設置運動甚深，1923年治警事件曾被捕羈押。1927年捐獻龍山寺時鐘塔。1934年蘇璧輝遷居中國廈門，1937年中日開戰，被懷疑是日本間諜，與長子同遭中國政府軍隊槍決。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit3-5')" @keydown.enter="routeBtn('/unit3-5')" tabindex="0" alt="世界語" title="世界語">#世界語</div>
                  <div class="hashtag" @click="routeBtn('/unit4-1')" @keydown.enter="routeBtn('/unit4-1')" tabindex="0" alt="時鐘塔" title="時鐘塔">#時鐘塔</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">變身請進入</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[16]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">杜香國</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-146-杜香國-pic33.png" alt="杜香國" title="杜香國">
            <div class="content">
              <p></p>
              <p></p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-147-杜香國-插畫46.png" alt="杜香國" title="杜香國">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-148-杜香國-pic34.png" alt="杜香國" title="杜香國">
              <img class="zoom-2-btn" @click="showImg[16]=true" @keydown.enter="showImg[16]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source" style="bottom: -14%">國立臺灣歷史博物館 館藏 2020.032.0252 </p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">杜香國</p>
                <p class="year">1894-1946</p>
                <div class="text-1"><div class="dot"></div>鄉紳儒士</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">杜香國出身德望家族，經營帽蓆、證券、紙業，與其父杜清熱心投入文協、民眾黨地方組織。從杜香國、杜清父子與文協成員們的信件往來，可以窺見當時文協活動的狀況，是研究文協的重要資料。</p>
                <p class="content-style"></p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0" alt="臺灣人的臺灣" title="臺灣人的臺灣">#臺灣人的臺灣</div>
                  <div class="hashtag" @click="routeBtn('/unit4-3')" @keydown.enter="routeBtn('/unit4-3')" tabindex="0" alt="交誼機關" title="交誼機關">#交誼機關</div>
                  <div class="hashtag" @click="routeBtn('/unit4-4')" @keydown.enter="routeBtn('/unit4-4')" tabindex="0" alt="風俗改良" title="風俗改良">#風俗改良</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[17]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">蔣渭水</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-150-蔣渭水-pic35.png" alt="蔣渭水" title="蔣渭水">
            <div class="content">
              <p>同胞須團結，團結真有力！</p>
              <p>蔣渭水，1927年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" src="../assets/person-intro/20230424-人物介紹-物件-151-蔣渭水-插畫47.png" alt="蔣渭水" title="蔣渭水">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-152-蔣渭水-pic36.png" alt="蔣渭水" title="蔣渭水">
              <img class="zoom-2-btn" @click="showImg[17]=true" @keydown.enter="showImg[17]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source"> 蔣渭水文化基金會 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">蔣渭水</p>
                <p class="year">1891-1931</p>
                <div class="text-1"><div class="dot"></div>醫者與牧者</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">就讀醫校時期即顯露領導長才，畢業後在大稻埕開設大安醫院，1921年主導文化協會成立，也是臺灣民眾黨、工友總聯盟核心人物。大安醫院同時也是《臺灣民報》發行所，另開設文化書局，推廣先進思潮。其思想活潑新穎，具濃厚漢民族認同，也富有社會主義精神。</p>
                <p class="content-style">蔣渭水在1931年因病逝世，同志們決議舉辦大眾葬，據聞有五千餘人趕來送行。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/unit2-0')" tabindex="0" alt="文協向前衝" title="文協向前衝">#文協向前衝</div>
                  <div class="hashtag" @click="routeBtn('/unit2-4')" @keydown.enter="routeBtn('/unit2-4')" tabindex="0" alt="政治結社的走向" title="政治結社的走向">#政治結社的走向</div>
                  <div class="hashtag" @click="routeBtn('/unit3-4')" @keydown.enter="routeBtn('/unit3-4')" tabindex="0" alt="社會歌謠" title="社會歌謠">#社會歌謠</div>
                  <div class="hashtag" @click="routeBtn('/unit4-4')" @keydown.enter="routeBtn('/unit4-4')" tabindex="0" alt="風俗改良" title="風俗改良">#風俗改良</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">變身請進入</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[18]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">韓石泉</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-159-韓石泉-pic37.png" alt="韓石泉" title="韓石泉">
            <div class="content">
              <p>醫師應具有博愛精神與高尚人格，研修現代必需基本學識，並要深刻把握醫學有關專門知識和技術，並能適合運用；且其知識和技術，須與世界醫學水準相通，需要不斷獲得進步。</p>
              <p>韓石泉，〈醫師十箴〉《六十回憶》2009年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style=" top: 51.3%; left: 10%;" src="../assets/person-intro/20230424-人物介紹-物件-160-韓石泉-插畫53.png" alt="韓石泉" title="韓石泉">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-161-韓石泉-pic38.png" alt="韓石泉" title="韓石泉">
              <img class="zoom-2-btn" @click="showImg[18]=true" @keydown.enter="showImg[18]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">韓良誠 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">韓石泉</p>
                <p class="year">1897-1963</p>
                <div class="text-1"><div class="dot"></div>醫者與牧者</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">出身臺南秀才家庭，1913年考取醫學校，三年級時因抗議校內賣店與舍監無理，曾發動學潮罷買。1922年與黃金火醫師於臺南合開「共和醫院」，1928年另立「韓內科醫院」。</p>
                <p class="content-style">醫生事業逐漸發展的同時，積極參與社會活動，文協創立時即為理事，巡迴講習健康衛生，也放下身段參加演劇活動推廣新劇與新觀念。治警事件中曾遭到逮捕羈押，後加入臺灣民眾黨，擔任中央委員，負責臺南支部事務。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-1')" @keydown.enter="routeBtn('/unit2-1')" tabindex="0" alt="臺灣人的臺" title="臺灣人的臺">#臺灣人的臺灣</div>
                  <div class="hashtag" @click="routeBtn('/unit3-2')" @keydown.enter="routeBtn('/unit3-2')" tabindex="0" alt="演劇與電影" title="演劇與電影">#演劇與電影</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">變身請進入</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[19]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">李應章</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-166-李應章-pic39.png" alt="李應章" title="李應章">
            <div class="content">
              <p>我不堪其悲傷、不堪其抑鬱、直跑到講演場上、敘了一番農民的苦狀、訴出幾聲農民的悲哀、給社會的同胞聽聽。</p>
              <p>李應章，〈蔗農爭議的回顧〉《臺灣民報》1927年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 19.5vw; top: 52.7%; left: 2.5%;" src="../assets/person-intro/20230424-人物介紹-物件-167-李應章-插畫57_Fix.png" alt="李應章" title="李應章">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-168-李應章-pic40.png" alt="李應章" title="李應章">
              <img class="zoom-2-btn" @click="showImg[19]=true" @keydown.enter="showImg[19]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">《玉米田裡的小男孩》</p>
              <p class="source" style="bottom: -14%">作者 陳俊龍博士 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">李應章</p>
                <p class="year">1897-1954</p>
                <div class="text-1"><div class="dot"></div>醫者與牧者</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">就讀醫學校時即熱心進步思想，與同學發起全臺性學生組織，促成文協的成立，並擔任理事。畢業返回故鄉二林開設保安醫院，以醫術博得鄉里的敬重，醫務繁多而騎乘機車出診。</p>
                <p class="content-style">診療時接觸到遭剝削的窮苦蔗農，與地方文協同仁率領蔗農陳情，爆發警民衝突而被捕。在獄期間家逢變故而沉潛，而後前往中國改名李偉光。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" tabindex="0" alt="為農工大眾主張" title="為農工大眾主張">#為農工大眾主張</div>
                  <div class="hashtag" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0" alt="左派與右派" title="左派與右派">#左派與右派</div>
                </div>
              </div>
            </div>
            <div class="turn-div" @click="page='turn'" @keydown.enter="page='turn'" tabindex="0">
              <div style="margin-top: 1.1vw">變身請進入</div>
              <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
              <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[20]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">賴和</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-173-賴和-pic41.png" alt="賴和" title="賴和">
            <div class="content">
              <p>美麗島上經散播了無限種子</p>
              <p>自由的花、平等的樹</p>
              <p>專待我們熱血來  培養起</p>
              <p>賴和，〈代諸同志贈林呈祿先生〉1924年</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 7.2vw; top: 51%; left: 12.5%;" src="../assets/person-intro/20230424-人物介紹-物件-174-賴和-插畫61.png" alt="賴和" title="賴和">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-175-賴和-pic42.png" alt="賴和" title="賴和">
              <img class="zoom-2-btn" @click="showImg[20]=true" @keydown.enter="showImg[20]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">賴和文教基金會 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">賴和</p>
                <p class="year">1894-1943</p>
                <div class="text-1"><div class="dot"></div>醫者與牧者</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">臺北總督府醫學校畢業。赴廈門任職時，受到中國新文學運動啟發，1919年返回故鄉彰化開設賴和醫院。文協成立後擔任理事，並投入講演會與《臺灣民報》編輯工作，鼓勵後進以新文學創作。</p>
                <p class="content-style">1927年文協改組後，兼任臨時中央委員與民眾黨幹事，在《臺灣民報》、《臺灣大眾時報》、《南音》發表文藝作品，作品反映底層人民的生存處境，表現強烈的人文關懷。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit3-3')" @keydown.enter="routeBtn('/unit3-3')" tabindex="0" alt="言論機關" title="言論機關">#言論機關</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[21]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">林淇漳</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-177-林淇漳-pic43.png" alt="林淇漳" title="林淇漳">
            <div class="content">
              <p></p>
              <p></p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 6.5vw; top: 50.2%; left: 13%;" src="../assets/person-intro/20230424-人物介紹-物件-178-林淇漳-插畫62.png" alt="林淇漳" title="林淇漳">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-179-林淇漳-pic44.png" alt="林淇漳" title="林淇漳">
              <img class="zoom-2-btn" @click="showImg[21]=true" @keydown.enter="showImg[21]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">林盧秀蘭 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">林淇漳</p>
                <p class="year">1899-1965</p>
                <div class="text-1"><div class="dot"></div>醫者與牧者</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">嘉義長春醫院創辦人林淇漳醫生，是文化協會支持者。1916年醫校畢業後，曾隨商船擔任船醫，1925年以醫專學生身分在文協活動中演講「南洋一瞥」，1927年開設長春醫院，後受洗為基督教信徒，戰後參與地方救濟事業。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit3-1')" @keydown.enter="routeBtn('/unit3-1')" tabindex="0" alt="文化講演會" title="文化講演會">#文化講演會</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[22]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">楊肇嘉</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-181-楊肇嘉-pic45.png" alt="楊肇嘉" title="楊肇嘉">
            <div class="content">
              <p>總講臺灣的進步、臺灣的幸福、不是別人做乎咱、大家著抱開拓者的精神、愛有冒險進取的氣概、有愛有團結協力的觀念、大家盡力出來奮鬪、造成大家的幸福、是我最大的希望。</p>
              <p>摘自〈我的希望〉</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 9.3vw; top: 49.5%; left: 9.8%;" src="../assets/person-intro/20230424-人物介紹-物件-182-楊肇嘉-插畫63.png" alt="楊肇嘉" title="楊肇嘉">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-183-楊肇嘉-pic46.png" alt="楊肇嘉" title="楊肇嘉">
              <img class="zoom-2-btn" @click="showImg[22]=true" @keydown.enter="showImg[22]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">中央研究院臺灣史研究所檔案館 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">楊肇嘉</p>
                <p class="year">1892-1976</p>
                <div class="text-1"><div class="dot"></div>鄉紳儒士</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">清水人，因家境寬裕頗富聲望，1920年起擔任清水街長職務，受蔡惠如影響投入政治運動，1926年赴東京早稻田大學，結交政界廣植人脈。1930年楊肇嘉回台主持臺灣地方自治聯盟，主持台灣新民報業務，在1935年的首度地方選舉、1939年反對總督府米穀統制政策等，是臺灣政治運動的核心人物，他長期贊助藝術文化，在藝文圈也特別具有影響力。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-4')" @keydown.enter="routeBtn('/unit2-4')" tabindex="0" alt="政治結社的走向" title="政治結社的走向">#政治結社的走向</div>
                  <div class="hashtag" @click="routeBtn('/unit3-1')" @keydown.enter="routeBtn('/unit3-1')" tabindex="0" alt="文化講演會" title="文化講演會">#文化講演會</div>
                  <div class="hashtag" @click="routeBtn('/unit3-3')" @keydown.enter="routeBtn('/unit3-3')" tabindex="0" alt="言論機關" title="言論機關">#言論機關</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[23]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">簡吉</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-185-簡吉-pic47.png" alt="簡吉" title="簡吉">
            <div class="content">
              <p>情況愈發複雜化，恐怕會遇到更多困難，而我卻無能為力，
                每思及此就深感於心不安！但請你要更堅強地好好幹下去。</p>
              <p>我會冷靜、嚴肅和認真地，並像書中常說的「以我滿腔的熱忱」，
                從此鐵窗下經常寫信給你們！</p>
              <p>《簡吉獄中日記》，1930年（昭和五年）01/10由獄中寫給仍在各地工作的簡娥的信</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 11.5vw; top: 50.2%; left: 9.4%;" src="../assets/person-intro/20230424-人物介紹-物件-186-簡吉-插畫64.png" alt="簡吉" title="簡吉">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230711-人物介紹-物件-187-簡吉-pic48.png" alt="簡吉" title="簡吉">
              <img class="zoom-2-btn" @click="showImg[23]=true" @keydown.enter="showImg[23]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source">財團法人大眾教育基金會 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">簡吉</p>
                <p class="year">1903-1951</p>
                <div class="text-1"><div class="dot"></div>叛逆青年</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">生於高雄鳳山，原為鳳山公學校教師，因有感於學童困苦辭去教職投入社會運動，1925年組織鳳山農民組合，發展全國性農民運動組織。1928年開始支持臺灣共產黨，開設會會科學研究會、赤色救援會，在民間串聯農民抵抗，而屢番被捕。戰後228事件簡吉參與左派民軍組織，1950年被捕隔年遭到槍決。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-2')" @keydown.enter="routeBtn('/unit2-2')" tabindex="0" alt="為農工大眾主張" title="為農工大眾主張">#為農工大眾主張</div>
                  <div class="hashtag" @click="routeBtn('/unit3-4')" @keydown.enter="routeBtn('/unit3-4')" tabindex="0" alt="社會歌謠" title="社會歌謠">#社會歌謠</div>
                  <div class="hashtag" @click="routeBtn('/unit3-5')" @keydown.enter="routeBtn('/unit3-5')" tabindex="0" alt="世界語" title="世界語">#世界語</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="person-1" v-if="personArr[24]">
        <div class="top-content">
          <div class="person">
            <div class="person-name">王敏川</div>
            <img class="big-person-img" src="../assets/person-intro/20230711-人物介紹-物件-189-王敏川-pic49.png" alt="王敏川" title="王敏川">
            <div class="content">
              <p>此地同來數十人。俱懷才略策維新。</p>
                <p>相逢轉恨無言說。只把頭顱暗點頻。</p>
                <p>自料樊籠不易開。讀書靜坐屏疑猜。</p>
              <p>摘自〈獄中雜詠〉</p>
            </div>
          </div>
        </div>

        <div class="bottom-content">
          <div class="person">
            <img class="left-person-img" style="width: 11.5vw; top: 50.2%; left: 9.4%;" src="../assets/person-intro/20230424-人物介紹-物件-190-王敏川-插畫65.png" alt="王敏川" title="王敏川">
            <div class="center-person-div">
              <img class="w-full" src="../assets/person-intro/20230424-人物介紹-物件-191-王敏川-pic50.png" alt="王敏川" title="王敏川">
              <img class="zoom-2-btn" @click="showImg[24]=true" @keydown.enter="showImg[24]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
              <p class="source"> 蔣渭水文化基金會 提供</p>
            </div>
            <div class="content">
              <div class="row-1">
                <p class="person-name">王敏川</p>
                <p class="year">1889-1942</p>
                <div class="text-1"><div class="dot"></div>叛逆青年</div>
              </div>
              <div class="row-2">
                <div class="dot"></div>
                <div>人物簡介</div>
                <div class="line"></div>
              </div>
              <div class="row-3">
                <p class="content-style">彰化人，早期任教於彰化公學校，後入早稻田大學接觸社會主義，長期編輯臺灣青年、台灣民報編輯主力，論述擲地有聲，頗有聲望。1926年底推動文協左傾，發行大眾時報，屢遭羈押、入獄，1931年於第四次代表大會中被選為中央委員長兼財務部長，大力支持工農運動與台灣共產黨，後遭長期關押文協結束運作，是為臺灣文化協會末代委員長。</p>
              </div>
              <div class="row-4">
                <div>
                  <div class="dot" style="background: black"></div>
                  <div>與文協的故事</div>
                </div>
                <div>
                  <div class="hashtag" @click="routeBtn('/unit2-3')" @keydown.enter="routeBtn('/unit2-3')" tabindex="0" alt="左派與右派" title="左派與右派">#左派與右派</div>
                  <div class="hashtag" @click="routeBtn('/unit3-3')" @keydown.enter="routeBtn('/unit3-3')" tabindex="0" alt="言論機關" title="言論機關">#言論機關</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="turn-page" v-if="page=='turn'">
      <div class="person person-1" v-if="personArr[0]">
        <div class="name-div">
          一百年後的<span>連溫卿</span>是?
        </div>
        <div class="content content-1">
          <div class="title">語言學家</div>
          <div class="line"></div>
          <div class="content-text">希望人工的世界語能夠 超越民族信仰，並促使人類和平，解決人種、語言不同導致造成糾紛。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-58-連溫卿-插畫3.png" alt="語言學家" title="語言學家">
        </div>
        <div class="content content-2">
          <div class="title">領袖</div>
          <div class="line"></div>
          <div class="content-text">具邀請或等待支持者追隨，寄望時勢出現，領導具規模的政治組織，實施具規模的政治行動。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-59-連溫卿-插畫4.png" alt="領袖" title="領袖">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 8vw;" src="../assets/person-intro/20230424-人物介紹-物件-57-連溫卿-插畫2.png" alt="連溫卿" title="連溫卿">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[1]">
        <div class="name-div">
          一百年後的<span>蔡培火</span>是?
        </div>
        <div class="content content-1">
          <div class="title">民歌手</div>
          <div class="line"></div>
          <div class="content-text">隨時隨地都可以為臺灣自治和本土文化讚頌歡唱。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-65-蔡培火-插畫7.png" alt="民歌手" title="民歌手">
        </div>
        <div class="content content-2">
          <div class="title">本土語推行者</div>
          <div class="line"></div>
          <div class="content-text">推廣臺語白話字。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-66-蔡培火-插畫8.png" alt="本土語推行者" title="本土語推行者">
        </div>
        <div class="content content-3">
          <div class="title">政治家</div>
          <div class="line"></div>
          <div class="content-text">需要寬闊視野、胸襟、決心和毅力，不怕荊棘滿途，只會迎難而上，會尊重政治，並堅持自己的信念。</div>
          <img class="img-3" src="../assets/person-intro/20230711-人物介紹-物件-67-蔡培火-插畫9.png" alt="政治家" title="政治家">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 10.5vw; left: 6.5vw; " src="../assets/person-intro/20230424-人物介紹-物件-64-蔡培火-插畫6.png" alt="蔡培火" title="蔡培火">
        </div>
      </div>
      <!--img2-->
      <!--img3-->
      <div class="person person-1" v-if="personArr[4]">
        <div class="name-div">
          一百年後的<span>謝雪紅</span>是?
        </div>
        <div class="content content-1">
          <div class="title">導覽員</div>
          <div class="line"></div>
          <div class="content-text">為臺灣民主發聲，爭取臺灣獨立自主，要能號召愛鄉土、有實力的戰鬥團隊。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-81-謝雪紅-插畫14.png" alt="導覽員" title="導覽員">
        </div>
        <div class="content content-2">
          <div class="title">社會關心者</div>
          <div class="line"></div>
          <div class="content-text">作為組織先驅，要隨時掌握國際社會運動情勢。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-82-謝雪紅-插畫15.png" alt="社會關心者" title="社會關心者">
        </div>

        <div class="circle">
          <img style="top: -2vw; width: 10vw; left: 6.5vw; " src="../assets/person-intro/20230424-人物介紹-物件-80-謝雪紅-插畫13.png" alt="謝雪紅" title="謝雪紅">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[5]">
        <div class="name-div">
          一百年後的<span>簡娥</span>是?
        </div>
        <div class="content content-1">
          <div class="title">行動家</div>
          <div class="line"></div>
          <div class="content-text">瘦小的身体卻有著強大心靈與行動，不畏懼威脅，勇於行動。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-88-簡娥-插畫18.png" alt="行動家" title="行動家">
        </div>
        <div class="content content-2">
          <div class="title">新時代女性</div>
          <div class="line"></div>
          <div class="content-text">身為新一代女性，具備神出鬼沒、行動敏捷特質。為躲避警察的追捕，喬裝打扮也是必須的。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-89-簡娥-插畫19.png" alt="新時代女性" title="新時代女性">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 10vw; left: 7vw;" src="../assets/person-intro/20230424-人物介紹-物件-87-簡娥-插畫17.png" alt="簡娥" title="簡娥">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[6]">
        <div class="name-div">
          一百年後的<span>陳植棋</span>是?
        </div>
        <div class="content content-1">
          <div class="title">熱血青年</div>
          <div class="line"></div>
          <div class="content-text">渴望自由民主，為自己權力展開行動。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-95-陳植棋-插畫22.png" alt="熱血青年" title="熱血青年">
        </div>
        <div class="content content-2">
          <div class="title">西畫家</div>
          <div class="line"></div>
          <div class="content-text">名言「人生是短促的，藝術才是永遠」，追求藝術的人生。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-96-陳植棋-插畫23.png" alt="西畫家" title="西畫家">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 17.2vw; left: 4vw; " src="../assets/person-intro/20230424-人物介紹-物件-94-陳植棋-插畫21.png" alt="陳植棋" title="陳植棋">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[7]">
        <div class="name-div">
          一百年後的<span>莊松林</span>是?
        </div>
        <div class="content content-1">
          <div class="title">YouTuber</div>
          <div class="line"></div>
          <div class="content-text">透過戲劇表演，傳達社會階級意識，要讓大家耳目一新。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-102-莊松林-插畫26.png" alt="YouTuber" title="YouTuber">
        </div>
        <div class="content content-2">
          <div class="title">語言學習者</div>
          <div class="line"></div>
          <div class="content-text">學習世界語從聽開始，期望透過世界語解除文化隔閡。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-103-莊松林-插畫27.png" alt="語言學習者" title="語言學習者">
        </div>
        <div class="content content-3">
          <div class="title">網路評論家</div>
          <div class="line"></div>
          <div class="content-text">大量閱讀、積極論辯，挑戰刻板社會觀念。</div>
          <img class="img-3" src="../assets/person-intro/20230711-人物介紹-物件-104-莊松林-插畫28.png" alt="網路評論家" title="網路評論家">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 16.6vw; left: -1.5vw; " src="../assets/person-intro/20230711-人物介紹-物件-101-莊松林-插畫25.png" alt="莊松林" title="莊松林">
        </div>
      </div>
      <!--img8-->
      <!--img9-->
      <div class="person person-1" v-if="personArr[10]">
        <div class="name-div">
          一百年後的<span>蔡淵騰</span>是?
        </div>
        <div class="content content-1">
          <div class="title">地主</div>
          <div class="line"></div>
          <div class="content-text">不只為自己的土地聲張，更為蔗農精算應得利益。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-119-蔡淵騰-插畫34.png" alt="地主" title="地主">
        </div>
        <div class="content content-2">
          <div class="title">農業組織者</div>
          <div class="line"></div>
          <div class="content-text">關心農民請從動手開始，將自己站在他們一樣位子，才能感同身受他們的處境。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-118-蔡淵騰-插畫33.png" alt="農業組織者" title="農業組織者">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 9vw; left: 7.5vw; " src="../assets/person-intro/20230424-人物介紹-物件-117-蔡淵騰-插畫32.png" alt="蔡淵騰" title="蔡淵騰">
        </div>
      </div>
      <!--img11-->
      <div class="person person-1" v-if="personArr[12]">
        <div class="name-div">
          一百年後的<span>陳澄波</span>是?
        </div>
        <div class="content content-1">
          <div class="title">熱愛臺灣風景者</div>
          <div class="line"></div>
          <div class="content-text">他熱愛臺灣風景，畫作都是戶外寫生，記者問過他為什麼比較黑，他說是嘉義陽光炙熱的關係。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-129-陳澄波-插畫38.png" alt="熱愛臺灣風景者" title="熱愛臺灣風景者">
        </div>
        <div class="content content-2">
          <div class="title">斜槓</div>
          <div class="line"></div>
          <div class="content-text">是為斜槓藝術家，以藝術家的浪漫，跨界政治，為人民訴求。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-130-陳澄波-插畫39.png" alt="斜槓" title="斜槓">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 12.5vw; left: 5vw; " src="../assets/person-intro/20230424-人物介紹-物件-128-陳澄波-插畫37.png" alt="陳澄波" title="陳澄波">
        </div>
      </div>
      <!--img13-->
      <!--img14-->
      <div class="person person-1" v-if="personArr[15]">
        <div class="name-div">
          一百年後的<span>蘇璧輝</span>是?
        </div>
        <div class="content content-1">
          <div class="title">時間管理者</div>
          <div class="line"></div>
          <div class="content-text">時鐘塔由蘇璧輝家族捐贈，上面寫的字母「TEMPO  ESTA  MONO」是世界語「時間就是金錢」的意思。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-144-蘇璧輝-插畫44.png" alt="時間管理者" title="時間管理者">
        </div>
        <div class="content content-2">
          <div class="title">語言推行者</div>
          <div class="line"></div>
          <div class="content-text">積極推動世界語，將世界語成為國際交流的媒介語言。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-145-蘇璧輝-插畫45.png" alt="語言推行者" title="語言推行者">
        </div>

        <div class="circle">
          <img style="top: -2vw; width: 10.2vw; left: 7vw; " src="../assets/person-intro/20230424-人物介紹-物件-143-蘇璧輝-插畫43.png" alt="蘇璧輝" title="蘇璧輝">
        </div>
      </div>
      <!--img16-->
      <div class="person person-1" v-if="personArr[17]">
        <div class="name-div">
          一百年後的<span>蔣渭水</span>是?
        </div>
        <div class="content content-5">
          <div class="title">醫生</div>
          <div class="line"></div>
          <div class="content-text">身為醫生，以「臨床講義」為臺灣診治，盼望打醒臺灣人。</div>
          <img class="img-5" src="../assets/person-intro/20230711-人物介紹-物件-155-蔣渭水-插畫49.png" alt="醫生" title="醫生">
        </div>
        <div class="content content-1">
          <div class="title">改革家</div>
          <div class="line"></div>
          <div class="content-text">用鍵盤號招群眾，及時評論，網路上一呼百諾。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-157-蔣渭水-插畫51.png" alt="改革家" title="改革家">
        </div>
        <div class="content content-2">
          <div class="title">歌謠家</div>
          <div class="line"></div>
          <div class="content-text">改編歌詞給大家琅琅上口，例如「臺灣文化協會會歌」、「勞動節歌」。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-156-蔣渭水-插畫50.png" alt="歌謠家" title="歌謠家">
        </div>
        <div class="content content-3">
          <div class="title">社會運動領頭者</div>
          <div class="line"></div>
          <div class="content-text">行動力強，不怕攻擊與塗污抹黑。</div>
          <img class="img-3" src="../assets/person-intro/20230711-人物介紹-物件-158-蔣渭水-插畫52.png" alt="社會運動領頭者" title="社會運動領頭者">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 10vw; left: 6vw; " src="../assets/person-intro/20230424-人物介紹-物件-154-蔣渭水-插畫48.png" alt="蔣渭水" title="蔣渭水">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[18]">
        <div class="name-div">
          一百年後的<span>韓石泉</span>是?
        </div>
        <div class="content content-1">
          <div class="title">醫生</div>
          <div class="line"></div>
          <div class="content-text">聽見基層農民、勞工內心話，為他們發聲。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-164-韓石泉-插畫55.png" alt="醫生" title="醫生">
        </div>
        <div class="content content-2">
          <div class="title">病歷與書籍</div>
          <div class="line"></div>
          <div class="content-text">常帶著農民、勞工生命記錄的病歷表之外，同時也要帶本書，散播健康的生活觀念。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-165-韓石泉-插畫56.png" alt="病歷與書籍" title="病歷與書籍">
        </div>
        <div class="circle">
          <img style="top: -2vw; width: 10vw; left: 7vw; " src="../assets/person-intro/20230424-人物介紹-物件-163-韓石泉-插畫54.png" alt="韓石泉" title="韓石泉">
        </div>
      </div>
      <div class="person person-1" v-if="personArr[19]">
        <div class="name-div">
          一百年後的<span>李應章</span>是?
        </div>
        <div class="content content-1">
          <div class="title">行動家</div>
          <div class="line"></div>
          <div class="content-text">像是現代為偏鄉出診的醫生，騎機車到鄉里做醫診業務，同時迅速為農組進行行動。</div>
          <img class="img-1" src="../assets/person-intro/20230711-人物介紹-物件-171-李應章-插畫59.png" alt="行動家" title="行動家">
        </div>
        <div class="content content-2">
          <div class="title">醫生</div>
          <div class="line"></div>
          <div class="content-text">用醫生角度診斷農民受到不公的待遇，並為他們發聲。</div>
          <img class="img-2" src="../assets/person-intro/20230711-人物介紹-物件-172-李應章-插畫60.png" alt="醫生" title="醫生">
        </div>

        <div class="circle">
          <img style="top: -2vw; width: 15vw; left: 6.6vw; " src="../assets/person-intro/20230424-人物介紹-物件-170-李應章-插畫58.png" alt="李應章" title="李應章">
        </div>
      </div>
      <!--img20-->
      <!--img21-->
      <!--img22-->
      <!--img23-->
      <!--img24-->
      <div class="turn-div" @click="page='normal'" @keydown.enter="page='normal'" tabindex="0">
        <div style="margin-top: 1.1vw">返回</div>
        <img style="width: 2.2vw; margin: 1.5vw 0 0 0.6vw" src="../assets/common/right-arrow-green.png"  :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
        <img style="width:2.8vw; margin-left: 0.2vw" src="../assets/person-intro/20230424-人物介紹-物件-29-進入門.png" :alt="page=='turn'?'返回':'變身請進入'" :title="page=='turn'?'返回':'變身請進入'">
      </div>
    </div>

    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-56-連溫卿-pic2-放大文物.png" alt="連溫卿" title="連溫卿">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 27.2vw; width: 9vw;">
            <div class="bold-title">連溫卿</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立清華大學 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-63-蔡培火-pic4-放大文物.png" alt="蔡培火" title="蔡培火">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 24.8vw; width: 14.1vw;">
            <div class="bold-title">蔡培火</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣文學館典藏 林章峯捐贈</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-71-謝文達-pic6-放大文物.png" alt="謝文達" title="謝文達">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 27vw; width: 8.2vw;">
            <div class="bold-title">謝文達</div>
            <div class="noto-text" style="margin-top: 0.4vw">國家圖書館 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-75-林獻堂-pic8-放大文物.png" alt="林獻堂" title="林獻堂">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 24.7vw; width: 14.1vw;">
            <div class="bold-title">林獻堂</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣文學館典藏 林章峯捐贈</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
        <!-- <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-79-謝雪紅-pic10-放大文物.png" alt="謝雪紅" title="謝雪紅">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 26vw; width: 11.6vw;">
            <div class="bold-title">謝雪紅</div>
            <div class="noto-text" style="margin-top: 0.4vw">新世語文化有限公司 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-86-簡娥-pic12-放大文物.png" alt="簡娥" title="簡娥">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 27.8vw; width: 8.2vw;">
            <div class="bold-title">簡娥</div>
            <div class="noto-text" style="margin-top: 0.4vw">莊永明家屬 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-93-陳植棋-pic14-放大文物.png" alt="陳植棋" title="陳植棋">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 22.5vw; width: 18.6vw;">
            <div class="bold-title">陳植棋</div>
            <div class="noto-text" style="margin-top: 0.4vw">中央研究院臺灣史研究所檔案館 陳子智 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img7--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[7]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-100-莊松林-pic16-放大文物.png" alt="莊松林" title="莊松林">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 28.5vw; width: 6.5vw;">
            <div class="bold-title">莊松林</div>
            <div class="noto-text" style="margin-top: 0.4vw">莊明正 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[7]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img8--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[8]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-108-盧丙丁-pic18-放大文物.png" alt="盧丙丁" title="盧丙丁">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 24.8vw; width: 14.1vw;">
            <div class="bold-title">盧丙丁</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣文學館典藏 林章峯捐贈</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[8]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img9--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[9]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-112-王詩琅-pic20-放大文物.png" alt="王詩琅" title="王詩琅">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 27.5vw; width: 8.2vw;">
            <div class="bold-title">王詩琅</div>
            <div class="noto-text" style="margin-top: 0.4vw">莊永明家屬 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[9]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img10--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[10]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-116-蔡淵騰-pic22-放大文物.png" alt="蔡淵騰" title="蔡淵騰">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 28.5vw; width: 6.5vw;">
            <div class="bold-title">蔡淵騰</div>
            <div class="noto-text" style="margin-top: 0.4vw">蔡詩傑 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[10]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img11--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[11]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-123-蔡惠如-pic24-放大文物.png" alt="蔡惠如" title="蔡惠如">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 23.7vw; width: 16vw;">
            <div class="bold-title">蔡惠如</div>
            <div class="noto-text" style="margin-top: 0.4vw">中央研究院臺灣史研究所檔案館 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[11]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img12--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[12]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-127-陳澄波-pic26-放大文物.png" alt="陳澄波" title="陳澄波">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 26.5vw; width: 10.7vw;">
            <div class="bold-title">陳澄波</div>
            <div class="noto-text" style="margin-top: 0.4vw">陳澄波文化基金會 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[12]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img13--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[13]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-134-陳懷澄-pic28-放大文物.png" alt="陳懷澄" title="陳懷澄">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 28.5vw; width: 6.5vw;">
            <div class="bold-title">陳懷澄</div>
            <div class="noto-text" style="margin-top: 0.4vw">李昭容 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[13]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img14--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[14]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-138-林幼春-pic30-放大文物.png" alt="林幼春" title="林幼春">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 24.8vw; width: 14.1vw;">
            <div class="bold-title">林幼春</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣文學館典藏 林章峯捐贈</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[14]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img15--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[15]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-142-蘇璧輝-pic32-放大文物.png" alt="蘇璧輝" title="蘇璧輝">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 28.5vw; width: 6.5vw;">
            <div class="bold-title">蘇璧輝</div>
            <div class="noto-text" style="margin-top: 0.4vw">鄭慧美 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[15]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img16--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[16]">
        <!-- <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-149-杜香國-pic34-放大文物.png" alt="杜香國" title="杜香國">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 23vw; width: 17.4vw;">
            <div class="bold-title">杜香國</div>
            <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2020.032.0252 </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[16]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img17--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[17]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-153-蔣渭水-pic36-放大文物.png" alt="蔣渭水" title="蔣渭水">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 26.5vw; width: 10.7vw;">
            <div class="bold-title">蔣渭水</div>
            <div class="noto-text" style="margin-top: 0.4vw">蔣渭水文化基金會 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[17]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img18--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[18]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-162-韓石泉-pic38-放大文物.png" alt="韓石泉" title="韓石泉">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 28.5vw; width: 6.5vw;">
            <div class="bold-title">韓石泉</div>
            <div class="noto-text" style="margin-top: 0.4vw">韓良誠 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[18]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img19--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[19]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-169-李應章-pic40-放大文物.png">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 22.2vw; width: 19vw;">
            <div class="bold-title">李應章</div>
            <div class="noto-text" style="margin-top: 0.4vw">《玉米田裡的小男孩》作者 陳俊龍博士 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[19]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img20--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[20]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-176-賴和-pic42-放大文物.png" alt="賴和" title="賴和">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 27vw; width: 10vw;">
            <div class="bold-title">賴和</div>
            <div class="noto-text" style="margin-top: 0.4vw">賴和文教基金會 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[20]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img21--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[21]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-180-林淇漳-pic44-放大文物.png" alt="林淇漳" title="林淇漳">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 28vw; width: 7.3vw;">
            <div class="bold-title">林淇漳</div>
            <div class="noto-text" style="margin-top: 0.4vw">林盧秀蘭 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[21]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img22--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[22]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-184-楊肇嘉-pic46-放大文物.png" alt="楊肇嘉" title="楊肇嘉">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 23.8vw; width: 15.8vw;">
            <div class="bold-title">楊肇嘉</div>
            <div class="noto-text" style="margin-top: 0.4vw">中央研究院臺灣史研究所檔案館 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[22]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img23--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[23]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230711-人物介紹-物件-188-簡吉-pic48-放大文物.png" alt="簡吉" title="簡吉">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 25.3vw; width: 13.3vw;">
            <div class="bold-title">簡吉</div>
            <div class="noto-text" style="margin-top: 0.4vw">財團法人大眾教育基金會 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[23]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img24--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[24]">
        <!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/person-intro/sample/20230424-對位-人物介紹-01-連溫卿3.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.9vw 0 0 23vw" src="../assets/person-intro/20230424-人物介紹-物件-192-王敏川-pic50-放大文物.png" alt="王敏川" title="王敏川">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 26.5vw; width: 10.7vw;">
            <div class="bold-title">王敏川</div>
            <div class="noto-text" style="margin-top: 0.4vw">蔣渭水文化基金會 提供</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[24]=false">
          </div>
        </div>
      </div>
    </transition>
    <intro-btn ></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import {onMounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import {useRoute} from "vue-router/dist/vue-router";
import myMixin from "@/assets/common/js/mixin";
import {head} from "axios";
import IntroBtn from "@/components/IntroBtn.vue";
export default {
  name: "PersonIntroView",
  mixins: [myMixin],
  components:{
    IntroBtn,
    toolbar,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const headPage = ref(0);
    const page = ref('normal');
    const personArr = reactive(Array.from({ length: 25 }, () => false));
    const showImg = reactive(Array.from({ length: 25 }, () => false));
    const route = useRoute();


    onMounted(()=>{
      if(route.params.num == ''){
        personArr[0] = true;
      }else{
        personArr[route.params.num] = true;
        //personAr
        // r[0] = true;
        if(route.params.num > 17){
          headPage.value = 2;
        }
        else if(route.params.num > 8){
          headPage.value = 1;
        }
      }
    });

    function routeBtn(path){
      router.push(path);
    }

    function rightHeadBtn(){
      headPage.value = Math.min(headPage.value+1, 2);
    }
    function leftHeadBtn(){
      headPage.value = Math.max(headPage.value-1, 0);
    }

    function changePerson(num){
      page.value = 'normal';
      for (let i=0; i<personArr.length;i++)
        personArr[i] = false;
      personArr[num] = true;
      routeBtn('/person-intro/'+num);
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }


    return{
      routeBtn,
      page,
      personArr,
      headPage,
      rightHeadBtn,
      leftHeadBtn,
      showImg,
      changePerson,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent,
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/personintro.css"></style>
