<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="2" unit="3" route-name="unit3-1" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit3-1/sample/20230411-對位-3-1-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="pic-1" src="../assets/Unit3-1/20230411-3-1-物件-01-首圖1.png" alt="文化講演會" title="文化講演會">

      <div class="title-logo">文化講演會</div>

      <div class="wave">
        <LottieTest :json="require('@/assets/Unit3-1/3-1-0.json')" alt="文化講演會" title="文化講演會"></LottieTest>
      </div>
    </div>

    <div class="part2 part-color">
        <div class="content">
          <img style="width: 31.2vw" src="../assets/Unit3-1/20230411-3-1-物件-02-裝飾字1-造勢活動.png" alt="造勢活動" title="造勢活動">
          <p class="content-style" style="margin-top: 2.8vw">1923年5月， 黃呈聰、王敏川等舉辦《臺灣民報》宣傳巡迴演講，大受地方人士歡迎，之後開始頻繁舉辦演講會。在識字率不高的臺灣社會，講演活動具有號召力，能實際傳達訊息，更是本土政治主張最強力的造勢活動。</p>
          <p class="content-style">當時由臺北、臺中、臺南三處為中心，派出辯士（演講者）四處巡迴，約定日期後，辯士自掏腰包買車票到場、地方人士供給膳宿，在演講前常有舉旗、車陣、鞭炮，表示地方眾人的熱烈歡迎。</p>
          <p class="content-style">這些經申請的活動，總督府都會派警察盯哨，見場面過激便要求「中止」，嚴重者則要求活動立即「解散」， 甚至會把演講者或吆喝的聽眾， 扣押到警局「檢束」。為突破警方的干擾，1927年11月連溫卿等文協幹部曾試圖發動群眾包圍警署，致使警民衝突，演講會場更成為政治抗爭的最前線。</p>
        </div>
      <div class="people">
        <LottieTest :json="require('@/assets/Unit3-1/3-1-1.json')" ></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
<!--      <img class="sample" src="../assets/Unit3-1/sample/20230411-對位-3-1-03.png">-->
      <div class="content-1">
        <div style="margin-right: 3vw">
          <img class="h-full" src="../assets/Unit3-1/20230411-3-1-物件-03-pic1.png" alt="大型演講現場" title="大型演講現場">
          <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
        <div >
          <img class="h-full" src="../assets/Unit3-1/20230411-3-1-物件-04-pic2.png" alt="大型演講現場" title="大型演講現場">
          <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
        <transition name="img-frame-fade"><img v-if="showText[0]==true" class="img-1" src="../assets/Unit3-1/20230419-3-1-物件-06-到處都是監視者-説明.png" alt="四處都有監視者" title="四處都有監視者"></transition>
        <transition name="img-frame-fade"><img v-if="showText[1]==true" class="img-2" src="../assets/Unit3-1/20230411-3-1-物件-07-細讀照片-説明1.png" alt="細讀照片" title="細讀照片"></transition>
        <transition name="img-frame-fade"><img v-if="showText[1]==true" class="img-3" src="../assets/Unit3-1/20230411-3-1-物件-07-細讀照片-説明2.png" alt="細讀照片" title="細讀照片"></transition>
      </div>
      <div class="content-2">
          <p class="title-style" style="color: #2A806B">大型演講現場</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">1933 年 7 月 23 日 地方自治聯盟大型演講會 <span class="btn" style="left: 22vw" @click="showText[0]=!showText[0]" @keydown.enter="showText[0]=!showText[0]" tabindex="0" alt="四處都有監視者" title="四處都有監視者">四處都有監視者</span><span class="btn" style="left: 30vw" @click="showText[1]=!showText[1]" @keydown.enter="showText[1]=!showText[1]" tabindex="0" alt="細讀照片" title="細讀照片">細讀照片</span></p>
          <p class="source-style" style="margin-top: 0.3vw">六然居資料室 提供</p>
          <p class="content-style" style="margin-top: 0.5vw;">臺灣地方自治聯盟全島住民中部大會演講情景。會場背景貼滿了演講主題與講者，到場聆聽的聽眾相當踴躍。講臺上與側邊看臺有看到至少有3名警察在場監聽。</p>
      </div>
    </div>

    <div class="part4 part-color">
      <div class="content-1">
        <img class="w-full" src="../assets/Unit3-1/20230411-3-1-物件-08-pic3-放大文物.png" alt="留學生團講演會" title="留學生團講演會">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 4%; right: 6%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #2A806B">聽留學生說新知</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺灣文化協會新竹分部主辦「留學生團講演會」宣傳單</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2003.016.0105</p>
        <p class="content-style" style="margin-top: 0.5vw;">臺灣文化協會新竹分部於1927年8月12-14日主辦的「留學生團講演會」宣傳單。</p>
        <p class="content-style">主講人為東京商科大學的鄭昌言、東京中央大學的賴遠輝、吳鴻爐、新竹的林冬桂、彭宇棟、李傳興等。</p>
      </div>
      <div class="people">
        <LottieTest :json="require('@/assets/Unit3-1/3-1-2.json')" alt="留學生團講演會" title="留學生團講演會"></LottieTest>
      </div>
    </div>

    <div class="part5 part-color">
      <div class="pic-1">
        <img class="w-full" src="../assets/Unit3-1/20230411-3-1-物件-09-pic4.png" alt="我的希望" title="我的希望">
        <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 10%; right: -4%" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="audio-div">
        <audio-comp :audio-src="require('../assets/Unit3-1/我的希望.mp3')" style-class="orange-style" volume-style="left:98%;"></audio-comp>
      </div>

      <div class="content-1">
        <p class="title-style" style="color: #267c68">聽到跨時空的演講</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">楊肇嘉〈我的希望〉唱片、講詞 1935年</p>
        <p class="source-style" style="margin-top: 0.3vw">錄音、唱片 : 徐登芳、六然居資料室 提供</p>
        <p class="content-style" style="margin-top: 0.5vw">出身清水的政治運動家楊肇嘉，熱衷推動新聞媒體，也是藝文贊助者，他在1935年利用一次前往東京的機會，委託唱片公司錄製他的政治演講。他細心撰擬稿件，也特地在照相館拍攝兩張「定裝照」，表現他雄壯的演講姿態，作為唱片歌詞單的配圖。當時的留聲機唱片兩面共約7分鐘，濃縮地表現他的政治思想，與對新生代青年的期許。</p>
      </div>

      <div class="turnClass">
        <div id="flipbook" >
              <img class="page-1" :src="require('@/assets/Unit3-1/翻書-1.png')" alt="楊肇嘉〈我的希望〉講詞 1935年" title="楊肇嘉〈我的希望〉講詞 1935年">
              <img class="page-2" :src="require('@/assets/Unit3-1/翻書-2.png')" alt="楊肇嘉〈我的希望〉講詞 1935年" title="楊肇嘉〈我的希望〉講詞 1935年">
              <img class="page-3" :src="require('@/assets/Unit3-1/翻書-3.png')" alt="楊肇嘉〈我的希望〉講詞 1935年" title="楊肇嘉〈我的希望〉講詞 1935年">
              <img class="page-4" :src="require('@/assets/Unit3-1/翻書-4.png')" alt="楊肇嘉〈我的希望〉講詞 1935年" title="楊肇嘉〈我的希望〉講詞 1935年">
        </div>

        <transition name="img-frame-fade"><img @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" v-if="booking==false && bookPage==1" class="zoom-2-btn zoom-fade "  style="width: 2.2vw; bottom: 2.5%; right: 27%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊"></transition>
        <transition name="img-frame-fade"><img @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" v-if="booking==false && bookPage==2 || bookPage==3" class="zoom-2-btn zoom-fade "  style="width: 2.2vw; bottom: 2.5%; right: 2%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊"></transition>
        <transition name="img-frame-fade"><img @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" v-if="booking==false && bookPage==4" class="zoom-2-btn zoom-fade "  style="width: 2.2vw; bottom: 2.5%; right: 27%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊"></transition>

        <transition name="img-frame-fade"><img v-if="bookPage==2 || bookPage==3" class="arrow left-arrow-center"  @click="bookLeftBtn" @keydown.enter="bookLeftBtn" tabindex="0" src="../assets/common/left-arrow.png" alt="上一頁" title="上一頁"></transition>
        <transition name="img-frame-fade"><img v-if="bookPage==4" class="arrow left-arrow"  @click="bookLeftBtn" @keydown.enter="bookLeftBtn" tabindex="0" src="../assets/common/left-arrow.png" alt="上一頁" title="上一頁"></transition>

        <transition name="img-frame-fade"><img v-if="bookPage==1"  class="arrow right-arrow" @click="bookRightBtn" @keydown.enter="bookRightBtn" tabindex="0"  src="../assets/common/right-arrow.png" alt="下一頁" title="下一頁"></transition>
        <transition name="img-frame-fade"><img v-if="bookPage==2 || bookPage==3"  class="arrow right-arrow-center" @click="bookRightBtn" @keydown.enter="bookRightBtn" tabindex="0"  src="../assets/common/right-arrow.png" alt="下一頁" title="下一頁"></transition>
      </div>
    </div>

    <div class="part6 part-color">
<!--      <img class="sample" style="z-index: 0" src="../assets/Unit3-1/sample/20230411-對位-3-1-06.png">-->
      <div class="content-1">
        <p class="title-style" style="color: #267c68">文化講演聽眾人數分佈</p>
        <p class="content-style" style="color: #267c68; margin-top: 0.3vw">人數為累積參加的人次，() 為舉辦場次。</p>
        <div class="item" :class="mapNum==0?'item-selected':''" @click="mapBtn(0)" @keydown.enter="mapBtn(0)" tabindex="0" alt="臺北州" title="臺北州"><div class="option"></div>臺北州</div>
        <div class="item" :class="mapNum==1?'item-selected':''" @click="mapBtn(1)" @keydown.enter="mapBtn(1)" tabindex="0" alt="新竹州" title="新竹州"><div class="option"></div>新竹州</div>
        <div class="item" :class="mapNum==2?'item-selected':''" @click="mapBtn(2)" @keydown.enter="mapBtn(2)" tabindex="0" alt="臺中州" title="臺中州"><div class="option"></div>臺中州</div>
        <div class="item" :class="mapNum==3?'item-selected':''" @click="mapBtn(3)" @keydown.enter="mapBtn(3)" tabindex="0" alt="臺南州" title="臺南州"><div class="option"></div>臺南州</div>
        <div class="item" :class="mapNum==4?'item-selected':''" @click="mapBtn(4)" @keydown.enter="mapBtn(4)" tabindex="0" alt="高雄州" title="高雄州"><div class="option"></div>高雄州</div>
      </div>
      <div class="map-div">
        <img class="w-full" src="../assets/Unit3-1/20230411-3-1-物件-17-臺灣.png" alt="臺灣" title="臺灣">

        <transition name="img-frame-fade"><img v-if="mapNum==-1" class="absolute" style="width: 2.3vw; top: 11%; left: 75%;" src="../assets/Unit3-1/20230411-3-1-物件-18-臺北州.png" alt="臺北州" title="臺北州"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==-1" class="absolute" style="width: 2.3vw; top: 17.5%; left: 48.5%;" src="../assets/Unit3-1/20230411-3-1-物件-19-新竹州.png" alt="新竹州" title="新竹州"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==-1" class="absolute" style="width: 2.3vw; top: 36.5%; left: 33.5%;" src="../assets/Unit3-1/20230411-3-1-物件-20-臺中州.png" alt="臺中州" title="臺中州"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==-1" class="absolute" style="width: 2.3vw; top: 52%; left: 15.5%;" src="../assets/Unit3-1/20230411-3-1-物件-21-臺南州.png" alt="臺南州" title="臺南州"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==-1" class="absolute" style="width: 2.3vw; top: 70.5%; left: 22%;" src="../assets/Unit3-1/20230411-3-1-物件-22-高雄州.png" alt="高雄州" title="高雄州"></transition>

        <transition name="img-frame-fade"><img v-if="mapNum==0" class="absolute" style="width:7.55vw; top: 0.5%; left: 61.58%;" src="../assets/Unit3-1/20230411-3-1-物件-18-臺北州-地圖.png" alt="臺北州" title="臺北州"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==1" class="absolute" style="width: 8.8vw; top: 6.7%; left: 28.7%;" src="../assets/Unit3-1/20230411-3-1-物件-19-新竹州-地圖.png" alt="新竹州" title="新竹州"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==2" class="absolute" style="width:11.9vw; top: 22.5%; left: 11.65%;" src="../assets/Unit3-1/20230411-3-1-物件-20-臺中州-地圖.png" alt="臺中州" title="臺中州"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==3" class="absolute" style="width:10.14vw; top: 41.5%; left: -2.7%;" src="../assets/Unit3-1/20230411-3-1-物件-21-臺南州-地圖.png" alt="臺南州" title="臺南州"></transition>
        <transition name="img-frame-fade"><img v-if="mapNum==4" class="absolute" style="width:9.36vw; top: 52.2%; left: 6.2%;" src="../assets/Unit3-1/20230411-3-1-物件-22-高雄州-地圖.png" alt="高雄州" title="高雄州"></transition>
      </div>
      <transition name="img-frame-fade">
        <div class="content-2" v-if="mapPage==0">
          <p class="content-style">講演會在1923年逐年增加，至1925年及1926年達最高紀錄，年間全島達三百餘次，有攻城掠地之義，即有講演之處，則可視為響應文化派的地方。《臺灣總督府警察沿革誌》載有三種有關文化講演會統計表，包括「講演次數及解散處份次數」、「辯士人數及中止處分人數」和「聽眾總人數及每一次平均人數」</p>
        </div>
      </transition>

      <div class="content-3" v-if="mapPage==1">
        <transition name="img-frame-fade">
          <div class="chart-div chart-1" v-if="mapNum==0">
            <div class="chart">
              <div class="count">
                <p>4000人</p>
                <p>(4場)</p>
              </div>
              <div class="bar" :style="barStyle[0]"></div>
              <div class="year">1923</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>13950人</p>
                <p>(51場)</p>
              </div>
              <div class="bar" :style="barStyle[1]"></div>
              <div class="year">1924</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>27920人</p>
                <p>(99場)</p>
              </div>
              <div class="bar" :style="barStyle[2]"></div>
              <div class="year">1925</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>24915人</p>
                <p>(97場)</p>
              </div>
              <div class="bar" :style="barStyle[3]"></div>
              <div class="year">1926</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>24850人</p>
                <p>(83場)</p>
              </div>
              <div class="bar" :style="barStyle[4]"></div>
              <div class="year">1927(年)</div>
            </div>
          </div>
        </transition>
        <transition name="img-frame-fade">
          <div class="chart-div chart-2" v-if="mapNum==1">
            <div class="chart">
              <div class="count">
                <p>18410人</p>
                <p>(22場)</p>
              </div>
              <div class="bar" :style="barStyle[5]"></div>
              <div class="year">1925</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>28630人</p>
                <p>(68場)</p>
              </div>
              <div class="bar" :style="barStyle[6]"></div>
              <div class="year">1926</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>42000人</p>
                <p>(73場)</p>
              </div>
              <div class="bar" :style="barStyle[7]"></div>
              <div class="year">1927(年)</div>
            </div>
          </div>
        </transition>
        <transition name="img-frame-fade">
          <div class="chart-div chart-3" v-if="mapNum==2">
            <div class="chart">
              <div class="count">
                <p>14113人</p>
                <p>(25場)</p>
              </div>
              <div class="bar" :style="barStyle[10]"></div>
              <div class="year">1923</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>22525人</p>
                <p>(47場)</p>
              </div>
              <div class="bar" :style="barStyle[11]"></div>
              <div class="year">1924</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>36440人</p>
                <p>(102場)</p>
              </div>
              <div class="bar" :style="barStyle[12]"></div>
              <div class="year">1925</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>13360人</p>
                <p>(27場)</p>
              </div>
              <div class="bar" :style="barStyle[13]"></div>
              <div class="year">1926</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>15940人</p>
                <p>(47場)</p>
              </div>
              <div class="bar" :style="barStyle[14]"></div>
              <div class="year">1927(年)</div>
            </div>
          </div>
        </transition>
        <transition name="img-frame-fade">
          <div class="chart-div chart-4" v-if="mapNum==3">
            <div class="chart">
              <div class="count">
                <p>2773人</p>
                <p>(6場)</p>
              </div>
              <div class="bar" :style="barStyle[15]"></div>
              <div class="year">1923</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>7575人</p>
                <p>(34場)</p>
              </div>
              <div class="bar" :style="barStyle[16]"></div>
              <div class="year">1924</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>32840人</p>
                <p>(67場)</p>
              </div>
              <div class="bar" :style="barStyle[17]"></div>
              <div class="year">1925</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>36870人</p>
                <p>(88場)</p>
              </div>
              <div class="bar" :style="barStyle[18]"></div>
              <div class="year">1926</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>11890人</p>
                <p>(34場)</p>
              </div>
              <div class="bar" :style="barStyle[19]"></div>
              <div class="year">1927(年)</div>
            </div>
          </div>
        </transition>
        <transition name="img-frame-fade">
          <div class="chart-div chart-5" v-if="mapNum==4">
            <div class="chart">
              <div class="count">
                <p>200人</p>
                <p>(1場)</p>
              </div>
              <div class="bar" :style="barStyle[20]"></div>
              <div class="year">1923</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>2270人</p>
                <p>(24場)</p>
              </div>
              <div class="bar" :style="barStyle[22]"></div>
              <div class="year">1925</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>9190人</p>
                <p>(35場)</p>
              </div>
              <div class="bar" :style="barStyle[23]"></div>
              <div class="year">1926</div>
            </div>
            <div class="chart">
              <div class="count">
                <p>2380人</p>
                <p>(8場)</p>
              </div>
              <div class="bar" :style="barStyle[24]"></div>
              <div class="year">1927(年)</div>
            </div>
          </div>
        </transition>

      </div>

      <div class="source">資料來源：《臺灣總督府警察沿革治》有關文化協會講演會的統計表</div>
      <div class="back-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0" alt="回廟埕拚文化" title="回廟埕拚文化">
        <img src="../assets/common/left-arrow-green.png"  alt="回廟埕拚文化" title="回廟埕拚文化">
        <p style="margin-left: 0.5vw">回廟埕拚文化</p>
      </div>
    </div>


    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-11.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36vw; margin:2.9vw 0 0 13.3vw" src="../assets/Unit3-1/20230704-3-1-物件-03-pic1-放大文物.png"  alt="大型演講現場" title="大型演講現場">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 21.5vw; width: 20.9vw;">
            <div >
              <div class="bold-title">1933年7月23日 地方自治聯盟大型演講會 </div>
              <div class="noto-text" style="margin-top: 0.4vw">六然居資料室 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-12.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36vw; margin:2.9vw 0 0 13.3vw" src="../assets/Unit3-1/20230704-3-1-物件-04-pic2-放大文物.png" alt="大型演講現場" title="大型演講現場">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 21.5vw; width: 20.9vw;">
            <div >
              <div class="bold-title">1933年7月23日 地方自治聯盟大型演講會 </div>
              <div class="noto-text" style="margin-top: 0.4vw">六然居資料室 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-13.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:29.7vw; margin:2.5vw 0 0 16.9vw" src="../assets/Unit3-1/20230704-3-1-物件-08-pic3-放大文物.png"  alt="留學生團講演會" title="留學生團講演會">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 18.5vw; width: 26.8vw;">
            <div >
              <div class="bold-title">臺灣文化協會新竹分部主辦「留學生團講演會」宣傳單</div>
              <div class="noto-text" style="margin-top: 0.4vw">國立臺灣歷史博物館 館藏 2003.016.0105</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-14.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:27.2vw; margin:2.5vw 0 0 18vw" src="../assets/Unit3-1/20230704-3-1-物件-09-pic4-放大文物.png"  alt="我的希望" title="我的希望">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 23vw; width: 17.1vw;">
            <div >
              <div class="bold-title">楊肇嘉〈我的希望〉唱片 1935年</div>
              <div class="noto-text" style="margin-top: 0.4vw">徐登芳 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-15.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.2vw; margin:2.5vw 0 0 13.5vw" src="../assets/Unit3-1/20230704-3-1-物件-10-pic5-放大文物.png" alt="我的希望" title="我的希望">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 23vw; width: 17.1vw;">
            <div >
              <div class="bold-title">楊肇嘉〈我的希望〉講詞 1935年</div>
              <div class="noto-text" style="margin-top: 0.4vw">六然居資料室 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-1/sample/20230411-對位-3-1-16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.2vw; margin:2.5vw 0 0 13.5vw" src="../assets/Unit3-1/20230704-3-1-物件-11-pic6-放大文物.png"  alt="我的希望" title="我的希望">
          </div>

          <div class="content-text" style="position: absolute; top:30.7vw; left: 23vw; width: 17.1vw;">
            <div >
              <div class="bold-title">楊肇嘉〈我的希望〉講詞 1935年</div>
              <div class="noto-text" style="margin-top: 0.4vw">六然居資料室 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>

    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import Unit3BackBtn from "@/components/Unit3BackBtn";
import FooterFeature from "@/components/FooterFeature";
import LottieTest from "@/components/LottieTest";
import {nextTick, onBeforeUnmount, onMounted, onUnmounted, reactive, ref} from "vue";
import myMixin from "@/assets/common/js/mixin";
import AudioComp from "@/components/AudioComp";
import $ from "jquery";
import turn from "@/assets/utils/lib/turn";
import greatAudio from "@/assets/Unit3-4/audio/great.mp3";


export default {
  name: "Unit3-1",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    Unit3BackBtn,
    FooterFeature,
    LottieTest,
    AudioComp,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));
    const showText = reactive(Array.from({ length: 2 }, () => false));

    const book1 = ref(require('@/assets/Unit3-1/10-pic5-翻書動態/10-pic5-翻頁效果-01.png'));

    const mapNum = ref(-1);
    const barStyle = reactive(Array.from({ length: 25 }, () => ''));
    const mapPage = ref(0);
    let progress = [5,7,10.5,9.8,9.6,
                    8.5,11,14,0,0,
                    7.5,9,12.5,7,8,
                    3,5.5,12,12.5,6.5,
                    1.3,0,2,6,2.5];

    const flipAudio = require('@/assets/Unit3-1/insert_paper.mp3');
    const flipEle = new Audio(flipAudio);


    function onTurn(){


      $("#flipbook").turn({
        acceleration:true,
        gradients: true,
        autoCenter: true,
        height:646,
        width:896,
        direction: "rtl"
      });

      $('#flipbook').turn('size', '100%', '100%');
      $("#flipbook").bind("turning", function(event, page, view) {
        bookPage.value = page;
        flipEle.play();

      });

    }


    onMounted(()=>{

      //$("#flipbook").turn("destroy");
      if ($('#flipbook').turn('is')) {
        $("#flipbook").turn("destroy");
      }
      onTurn();


      window.addEventListener('resize', ()=>{
        $('#flipbook').turn('size', 'auto', '100%');
      });

    });



    function chartAnim(){
      for (let i=0; i<barStyle.length; i++){
        barStyle[i] = 'height:' + progress[i] + "vw;";
      }
    }

    function mapBtn(num){
      mapNum.value = num;
      mapPage.value = 1;

      for (let i=0; i<barStyle.length; i++){
        barStyle[i] = 'height:0vw;';
      }

      setTimeout(()=>{
        chartAnim();
      }, 300);
    }

    function routeBtn(path){
      router.push(path);
    }

    let bookId = null;
    let bookIndex = 1;
    const bookPage = ref(1);
    const booking = ref(false);
    function bookRightBtn(){
      $("#flipbook").turn("next");
      // if(bookPage.value == 3){
      //   return;
      // }
      // bookPage.value += 1;

      return;
      if(booking.value || bookPage.value == 2) return;

      booking.value = true;
      bookIndex = 1;

      if(bookPage.value == 0){
        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = (bookIndex + 1) % (59+1);

          book1.value = require('@/assets/Unit3-1/10-pic5-翻書動態/10-pic5-翻頁效果-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex >=  59){
            //book1Style.value = 'opacity:0;';
            clearInterval(bookId);
            bookPage.value=1;
            booking.value = false;
          }

        }, 50);
      }else if(bookPage.value==1){

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = (bookIndex + 1) % (59+1);

          book1.value = require('@/assets/Unit3-1/11-pic6-翻書動態/11-pic6-翻頁效果-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex >=  59){
            //book1Style.value = 'opacity:0;';
            clearInterval(bookId);
            bookPage.value=2;
            booking.value = false;
          }

        }, 50);
      }


    }

    function bookLeftBtn(){
      $("#flipbook").turn("previous");
      // if(bookPage.value == 1){
      //   return;
      // }
      // bookPage.value -= 1;

      return;
      if(booking.value || bookPage.value == 0) return;
      booking.value = true;

      if(bookPage.value == 1){
        bookIndex = 59;

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = bookIndex-1;

          book1.value = require('@/assets/Unit3-1/10-pic5-翻書動態/10-pic5-翻頁效果-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex <= 1){
            clearInterval(bookId);
            bookPage.value=0;
            booking.value = false;
          }

        }, 50);
      }else if(bookPage.value==2){
        bookIndex = 59;

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = bookIndex-1;

          book1.value = require('@/assets/Unit3-1/11-pic6-翻書動態/11-pic6-翻頁效果-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex <= 1){
            clearInterval(bookId);
            bookPage.value=1;
            booking.value = false;
          }

        }, 50);
      }

    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      book1,
      bookRightBtn,
      bookLeftBtn,
      bookPage,
      booking,
      mapNum,
      barStyle,
      mapPage,
      mapBtn,
      showImg,
      showText,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit3-1.css"></style>
