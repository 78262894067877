<template>
  <div class="wrap" >
    <toolbar type="2" unit="5" route-name="personal-info" color="brown"></toolbar>
<!--    <img class="sample" src="../assets/common/sample/20231101-網站-共用按鈕-or-0-個人資料蒐集.jpg">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="content-text">
      <div class="title">國立臺灣歷史博物館《樂為世界人》網站</div>
      <div class="title" style="margin-top: 0.5vw">個人資料蒐集/處理/利用之告知及同意</div>
      <div style="margin-top: 1.4vw">當您瀏覽《樂為世界人》網站（以下簡稱本網站）或者提供您的個人資料給本網站時，為遵循法令，國立臺灣歷史博物館（以下簡稱本館）謹依個人資料保護法第8條規定，告知以下事項：</div>

      <div class="subtitle" style="margin-top: 2.2vw"><span class="number2">一‧</span>瀏覽行為之分析之告知：</div>
        <p>本網站有安裝使用Google Analytics （Google分析）進行使用者行為分析，因此當您進入本網站以及在本網站進行瀏覽時，Google將會蒐集到包含：您的瀏覽器類型、裝置類型、作業系統、瀏覽的網頁內容與停留時間等資訊，Google會將這些資訊整理作出分析報表再提供本網站，將有助於本網站改善既有服務與開發新服務，以及提供您未來更好的瀏覽體驗。但上述蒐集與分析均不會揭露任何足以辨識您個人身分的資料。</p>
        <p>在您瀏覽本網站之過程中，如有涉及提供您的個人資料之情形（例如、但不限於：上傳資料、張貼留言、填寫表單、反映意見…等），則同時適用貳、之個資蒐集、處理、利用之告知。</p>

      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">二‧</span>個資蒐集、處理、利用之告知：</div>
      <div class="content">
        <p class="relative"><span class="number">1.</span>蒐集目的</p>
        <div class="item">
          <p class="relative"><span class="number">‧</span>為進行/會員及管理與服務、行銷、契約管理、電子商務服務、內部的統計調查與研究分析以及其他經營合於營業登記項目或組織章程所定業務之需要（法定特定目的項目標號：040,067,069,090,148,157）。</p>
          <p class="relative"><span class="number">‧</span>前述行銷，包括本網站現有及未來陸續產生或辦理之所有產品與服務之宣傳與廣告，並包括本網站與第三人合作辦理之各項產品與服務及其行銷推廣活動。</p>
        </div>
        <p class="relative" style="margin-top: 0.4vw"><span class="number">2.</span>蒐集之個人資料類別</p>
        <div class="item">
          <p class="relative"><span class="number">‧</span>C001辨識個人者：如姓名、職稱、國民身分證編號、相片、電話（住家、工作電話及手機號碼）、住址（住家、戶籍及工作地址）、電子郵遞地址、網路平台帳號等資訊。</p>
          <p class="relative"><span class="number">‧</span>C002辨識財務者：如金融機構帳戶號碼與姓名。</p>
          <p class="relative"><span class="number">‧</span>C011個人描述及C021家庭情形：如性別、國籍、出生年月日、婚姻狀況等。</p>
          <p class="relative"><span class="number">‧</span>其他依主管機關公告之個人資料類別。</p>
        </div>
        <p class="relative" style="margin-top: 0.4vw"><span class="number">3.</span>利用期間、地區、對象及方式</p>
        <div class="item">
          <p class="relative"><span class="number">‧</span>期間：本網站營運期間。</p>
          <p class="relative"><span class="number">‧</span>地區：本網站執行業務所及之國內外地區。</p>
          <p class="relative"><span class="number">‧</span>利用之對象：國立臺灣歷史博物館及本網站（如有涉及行銷、金流或物流，則包括提供該等行銷或金流物流服務之合作或協力廠商）。</p>
          <p class="relative"><span class="number">‧</span>利用之方式：得以紙本、電子文件、電子郵件傳送或其他合於當時科技之適當方式利用之。</p>
        </div>
        <p class="relative" style="margin-top: 0.4vw"><span class="number">4.</span>提供者之權利</p>
        <p>您提供個人資料予本網站者，依個資法得行使以下權利：</p>
        <div class="item">
          <p class="relative"><span class="number">‧</span>查詢或請求閱覽。</p>
          <p class="relative"><span class="number">‧</span>請求製給複製本。</p>
          <p class="relative"><span class="number">‧</span>請求補充或更正。</p>
          <p class="relative"><span class="number">‧</span>請求停止蒐集、處理或利用。</p>
          <p class="relative"><span class="number">‧</span>請求刪除。</p>
        </div>
        <p>※您進行上述請求時應以書面方式行使之，來信請寄本館地址：臺南市安南區長和路一段250號。</p>

        <p class="relative" style="margin-top: 0.4vw"><span class="number">5.</span>本網站不會將您提供的資料與內容用於不屬於以上目的，或超出本網站範圍之用途，也不會未經您同意而逕自向非相關第三人或組織提供您的個人資料。但若中華民國政府基於司法調查，需要取用您提供的資料，本網站得配合政府請求而提供。</p>
      </div>


      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">三‧</span>資料之機密性與安全性</div>
      <p>在本網站蒐集、處理、利用您個人資料之過程中，本網站將盡力採取符合當前科技水準的防護措施，保護您個人資料的機密性與安全性，以防止遭到竊取、盜用或不當使用。</p>

      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">四‧</span>上述告知條款之效力及解釋，以中華民國（臺灣）之法律為準據法。因上述告知條款所生之爭議，如有訟訴之必要，您同意以臺灣臺南地方法院為第一審管轄法院。</div>

      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">五‧</span>本人於使用本網站時，即代表已閱讀、瞭解並同意上述各項告知及約定記載。</div>
    </div>

    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import Toolbar from "@/components/Toolbar";
import FooterFeature from "@/components/FooterFeature";

export default {
  name: "PrivacyView",
  components:{
    Toolbar,
    FooterFeature
  },
  setup(){


    return{

    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Privacy.css"></style>
