<template>
  <div class="wrap" @keydown.esc="escEvent">
    <!--    <toolbar type="1" style="position: fixed; z-index: 10; top:4.5%;"></toolbar>-->
    <entoolbar type="1" unit="1" route-name="unit1-2" color="brown"></entoolbar>

    <!--    <img class="sample" src="../assets/Unit1-2/sample2.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 ">
      <img class="bg-1" src="../assets/Unit1-2/bg-1.png">
      <div class="title-logo">
        <p>The World Rocked</p>
        <p style="margin-top: -1vw;letter-spacing: 0.217vw;">by New Thinking</p>
      </div>

      <model-viewer
          ref="modelViewerRef"
          tabindex="-1" alt="globe" title="globe"
          src="/Globe2.glb"
          autoplay
      ></model-viewer>

      <div class="model-btn">
        <img v-if="modelPlaying==true" @click="modelBtn(false)" @keydown.enter="modelBtn(false)" tabindex="0" class="w-full cursor-pointer" src="../assets/common/202300920-78-暫停鍵.png" alt="stop button" title="stop button">
        <img v-if="modelPlaying==false" @click="modelBtn(true)" @keydown.enter="modelBtn(true)" tabindex="0" class="w-full cursor-pointer" src="../assets/common/202300920-79-播放鍵.png" alt="paly button" title="play button">
      </div>

      <div class="people-1" alt="crowd" title="crowd">
        <LottieTest :json="require('@/assets/Unit1-2/1-1-1.json')" ></LottieTest>
      </div>
    </div>
    <div class="part2 ">
      <img class="globe" src="../assets/Unit1-2/globe.png" alt="globe" title="globe">
      <div class="content">
        <p>Taiwan is over here</p>
        <p>A globe from Japanese period.</p>
        <p>On loan from Formosa Vintage Museum T2018.001.3215</p>
        <p>This wooden globe from the Japanese period refers to Taiwan as “New Japan”, reflecting a perspective of the emerging new world at the time.</p>
      </div>
      <div class="point" @click="globeImg=!globeImg"  @keydown.enter="globeImg=!globeImg" tabindex="0" alt="Click to see picture" title="Click to see picture">
        <div></div>

        <img class="globe-img" :class="globeImg==true?'globe-img-open':''" src="../assets/Unit1-2/globe-img.png" alt="Taiwan is over here" title="Taiwan is over here">

        <!--        <div class="globe-img">-->
        <!--        </div>-->
      </div>
      <img class="zoom-btn"  @click="showGlobeImg=true" @keydown.enter="showGlobeImg=true" tabindex="0" src="../assets/Unit1-2/20230629-77-3D-icon.png" alt="pop-up window" title="pop-up window">

      <transition name="img-fade-div">
        <div class="model-btn" v-if="showGlobeImg">
          <img v-if="modelPlaying2==true" @click="modelBtn2(false)" @keydown.enter="modelBtn2(false)" tabindex="0" class="w-full cursor-pointer" src="../assets/common/202300920-78-暫停鍵.png" alt="stop button" title="stop button">
          <img v-if="modelPlaying2==false" @click="modelBtn2(true)" @keydown.enter="modelBtn2(true)" tabindex="0" class="w-full cursor-pointer" src="../assets/common/202300920-79-播放鍵.png" alt="paly button" title="play button">
        </div>
      </transition>

    </div>

    <div class="part3 ">
      <img class="text-1" src="../assets/Unit1-2/en/20230424-EN網頁-07-裝飾字1.png" alt="The State of The World" title="The State of The World">
      <div class="content" tabindex="0">
        World War I had just ended and the League of Nations had been formed. The principle of “self-determination” proposed by President Woodrow Wilson of the United States gave people in colonies hopes of liberation. The Korean independence movement, and the anti-imperial and anti-feudal movement launched in China, became examples that inspired young people to forge ahead. The founding of the USSR set off a series of communist revolutions, and left-wing youth and proletarian movements grew stronger.
      </div>

      <img class="poster" src="../assets/Unit1-2/poster.png" title="Crowds listening to speeches during the 1919 31 Movement, the Independence Movement of Korea；Poster of the 70th anniversary of the Russian Revolution" alt="Crowds listening to speeches during the 1919 31 Movement, the Independence Movement of Korea；Poster of the 70th anniversary of the Russian Revolution">
      <div class="people-2" alt="person" title="person">
        <LottieTest :json="require('@/assets/Unit1-2/1-1-2.json')" ></LottieTest>
      </div>

      <img class="zoom-btn-1"  @click="showImg[0]=true"  @keydown.enter="showImg[0]=true" tabindex="0" src="../assets/common/zoom-btn.png" alt="pop-up window" title="pop-up window">
      <img class="zoom-btn-2"  @click="showImg[1]=true"  @keydown.enter="showImg[1]=true" tabindex="0" src="../assets/common/zoom-btn.png" alt="pop-up window" title="pop-up window">
    </div>

    <div class="part4 ">
      <img class="map" src="../assets/Unit1-2/map.png" alt="world map" title="world map">

      <transition name="start-panel-fade" >
        <div class="start-panel" v-show="showStartPanel" >
          <div class="circle">
            <div>
              <p>Wake up!</p>
            </div>
            <div>
              <p>See how new ideas affected the world.</p>
              <p><span style="border-bottom: 0.15vw solid white; ">Click on place</span> for details of the event.</p>
              <p><span style="border-bottom: 0.15vw solid white; ">Click on year</span> to see the development of the new ideas spread.</p>
            </div>

            <img @click="showStartPanel=false; yearBtn(0)" @keydown.enter="showStartPanel=false; yearBtn(0)" tabindex="0" class="circle-close" src="../assets/Unit1-1/close.png" alt="close" title="close">
          </div>
        </div>
      </transition>

      <div class="map-point p-mexico" :class="mapPointClass[0]" @click="mapBtn([-1,-1,-1,18,-1,-1])" @keydown.enter="mapBtn([-1,-1,-1,18,-1,-1])" :tabindex="mapPointClass[0]==''?-1:0"><div class="inner-point"></div><p>Mexico</p></div>
      <div class="map-point p-us" :class="mapPointClass[1]" @click="mapBtn([-1,2,13,-1,-1,-1])" @keydown.enter="mapBtn([-1,2,13,-1,-1,-1])" :tabindex="mapPointClass[1]==''?-1:0"><div class="inner-point"></div><p>USA</p></div>
      <div class="map-point p-finland" :class="mapPointClass[2]" @click="mapBtn([-1,3,-1,-1,-1,-1])" @keydown.enter="mapBtn([-1,3,-1,-1,-1,-1])" :tabindex="mapPointClass[2]==''?-1:0"><div class="inner-point"></div><p>Finland</p></div>
      <div class="map-point p-switzerland" :class="mapPointClass[3]" @click="mapBtn([-1,-1,-1,20,-1,-1])" @keydown.enter="mapBtn([-1,-1,-1,20,-1,-1])" :tabindex="mapPointClass[3]==''?-1:0"><div class="inner-point"></div><p>Switzerland</p></div>
      <div class="map-point p-hungary" :class="mapPointClass[4]" @click="mapBtn([-1,5,16,-1,-1,-1])" @keydown.enter="mapBtn([-1,5,16,-1,-1,-1])" :tabindex="mapPointClass[4]==''?-1:0"><div class="inner-point"></div><p style="left: 160%">Hungary</p></div>
      <div class="map-point p-ireland" :class="mapPointClass[5]" @click="mapBtn([-1,9,14,-1,-1,23])" @keydown.enter="mapBtn([-1,9,14,-1,-1,23])" :tabindex="mapPointClass[5]==''?-1:0"><div class="inner-point"></div><p>Ireland</p></div>
      <div class="map-point p-turkey" :class="mapPointClass[6]" @click="mapBtn([-1,-1,24,24,24,24])" @keydown.enter="mapBtn([-1,-1,24,24,24,24])" :tabindex="mapPointClass[6]==''?-1:0"><div class="inner-point"></div><p style="left: 170%">Turkey</p></div>
      <div class="map-point p-germany" :class="mapPointClass[7]" @click="mapBtn([-1,10,17,-1,-1,-1])" @keydown.enter="mapBtn([-1,10,17,-1,-1,-1])" :tabindex="mapPointClass[7]==''?-1:0"><div class="inner-point"></div><p>Germany</p></div>
      <div class="map-point p-bulgaria" :class="mapPointClass[8]" @click="mapBtn([-1,8,-1,-1,-1,-1])" @keydown.enter="mapBtn([-1,8,-1,-1,-1,-1])" :tabindex="mapPointClass[8]==''?-1:0"><div class="inner-point"></div><p style="left: 250%">Bulgaria</p></div>
      <div class="map-point p-poland" :class="mapPointClass[9]" @click="mapBtn([-1,7,-1,-1,-1,-1])" @keydown.enter="mapBtn([-1,7,-1,-1,-1,-1])" :tabindex="mapPointClass[9]==''?-1:0"><div class="inner-point"></div><p>Poland</p></div>
      <div class="map-point p-india" :class="mapPointClass[10]" @click="mapBtn([-1,6,-1,19,-1,-1])" @keydown.enter="mapBtn([-1,6,-1,19,-1,-1])" :tabindex="mapPointClass[10]==''?-1:0"><div class="inner-point"></div><p>India</p></div>
      <div class="map-point p-russia" :class="mapPointClass[11]" @click="mapBtn([0,-1,11,-1,-1,-1])"  @keydown.enter="mapBtn([0,-1,11,-1,-1,-1])" :tabindex="mapPointClass[11]==''?-1:0"><div class="inner-point"></div><p>The Soviet Union</p></div>
      <div class="map-point p-china" :class="mapPointClass[12]" @click="mapBtn([-1,-1,12,-1,21,-1])" @keydown.enter="mapBtn([-1,-1,12,-1,21,-1])" :tabindex="mapPointClass[12]==''?-1:0"><div class="inner-point"></div><p>China</p></div>
      <div class="map-point p-hongkong" :class="mapPointClass[13]" @click="mapBtn([-1, 1,-1,-1,-1,-1])" @keydown.enter="mapBtn([-1, 1,-1,-1,-1,-1])" :tabindex="mapPointClass[13]==''?-1:0"><div class="inner-point"></div><p>Hong Kong</p></div>
      <div class="map-point p-korean" :class="mapPointClass[14]" @click="mapBtn([-1,-1,15,-1,-1,-1])" @keydown.enter="mapBtn([-1,-1,15,-1,-1,-1])" :tabindex="mapPointClass[14]==''?-1:0"><div class="inner-point"></div><p>Korea</p></div>
      <div class="map-point p-japan" :class="mapPointClass[15]" @click="mapBtn([-1,4,-1,-1, 22,-1])" @keydown.enter="mapBtn([-1,4,-1,-1, 22,-1])" :tabindex="mapPointClass[15]==''?-1:0"><div class="inner-point"></div><p>Japan</p></div>


      <map-info language="en" @close-event="mapClose" :show="showArr[0]" name="The Soviet Union" year="1917" pos="top:23.2%;left:68.2%" content="The October Revolution results in the Soviet Socialist Republic." :img="require('@/assets/Unit1-2/map-info/20230329-39-1917蘇聯.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[1]" name="Hong Kong" year="1918" pos="top:49.2%;left:75.2%" content="Rice riots erupt in July." :img="require('@/assets/Unit1-2/map-info/20230329-42-1918香港.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[2]" name="USA" year="1918" pos="top:36.2%;left:30.8%" content="After WW1, Wilson proposes the 14 Points and the principles for self-determination." :img="require('@/assets/Unit1-2/map-info/20230329-49-1918美國.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[3]" name="Finland" year="1918" pos="top:23.2%;left:53.5%" content="Finland’s independence leads to civil war between the left and right." :img="require('@/assets/Unit1-2/map-info/20230329-44-1918芬蘭.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[4]" name="Japan" year="1918" pos="top:49.2%;left:77.3%" content="The Rice Riots break out around Japan." :img="require('@/assets/Unit1-2/map-info/20230329-41-1918日本.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[5]" name="Hungary" year="1918" pos="top:37.2%;left:51.3%" content="The Chrysanthemum Revolution results in the establishing of the first Republic of Hungary." :img="require('@/assets/Unit1-2/map-info/20230329-46-1918匈牙利.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[6]" name="India" year="1918" pos="top:48.2%;left:65.7%" content="Gandhi promotes a movement that calls for civil disobedience and non-violent non-cooperation." :img="require('@/assets/Unit1-2/map-info/20230329-43-1918印度.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[7]" name="Poland" year="1918" pos="top:30.8%;left:51.7%" content="The Revival of Polish Independence Movement bring about the Republic of Poland." :img="require('@/assets/Unit1-2/map-info/20230329-40-1918波蘭.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[8]" name="Bulgaria" year="1918" pos="top:39.8%;left:52.7%" content="Rebellious troops around the country force the government to seek peace with the Allies." :img="require('@/assets/Unit1-2/map-info/20230329-47-1918保加利亞.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[9]" name="Ireland" year="1918" pos="top:30.2%;left:45.3%" content="Irish Parliament does not recognize sovereignty of British Commonwealth of Nations." :img="require('@/assets/Unit1-2/map-info/20230329-48-1918愛爾蘭.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[10]" name="Germany" year="1918" pos="top:33.2%;left:50.1%" content="Strikes and revolts occur throughout country, a parliamentary republic is established, the German Empire falls." :img="require('@/assets/Unit1-2/map-info/20230329-45-1918德國.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[11]" name="The Soviet Union" year="1919" pos="top:23.2%;left:68.2%" content="Lenin establishes the Communist Third International, headquarters established in Moscow." :img="require('@/assets/Unit1-2/map-info/20230329-52-1919蘇聯.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[12]" name="China" year="1919" pos="top:42.2%;left:74.3%" content="May 4th Movement breaks out, intellectuals stir up nationalist movements." :img="require('@/assets/Unit1-2/map-info/20230329-51-1919中國.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[13]" name="USA" year="1919" pos="top:36.2%;left:30.8%" content="Large strikes launched by workers in the steel and meat industries, race riots erupt in Chicago and elsewhere." :img="require('@/assets/Unit1-2/map-info/20230329-57-1919美國.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[14]" name="Ireland" year="1919" pos="top:30.2%;left:45.3%" content="Irish Republican Army launches guerilla-style war of independence." :img="require('@/assets/Unit1-2/map-info/20230329-56-1919愛爾蘭.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[15]" name="Korea" year="1919" pos="top:40.2%;left:78.3%" content="Supporters of independence demonstrate and issue a declaration of independence in Seoul during independence movement of Korea known as the 31 Movement.<br>Provisional Government of the Republic of Korea founded in Shanghai, China." :img="require('@/assets/Unit1-2/en/20230424-EN網頁-53-1919朝鮮.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[16]" name="Hungary" year="1919" pos="top:37.2%;left:51.3%" content="Communist revolution overturns government, establishes the Hungarian Soviet Republic (lasts four months)." :img="require('@/assets/Unit1-2/map-info/20230329-55-1919匈牙利.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[17]" name="Germany" year="1919" pos="top:33.2%;left:50.1%" content="Weimar Republic established in Germany.<br>Soviet Republic of Bavaria established and overthrown." :img="require('@/assets/Unit1-2/map-info/20230329-54-1919德國.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[18]" name="Mexico" year="1920" pos="top:51.2%;left:23.7%" content="Railroad workers strike setting off wave of anti-government sentiment, President Carranza is assassinated." :img="require('@/assets/Unit1-2/map-info/20230329-60-1920墨西哥.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[19]" name="India" year="1920" pos="top:48.2%;left:65.7%" content="The Indian National Congress transformed into a mass-based political party, promotes independence movement." :img="require('@/assets/Unit1-2/map-info/20230329-58-1920印度.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[20]" name="Switzerland" year="1920" pos="top:42.2%;left:30.9%" content="The League of Nations is established to maintain world peace." :img="require('@/assets/Unit1-2/map-info/20230329-59-1920瑞士.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[21]" name="China" year="1921" pos="top:42.2%;left:74.3%" content="Chinese Communist Party established in Shanghai." :img="require('@/assets/Unit1-2/en/20230424-EN網頁-61-1921中國.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[22]" name="Japan" year="1921" pos="top:49.2%;left:77.3%" content="Min Wen-shik assassinated." :img="require('@/assets/Unit1-2/map-info/20230329-62-1921日本.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[23]" name="Ireland" year="1922" pos="top:30.2%;left:45.3%" content="The Irish Free State established as a self-governing territory of the British Empire." :img="require('@/assets/Unit1-2/map-info/20230329-63-1922愛爾蘭.png')"></map-info>
      <map-info language="en" @close-event="mapClose" :show="showArr[24]" name="Turkey" year="1919-23" pos="top:40.2%;left:54.3%" content="Turkish Nationalist Movement (1919-23) leads to the establishment of the Turkish Republic." :img="require('@/assets/Unit1-2/map-info/20230329-50-1918土耳其.png')" type=2></map-info>


      <div class="timeline-wrap">
        <img class="bg" src="../assets/Unit1-2/timeline.png">
        <div class="year-btn year-btn-1" :class="curYear=='1917'?'active':''" @click="yearBtn(0)" @keydown.enter="yearBtn(0)" tabindex="0"><div>1917</div></div>
        <div class="year-btn year-btn-2" :class="curYear=='1918'?'active':''" @click="yearBtn(1)" @keydown.enter="yearBtn(1)" tabindex="0"><div>1918</div></div>
        <div class="year-btn year-btn-3" :class="curYear=='1919'?'active':''" @click="yearBtn(2)" @keydown.enter="yearBtn(2)" tabindex="0"><div>1919</div></div>
        <div class="year-btn year-btn-4" :class="curYear=='1920'?'active':''" @click="yearBtn(3)" @keydown.enter="yearBtn(3)" tabindex="0"><div>1920</div></div>
        <div class="year-btn year-btn-5" :class="curYear=='1921'?'active':''" @click="yearBtn(4)" @keydown.enter="yearBtn(4)" tabindex="0"><div>1921</div></div>
        <div class="year-btn year-btn-6" :class="curYear=='1922'?'active':''" @click="yearBtn(5)" @keydown.enter="yearBtn(5)" tabindex="0"><div>1922</div></div>
      </div>




    </div>

    <div class="part5 ">
      <div class="content">
        <p>Underground revolution in Taiwan?</p>
        <p>Independent News (Distributed in Shanghai) Dec. 13, 1922</p>
        <p>Courtesy of the National Museum of Korean Contemporary History</p>
        <p>Korea was under Japanese rule, and the Korean independence movement publication “Independent News”, published in Shanghai, reported on Taiwan’s anti-Japanese organizations. The article mentions a leader of the anti-Japanese movement by the name of “Lin Chien-tang” (Probably referring to Lin Hsien-tang), saying that he opposed the governor-general’s dictatorship and that he promoted the autonomy movement, while secretly recruiting troops. In addition, the destruction of an imperial monument in Changhua suggested the imminent eruption of revolution in Taiwan. The article uses slurs when referring to Japanese officials and General Kitashirakawanomiya Yoshihisa.</p>
      </div>

      <div class="pic-1" v-show="part5Img==1">
        <img class="img" src="../assets/Unit1-2/pic-img-3.png" alt="Independent News (Distributed in Shanghai) Dec. 13, 1922" title="Independent News (Distributed in Shanghai) Dec. 13, 1922">
        <img class="zoom-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" src="../assets/Unit1-2/zoom-btn.png" alt="more information" title="more information">
        <img class="right-arrow" @click="part5Img=2" @keydown.enter="part5Img=2" tabindex="0" src="../assets/common/right-arrow.png" alt="next page" title="next page">
      </div>

      <div class="pic-2" v-show="part5Img==2">
        <img class="img" src="../assets/Unit1-2/pic-img-4.png" alt="Independent News (Distributed in Shanghai) Dec. 13, 1922" title="Independent News (Distributed in Shanghai) Dec. 13, 1922">
        <img class="zoom-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" src="../assets/Unit1-2/zoom-btn.png" alt="more information" title="more information">
        <img class="left-arrow" @click="part5Img=1" @keydown.enter="part5Img=1" tabindex="0" src="../assets/common/left-arrow.png" alt="previous page" title="previous page">
      </div>
    </div>

    <div class="part6 ">
      <div class="part6-wrap">
        <div class="content">
          <img src="../assets/Unit1-2/en/20230424-EN網頁-64-裝飾字2.png" alt="Young People Rise Up" title="Young People Rise Up">
          <p tabindex="0">In the wake of Hsieh Wen-ta’s air shows, Li Ying-cheung, Wu Hai-shui, Ho Li-dong and other students from the Taiwan Governor-General’s Medical College planned to form a youth group that promoted the cultural advancement of Taiwanese. In line with global trends, Chiang Wei-shui, a physician practicing in Dadaocheng, encouraged the organization’s expansion and connected people from all over Taiwan who shared the same opinion.During the same period, Taiwanese Youth published by the Society of New People (organized by young Taiwanese in Tokyo) eagerly asked: “Taiwan’s youth! Takasago’s youth! Can we still be silent?”</p>
        </div>

        <div class="book" alt="Taiwanese Youth" title="Taiwanese Youth">
          <LottieTest :json="require('@/assets/Unit1-2/1-1-3.json')" ></LottieTest>
        </div>

      </div>
    </div>

    <div class="part7 ">
      <div class="unit-btn-wrap">
        <div class="unit-btn" @click="routeBtn('/en/unit1-1')" @keydown.enter="routeBtn('/en/unit1-1')" tabindex="0">
          <img src="../assets/Unit1-1/unit1-1.png" alt="Skyview of the World" title="Skyview of the World">
          <div ><p>Skyview of</p><p>the World</p></div>
        </div>
        <div class="unit-btn" style="width: 16vw" @click="routeBtn('/en/unit1-2')" @keydown.enter="routeBtn('/en/unit1-2')" tabindex="0">
          <img src="../assets/Unit1-1/202300801-69-思潮滾動世界按鈕-淡.png"  alt="The World Rocked by New Thinking" title="The World Rocked by New Thinking">
          <div ><p>The World Rocked</p><p>by New Thinking</p></div>
        </div>
      </div>
    </div>


    <!--寫這裡-->
    <!--img0--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos">
      <div class="img-frame-div"  v-if="showGlobeImg">
        <!--              <img style="position: fixed; top: 0%; left: 0%; z-index: 10; opacity: 0.5" src="../assets/Unit1-2/20230629&#45;&#45;1-18-pic彈跳視窗.png">-->
        <div class="black-bg" ></div>
        <div class="frame">
          <!--          <img class="relative" style="width:18.2vw; margin:2.71vw 0 0 22.8vw" src="../assets/Unit1-2/globe.png">-->
          <div class="content-text" style="top: 34vw;left: 50%;">
            <div class="title">A globe from Japanese period.</div>
            <div class="subtitle">On loan from Formosa Vintage Museum T2018.001.3215</div>
          </div>

          <model-viewer
              ref="modelViewerRef2"
              src="/Globe2.glb"
              autoplay
              :camera-controls="true"
          ></model-viewer>
          <div class="feature">
            <!--              <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">-->
            <!--              <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">-->
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showGlobeImg=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img0--><!--hor-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--         <img style="position: fixed; top: 0%; left: 0%; z-index: 10; opacity: 0.5" src="../assets/Unit1-2/20230629&#45;&#45;1-18-pic彈跳視窗.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full"  :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:24.5vw; margin:4.3vw 0 0 13.6vw" src="../assets/Unit1-2/20230629-18-pic.png" alt="Crowds listening to speeches during the 1919 31 Movement, the Independence Movement of Korea" title="Crowds listening to speeches during the 1919 31 Movement, the Independence Movement of Korea">
          </div>

          <div class="content-text" style="top: 12%; left: 90%;width: 50%">
            <div class="bold-title-b">Crowds listening to speeches during the 1919 31 Movement, the Independence Movement of Korea</div>
            <div class="noto-text">Courtesy of the National Museum of Korean Contemporary History</div>
          </div>
          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>

        </div>
      </div>
    </transition>
    <!--img1--><!--hor-->
    <transition name="img-frame-fade" @before-enter="resetPos"  >
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--         <img style="position: fixed; top: 0%; left: 0%; z-index: 10; opacity: 0.5" src="../assets/Unit1-2/20230629-1-1-11-pic彈跳視窗.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full"  :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:40.7vw; margin:4.5vw 0 0 -3.5vw" src="../assets/Unit1-2/20230629-11-pic.png" alt="Poster of the 70th anniversary of the Russian Revolution" title="Poster of the 70th anniversary of the Russian Revolution">
          </div>

          <div class="content-text" style="top: 12%; left: 90%;width: 50%">
            <div class="bold-title">Poster of the 70th anniversary of the Russian Revolution</div>
            <div class="noto-text">Courtesy of Zhu Jiahuang</div>
            <div class="sub-title" style="text-align: left">The Russian text in the upper left corner of this red poster reads “Liberate the World, All Power to the Soviets and the Land”. The right side of the poster reads “Mir vrede beke” (German: I want peace), Paz (Portuguese: peace), Mir Hòa Bình (Vietnamese: peace), Paix frieden (German: freedom), Pokój (Polish: peace)</div>
          </div>
          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>

        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos"  >
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--    <img style="position: fixed; top: 0%; left: 0%; z-index: 10; opacity: 0.5" src="../assets/Unit1-2/20230629-1-1-20-pic彈跳視窗.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full"  :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:24.8vw; margin:0vw 0 0 19.5vw" src="../assets/Unit1-2/20230629-20-pic-放大文物.png" alt="Independent News (Distributed in Shanghai) Dec. 13, 1922" title="Independent News (Distributed in Shanghai) Dec. 13, 1922">
          </div>
          <div class="content-text">
            <div>
              <div class="title">Independent News (Distributed in Shanghai) Dec. 13, 1922</div>
              <div class="subtitle">Courtesy of the National Museum of Korean Contemporary History</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos"  >
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--         <img style="position: fixed; top: 0%; left: 0%; z-index: 10; opacity: 0.5" src="../assets/Unit1-2/20230629-1-1-21-pic彈跳視窗.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full"  :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:59.2vw; margin:3vw 0 0 3.2vw" src="../assets/Unit1-2/20230629-21-pic-放大文物.png" alt="Independent News (Distributed in Shanghai) Dec. 13, 1922" title="Independent News (Distributed in Shanghai) Dec. 13, 1922">
          </div>
          <div class="content-text">
            <div >
              <div class="title">Independent News (Distributed in Shanghai) Dec. 13, 1922</div>
              <div class="subtitle">Courtesy of the National Museum of Korean Contemporary History</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')" >
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>


    <en-intro-btn ></en-intro-btn>

    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import EnIntroBtn from "@/components/EnIntroBtn";
import router from "@/router";
import LottieTest from "@/components/LottieTest";
import { ModelViewer } from '@google/model-viewer';
import {onUnmounted, reactive, ref} from "vue";
import MapInfo from "@/components/MapInfo";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin"


export default {
  name: "Unit1-2",
  mixins: [myMixin],
  components:{
    EnIntroBtn,
    Entoolbar,
    LottieTest,
    ModelViewer,
    MapInfo,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();

    const globeImg = ref(false);
    const showGlobeImg = ref(false);
    const showImg = reactive(Array.from({ length: 10 }, () => false));
    const part5Img = ref(1);

    const mapPointClass = reactive(Array(16).fill(''));
    const showArr = reactive(Array(25).fill('false'));
    const curYear = ref('1917');

    const showStartPanel = ref(true);
    const modelViewerRef = ref(null);
    const modelPlaying = ref(true);
    const modelViewerRef2 = ref(null);
    const modelPlaying2 = ref(true);

    let animArr = [
      [0],
      [6,2,3,1,4,8,7,5,10,9],
      [14,11,15,16,12,24,13,10],
      [24,19,20,18],
      [24,22,21],
      [24,23]
    ]
    let animIndex = 0;
    let mapAnimInterval = null;


    function routeBtn(path){
      router.push(path);
    }

    //yearBtn(0);

    function yearBtn(index){
      mapPointClass.forEach((item, index) => {mapPointClass[index] = "";});

      switch (index){
        case 0:
          curYear.value = '1917';
          mapPointClass[11] = 'map-point-active';
          break;
        case 1:
          curYear.value = '1918';
          mapPointClass[9] = 'map-point-active';
          mapPointClass[15] = 'map-point-active';
          mapPointClass[13] = 'map-point-active';
          mapPointClass[10] = 'map-point-active';
          mapPointClass[2] = 'map-point-active';
          mapPointClass[7] = 'map-point-active';
          mapPointClass[4] = 'map-point-active';
          //mapPointClass[6] = 'map-point-active';
          mapPointClass[8] = 'map-point-active';
          mapPointClass[5] = 'map-point-active';
          mapPointClass[1] = 'map-point-active';
          break;
        case 2:
          curYear.value = '1919';
          mapPointClass[12] = 'map-point-active';
          mapPointClass[11] = 'map-point-active';
          mapPointClass[14] = 'map-point-active';
          mapPointClass[7] = 'map-point-active';
          mapPointClass[4] = 'map-point-active';
          mapPointClass[5] = 'map-point-active';
          mapPointClass[1] = 'map-point-active';
          mapPointClass[6] = 'map-point-active';
          break;
        case 3:
          curYear.value = '1920';
          mapPointClass[10] = 'map-point-active';
          mapPointClass[3] = 'map-point-active';
          mapPointClass[0] = 'map-point-active';
          mapPointClass[6] = 'map-point-active';
          break;
        case 4:
          curYear.value = '1921';
          mapPointClass[15] = 'map-point-active';
          mapPointClass[12] = 'map-point-active';
          mapPointClass[6] = 'map-point-active';
          break;
        case 5:
          curYear.value = '1922';
          mapPointClass[5] = 'map-point-active';
          mapPointClass[6] = 'map-point-active';
          break;

      }

      animIndex = 0;
      clearInterval(mapAnimInterval);
      mapAnimInterval = setInterval(mapAnim, 3000);
    }

    onUnmounted(()=>{
      clearInterval(mapAnimInterval);
    });

    function mapAnim(){
      /*暫時關閉倫波動畫*/
      return;

      let curIndex = 0;

      mapClose();
      switch (curYear.value){
        case '1917': curIndex = 0; break;
        case '1918': curIndex = 1; break;
        case '1919': curIndex = 2; break;
        case '1920': curIndex = 3; break;
        case '1921': curIndex = 4; break;
        case '1922': curIndex = 5; break;
      }

      let next = animIndex;
      if(next >= animArr[curIndex].length){
        next = 0;
        animIndex = 0;

        switch (curYear.value){
          case '1917': curYear.value='1918'; curIndex = 1; break;
          case '1918': curYear.value='1919'; curIndex = 2; break;
          case '1919': curYear.value='1920'; curIndex = 3; break;
          case '1920': curYear.value='1921'; curIndex = 4; break;
          case '1921': curYear.value='1922'; curIndex = 5; break;
          case '1922': curYear.value='1917'; curIndex = 0; break;
        }
      }

      showArr[animArr[curIndex][next]] = 'true';
      animIndex++;
    }
    function mapBtn(mapArr){
      mapClose();
      let curIndex = -1;
      switch (curYear.value){
        case '1917': curIndex = 0; break;
        case '1918': curIndex = 1; break;
        case '1919': curIndex = 2; break;
        case '1920': curIndex = 3; break;
        case '1921': curIndex = 4; break;
        case '1922': curIndex = 5; break;
      }
      if(curIndex != -1){
        showArr[mapArr[curIndex]] = 'true';
      }

      clearInterval(mapAnimInterval);
      mapAnimInterval = setInterval(mapAnim, 5000);
    }

    function mapClose(){
      showArr.forEach((arr, index)=>{
        showArr[index] = 'false';
      });
    }

    function escEvent(){
      showGlobeImg.value = false;
      for (let i=0; i<showImg.length;i++)
        showImg[i] = false;
    }

    function modelBtn(playing){
      if(playing){
        modelViewerRef.value.play();
      }else{
        modelViewerRef.value.pause();
      }
      modelPlaying.value = playing;
    }

    function modelBtn2(playing){
      if(playing){
        modelViewerRef2.value.play();
      }else{
        modelViewerRef2.value.pause();
      }
      modelPlaying2.value = playing;
    }

    return{
      routeBtn,
      globeImg,
      showGlobeImg,
      mapPointClass,
      yearBtn,
      curYear,
      mapBtn,
      showArr,
      mapClose,
      showStartPanel,
      part5Img,
      showImg,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent,
      modelViewerRef,
      modelPlaying,
      modelBtn,
      modelViewerRef2,
      modelPlaying2,
      modelBtn2
    }

  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit1-2.css"></style>
<style scoped>


.test-btn{
  position: absolute;
//background: black;
  width: 30vw;
  height: 18vw;
  bottom: 4%;
  left: 14%;
  cursor: pointer;
}


</style>
