<template>
  <div class="wrap">
    <toolbar type="1" style="position: fixed; z-index: 10; top:4.5%; "></toolbar>

    <img class="sample" src="../assets/Unit2-5/sample.png">

    <div class="test-btn unit-btn-2-1" @click="routeBtn('/unit2-1')"></div>
    <div class="test-btn unit-btn-2-2" @click="routeBtn('/unit2-2')"></div>
    <div class="test-btn unit-btn-2-3" @click="routeBtn('/unit2-3')"></div>
    <div class="test-btn unit-btn-2-4" @click="routeBtn('/unit2-4')"></div>
    <div class="test-btn unit-btn-2-5" @click="routeBtn('/unit2-5')"></div>

    <intro-btn></intro-btn>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";

export default {
  name: "Unit2-2",
  components:{
    toolbar,
    IntroBtn
  },
  setup(){

    function routeBtn(path){
      router.push(path);
    }

    return{
      routeBtn
    }
  }
}
</script>

<style scoped>

.wrap{
  position: relative;
  width: 100%;
}

.sample{
  position: relative;
  width: 100%;
}

.test-btn{
  position: absolute;
  //background: black;
  //opacity: 0.5;
  width: 25vw;
  height: 18vw;
  cursor: pointer;
}

.unit-btn-2-1{
  bottom: 7.7%;
  left: 10%;
}

.unit-btn-2-2{
  bottom: 7.7%;
  left: 39.5%;
}

.unit-btn-2-3{
  bottom: 7.7%;
  left: 67.5%;
}

.unit-btn-2-4{
  bottom: 1.5%;
  left: 26.5%;
}

.unit-btn-2-5{
  bottom: 1.5%;
  left: 55%;
}
</style>
