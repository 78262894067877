<template>
  <div  ref="container"></div>
</template>

<script>
import lottie from "lottie-web";
//import jsonData from "@/assets/Unit1-1/01.json";

export default {
  name:"LottieOnce",
  props:{
    json:String,
    autoplay: {
      type: Boolean,
      default: true,
    }
  },
  data(){
      return{
        anim:null
      }
  },
  mounted() {


    this.anim = lottie.loadAnimation({
      container: this.$refs.container,
      renderer:'svg',
      loop:false,
      autoplay:false,
      animationData:this.json,

    });

    if(this.autoplay == true){
      this.anim.play();
    }
  }
}
</script>

<style scoped>

</style>
