<template>
  <div class="wrap"  @mousemove="handleMouseMove" @keydown.esc="escEvent">
<!--    <toolbar type="1" unit="3" route-name="unit3-4/game" ></toolbar>-->

<!--    <img class="sample" src="../assets/Unit3-4/sample/20230413-對位-3-4-22.png">-->

    <div class="part5">
      <transition name="img-frame-img">
        <div class="json-1" v-if="page!='result'">
          <LottieTest :json="require('@/assets/Unit3-4/3-4-3.json')" alt="Farmers" title="Farmers"></LottieTest>
        </div>
      </transition>

      <transition name="img-frame-fade">
        <div class="content-1" v-if="page=='select'">
        <P class="content-style">Begin by choosing either "Two-Character Collection" or "Three-Character Collection". As the text appears in sync with the audio, try to gather the corresponding "Two-Character Collection" or "Three-Character Collection”!</P>
        <p class="subtitle">Select the Music</p>
        <div class="btn-div">
          <div class="blue-small-btn" @click="page='game'; gameMode=0;" @keydown.enter="page='game'; gameMode=0;" tabindex="0" alt="Two-Character Collection" title="Two-Character Collection">Two-Character Collection</div>
          <div class="blue-small-btn" @click="page='game'; gameMode=1;" @keydown.enter="page='game'; gameMode=1;" tabindex="0" alt="Three-Character Collection" title="Three-Character Collection">Three-Character Collection</div>
        </div>
      </div>
      </transition>

      <transition name="img-frame-fade">
        <div class="right-info" v-if="page=='game'">
          <div class="time-div">00:{{timeText}}</div>
          <progress class="score-progress" :value="curCount" :max="maxCount"></progress>
          <div class="start-div">
            <transition name="img-frame-fade"><img v-if="startCount>=3" src="../assets/Unit3-4/20230413-3-4-物件-34-星星2.png"></transition>
            <transition name="img-frame-fade"><img v-if="startCount>=2" src="../assets/Unit3-4/20230413-3-4-物件-34-星星2.png"></transition>
            <transition name="img-frame-fade"><img v-if="startCount>=1" src="../assets/Unit3-4/20230413-3-4-物件-34-星星2.png"></transition>
          </div>
        </div>
      </transition>

      <transition name="img-frame-fade">
        <div class="game" v-if="gameMode==0">
        <img class="pan" src="../assets/Unit3-4/20230413-3-4-物件-32-插畫32.png" alt="picture" title="picture">
        <div class="source">
          <p>Written by the Taiwan Red Aid Society (Chen De-xing, Chen Jie)</p>
          <p>Read by Huang Yu-yuan</p>
          <p>Pronunciation verified by Lu Xing-chang</p>
        </div>


        <div class="blue-btn" v-if="gameStart==false" style="top: 36vw; left: 44vw" @click="startBtn" @keydown.enter="startBtn" tabindex="0" alt="START" title="START">START</div>
        <div class="blue-btn" v-if="gameStart==true" style="top: 36vw; left: 44vw" @click="collectBtn" @keydown.enter="collectBtn" tabindex="0" alt="Collect" title="Collect">Collect</div>

        <div class="text-row">
          <div class="text-group" v-for="item in lyrics" :key="item" :style="'left:' + item.left + 'vw; top:' + item.top + 'vw;'">{{item.text}}</div>
        </div>

        <div class="great-text" v-if="status=='great'">GOOD</div>
        <div class="miss-text" v-if="status=='miss'">MISSED</div>

        <audio ref="audio"
               @timeupdate="updateTime"
               @loadedmetadata="loadAudio(0)"
        >
          <source  src="../assets/Unit3-4/audio/04-雙字集唸謠.mp3" type="audio/mpeg">
        </audio>
      </div>
      </transition>

      <transition name="img-frame-fade">
        <div class="game" v-if="gameMode==1">
        <img class="pan" src="../assets/Unit3-4/20230413-3-4-物件-32-插畫32.png" alt="picture" title="picture">
        <div class="source">
          <p>Written by the Taiwan Red Aid Society (Chen De-xing, Chen Jie)</p>
          <p>Read by Huang Yu-yuan</p>
          <p>Pronunciation verified by Lu Xing-chang</p>
        </div>
<!--        <div class="right-info">-->
<!--          <div class="time-div">00:35</div>-->
<!--          <progress class="score-progress" :value="50" :max="100"></progress>-->
<!--          <div class="start-div">-->
<!--            <img src="../assets/Unit3-4/20230413-3-4-物件-34-星星2.png">-->
<!--            <img src="../assets/Unit3-4/20230413-3-4-物件-34-星星2.png">-->
<!--            <img src="../assets/Unit3-4/20230413-3-4-物件-34-星星2.png">-->
<!--          </div>-->
<!--        </div>-->

        <div class="blue-btn" v-if="gameStart==false" style="top: 36vw; left: 44vw" @click="startBtn2" @keydown.enter="startBtn2" tabindex="0" alt="START" title="START">START</div>
        <div class="blue-btn" v-if="gameStart==true" style="top: 36vw; left: 44vw" @click="collectBtn2" @keydown.enter="collectBtn2" tabindex="0" alt="Collect" title="Collect">Collect</div>

        <div class="text-row">
          <div class="text-group" v-for="item in lyrics2" :key="item" :style="'left:' + item.left + 'vw; top:' + item.top + 'vw;'">{{item.text}}</div>
        </div>

        <div class="great-text" v-if="status=='great'">GOOD</div>
        <div class="miss-text" v-if="status=='miss'">MISSED</div>

        <audio ref="audio2"
               @timeupdate="updateTime2"
               @loadedmetadata="loadAudio(1)"
        >
          <source  src="../assets/Unit3-4/audio/05-三字集唸謠.mp3" type="audio/mpeg">
        </audio>
      </div>
      </transition>

      <transition name="img-frame-fade">
          <div class="result" v-if="page=='result'">
            <div class="img-1" v-if="startCount<=1">
              <img class="w-full" src="../assets/Unit3-4/20230413-3-4-物件-43-插圖62.png" alt="picture" title="picture">
              <div class="title">Beginner Balladeer</div>
              <div class="content">
                <p>You only got one star,</p>
                <p>but you can do better!</p>
                <p>Practice more and work your way</p>
                <p>towards becoming</p>
                <p>a Folk Song Expert!</p>
              </div>
              <div class="btn-div">
                <div class="blue-btn-2" @click="resetBtn" @keydown.enter="resetBtn" tabindex="0" alt="Sing it again" title="Sing it again">Sing it again</div>
                <div class="blue-btn-2" style="margin-left: 0.5vw" @click="openVideo()" @keydown.enter="openVideo()" tabindex="0" alt="Listen to the Song" title="Listen to the Song">Listen to the Song</div>
              </div>
            </div>

            <div class="img-2" v-if="startCount==2">
              <img class="w-full" src="../assets/Unit3-4/en/20230413-3-4-物件-42-插圖52.png" alt="picture" title="picture">
              <div class="title">Champion of Taiwanese Rhythm</div>

              <div class="content">
                <p style="margin-top: 0.8vw">Congratulations on getting two stars!</p>
                <p style="margin-top: 0.5vw">You weren’t quite perfect,</p>
                <p style="margin-top: 0.5vw">but your knowledge is still first rate! </p>
              </div>
              <div class="btn-div">
                <div class="blue-btn-2" @click="resetBtn" @keydown.enter="resetBtn" tabindex="0" alt="Sing it again" title="Sing it again">Sing it again</div>
                <div class="blue-btn-2" style="margin-left: 0.5vw" @click="openVideo()" @keydown.enter="openVideo()" tabindex="0" alt="Listen to the Song" title="Listen to the Song">Listen to the Song</div>
              </div>
            </div>

            <div class="img-3" v-if="startCount==3">
              <img class="w-full" src="../assets/Unit3-4/20230413-3-4-物件-41-插圖42.png" alt="picture" title="picture">
              <div class="title">Folk Song Expert</div>
              <div class="content">
                <p>Congratulations, you got three stars!</p>
                <p style="margin-top: 0.5vw">Wow, you collected every one and</p>
                <p style="margin-top: 0.5vw">didn’t miss a beat!</p>
              </div>
              <div class="btn-div">
                <div class="blue-btn-2" @click="resetBtn" @keydown.enter="resetBtn" tabindex="0" alt="Sing it again" title="Sing it again">Sing it again</div>
                <div class="blue-btn-2" style="margin-left: 0.5vw" @click="openVideo()" @keydown.enter="openVideo()" tabindex="0" alt="Listen to the Song" title="Listen to the Song">Listen to the Song</div>
              </div>
            </div>
          </div>
      </transition>


      <div class="back-btn" @click="routeBtn('/en/unit3-4')" @keydown.enter="routeBtn('/en/unit3-4')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="Back to Social Ballads" title="Back to Social Ballads">
        <p style="margin-left: 0.5vw">Back to Social Ballads</p>
      </div>

    </div>

    <transition name="img-frame-fade"><div class="video-bg"  v-if="showVideo[0]" >
      <div class="video-div" >
        <video id="video"  class="w-full" ref="videoRef" @click="playVideo()" @keydown.enter="playVideo()" tabindex="0" @pause="videoPausedEvent">
          <source src="../assets/Unit3-4/video/雙字集全.mp4" >
          type="video/mp4">
        </video>

        <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showVideo[0]=false">
      </div>
    </div></transition>
    <transition name="img-frame-fade"><div class="video-bg"  v-if="showVideo[1]">
      <div class="video-div" >
        <video id="video"  class="w-full" ref="videoRef" @click="playVideo2()" @keydown.enter="playVideo2()" tabindex="0" @pause="videoPausedEvent">
          <source src="../assets/Unit3-4/video/三字集全.mp4" >
          type="video/mp4">
        </video>

        <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showVideo[1]=false;">
      </div>
    </div></transition>
    <div class="video-touch" v-if="showVideo[0]==true || showVideo[1]==true"  @mousemove="customCursorStyle='opacity:1'"  @mouseleave="customCursorStyle='display:none'"  @click="playVideo" @keydown.enter="playVideo" tabindex="0"></div>


    <div class="custom-cursor" ref="customCursor"  :style="customCursorStyle" >
      <img class="w-full" :src="customCursorImg">
    </div>

  </div>
</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import router from "@/router";
import LottieTest from "@/components/LottieTest";
import {reactive, ref} from "vue";
import myMixin from "@/assets/common/js/mixin";
import audioSource from "@/assets/Unit3-4/audio/great.mp3";
import greatAudio from "@/assets/Unit3-4/audio/great.mp3";
import missAudio from "@/assets/Unit3-4/audio/miss.mp3";

export default {
  name: "Unit3-4",
  mixins: [myMixin],
  components:{
    EnToolbar,
    LottieTest,
  },
  setup(){
    const audio = ref();
    const audio2 = ref();
    const page = ref('select');
    const gameMode = ref(-1);
    const gameStart = ref(false);
    let offset = 5.5;
    const timeText = ref(0);
    const status = ref('');
    const lyrics =  reactive([
      {text:'農民', left:offset+4.2*0, top:0, collect:'false'},
      {text:'佃人', left:offset+4.2*1, top:0, collect:'false'},
      {text:'田租', left:offset+4.2*2, top:0, collect:'false'},
      {text:'稅金', left:offset+4.2*3, top:0, collect:'false'},
      {text:'飢餓', left:offset+4.2*4, top:3.6, collect:'false'},
      {text:'赤貧', left:offset+4.2*5, top:3.6, collect:'false'},
      {text:'非常', left:offset+4.2*6+1, top:3.6, collect:'false'},
      {text:'悲慘', left:offset+4.2*7+1, top:3.6, collect:'false'},
      {text:'尚且', left:offset+4.2*8+1, top:7.3, collect:'false'},
      {text:'可憐', left:offset+4.2*9+1, top:7.3, collect:'false'},
      {text:'一生', left:offset+4.2*10+2, top:7.3, collect:'false'},
      {text:'打拼', left:offset+4.2*11+2, top:7.3, collect:'false'},
      {text:'猶是', left:offset+4.2*12+3, top:7.3, collect:'false'},
      {text:'辛苦', left:offset+4.2*13+3, top:7.3, collect:'false'},
      {text:'販賣', left:offset+4.2*14+4, top:0, collect:'false'},
      {text:'妻子', left:offset+4.2*15+4, top:0, collect:'false'},
      {text:'到尾', left:offset+4.2*16+4, top:0, collect:'false'},
      {text:'自盡', left:offset+4.2*17+4, top:0, collect:'false'},
    ]);
    const lyrics2 =  reactive([
      {text:'無產者', left:offset+5*0, top:0, collect:'false'},
      {text:'散鄉人', left:offset+5*1, top:0, collect:'false'},
      {text:'勞働者', left:offset+5*2, top:0, collect:'false'},
      {text:'日做工', left:offset+5*3, top:0, collect:'false'},
      {text:'做不休', left:offset+5*4, top:3.6, collect:'false'},
      {text:'負債種', left:offset+5*5, top:3.6, collect:'false'},
      {text:'住破厝', left:offset+5*6+1, top:3.6, collect:'false'},
      {text:'壞門窓', left:offset+5*7+1, top:3.6, collect:'false'},
      {text:'四面壁', left:offset+5*8+2, top:7.3, collect:'false'},
      {text:'全是穴', left:offset+5*9+2, top:7.3, collect:'false'},
      {text:'無電燈', left:offset+5*10+3, top:7.3, collect:'false'},
      {text:'番油點', left:offset+5*11+3, top:7.3, collect:'false'},
    ]);
    const maxCount = ref(0);
    const curCount = ref(0);
    const startCount = ref(0);

    const videoRef = ref();
    const videoRef2 = ref();
    const customCursor = ref();
    const customCursorStyle = ref('display:none;');
    const videoPaused = ref(true);
    const customCursorImg = ref(require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png'));
    const showVideo = reactive(Array.from({ length: 2 }, () => false));
    let videoIndex = -1;

    function routeBtn(path){
      router.push(path);
    }

    let updatetime = 0;
    function updateTime(){
      let time = audio.value.currentTime*1000;
      //console.log(time)
      //let delay = parseInt((time-(updatetime+100))/100);
      if(time > (updatetime+100)){
      //if(delay > 0){
        for (let i=0; i<lyrics.length;i++){
          lyrics[i].left -= 4.2 *  (4.2/24);

          if(lyrics[i].left < -1.5 && lyrics[i].collect=='false'){
            lyrics[i].collect ='miss'
            setStatus('miss');
          }
        }
        updatetime = time;
      }
      let duration = audio.value.duration - audio.value.currentTime;
      timeText.value = parseInt(duration).toString().padStart(2, '0');
    }

    function updateTime2(){
      let time = audio2.value.currentTime*1000;
      //console.log(time)
      if(time > (updatetime+100)){
        for (let i=0; i<lyrics2.length;i++){
          lyrics2[i].left -= 4.3 *  (4.3/24);

          if(lyrics2[i].left < -4 && lyrics2[i].collect=='false'){
            lyrics2[i].collect ='miss'
            setStatus('miss');
          }
        }
        updatetime = time;
      }
      let duration = audio2.value.duration - audio2.value.currentTime;
      timeText.value = parseInt(duration).toString().padStart(2, '0');
    }

    function collectBtn(){
      let isCollect = false;
      for (let i=0; i<lyrics.length;i++){
        if(lyrics[i].left >= -3 && lyrics[i].left <= 0 && lyrics[i].collect=='false'){
          lyrics[i].collect = 'true';
          setStatus('great');
          addCount();
          isCollect = true;
        }
      }

      if(!isCollect){
        setStatus('miss');
      }
    }

    function collectBtn2(){
      let isCollect = false;

      for (let i=0; i<lyrics2.length;i++){
        if(lyrics2[i].left >= -4 && lyrics2[i].left <= 0 && lyrics2[i].collect=='false'){
          lyrics2[i].collect = 'true';
          setStatus('great');
          addCount();
          isCollect = true;
        }
      }

      if(!isCollect){
        setStatus('miss');
      }
    }

    function addCount(){
      curCount.value++;

      if(gameMode.value==0){
        if(curCount.value >= 14){
          startCount.value = 3;
        }else if(curCount.value >= 9){
          startCount.value = 2;
        }else if(curCount.value >= 5){
          startCount.value = 1;
        }
      }else if(gameMode.value==1){
        if(curCount.value >= 9){
          startCount.value = 3;
        }else if(curCount.value >= 6){
          startCount.value = 2;
        }else if(curCount.value >= 3){
          startCount.value = 1;
        }
      }
    }

    let statusTimeout = null;
    const greatAudio = require('@/assets/Unit3-4/audio/great.mp3');
    const missAudio = require('@/assets/Unit3-4/audio/miss.mp3');
    const greatElement = new Audio(greatAudio);
    const missElement = new Audio(missAudio);

    function setStatus(text){
      clearTimeout(statusTimeout);

      if(text == 'great'){
        greatElement.volume = 0.5;
        greatElement.play();
      }
      else if(text == 'miss'){
        missElement.volume = 1;
        missElement.play();
      }
      status.value = text;
      statusTimeout = setTimeout(()=>{
        status.value = '';
      }, 500);
    }

    function startBtn(){
      audio.value.play();
      gameStart.value = true;
    }

    function startBtn2(){
      audio2.value.play();
      gameStart.value = true;
    }

    function loadAudio(num){
      if(num == 0){
        timeText.value = parseInt(audio.value.duration).toString().padStart(2, '0');
        audio.value.addEventListener('ended', ()=>{
          page.value = 'result';
          videoIndex = gameMode.value;
          gameMode.value = -1;
        });
        maxCount.value = lyrics.length;
      }else{
        timeText.value = parseInt(audio2.value.duration).toString().padStart(2, '0');
        audio2.value.addEventListener('ended', ()=>{
          page.value = 'result';
          videoIndex = gameMode.value;
          gameMode.value = -1;
        });
        maxCount.value = lyrics2.length;

      }
    }

    function resetBtn(){
      location.reload();
    }

    function playVideo(){
      if(videoRef.value.paused){
        videoRef.value.play();
        //customCursor.value.style.opacity = 0;
        videoPaused.value = false;
        videoRef.value.controls = true;
        customCursorImg.value = require('../assets/common/20230704-物件-6-0-15-影片暫停鍵.png');
      }else{
        videoRef.value.pause();
        videoPausedEvent();
      }
    }

    function playVideo2(){
      if(videoRef2.value.paused){
        videoRef2.value.play();
        //customCursor.value.style.opacity = 0;
        videoPaused.value = false;
        videoRef2.value.controls = true;
        customCursorImg.value = require('../assets/common/20230704-物件-6-0-15-影片暫停鍵.png');
      }else{
        videoRef2.value.pause();
        videoPausedEvent();
      }
    }

    function videoPausedEvent(){
      customCursorImg.value = require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png');
      videoPaused.value = true;
      videoRef.value.controls = false;
      videoRef2.value.controls = false;
    }


    function handleMouseMove(event) {
      customCursor.value.style.left = (event.pageX - customCursor.value.clientWidth /2) + "px" ;
      customCursor.value.style.top = (event.pageY - customCursor.value.clientHeight /2)  + "px";
    }


    function openVideo(){
      showVideo[videoIndex] = true;
      customCursorImg.value = require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png');
    }

    function escEvent(){
      showVideo[0] = false;
      showVideo[1] = false;
    }

    return{
      routeBtn,
      gameMode,
      audio,
      audio2,
      startBtn,
      startBtn2,
      lyrics,
      updateTime,
      updateTime2,
      gameStart,
      collectBtn,
      collectBtn2,
      status,
      lyrics2,
      timeText,
      loadAudio,
      curCount,
      maxCount,
      startCount,
      page,
      resetBtn,
      videoRef,
      videoRef2,
      customCursor,
      customCursorStyle,
      playVideo,
      playVideo2,
      handleMouseMove,
      videoPaused,
      videoPausedEvent,
      customCursorImg,
      showVideo,
      openVideo,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit3-4-game.css"></style>
