<template>
  <div class="intro" @click="routeBtn('/en/person-intro-all')"  @keydown.enter="routeBtn('/en/person-intro-all')" :style="style" tabindex="0">
    <div style="width: 4.5vw; margin-left: 0vw"><img class="w-full" src="../assets/common/en/20230428EN網站-共用按鈕-物件-or-06-人物介紹按鈕.png" alt="People of Cultural Association" title="People of Cultural Association"></div>
    <div style="width: 4vw"><LottieTest :json="require('@/assets/common/people-introduce.json')" alt="People of Cultural Association" title="People of Cultural Association"></LottieTest></div>
  </div>
</template>

<script>
import router from "@/router";
import LottieTest from "@/components/LottieTest";

export default {
  name: "EnIntroBtn",
  components: {LottieTest},
  props:{
    style : String
  },
  setup(){
    function routeBtn(path){
      router.push(path);
    }

    return{
      routeBtn
    }
  }
}
</script>

<style scoped>
.intro{
  position: fixed;
  bottom: 2.5%;
  right: 2%;
  display: flex;
  flex-direction: column;
  //align-items: center;
  z-index: 10;
  //width: 2.9%;
  cursor: pointer;
}
</style>
