<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="1" unit="3" route-name="unit3-5" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit3-5/sample/20230704-對位-3-5-op-01.png">-->
<!--    <a href="http://122.117.191.93:6981" target="_blank"><div style="position: absolute; bottom: 0%; left: 0; width: 100%; height: 49.6vw; cursor: pointer"></div></a>-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-5/3-1-0.json')" ></LottieTest>
      </div>
      <img class="img-2" src="../assets/Unit3-5/20230411-3-5-物件-02-插圖１.png" alt="世界語" title="世界語">
      <div class="title-logo">世界語</div>
    </div>

    <div class="part2 part-color">
      <div class="section-1">
        <div class="content">
          <img class="logo-text" src="../assets/Unit3-5/20230411-3-5-物件-03-裝飾字1-語文革命.png" alt="語文革命" title="語文革命">
          <div class="content-style" style="width: 47%; margin-top: 3.5vw">
            <p>文協成員歷來主張許多不同的語文運動，大致上有中國白話文、臺灣話文，比較特殊、且而今較不為人知的，還有更具革命性的「世界語運動」。</p>
            <p>世界語（Esperanto）是波蘭醫生柴門霍夫（L. L. Zamenhof, 1859-1917）在1887年創造的人工語言，期待透過簡單易學的輔助語，打破國際間的語言藩籬，促進交流和相互理解。世界語的推廣可以說是一種文化運動，追求語言使用的平等自由、跨民族的教育觀點，傳達普世一家的理想。文協重要幹部連溫卿、理事蘇璧輝皆為臺灣世界語重要推手，臺南文協的核心人物莊松林，生物學家王雨卿，也都是推動世界語的前輩。當年所留下推動世界語的文獻，仍能展現徹底顛覆自我、建立世界文化的氣魄。</p>
          </div>
        </div>
              <img class="img-1" src="../assets/Unit3-5/20230411-3-5-物件-04-插圖5.png" alt="世界地圖" title="世界地圖">
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-5/3-5-1.json')" ></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <div class="content-1">
        <p class="title-style" style="color: #267c68">用世界語認識世界與臺灣</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺灣世界語學會機關誌 La Verda Ombro《綠蔭》1920年10月</p>
        <p class="source-style" style="margin-top: 0.3vw">中央研究院臺灣史研究所檔案館 提供</p>
        <p class="content-style" style="margin-top: 0.5vw">手寫簡易蒟蒻版印刷品。收錄內容從國際現狀到臺灣議會設置請願運動、從世界語創辦者柴門霍夫到印度聖雄甘地的思想、臺灣原住民傳說故事等等。</p>
        <p class="content-style">綠蔭雜誌是當時在臺發刊最久的世界語雜誌。目前傳世共39期，發行時間從1920年1月至1926年3月間。</p>
        <p class="content-style">本圖左頁描繪臺灣原住民的器物，文中稱原住民為indiĝeno en Formoso（臺灣原住民）而非當時習慣稱呼的蕃人（savaĝulo），是非常先進的概念。</p>
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-5/20230411-3-5-物件-05-pic1.png" alt="綠蔭" title="綠蔭">
        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit3-5/20230411-3-5-物件-07-pic2.png" alt="世界語教科書" title="世界語教科書">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #267c68">世界語教科書</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺灣世界語學會教育部出版世界語學習用書  1932年5月</p>
        <p class="source-style" style="margin-top: 0.3vw">中央研究院臺灣史研究所檔案館 提供</p>
        <p class="content-style" style="margin-top: 0.5vw">為連溫卿以「臺灣世界語學會教育部」名義出版的世界語學習用書。全書僅序言部分以日文呈現，其餘內文部分皆以世界語寫成。內容包括：練習問題集、世界語單字等。</p>
      </div>
      <div class="content-3">
        <p class="title-style" style="color: #267c68">一起來講世界語</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺南世界語ESP講習會合照</p>
        <p class="source-style" style="margin-top: 0.3vw">莊明正 提供</p>
        <p class="content-style" style="margin-top: 0.5vw">畢業於臺南商業補習學校、赴中國求學的莊松林（1910-1974），自述曾於1931、1935年兩度參加世界語ESP講習會。莊松林位於後排左二，前排居中者為王雨卿，左二為其日籍夫人佐伯操。</p>
      </div>
      <div class="img-3">
        <img class="w-full" src="../assets/Unit3-5/20230411-3-5-物件-08-pic3.png" alt="臺南世界語ESP講習會合照" title="臺南世界語ESP講習會合照">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%; z-index:5;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="img-4">
        <img class="w-full" src="../assets/Unit3-5/20230411-3-5-物件-09-插圖3.png" alt="一起來講世界語" title="一起來講世界語">
        <img class="zoom-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 49.5%; left: 27.4%;" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
    </div>

    <div class="part4 part-color">
<!--      <img class="img-2" src="../assets/Unit3-5/20230411-3-5-物件-16-插圖3.png">-->
<!--      <img class="img-1" @click="openUrl()" src="../assets/Unit3-5/20230411-3-5-物件-14-翻譯處.png" alt="開啟新視窗" title="開啟新視窗">-->
      <transition name="img-frame-fade" >
        <div class="circle" v-if="showStartPanel==true">
          <div>
            <p>世界語</p>
            <p>怎麼說?</p>
          </div>
          <div>
            <p>「樂為世界人」的世界語，你知道怎麼說嗎?</p>
            <p>一起來查詢吧!</p>
          </div>
          <div class="play-btn" @click="openUrl()" @keydown.enter="openUrl()" tabindex="0" alt="開始查詢" title="開始查詢">開始查詢</div>
<!--          <img @click="showStartPanel=false" class="circle-close" src="../assets/Unit1-1/close.png" >-->
        </div>
      </transition>

      <img class="img-1" src="../assets/Unit3-5/20230704-3-5-物件-25-插畫４.png" alt="世界地圖" title="世界地圖">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-5/3-5-2.json')" ></LottieTest>
      </div>
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit3-5/3-5-3.json')" ></LottieTest>
      </div>
      <div class="json-3">
        <LottieTest :json="require('@/assets/Unit3-5/20230704-3-5-4.json')" ></LottieTest>
      </div>

      <div class="back-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-green.png" alt="回廟埕拚文化" title="回廟埕拚文化">
        <p style="margin-left: 0.5vw">回廟埕拚文化</p>
      </div>
    </div>

    <!--寫這裡-->
    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--     <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-02.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:2.8vw 0 0 15vw" src="../assets/Unit3-5/20230704-3-5-物件-05-pic1-放大文物.png" alt="綠蔭" title="綠蔭">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 16.3vw; width: 31.2vw;">
            <div >
              <div class="bold-title">臺灣世界語學會機關誌 La Verda Ombro《綠蔭》1920 年 10 月</div>
              <div class="noto-text" style="margin-top: 0.3vw">中央研究院臺灣史研究所檔案館 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--                                <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-03.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:18.1vw; margin:2.8vw 0 0 22.7vw" src="../assets/Unit3-5/20230704-3-5-物件-07-pic2-放大文物.png" alt="臺灣世界語學會教育部出版世界語學習用書 1932 年 5 月" title="臺灣世界語學會教育部出版世界語學習用書 1932 年 5 月">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 17.8vw; width: 27.6vw;">
            <div >
              <div class="bold-title">臺灣世界語學會教育部出版世界語學習用書 1932 年 5 月</div>
              <div class="noto-text" style="margin-top: 0.3vw">中央研究院臺灣史研究所檔案館 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--                                        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-04.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.5vw; margin:2.9vw 0 0 14vw" src="../assets/Unit3-5/20230704-3-5-物件-08-pic3-放大文物.png" alt="臺南世界語ESP講習會合照" title="臺南世界語ESP講習會合照">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 25vw; width: 14.3vw;">
            <div >
              <div class="bold-title">臺南世界語ESP講習會合照</div>
              <div class="noto-text" style="margin-top: 0.3vw">莊明正 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--hor-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--                        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit3-5/sample/20230411-對位-3-5-05.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:20vw; margin:4.6vw 0 0 7.3vw" src="../assets/Unit3-5/20230704-3-5-物件-10-pic4-放大文物.png" alt="綠島" title="綠島">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 38.7vw; width: 29vw;">
            <div class="bold-title">《La Verda Insulo》(綠島) 第2年第2期</div>
            <div class="noto-text" style="margin-top: 0.2vw">中央研究院臺灣史研究所檔案館 提供</div>
            <div class="content-style" style="margin-top: 0.9vw; text-align: justify">1932年在臺南田町世界館成立「世界語普及會臺南支部」，成立後即籌備雜誌《Verda Insulo》之發行，由長榮中學教師、生物學者王雨卿，任編輯發行兼印刷人。臺南世界語學會所發行之雜誌。</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>

    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import {reactive, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";

export default {
  name: "Unit3-5",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));
    const showStartPanel = ref(true);

    function routeBtn(path){
      router.push(path);
    }

    function openUrl(){

        window.open("https://taiwanfuntake.com/language/#/", "_self");

    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      showStartPanel,
      openUrl,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit3-5.css"></style>
