<template>
  <div class="footer-feature-wrap" :class="logoWrapClass" v-if="footerWrap=='black'">
      <router-link class="blind-style" accesskey="B"  to="" style="top: 25%; left: -3%">:::</router-link>
      <div class="museum-logo" tabindex="0" @click="openUrl('https://www.nmth.gov.tw/')" @keydown.enter="openUrl('https://www.nmth.gov.tw/')"><img class="w-full" :src="arroowClass==''?require('../assets/common/museum-logo-black.png'):require('../assets/common/museum-logo.png')" alt="連結至國立台灣歷史博物館網站(另開新視窗)" title="連結至國立台灣歷史博物館網站(另開新視窗)"></div>
      <div class="open-btn" tabindex="0" :class="arroowClass" @click="openBtn" @keydown.enter="openBtn"  alt="開啟/收合下方選單區" title="開啟/收合下方選單區"><img class="w-full" :src="arroowClass==''?require('../assets/common/open-black.png'):require('../assets/common/open.png')" alt="開啟/收合下方選單區" title="開啟/收合下方選單區"></div>
      <div class="font-btn" tabindex="0" @click="setFontSize" @keydown.enter="setFontSize" alt="縮放字級" title="縮放字級"><img class="w-full" :src="arroowClass==''?require('../assets/common/font-black.png'):require('../assets/common/font.png')" alt="縮放字級" title="縮放字級"></div>
      <div class="share-btn" tabindex="0" alt="分享" title="分享" @click="showShare=!showShare" @keydown.enter="showShare=!showShare">
        <img class="w-full" :src="arroowClass==''?require('../assets/common/share-black.png'):require('../assets/common/share.png')" alt="分享" title="分享">

        <transition name="img-frame-fade">
          <div class="share-child" v-if="showShare">
            <img @click="shareOnLine" @keydown.enter="shareOnLine" tabindex="0" class="w-full" :src="arroowClass==''?require('../assets/common/line-black.png'):require('../assets/common/line.png')" alt="LINE通訊軟體分享(另開新視窗)" title="LINE通訊軟體分享(另開新視窗)">
            <img @click="shareOnTwitter" @keydown.enter="shareOnTwitter" tabindex="0" class="w-full" :src="arroowClass==''?require('../assets/common/x-black.png'):require('../assets/common/x.png')" alt="Twitter社群分享(另開新視窗)" title="Twitter社群分享(另開新視窗)">
            <img @click="shareOnFacebook" @keydown.enter="shareOnFacebook" tabindex="0" class="w-full" :src="arroowClass==''?require('../assets/common/fb-black.png'):require('../assets/common/fb.png')" alt="Facebook通訊軟體分享(另開新視窗)" title="Facebook通訊軟體分享(另開新視窗)">
          </div>
        </transition>
      </div>
  </div>

  <div class="footer-feature-wrap" :class="logoWrapClass" v-if="footerWrap=='white'">
    <router-link class="blind-style" accesskey="B"  to="" style="top: 25%; left: -3%">:::</router-link>
      <div class="museum-logo" tabindex="0" @click="openUrl('https://www.nmth.gov.tw/')" @keydown.enter="openUrl('https://www.nmth.gov.tw/')"><img class="w-full" src="../assets/common/museum-logo.png" alt="連結至國立台灣歷史博物館網站(另開新視窗)" title="連結至國立台灣歷史博物館網站(另開新視窗)"></div>
      <div class="open-btn" tabindex="0" :class="arroowClass"  @click="openBtn" @keydown.enter="openBtn" alt="開啟/收合下方選單區" title="開啟/收合下方選單區"><img class="w-full" src="../assets/common/open.png" alt="開啟/收合下方選單區" title="開啟/收合下方選單區"></div>
    <div class="font-btn" tabindex="0" @click="setFontSize" @keydown.enter="setFontSize"  alt="縮放字級" title="縮放字級"><img class="w-full" src="../assets/common/font.png" alt="縮放字級" title="縮放字級"></div>
    <div class="share-btn" tabindex="0" alt="分享" title="分享" @click="showShare=!showShare" @keydown.enter="showShare=!showShare">
      <img class="w-full" src="../assets/common/share.png" alt="分享" title="分享">

      <transition name="img-frame-fade" >
        <div class="share-child" v-if="showShare">
          <img @click="shareOnLine" @keydown.enter="shareOnLine" tabindex="0" class="w-full" src="../assets/common/line.png" alt="LINE通訊軟體分享(另開新視窗)" title="LINE通訊軟體分享(另開新視窗)">
          <img @click="shareOnTwitter" @keydown.enter="shareOnTwitter" tabindex="0" class="w-full" src="../assets/common/x.png" alt="Twitter社群分享(另開新視窗)" title="Twitter社群分享(另開新視窗)">
          <img @click="shareOnFacebook" @keydown.enter="shareOnFacebook" tabindex="0" class="w-full" src="../assets/common/fb.png" alt="Facebook通訊軟體分享(另開新視窗)" title="Facebook通訊軟體分享(另開新視窗)">
        </div>
      </transition>
    </div>
  </div>





  <div class="footer-feature-wrap" :class="logoWrapClass" v-if="footerWrap=='black' & language=='en'">
    <router-link class="blind-style" accesskey="B"  to="" style="top: 25%; left: -3%">:::</router-link>
    <div class="museum-logo" tabindex="0" @click="openUrl('https://www.nmth.gov.tw/')" @keydown.enter="openUrl('https://www.nmth.gov.tw/')"><img class="w-full" :src="arroowClass==''?require('../assets/common/museum-logo-black.png'):require('../assets/common/museum-logo.png')" alt="Link to the National Museum of Taiwan History webside(open in a new window)" title="Link to the National Museum of Taiwan History webside(open in a new window)"></div>
    <div class="open-btn" tabindex="0" :class="arroowClass" @click="openBtn" @keydown.enter="openBtn"  alt="Open / close footer content" title="Open / close footer content"><img class="w-full" :src="arroowClass==''?require('../assets/common/open-black.png'):require('../assets/common/open.png')" alt="Open / close footer content" title="Open / close footer content"></div>
    <div class="font-btn" tabindex="0" @click="setFontSize" @keydown.enter="setFontSize" alt="zoom the font size" title="zoom the font size"><img class="w-full" :src="arroowClass==''?require('../assets/common/font-black.png'):require('../assets/common/font.png')" alt="zoom the font size" title="zoom the font size"></div>
    <div class="share-btn" tabindex="0" alt="sharing" title="sharing" @click="showShare=!showShare" @keydown.enter="showShare=!showShare">
      <img class="w-full" :src="arroowClass==''?require('../assets/common/share-black.png'):require('../assets/common/share.png')" alt="sharing" title="sharing">

      <transition name="img-frame-fade">
        <div class="share-child" v-if="showShare">
          <img @click="shareOnLine" @keydown.enter="shareOnLine" tabindex="0" class="w-full" :src="arroowClass==''?require('../assets/common/line-black.png'):require('../assets/common/line.png')" alt="share to Line(open in a new window)" title="share to Line(open in a new window)">
          <img @click="shareOnTwitter" @keydown.enter="shareOnTwitter" tabindex="0" class="w-full" :src="arroowClass==''?require('../assets/common/x-black.png'):require('../assets/common/x.png')" alt="share to Twitter(open in a new window)" title="share to Twitter(open in a new window)">
          <img @click="shareOnFacebook" @keydown.enter="shareOnFacebook" tabindex="0" class="w-full" :src="arroowClass==''?require('../assets/common/fb-black.png'):require('../assets/common/fb.png')" alt="share to Facebook(open in a new window)" title="share to Facebook(open in a new window)">
        </div>
      </transition>
    </div>
  </div>

  <div class="footer-feature-wrap" :class="logoWrapClass" v-if="footerWrap=='white'& language=='en'">
    <router-link class="blind-style" accesskey="B"  to="" style="top: 25%; left: -3%">:::</router-link>
    <div class="museum-logo" tabindex="0" @click="openUrl('https://www.nmth.gov.tw/')" @keydown.enter="openUrl('https://www.nmth.gov.tw/')"><img class="w-full" src="../assets/common/museum-logo.png" alt="Link to the National Museum of Taiwan History webside(open in a new window)" title="Link to the National Museum of Taiwan History webside(open in a new window)"></div>
    <div class="open-btn" tabindex="0" :class="arroowClass"  @click="openBtn" @keydown.enter="openBtn" alt="Open / close footer content" title="Open / close footer content"><img class="w-full" src="../assets/common/open.png" alt="Open / close footer content" title="Open / close footer content"></div>
    <div class="font-btn" tabindex="0" @click="setFontSize" @keydown.enter="setFontSize"  alt="zoom the font size" title="zoom the font size"><img class="w-full" src="../assets/common/font.png" alt="zoom the font size" title="zoom the font size"></div>
    <div class="share-btn" tabindex="0" alt="sharing" title="haring" @click="showShare=!showShare" @keydown.enter="showShare=!showShare">
      <img class="w-full" src="../assets/common/share.png" alt="sharing" title="sharing">

      <transition name="img-frame-fade" >
        <div class="share-child" v-if="showShare">
          <img @click="shareOnLine" @keydown.enter="shareOnLine" tabindex="0" class="w-full" src="../assets/common/line.png" alt="share to Line(open in a new window)" title="share to Line(open in a new window)">
          <img @click="shareOnTwitter" @keydown.enter="shareOnTwitter" tabindex="0" class="w-full" src="../assets/common/x.png" alt="share to Twitter(open in a new window)" title="share to Twitter(open in a new window)">
          <img @click="shareOnFacebook" @keydown.enter="shareOnFacebook" tabindex="0" class="w-full" src="../assets/common/fb.png" alt="share to Facebook(open in a new window)" title="share to Facebook(open in a new window)">
        </div>
      </transition>
    </div>
  </div>







  <div class="footer-bg" :class="bgClass">
    <div style="width:17.5vw;">
      <div>{{ language=='ch' ? '聯絡資訊':'Contact' }}</div>
      <div>{{ language=='ch' ? '電話':'Tel'}} | {{contactJson['phone']}}</div>
      <div>{{ language=='ch' ? '服務信箱':'Email'}} | {{contactJson['email']}}</div>
      <div>{{ language=='ch' ? '地   址':'Address'}} | {{ language=='ch' ? contactJson['address_cn']: contactJson['address_en']}}</div>
      <div>{{ language=='ch' ? '網   站':'Website'}} | {{contactJson['website']}}</div>
    </div>
    <div style="width:16vw;">
      <div class="cursor-pointer" @click="routeBtn('/privacy')" @keydown.enter="routeBtn('/privacy')" tabindex="0">{{ language=='ch' ? '隱私權及安全政策宣示':'Privacy Policy & Security Policy'}}</div>
      <div class="cursor-pointer" @click="routeBtn('/copyright')" @keydown.enter="routeBtn('/copyright')" tabindex="0">{{ language=='ch' ? '著作權聲明':'Copyright Statement'}}</div>
      <div class="cursor-pointer" @click="routeBtn('/personal-info')" @keydown.enter="routeBtn('/personal-info')" tabindex="0">{{ language=='ch' ? '個資保護':'PDNC'}}</div>
      <div>{{ language=='ch' ? '©️國立臺灣歷史博物館版權所有':'Copyright National Museum of Taiwan History All'}}</div>
      <div v-if="language=='en'">© 2021 National Museum of Taiwan History.</div>
      <div>{{ language=='ch' ? '最佳瀏覽狀態 : 螢幕解析1920x1080':'Best view：1920x1080'}}</div>
<!--      <div>{{ language=='ch' ? '訪客人數 : '+count:'Visitors ： '+count}}</div>-->
    </div>
    <div class="marks" v-if="language=='ch'">
      <img style="width: 5vw" src="../assets/common/mark-1.png" @click="openUrl('https://accessibility.moda.gov.tw/Applications/Detail?category=20230922181558')" @keydown.enter="openUrl('https://accessibility.moda.gov.tw/Applications/Detail?category=20230922181558')" tabindex="0" alt="無障礙網站（另開新視窗）" title="無障礙網站（另開新視窗）">
<!--      <img style="width:2vw; margin-left: 0.9vw" src="../assets/common/mark-2.png" @click="openUrl('https://www.gov.tw/Default.aspx')" @keydown.enter="openUrl('https://www.gov.tw/Default.aspx')" tabindex="0" alt="我的E政府（另開新視窗）" title="我的E政府（另開新視窗）">-->
    </div>
    <div class="marks" v-if="language=='en'">
      <img style="width: 5vw" src="../assets/common/mark-1.png" @click="openUrl('https://accessibility.moda.gov.tw/Applications/Detail?category=20230922181558')" @keydown.enter="openUrl('https://accessibility.moda.gov.tw/Applications/Detail?category=20230922181558')" tabindex="0" alt="Web Accessibility Guidelines 2.0 Approbal(open in a new window)" title="Web Accessibility Guidelines 2.0 Approbal(open in a new window)">
<!--      <img style="width:2vw; margin-left: 0.9vw" src="../assets/common/mark-2.png" @click="openUrl('https://taiwan.gov.tw')" @keydown.enter="openUrl('https://taiwan.gov.tw')" tabindex="0" alt="Taiwan.gov.tw(open in a new window)" title="Taiwan.gov.tw(open in a new window)">-->
    </div>
  </div>

</template>

<script>

import {onMounted, reactive, ref} from "vue";
import router from "@/router";
import axios from "axios";

export default {
  name: "FooterFeature",
  props:{
    color:{
      type:String,
      default:'black',
    },
    language:{
      type:String,
      default: 'ch'
    }
  },
  setup(props){

    const logoWrapClass = ref('');
    const bgClass = ref('');
    const arroowClass = ref('');
    const footerWrap = ref('black');
    const showShare = ref(false);
    const count = ref('');
    const contactJson = reactive({});

    function openBtn(){
      if(logoWrapClass.value == ''){
        logoWrapClass.value = 'footer-feature-wrap-open';
        bgClass.value = 'footer-bg-open';
        arroowClass.value = 'arrow-open';
      }else{
        logoWrapClass.value = '';
        bgClass.value = '';
        arroowClass.value = '';
      }
    }

    function routeBtn(path){
      if(props.language == 'ch'){
        router.push(path);
      }else{
        router.push('/en'+path);
      }
    }

    function findContainingDiv() {
      const imgElement = document.querySelector(".footer-feature-wrap");
      const containers = document.querySelectorAll(".part-color");

      for (const container of containers) {
        const containerRect = container.getBoundingClientRect();
        const imgRect = imgElement.getBoundingClientRect();

        if (imgRect.bottom >= containerRect.top && imgRect.bottom <= containerRect.bottom) {
          let hex = rgbToHex(window.getComputedStyle(container).backgroundColor);
          //console.log(hex);

          switch (hex){
            case "#ffffff":
              footerWrap.value = "black";
              break;
            case "#dfe5d7":
              footerWrap.value = "black";
              break;
            default:
              footerWrap.value = "white";
              break;
          }
          //break;
        }
      }

      function rgbToHex(rgb) {
        // 將RGB格式的顏色值拆分成三個部分：r, g, b
        const rgbValues = rgb.match(/\d+/g).map(Number);

        // 將十進制的 r, g, b 值轉換成十六進制
        const r = rgbValues[0].toString(16).padStart(2, '0');
        const g = rgbValues[1].toString(16).padStart(2, '0');
        const b = rgbValues[2].toString(16).padStart(2, '0');

        // 組合成 HEX 格式並返回
        return `#${r}${g}${b}`;
      }
    }


    function openUrl(url){
      window.open(url);
    }

    const shareUrl = 'https://tca100.nmth.gov.tw';

    function shareOnFacebook() {
      window.open(`https://www.facebook.com/sharer.php?u=${encodeURIComponent(shareUrl)}`);
    }

    function shareOnLine() {
      window.open(`https://line.me/R/msg/text/?${encodeURIComponent(shareUrl)}`);
    }

    function shareOnTwitter() {
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`);
    }

    function setFontSize(){
      //調整css變數
      const root = document.documentElement;
      //root.style.setProperty('--font-size', '5');
      const computedStyle = getComputedStyle(root);
      const currentFontSize = computedStyle.getPropertyValue('--font-size');
      //console.log(currentFontSize);
      if(currentFontSize == 1.1){
        root.style.setProperty('--font-size', '1');
      }else{
        root.style.setProperty('--font-size', '1.1');
      }
    }


    onMounted(() => {
      findContainingDiv();

      window.addEventListener("scroll", findContainingDiv);

      //getData();
      getContact();
    });


    async function getData(){

      if(window.firstOpen == true){
        window.firstOpen=false;
        await axios.get(window.visitorPath+'visitor-count.php?func=add')
            .then((res)=>{
                  count.value = JSON.parse(res.request.responseText).count;
                }
            );
      }else{

        await axios.get(window.visitorPath+'visitor-count.php?func=get')
            .then((res)=>{
                  count.value = JSON.parse(res.request.responseText).count;
                }
            );
      }
    }

    async function getContact() {
      try {
        const response = await axios.get(window.contactPath+'get-contact.php');
        // 將響應的數據賦值給 contactJson
        Object.assign(contactJson, response.data);
      } catch (error) {
        console.error('Error fetching contact data:', error);
      }
    }

    return{
      logoWrapClass,
      openBtn,
      bgClass,
      arroowClass,
      routeBtn,
      footerWrap,
      openUrl,
      showShare,
      shareOnFacebook,
      shareOnLine,
      shareOnTwitter,
      setFontSize,
      count,
      contactJson
    }
  }
}

</script>


<style src="../assets/css/common.css"></style>
<style scoped>
.footer-feature-wrap{
  position: fixed;
  bottom: 1.5%;
  //bottom: 10%;
  left: 2.4%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 11;
  width: 16%;
  transition: bottom 0.2s ease-in-out;
}

.footer-feature-wrap-open{
  bottom: 10%;
}

.footer-feature-wrap > div{
  cursor: pointer;
}

.footer-feature-wrap .museum-logo {
  width: 68%;
}
.footer-feature-wrap .open-btn {
  margin-left: 0.5%;
  width: 9.5%;
}
.footer-feature-wrap .font-btn {
  margin-left:0.5%;
  width: 9.5%;
}

.footer-feature-wrap .share-btn {
  position: relative;
  margin-left:0.5%;
  width: 9.5%;
}

.footer-bg{
  position: fixed;
  bottom: -12vw;
  width: 100%;
  height: 12vw;
  background: #606060;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  padding: 2vw 9vw 0 48vw;
  font-size: calc(0.7vw * var(--font-size));
  transition: bottom 0.2s ease-in-out;
}

.footer-bg-open{
  bottom: 0vw;
}

.footer-bg > div{
  text-align: left;
  line-height: 1.2vw;
}

.footer-bg .marks{
  display: flex;
}

.footer-bg .marks img{
  cursor: pointer;

}

.arrow-open{
  transform: rotate(180deg);
}


.share-child{
  position: absolute;
  top: -4.3vw;
  left: 0%;
  width: 100%;
}


</style>
