<template>
  <div class="wrap" >
    <EnToolbar type="2" unit="5" route-name="personal-info" color="brown"></EnToolbar>
<!--    <img class="sample" src="../assets/common/sample/20231117-EN網站-對位-共用按鈕-op-個資收集.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="content-text">
      <div class="title">National Museum of Taiwan History’s the website of the Blessed to be a Global Citizen – the 100th Anniversary of the Taiwan Cultural Association</div>
      <div class="title" style="margin-top: 0vw">Collection, Processing, and Use of Personal Data Notice and Consent Form</div>
      <div style="margin-top: 0.8vw">When you browse the website of the Blessed to be a Global Citizen – the 100th Anniversary of the Taiwan Cultural Association (Hereinafter “the website”) or provide your personal data to the website, in accordance with Article 8 of the Personal Data Protection Law, the National Museum of Taiwan History (Hereinafter “the museum”) informs you of the following information:</div>

      <div class="subtitle" style="margin-top: 0.2vw; left: 0.85vw"><span class="number2">1.</span>Notice of browsing behavior analysis:</div>
      <div class="content">
        <p class="relative">The website has Google Analytics installed for user behavior analysis, so when you enter and browse the website, Google will collect information including: information about your browser type, device type, operating system, content and time spent on the website. Google will compile this information and provide the website with analysis reports to help the website improve existing services and develop new services, as well as provide you with a better browsing experience in the future. However, none of the above collection and analysis will include any personally identifiable information about you.</p>
        <p>If, in the course of your visit to the website, you provide personal data (including but not limited to uploaded information, posted comments, filled out forms, and shared opinions), then "2. Information on the collection, processing, and use of personal data" below also applies.</p>
      </div>

      <div class="subtitle" style="margin-top: 0.4vw; left: 0.85vw"><span class="number2">2.</span>Information on the collection, processing, and use of personal data:</div>
      <div class="content">
        <p style="position:relative; left: 1.5vw"><span class="number">(1)</span>Purpose of collection</p>
        <div class="item">
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">a)</span>For carry out/membership and management of services, marketing, contract management, e-commerce services, internal surveys and research analysis, and other business needs consistent with the business registration program or the articles of incorporation (statutory specific purpose items no.: 040,067,069,090,148,157)</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">b)</span>The aforementioned marketing includes all existing and future promotions and advertisements of products and services generated or performed by the website, and includes all products and services and their marketing and promotional activities performed by the website in cooperation with third parties.</p>
        </div>
        <p style="position:relative; margin-top: 0.4vw; left: 1.5vw"><span class="number">(2)</span>Types of personal data collected</p>
        <div class="item">
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">a)</span>C001 For identifying individuals: Such as name, position title, national ID card number, photos, telephone numbers (home, work, and cell phone numbers), addresses (home, census register, and work addresses), e-mail address, and online platform account.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">b)</span>C002 For identifying financial conditions: Such as financial institution account number and name.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">c)</span>C011 Individual description and C021 Family condition: Such as gender, nationality, date of birth, and marital status.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">d)</span>Other categories of personal data announced by the competent authorities.</p>
        </div>
        <p style="position:relative; margin-top: 0.4vw; left: 1.5vw"><span class="number">(3)</span>Period, areas, targets, and methods of use</p>
        <div class="item">
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">a)</span>Period: The website’s service period.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">b)</span>Areas: The areas, both domestic and overseas, where the website operates.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">c)</span>Targets: National Museum of Taiwan History and the website (including partners or third parties providing relevant services, such as marketing, financial flow, or logistics.).</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">d)</span>Methods of use: Including paper, electronic documents, electronic mail transmission or other appropriate means in accordance with current technology.</p>
        </div>
        <p style="position:relative; margin-top: 0.4vw; left: 1.5vw"><span class="number">(4)</span>Rights of providers</p>
        <div class="content">
          <p class="relative" style=" left: 0.7vw">If you provide personal information to the website, you may exercise the following rights under the Personal Data Protection Law:</p>
        </div>
          <div class="item">
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">a)</span>Inquire or request a view.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">b)</span>Request a copy.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">c)</span>Request additions or corrections.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">d)</span>Request termination of collecting, processing, or using.</p>
          <p style="position:relative; left: 1.1vw"><span style=" position: absolute; top: 0vw; left: -1.1vw">e)</span>Request deletion.</p>
        </div>
        <div class="content">
          <p class="relative" style=" left: 0.7vw">※The above requests should be made in writing to No. 250, Sec. 1, Changhe Road, Annan District, Tainan City, Taiwan.</p>
        </div>
        <p style="position:relative; margin-top: 0.4vw; left: 1.5vw"><span class="number">(5)</span>The website will not use the information and content you provide for purposes other than those stated above or any outside the scope of the website, nor will the website provide your personal data to non-related third parties or organizations without your consent. However, if the government of the Republic of China requires access to information you provide for any judicial investigations, the website will cooperate with the government's request to provide the information.</p>
      </div>


      <div class="subtitle" style="margin-top: 0.4vw; left: 0.85vw"><span class="number2">3.</span>Data confidentiality and security</div>
      <div class="content">
        <p class="relative">In the course of the website's collection, processing, and use of your personal data, the website will endeavor to protect the confidentiality and security of your personal data from theft, misappropriation, or improper use by using safeguards consistent with current technology.</p>
      </div>
      <div class="subtitle" style="margin-top: 0.4vw; left: 0.85vw"><span class="number2">4.</span>The laws of the Republic of China (Taiwan) shall govern the validity and interpretation of the above notice. In the event of a dispute arising from the above notice, you agree that the Tainan District Court of Taiwan shall be the court of first instance in the event that litigation is necessary.</div>

      <div class="subtitle" style="margin-top:0.4vw; left: 0.85vw"><span class="number2">5.</span>By using this website, I acknowledge that I have read, understood, and agreed to all of the above notices and agreements.</div>
    </div>

    <FooterFeature language='en' color="black"></FooterFeature>
  </div>
</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import FooterFeature from "@/components/FooterFeature";

export default {
  name: "PrivacyView",
  components:{
    EnToolbar,
    FooterFeature
  },
  setup(){


    return{

    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/en/Privacy.css"></style>
