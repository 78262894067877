<template>
  <div class="nav-wrap" :style='style' >
<!--    <img v-if="type==1" @click="routeBtn('/')" class="logo" src="../assets/enter/logo.png" title="樂為世界人 Blessed to be a Global Citizen" alt="樂為世界人 Blessed to be a Global Citizen">-->
<!--    <img v-if="type==2" @click="routeBtn('/')" class="logo" src="../assets/logo-white.png" title="樂為世界人 Blessed to be a Global Citizen" alt="樂為世界人 Blessed to be a Global Citizen">-->
    <div class="jump-to-main" tabindex="0" @click="focusRouterLinkById('router-link-c')" @keydown.enter="focusRouterLinkById('router-link-c')" alt="Jump to the central content area" title="Jump to the central content area">Jump to the central content area</div>

    <router-link class="blind-btn btn" accesskey="U"  to="" >
      <img class="w-full" src="../assets/common/blind-btn.png" alt=":::上方選單區" title=":::上方選單區" >
    </router-link>

    <div class="title-logo" @click="routeBtn('/en/')"  @keydown.enter="routeBtn('/en')" ref="imgRef" tabindex="0">
      <img v-if="(titleLogo=='orange') || (titleLogo==''&&color=='orange')"  class="w-full" src="../assets/common/logo-1.png" alt="Blessed to be a Global citizen Homepage" title="Blessed to be a Global citizen Homepage">
      <img v-if="(titleLogo=='brown') || (titleLogo==''&&color=='brown')"  class="w-full" src="../assets/common/logo-2.png" alt="Blessed to be a Global citizen Homepage" title="Blessed to be a Global citizen Homepage">
      <img v-if="(titleLogo=='white') || (titleLogo==''&&color=='white')"  class="w-full" src="../assets/common/logo-3.png" alt="Blessed to be a Global citizen Homepage" title="Blessed to be a Global citizen Homepage">
    </div>

    <div style="position: absolute; top: 25%; right: 12%; width: 36.6vw; display: flex; justify-content: space-between;" :style="unitBtn()">
      <div class="unit-btn" @click="routeBtn('/en/unit1-1')" @keydown.enter="routeBtn('/en/unit1-1')" tabindex="0">
        <div class="unit-bar" >
          <div class="icon-space" style="height: 5vw"></div>
<!--          <div class="small-unit-btn" @click="routeBtn('/en/unit1-1')">Skyview of the World</div>-->
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit1-2')" tabindex="0"><p>The World Rocked by New Thinking</p></div>
        </div>
        <img class="w-full relative" :src="unit=='1' ? require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第一單元MENU按鈕-彩.png'):require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第一單元MENU按鈕-白.png')" alt="Skyview of the World" title="Skyview of the World">
        <div class="unit-label" style="bottom: -0.8vw">Skyview of the World</div>
      </div>
      <div class="unit-btn" @click="routeBtn('/en/unit2-0')" @keydown.enter="routeBtn('/en/unit2-0')" tabindex="0">
        <div class="unit-bar" >
          <div class="icon-space"></div>
<!--          <div class="small-unit-btn" @click="routeBtn('/en/unit2-0')"><p>The Taiwan Cultural Association</p><p>Forges Ahead</p></div>-->
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit2-1')" tabindex="0"><p>Taiwanese People's Taiwan</p></div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit2-2')" tabindex="0"><p>Advocating for Farmers and Workers</p></div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit2-3')" tabindex="0">The Left and The Right</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit2-4')" tabindex="0"><p>The Trend of Political Association</p></div>
        </div>
        <img class="w-full relative" :src="unit=='2' ? require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第二單元MENU按鈕-彩.png'):require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第二單元MENU按鈕-白.png')" alt="The Taiwan Cultural Association Forges Ahead" title="The Taiwan Cultural Association Forges Ahead">
        <div class="unit-label"><p>The Taiwan Cultural Association</p><p>Forges Ahead</p></div>
      </div>
      <div class="unit-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">
        <div class="unit-bar" >
          <div class="icon-space"></div>
<!--          <div class="small-unit-btn" @click="routeBtn('/en/enter')"><p>Activities of Taiwan Cultural</p><p>Association</p></div>-->
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit3-1')" tabindex="0">Cultural Lectures</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit3-2')" tabindex="0">Stage Performances and Films</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit3-3')" tabindex="0">Speech Forums</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit3-4')" tabindex="0">Social Ballads</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit3-5')" tabindex="0">Esperanto</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit4-1')" tabindex="0">Monument of Time</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit4-2')" tabindex="0">Cultural Bases</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit4-3')" tabindex="0">Social Forums</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit4-4')" tabindex="0">Changing Customs</div>
          <div class="small-unit-btn" @click.stop="routeBtn('/en/unit4-5')" tabindex="0">Our Own Memorial Days</div>
        </div>
        <img class="w-full relative" :src="unit=='3' ? require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第三單元MENU按鈕-彩.png'):require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第三單元MENU按鈕-白.png')" alt="Activities of Taiwan Cultural Association" title="Activities of Taiwan Cultural Association">
        <div class="unit-label"><p>Activities of Taiwan</p><p>Cultural Association</p></div>
      </div>
      <div class="unit-btn" @click="routeBtn('/en/unit6-2')" @keydown.enter="routeBtn('/en/unit6-2')" tabindex="0">
        <div class="unit-bar" >
          <div class="icon-space" style="height: 6.4vw"></div>
<!--          <div class="small-unit-btn" @click="routeBtn('/en/unit6-2')"><p>Conversation across time</p></div>-->
        </div>
        <img  class="w-full relative" :src="unit=='4' ? require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第四單元MENU按鈕-彩.png'):require('@/assets/common/20230620-臺史博-文協線上展-網站-共用按鈕-物件-or_05-第四單元MENU按鈕-白.png')" alt="Conversation Across Time" title="Conversation Across Time">
        <div class="unit-label"><p>Conversation</p><p>Across Time</p></div>
      </div>
    </div>


    <div v-if="(titleLogo==''&&(color=='brown' || color=='orange')) || titleLogo=='brown' || titleLogo=='orange' " ><en-menubar-comp :route-name="routeName"></en-menubar-comp></div>
    <div v-if="(titleLogo==''&&color=='white') || titleLogo=='white'" ><en-menubar-comp color="white" :route-name="routeName"></en-menubar-comp></div>

  </div>

</template>

<script>

import router from "@/router";
import EnMenubarComp from "@/components/EnMenubarComp";
import {onMounted, ref} from "vue";


export default {
  name: "ToolbarComponent",
  components:{
    EnMenubarComp
  },
  props:{
    type:String,
    style:String,
    unit:{
      type:String,
      default:'1',
    },
    color:{
      type:String,
      default: "orange",
    },
    routeName:{
      type:String,
      default: "",
    },
    fontColor:{
      type:String,
      default:'#4e241f',
    }
  },
  setup(props){

    const titleLogo = ref('');

    function openUrl(url, type){
      window.open(url, type);
    }

    function routeBtn(path){
      router.push(path);
    }

    function findContainingDiv() {
      const imgElement = document.querySelector(".title-logo");
      const containers = document.querySelectorAll(".part-color");

      for (const container of containers) {
        const containerRect = container.getBoundingClientRect();
        const imgRect = imgElement.getBoundingClientRect();

        if (imgRect.bottom >= containerRect.top && imgRect.bottom <= containerRect.bottom) {
          let hex = rgbToHex(window.getComputedStyle(container).backgroundColor);
          //console.log(hex);

          switch (hex){
            case "#ffffff":
              titleLogo.value = "orange";
              break;
            case "#dfe5d7":
              titleLogo.value = "brown";
              break;
            default:
              titleLogo.value = "white";
              break;
          }
          //break;
        }
      }

      function rgbToHex(rgb) {
        // 將RGB格式的顏色值拆分成三個部分：r, g, b
        const rgbValues = rgb.match(/\d+/g).map(Number);

        // 將十進制的 r, g, b 值轉換成十六進制
        const r = rgbValues[0].toString(16).padStart(2, '0');
        const g = rgbValues[1].toString(16).padStart(2, '0');
        const b = rgbValues[2].toString(16).padStart(2, '0');

        // 組合成 HEX 格式並返回
        return `#${r}${g}${b}`;
      }
    }

    function unitBtn(){
      if(titleLogo.value == ''){
        return "color:"+props.fontColor+";";
      }else{
        switch (titleLogo.value){
          case "orange":
            return "color:#4e241f;";
          case "white":
            return "color:#ffffff;";
          case "brown":
            return "color:#4e241f;";
        }
      }
    }

    onMounted(() => {
      findContainingDiv();

      window.addEventListener("scroll", findContainingDiv);
    });

    function focusRouterLinkById(id) {
      var routerLink = document.getElementById(id);

      if (routerLink) {
        routerLink.focus();
      }
    }


    return{
      openUrl,
      routeBtn,
      titleLogo,
      unitBtn,
      focusRouterLinkById,
    }
  }
}
</script>
<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/toolbar.css"></style>

<style scoped>

.nav-wrap{
  font-family: Arial;
}

.unit-btn .unit-label{
  font-family: Arial!important;
}

.unit-bar .small-unit-btn{
  font-family: Arial;
  font-size: 0.6vw;
}

.unit-bar .icon-space{
  height: 6vw;
}

.unit-bar .small-unit-btn p{
  line-height: 0.8vw;
}

.unit-btn .unit-label{
  position: absolute;
  white-space: nowrap;
  bottom: -1.8vw;
  font-size: calc(0.5vw * var(--font-size));
  line-height: 0.8vw;
}

.unit-bar{
  min-width: 10.5vw;
}

</style>
