<template>
  <div class="wrap" >
    <EnToolbar type="2" unit="5" route-name="copyright" color="brown"></EnToolbar>
<!--    <img class="sample" src="../assets/common/sample/20231107-臺史博-文協線上展-EN網站-對位-op-著作權聲明.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="content-text">
      <div class="title">Copyright Statement</div>
      <div class="subtitle" style="margin-top: 1vw"><span class="number2">1.</span>All content published on the the website of the Blessed to be a Global Citizen – the 100th Anniversary of the Taiwan Cultural Association (hereinafter referred to as “the website”), including textual narratives, pictures, photographs, audio recordings, videos, and other works, are protected by the Copyright Act, except for a few cases where the content is not protected by the Copyright Act (as exemplified in the second paragraph of this statement), whether published in the name of the museum or individual creators. Anyone wishing to use any of these protected works must obtain the prior authorization or consent of the copyright owner of the work in question, in addition to complying with the fair use provisions of the Copyright Act (as exemplified in Item 3 of this statement).</div>

      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">2.</span>Examples of situations that are not protected by the Copyright Act are as follows:</div>
      <div class="content">
        <p class="relative" style="left: 0.7vw; margin-top: 0.6vw"><span class="number">(1)</span>Cases that are not the subject of copyright under Article 9 of the Copyright Act. For example, constitutions, laws, orders, or official documents (official documents, including civil servants’ speeches, drafts, press releases), drafted in the course of their duties) are not protected by the Copyright Act and may be freely used by anyone.</p>
        <p class="relative" style="margin-top: 0.2vw; left: 0.7vw"><span class="number">(2)</span>When the duration of the copyright has expired. For example, if a photographic, audiovisual, or sound recording work has been published for more than 50 years or if the author of the linguistic or art work has been dead for more than 50 years. However, users may not infringe upon the moral rights of the author of the work and may only freely exploit his or her copyrighted works (Article 18 and 43 of the Copyright Act).</p>
      </div>

      <div class="subtitle" style="margin-top: 0.4vw"><span class="number2">3.</span>Examples of the circumstances under which the Copyright Act allows the fair use of works on this website are as follows.</div>
      <div class="content">
        <p class="relative" style="left: 0.7vw; margin-top: 0.3vw"><span class="number">(1)</span>The works published on this website in the name of the copyright owner may, within a reasonable scope, be reproduced, publicly broadcast, or publicly transmitted (Article 50 of the Copyright Act).</p>
        <p class="relative" style="margin-top: 0.1vw; left: 0.7vw"><span class="number">(2)</span>This website may be quoted within a reasonable scope for the purpose of reports, comment, teaching, research, or other legitimate purposes (Article 52 of the Copyright Act).</p>
        <p class="relative" style="margin-top: 0.1vw; left: 0.7vw"><span class="number">(3)</span>For other cases of fair use, please refer to Articles 44 to 65 of the Copyright Act.</p>
        <p class="relative" style="margin-top: 0.1vw; left: 0.7vw"><span class="number">(4)</span>All reasonable use of the works on this website shall be in accordance with the law and shall expressly state the source of the works and respect the moral rights of the author.</p>
        <p class="relative" style="margin-top: 0.1vw; left: 0.7vw"><span class="number">(5)</span>The user should ensure that his or her use complies with the fair use and related regulations of the Copyright Act. </p>
      </div>

      <div class="subtitle" style="margin-top: 0vw"><span class="number2">4.</span>Except for the cases specified in the first proviso of Article 80-1 of the Copyright Act (Where removal or alteration of electronic rights management information of the work is unavoidable in the lawful exploitation of the work given technological limitations at the time of the act.), the electronic rights management information indicated in any work on this site may not be removed or changed without permission. Any electronic information that has been unlawfully removed or changed may not be distributed, imported or held with the intent to distribute, publicly release, publicly perform, or publicly transmit.</div>


    </div>

    <FooterFeature language='en' color="black"></FooterFeature>
  </div>
</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import FooterFeature from "@/components/FooterFeature";

export default {
  name: "PrivacyView",
  components:{
    EnToolbar,
    FooterFeature
  },
  setup(){


    return{

    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/en/Privacy.css"></style>
