<template>
  <div class="wrap " :class="videoStyle">

<!--    <img style="width: 100%; position: absolute; top: 0; left: 0; opacity: 0.3; z-index: 50" src="../assets/loading/sample2.png">-->

    <div class="w-full">
      <video id="video"  class="w-full"  autoplay muted playsinline>
        <source src="../assets/loading/loading2.mp4"
                type="video/mp4">
      </video>
    </div>

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="skip-btn" @click="endAnim" @keydown.enter="endAnim" tabindex="0">Skip</div>

<!--    <div v-if="videoEnd" @click="routeBtn('/')">-->
<!--      <img class="sample" src="../assets/loading/sample.jpg">-->
<!--    </div>-->

  </div>
</template>

<script>

import router from "@/router";
import {nextTick, ref} from "vue";

export default {
  name: "LoadingView",
  components:{

  },
  setup(){

    const videoEnd = ref(false);
    const videoStyle = ref('');

    init();

    function routeBtn(path){
      router.push(path);
    }

    async function init(){
      await nextTick();

      document.getElementById('video').addEventListener('ended',()=>{
        //videoEnd.value = true;
        endAnim();
      });
    }

    function endAnim(){
      if(videoEnd.value){
        return;
      }

      videoEnd.value = true;
      sessionStorage.setItem('loading', '1');
      videoStyle.value = 'fade-out';
      setTimeout(()=>{
        routeBtn('/');
     }, 200);
    }

    return{
      routeBtn,
      videoEnd,
      videoStyle,
      endAnim
    }
  }
}
</script>

<style scoped>

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrap{
  position: relative;
  width: 100%;
}

.sample{
  position: absolute;
  width: 100%;
  top:0;
  left: 0;
  cursor: pointer;

  animation: fade-in 1s ease-in;
  -webkit-animation: fade-in 1s ease-in;
}

.skip-btn{
  position: absolute;
  top: 47%;
  right: 15%;
  width: 3vw;
  height: 3vw;
  border: 1px solid white;
  color: white;
  border-radius: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  cursor: pointer;
}

.skip-btn:hover{
  color: #EA5581;
  background: white;
}

.fade-out{
  animation: fade-in 0.2s reverse ease-in;
  -webkit-animation: fade-in 0.2s reverse ease-in;
}


</style>
