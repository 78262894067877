<template>
  <div class="wrap" @keydown.esc="escEvent">
    <Entoolbar type="1" unit="3" route-name="unit4-4" color="brown"></Entoolbar>

<!--    <img class="sample" src="../assets/Unit4-4/sample/unit4-4.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="img-1" src="../assets/Unit4-4/20230417-4-4-物件-01-首圖1.png" alt="Advertisement for the Anti-Ghost Festival Pudu Rite Pamphlet" title="Advertisement for the Anti-Ghost Festival Pudu Rite Pamphlet">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-4/4-4-0.json')" alt="Changing Customs" title="Changing Customs" ></LottieTest>
      </div>
      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2vw; top: 34%; left: 69.7%;" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
      <div class="title-logo">Changing Customs</div>
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit4-4/en/20230708-EN網站-4-4-物件-04-裝飾字1-破除陋習.png" alt="Abolish Shameful Customs" title="Abolish Shameful Customs">
      <div class="content-1 content-style">
        A substantial part of the Taiwan Cultural Association members' advocacy centered on eradicating "debased customs," which represented a comprehensive reevaluation of traditional ways of life.
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-4/4-4-1.json')" alt="Changing Customs" title="Changing Customs"></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">
      <div class="info-window" style="top: 13%; left: 9.5%;" :class="cardStatus[0]=='front'?'':'flip'">
        <div class="front" >
          <img class="img" src="../assets/Unit4-4/20230417-4-4-物件-05-翻牌1_Alpha.png" alt="Religion" title="Religion">
          <div class="content">
            <p class="title-style-s" style="color:#ea5518;">Religion</p>
            <p class="content-style" style="margin-top: 0.5vw; height: 12vw; overflow: auto">Religion has always played a central role in Taiwanese society. Extravagant god-welcoming ceremonies have usually been funded by ordinary people. Youth members of the Taiwan Cultural Association saw this as a “divine debt burden” borne by the people and felt it should be eliminated.</p>
          </div>
          <div class="blue-btn" @click="cardStatus[0]='back'" @keydown.enter="cardStatus[0]='back'" tabindex="0" style="margin-top: 0.1vw" alt="Extravagant Religious Activities" title="Extravagant Religious Activities">Extravagant Religious Activities</div>
        </div>
        <div class="back">
          <div class="content">
            <p class="title-style-s" style="color:#ea5518;">Extravagant Religious Activities</p>
            <p class="content-style" style="margin-top: 0.5vw;">Traditionally, Taiwanese religious activities were small-scale, local celebrations. However, due to transportation and commercial developments in the 1910s, these events became increasingly ostentatious, even turning into occasions for flaunting wealth. Youth members of the Taiwan Cultural Association viewed these extravagances as wasteful. They believed that not only were these activities superstitious, but they also squandered society's resources and should be simplified or abandoned entirely.</p>
            <div class="card-back-btn" @click="cardStatus[0]='front'" @keydown.enter="cardStatus[0]='front'" tabindex="0">
              <img src="../assets/common/left-arrow-green.png" alt="Back to Religion" title="Back to Religion">
              <p style="margin-left: 0.5vw">Back</p>
            </div>
          </div>
        </div>

      </div>

      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-4/20230417-4-4-物件-07-pic2.png" alt="Anti-Ghost Festival Pudu Rite Pamphlet" title="Anti-Ghost Festival Pudu Rite Pamphlet">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.3vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #ffffff">Down with all Superstitions</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Anti-Ghost Festival Pudu Rite Pamphlet</p>
        <p class="source-style" style="margin-top: 0.3vw">Courtesy of Zhuang Ming-zheng</p>
        <p class="content-style" style="margin-top: 0.5vw">In Tainan, the "Chikan Labor Youth Association" vigorously promoted the anti-Pudu movement in 1929. In September 1930, they compiled their arguments into a booklet, titled "Anti-Ghost Festival Pudu Rite Pamphlet." The pamphlet included articles by Chuang Sung-lin, Lin Xuan-ao, Lin Zhan-ao, Lin Qiu-wu, Zhu Dian-ren, and Liao Yu-wen, and was published by Xingwenzhai Bookstore. It was a representative work of the anti-Pudu movement at that time.</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-4/4-4-2.json')" alt="Religion" title="Religion"></LottieTest>
      </div>
    </div>

    <div class="part4 part-color">
      <div class="content-1">
        <p class="title-style" style="color: #ffffff">Conflict Over Parental Betrothal </p>
        <p class="subtitle-style" style="margin-top: 0.5vw"><i>Taiwan Min Pao</i> “My parents are trying to engage me to somebody. What do I do?” </p>
        <p class="source-style" style="margin-top: 0.3vw"><i>Taiwan Min Pao</i>, Issue 1, 66</p>
        <p class="content-style" style="margin-top: 0.5vw">In this piece from an advice column in the <i>Taiwan Min Pao</i>, a woman asks what she should do about her parents trying to arrange a marriage for her, reflecting the clash between the old and new.</p>
      </div>
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-4/20230417-4-4-物件-12-pic3.png" alt="Taiwan Min Pao “My parents are trying to engage me to somebody. What do I do?”" title="Taiwan Min Pao “My parents are trying to engage me to somebody. What do I do?”">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.3vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="info-window" style="top: 13%; left: 69%;" :class="cardStatus[1]=='front'?'':'flip'">
        <div class="front" >
          <img class="img" src="../assets/Unit4-4/20230417-4-4-物件-10-翻牌2_Alpha.png" alt="Marriage Customs" title="Marriage Customs">
          <div class="content">
            <p class="title-style-s" style="color:#ea5518; margin-top: -2vw">Marriage Customs</p>
            <p class="content-style" style="margin-top: 0vw; height:15.5vw; overflow: auto">In terms of marriage customs, they emphasized that parents should respect love marriages, and abolish the practice of paying a bride price. While it was argued that women should play a greater role in society, and learn new housekeeping and modern hygiene concepts, they were not encouraged to join social movements at a cost of disregarding family responsibilities</p>
          </div>
          <div class="blue-btn" style="margin-top: 0.3vw" @click="cardStatus[1]='back'" @keydown.enter="cardStatus[1]='back'" tabindex="0" alt="Transforming Marriage Customs" title="Transforming Marriage Customs">Transforming Marriage Customs</div>
        </div>
        <div class="back" >
          <div class="content">
            <p class="title-style-s" style="color:#ea5518;">Transforming Marriage Customs</p>
            <p class="content-style" style="margin-top: 0.5vw;">At that time, most marriage customs involved matchmakers arranging engagements with parents, and the bride's family receiving a dowry. High dowries were seen as akin to human trafficking, leading to advocacy for the abolition of the dowry system. Han Shi-quan and a few other members of the Taiwan Cultural Association led by example, using their own weddings as cases of "civilized marriage," which involved love marriages and simplified ceremonies.</p>
            <div class="card-back-btn" @click="cardStatus[1]='front'" @keydown.enter="cardStatus[1]='front'" tabindex="0">
              <img src="../assets/common/left-arrow-green.png" alt="Back to Marriage Customs" title="Back to Marriage Customs">
              <p style="margin-left: 0.5vw">Back</p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="part5 part-color">
      <div class="info-window" style="top: 13%; left: 9.5%;" :class="cardStatus[2]=='front'?'':'flip'">
        <div class="front" >
          <img class="img" src="../assets/Unit4-4/20230417-4-4-物件-13-翻牌3_Alpha.png" alt="Traditional Taiwanese Opera" title="Traditional Taiwanese Opera">
          <div class="content" >
            <p class="title-style-s" style="color:#ea5518;">Traditional Taiwanese Opera</p>
            <p class="content-style" style="margin-top: 0.5vw; height: 9vw">The Taiwan Cultural Association also rejected traditional opera, as the touring opera troupes that performed old-fashioned dramas were seen as a threat to good social order.</p>
          </div>
          <div class="blue-btn" style="margin-top: 1vw" @click="cardStatus[2]='back'" @keydown.enter="cardStatus[2]='back'" tabindex="0" alt="The Mass Appeal of Taiwanese Opera" title="The Mass Appeal of Taiwanese Opera">The Mass Appeal of Taiwanese Opera</div>
        </div>
        <div class="back"  >
          <div class="content" style="padding: 0 1.5vw 0 1.5vw">
            <p class="title-style-s" style="color:#ea5518;">The Mass Appeal of Taiwanese Opera</p>
            <p class="content-style" style="margin-top: 0.5vw; text-align: justify;">Taiwanese Opera, a local theatrical genre in Taiwan, was the most popular form of entertainment among the people. It expanded beyond outdoor performances, introducing ticketed indoor shows and professional troupes. Though there were other forms of theater, such as Peking Opera, which was generally considered to have greater artistic value, and the cultural dramas promoted by the Taiwan Cultural Association, none could match the lively appeal and public reception of Taiwanese Opera.</p>
            <div class="card-back-btn" @click="cardStatus[2]='front'" @keydown.enter="cardStatus[2]='front'" tabindex="0">
              <img src="../assets/common/left-arrow-green.png" alt="Back to Traditional Taiwanese Opera" title="Back to Traditional Taiwanese Opera">
              <p style="margin-left: 0.5vw">Back</p>
            </div>
          </div>
        </div>

      </div>

      <div class="content-1">
        <p class="title-style" style="color: #ffffff">I Protest!</p>
        <p class="title-style" style="color: #ffffff">Someone is Performing a Taiwanese Opera</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">Dajia Local Petition to Stop Taiwanese Opera</p>
        <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan History 2019.031.0298</p>
        <p class="content-style" style="margin-top: 0.5vw; max-height: 20vw; overflow: scroll; padding-right: 20px;">This document, dating back to 1926, is a petition from the Dajia area to suppress Taiwanese opera performances initiated by Peng Qing-kao and others. They requested that the police strictly crack down on Taiwanese opera troupes' performances. The content highlights that the plays were initially intended to punish evil and promote good with artistic flair. However, there were instances of troupes abducting virtuous women, and more importantly, local women became so captivated by the performances that they stayed up late and dozed off during the day. This adversely affected the rush straw hat manufacturing industry. Consequently, the petition called on police to strictly prohibit Taiwanese opera troupes from operating in the Dajia area. Peng Qing-kao (1890-1955), the father of Peng Ming-min, established De Ren Hospital in Dajia, Taichung, between 1914 and 1933 and enjoyed significant prestige in the region. Key members of the Taiwan Cultural Association and the Taiwanese People's Party, such as Du Qing, Du Xiang-guo, and Huang Qing-bo, also signed the joint petition.</p>
      </div>

      <div class="book-wrap">
<!--        <img class="book w-full relative" :style="bookStyle[0]" :src="book[0].value" style="z-index: 7">-->
<!--        <img v-for="n in 6" :key="n" class="w-full absolute top-0 book " :style="bookStyle[n]+' z-index:'+(7-n)+';'"  :src="book[n].value">-->
        <div class="turnClass">
          <div id="flipbook" alt="Dajia Local Petition to Stop Taiwanese Opera" title="Dajia Local Petition to Stop Taiwanese Opera">
            <img class="page page-1 " :src="require('../assets/Unit4-4/17-pic5-翻頁動態效果/17-pic5-翻頁動態-00.png')">
            <img class="page page-2 " :src="require('../assets/Unit4-4/18-pic6-翻頁動態效果/18-pic6-翻頁動態-00.png')">
            <img class="page page-3 " :src="require('../assets/Unit4-4/19-pic7-翻頁動態效果/19-pic7-翻頁動態-00.png')">
            <img class="page page-4 " :src="require('../assets/Unit4-4/20-pic8-翻頁動態效果/20-pic8-翻頁動態-00.png')">
            <img class="page page-5 " :src="require('../assets/Unit4-4/21-pic9-翻頁動態效果/21-pic9-翻頁動態-00.png')">
            <img class="page page-6 " :src="require('../assets/Unit4-4/22-pic10-翻頁動態效果/22-pic10-翻頁動態-00.png')">
            <img class="page page-7 " :src="require('../assets/Unit4-4/20230417-4-4-物件-23-pic11.png')">
          </div>
        </div>

        <transition name="img-frame-fade"><img v-if="bookPage>1" @click="bookLeftBtn" @keydown.enter="bookLeftBtn" tabindex="0" class="left-arrow"  src="../assets/common/left-arrow-white.png" alt="previous page" title="previous page"></transition>
        <img class="zoom-2-btn zoom-fade " @click="showImg[bookPage+2]=true"  @keydown.enter="showImg[bookPage+2]=true" tabindex="0"  style="width: 2.2vw; bottom: 5%; right: 3%; z-index: 10" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        <transition name="img-frame-fade"><img v-if="bookPage<7" @click="bookRightBtn" @keydown.enter="bookRightBtn" tabindex="0" class="right-arrow"  src="../assets/common/right-arrow-white.png" alt="next page" title="next page"></transition>
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-4/4-4-4.json')" alt="people" title="people"></LottieTest>
      </div>

      <div class="back-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-white.png" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
        <p style="margin-left: 0.5vw">Back</p>
      </div>
    </div>

    <!--寫這裡-->

    <!--img0--><!--hor-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--         <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-09.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:4.6vw 0 0 3.5vw" src="../assets/Unit4-4/20230703-4-4-物件-02-pic1.png" alt="Advertisement for the Anti-Ghost Festival Pudu Rite Pamphlet" title="Advertisement for the Anti-Ghost Festival Pudu Rite Pamphlet">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 60vw; width: 33vw;">
            <div class="bold-title">Advertisement for the Anti-Ghost Festival Pudu Rite Pamphlet</div>
            <div class="noto-text" >Courtesy of Zhuang Ming-zheng</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--          <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-10.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.6vw; margin:2.4vw 0 0 23.3vw" src="../assets/Unit4-4/20230703-4-4-物件-07-pic2-放大文物.png" alt="Anti-Ghost Festival Pudu Rite Pamphlet" title="Anti-Ghost Festival Pudu Rite Pamphlet">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Anti-Ghost Festival Pudu Rite Pamphlet</div>
              <div class="noto-text" >Courtesy of Zhuang Ming-zheng</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--          <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-18.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:42.7vw; margin:6.2vw 0 0 10.7vw" src="../assets/Unit4-4/20230703-4-4-物件-12-pic3-放大文物.png" alt="Taiwan Min Pao “My parents are trying to engage me to somebody. What do I do?”" title="Taiwan Min Pao “My parents are trying to engage me to somebody. What do I do?”">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title"><i>Taiwan Min Pao</i>
                “My parents are trying to engage me to somebody. What do I do?”
              </div>
              <div class="noto-text"><i>Taiwan Min Pao</i>, Issue 1, 66</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--          <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-11.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.5vw; margin:2.8vw 0 0 13.7vw" src="../assets/Unit4-4/20230703-4-4-物件-17-pic5-放大文物.png" alt="Dajia Local Petition to Stop Taiwanese Opera" title="Dajia Local Petition to Stop Taiwanese Opera">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Dajia Local Petition to Stop Taiwanese Opera</div>
              <div class="noto-text" >National Museum of Taiwan History 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[4]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-12.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37vw; margin:2.4vw 0 0 13.5vw" src="../assets/Unit4-4/20230703-4-4-物件-18-pic6-放大文物.png" alt="Dajia Local Petition to Stop Taiwanese Opera" title="Dajia Local Petition to Stop Taiwanese Opera">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Dajia Local Petition to Stop Taiwanese Opera</div>
              <div class="noto-text">National Museum of Taiwan History 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-13.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.4vw; margin:2.4vw 0 0 13.3vw" src="../assets/Unit4-4/20230703-4-4-物件-19-pic7-放大文物.png" alt="Dajia Local Petition to Stop Taiwanese Opera" title="Dajia Local Petition to Stop Taiwanese Opera">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Dajia Local Petition to Stop Taiwanese Opera</div>
              <div class="noto-text" >National Museum of Taiwan History 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
        <!--         <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-14.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.4vw; margin:2.1vw 0 0 13.3vw" src="../assets/Unit4-4/20230703-4-4-物件-20-pic8-放大文物.png" alt="Dajia Local Petition to Stop Taiwanese Opera" title="Dajia Local Petition to Stop Taiwanese Opera">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Dajia Local Petition to Stop Taiwanese Opera</div>
              <div class="noto-text" >National Museum of Taiwan History 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img7--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[7]">
        <!--         <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-15.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.6vw; margin:2.4vw 0 0 13.1vw" src="../assets/Unit4-4/20230703-4-4-物件-21-pic9-放大文物.png" alt="Dajia Local Petition to Stop Taiwanese Opera" title="Dajia Local Petition to Stop Taiwanese Opera">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Dajia Local Petition to Stop Taiwanese Opera</div>
              <div class="noto-text" >National Museum of Taiwan History 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[7]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img8--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[8]">
        <!--                 <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.8vw; margin:2.5vw 0 0 13.5vw" src="../assets/Unit4-4/20230703-4-4-物件-22-pic10-放大文物.png" alt="Dajia Local Petition to Stop Taiwanese Opera" title="Dajia Local Petition to Stop Taiwanese Opera">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Dajia Local Petition to Stop Taiwanese Opera</div>
              <div class="noto-text" >National Museum of Taiwan History 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[8]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img9--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[9]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-4/sample/20230417-對位-4-4-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:37.5vw; margin:2.1vw 0 0 13.4vw" src="../assets/Unit4-4/20230703-4-4-物件-23-pic11-放大文物.png" alt="Dajia Local Petition to Stop Taiwanese Opera" title="Dajia Local Petition to Stop Taiwanese Opera">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Dajia Local Petition to Stop Taiwanese Opera</div>
              <div class="noto-text" >National Museum of Taiwan History 2019.031.0298</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[9]=false">
          </div>
        </div>
      </div>
    </transition>

    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import router from "@/router";
import {onMounted, reactive, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import EnIntroBtn from "@/components/EnIntroBtn.vue";
import turn from "@/assets/utils/lib/turn";
import $ from "jquery";
import flipAudio from "@/assets/Unit3-1/insert_paper.mp3";

export default {
  name: "Unit4-4",
  mixins: [myMixin],
  components:{
    Entoolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));
    const cardStatus = reactive(Array.from({ length: 3 }, () => 'front'));
    const book = reactive(Array.from({ length: 7 }, () => ref()));
    const bookStyle = reactive(Array.from({ length: 7 }, () => ''));
    const flipAudio = require('@/assets/Unit3-1/insert_paper.mp3');
    const flipEle = new Audio(flipAudio);

    function onTurn(){
      $("#flipbook").turn({
        acceleration:true,
        display: "single",
        //gradients: true,
        autoCenter: true,
        height:646,
        width:1500,
        direction: "rtl",
      });


      $('#flipbook').turn('size', '100%', '100%');
      $("#flipbook").bind("turning", function(event, page, view) {
        bookPage.value = page;
        flipEle.play();
      });
      $("#flipbook").bind("start", function(event, pageObject, corner) {
        $('#flipbook').turn('size', 'auto', '100%');
      });

    }


    onMounted(()=>{
      bookInit();

      bookPage.value = 1;
      onTurn();
      window.addEventListener('resize', ()=>{
        $('#flipbook').turn('size', 'auto', '100%');
      });
    });

    function bookInit(){
      book[0].value = require('../assets/Unit4-4/17-pic5-翻頁動態效果/17-pic5-翻頁動態-00.png');
      book[1].value = require('../assets/Unit4-4/18-pic6-翻頁動態效果/18-pic6-翻頁動態-00.png');
      book[2].value = require('../assets/Unit4-4/19-pic7-翻頁動態效果/19-pic7-翻頁動態-00.png');
      book[3].value = require('../assets/Unit4-4/20-pic8-翻頁動態效果/20-pic8-翻頁動態-00.png');
      book[4].value = require('../assets/Unit4-4/21-pic9-翻頁動態效果/21-pic9-翻頁動態-00.png');
      book[5].value = require('../assets/Unit4-4/22-pic10-翻頁動態效果/22-pic10-翻頁動態-00.png');
      book[6].value = require('../assets/Unit4-4/20230417-4-4-物件-23-pic11.png');

      bookStyle[0] = '';
      bookStyle[1] = 'opacity:0;';
      bookStyle[2] = 'opacity:0;';
      bookStyle[3] = 'opacity:0;';
      bookStyle[4] = 'opacity:0;';
      bookStyle[5] = 'opacity:0;';
      bookStyle[6] = 'opacity:0;';
    }


    let bookId = null;
    let bookIndex = 0;
    let booking = false;

    const bookPage = ref(0);

    function bookRightBtn(){
      $("#flipbook").turn("next");
      return;
      if(booking || bookPage.value == 6) return;

      booking = true;
      bookIndex = 0;

      clearInterval(bookId);

      if(bookPage.value+1 <= 6){
        bookStyle[bookPage.value+1] = "";
      }

      bookId = setInterval(()=>{

        bookIndex = (bookIndex + 1) % (50+1);

        let img = null;
        switch (bookPage.value){
          case 0: img= require('../assets/Unit4-4/17-pic5-翻頁動態效果/17-pic5-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 1: img=require('../assets/Unit4-4/18-pic6-翻頁動態效果/18-pic6-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 2: img=require('../assets/Unit4-4/19-pic7-翻頁動態效果/19-pic7-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 3: img=require('../assets/Unit4-4/20-pic8-翻頁動態效果/20-pic8-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 4: img=require('../assets/Unit4-4/21-pic9-翻頁動態效果/21-pic9-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 5: img=require('../assets/Unit4-4/22-pic10-翻頁動態效果/22-pic10-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
        }


        if(bookPage.value < 6){
          book[bookPage.value].value = img;
        }

        if(bookIndex >=  50){
          bookStyle[bookPage.value] = 'opacity:0;';
          clearInterval(bookId);
          bookPage.value= Math.min(bookPage.value+1, 6);
          booking = false;
        }

      }, 50);

    }

    function bookLeftBtn(){
      $("#flipbook").turn("previous");
      return;
      if(booking || bookPage.value == 0) return;
      booking = true;

      bookIndex = 50;
      clearInterval(bookId);

      bookId = setInterval(()=>{

        bookIndex = bookIndex-1;

        if(bookPage.value-1 >= 0){
          bookStyle[bookPage.value-1] = "";
        }

        let img = null;
        switch (bookPage.value){
          case 1: img= require('../assets/Unit4-4/17-pic5-翻頁動態效果/17-pic5-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 2: img=require('../assets/Unit4-4/18-pic6-翻頁動態效果/18-pic6-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 3: img=require('../assets/Unit4-4/19-pic7-翻頁動態效果/19-pic7-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 4: img=require('../assets/Unit4-4/20-pic8-翻頁動態效果/20-pic8-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 5: img=require('../assets/Unit4-4/21-pic9-翻頁動態效果/21-pic9-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png'); break;
          case 6: img=require('../assets/Unit4-4/22-pic10-翻頁動態效果/22-pic10-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png');break;
        }

        book[bookPage.value-1].value = img;

        if(bookIndex <= 0){
          clearInterval(bookId);
          bookStyle[bookPage.value] = 'opacity:0;';
          bookPage.value= Math.max(bookPage.value-1, 0);
          booking = false;
        }

      }, 50);

    }


    function routeBtn(path){
      router.push(path);
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      cardStatus,
      book,
      bookStyle,
      bookPage,
      bookRightBtn,
      bookLeftBtn,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit4-4.css"></style>
