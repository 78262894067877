<template>
  <div class="intro" @click="routeBtn('/person-intro-all')" @keydown.enter="routeBtn('/person-intro-all')" :style="style" tabindex="0">
    <div style="width: 3vw"><img class="w-full" src="@/assets/common/20230629-臺史博-文協線上展-網站-共用按鈕-物件-or-06-人物介紹按鈕.png" alt="文協人物介紹" title="文協人物介紹"></div>
<!--    <div class="w-full"><img class="w-full" src="../assets/enter/intro-img.gif" alt="文協人物介紹" title="文協人物介紹"></div>-->
    <div style="width: 4vw"><LottieTest :json="require('@/assets/common/people-introduce.json')" alt="文協人物介紹" title="文協人物介紹"></LottieTest></div>
  </div>
</template>

<script>
import router from "@/router";
import LottieTest from "@/components/LottieTest";

export default {
  name: "IntroBtn",
  components: {LottieTest},
  props:{
    style : String
  },
  setup(){
    function routeBtn(path){
      router.push(path);
    }

    return{
      routeBtn
    }
  }
}
</script>

<style scoped>
.intro{
  position: fixed;
  bottom: 2.5%;
  right: 2.6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  //width: 2.9%;
  cursor: pointer;
}
</style>
