<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="1" unit="3" route-name="unit4-5" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit4-5/sample/20230703-對位-4-5-op-01-自己的紀念日.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="img-1" src="../assets/Unit4-5/20230703-4-5-物件-01-首圖1.png" alt="國際青年日演講宣傳單" title="國際青年日演講宣傳單">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-5/4-5-0.json')" alt="自己的紀念日" title="自己的紀念日" ></LottieTest>
      </div>

      <div class="title-logo">自己的紀念日</div>
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit4-5/20230703-4-5-物件-02-裝飾子2-新紀念日.png" alt="新紀念日" title="新紀念日">
      <div class="content-1 content-style">
        文協媒體中，可發現許多「新紀念日」的誕生與宣傳，有的是國際性的，也有文協發展過程中別具紀念性的日子，如「治警事件檢舉紀念日」（12/16）、「文協改組紀念日」（1/3，文化日）、民報日（7/16，臺灣青年創刊、臺灣民報遷台），還有正面迎戰總督府統治的「6/17恥政紀念日」。當一個「新的紀念日」成立，將某些日子儀式化，闡述其意義的過程，也標誌了一個新政治群體的成形。
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-5/4-5-1.json')" alt="新紀念日" title="新紀念日" ></LottieTest>
      </div>
    </div>

    <div class="part3 part-color">

      <div class="arrow-btn" style="left: 11%;z-index: 2" @click="part3Page--" @keydown.enter="part3Page--" tabindex="0" v-if="part3Page>0">
        <p>{{leftArrowText[part3Page]}}</p>
        <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/left-arrow.png" alt="上一頁" title="上一頁">
      </div>


      <transition name="img-frame-fade">
      <div class="page page-1" v-if="part3Page==0">
        <img class="bg w-full" src="../assets/Unit4-5/20230703-4-5-物件-03-插畫1.png" alt="國際婦女節" title="國際婦女節">
        <div class="content-1">
          <p class="title-style" style="color: #267c68">國際婦女節</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">《新臺灣大眾時報2之1》3月號三月的紀念日</p>
          <p class="subtitle-style">《臺灣民報》第7冊211嘉義婦女問題講演會</p>
          <p class="source-style" style="margin-top: 0.3vw">出自《新臺灣大眾時報》、《臺灣民報》</p>
          <p class="content-style" style="margin-top: 0.5vw">性別議題—特別是女權與婚姻問題，在文化協會發展開始便受到廣泛討論。在文協影響下，彰化、嘉義前後成立「彰化婦女共勵會」、「諸羅婦女協進會」，主辦講演會、夜學等活動。國際婦女節也以「国際婦人デー」之名成為3月的重要紀念日之一。</p>
        </div>
        <div class="img-1">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-05-pic1.png" alt="《新臺灣大眾時報2之1》3月號三月的紀念日" title="《新臺灣大眾時報2之1》3月號三月的紀念日">
          <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 20%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
        <div class="img-2">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-06-pic2.png" alt="《臺灣民報》第7冊211嘉義婦女問題講演會" title="《臺灣民報》第7冊211嘉義婦女問題講演會">
          <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.3vw; bottom: 8%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
      </div>
      </transition>

      <transition name="img-frame-fade">
      <div class="page page-2" v-if="part3Page==1">
        <img class="bg w-full" src="../assets/Unit4-5/20230703-4-5-物件-09-插畫2.png" alt="五一勞動節" title="五一勞動節">
        <div class="content-1">
          <p class="title-style" style="color: #267c68">五一勞動節</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">《台灣大眾時報》五一的各地爭報</p>
          <p class="source-style" style="margin-top: 0.3vw">出自《台灣大眾時報》</p>
          <p class="content-style" style="margin-top: 0.5vw">1927年文協左傾後，勞働節（依其原文就讀做May Day）就成為文協最重要的紀念日，每逢5月1日便各地串連舉辦活動，自稱「無產青年」的團隊巡迴演講，並在雜誌上大加宣揚遍地開花的熱烈情形。</p>
        </div>

        <div class="arrow-btn arrow-btn-2" style="left: 49.6%;" @click="page3Img--" @keydown.enter="page3Img--" tabindex="0" v-if="page3Img>0">
          <img class="w-full"  src="../assets/common/left-arrow.png" alt="上一頁" title="上一頁">
        </div>

        <transition name="img-frame-fade"><div class="img" v-if="page3Img==0" style="top:3%; left: 63.7%;">
          <img class="h-full" src="../assets/Unit4-5/20230703-4-5-物件-10-pic3.png" alt="《台灣大眾時報》五一的各地爭報" title="《台灣大眾時報》五一的各地爭報">
          <img class="zoom-2-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div></transition>
        <transition name="img-frame-fade"><div class="img" v-if="page3Img==1" style="top:3%; left: 56%;">
          <img class="h-full" src="../assets/Unit4-5/20230703-4-5-物件-11-pic4.png" alt="《台灣大眾時報》五一的各地爭報" title="《台灣大眾時報》五一的各地爭報">
          <img class="zoom-2-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div></transition>
        <transition name="img-frame-fade"><div class="img" v-if="page3Img==2" style="top:3%; left: 56%;">
          <img class="h-full" src="../assets/Unit4-5/20230703-4-5-物件-13-pic6.png" alt="《台灣大眾時報》五一的各地爭報" title="《台灣大眾時報》五一的各地爭報">
          <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div></transition>
        <transition name="img-frame-fade"><div class="img" v-if="page3Img==3" style="top:3%; left: 56%;">
          <img class="h-full" src="../assets/Unit4-5/20230703-4-5-物件-12-pic5.png" alt="《台灣大眾時報》五一的各地爭報" title="《台灣大眾時報》五一的各地爭報">
          <img class="zoom-2-btn" @click="showImg[6]=true" @keydown.enter="showImg[6]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div></transition>


        <div class="arrow-btn arrow-btn-2" style="right: 6%;" @click="page3Img++" @keydown.enter="page3Img++" tabindex="0" v-if="page3Img<3">
          <img class="w-full"  src="../assets/common/right-arrow.png" alt="下一頁" title="下一頁">
        </div>
      </div>
      </transition>

      <transition name="img-frame-fade">
      <div class="page page-3" v-if="part3Page==2">
        <img class="bg w-full" src="../assets/Unit4-5/20230703-4-5-物件-14-插畫3.png" alt="始政「恥」紀念日" title="始政「恥」紀念日">
        <div class="content-1">
          <p class="title-style" style="color: #267c68">始政「恥」紀念日</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">《新臺灣大眾時報2之3》 6月號04 〈我們XX的六一七又來了〉</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">617恥政紀念日活動講題與標語</p>
          <p class="source-style" style="margin-top: 0.3vw">出自《新臺灣大眾時報》、《臺灣民報》</p>
          <p class="content-style" style="margin-top: 0.5vw; height:10vw; overflow: auto">1895年6月17日，臺灣總督府宣布建政。每年這一天官方及日本人團體都會舉辦活動大肆慶祝，臺灣人反應較為消極。1926年文化協會青年開始舉辦大型演講會，進行政治演講，算是對總督府殖民體制的正面迎擊，特別挑動警察神經，而後每年的617活動愈加盛大，與警察大人大玩捉迷藏。1929年王敏川等文協份子在6月初即遭警方檢束，至紀念日後才釋放，可說是「預防性羈押」。</p>
        </div>
        <div class="img-1">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-15-pic7.png" alt="《新臺灣大眾時報2之3》6月號04〈我們XX的六一七又來了〉" title="《新臺灣大眾時報2之3》6月號04〈我們XX的六一七又來了〉">
          <img class="zoom-2-btn" @click="showImg[7]=true" @keydown.enter="showImg[7]=true" tabindex="0" style="width: 2.3vw; bottom: 6%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
        <div class="img-2">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-16-pic8.png" alt="617恥政紀念日活動講題與標語" title="617恥政紀念日活動講題與標語">
          <img class="zoom-2-btn" @click="showImg[8]=true" @keydown.enter="showImg[8]=true" tabindex="0" style="width: 2.3vw; bottom: 15%; right: 5%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
      </div>
      </transition>

      <transition name="img-frame-fade">
      <div class="page page-4" v-if="part3Page==3">
        <img class="bg w-full" src="../assets/Unit4-5/20230703-4-5-物件-17-插畫4.png" alt="國際青年日" title="國際青年日">
        <div class="content-1">
          <p class="title-style" style="color: #267c68">國際青年日</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">國際青年日演講宣傳單</p>
          <p class="source-style" style="margin-top: 0.3vw">莊明正提供</p>
          <p class="content-style" style="margin-top: 0.5vw">9月的第一個星期日為共產主義青年們重視的國際青年日，紀念1915年反帝國主義的大會及示威活動。</p>
          <p class="content-style" >*現今的國際青年日為聯合國公定的8月12日</p>
        </div>
        <div class="img-1">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-18-pic9.png" alt="國際青年日演講宣傳單" title="國際青年日演講宣傳單">
          <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.3vw; bottom: 3%; right: 0%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
      </div>
      </transition>

      <transition name="img-frame-fade">
      <div class="page page-5" v-if="part3Page==4">
        <img class="bg w-full" src="../assets/Unit4-5/20230703-4-5-物件-18-插畫5.png" alt="臺灣文化日" title="臺灣文化日">
        <div class="content-1">
          <p class="title-style" style="color: #267c68">臺灣文化日</p>
          <p class="content-style" style="margin-top: 0.5vw">2011年文化部發起將10月17日訂為「臺灣文化日」紀念 1921年臺灣文化協會的成立。宣揚文協前輩們追求的信念，寄望文化做為推動臺灣人團結進步的力量。</p>
        </div>
        <div class="json-1">
          <LottieTest :json="require('@/assets/Unit4-5/4-5-2.json')" alt="臺灣文化日" title="臺灣文化日"></LottieTest>
        </div>

      </div>
      </transition>

      <transition name="img-frame-fade">
      <div class="page page-6" v-if="part3Page==5">
        <img class="bg w-full" src="../assets/Unit4-5/20230703-4-5-物件-19-插畫6.png" alt="治警事件紀念日" title="治警事件紀念日">
        <div class="content-1">
          <p class="title-style" style="color: #267c68">治警事件紀念日</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">《臺灣民報》第7冊301十二月十六的紀念日</p>
          <p class="source-style" style="margin-top: 0.3vw">出自《臺灣民報》</p>
          <p class="content-style" style="margin-top: 0.5vw">1923年12月16日，議會期成同盟遭到取締，蔡培火、蔣渭水等文協主力者被捕，總督府展開針對政治行動的司法審判，是為治警事件。</p>
          <p class="content-style" >治警事件紀念日也被視為是「民眾運動的紀念日」，每年此時，文協成員及治警事件中曾被檢舉者會舉辦紀念活動，宣揚民眾運動的意旨。</p>
        </div>
        <div class="img-1">
          <img class="w-full" src="../assets/Unit4-5/20230703-4-5-物件-20-pic10.png" alt="《臺灣民報》第7冊301十二月十六的紀念日" title="《臺灣民報》第7冊301十二月十六的紀念日">
          <img class="zoom-2-btn" @click="showImg[9]=true" @keydown.enter="showImg[9]=true" tabindex="0" style="width: 2.3vw; bottom: 10%; right: 6%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        </div>
      </div>
      </transition>


      <div class="arrow-btn" style="right: 12%;" @click="part3Page++" @keydown.enter="part3Page++" tabindex="0" v-if="part3Page<5">
        <p style="display: flex; flex-direction: column; align-items: center">{{rightArrowText[part3Page]}}</p>
        <img class="w-full" style="margin-top: 0.3vw" src="../assets/common/right-arrow.png" alt="下一頁" title="下一頁">
      </div>

      <div class="back-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0" alt="回廟埕拚文化" title="回廟埕拚文化">
        <img src="../assets/common/left-arrow-green.png" alt="回廟埕拚文化" title="回廟埕拚文化">
        <p style="margin-left: 0.5vw">回廟埕拚文化</p>
      </div>
    </div>


    <!--寫這裡-->

    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-10.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34vw; margin:4vw 0 0 14.6vw" src="../assets/Unit4-5/20230703-4-5-物件-18-pic9-放大文物.png" alt="國際青年日演講宣傳單" title="國際青年日演講宣傳單">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 25.6vw; width: 12.3vw;">
            <div >
              <div class="bold-title">國際青年日演講宣傳單</div>
              <div class="noto-text" style="margin-top: 0.3vw">莊明正 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-11.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:11.3vw; margin:1.7vw 0 0 25.9vw" src="../assets/Unit4-5/20230703-4-5-物件-05-pic1-放大文物.png" alt="《新臺灣大眾時報2之1》3月號三月的紀念日" title="《新臺灣大眾時報2之1》3月號三月的紀念日">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 20.5vw; width: 22.4vw;">
            <div >
              <div class="bold-title">《新臺灣大眾時報2之1》3月號三月的紀念日</div>
              <div class="noto-text" style="margin-top: 0.3vw">出自《新臺灣大眾時報》</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-12.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:46vw; margin:7.5vw 0 0 9.3vw" src="../assets/Unit4-5/20230703-4-5-物件-06-pic2-放大文物.png" alt="《臺灣民報》第7冊211嘉義婦女問題講演會" title="《臺灣民報》第7冊211嘉義婦女問題講演會">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 21vw; width: 22.7vw;">
            <div >
              <div class="bold-title">《臺灣民報》第7冊211嘉義婦女問題講演會</div>
              <div class="noto-text" style="margin-top: 0.3vw">出自《臺灣民報》</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-13.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:17.5vw; margin:2.8vw 0 0 23.1vw" src="../assets/Unit4-5/20230703-4-5-物件-10-pic3-放大文物.png" alt="《台灣大眾時報》五一的各地爭報" title="《台灣大眾時報》五一的各地爭報">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23vw; width: 17.5vw;">
            <div >
              <div class="bold-title">《台灣大眾時報》五一的各地爭報</div>
              <div class="noto-text" style="margin-top: 0.3vw">出自《台灣大眾時報》</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-14.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.3vw; margin:2.7vw 0 0 14vw" src="../assets/Unit4-5/20230703-4-5-物件-11-pic4-放大文物.png" alt="《台灣大眾時報》五一的各地爭報" title="《台灣大眾時報》五一的各地爭報">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23vw; width: 17.5vw;">
            <div >
              <div class="bold-title">《台灣大眾時報》五一的各地爭報</div>
              <div class="noto-text" style="margin-top: 0.3vw">出自《台灣大眾時報》</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-15.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.5vw; margin:2.7vw 0 0 13.1vw" src="../assets/Unit4-5/20230703-4-5-物件-13-pic6-放大文物.png" alt="《台灣大眾時報》五一的各地爭報" title="《台灣大眾時報》五一的各地爭報">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23vw; width: 17.5vw;">
            <div >
              <div class="bold-title">《台灣大眾時報》五一的各地爭報</div>
              <div class="noto-text" style="margin-top: 0.3vw">出自《台灣大眾時報》</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[6]">
        <!--                <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-16.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.4vw; margin:2.6vw 0 0 14vw" src="../assets/Unit4-5/20230703-4-5-物件-12-pic5-放大文物.png" alt="《台灣大眾時報》五一的各地爭報" title="《台灣大眾時報》五一的各地爭報">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23vw; width: 17.5vw;">
            <div >
              <div class="bold-title">《台灣大眾時報》五一的各地爭報</div>
              <div class="noto-text" style="margin-top: 0.3vw">出自《台灣大眾時報》</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[6]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img7yet--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[7]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-17.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:16.5vw; margin:2.6vw 0 0 24.1vw" src="../assets/Unit4-5/20230703-4-5-物件-15-pic7-放大文物.png" alt="《新臺灣大眾時報2之3》6月號04〈我們XX的六一七又來了〉" title="《新臺灣大眾時報2之3》6月號04〈我們XX的六一七又來了〉">
          </div>

          <div class="content-text" style="position: absolute; top:32vw; left: 23.6vw; width: 17.5vw;">
            <div >
              <div class="bold-title">《新臺灣大眾時報2之3》6月號04〈我們XX的六一七又來了〉</div>
              <div class="noto-text" style="margin-top: 0.3vw">出自《新臺灣大眾時報》</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[7]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img8--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[8]">
        <!--        <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-18.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:45.2vw; margin:2.9vw 0 0 9.5vw" src="../assets/Unit4-5/20230703-4-5-物件-16-pic8-放大文物.png" alt="617恥政紀念日活動講題與標語" title="617恥政紀念日活動講題與標語">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.5vw; width: 18.2vw;">
            <div >
              <div class="bold-title">617恥政紀念日活動講題與標語</div>
              <div class="noto-text" style="margin-top: 0.3vw">出自《新臺灣大眾時報》、《警察沿革誌》</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[8]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img9--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[9]">
        <!--     <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-5/sample/20230417-對位-4-5-op-19.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:39.3vw; margin:2.8vw 0 0 12.7vw" src="../assets/Unit4-5/20230703-4-5-物件-20-pic10-放大文物.png" alt="《臺灣民報》第7冊301十二月十六的紀念日" title="《臺灣民報》第7冊301十二月十六的紀念日">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 21.5vw; width: 22vw;">
            <div >
              <div class="bold-title">《臺灣民報》第7冊301十二月十六的紀念日</div>
              <div class="noto-text" style="margin-top: 0.3vw">出自《臺灣民報》</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[9]=false">
          </div>
        </div>
      </div>
    </transition>

    <intro-btn></intro-btn>
    <FooterFeature color="black"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import LottieTest from "@/components/LottieTest";
import {reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
export default {
  name: "Unit4-5",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));
    const page3Img = ref(0);
    const part3Page = ref(0);
    const leftArrowText = reactive(['','3 月 8 日','5 月 1 日','6 月 17 日','9 月 1 日','10 月 17 日']);
    const rightArrowText = reactive(['5 月 1 日','6 月 17 日','9 月 1 日','10 月 17 日','12 月 16 日','']);

    function routeBtn(path){
      router.push(path);
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      page3Img,
      part3Page,
      leftArrowText,
      rightArrowText,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit4-5.css"></style>
