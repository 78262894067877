<template>
  <div class="wrap" @mousemove="handleMouseMove" @keydown.esc="escEvent">
    <EnToolbar type="2" unit="3" route-name="unit3-2" color="brown"></EnToolbar>

<!--    <img class="sample" src="../assets/Unit3-2/sample/20230414-對位-3-2-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">

      <div class="title-logo">Stage Performances and Films</div>
      <img class="img-1" src="../assets/Unit3-2/20230414-物件-3-2-25-首圖１.png" alt="Stage Performances and Films" title="Stage Performances and Films">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-2/3-2-0.json')" alt="Stage Performances and Films" title="Stage Performances and Films"></LottieTest>
      </div>

      <div class="loop-img ">
        <img style="position: relative; width: 26.3vw; margin: 0.5vw 0 0 -0.8vw; max-width: none; opacity: 0" src="../assets/Unit3-2/20230414-物件-3-2-03-pic2.png" alt="Stage Performances and Films" title="Stage Performances and Films">
        <img style="position: absolute; width: 26.3vw; top: -0.15%; left: -3.3%; max-width: none; " :style="loopImgStyle[0]" :src="loopImgSrc[0].value" alt="Stage Performances and Films" title="Stage Performances and Films">
        <img style="position: absolute; width: 26.3vw; top: -0.15%; left: -3.3%; max-width: none; " :style="loopImgStyle[1]" :src="loopImgSrc[1].value" alt="Stage Performances and Films" title="Stage Performances and Films">
      </div>
      <div class="part-color" style="position: absolute; bottom: 0; width: 20vw; height: 6vw; background: white;"></div>
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit3-2/en/20230708-EN網站-物件-3-2-05-裝飾字1-文化劇.png" alt="Cultural Drama" title="Cultural Drama">
      <div class="content-1 content-style">Taiwan’s traditional plays are mostly stories of princes and generals, or old-fashioned love dramas. However, young people who loved modern art naturally developed completely different forms of entertainment. They wrote scripts in everyday language, and presented contemporary scenes on stage. They especially loved to criticize old-fashioned social ethics through what they called “cultural dramas.” Local artists performing with the Yanfeng Youth Club in Caotun, the Xinguang Theatrical Troupe in Hsinchu, and the Tainan Cultural Theatrical Troupe and Anping Theatrical Troupe in Tainan, won widespread acclaim.</div>

      <div class="img-1">
        <img class="w-full" src="../assets/Unit3-2/20230414-物件-3-2-07-pic5.png" alt="Tainan Cultural Drama Troupe Live Performance October 14-17, 1927" title="Tainan Cultural Drama Troupe Live Performance October 14-17, 1927">
        <img class="zoom-2-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>

      <div class="content-2">
        <p class="title-style" style="color: #267c68">Tainan Cultural Drama Troupe Live Performance<span class="year">October 14-17, 1927</span></p>
        <p class="subtitle-style" style="margin-top: 0.5vw"></p>
        <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan Literature Donated by Lin Zhang-feng</p>
        <p class="content-style" style="margin-top: 0.5vw;">The Tainan Cultural Drama Troupe held its second performance event at Nanzuo in Tainan City, which brought together several renowned anti-colonial actors on stage. More than 30 people participated including Lu Bing-ding (Right 1), Chuang Sung-lin, Lin Zhan-ao, Tsai Pei-huo, Liang Jia-sheng, Wang Shou-lu, Huang Jin-huo, and Han Shi-quan.</p>
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-2/3-2-1.json')" alt="Cultural Drama" title="Cultural Drama" ></LottieTest>
        <img class="json-img" src="../assets/Unit3-2/20230414-物件-3-2-06-pic4.png" alt="Cultural Drama" title="Cultural Drama">
      </div>
    </div>

    <div class="part3 part-color">
      <div class="img-1">
        <img class="h-full" src="../assets/Unit3-2/20230414-物件-3-2-08-pic6.png" alt="Capturing Cultural Drama Performances" title="Capturing Cultural Drama Performances">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 2%; right: 4%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="img-2">
        <img class="h-full" src="../assets/Unit3-2/20230414-物件-3-2-09-pic7.png" alt="Capturing Cultural Drama Performances" title="Capturing Cultural Drama Performances">
        <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="width: 2.2vw; bottom: 2%; right: 4%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #267c68;width: 43vw">Capturing Cultural Drama Performances</p>
        <p class="subtitle-style" style="margin-top: 0.5vw"></p>
        <p class="source-style" style="margin-top: 0.3vw">National Museum of Taiwan Literature Donated by Lin Zhang-feng</p>
        <p class="content-style" style="margin-top: 0.5vw;">These two photos, from the album of married couple Lin Shi-Hao and Lu Bing-ding, record moments from cultural drama performances of the time. Taiwan Governor's Office Medical School graduates Han Shi-quan, Huang Jin-huo, and Wang Shou-lu often played important roles in the dramas.</p>
      </div>
    </div>

    <div class="part4 ">
      <img class="top-half" src="../assets/Unit3-2/HalfRound.png">
      <div class="section-1 part-color">
          <img class="logo-text" src="../assets/Unit3-2/en/20230708-EN網站-物件-3-2-10-裝飾字2-活動寫真.png" alt="Moving Pictures" title="Moving Pictures">
          <div class="content-1 content-style" style="width: 42vw">
            <p>Taiwan Cultural Association began showing moving pictures, or movies as we know know them, in 1926 when Director Tsai Pei-huo purchased a film projector and films, which was transported across Taiwan for screenings.</p>
            <p style="margin-top: 1.5vw">Tsai Pei-huo purchased a projector and began showing films around Taiwan in 1926. The films typically introduced the economic situation in other countries or modern married life, interspersed with humor, while encouraging learning. Activists added local perspectives and related trends. The police also conducted surveillance on these events, sometimes ordering their suspension.</p>
          </div>
          <div class="json-2"><LottieTest :json="require('@/assets/Unit3-2/3-2-3.json')" alt="Moving Pictures" title="Moving Pictures"></LottieTest></div>
          <div class="json-3"><LottieTest :json="require('@/assets/Unit3-2/3-2-4.json')" alt="Moving Pictures" title="Moving Pictures"></LottieTest></div>
          <img class="img-1" src="../assets/Unit3-2/20230414-物件-3-2-12-插畫1.png" alt="Moving Pictures" title="Moving Pictures">
          <img class="img-2" src="../assets/Unit3-2/20230414-物件-3-2-14-插畫2.png" alt="Moving Pictures" title="Moving Pictures">
          <div class="video-div" >
            <video id="video"  class="w-full" ref="videoRef" @click="playVideo"  @pause="videoPausedEvent">
              <source src="../assets/Unit3-2/video4.mp4"
                      type="video/mp4">
            </video>
          </div>
        <div class="video-touch"  @mousemove="customCursorStyle='opacity:1'" @mouseleave="customCursorStyle='display:none'" @click="playVideo" @keydown.enter="playVideo" tabindex="0"></div>
          <div class="content-2">
            <p class="title-style" style="color: #ffffff">Tour the Arctic with the Moving Picture Team</p>
            <p class="subtitle-style" style="margin-top: 0.5vw">Nanook of the North</p>
            <p class="source-style" style="margin-top: 0.3vw">Wikimidia, Public Domin</p>
            <div class="content-style" style="margin-top: 0.5vw; width: 35vw">
              <p>According to records, the first batch of film screenings included titles such as Farming in Denmark, Cooperative Enterprises in Denmark, Red Cross, and Ecology of Arctic Animals, but these titles have been lost to history. Here, we play Nanook of the North from 1922 to get a feel for similar movies of the time.</p>
              <p>The film’s protagonist is a man named Nanook living in the north of Canada and it documents the daily life of the Inuit people in the Arctic Circle as they trade fur with settlers, fishing in the snow and ice, and hunting walruses. The film presents a hard day of struggle.</p>
              <p>Imagine yourself as one of the Moving Picture Team’s speakers, and give an introduction to this movie.</p>
            </div>
          </div>

        <img class="zoom-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="width: 2.2vw; bottom: 31%; right: 40.8%; z-index: 5" src="../assets/common/zoom-btn.png">

      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit3-2/3-2-2.json')" alt="people" title="people" ></LottieTest>
      </div>
    </div>

    <div class="part5">
      <img class="top-half" src="../assets/Unit3-2/HalfRound_2-02.png">
      <div class="section-1 part-color">
        <div class="json-1"><LottieTest :json="require('@/assets/Unit3-2/3-2-5.json')" alt="people" title="people"></LottieTest></div>

        <div class="content-1">
          <p class="title-style" style="color: #267c68">A New Era of Popular Music</p>
          <p class="subtitle-style" style="margin-top: 0.5vw">Meitai Troupe Music Promotion Society rules and membership form</p>
          <p class="source-style" style="margin-top: 0.3vw">Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</p>
          <p class="content-style" style="margin-top: 0.5vw;">The Moving Picture Team, founded by Tsai Pei-huo in 1925, was created after he acquired screening equipment. Following a split in the Taiwan Cultural Association, Tsai established the Meitai Troupe for touring performances. In 1933, the Society's rules were set and published in both Japanese and Chinese. The Society aimed to study music, purify people's hearts, and concentrate on enhancing and uplifting family life.</p>
        </div>

        <div class="img-1">
          <img class="w-full" src="../assets/Unit3-2/20230414-物件-3-2-20-pic9.png" alt="Meitai Troupe Music Promotion Society rules and membership form" title="Meitai Troupe Music Promotion Society rules and membership form">
          <img class="zoom-2-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="width: 2.2vw; bottom: 3%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
        </div>

        <div class="json-2"><LottieTest :json="require('@/assets/Unit3-2/3-2-6.json')" alt="people" title="people"></LottieTest></div>

        <div class="back-btn" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">
          <img src="../assets/common/left-arrow-green.png" alt="Back to Activities of Taiwan Cultural Association" title="Back to Activities of Taiwan Cultural Association">
          <p style="margin-left: 0.5vw">Back</p>
        </div>
      </div>
    </div>


    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language='en'  color="black"></FooterFeature>

    <div class="custom-cursor" ref="customCursor" :style="customCursorStyle">
      <img class="w-full" :src="customCursorImg" >
    </div>

    <!--img0--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-02.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.7vw; margin:2.8vw 0 0 13.8vw" src="../assets/Unit3-2/20230704-物件-3-2-07-pic5-放大文物.png" alt="Tainan Cultural Drama Troupe Live Performance October 14-17, 1927" title="Tainan Cultural Drama Troupe Live Performance October 14-17, 1927">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Tainan Cultural Drama Troupe Live Performance October 14-17, 1927</div>
              <div class="noto-text" >National Museum of Taiwan Literature Donated by Lin Zhang-feng</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-03.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.7vw; margin:2.8vw 0 0 13.8vw" src="../assets/Unit3-2/20230704-物件-3-2-08-pic6-放大文物.png" alt="Capturing Cultural Drama Performances" title="Capturing Cultural Drama Performances">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Capturing Cultural Drama Performances</div>
              <div class="noto-text" >National Museum of Taiwan Literature Donated by Lin Zhang-feng</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-04.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.7vw; margin:2.8vw 0 0 13.8vw" src="../assets/Unit3-2/20230704-物件-3-2-09-pic7-放大文物.png" alt="Capturing Cultural Drama Performances" title="Capturing Cultural Drama Performances">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Capturing Cultural Drama Performances</div>
              <div class="noto-text" >National Museum of Taiwan Literature Donated by Lin Zhang-feng</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--hor-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-06.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:12.5vw; margin:3.5vw 0 0 13.5vw" src="../assets/Unit3-2/20230704-物件-3-2-19-pic8.png" alt="Moving Picture Team Flag" title="Moving Picture Team Flag">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 38.7vw; width: 21vw;">
            <div class="bold-title">Moving Picture Team Flag</div>
            <div class="noto-text" >National Museum of Taiwan History 2014.011.0105</div>
            <div class="sub-title" style="text-align: left">We cannot be sure if this flag, inscribed with“Moving Picture Team”was ever used by the Taiwan Cultural Association, but it is still a rare artifact from the early days of cinema. Photos from the 1920’s taken at Taiwan Cultural Association film screenings often show similar flags, which were used to promote the event and also hung in the venues.</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit3-2/sample/20230414-對位-3-2-05.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:47.2vw; margin:6.1vw 0 0 9.5vw" src="../assets/Unit3-2/20230704-物件-3-2-21-pic9-放大文物.png" alt="Meitai Troupe Music Promotion Society rules and membership form" title="Meitai Troupe Music Promotion Society rules and membership form">
          </div>

          <div class="content-text">
            <div >
              <div class="bold-title">Meitai Troupe Music Promotion Society rules and membership form</div>
              <div class="noto-text" style="margin-top: 0.4vw">Courtesy of the Academic Institute of Taiwan History, Academia Sinica Archives</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import EnIntroBtn from "@/components/EnIntroBtn.vue";
import router from "@/router";
import FooterFeature from "@/components/FooterFeature";
import LottieTest from "@/components/LottieTest";
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue";
import ImgVertical from "@/components/ImgVertical";
import myMixin from "@/assets/common/js/mixin";
import {Vue3Marquee} from "vue3-marquee";
import 'vue3-marquee/dist/style.css'

export default {
  name: "Unit3-2",
  mixins: [myMixin],
  components:{
    ImgVertical,
    EnToolbar,
    EnIntroBtn,
    FooterFeature,
    LottieTest,
    Vue3Marquee,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const videoRef = ref();
    const customCursor = ref();
    const customCursorStyle = ref('display:none;');
    const videoPaused = ref(true);
    const customCursorImg = ref(require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png'));
    const showImg = reactive(Array.from({ length: 5 }, () => false));
    const loopImgSrc = reactive(Array.from({ length: 2 }, () => ref(null)));
    const loopImgStyle = reactive(Array.from({ length: 2 }, () => ''));
    loopImgSrc[0].value = require('../assets/Unit3-2/20230414-物件-3-2-01-pic1.png');
    loopImgSrc[1].value = require('../assets/Unit3-2/20230414-物件-3-2-03-pic2.png');
    loopImgStyle[1] = 'transform:translate(24.8vw, -3.3vw)';

    onMounted(()=>{
      videoRef.value.currentTime = 3;
    });

    function playVideo(){
      if(videoRef.value.paused){
        if(videoRef.value.currentTime == 3){
          videoRef.value.currentTime = 0;
        }
        videoRef.value.play();
        //customCursor.value.style.opacity = 0;
        customCursorImg.value = require('../assets/common/20230704-物件-6-0-15-影片暫停鍵.png');
        videoPaused.value = false;
        videoRef.value.controls = true;
      }else{
        videoRef.value.pause();
        videoPausedEvent();
      }
    }

    function videoPausedEvent(){
      customCursorImg.value = require('../assets/common/20230417-6-0-物件-02-影片播放鍵.png');
      videoPaused.value = true;
      videoRef.value.controls = false;
    }


    function handleMouseMove(event) {
      customCursor.value.style.left = (event.pageX - customCursor.value.clientWidth /2) + "px" ;
      customCursor.value.style.top = (event.pageY - customCursor.value.clientHeight /2)  + "px";
    }

    function routeBtn(path){
      router.push(path);
    }

    let loopInterval = setInterval(()=>{
      loopAnim();
    }, 5000);
    let loopTimeout = null;


    onUnmounted(()=>{
      clearInterval(loopInterval);
      clearTimeout(loopTimeout);
    });

    let curCenter = 0;
    let nextImg = 1;

    async function loopAnim(){
      switch (nextImg){
        case 0:
          loopImgSrc[(curCenter+1)%2].value = require("../assets/Unit3-2/20230414-物件-3-2-01-pic1.png");
          break;
        case 1:
          loopImgSrc[(curCenter+1)%2].value = require("../assets/Unit3-2/20230414-物件-3-2-03-pic2.png");
          break;
        case 2:
          loopImgSrc[(curCenter+1)%2].value = require("../assets/Unit3-2/20230414-物件-3-2-03-pic3.png");
          break;
      }

      loopImgStyle[curCenter] = 'transform:translate(-25.3vw, 3.325vw); transition: all 0.5s ease-in-out';
      loopImgStyle[(curCenter+1)%2] = 'transform:translate(0vw, 0vw); transition: all 0.5s ease-in-out';

      curCenter = (curCenter+1) % 2;
      nextImg = (nextImg+1) %3;

      loopTimeout = setTimeout(()=>{
        loopImgStyle[(curCenter+1)%2] = 'transform:translate(24.8vw, -3.3vw)';
        loopImgStyle[curCenter] = 'transform:translate(0vw, 0vw)';
      }, 500);
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      videoRef,
      customCursor,
      customCursorStyle,
      playVideo,
      handleMouseMove,
      videoPaused,
      videoPausedEvent,
      customCursorImg,
      showImg,
      loopImgSrc,
      loopImgStyle,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit3-2.css"></style>
