<template>
  <div class="wrap">
    <EnToolbar type="1" unit="5" route-name="guide" color="brown"></EnToolbar>
<!--    <img class="sample" src="../assets/common/sample/20231107-臺史博-文協線上展-EN網站-對位-op-網頁導覽.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="content-text">
      <div class="title">Sitemap</div>
      <div class="content-1">
        <p>This website with be built in accordance with design principles set out in Accessible Web Development Guidelines 2.0 and will follow the standards of accessible website design. It will incorporate such design elements as web guide tiles (:::), sitemap (site navigator), and access keys. The access keys to be used on this website are explained below.</p>
        <p style="margin-top: 2vw">Alt+U takes the user to the upper navigation area which includes the site navigator, language options, and main menu.</p>
        <p>Alt+C takes the user to the central content area.</p>
        <p>Alt+B takes the user to the lower navigation area which includes the quick menu and contact information for the National Museum of Taiwan History.</p>
        <p>+：“Zoom in” in the pop-up window.</p>
        <p>-：“Zoom out” in the pop-up window.</p>
        <p>ESC：“Close” in the pop-up window.</p>
      </div>

      <div style="padding: 0 0 0 2.2vw">
        <div class="subtitle" style="margin-top: 2.4vw; left: -2vw" @click="routeBtn('/en/')" @keydown.enter="routeBtn('/en/')" tabindex="0">0-Home Page</div>
        <div class="subtitle" style="margin-top: 0.2vw; left: -2vw" @click="routeBtn('/en/unit1-1')" @keydown.enter="routeBtn('/en/unit1-1')" tabindex="0">1-Skyview of the World</div>
        <div class="unit">
          <p @click="routeBtn('/en/unit1-2')" @keydown.enter="routeBtn('/en/unit1-2')" tabindex="0"><span class="number2">1-1</span>The World Rocked by New Thinking</p>
        </div>

        <div class="subtitle" style="margin-top: 0.2vw; left: -2vw" @click="routeBtn('/unit2-0')" @keydown.enter="routeBtn('/en/unit2-0')" tabindex="0">2-The Taiwan Cultural Association Forges Ahead</div>
        <div class="unit">
          <p @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0"><span class="number2" >2-1</span>Taiwanese People’s Taiwan</p>
          <p @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" tabindex="0"><span class="number2" >2-2</span>Advocating for Farmers and Workers</p>
          <p @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0"><span class="number2" >2-3</span>The Left and The Right</p>
          <p @click="routeBtn('/en/unit2-4')" @keydown.enter="routeBtn('/en/unit2-4')" tabindex="0"><span class="number2" >2-4</span>The Trend of Political Association</p>
        </div>

        <div class="subtitle" style="margin-top: 0.2vw; left: -2vw" @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0">3-Activities of Taiwan Cultural Association</div>
        <div class="unit">
          <p @click="routeBtn('/en/unit3-1')" @keydown.enter="routeBtn('/en/unit3-1')" tabindex="0"><span class="number2" >3-1</span>Cultural Lectures</p>
          <p @click="routeBtn('/en/unit3-2')" @keydown.enter="routeBtn('/en/unit3-2')" tabindex="0"><span class="number2" >3-2</span>Stage Performances and Films</p>
          <p @click="routeBtn('/en/unit3-3')" @keydown.enter="routeBtn('/en/unit3-3')" tabindex="0"><span class="number2" >3-3</span>Speech Forums</p>
          <p @click="routeBtn('/en/unit3-4')" @keydown.enter="routeBtn('/en/unit3-4')" tabindex="0"><span class="number2">3-4</span>Social Ballads</p>
          <p @click="routeBtn('/en/unit3-5')" @keydown.enter="routeBtn('/en/unit3-5')" tabindex="0"><span class="number2">3-5</span>Esperanto</p>
          <p @click="routeBtn('/en/unit4-1')" @keydown.enter="routeBtn('/en/unit4-1')" tabindex="0"><span class="number2">3-6</span>Monument of Time</p>
          <p @click="routeBtn('/en/unit4-2')" @keydown.enter="routeBtn('/en/unit4-2')" tabindex="0"><span class="number2">3-7</span>Cultural Bases</p>
          <p @click="routeBtn('/en/unit4-3')" @keydown.enter="routeBtn('/en/unit4-3')" tabindex="0"><span class="number2">3-8</span>Social Forums</p>
          <p @click="routeBtn('/en/unit4-4')" @keydown.enter="routeBtn('/en/unit4-4')" tabindex="0"><span class="number2">3-9</span>Changing Customs</p>
          <p @click="routeBtn('/en/unit4-5')" @keydown.enter="routeBtn('/en/unit4-5')" tabindex="0"><span class="number2">3-10</span>Our Own Memorial Days</p>
        </div>

        <div class="subtitle" style="margin-top: 0.2vw; left: -2vw" @click="routeBtn('/en/unit6-2')" @keydown.enter="routeBtn('/en/unit6-2')" tabindex="0">4-Conversation Across Time</div>
        <div class="subtitle" style="margin-top: 0.2vw; left: -2vw" @click="routeBtn('/en/person-intro-all')" @keydown.enter="routeBtn('/en/person-intro-all')" tabindex="0">5-People of Cultural Association</div>
      </div>
    </div>

    <FooterFeature language='en' ></FooterFeature>
  </div>
</template>

<script>
import EnToolbar from "@/components/EnToolbar.vue";
import FooterFeature from "@/components/FooterFeature";
import router from "@/router";

export default {
  name: "GuideView",
  components: {
    EnToolbar,
    FooterFeature
  },
  setup(){

    function routeBtn(path){
      router.push(path);
    }

    return{
      routeBtn,
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/en/Guide.css"></style>

