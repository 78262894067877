<template>
  <div class="wrap" @keydown.esc="escEvent">
    <toolbar type="2" unit="3" route-name="unit4-2" color="brown"></toolbar>

<!--    <img class="sample" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <div class="json-2">
        <LottieTest :json="require('@/assets/Unit4-2/4-2-1.json')" alt="文化基地" title="文化基地" ></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit4-2/20230703-物件-4-2-01-首圖１.png"  alt="文化基地" title="文化基地">
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-2/4-2-0.json')" alt="文化基地" title="文化基地" ></LottieTest>
      </div>
      <div class="title-logo">文化基地</div>
      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" style="width: 2.1vw; bottom: 26%; right: 51.2%;" src="../assets/common/zoom-btn.png" alt="詳細資訊" title="詳細資訊">
    </div>

    <div class="part2 part-color">
      <img class="logo-text" src="../assets/Unit4-2/20230415-物件-4-2-04-裝飾字1-讀報社.png" alt="讀報社" title="讀報社">
      <div class="content content-style">
        <p>文協成立之初，延用蔣渭水「文化公司」的理念，在各地開設提供新聞雜誌閱覽的「讀報社」。協會創辦後兩個月間，就在苑裡、草屯、彰化、北斗、員林、社頭、嘉義、高雄建立讀報社，而後在各地響應下繼續增設。</p>
        <p>讀報社初期陳列的是臺灣及日本的報紙雜誌，而後備有多種中國報紙雜誌，當中刊有關於殖民地解放運動的記事，則用紅筆「劃重點」，來喚起讀者注意。做為固定的據點，讀報社也成為會員、認同文協理念的民眾聚會場所，許多也綜合「臺灣民報發行所」、講習場地等機能。</p>
      </div>
      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-2/4-2-2.json')" alt="讀報社" title="讀報社"></LottieTest>
      </div>
      <img class="img-1" src="../assets/Unit4-2/20230415-物件-4-2-05-插畫１.png" alt="臺灣地圖" title="臺灣地圖">
    </div>

    <div class="part3 part-color">
      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-2/20230415-物件-4-2-06-pic2.png" alt="北港讀報社發會式紀念 1927年5月16日" title="北港讀報社發會式紀念 1927年5月16日">
        <img class="zoom-2-btn" @click="showImg[1]=true" @keydown.enter="showImg[1]=true" tabindex="0" style="width: 2.2vw; bottom: 6%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-1">
        <p class="title-style" style="color: #267c68">眾人來讀報</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">北港讀報社發會式紀念 1927年5月16日</p>
        <p class="source-style" style="margin-top: 0.3vw">蔣渭水文化基金會 提供</p>
        <p class="content-style" style="margin-top: 0.5vw">林麗明與蔡少庭籌設北港讀報社和讀書會，邀請鄭松筠及蔣渭水（前排左5、6）參加啟用式。</p>
      </div>

      <div class="content-2">
        <p class="title-style" style="color: #267c68">書報看到飽</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">大甲讀報會會則 1924年10月5日</p>
        <p class="source-style" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2020.032.0145</p>
        <p class="content-style" style="margin-top: 0.5vw">設有會長，幹事、常務員各2名。普通會員每月繳50錢，現場的新聞雜誌可以看到飽。</p>
      </div>

      <div class="book-wrap">
<!--          <img class="w-full relative" :style="book1Style" :src="book1">-->
<!--          <img class="w-full absolute top-0" :style="book2Style"  :src="book2">-->
<!--          <img class="w-full absolute top-0" :style="book3Style"  :src="book3">-->
        <div class="turnClass">
          <div id="flipbook" alt="大甲讀報會會則 1924年10月5日" title="大甲讀報會會則 1924年10月5日">
            <img class="page page-1 " :src="require('../assets/Unit4-2/20230703-物件-4-2-op-08-pic3-放大文物.png')">
            <img class="page page-2 " :src="require('../assets/Unit4-2/20230703-物件-4-2-op-09-pic4-放大文物.png')">
            <img class="page page-3 " :src="require('../assets/Unit4-2/20230703-物件-4-2-op-10-pic5-放大文物.png')">
          </div>
        </div>

        <transition name="img-frame-fade"><img v-if="bookPage>1" @click="bookLeftBtn" @keydown.enter="bookLeftBtn" tabindex="0" class="left-arrow"  src="../assets/common/left-arrow.png" alt="上一頁" title="上一頁"></transition>
        <img class="zoom-2-btn zoom-fade " @click="showImg[bookPage+1]=true" @keydown.enter="showImg[bookPage+1]=true" tabindex="0" style="width: 2.2vw; bottom: 5%; right: 3%; z-index: 5" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
        <transition name="img-frame-fade"><img v-if="bookPage<3" @click="bookRightBtn" @keydown.enter="bookRightBtn" tabindex="0" class="right-arrow"  src="../assets/common/right-arrow.png" alt="下一頁" title="下一頁"></transition>
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-2/4-2-3.json')" alt="眾人來讀報" title="眾人來讀報" ></LottieTest>
      </div>
    </div>

    <div class="part4 part-color">
      <img class="logo-text" src="../assets/Unit4-2/20230415-物件-4-2-13-裝飾字2-書局.png" alt="書局" title="書局">
      <div class="content-1 content-style">
        1926年之後文協周邊的文化機構更有發達，臺中文協要員集資成立「中央書局」，蔣渭水在臺北成立「文化書局」，臺南則有長期力挺無產青年立場的林占鰲主持的「興文齋書店」，後來謝雪紅於大稻埕成立「國際書局」販售左派圖書。這些具有長期運作、營利能力的文化機構，長久營運，成為影響近代人文風景的文化據點。
      </div>

      <div class="img-1">
        <img class="w-full" src="../assets/Unit4-2/20230415-物件-4-2-14-pic6.png" alt="文化書局 1926年7月11日刊登於《臺灣民報》廣告" title="文化書局 1926年7月11日刊登於《臺灣民報》廣告">
        <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="width: 2.2vw; bottom: 10%; right: 2%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">
      </div>
      <div class="content-2">
        <p class="title-style" style="color: #ffffff">新文化介紹機關</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">文化書局1926年7月11日刊登於《臺灣民報》廣告</p>
        <p class="source-style" style="margin-top: 0.3vw">出自《臺灣民報》</p>
      </div>
      <div class="img-2">
        <img class="w-full" src="../assets/Unit4-2/20230415-物件-4-2-17-pic7.png" alt="臺灣大眾時報創刊號上的國際書局廣告" title="臺灣大眾時報創刊號上的國際書局廣告">
<!--        <img class="zoom-2-btn" style="width: 2.2vw; bottom: 4%; right: 3%;" src="../assets/common/zoom-2-btn.png" alt="詳細資訊" title="詳細資訊">-->
      </div>
      <div class="content-3">
        <p class="title-style" style="color: #ffffff">臺灣唯一的戰鬥圖書販賣店</p>
        <p class="subtitle-style" style="margin-top: 0.5vw">臺灣大眾時報創刊號上的國際書局廣告</p>
        <p class="source-style" style="margin-top: 0.3vw">出自《臺灣大眾時報》</p>
      </div>

      <div class="json-1">
        <LottieTest :json="require('@/assets/Unit4-2/4-2-4.json')" alt="中央書局" title="中央書局"></LottieTest>
      </div>

      <div class="back-btn" @click="routeBtn('/enter')" @keydown.enter="routeBtn('/enter')" tabindex="0">
        <img src="../assets/common/left-arrow-white.png" alt="回廟埕拚文化" title="回廟埕拚文化">
        <p style="margin-left: 0.5vw">回廟埕拚文化</p>
      </div>
    </div>

    <!--寫這裡-->
    <!--img0--><!--hor-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[0]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-05.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:21vw; margin:4.3vw 0 0 7vw" src="../assets/Unit4-2/20230703-物件-4-2-op-03-pic1.png" alt="嘉義讀報社創立紀念攝影" title="嘉義讀報社創立紀念攝影">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 38.7vw; width: 20vw;">
            <div class="bold-title">嘉義讀報社創立紀念攝影</div>
            <div class="noto-text" style="margin-top: 0.2vw">國立臺灣文學館典藏 吳恪捐贈</div>
            <div class="content-style text-left" style="margin-top: 0.9vw">嘉義讀報社，同時也是「臺灣民報批發處」。坐者左一為文化協會1925年理事王甘棠。</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img1--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
        <!--  <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-04.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34.2vw; margin:3.2vw 0 0 14.9vw" src="../assets/Unit4-2/20230703-物件-4-2-op-06-pic2-放大文物.png" alt="北港讀報社發會式紀念 1927年5月16日" title="北港讀報社發會式紀念 1927年5月16日">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 21.3vw; width: 21.1vw;">
            <div >
              <div class="bold-title">北港讀報社發會式紀念 1927年5月16日</div>
              <div class="noto-text" style="margin-top: 0.3vw">蔣渭水文化基金會 提供</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-06.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.9vw; margin:2.3vw 0 0 14.2vw" src="../assets/Unit4-2/20230703-物件-4-2-op-08-pic3-放大文物.png" alt="大甲讀報會會則 1924年10月5日" title="大甲讀報會會則 1924年10月5日">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.5vw; width: 18vw;">
            <div >
              <div class="bold-title">大甲讀報會會則 1924年10月5日</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2020.032.0145</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-07.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:35.2vw; margin:2.3vw 0 0 14.2vw" src="../assets/Unit4-2/20230703-物件-4-2-op-09-pic4-放大文物.png" alt="大甲讀報會會則 1924年10月5日" title="大甲讀報會會則 1924年10月5日">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.5vw; width: 18vw;">
            <div >
              <div class="bold-title">大甲讀報會會則 1924年10月5日</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2020.032.0145</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4--><!--ver-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
        <!--<img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-08.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:36.2vw; margin:2.4vw 0 0 13.7vw" src="../assets/Unit4-2/20230703-物件-4-2-op-10-pic5-放大文物.png" alt="大甲讀報會會則 1924年10月5日" title="大甲讀報會會則 1924年10月5日">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 23.5vw; width: 18vw;">
            <div >
              <div class="bold-title">大甲讀報會會則 1924年10月5日</div>
              <div class="noto-text" style="margin-top: 0.3vw">國立臺灣歷史博物館 館藏 2020.032.0145</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5--><!--ver-->
    <transition name="img-frame-fade" @before-enter="resetPos" >
      <div class="img-frame-div"  v-show="showImg[5]">
        <!--                <img style="position: fixed; top: 13%; left: 18%; z-index: 10; opacity: 0.5; width: 63vw;" src="../assets/Unit4-2/sample/20230415-對位-4-2-op-09.png">-->

        <div class="black-bg" ></div>
        <div class="frame">

          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:52.6vw; margin:6.5vw 0 0 6.3vw" src="../assets/Unit4-2/20230703-物件-4-2-op-14-pic6-放大文物.png" alt="文化書局 1926年7月11日刊登於《臺灣民報》廣告" title="文化書局 1926年7月11日刊登於《臺灣民報》廣告">
          </div>

          <div class="content-text" style="position: absolute; top:31vw; left: 19.5vw; width: 26.2vw;">
            <div >
              <div class="bold-title">文化書局 1926年7月11日刊登於《臺灣民報》廣告</div>
              <div class="noto-text" style="margin-top: 0.3vw">出自《臺灣民報》</div>
            </div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="縮小" title="縮小" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="放大" title="放大" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="關閉視窗" title="關閉視窗" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>

    <intro-btn></intro-btn>
    <FooterFeature color="white"></FooterFeature>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar";
import router from "@/router";
import IntroBtn from "@/components/IntroBtn";
import LottieTest from "@/components/LottieTest";
import {onMounted, reactive, ref} from "vue";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import $ from "jquery";
import turn from "@/assets/utils/lib/turn";

export default {
  name: "Unit4-1",
  mixins: [myMixin],
  components:{
    toolbar,
    IntroBtn,
    LottieTest,
    FooterFeature
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();
    const showImg = reactive(Array.from({ length: 20 }, () => false));



    const book1 = ref(require('../assets/Unit4-2/08-pic3-翻頁動態/08-pic3-翻頁動態-00.png'));
    const book2 = ref(require('../assets/Unit4-2/09-pic4-翻頁動態/09-pic4-翻頁動態-00.png'));
    const book3 = ref(require('../assets/Unit4-2/20230415-物件-4-2-10-pic5.png'));
    const book1Style = ref('z-index:3;');
    const book2Style = ref('z-index:2; opacity:0;');
    const book3Style = ref('z-index:1; opacity:0;');
    const flipAudio = require('@/assets/Unit3-1/insert_paper.mp3');
    const flipEle = new Audio(flipAudio);

    function routeBtn(path){
      router.push(path);
    }

    function onTurn(){
      $("#flipbook").turn({
        acceleration:true,
        display: "single",
        //gradients: true,
        autoCenter: true,
        height:646,
        width:896,
        direction: "rtl",
      });


      $('#flipbook').turn('size', '100%', '100%');
      $("#flipbook").bind("turning", function(event, page, view) {
        bookPage.value = page;
        flipEle.play();
      });
      $("#flipbook").bind("start", function(event, pageObject, corner) {
        $('#flipbook').turn('size', 'auto', '100%');
      });

    }

    onMounted(()=>{
      onTurn();
      window.addEventListener('resize', ()=>{
        $('#flipbook').turn('size', 'auto', '100%');
      });
    });

    let bookId = null;
    let bookIndex = 0;
    const bookPage = ref(1);
    let booking = false;
    function bookRightBtn(){
      $("#flipbook").turn("next");
      return;
      if(booking || bookPage.value == 2) return;

      booking = true;
      bookIndex = 0;

      if(bookPage.value == 0){
        clearInterval(bookId);

        book2Style.value = 'z-index:2;';
        book3Style.value = 'z-index:1;';

        bookId = setInterval(()=>{

          bookIndex = (bookIndex + 1) % (47+1);

          book1.value = require('../assets/Unit4-2/08-pic3-翻頁動態/08-pic3-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex >=  47){
            book1Style.value = 'opacity:0;';
            clearInterval(bookId);
            bookPage.value=1;
            booking = false;
          }

        }, 50);
      }else if(bookPage.value==1){

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = (bookIndex + 1) % (47+1);
          book2.value = require('../assets/Unit4-2/09-pic4-翻頁動態/09-pic4-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex >=  47){
            book2Style.value = 'opacity:0;';
            clearInterval(bookId);
            bookPage.value=2;
            booking = false;
          }

        }, 50);
      }


    }

    function bookLeftBtn(){
      $("#flipbook").turn("previous");
      return;
      if(booking || bookPage.value == 0) return;
      booking = true;

      if(bookPage.value == 1){
        bookIndex = 47;

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = bookIndex-1;

          book1Style.value = "z-index:3";
          book1.value = require('../assets/Unit4-2/08-pic3-翻頁動態/08-pic3-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex <= 0){
            clearInterval(bookId);
            bookPage.value=0;
            booking = false;
          }

        }, 50);
      }else if(bookPage.value==2){
        bookIndex = 47;

        clearInterval(bookId);

        bookId = setInterval(()=>{

          bookIndex = bookIndex-1;

          book2Style.value = "z-index:2";
          book2.value = require('../assets/Unit4-2/09-pic4-翻頁動態/09-pic4-翻頁動態-'+bookIndex.toString().padStart(2, '0') +'.png');

          if(bookIndex <= 0){
            clearInterval(bookId);
            bookPage.value=1;
            booking = false;
          }

        }, 50);
      }

    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }

    return{
      routeBtn,
      showImg,
      book1,
      book2,
      book3,
      book1Style,
      book2Style,
      book3Style,
      bookPage,
      bookRightBtn,
      bookLeftBtn,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/Unit4-2.css"></style>
