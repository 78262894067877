<template>
  <div class="unit2-1-wrap" @keydown.esc="escEvent">
    <Entoolbar type="1" unit="2" route-name="unit2-1"  color="orange"></Entoolbar>

<!--    <img class="sample" src="../assets/Unit2-1/sample4.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="part1 part-color">
      <img class="building" src="../assets/Unit2-1/20230407-物件-2-1-01-首圖1.png">

      <div class="taiwan" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-0.json')" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan" ></LottieTest>
      </div>

      <div class="title-logo">Taiwanese People’s Taiwan</div>
    </div>

    <div class="part2 part-color">
      <div class="content" >
          <img style="width: 60vw" src="../assets/Unit2-1/en/20230706-EN網站-2-1-物件-02-裝飾字1-議會請願.png" alt="Parliamentary Petition" title="Parliamentary Petition">
          <p tabindex="0" style="margin-top:1vw">In the first four years after the formation of the Taiwan Cultural Association, it focused on popularizing civilian education such as newspaper reading groups, seminars, and summer schools. It also promoted Taiwanese autonomy and the establishment of a parliament.</p>
          <p style="margin-top:1vw">The parliamentary petition emerged as a notable political movement during the Japanese colonial period. In January 1921, Lin Hsien-tang spearheaded the effort by submitting petition signatures to the Japanese Imperial Diet, demanding the establishment of a Taiwanese parliament composed of members elected by the Taiwanese people. This parliament would hold substantial powers, including the authority to enact special legislation and allocate budgetary support. Over the following 14 years, until 1934, petitions were submitted 15 times, with the number of signatures peaking at 2,400. This high-profile petition movement prompted discussions in the Japanese parliament about the fundamental issue of colonial governance in Taiwan, ultimately raising political awareness of the Taiwanese people about self-governance and autonomy.</p>
      </div>

      <img class="building" src="../assets/Unit2-1/20230407-物件-2-1-03-pic1.png" title="The Taiwan Parliament Petition Movement Group Arrives at Tokyo Station in 1925" alt="The Taiwan Parliament Petition Movement Group Arrives at Tokyo Station in 1925">

      <div class="people" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-1.json')" alt="people" title="people"></LottieTest>
      </div>

      <img class="zoom-btn" @click="showImg[0]=true" @keydown.enter="showImg[0]=true" tabindex="0" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
    </div>

    <div class="part3 part-color">
      <div class="pics-div">
        <img src="../assets/Unit2-1/20230407-物件-2-1-05-pic2.png" title="Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet" alt="Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet">
        <img style="margin-left: 1.5vw" src="../assets/Unit2-1/20230407-物件-2-1-06-pic3.png" title="Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet" alt="Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet">
      </div>
      <img class="zoom-2-btn" @click="showImg[1]=true"  @keydown.enter="showImg[1]=true" tabindex="0" style="top: 51.3%; left: 26.6%; width: 2.1vw" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information"
      >
      <img class="zoom-2-btn" @click="showImg[2]=true" @keydown.enter="showImg[2]=true" tabindex="0" style="top: 52%; left: 81.3%; width: 2.1vw" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information"
      >

      <div class="content">
        <p>Invitation to a Welcome Home Banquet</p>
        <p>Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet</p>
        <p>National Museum of Taiwan History 2020.032.0143.0001~2</p>
        <p>This is the letter Lin Hsien-tang sent to Du Xiang-guo to invite him to attend the Petition Committee Member Welcome Banquet. Committee members included Chiang Wei-shui, Tsai Pei-huo, and Hong Yuan-huang and the banquet was held at Yongle Building in Taichung and costs were covered by membership fees. In the letter, Lin Hsien-tang also asked Du Xiang-guo to invite other interested parties to attend. This invitation is a window on how association members socialized and the importance they placed on the parliamentary petition movement.  </p>
      </div>
    </div>

    <div class="part4 part-color" ref="part4">
      <div class="circle-wrap">
        <div class="circle up-semi part-color" >

          <div class="content">
            <img style="width: 60vw" src="../assets/Unit2-1/en/20230706-EN網站-2-1-物件-08-裝飾字2-治警事件.png" alt="Security Police Law" title="Security Police Law">
            <p tabindex="0">In 1923, with the long-term goal of establishing a parliament, Tsai Pei-huo and Chiang Wei-shui founded the “Taiwan Parliament Founding Alliance.” They organized events in Tokyo and various locations throughout Taiwan. However, that same year, the Security Police Law was implemented. Under pressure from the Governor-General's Office, the Taiwan Cultural Association agreed to avoid political issues. Despite this, by the end of the year, the Taiwan Parliament Founding Alliance was banned, and key members, including Tsai Pei-huo and Chiang Wei-shui, were arrested. Following more than a year of legal proceedings, several core members were sentenced to prison in what came to be known as the “The Event of Law-breaking of Security Police Law.” Notable individuals like Chiang Wei-shui, Tsai Pei-huo, Chen Feng-yuan, Lin You-chun, and Wang Min-chuan, who were imprisoned as a result of this incident, left behind numerous poetic works.</p>
          </div>

          <div class="people" >
            <LottieTest :json="require('@/assets/Unit2-1/2-1-2.json')" alt="Security Police Law" title="Security Police Law"></LottieTest>
          </div>

        </div>
      </div>


    </div>

    <div class="part5 part-color" >
      <img class="head-img" src="../assets/Unit2-1/20230407-物件-2-1-09-pic4.png" alt="Tsai Pei-huo" title="Tsai Pei-huo">

      <div class="people" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-3.json')" alt="Tsai Pei-huo was arrested." title="Tsai Pei-huo was arrested." ></LottieTest>
      </div>

      <div class="content">
        <p class="title">Indignant Prison Song</p>
        <p class="subtitle" style="margin-top: 0.8vw">"Taiwan Autonomy Song" sung by Tsai Pei-huo without accompaniment </p>
        <p class="source" style="margin-top: 0.4vw">Sound : Courtesy of Cai Fu-hua | Photo : Wikimidia, Public Domin</p>
        <p class="text" style="margin-top: 0.4vw">Tsai Pei-huo wrote the “Taiwan Autonomy Song” while imprisoned in Tainan, then later composed the music himself, turning it into a folk song. This is a recording of Tsai Pei-huo singing the song in his later years. </p>
        <div class="lyrics">
          <p><span class="number">Verse 1:</span>Penglai's beautiful island is lovely Our ancestral heritage is here Fields and gardens we till</p>
          <p>trees we plant Toiling generation after generation</p>
          <p>Understand, understand We are pioneers Not mindless servants</p>
          <p>Taiwan must gain autonomy soon Public affairs should be in our hands</p>
          <p><span class="number">Verse 2:</span>Yushan overshadows Fusang Our spirits soar</p>
          <p>With love of our homeland in our hearts We do not fear your power</p>
          <p>Who can stop us, who can stop us Let's champion autonomy together Proclaim with one voice</p>
          <p>Fulfill all of our obligations We should enjoy the right to autonomy</p>
        </div>

        <audio-comp :audio-src="require('../assets/Unit2-1/song.mp3')" style-class="orange-style" volume-style="left:81%;"></audio-comp>

      </div>

    </div>

    <div class="part6 part-color" ref="part6">
<!--      <img class="absolute" style="top: -1%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample5.png">-->
      <div class="circle-wrap">
        <div class="circle down-semi part-color" >

          <transition name="img-frame-fade" >
            <div class="black-circle" v-show="showMember==1" id="black-circle">
              <div>
                <p style="margin-left: 5vw; width: 15vw; text-align: center; line-height: 2.5vw">Photo Upon Release from Prison</p>
              </div>
              <div>
                <p style="line-height: 1.5vw">This snapshot, taken after the Event of Law-breaking of Security Police Law, commemorates the parole of some participants. Take a closer look. Can you name the released defendants in the photograph? </p>
              </div>

              <div class="answer-btn" @click="showMember=2;" @keydown.enter="showMember=2;" tabindex="0" alt="See the answer" title="See the answer">See the answer</div>
            </div>
          </transition>

          <transition name="img-frame-fade" >
            <div class="black-circle" v-show="showMember==2" id="black-circle">
              <div>
                <p style="margin-left: 3.5vw; width: 18vw; text-align: center; line-height: 2.5vw">The Defendants Stand Bareheaded </p>
              </div>
              <div>
                <p style="line-height: 1.5vw">This photo, taken on February 18, 1924, captures the moment when the first parole was granted for participants in the event. The hatless individuals are the defendants. From left to right, they are: Zheng Song-yun, Shi Huan-chang, Chiang Wei-shui, and Tsai Pei-huo. Those wearing hats have come to welcome them home.</p>
              </div>
            </div>
          </transition>

          <div :class="showMember==2? 'show-answer' : ''">
            <img class="question-img" src="../assets/Unit2-1/20230407-物件-2-1-16-pic4.png" title="Photo Upon Release from Prison" alt="Photo Upon Release from Prison">
            <img class="focus-img" src="../assets/Unit2-1/20230407-物件-2-1-19-pic6.png" alt="From left to right, they are: Zheng Song-yun, Shi Huan-chang, Chiang Wei-shui, and Tsai Pei-huo." title="From left to right, they are: Zheng Song-yun, Shi Huan-chang, Chiang Wei-shui, and Tsai Pei-huo.">
            <img class="focus-zoom-img" src="../assets/Unit2-1/20230407-物件-2-1-20-pic7.png" alt="From left to right, they are: Zheng Song-yun, Shi Huan-chang, Chiang Wei-shui, and Tsai Pei-huo." title="From left to right, they are: Zheng Song-yun, Shi Huan-chang, Chiang Wei-shui, and Tsai Pei-huo.">
            <img class="zoom-btn" @click="showImg[3]=true" @keydown.enter="showImg[3]=true" tabindex="0" style="top: 65%; left: 85.2%; width: 1.5%" src="../assets/common/zoom-btn.png" alt="more information" title="more information">
          </div>

          <div class="provide">Courtesy of the Chiang Wei-shui Cultural and Educational Foundation</div>
        </div>
      </div>


    </div>

    <div class="part7 part-color">
      <div class="content">
        <img src="../assets/Unit2-1/en/20230706-EN網站-2-1-物件-21-裝飾字3-無力者大會.png" alt="General Assembly of the Powerless" title="General Assembly of the Powerless">
        <p tabindex="0">After the second conference in December 1923, the headquarters of the Taiwan Cultural Association relocated to Tainan to develop youth organizations, speeches, and regular lectures, and to seek subscribers to the <i>Taiwan Min Pao</i> newspaper. In June 1924, the Taiwan Culture Association organized the “General Assembly of the Powerless” to confront the Governor-General, responding to those who criticized the campaign for autonomy and winning widespread public support.</p>
      </div>
      <div class="people" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-4.json')"  alt="General Assembly of the Powerless" title="General Assembly of the Powerless"></LottieTest>
      </div>
    </div>

    <div class="part8 part-color">
      <div class="content-1">
        <img style="width: 18.7vw; height: 22.15vw;" src="../assets/Unit2-1/20230407-物件-2-1-22-pic8.png" title="Leaflet for the General Assembly of the Powerless" alt="Leaflet for the General Assembly of the Powerless">
        <div class="en-bar" style="height: 22.2vw; overflow: auto">
          <p>Challenge the General Assembly of the Powerful!</p>
          <P>Leaflet for the General Assembly of the Powerless</P>
          <p>Courtesy of Formosa Vintage Museum</p>
          <p>The leaflet states that the event is in response to the General Assembly of the Powerful held the previous month. Three events were held in the north, central, and south regions, featuring speakers and debates. The assembly represents a major event organized by the Taiwan Cultural Association. Lin Hsien-tang also lent the Lin family ancestral hall as a venue, and it was reported that over a thousand people attended.</p>
        </div>
        <img class="zoom-2-btn" @click="showImg[4]=true" @keydown.enter="showImg[4]=true" tabindex="0" style="bottom: 2.5%; right: 60.5%; width: 2.2vw" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>

      <div class="content-2">
        <img style="width: 9.2vw;"  src="../assets/Unit2-1/20230407-物件-2-1-23-pic9.png" title="The Resolution of the General Assembly of the Powerful" alt="The Resolution of the General Assembly of the Powerful" >
        <img class="zoom-2-btn" @click="showImg[5]=true" @keydown.enter="showImg[5]=true" tabindex="0" style="bottom: 4.5%; right: 9.5%; width: 2.2vw" src="../assets/common/zoom-2-btn.png" alt="more information" title="more information">
      </div>
      <div class="source">
        <p>The Resolution of the General Assembly of the Powerful</p>
        <p><i>The Taiwan Minpao</i>1924/08/11</p>
      </div>

      <div class="content-3">
        <p>Declaration of the Island-wide General Assembly of the Powerless</p>
        <p class="en-bar" style="height: 25vw; overflow: auto">The gentry class has no understanding of politics or law, yet they dare to raise the banner of rebellion against humanism and justice. In the twentieth century, corrupt elements such as these should be eradicated. A man with a selfish and self-serving spirit by the name of Koo recently had the audacity to call himself a “powerful person” and advocated a convention to oppose this most reasonable and orderly movement. Koo’s past is well-known, so he is not to be blamed, but if he is allowed to have his way, I am afraid that the general public will become suspicious. Therefore, we have to do something substantial to draw the attention of our brothers all over the island.</p>
      </div>

      <img class="left-text-img" src="../assets/Unit2-1/20230407-物件-2-1-25-插圖1.png" alt="The Resolution of the General Assembly of the Powerless" title="The Resolution of the General Assembly of the Powerless">
      <img class="right-text-img" src="../assets/Unit2-1/20230407-物件-2-1-26-插圖2.png" alt="The Resolution of the General Assembly of the Powerful" title="The Resolution of the General Assembly of the Powerful">

      <div class="left-people" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-6.json')" alt="powerless person" title="powerless person"></LottieTest>
      </div>
      <div class="right-people" >
        <LottieTest :json="require('@/assets/Unit2-1/2-1-5.json')" alt="powerful person" title="powerful person"></LottieTest>
      </div>

    </div>

    <div class="part9 part-color">
      <div class="unit-btn" style="top: 23%; left: 12%; " @click="routeBtn('/en/unit2-0')" @keydown.enter="routeBtn('/en/unit2-0')" tabindex="0">
        <img class="w-full"  src="../assets/Unit2-0/文協向前衝按鈕.png" alt="The Taiwan Cultural Association Forges Ahead" title="The Taiwan Cultural Association Forges Ahead">
        <p>The Taiwan Cultural</p>
        <p>Association Forges Ahead</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 38.5%; ">
        <img class="w-full" src="../assets/Unit2-0/20230721-2-1-物件-30-臺灣人的臺灣按鈕-淡.png" alt="Taiwanese People’s Taiwan" title="Taiwanese People’s Taiwan" @click="routeBtn('/en/unit2-1')" @keydown.enter="routeBtn('/en/unit2-1')" tabindex="0">
        <p>Taiwanese People’s</p>
        <p>Taiwan</p>
      </div>
      <div class="unit-btn" style="top: 23%; left: 65%; ">
        <img class="w-full" src="../assets/Unit2-0/為農工大眾主張按鈕.png" alt="Advocating for Farmers and Workers" title="Advocating for Farmers and Workers" @click="routeBtn('/en/unit2-2')" @keydown.enter="routeBtn('/en/unit2-2')" tabindex="0">
        <p>Advocating for</p>
        <p>Farmers and Workers</p>
      </div>
      <div class="unit-btn" style="top: 57.5%; left: 25%; ">
        <img class="w-full" src="../assets/Unit2-0/左派與右派按鈕.png" alt="The Left and The Right" title="The Left and The Right" @click="routeBtn('/en/unit2-3')" @keydown.enter="routeBtn('/en/unit2-3')" tabindex="0">
        <p>The Left and The Right</p>
      </div>
      <div class="unit-btn" style="top: 57.5%; left: 52%; ">
        <img class="w-full" src="../assets/Unit2-0/政治結社的走向按鈕.png" alt="The Trend of Political Association" title="The Trend of Political Association" @click="routeBtn('/en/unit2-4')" @keydown.enter="routeBtn('/en/unit2-4')" tabindex="0">
        <p>The Trend of</p>
        <p>Political Association</p>
      </div>
    </div>

    <!--img1-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[0]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample6.png">-->

        <div class="black-bg"></div>
        <div class="frame">
          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:34.1vw; margin:3.7vw 0 0 4vw;"    src="../assets/Unit2-1/20230703-物件-2-1-03-pic1-放大文物.png" alt="The Taiwan Parliament Petition Movement Group Arrives at Tokyo Station in 1925" title="The Taiwan Parliament Petition Movement Group Arrives at Tokyo Station in 1925">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw;  width: 30vw; left: 54vw" >
            <div class="bold-title-b">The Taiwan Parliament Petition Movement Group Arrives at Tokyo Station in 1925</div>
            <div class="noto-text">Courtesy of Academic Institute of Taiwan History, Academia Sinica Archives</div>
            <div class="sub-title" style="white-space: normal; text-align: left!important;">Taiwanese living in Japan greeted the petition group at Tokyo Station with flags waving. Large flags were inscribed with “Welcome Taiwan Parliament Petition Group” while small flags featured eye-catching slogans such as “Freedom”, “Equality”, and “Justice”, which symbolized the cherished ideals that the group and their supporters passionately pursued.</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[0]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img2-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[1]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample7.png">-->

        <div class="black-bg"></div>
        <div class="frame">

          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:11.6vw; margin:2.3vw 0 0 26.5vw" src="../assets/Unit2-1/20230703-物件-2-1-05-pic2-放大文物.png" alt="Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet" title="Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet">
          </div>

          <div class="content-text">
            <div class="bold-title">Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet</div>
            <div class="noto-text">National Museum of Taiwan History 2020.032.0143.0001</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[1]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img3-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[2]">
<!--          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample8.png">-->

        <div class="black-bg"></div>
        <div class="frame">

          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:42.6vw; margin:8.5vw 0 0 10.8vw" src="../assets/Unit2-1/20230703-物件-2-1-06-pic3-放大文物.png" alt="Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet" title="Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet">
          </div>

          <div class="content-text" >
            <div class="bold-title">Invitation from Lin Hsien-tang to Du Xiang-guo to attend a parliamentary petition committee welcome banquet</div>
            <div class="noto-text">National Museum of Taiwan History 2020.032.0143.0002</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[2]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img4-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[3]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample9.png">-->

        <div class="black-bg"></div>
        <div class="frame">

          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:34.1vw; margin:3.7vw 0 0 4vw" src="../assets/Unit2-1/20230703-物件-2-1-17-pic4-放大文物.png" alt="Photo Upon Release from Prison" title="Photo Upon Release from Prison">
          </div>

          <div class="content-text" style="position: absolute; top:4.5vw; left: 60vw; width: 30vw;">
            <div class="bold-title">Photo Upon Release from Prison</div>
            <div class="noto-text" >Courtesy of the Chiang Wei-shui Cultural and Educational Foundation</div>
            <div class="sub-title" style="white-space: normal; text-align: left!important;" >Following the Security Police Incident, Chiang Wei-shui was imprisoned twice. This photo, taken on February 18, 1924, captures the moment after Chiang had been in prison for 64 days and was finally granted his first parole. The individuals standing without hats are the defendants. From left to right, they are: Zheng Song-yun, Shi Huan-chang, Chiang Wei-shui, and Tsai Pei-huo. The people wearing hats have come to welcome them home.</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[3]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img5-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[4]">
<!--        <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample10.png">-->

        <div class="black-bg"></div>
        <div class="frame">

          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
           <img class="relative" style="width:29.5vw; margin:1.3vw 0 0 17.4vw" src="../assets/Unit2-1/20230703-物件-2-1-22-pic8-放大文物.png" alt="Leaflet for the General Assembly of the Powerless" title="Leaflet for the General Assembly of the Powerless">
          </div>

          <div class="content-text" >
            <div class="bold-title">Leaflet for the General Assembly of the Powerless</div>
            <div class="noto-text" style="margin-top: 0.4vw">Courtesy of Formosa Vintage Museum</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[4]=false">
          </div>
        </div>
      </div>
    </transition>
    <!--img6-->
    <transition name="img-frame-fade"  @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showImg[5]">
<!--          <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit2-1/sample11.png">-->

        <div class="black-bg"></div>
        <div class="frame">

          <div :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="relative" style="width:6.6vw; margin:2.5vw 0 0 29.2vw" src="../assets/Unit2-1/20230703-物件-2-1-23-pic9-放大文物.png" alt="The Resolution of the General Assembly of the Powerful" title="The Resolution of the General Assembly of the Powerful">
          </div>

          <div class="content-text">
            <div class="bold-title">The Resolution of the General Assembly of the Powerful</div>
            <div class="noto-text" style="margin-top: 0.4vw"><i>The Taiwan Minpao</i> 1924/08/11</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image"  @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showImg[5]=false">
          </div>
        </div>
      </div>
    </transition>


    <EnIntroBtn></EnIntroBtn>
    <FooterFeature language='en'  color="black"></FooterFeature>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import router from "@/router";
import EnIntroBtn from "@/components/EnIntroBtn";
import {onMounted, onUnmounted, reactive, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin";
import AudioComp from "@/components/AudioComp";


export default {
  name: "Unit2-1",
  mixins: [myMixin],
  components:{
    AudioComp,
    Entoolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();

    const pic = ref(1);
    const part4 = ref();
    const part6 = ref();


    const showMember = ref(1);
    const showImg = reactive(Array.from({ length: 20 }, () => false));



    function routeBtn(path){
      router.push(path);
    }

    function nextBtn(){
      pic.value  += 1;
      if(pic.value >= 3){
        pic.value = 3;
      }
    }

    function prevBtn(){
      pic.value -= 1;
      if(pic.value <= 1){
        pic.value = 1;
      }
    }

    function escEvent(){
      for (let i=0; i < showImg.length; i++){
        showImg[i] = false;
      }
    }


    onMounted(()=>{
      window.addEventListener('scroll', scrollEvent);

    });

    onUnmounted(()=>{
      window.removeEventListener('scroll', scrollEvent)
    });

    function scrollEvent(){
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollTriggerTop = part4.value.offsetTop - window.innerHeight;
      const scrollPercentage = Math.max(0, Math.min(1, (scrollTop - scrollTriggerTop) / window.innerHeight));
      const upSemi = document.querySelector('.up-semi');
      upSemi.style.width = (117*scrollPercentage)+"vw";
      upSemi.style.height = (105*scrollPercentage)+"vw";


      const scrollTop2 = window.pageYOffset || document.documentElement.scrollTop;
      const scrollTriggerTop2 = part6.value.offsetTop - window.innerHeight;
      const scrollTriggerMiddle2 = scrollTriggerTop2 + (part6.value.offsetHeight * 0.9);
      const scrollPercentage2 = Math.max(0, Math.min(1, (scrollTop2 - scrollTriggerMiddle2) / window.innerHeight));

      const downSemi = document.querySelector('.down-semi');
      //document.getElementById('black-circle').style.fontSize = (1-(1*scrollPercentage2*1.5))+"vw";
      //console.log((1-(1*scrollPercentage2)))
      downSemi.style.width = (117-(117*scrollPercentage2))+"vw";
      downSemi.style.height = (105-(105*scrollPercentage2))+"vw";
    }


    return{
      routeBtn,
      pic,
      part4,
      part6,
      nextBtn,
      prevBtn,
      showMember,
      showImg,
      scaleSize,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent
    }
  }
}
</script>



<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit2-1.css"></style>




