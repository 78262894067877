<template>
  <div class="unit1-1-wrap relative" @keydown.esc="escEvent">
    <entoolbar type="1" unit="1" route-name="unit1-1" color="brown"  :style="toolbarStyle"  ></entoolbar>

<!--        <img class="sample" src="../assets/Unit1-1/sample/20230821-EN網站-對位-1-op-01.png">-->

    <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
    <div class="video-wrap w-full relative z-10" >
      <video muted loop autoplay  playsinline class="w-full">
        <source src="../assets/Unit1-1/video.mp4" type="video/mp4">
      </video>
      <div class="big-title">Skyview of the World</div>
    </div>

    <div class="relative w-full z-10" >
      <img class="absolute w-full" style=" top: -13vw" src="../assets/Unit1-1/cloud.png" alt="cloud" title="cloud">
    </div>

    <div class="part1">
      <div class="airplane-1"  alt="airplane" title="airplane">
        <img src='@/assets/Unit1-1/airplane-1.png' alt="airplane" title="airplane">
        <div class="pilot" alt="pilot" title="pilot">
          <LottieTest :json="require('@/assets/Unit1-1/01.json')" ></LottieTest>
        </div>
      </div>

      <div class="content">
        <div>
          <img src="../assets/Unit1-1/en/20230424-EN網站-1-鳥瞰世界-物件-op-25-裝飾文字1.png" alt="flight" title="flight">
        </div>
        <div tabindex="0">
          <p>In 1917, Hsieh Wen-ta, a 16-year-old boy in Taichung, looked up at the sky and watched an air show featuring Arthur Smith, a stunt pilot from the United States. Three years later, he graduated from a Japanese flight school and became the first Taiwanese-born pilot in history.</p>
          <p style="margin-top: 1.6vw">From August to October 1920, Hsieh paid flying visits to Taichung and Taipei, attracting public attention. Students at the Taipei Medical School and the Normal School were particularly excited and held support events. On September 23, the “Hsieh Wen-ta Flying Support” attracted thousands of people, and was the largest gathering of youth ever in local history. On October 17, Hsieh flew the airplane he named “Hsin Gao” to Taichung for an air show. Later, he flew north to the Taipei Military Training Ground for another show.</p>
          <p style="margin-top: 1.6vw">The Taiwan Cultural Association was established on the first anniversary of his Taichung air show.</p>
        </div>
      </div>

      <div class="down-arrow" alt="Please continue exploring" title="Please continue exploring">
        <div>Please continue exploring</div>
        <LottieTest :json="require('@/assets/Unit1-1/down.json')" ></LottieTest>
      </div>

    </div>

    <div class="part2">
      <img class="airplane-2" src='@/assets/Unit1-1/airplane-2.png' alt="airplane" title="airplane">
      <div class="cloud-anim-1" alt="cloud" title="cloud">
        <LottieTest :json="require('@/assets/Unit1-1/02.json')" ></LottieTest>
      </div>
      <div class="cloud-anim-2" alt="cloud" title="cloud">
        <LottieTest :json="require('@/assets/Unit1-1/03.json')" ></LottieTest>
      </div>

      <div class="content" tabindex="0">
        <div>Cooperation began with a flight …</div>
        <div>This is the “Hsin Kao”, a 1920 Itoh Emi 5 aircraft flown by Hsieh Wen-ta. This propeller airplane will take us up and give us “a bird’s eye view” of Taiwan 100 years ago. This scene shows Hsieh Wen-ta, Taiwan’s first airplane pilot, soaring in the sky on return his home.</div>
        <div style="margin-top: 1.6vw">The aircraft he flew was manufactured in the USA, then purchased and modified by flight school founder Itoh Otojiroh. Hsieh’s family reportedly had to sell three sugar factories to buy it.</div>
      </div>

      <div class="people" alt="character illustration" title="character illustration">
        <LottieTest :json="require('@/assets/Unit1-1/04.json')" ></LottieTest>
      </div>
    </div>

    <div class="part3">

    </div>


    <div class="part4" >
      <div class="fly-paper-wrap">
<!--        <img class="fly-paper" :style="flyPaperStyle" src="../assets/Unit1-1/fly-paper.png" alt="Paper" title="Paper">-->
        <transition name="img-frame-fade">
          <img v-if="flyPaperSrc!=''" class="fly-paper" :style="flyPaperStyle" :src="flyPaperSrc" alt="Paper" title="Paper">
        </transition>
      </div>

      <img class="airplane-3" src="../assets/Unit1-1/airplane-3.png" alt="airplane&pilot" title="airplane&pilot">
      <img class="paper-2" src="../assets/Unit1-1/paper-2.png" alt="News stories about Hsieh Wen-ta’s flying" title="News stories about Hsieh Wen-ta’s flying">
      <img class="paper-1" src="../assets/Unit1-1/paper-1.png" alt="News stories about Hsieh Wen-ta’s flying" title="News stories about Hsieh Wen-ta’s flying">
      <img class="paper-3" src="../assets/Unit1-1/paper-3.png" alt="News stories about Hsieh Wen-ta’s flying" title="News stories about Hsieh Wen-ta’s flying">
      <img class="zoom-btn" @click="showPaperImg=true" @keydown.enter="showPaperImg=true" tabindex="0" src="../assets/common/zoom-btn.png" alt="more information" title="more information">

      <div class="content" ref="flypaper">
        <img class="logo" src="../assets/Unit1-1/en/20230424-EN網站-1-鳥瞰世界-物件-op-25-裝飾文字2.png" alt="Taipei" title="Taipei">
        <div tabindex="0">
          <p>Before Hsieh Wen-ta’s upcoming performances, the press reported that his aircraft was too old to be repaired. The flying club received many donations, totaling around 25,000 Taiwanese yen, enabling the purchase of a three-seat aircraft from Ito Flight Manufacturing. The plane was named “Taipei.”</p>
          <p style="margin-top: 1.6vw">The enthusiastic fund-raising campaign and the popularity of youth associations pointed to the impending formation of the Cultural Association.
          </p>
        </div>
      </div>

      <img class="airplane-4" src="../assets/Unit1-1/airplane-4.png" alt="airplane" title="airplane">



    </div>

    <div class="part5 part-color">
      <img class="tree-1" src="../assets/Unit1-1/tree-1.png" alt="tree" title="tree">
      <img class="tree-2" src="../assets/Unit1-1/tree-2.png" alt="tree" title="tree">

      <div class="people" alt="character illustration" title="character illustration">
        <LottieTest :json="require('@/assets/Unit1-1/05.json')" ></LottieTest>
      </div>

      <div class="cloud" alt="cloud" title="cloud">
        <LottieTest :json="require('@/assets/Unit1-1/06.json')" ></LottieTest>
      </div>
      <div class="people-2" alt="character illustration" title="character illustration">
        <LottieTest :json="require('@/assets/Unit1-1/07.json')" ></LottieTest>
      </div>
      <div class="paper" ref="paper" alt="leaflet" title="leaflet">
        <LottieTest :json="require('@/assets/Unit1-1/08.json')" ></LottieTest>
      </div>

      <img class="airplane-5" ref="airplane5"  src="../assets/Unit1-1/airplane-5.png" alt="airplane" title="airplane">


      <div class="content">
        <img class="logo" src="../assets/Unit1-1/en/20230424-EN網站-鳥瞰世界-物件-op-25-裝飾文字3.png" alt="Mission" title="Mission">
        <div tabindex="0">
          In February 1923, Hsieh flew it over Tokyo to distribute leaflets titled “Fight Against the Governor’s Dictatorship and Establish the Taiwan Parliament” in support of petitions for greater democracy in Taiwan. Later, he also participated in the Social Issues Study Club, and he was committed to “social movements for the proletariat”
        </div>
      </div>
    </div>

    <div class="part6 part-color">
      <div class="unit-btn-wrap">
        <div class="unit-btn" style="width: 16.3vw" @click="routeBtn('/en/unit1-1')" @keydown.enter="routeBtn('/en/unit1-1')" tabindex="0">
          <img src="../assets/Unit1-1/20230801-1-鳥瞰世界-物件-21-鳥瞰世界按紐-淡.jpg"  alt="Skyview of the World" title="Skyview of the World">
          <div ><p>Skyview of</p><p>the World</p></div>
        </div>
        <div class="unit-btn" @click="routeBtn('/en/unit1-2')" @keydown.enter="routeBtn('/en/unit1-2')" tabindex="0">
          <img src="../assets/Unit1-1/unit1-2.png"  alt="The World Rocked by New Thinking" title="The World Rocked by New Thinking">
          <div><p>The World Rocked</p><p>by New Thinking</p></div>
        </div>
      </div>
    </div>


    <transition name="img-frame-fade"   @before-enter="resetPos">
      <div class="img-frame-div"  v-show="showPaperImg">
        <!--                <img style="position: fixed; top: 18%; left: 18%; z-index: 10; opacity: 0.5" src="../assets/Unit1-1/sample-2.png">-->

        <div class="black-bg" ></div>
        <div class="frame">
          <div class="absolute w-full h-full" :style="'transform: scale('+ scaleSize +') translate(' + x +'px,'+ y +'px)'"  @mousedown="dragStart" @mousemove="drag" @mouseup="dragEnd">
            <img class="paper-1" src="../assets/Unit1-1/paper-zoom-1.png" alt="News stories about Hsieh Wen-ta’s flying" title="News stories about Hsieh Wen-ta’s flying">
            <img class="paper-2" src="../assets/Unit1-1/paper-zoom-2.png" alt="News stories about Hsieh Wen-ta’s flying" title="News stories about Hsieh Wen-ta’s flying">
            <img class="paper-3" src="../assets/Unit1-1/paper-zoom-3.png" alt="News stories about Hsieh Wen-ta’s flying" title="News stories about Hsieh Wen-ta’s flying">
          </div>
          <div class="content-text-2">
            <div class="bold-title">News stories about Hsieh Wen-ta’s flying</div>
            <div class="noto-text"><p>Clippings Taiwan Daily News</p><p>1920/08/29 | 1920/09/12 | 1920/08/28</p></div>
            <div class="sub-title" style="text-align: left">Hsieh Wen-ta’s activities were a focal point of the news for three years, from his air shows, aircraft maintenance, and raising funds to purchase a new plane to his dropping of leaflets petitioning the establishment of a Taiwan Parliament over Tokyo in 1923, and the announcement of his retirement.</div>
          </div>

          <div class="feature">
            <img class="zoom-out" src="../assets/common/20230629-2-0-物件-98-縮小減號.png" alt="Reduce the image" title="Reduce the image" @click="changeSize('minus')">
            <img class="zoom-in" src="../assets/common/20230629-2-0-物件-97-放大加號.png" alt="Enlarge the image" title="Enlarge the image" @click="changeSize('plus')">
            <img class="close" src="../assets/common/20230629-2-0-物件-82-彈跳視窗-叉叉.png" alt="close" title="close" @click="showPaperImg=false">
          </div>

        </div>
      </div>
    </transition>


    <en-intro-btn></en-intro-btn>

    <FooterFeature language='en'></FooterFeature>
  </div>
</template>

<script>
import Entoolbar from "@/components/EnToolbar";
import EnIntroBtn from "@/components/EnIntroBtn";
import router from "@/router";
import {onMounted, onUnmounted, ref} from "vue";
import LottieTest from "@/components/LottieTest";
import FooterFeature from "@/components/FooterFeature";
import myMixin from "@/assets/common/js/mixin"

export default {
  name: "Unit1-1",
  mixins: [myMixin],
  components:{
    Entoolbar,
    EnIntroBtn,
    LottieTest,
    FooterFeature,
  },
  setup(){
    const { scaleSize, changeSize, dragStart, drag, dragEnd, resetPos, x, y} = myMixin.setup();

    const showPaperImg = ref(false);
    const toolbarStyle = ref("background: rgba(255,255,255,0.75)");

    function routeBtn(path){
      router.push(path);
    }

    const airplane5 = ref(null);
    const paper = ref(null);
    const flyPaperStyle = ref('');
    const flyPaperSrc = ref('');
    const flypaper = ref(null);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          //console.log('目標元素進入畫面');
          if(entry.target == airplane5.value){
            airplane5.value.classList.add('airplane-5-anim');
          }
          else if(entry.target == paper.value){
            paper.value.classList.add('paper-anim');
          }
          else if(entry.target == flypaper.value){
            flyPaperAnim();
          }
        }
      });
    }, {
      root: null, // 觀察器根節點，null 表示整個瀏覽器視窗
      threshold: 0.5 // 設定觸發 callback 的交叉比例，0 表示完全不交叉，1 表示完全交叉
    });

    onMounted(() => {
      // 在組件挂載後開始觀察目標元素
      observer.observe(airplane5.value);
      observer.observe(paper.value);
      observer.observe(flypaper.value)

      //window.addEventListener('scroll', handleScroll);

      window.addEventListener('scroll', titleScroll);
    });

    // function  handleScroll() {
    //   const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; // 获取窗口高度
    //   const fullHeight = document.documentElement.scrollHeight || document.body.scrollHeight; // 获取页面总高度
    //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // 获取滚动距离
    //   this.scrollPercentage = (scrollTop / (fullHeight - windowHeight)) * 100; // 计算滚动页面百分比
    //   //this.imgStyle.transform = `translateY(${this.scrollPercentage * 0.3}px)`; // 根据滚动页面百分比计算图片的位移
    //   if(this.scrollPercentage >= 50 && this.scrollPercentage <= 60){
    //     //console.log(this.scrollPercentage);
    //     let percent = (this.scrollPercentage - 50)/(60-50);
    //     //console.log(percent)
    //     flyPaperStyle.value = 'top:'+ (-70+(79*percent)).toString() + "%; left:" + (15+(32*percent)).toString()+"%;";
    //   }
    // }

    let flypaperIndex = 1;
    let flypaperInterval = null;
    function flyPaperAnim(){
      clearInterval(flypaperInterval);
      flypaperIndex = 0;

      flypaperInterval = setInterval(()=>{

        flypaperIndex = (flypaperIndex + 1) % (45+1);

        flyPaperSrc.value = require('../assets/Unit1-1/12-插畫/12-插畫-'+flypaperIndex.toString() +'.png');

        if(flypaperIndex == 45){
          clearInterval(flypaperInterval);
        }
      }, 60);
    }

    function escEvent(){
      showPaperImg.value = false;
    }

    onUnmounted(() => {
      // 在組件卸載前解除 Intersection Observer
      observer.disconnect();
      clearInterval(flypaperInterval);

      window.removeEventListener('scroll', titleScroll);
    });

    function titleScroll() {
      const windowHeight = (document.documentElement && document.documentElement.clientHeight) || document.body.clientHeight;
      const docHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
      const windowBottom = windowHeight + window.pageYOffset;

      // 这里我们取滚动的百分比
      const scrollPercent = (windowBottom / docHeight) * 100;

      //console.log(scrollPercent)

      if (scrollPercent > 25) { // 当页面滚动超过50%时
        toolbarStyle.value = "background: rgba(255,255,255,0)";
      } else {
        toolbarStyle.value = "background: rgba(255,255,255,0.75)";
      }
    }

    return{
      routeBtn,
      showPaperImg,
      airplane5,
      paper,
      flyPaperStyle,
      scaleSize,
      flyPaperSrc,
      flypaper,
      changeSize,
      dragStart,
      drag,
      dragEnd,
      resetPos,
      x,
      y,
      escEvent,
      toolbarStyle
    }
  }
}
</script>

<style scoped src="../assets/css/en-common.css"></style>
<style scoped src="../assets/css/en/Unit1-1.css"></style>
