<template>
  <div class="home-wrap">
<!--    <toolbar type="-1"></toolbar>-->

<!--    <img style="position: absolute; width: 100%; top: 0%; opacity: 0.4" src="@/assets/home/sample/20230424-EN網頁-index-op-menu4.png">-->

    <div>
      <en-menubar-comp color="white"></en-menubar-comp>
    </div>

    <div style="position: relative">
      <img class="logo" src="../assets/home/logo.png" alt="Blessed to be a Global Citizen" title="Blessed to be a Global Citizen">
      <div class="word"><LottieOnce :json="word" ></LottieOnce></div>

      <model-viewer
          tabindex="-1" alt="globe" title="globe"
          src="/Globe2.glb"
          autoplay
      ></model-viewer>


      <img class="map" src="@/assets/home/map.png" alt="map" title="map">

      <img class="png-anim anim-fade" :src="currentFrame"  />
      <img class="pic-1" src="../assets/home/pic-1.png" alt="picture" title="picture">
      <img class="pic-2" src="../assets/home/pic-2.png" alt="picture" title="picture">
      <div class="electric"><LottieTest :json="electric" ></LottieTest></div>
      <img class="pic-3" src="../assets/home/pic-3.png" alt="picture" title="picture">
      <div class="speak anim-fade"><LottieTest :json="speak" ></LottieTest></div>
      <img class="pic-4 anim-fade" src="../assets/home/pic-4.png" alt="picture" title="picture">
      <img class="pic-5" src="../assets/home/pic-5.png" alt="picture" title="picture">
      <div class="drama"><LottieTest :json="drama" ></LottieTest></div>
      <img class="pic-6" src="../assets/home/pic-6.png" alt="airplane" title="airplane">
      <div class="timetower"><LottieTest :json="timetower" ></LottieTest></div>

      <router-link class="blind-style" accesskey="C" id="router-link-c"   to="" >:::</router-link>
      <div class="unit1-btn btn-scale"><img @click="routeBtn('/en/unit1-1')" @keydown.enter="routeBtn('/en/unit1-1')" tabindex="0" class="w-full unit-btn" src="../assets/home/en/20230424-EN網站-index-物件-op-08-鳥瞰世界按鈕.png" alt="Skyview of the World" title="Skyview of the World"></div>
      <div class="unit2-btn btn-scale"><img @click="routeBtn('/en/unit2-0')" @keydown.enter="routeBtn('/en/unit2-0')" tabindex="0" class="w-full unit-btn" src="../assets/home/en/20230424-EN網站-index-物件-op-09-文協向前衝按鈕.png" alt="The Taiwan Cultural Association Forges Ahead" title="The Taiwan Cultural Association Forges Ahead"></div>
      <div class="unit3-btn btn-scale"><img @click="routeBtn('/en/enter')" @keydown.enter="routeBtn('/en/enter')" tabindex="0" class="w-full unit-btn" src="../assets/home/en/20230424-EN網站-index-物件-op-10-廟埕拼文化按鈕.png" alt="Activities of Taiwan Cultural Association" title="Activities of Taiwan Cultural Association"></div>
      <div class="unit4-btn btn-scale"><img @click="routeBtn('/en/unit6-2')" @keydown.enter="routeBtn('/en/unit6-2')" tabindex="0" class="w-full unit-btn" src="../assets/home/en/20230424-EN網站-index-物件-op-11-番外篇按鈕.png" alt="Conversation Across Time" title="Conversation Across Time"></div>


    </div>

    <en-intro-btn></en-intro-btn>

    <FooterFeature language='en'  color="white"></FooterFeature>




<!--    <div class="test-btn btn-5" @click="routeBtn('/person-intro-all')"></div>-->
  </div>

<!--  <img class="sample" src="../assets/common/sample2.png">-->
</template>

<script>


import {nextTick, onBeforeUnmount, ref} from "vue";
import router from "@/router";
import toolbar from "@/components/Toolbar";
import EnIntroBtn from "@/components/EnIntroBtn";
import FooterFeature from "@/components/FooterFeature";
import LottieOnce from "@/components/LottieOnce";
import LottieTest from "@/components/LottieTest";
import { ModelViewer } from '@google/model-viewer';
import EnMenubarComp from "@/components/EnMenubarComp";

export default {
  name: 'HomeView',
  components: {
    EnMenubarComp,
    LottieTest,
    toolbar,
    EnIntroBtn,
    FooterFeature,
    LottieOnce,
    ModelViewer,
  },
  setup(){

    const word = require('@/assets/home/en/EN word.json');
    const electric = require('@/assets/home/Electric.json');
    const speak = require('@/assets/home/speak.json');
    const drama = require('@/assets/home/drama.json');
    const timetower = require('@/assets/home/timetower.json');
    const currentFrame = ref(null);
    let pngAnimIndex = 0;
    let intervalId = null;

    /*調整css變數
    const root = document.documentElement;
    root.style.setProperty('--font-size', '5');
     */

    if(sessionStorage.getItem('loading') != '1'){
      router.push('/loading');
      return;
    }


    const showBtn = ref(false);


    init();

    async function init(){
      await nextTick();

      document.getElementById('video').addEventListener('ended',()=>{
        showBtn.value = true;
      });

      setTimeout(()=>{
        window.addEventListener('click', function (){
          document.getElementById('video').currentTime = 46;
        })
      }, 1000);
    }

    function openUrl(url, type){
      window.open(url, type);
    }

    function routeBtn(path){
      router.push(path);
    }


    intervalId = setInterval(nextFrame, 32);

    function nextFrame() {
      // Cycle through the frames
      pngAnimIndex = (pngAnimIndex + 1) % 180;
      currentFrame.value = require('@/assets/home/png-anim/20230330-臺史博-文協線上展-物件動態-李應章'+ pngAnimIndex.toString().padStart(4, '0') +'.png');

      if(pngAnimIndex >= 179){
        clearInterval(intervalId);
      }
    }

    onBeforeUnmount(()=>{
      clearInterval(intervalId);
    });

    return{
      openUrl,
      showBtn,
      routeBtn,
      word,
      electric,
      speak,
      drama,
      timetower,
      currentFrame,
    }
  }

}
</script>

<style src="../assets/css/common.css"></style>
<style scoped src="../assets/css/en/Home.css"></style>

<!--<style scoped>-->
<!--.sample{-->
<!--  position: absolute;-->
<!--  top: 0;-->
<!--  left: 0;-->
<!--  width: 100%;-->
<!--  opacity: 0.3;-->
<!--}-->

<!--.test-btn{-->
<!--  position: absolute;-->
<!--  /*background: black;*/-->
<!--  /*opacity: 0.5;*/-->
<!--  cursor: pointer;-->
<!--}-->

<!--.btn-1{-->
<!--  top: 29.5vw;-->
<!--  left: 8%;-->
<!--  width: 19vw;-->
<!--  height: 10vw;-->
<!--}-->

<!--.btn-1 img{-->
<!--  position: absolute;-->
<!--  top: -8%;-->
<!--  left: 38%;-->
<!--  width: 26.5%;-->
<!--  opacity: 0;-->
<!--  transition: opacity 0.3s;-->
<!--}-->

<!--.btn-1:hover img{-->
<!--  opacity: 1;-->
<!--}-->

<!--.btn-2{-->
<!--  top: 27vw;-->
<!--  left: 30%;-->
<!--  width: 32vw;-->
<!--  height: 15vw;-->
<!--}-->

<!--.btn-2 img{-->
<!--  position: relative;-->
<!--  top: -37%;-->
<!--  left: 49%;-->
<!--  width: 20.6%;-->
<!--  opacity: 0;-->
<!--  transition: opacity 0.3s;-->
<!--}-->

<!--.btn-2:hover img{-->
<!--  opacity: 1;-->
<!--}-->

<!--.btn-3{-->
<!--  top: 10vw;-->
<!--  left: 74%;-->
<!--  width: 22vw;-->
<!--  height: 16vw;-->
<!--}-->

<!--.btn-3 img{-->
<!--  position: absolute;-->
<!--  top: -3%;-->
<!--  left: 70%;-->
<!--  width: 29.9%;-->
<!--  opacity: 0;-->
<!--  transition: opacity 0.3s;-->
<!--}-->

<!--.btn-3:hover img{-->
<!--  opacity: 1;-->
<!--}-->

<!--.btn-4{-->
<!--  top: 28vw;-->
<!--  left: 75%;-->
<!--  width: 8vw;-->
<!--  height: 7vw;-->
<!--}-->

<!--.btn-4 img{-->
<!--  position: absolute;-->
<!--  top: -38%;-->
<!--  left: 67%;-->
<!--  width: 63%;-->
<!--  opacity: 0;-->
<!--  transition: opacity 0.3s;-->
<!--}-->

<!--.btn-4:hover img{-->
<!--  opacity: 1;-->
<!--}-->

<!--.btn-5{-->
<!--  top: 42vw;-->
<!--  left: 94%;-->
<!--  width: 4vw;-->
<!--  height: 13vw;-->
<!--}-->




<!--</style>-->


